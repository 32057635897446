import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

const ViewDocument = ({ show, onHide }) => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (show.isShow && show.document) {
      // Use the PDF.js Viewer URL with the document link
      const encodedUrl = encodeURIComponent(show.document);
      const viewerUrl = `https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodedUrl}`;
      setPdfUrl(viewerUrl);
      setError(null);
    }

    return () => {
      setPdfUrl(null);
      setError(null);
    };
  }, [show.isShow, show.document]);

  return (
    <Modal
      show={show.isShow}
      onHide={() => {
        onHide();
        setError(null); // Clear error state when closing
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
    >
      <Modal.Body>
        <div className="delate-content p-2">
          <div className="d-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Document</span>
            <span className="fs-14" style={{ color: "#72777A" }}>
              {show.file_name}
            </span>
          </div>
          <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
            <div className="col-md-12" style={{ height: "500px" }}>
              {error ? (
                <p style={{ color: "red" }}>{error}</p>
              ) : pdfUrl ? (
                <iframe
                  src={pdfUrl}
                  width="100%"
                  height="100%"
                  style={{ border: "none" }}
                  title="PDF Document"
                ></iframe>
              ) : (
                <p>Loading document...</p>
              )}
            </div>
          </div>
          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <a
              href={show.document}
              download={show.file_name}
              className="cancel-btn download-btn"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>
            <button className="delate-btn" onClick={onHide}>
              Close
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewDocument;
