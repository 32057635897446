import React, { useState } from "react";
import DownIcon from "../../Assets/Icons/DownIcon";
import { Link } from "react-router-dom";
import MoreVertical from "../../Assets/Icons/MoreVertical";
import AddIcon from "../../Assets/Icons/AddIcon";

const RequestdHeader = () => {
  const [tabItem, setTabItem] = useState("start");
  return (
    <div className="work-header requestd-header">
      <div className="fs-20">
        Work Order 321234 <span className="work-time">02:58</span>
      </div>
      <div className="d-flex">
        <ul className="request-list">
          <li>
            <Link
              to=""
              onClick={() => setTabItem("start")}
              className={tabItem === "start" && "active"}
            >
              Start Work
            </Link>
          </li>
          <li>
            <Link
              to=""
              onClick={() => setTabItem("request")}
              className={tabItem === "request" && "active"}
            >
              Request Closure
            </Link>
          </li>
          <li>
            <Link
              to=""
              onClick={() => setTabItem("confirm")}
              className={tabItem === "confirm" && "active"}
            >
              Confirm Work Done
            </Link>
          </li>
        </ul>
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-15 d-flex align-items-center gap-2">
              <MoreVertical /> Actions <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu dropdown-menu-end select-menu">
            <li>
              <Link to="/work-orders/add-work-order">
                <AddIcon /> Add Work Order
              </Link>
            </li>
            <li>
              <Link to="">
                <AddIcon /> Export to Excel
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RequestdHeader;
