import React, { useEffect, useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { DiagnosisDetailsContext } from "../../../Context/Inspections/WorkOrderContext";
import { ADD_RFQ_QUOTENOTES } from "../../../utls/constants";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { post } from "../../../Services/ApiHelper";
import { Link } from "react-router-dom";
import Astericks from "../../Common/Asterick";

const AddQuoteNoteModal = (props) => {
  const { diagnosisSummary, setDiagnosisSummary } = useContext(
    DiagnosisDetailsContext
  );
  const [notes, setNotes] = useState("");
  const [notesError, setNotesError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("bearerToken");

  useEffect(() => {
    //Can this also be used to reset error required section??
    setNotes("");
    setNotesError(null);
  }, [props.show]);

  const validateInputs = () => {
    let isValid = true;

    setNotesError(null);

    if (!notes) {
      setNotesError("Required *");
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = () => {
    const isValid = validateInputs();

    if (!isValid) {
      return;
    }
    setIsLoading(true);
    const payload = {
      quoteId: props.quoteId,
      notes: notes,
    };
    setNotes("");
    post(ADD_RFQ_QUOTENOTES, payload, token)
      .then((response) => {
        if (response.succeeded === true) {
          props.onHide();
          setIsLoading(false);
          setNotes("");

          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          //Update notes
          setDiagnosisSummary((prevDiagnosisSummary) => ({
            ...prevDiagnosisSummary,
            requestQuoteSummary: {
              ...prevDiagnosisSummary?.requestQuoteSummary,
              notes: notes,
            },
          }));
        } else {
          props.onHide();
          setIsLoading(false);
          setNotes("");

          toast.error(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((error) => {
        props.onHide();
        setIsLoading(false);
        setNotes("");
        toast.error("An error occurred", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .finally(() => {
        props.onHide();
        setIsLoading(false);
      });
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="">
            <span className="fs-16 fw-bold ">Add Quote Notes</span>
            <span style={{ color: "#72777A" }} className="fs-14 ps-3">
              Add notes to your quote
            </span>
          </div>
          <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2 fs-14">
                  Quote Notes
                  <Astericks />
                </label>
                {notesError && <p style={{ color: "red" }}>{notesError}</p>}
              </div>
              <textarea
                name=""
                onChange={(e) => setNotes(e.target.value)}
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "150px",
                  borderRadius: "5px",
                  padding: "15px 15px",
                }}
                placeholder="Enter quote notes"
              ></textarea>
            </div>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            {isLoading ? (
              <Spinner
                animation="border"
                style={{
                  color: "#D57D2A",
                }}
              />
            ) : (
              <Link to="" className="delate-btn" onClick={handleSubmit}>
                Add
              </Link>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddQuoteNoteModal;
