import React, { useState, useEffect, useContext } from "react";
import CreateRecurrence from "./CreateRecurrence";
import { Link, useLocation } from "react-router-dom";
import {
  CREATE_ROUTINE_INSPECTION_CATEGORY,
  EDIT_ROUTINE_INSPECTION_CATEGORY,
  GET_CALENDAR_TYPES,
  GET_CATEGORIES,
  GET_CHECKLISTS,
} from "../../../utls/constants";
import RoutineInspectionCategoryContext from "../../../Context/Inspections/RoutineInspectionCategoryContext";
import { Alert, Spinner, Toast } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import { TextInput } from "./Inputs";
import { TextField } from "../../TextField";
import { FormProvider, set, useForm } from "react-hook-form";
import useFormattedDate from "../../../Services/useFormattedDate";
import ReactDatePicker from "react-datepicker";
import DownIcon from "../../../Assets/Icons/DownIcon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { get, post, update } from "../../../Services/ApiHelper";
const CreateContent = ({item}) => {
  const [selectValue, setSelectValue] = useState({
    asset: "Select",
    category: "Select",
  });
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [assetCategories, setAssetCategories] = useState([]);
  const [checklists, setChecklists] = useState([]);

  const [data, setData] = useState({});
  const [recurrenceData, setRecurrenceData] = useState(null); // New state for recurrence data
  const token = localStorage.getItem("bearerToken");

  const [calendarTypes, setCalendarTypes] = useState([]);
  const storedStartDateData = window.localStorage.getItem("startDate");
  const storedEndDateData = window.localStorage.getItem("endsDate");
  const [disableSubmit, setDisableSubmit] = useState(false);

  const [createSuccessMessage, setCreateSuccessMessage] = useState(null);
  const [createErrorMessage, setCreateErrorMessage] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  let [startDate, setStartDate] = useState(new Date());
  let [endsDate, setEndsDate] = useState(new Date());
  let [value, setValue] = useState(new Date());

  
  // Add state for recurrence values
  const [recurrenceValue, setRecurrenceValue] = useState({});
  useEffect(() => {
    setData(location.state?.data || null);
  }, [location.state]);
  useEffect(() => {
    if(item !==null){
      setStartDate(new Date(item.startsOn));
      setEndsDate(new Date(item.endsOn));
    }
  
    get(GET_CALENDAR_TYPES, token).then((response) => {
      setCalendarTypes(response.data);
    });

      get(GET_CATEGORIES, token).then((response) => {
        setAssetCategories(response);
        
      });
      
      get(GET_CHECKLISTS, token).then((response) => {
        setChecklists(response.data);
      });
  }, []);

  const handleRecurrenceChange = (recurrenceValue) => {
    setRecurrenceData(recurrenceValue);
  };
  // const methods = useForm();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  
  
  const onSubmit = (data) => {
    setIsLoading(true)
    const payload = item ===null?
    
    {
      name: data.name,
      description: data.description,
      assetCategoryId: parseInt(data.category, 10) || 0,
      checklistId: parseInt(data.checklists, 10) || 0,
      startsOn: startDate,
      endsOn: endsDate,
      endsAfter: 0,
      calendarTypeName: "string",
      calendarTypeId: parseInt(data.calendar, 10) || 0,
      frequency: parseInt(data.recurrence, 10) || 0,
      createdBy:JSON.parse(localStorage.getItem("user")).id,
    }
    :{
      id: item.id,
      name: data.name,
      description: data.description,
      assetCategoryId: parseInt(data.category, 10) || 0,
      checklistId: parseInt(data.checklists, 10) || 0,
      startsOn: startDate,
      endsOn: endsDate,
      endsAfter: 0,
      calendarTypeId: parseInt(data.calendar, 10) || 0,
      frequency: parseInt(data.recurrence, 10) || 0,
      modifiedBy:JSON.parse(localStorage.getItem("user")).id,
    }
    ;

    if(item ===null){

    
    post(CREATE_ROUTINE_INSPECTION_CATEGORY, payload, token)
      .then((response) => {
        
        if (response.succeeded === true) {
          setIsLoading(false)
          toast.success(response.messages[0],{
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
         });

          setTimeout(() => {
            window.location.href = "/inspection-category";
          }, 3000);
        } else {
          toast.error(response.messages[0], {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
    
      })
      .catch((error) => {
        console.error(error);
      });

    }else{
      update(EDIT_ROUTINE_INSPECTION_CATEGORY, payload, token)
      .then((response) => {
        if (response.succeeded === true) {
          toast.success(response.messages[0],{
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
         });

          setTimeout(() => {
            window.location.href = "/inspection-category";
          }, 3000);
        } else {
          toast.error(response.messages[0], {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
    
      })
      .catch((error) => {
        console.error(error); 
      }
      );
    }

  };
  const validateEndDate = (date) => {
    if (date < startDate) {
      // Show error toast
      toast.error("End date cannot be before start date", {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });

      //disable submit button
      setDisableSubmit(true);
    }else {
      //enable submit button
      setDisableSubmit(false);
    }
  };
  const validateStartDate = (date) => {
    if (date > endsDate) {
      // Show error toast
      toast.error("Start date cannot be after end date", {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      // disable submit button
      setDisableSubmit(true);

    }else {
      //enable submit button
      setDisableSubmit(false);
    }
  };

      


  const ExampleCustomInput = ({ value, onClick }) => (
    <button className="example-custom-input" onClick={onClick}>
      {value} <DownIcon />
    </button>
  );

  const styleBtn = {
    borderRadius: "5px",
    background: "#D57D2A",
    color: "white",
    border: "0",
    width: "100%",
    outline: "none",
    height: "41px",
    padding: "0 15px",
  };

  const styleBtn2 = {
    borderRadius: "5px",
    background: "#d57d2a4d",
    color: "white",
    border: "0",
    width: "100%",
    outline: "none",
    height: "41px",
    padding: "0 15px",
  };
  return (
    <div className="order-details-content add-pm-content">
      <div className="fs-16 fw-bold">Inspection Details</div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row details-forms-one mt-5">
          <div className="col-lg-6">
            <label>Name</label>
            <div className="text-danger float-md-end">
              {errors.name && <span>{errors.name.message}</span>}
            </div>
            <input
              type="text"
              defaultValue={item ===null ?"":item.routineInspectionName}
              onChange={(e) => setName(e.target.value)}
              {...register("name", { required: "* required" })}
            />
          </div>
          <div className="col-md-6">
            <label>Description</label>
            <div className="text-danger float-md-end">
              {errors.description && <span>{errors.description.message}</span>}
            </div>
            <input
              type="text"
              error={errors.description}
              defaultValue={item ===null ?"":item.routineInspectionDescription}
              onChange={(e) => setDescription(e.target.value)}
              {...register("description", { required: "* required" })}
            />
          </div>
          <div className="col-lg-6">
            <div className="select-box">
              <label>Checklist</label>
              <div className="text-danger float-md-end">
                {errors.checklists && <span>{errors.checklists.message}</span>}
              </div>
              {/* <div className="text-danger float-md-end"><span>* required</span></div> */}
              <select
                className="form-select gray-color"
                style={{ color: selectValue?.asset !== "Select" ? "#000" : "" }}
                onChange={(e) =>
                  setSelectValue({ ...selectValue, asset: e.target.value })
                }
                {...register("checklists", { required: "* required" })}
                
              >
                <option selected value={item===null?"":item.checkListFormId} >
                 {item ===null ?"Select":item.checklistFormName}
                </option>
                {checklists.map((checklist) => (
                  <option value={checklist.id}>{checklist.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="select-box">
              <label>Asset Category</label>
              <div className="text-danger float-md-end">
                {errors.category && <span>{errors.category.message}</span>}
              </div>
              {/* <div className="text-danger float-md-end"><span>* required</span></div> */}
              <select
                className="form-select gray-color"
                style={{ color: selectValue?.asset !== "Select" ? "#000" : "" }}
                onChange={(e) =>
                  setSelectValue({ ...selectValue, category: e.target.value })
                }
                {...register("category", { required: "* required" })}
              >
                <option selected value={item ==null?"":item.assetCategoryId}

                >
                  {item ===null ?"Select":item.assetCategoryName}
                </option>
                {assetCategories.map((category) => (
                  <option value={category.id}
                  
                  >
                    {category.assetCategoryName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-lg-3">
            <label>Recurrence</label>
            <div className="text-danger float-md-end">
              {errors.recurrence && <span>{errors.recurrence.message}</span>}
            </div>
            <input
              type="number"
              defaultValue={item ===null ?"":item.frequency}
              onChange={(e) => setName(e.target.value)}
              {...register("recurrence", { required: "* required" })}
            />
          </div>
          <div className="col-lg-3">
            <div className="select-box">
              <label>per</label>
              <div className="text-danger float-md-end">
                {errors.calendar && <span>{errors.calendar.message}</span>}
              </div>
              <select
                className="form-select gray-color"
                style={{ color: selectValue?.asset !== "Select" ? "#000" : "" }}
                onChange={(e) =>
                  setRecurrenceValue({
                    ...recurrenceValue,
                    type: e.target.value,
                  })
                }
                {...register("calendar", { required: "* required" })}
              >
                <option selected value={item ==null?"":item.calendarTypeId}>
                  {item ===null ?"Select":item.calendarTypeName}
                </option>
                {calendarTypes.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="pm-calender">
              <label>Starts on</label>
              <ReactDatePicker
                value={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  validateStartDate(date); 
                  // onRecurrenceChange({ ...recurrenceValue, endsDate: date });
                }}
                maxDate={endsDate}
                selected={startDate}
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="pm-calender">
              <label>Ends on</label>  
              <div className="text-danger float-md-end">
                {errors.endsOn && <span>{errors.endsOn.message}</span>}
              </div>
              <ReactDatePicker
              value={endsDate}
                onChange={(date) => {
                  setEndsDate(date);
                  validateEndDate(date); // Call the validation function
                }}
                minDate={startDate}
                selected={endsDate}
              />
            </div>
          </div>
          <div className="details-buttons" style={{position: "relative"}}>
          {isLoading ?
          <Spinner animation="border"  style={{color: "#D57D2A", position: "absolute", right: "10%", top: "10%"}} /> : <input style={styleBtn} type="submit" value="Save" />
            }
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateContent;
