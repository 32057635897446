import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';

const AddScheduleModal = (props) => {
  const [selectValue, setSelectValue] = useState({
    asset: "Select",
    location: "Select",
    assignTeam: "Select",
    assignWorker: "Select",
  });
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add-schedule-modal"
    >
      <Modal.Body>
        <form className="add-schedule">
          <div className="fs-16 fw-medium">Add Schedule</div>
          <div className="row">
            <div className="col-md-12">
              <label>Location</label>
              <select
                className="form-select gray-color"
                style={{
                  color: selectValue?.location !== "Select" ? "#000" : "",
                }}
                onChange={(e) =>
                  setSelectValue({ ...selectValue, location: e.target.value })
                }
              >
                <option selected value="Select">
                  Select
                </option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
            <div className="col-md-12">
              <label>Asset(s)</label>
              <select
                className="form-select gray-color"
                style={{ color: selectValue?.asset !== "Select" ? "#000" : "" }}
                onChange={(e) =>
                  setSelectValue({ ...selectValue, asset: e.target.value })
                }
              >
                <option selected value="Select">
                  Select
                </option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
            <div className="col-md-12">
              <label>Last Maintenance Date</label>
              <input
                className="last-date"
                style={{ color: "#000" }}
                type="date"
                value="2023-12-10"
                placeholder="2023-12-10"
              />
            </div>
            <div className="col-md-6">
              <label>Assign Team</label>
              <select
                className="form-select gray-color"
                style={{
                  color: selectValue?.assignTeam !== "Select" ? "#000" : "",
                }}
                onChange={(e) =>
                  setSelectValue({ ...selectValue, assignTeam: e.target.value })
                }
              >
                <option selected value="Select">
                  Select
                </option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
            <div className="col-md-6">
              <label>Assign Worker</label>
              <select
                className="form-select gray-color"
                style={{
                  color: selectValue?.assignWorker !== "Select" ? "#000" : "",
                }}
                onChange={(e) =>
                  setSelectValue({
                    ...selectValue,
                    assignWorker: e.target.value,
                  })
                }
              >
                <option selected value="Select">
                  Select
                </option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
            <div className="col-md-6">
              {/* <div className="form-check round-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="for"
                  checked
                />
                <label className="form-check-label" for="for">
                  One WO for all assets
                </label>
              </div> */}
              <Form.Check
                checked
                label="One WO for all assets"
                name="group1"
                type="radio"
                id={`inline-radio-1`}
              />
            </div>
            <div className="col-md-6">
              {/* <div className="form-check round-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="per"
                />
                <label className="form-check-label" for="per">
                  One WO per Asset
                </label>
              </div> */}
             
              <Form.Check
                inline
                label="One WO per Asset"
                name="group1"
                type="radio"
                id={`inline-radio-2`}
              />

            </div>
            <div className="col-lg-12">
              <div className="text-end">
                <button className="next-btn">Add</button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddScheduleModal;
