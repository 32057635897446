import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import EditIcon2 from "../../Assets/Icons/EditIcon2";
import DeleteIcon from "../../Assets/Icons/DeleteIcon";
import EyesIcon from "../../Assets/Icons/EyesIcon";
import DownloadIcon from "../../Assets/Icons/DownloadIcon";
import DeleteModal from "../WorkOrders/Modal/DeleteModal";
import ViewChecklistModal from "./modals/ViewChecklistModal";
import EditProjectedPartModal from "./modals/EditProjectedPartModal";

import { GET_PM_TEMPLATE_BY_ID } from "../../utls/constants";
import { useParams } from "react-router-dom";
import { get } from "../../Services/ApiHelper";

const ViewTemplate = () => {
  const [inspection, setInspection] = useState({});
  const [viewCheckListShow, setViewCheckListShow] = useState(false);
  const [delateModalShow, setDelateModalShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [editData, setEditData] = useState({});
  //function to download files
  const handleDownload = (fileUrl) => {
    fetch(fileUrl)
      .then((response) => response.blob()) // Convert response to a Blob
      .then((blob) => {
        const url = window.URL.createObjectURL(blob); // Create a temporary URL
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileUrl); // Set the downloaded file name
        link.click(); // Simulate a click on the link to initiate download

        // Optional: Clean up the temporary URL after download (recommended)
        setTimeout(() => window.URL.revokeObjectURL(url), 1000); // Revoke after 1 second
      })
      .catch((error) => console.error("Error downloading file:", error));
  };
  const { id } = useParams();

  const [template, setTemplate] = useState();
  const bearerToken = localStorage.getItem("bearerToken");
  useEffect(() => {
    console.log(GET_PM_TEMPLATE_BY_ID(id));
    get(GET_PM_TEMPLATE_BY_ID(id), bearerToken)
      .then((response) => {
        setTemplate(response);
        console.log(response);
      })
      .catch((error) => console.log(error));
  }, [id]);
  return (
    <>
      <div className="order-sidebar-content p-0">
        <div className="p-4">
          <div className="fs-13 fw-medium">SCHEDULE TEMPLATE INFORMATION</div>
          <div className="washroom-sidebar mt-3 align-items-end">
            <div>
              <div className="fs-16 fw-bold pb-2" style={{ color: "#6C5B51" }}>
                Template Title
              </div>
              <div className="fs-20 fw-bold" style={{ color: "#D57D2A" }}>
                {template?.templateName}
              </div>
            </div>
          </div>
        </div>
        <div
          className="view-work-sidebar"
          style={{
            background: "rgba(241, 239, 239, 0.25)",
            padding: "25px 25px 80px",
            borderTop: "2px solid #F1EFEF",
          }}
        >
          <div className="row pb-2">
            <div className="col-12">
              <div className="fs-14 fw-medium">Description:</div>
              <div className="fs-14 fw-medium" style={{ color: "#6C5B51" }}>
                {template?.description}
              </div>
            </div>
            <div className="col-6">
              <div className="fs-14 fw-medium">Category of Work</div>
              <div className="fs-14 fw-medium" style={{ color: "#6C5B51" }}>
                {template?.categoryOfWorkName}
              </div>
            </div>
            <div className="col-6">
              <div className="fs-14 fw-medium">Asset Category</div>
              <div className="fs-14 fw-medium" style={{ color: "#6C5B51" }}>
                {template?.assetCategoryName}
              </div>
            </div>
            <div className="col-6">
              <div className="fs-14 fw-medium">Work recurs every</div>
              <div className="fs-14 fw-medium" style={{ color: "#6C5B51" }}>
                {template?.recursEvery > 1
                  ? `${template?.recursEvery}` +
                    ` ${template?.calendarTypeName}s`
                  : `${template?.recursEvery}` +
                    `${template?.calendarTypeName}`}
              </div>
            </div>
            <div className="col-6">
              <div className="fs-14 fw-medium">Work estimate hours</div>
              <div className="fs-14 fw-medium" style={{ color: "#6C5B51" }}>
                {template?.estimatedHours > 1
                  ? `${template?.estimatedHours} Hours`
                  : `${template?.estimatedHours} Hour`}
              </div>
            </div>
            <div className="col-6">
              <div className="fs-14 fw-medium">Priority</div>
              <div className="fs-14 fw-medium" style={{ color: "#6C5B51" }}>
                {template?.priorityName}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="order-request washroom-right-content responses-cont">
        <div className="washroom-title">
          <div className="fs-13 fw-medium">SCHEDULE DETAILS</div>
        </div>
        <div className="order-request mt-4 p-0">
          <Accordion defaultActiveKey="0">
            {/* LABOUR COSTS */}
            <Accordion.Item eventKey="0" className="request-box">
              <Accordion.Header className="request-box-title">
                <div className="fs-13 fw-medium">PARTS INFORMATION</div>
              </Accordion.Header>
              <Accordion.Body className="request-box-body p-0">
                <div className="fs-15 ps-2 pb-2">Projected Parts</div>
                <div
                  className="table-responsive-sm request_table"
                  style={{ maxWidth: "100%" }}
                >
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Parts</th>

                        <th scope="col">Quantity</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {template?.projectedParts?.map((item) => (
                        <tr key={item.id}>
                          <td>{item.partName}</td>
                          <td>{item.quantityProjected}</td>
                          {/* <td>
                            <div className="button-con">
                              <button
                                onClick={() => {
                                  setEditData(item);
                                  setEditShow(true);
                                }}
                              >
                                <EditIcon2 />
                              </button>

                              <button onClick={() => setDelateModalShow(true)}>
                                <DeleteIcon />
                              </button>
                            </div>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            {/* FILES */}
            <Accordion.Item eventKey="1" className="request-box">
              <Accordion.Header className="request-box-title">
                <div className="fs-13 fw-medium">CHECKLIST INFORMATION</div>
              </Accordion.Header>
              <Accordion.Body className="request-box-body p-0">
                <div className="fs-14 fw-medium black-38">
                  Checklist details
                </div>
                {template?.checkLists?.map((item) => (
                  <div className="upload-details" key={item.checkListFormId}>
                    <div>
                      <div className="fs-14" style={{ color: "#1F2024" }}>
                        {item.checkListName}
                      </div>
                      <div className="fs-13 gray-c">
                        {/* Added: {item.uploadedTime}{" "} */}
                      </div>
                    </div>
                    <div className="upload-icons">
                      <button
                        onClick={() => {
                          setInspection(item);
                          setViewCheckListShow(true);
                        }}
                      >
                        <EyesIcon />
                      </button>

                      <button onClick={() => handleDownload(item.file)}>
                        <DownloadIcon />
                      </button>
                    </div>
                  </div>
                ))}
                <div className="text-end pt-4"></div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>

      {/* Modal function */}
      <ViewChecklistModal
        inspection={inspection}
        show={viewCheckListShow}
        onHide={() => setViewCheckListShow(false)}
      />

      <DeleteModal
        show={delateModalShow}
        onHide={() => setDelateModalShow(false)}
      />

      <EditProjectedPartModal
        data={editData}
        show={editShow}
        onHide={() => setEditShow(false)}
      />
    </>
  );
};

const fileData = [
  {
    id: 0,
    fileName: "Pump calibration checklist",
    uploadedTime: "",
    file: "/demo/1.pdf",
  },
  {
    id: 1,
    fileName: "Submersible repairs checklist",
    uploadedTime: "",
    file: "/demo/1.jpg",
  },
  {
    id: 2,
    fileName: "HSE Zone 2 checklist",
    uploadedTime: "",
    file: "/demo/1.mp4",
  },
];

const labourData = [
  {
    id: 0,
    parts: "Part A - 005788",
    location: "Nairobi",
    quantity: "3",
  },
  {
    id: 1,
    parts: "Part B - 781912",
    location: "Mombasa",
    quantity: "1",
  },
  {
    id: 2,
    parts: "Part C - 562782",
    location: "Nairobi",
    quantity: "1",
  },
];

export default ViewTemplate;
