import React, { useState } from "react";
import EditIcon from "../../Assets/Icons/EditIcon";
import DeleteIcon from "../../Assets/Icons/DeleteIcon";
import DownIcon from "../../Assets/Icons/DownIcon";
import DropdownIcon from "../../Assets/Icons/DropdownIcon";
import ArrowLeft from "../../Assets/Icons/ArrowLeft";
import ArrowRight from "../../Assets/Icons/ArrowRight";
import SearchIcon from "../../Assets/Icons/SearchIcon";

const PumpContent = () => {
  const [selectedAsset, setSelectedAsset] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [selectedPerPage, setSelectedPerPage] = useState("5");
  const handlePerClick = (per) => {
    setSelectedPerPage(per);
  };
  const handleAssetClick = (Asset) => {
    setSelectedAsset(Asset);
  };
  const handleLocationClick = (location) => {
    setSelectedLocation(location);
  };

  const handleTeamClick = (team) => {
    setSelectedTeam(team);
  };
  return (
    <div className="order-request">
      <div className="request-box">
        <div className="request-box-title">
          <div className="fs-13 fw-medium">SCHEDULES</div>
          <button>
            <DownIcon />
          </button>
        </div>
        <div className="request-box-body">
          <div className="schedules-selection">
            <div className="schedules-dropdown">
              <div className="dropdown select-dropdown">
                <button className="select-title" data-bs-toggle="dropdown">
                  <span className="fs-13">Location:</span>
                  <span className="fs-14 d-flex align-items-center gap-1">
                    {selectedLocation} <DownIcon />
                  </span>
                </button>
                <ul className="dropdown-menu select-menu">
                  <li onClick={() => handleLocationClick("Narok")}>Narok</li>
                  <li onClick={() => handleLocationClick("Narok 1")}>
                    Narok 1
                  </li>
                  <li onClick={() => handleLocationClick("Narok 2")}>
                    Narok 2
                  </li>
                </ul>
              </div>
              <div className="dropdown select-dropdown">
                <button className="select-title" data-bs-toggle="dropdown">
                  <span className="fs-13">Team:</span>
                  <span className="fs-14 d-flex align-items-center gap-1">
                    {selectedTeam} <DownIcon />
                  </span>
                </button>
                <ul className="dropdown-menu select-menu">
                  <li onClick={() => handleTeamClick("AZ Team")}>AZ Team</li>
                  <li onClick={() => handleTeamClick("AZ Team 1")}>
                    AZ Team 1
                  </li>
                  <li onClick={() => handleTeamClick("AZ Team 2")}>
                    AZ Team 2
                  </li>
                </ul>
              </div>

              <div className="dropdown select-dropdown">
                <button className="select-title" data-bs-toggle="dropdown">
                  <span className="fs-13">Asset:</span>
                  <span className="fs-14 d-flex align-items-center gap-1">
                    {selectedAsset} <DownIcon />
                  </span>
                </button>
                <ul className="dropdown-menu select-menu">
                  <li onClick={() => handleAssetClick("Asset")}>Asset</li>
                  <li onClick={() => handleAssetClick("Asset")}>Asset</li>
                  <li onClick={() => handleAssetClick("Asset")}>Asset</li>
                  <li onClick={() => handleAssetClick("Asset")}>Asset</li>
                </ul>
              </div>
            </div>
            <div>
              <form action="" className="search__box">
                <button type="submit">
                  <SearchIcon />
                </button>
                <input type="search" />
              </form>
            </div>
          </div>

          <div className="table-responsive request_table table_fulls">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Location</th>
                  <th scope="col">Assets</th>
                  <th scope="col">Last Maintained</th>
                  <th scope="col">Next Maintenance</th>
                  <th scope="col">Team</th>
                  <th scope="col">Worker</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Nairobi HQ</td>
                  <td>Pump1, Pump 2, Pump 3</td>
                  <td>22/10/2023</td>
                  <td>23/09/2024</td>
                  <td>AZ Team</td>
                  <td>Peter Pan</td>
                  <td>
                    <div className="button-con">
                      <button>
                        <EditIcon />
                      </button>
                      <button>
                        <DeleteIcon />
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="table-pagination pagination-center mt-180">
          <div className="pagination-row">
            Rows per page:
            <div className="dropdown select-dropdown">
              <button className="select-title" data-bs-toggle="dropdown">
                <span className="fs-14 d-flex align-items-center gap-2">
                  {selectedPerPage} <DropdownIcon />
                </span>
              </button>
              <ul
                className="dropdown-menu select-menu"
                style={{ minWidth: "25px" }}
              >
                <li onClick={() => handlePerClick("5")}>5</li>
                <li onClick={() => handlePerClick("10")}>10</li>
                <li onClick={() => handlePerClick("15")}>15</li>
                <li onClick={() => handlePerClick("20")}>20</li>
              </ul>
            </div>
          </div>
          <div className="pagination-number">
            <div className="fs-14">1-3 of 6</div>
            <div className="arrow-btn">
              <button>
                <ArrowLeft />
              </button>
              <button>
                <ArrowRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PumpContent;
