import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import ArrowLeft from "../../../Assets/Icons/ArrowLeft";
import ArrowRight from "../../../Assets/Icons/ArrowRight";
import { Form } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";

const ViewChecklistModal = (props) => {
  const [inspection, setInspection] = useState(props.inspection);
  useEffect(() => {
    setInspection(props.inspection);
  }, [props.inspection]);

  const ComponentToPrint = React.forwardRef((props, ref) => {
    const CompanyDetails = JSON.parse(localStorage.getItem("company"));
    return (
      <div ref={ref}>
        <style type="text/css" media="print">
          {"@page { margin: 20px 20px 20px 25px; }}"}
        </style>
        <div
          className="fs-15 fw-bold"
          style={{
            color: "#6C5B51",
            textAlign: "center",
            margin: "10px 0px 5px 0px",
          }}
        >
          <div>
            {CompanyDetails?.companyName +
              " " +
              CompanyDetails?.street +
              " " +
              CompanyDetails?.address1 +
              "," +
              CompanyDetails?.city +
              "," +
              CompanyDetails?.country}
          </div>
          <div>{props.data.checkListName}</div>
        </div>
        <hr></hr>
        <div className="response-list-content">
          <SectionOne
            data={props.data}
            currentIndex={props.currentIndex}
            setCurrentIndex={props.setCurrentIndex}
            showPDF={true}
          />
        </div>
        <br />
        <br />
        <div
          className="fs-15 fw-bold"
          style={{ color: "#6C5B51", marginLeft: "20px", display: "flex" }}
        >
          <div style={{ flexGrow: 1 }}>
            <div>Created By: ..................................</div>
            <br />
            <div>Date: ..............................................</div>
            <br />
            <div>Sign: ..............................................</div>
          </div>
          <div style={{ flexGrow: 1 }}>
            <div>Created By: ..................................</div>
            <br />
            <div>Date: ..............................................</div>
            <br />
            <div>Sign: ..............................................</div>
          </div>
        </div>
      </div>
    );
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Continuous  Checks Checklist",
  });

  const [currentPage, setCurrentPage] = useState(1);

  const [currentIndex, setCurrentIndex] = useState(0);
  const totalSections = inspection?.checkListFormSections?.length;

  const handleNextClick = () => {
    if (currentIndex < totalSections - 1) {
      setCurrentIndex((prevIndex) => Math.min(prevIndex + 1));
    }
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalSections));
  };

  const handleBackClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(() => Math.max(currentIndex - 1));
    }
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Checklist</span>
            <span className="fs-14" style={{ color: "#72777A" }}>
              View filled in checklist
            </span>
          </div>
          <div className="order-request washroom-right-content responses-cont">
            <div className="response-checklist">
              <div className="fs-15 fw-bold" style={{ color: "#6C5B51" }}>
                {inspection?.checkListName}
              </div>
              <d iv className="response-pagination d-flex align-items-center">
                <div className="page-number fs-14">
                  Page {currentPage} of {totalSections}
                </div>
                <div className="pagination-btn">
                  <button
                    className="back-btn"
                    onClick={handleBackClick}
                    disabled={currentPage === 1}
                  >
                    Back <ArrowLeft />
                  </button>
                  <button
                    className="next-btn"
                    onClick={handleNextClick}
                    disabled={currentPage === totalSections}
                  >
                    <ArrowRight /> Next
                  </button>
                </div>
              </d>
            </div>

            <div className="response-list-content">
              {
                <SectionOne
                  data={inspection}
                  currentIndex={currentIndex}
                  setCurrentIndex={setCurrentIndex}
                  showPDF={false}
                />
              }
            </div>
            {/*<div style={{ display: "none" }}><ComponentToPrint data={inspection} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} ref={componentRef} /></div>*/}
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button
              className="cancel-btn"
              onClick={/*handlePrint*/ props.onHide}
            >
              Download
            </button>
            <Link to="" className="delate-btn" onClick={props.onHide}>
              Close
            </Link>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const SectionOne = ({ data, currentIndex, setCurrentIndex, showPDF }) => {
  const [isLoading, setIsLoading] = useState(true);

  const currentFormData = data.checkListFormSections?.[currentIndex];

  const isLoaded = data.checkListFormSections ? true : false;

  if (!currentFormData) {
    return <p>No data available.</p>;
  }

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, data.checkListFormSections.length - 1)
    );
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  return (
    <>
      {isLoading && !showPDF && (
        <div className="response-item">
          <div className="fs-13 fw-bold" style={{ color: "#D57D2A" }}>
            {currentFormData?.name}
          </div>
          {currentFormData?.tasks?.map((formData, index) => (
            <div key={index}>
              <div className="response-title fs-16 fw-semibold text-black pl-25">
                {formData.tasksName}
              </div>
              <Form.Check
                inline
                label={formData.tasksName}
                name={`group${index}`}
                type="checkbox"
                id={`response-checkbox-${index}`}
              />
              <div className="pl-25">
                <input
                  className="res-input"
                  type="text"
                  value={formData?.answer ?? "No Answer Provided"}
                />
              </div>
            </div>
          ))}
        </div>
      )}
      {isLoading &&
        showPDF &&
        isLoaded &&
        data.checkListFormSections.map((inspectionitem) => (
          <div className="response-item">
            <div className="fs-13 fw-bold" style={{ color: "#D57D2A" }}>
              {inspectionitem.name}
            </div>
            {inspectionitem.map((formData, index) => (
              <div key={index}>
                <div className="response-title fs-16 fw-semibold text-black pl-25">
                  {formData.task}
                </div>
                <Form.Check
                  inline
                  label={formData.task}
                  name={`group${index}`}
                  type="checkbox"
                  id={`response-checkbox-${index}`}
                />
                <div className="pl-25">
                  <input
                    className="res-input"
                    type="text"
                    value={formData.answer}
                  />
                </div>
              </div>
            ))}
          </div>
        ))}
    </>
  );
};

export default ViewChecklistModal;
