import React from 'react';

const AssetsIcons = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
            <path d="M13.9144 6.38689C13.6379 6.11035 13.6379 5.66195 13.9144 5.38541L15.5926 3.70721C15.7216 3.57231 15.9033 3.48828 16.1047 3.48828C16.305 3.48828 16.486 3.57152 16.6148 3.70528L18.2949 5.38541C18.5715 5.66195 18.5715 6.11035 18.2949 6.38689C18.0184 6.66346 17.57 6.66346 17.2934 6.38689L16.8128 5.90629V10.9544C16.8128 11.3455 16.4958 11.6626 16.1047 11.6626C15.7135 11.6626 15.3965 11.3455 15.3965 10.9544V5.90637L14.9159 6.38689C14.6394 6.66346 14.191 6.66346 13.9144 6.38689Z" fill="#6C5B51" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4219 12.2649C10.4219 13.8595 11.7145 15.1521 13.309 15.1521H18.9008C20.4954 15.1521 21.788 13.8595 21.788 12.2649V2.88715C21.788 1.29262 20.4953 0 18.9008 0H13.309C11.7145 0 10.4219 1.29262 10.4219 2.88715V12.2649ZM13.309 13.7358C12.4967 13.7358 11.8382 13.0773 11.8382 12.2649V2.88715C11.8382 2.07482 12.4967 1.41634 13.309 1.41634H18.9008C19.7131 1.41634 20.3716 2.07485 20.3716 2.88715V12.2649C20.3716 13.0773 19.7131 13.7358 18.9008 13.7358H13.309Z" fill="#6C5B51" />
            <path d="M2.88713 18.5834C2.07484 18.5834 1.41632 17.9249 1.41632 17.1126V7.7348C1.41632 6.92248 2.07484 6.26399 2.88713 6.26399H8.52851C8.91963 6.26399 9.23668 5.94692 9.23668 5.55582C9.23668 5.1647 8.91963 4.84766 8.52851 4.84766H2.88713C1.29261 4.84766 0 6.14025 0 7.7348V17.1126C0 18.7071 1.29261 19.9998 2.88713 19.9998H8.48076C10.0753 19.9998 11.3679 18.7071 11.3679 17.1126V17.0502C11.3679 16.6591 11.0508 16.3421 10.6597 16.3421C10.2686 16.3421 9.95157 16.6591 9.95157 17.0502V17.1126C9.95157 17.9249 9.29306 18.5834 8.48076 18.5834H2.88713Z" fill="#6C5B51" />
            <path d="M3.16446 13.9431C3.441 13.6665 3.8894 13.6665 4.16594 13.9431L4.97698 14.7541V8.38395C4.97698 7.99282 5.29403 7.67578 5.68515 7.67578C6.07625 7.67578 6.39332 7.99282 6.39332 8.38395V14.7542L7.20439 13.9431C7.48093 13.6665 7.92931 13.6665 8.20588 13.9431C8.48244 14.2196 8.48244 14.668 8.20588 14.9446L6.18591 16.9645C6.17365 16.9768 6.16099 16.9886 6.14797 16.9998C6.02385 17.1071 5.86209 17.172 5.68515 17.172C5.48812 17.172 5.30988 17.0915 5.18151 16.9617L3.16446 14.9446C2.88789 14.668 2.88789 14.2196 3.16446 13.9431Z" fill="#6C5B51" />
        </svg>
    );
};

export default AssetsIcons;