import React, { useState } from "react";
import { useReactToPrint } from "react-to-print";
import DownloadIcon from "../../../Assets/Icons/DownloadIcon";
import logo from "../../../Assets/Images/color-logo.svg";
import { Table } from "react-bootstrap";
import PDFDocument from "./PDFDocument";

const QuotesDownload = () => {
  const handlePrint = useReactToPrint({
    content: () => document.getElementById("quotes-pad"),
  });
  return (
    <>
      <div className="quotes-download">
        <div className="quotes-head d-flex flex-wrap justify-content-between">
          <div className="fs-15 text-black">DOCUMENT VIEW</div>
          <div className="d-flex gap-4 pt-2 pt-lg-0">
            <button onClick={handlePrint} className="fs-15 text-black fw-bold">
              Download in PDF <DownloadIcon />
            </button>
          </div>
        </div>
        <PDFDocument />
      </div>
    </>
  );
};

export default QuotesDownload;
