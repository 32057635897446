import React from 'react';

const CloseIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M9.99984 18.9587C5.05817 18.9587 1.0415 14.942 1.0415 10.0003C1.0415 5.05866 5.05817 1.04199 9.99984 1.04199C14.9415 1.04199 18.9582 5.05866 18.9582 10.0003C18.9582 14.942 14.9415 18.9587 9.99984 18.9587ZM9.99984 2.29199C5.74984 2.29199 2.2915 5.75033 2.2915 10.0003C2.2915 14.2503 5.74984 17.7087 9.99984 17.7087C14.2498 17.7087 17.7082 14.2503 17.7082 10.0003C17.7082 5.75033 14.2498 2.29199 9.99984 2.29199Z" fill="#6C5B51" />
            <path d="M7.64147 12.9831C7.48314 12.9831 7.3248 12.9248 7.1998 12.7998C6.95814 12.5581 6.95814 12.1581 7.1998 11.9165L11.9165 7.1998C12.1581 6.95814 12.5581 6.95814 12.7998 7.1998C13.0415 7.44147 13.0415 7.84147 12.7998 8.08314L8.08314 12.7998C7.96647 12.9248 7.7998 12.9831 7.64147 12.9831Z" fill="#6C5B51" />
            <path d="M12.3581 12.9831C12.1998 12.9831 12.0415 12.9248 11.9165 12.7998L7.1998 8.08314C6.95814 7.84147 6.95814 7.44147 7.1998 7.1998C7.44147 6.95814 7.84147 6.95814 8.08314 7.1998L12.7998 11.9165C13.0415 12.1581 13.0415 12.5581 12.7998 12.7998C12.6748 12.9248 12.5165 12.9831 12.3581 12.9831Z" fill="#6C5B51" />
        </svg>
    );
};

export default CloseIcon;