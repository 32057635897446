import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DownIcon from "../../../Assets/Icons/DownIcon";

const Recurrence = () => {
  const [startDate, setStartDate] = useState(new Date());

  const ExampleCustomInput = ({ value, onClick }) => (
    <button className="example-custom-input" onClick={onClick}>
      {value} <DownIcon />
    </button>
  );
  return (
    <>
      <div className="col-lg-6">
        <div className="form-check form-switch ps-0">
          <label className="form-check-label pe-5" for="recurrence-check">
            Recurrence
          </label>
          <input
            className="form-check-input ms-1"
            type="checkbox"
            role="switch"
            id="recurrence-check"
            // checked
          />
        </div>
      </div>
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-6 align-self-center">
            <div className="select-box mt-4">
              <label>Recurrence</label>
              <select className="form-select">
                <option selected>Yearly</option>
                <option value="1">Yearly</option>
                <option value="2">Yearly</option>
                <option value="3">Yearly</option>
              </select>
            </div>
            <div className="recurrence-grid">
              <div className="form-check round-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="Every"
                />
                <label className="form-check-label" for="Every">
                  Every
                </label>
              </div>
              <div className="select-box">
                <select className="form-select">
                  <option selected>January</option>
                  <option value="1">January</option>
                  <option value="2">January</option>
                  <option value="3">January</option>
                </select>
              </div>
              <div className="select-box">
                <select className="form-select number-select">
                  <option selected>5</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
              </div>
            </div>
            <div className="recurrence-grid">
              <div className="form-check round-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="Day"
                  checked
                />
                <label className="form-check-label" for="Day">
                  Day
                </label>
              </div>
              <div className="select-box">
                <select className="form-select">
                  <option selected>Third</option>
                  <option value="1">Third</option>
                  <option value="2">Third</option>
                  <option value="3">Third</option>
                </select>
              </div>
              <div className="select-box">
                <select className="form-select">
                  <option selected>Monday</option>
                  <option value="1">Monday</option>
                  <option value="2">Monday</option>
                  <option value="3">Monday</option>
                </select>
              </div>
            </div>
            <div className="recurrence-grid">
              <div className="fs-15">Of :</div>
              <div className="select-box namath-select">
                <select className="form-select">
                  <option selected>January</option>
                  <option value="1">January</option>
                  <option value="2">January</option>
                  <option value="3">January</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-6 align-self-center ps-lg-5">
            <div className="recurrence-right">
              <div className="check-groupe">
                <div className="form-check round-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="No-Date"
                  />
                  <label className="form-check-label" for="No-Date">
                    No End Date
                  </label>
                </div>
                <div className="form-check round-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="End-After"
                  />
                  <label className="form-check-label" for="End-After">
                    End After
                  </label>
                </div>
                <div className="form-check round-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="End-By"
                    checked
                  />
                  <label className="form-check-label" for="End-By">
                    End By
                  </label>
                </div>
              </div>
              <div>
                <div className="select-box">
                  <select className="form-select gray-color number-select">
                    <option selected>10 Occurences</option>
                    <option value="1">10 Occurences</option>
                    <option value="2">10 Occurences</option>
                    <option value="3">10 Occurences</option>
                  </select>
                </div>
                <div className="pm-calender">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    customInput={<ExampleCustomInput />}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Recurrence;
