import React from "react";
import Modal from "react-bootstrap/Modal";
import { Nav, Tab } from "react-bootstrap";

const ApproveCostsSecandModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="medium-modal"
      >
        <Modal.Body style={{ padding: "28px 30px" }}>
          <div className="delate-content">
            <div className="d-md-flex align-items-center gap-4">
              <span className="fs-16 fw-bold">Approve Costs</span>
              <div className="fs-14 pt-2 pt-md-0" style={{ color: "#72777A" }}>
                Approve or reject costs - Level 2
              </div>
            </div>
            <div className="fs-16 fw-semibold" style={{ paddingTop: "30px" }}>
              Contract Type
            </div>
            <Tab.Container defaultActiveKey="first">
              <Nav variant="pills" className="existing-tab-nav row">
                <Nav.Item className="col-md-6">
                  <Nav.Link eventKey="first">
                    <div className="fs-15 fw-medium title-text">
                      Out of Contract/ Un-budgeted
                    </div>
                    <div className="fs-12 fs-italic">
                      Tagging the work as Out of Contract means this work will
                      needs to be paid for outside the contractors retainer.
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="col-md-6">
                  <Nav.Link eventKey="second">
                    <div className="fs-15 fw-medium title-text">
                      In Contract/ Budgeted
                    </div>
                    <div className="fs-12 fs-italic">
                      Tagging the work as In Contract means this work does not
                      need to be paid for and can be catered for within the
                      contractors retainer.
                    </div>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <div className="row row-gap">
                    <div className="col-md-12">
                      <label className="fw-medium pb-2 fs-14">
                        Add comments for approving or rejecting the work
                      </label>
                      <textarea
                        name=""
                        id=""
                        style={{
                          background: "#F1EFEF",
                          width: "100%",
                          border: "0",
                          height: "150px",
                          borderRadius: "5px",
                          padding: "15px",
                        }}
                        placeholder="Enter comment"
                      ></textarea>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <div className="row row-gap">
                    <div className="col-md-12">
                      <label className="fw-medium pb-2 fs-14">
                        Add comments for approving or rejecting the work
                      </label>
                      <textarea
                        name=""
                        id=""
                        style={{
                          background: "#F1EFEF",
                          width: "100%",
                          border: "0",
                          height: "150px",
                          borderRadius: "5px",
                          padding: "15px",
                        }}
                        placeholder="Enter comment"
                      ></textarea>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>

            <div
              className="button-group"
              style={{
                marginTop: "25px",
                display: "flex",
                justifyContent: "end",
                gap: "30px",
              }}
            >
              <button className="border-btn" onClick={props.onHide}>
                Cancel
              </button>
              <button className="cancel-btn" onClick={props.handleRejectCosts}>
                Reject
              </button>
              <button
                to=""
                className="delate-btn"
                onClick={() => {
                  props.handleRecallQuote();
                  props.setShowApproveCostsContent(true);
                }}
              >
                Approve L2
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ApproveCostsSecandModal;
