import React from "react";
import { Link } from "react-router-dom";

const EditSidebar = () => {
  return (
    <div className="add-pm-sidebar order-nav-content">
      <div className="fs-19 fw-bold">EDIT INSPECTION CATEGORY</div>
      <ul>
        <li><Link to="" className="active">Inspection Details</Link></li>
      </ul>
    </div>
  );
};

export default EditSidebar;
