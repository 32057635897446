/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import DownIcon from "../../Assets/Icons/DownIcon";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import { HiDotsVertical } from "react-icons/hi";
import ViewIcon2 from "../../Assets/Icons/ViewIcon2";
import EditIcon2 from "../../Assets/Icons/EditIcon2";
import DelateIcon2 from "../../Assets/Icons/DelateIcon2";
import ArrowLeft from "../../Assets/Icons/ArrowLeft";
import ArrowRight from "../../Assets/Icons/ArrowRight";
import DropdownIcon from "../../Assets/Icons/DropdownIcon";
import getUniqueProperty from "../../utls/getUniqueProprty";
import filterViaProperty from "../../utls/filterViaProperty";
import searchAllProperty from "../../utls/searchAllProperty";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import DeleteModal from "../Requests/Modal/DeleteModal";
import { get, post } from "../../Services/ApiHelper";
import { DELETE_WORKORDER, GET_CLOSED_WORKORDERS, GET_CLOSED_WORKORDERS_BY_ASSETCATEGORY, GET_CLOSED_WORKORDERS_BY_ASSIGNED_TO, GET_CLOSED_WORKORDERS_BY_END_DATE, GET_CLOSED_WORKORDERS_BY_LOCATION, GET_CLOSED_WORKORDERS_BY_PRIORITY, GET_CLOSED_WORKORDERS_BY_START_DATE, GET_ONGOING_WORKORDERS2, GET_SIMPLE_LOCATION_LIST } from "../../utls/constants";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import usefetchData from "../../hooks/useFetchData";

const ClosedWork = () => {
  const [assetCategories, setAssetCategories] = useState(""); //Asset Select
  const [selectedLocation, setSelectedLocation] = useState(""); //Location Select
  const [selectedPriority, setSelectedPriority] = useState(""); //Priority Select
  const [selectedAssigned, setSelectedAssigned] = useState(""); //Assigned Select
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1); //for pagination
  const [RequestsPerPage, setRequestsPerPage] = useState(10); //for pagination
  const [search, setSearch] = useState(""); //for search
  const [RequestsShow, setRequestsShow] = useState(false); //for modal

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const ExampleCustomInput = ({ value, onClick }) => (
    <button className="example-custom-input" onClick={onClick}>
      {value ? value : ""} <DownIcon />
    </button>
  );

  const [workOrderData, setWorkOrderData] = useState([]);
  const [debouncedSearch, setDebouncedSearch] = useState(""); 
  const [allResponses, setAllResponses] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [locationId, setLocationId] = useState(null)
  const [assetCategoryId, setAssetCategoryId] = useState(null)
  const [assignedToId, setAssignedToId] = useState(null)
  const [ticketPriorityId, setTicketPriorityId] = useState(null)
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [me, setMe] = useState(false);
  const [shortListLocations, setShortListLocations] = useState([]);


  useEffect(() => {
    get(GET_SIMPLE_LOCATION_LIST, localStorage.getItem("bearerToken"))
      .then((response) => {
        setShortListLocations(response);
      })
      .catch((err) => {});
  }, []);

  const handleDelete = async () => {
    try {
      const bearerToken = localStorage.getItem("bearerToken");
      //const apiUrl = 'https://saharadeskbackend.azurewebsites.net/api/Requests/Delete';
      const data = { id: itemIdToDelete };
      await post(DELETE_WORKORDER, data, bearerToken); // Use the post helper function
    
      toast.success("Data Deleted Successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setTimeout(() => {
      }, 3000);
      setWorkOrderData(prevRequestsData => prevRequestsData?.filter(item => item.id !== itemIdToDelete));
      setRequestsShow(!RequestsShow);
    } catch (error) {
      console.error('Error deleting item:', error);
      toast.error("An error occured", {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setTimeout(() => {
        setRequestsShow(false)
      }, 3000);
    }
  };


  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle empty date strings
  
    const date = new Date(dateString);
    const day = date?.getDate().toString().padStart(2, '0');
    const month = (date?.getMonth() + 1).toString().padStart(2, '0');
    const year = date?.getFullYear().toString().slice(-2);
  
    return `${day}/${month}/${year}`;
  };

  const style = {
    maxWidth: "120px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  }

  const { data: priority } = usefetchData(
    ["priority"],
    `/Tickets/GetAllTicketPriorities`,
    {},
    "Couldn't get priority data. Please try again!",
    true
  );

  useEffect(() => {
    const stringedStartDate = startDate?.toISOString();
    const stringedEndDate = endDate?.toISOString();
    get(
      GET_ONGOING_WORKORDERS2(
        RequestsPerPage, 
        currentPage, 
        debouncedSearch, 
        assignedToId, 
        stringedStartDate, 
        stringedEndDate, 
        ticketPriorityId,
        locationId, 
        assetCategoryId, 
        sortOrder), localStorage.getItem("bearerToken")
    ).then((response) => {
      setWorkOrderData(response["data"]);
      setIsLoading(false);
      setAllResponses(response);
    });
  }, [RequestsPerPage, currentPage, debouncedSearch, assignedToId, startDate, endDate, ticketPriorityId, locationId, assetCategoryId, sortOrder]);

  //GET_NEW_WORKORDERS_BY_LOCATION

  const handleLocationClick = (locationId) => {
    setLocationId(locationId);
    setCurrentPage(1)
  };

  const handleAssetCategoryClick = (assetCategoryId) => {
    setAssetCategoryId(assetCategoryId);
    setCurrentPage(1)
  };

  const handleAssignedClick = (assignedToId) => {
    setAssignedToId(assignedToId);
    setCurrentPage(1)
  };

  const handlePriorityClick = (ticketPriorityId) => {
    setTicketPriorityId(ticketPriorityId);
    setCurrentPage(1)
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setCurrentPage(1)
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setCurrentPage(1)
  };

  const handleSearch = () => {
    setDebouncedSearch(search);
    setCurrentPage(1)
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const debouncedSearchFunction = debounce((searchValue) => {
    setSearch(searchValue);
  }, 500); 

  useEffect(() => {
    debouncedSearchFunction(debouncedSearch);
    return () => {
      debouncedSearchFunction.cancel(); 
    };
  }, [debouncedSearch]);
  
  const nextPageCheck = allResponses?.hasNextPage
const prevPageCheck = allResponses?.hasPreviousPage

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder((prevSortOrder) =>
        prevSortOrder === "asc" ? "desc" : "asc"
      );
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const sortedRequestsData = workOrderData?.sort((a, b) => {
    if (sortBy) {
      const valueA = a[sortBy];
      const valueB = b[sortBy];
      return sortOrder === "asc"
        ? valueA?.localeCompare(valueB)
        : valueB?.localeCompare(valueA);
    }
    return 0;
  });

  // const handleLocationClick = (location) => {
  //   setSelectedLocation(location);
  // };
  // const handlePriorityClick = (priority) => {
  //   setSelectedPriority(priority);
  // };

  // const handleAssignedClick = (Assigned) => {
  //   setSelectedAssigned(Assigned);
  // };

  // const handleAssetCategoryClick = (asset) => {
  //   setAssetCategories(asset);
  // };

  //get unique asset category & filter by asset category
  const assetCategory = getUniqueProperty(workOrderData, "assetCategoryId", "assetNamesCombined");
  const locations = getUniqueProperty(workOrderData, "locationId", "locationName");
  const assignedTo = getUniqueProperty(workOrderData, "currentAssignedTeamId", "currentAssignedTeamUserName");

  const filterByAsset = filterViaProperty(
    sortedRequestsData,
    assetCategories,
    "category"
  );

  //get request locations & filter by locatio

  //get priority & filter by priority

  // Update current page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [searchTermAssetCat, setSearchTermAssetCat] = useState("");
  const [searchTermLocation, setSearchTermLocation] = useState("");
  const [searchTermPriority, setSearchTermPriority] = useState("");
  const [searchTermAssignedTo, setSearchTermAssignedTo] = useState("");

  const handleSearchChangeLocation = (e) => {
    setSearchTermLocation(e.target.value);
  };
  const handleSearchChangeAssetCat = (e) => {
    setSearchTermAssetCat(e.target.value);
  };
  const handleSearchChangePriority = (e) => {
    setSearchTermPriority(e.target.value);
  };
  const handleSearchChangeAssignedTo = (e) => {
    setSearchTermAssignedTo(e.target.value);
  };

  // Filters
  const filteredLocations = shortListLocations?.filter((location) =>
    location?.locationName?.toLowerCase().includes(searchTermLocation.toLowerCase())
  );

  const filteredAssetCategory = assetCategory?.filter((item) =>
    item?.name?.toLowerCase().includes(searchTermAssetCat.toLowerCase())
  );

  const filteredPriorities = priority?.filter((item) =>
    item?.ticketPrioritiesName?.toLowerCase().includes(searchTermPriority.toLowerCase())
  );

  const filteredAssignedTo = assignedTo?.filter((item) =>
    item?.name?.toLowerCase().includes(searchTermAssignedTo.toLowerCase())
  );

    console.log(assignedTo)

  // const handleSearchChange = (e) => {
  //   setSearchTerm(e.target.value);
  // };


  return (
    <>
      <div className="selection-grope">
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Asset Category</span>
            <span className="fs-14 d-flex align-items-center gap-1">
            {assetCategoryId === null ? "All" : assetCategory?.find(item => item.id === assetCategoryId)?.name} <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu select-menu">
          <div
              className="dropdown-search"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                color: "#c5c7cd",
                position: "relative",
                alignItems: "center",
                borderRadius: "5px",
                border: "0.5px solid #f1efef",
                background: "#fff",
                marginBottom: "10px",
                padding: "5px",
                paddingLeft: "15px",
              }}
            >
                <SearchIcon />
              <input 
               type="text"
               placeholder="Search"
               style={{ border: "none", outline: "none" }}
               className="dropDownSearch"
               value={searchTermAssetCat}
               onChange={handleSearchChangeAssetCat}
              />
            </div>
            <li
              onClick={() => {
                setAssetCategoryId(null);
                setMe(!me);
              }}
            >
              <b>All</b>
            </li>
            <hr />
            <div className="dropdown-item-content">
              {filteredAssetCategory?.map((asset, i) => (
                <li key={i} onClick={() => handleAssetCategoryClick(asset.id)} style={{ padding: "6px 0px", fontSize: "14px" }}>
                  {asset.name}
                </li>
              ))}
            </div>
          </ul>
        </div>
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Location</span>
            <span className="fs-14 d-flex align-items-center gap-1">
            {locationId === null ? "All" : shortListLocations?.find(location => location.id === locationId)?.locationName} <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu select-menu">
            <div
              className="dropdown-search"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                color: "#c5c7cd",
                position: "relative",
                alignItems: "center",
                borderRadius: "5px",
                border: "0.5px solid #f1efef",
                background: "#fff",
                marginBottom: "10px",
                padding: "5px",
                paddingLeft: "15px",
              }}
            >
                <SearchIcon />
              <input 
               type="text"
               placeholder="Search"
               style={{ border: "none", outline: "none" }}
               className="dropDownSearch"
               value={searchTermLocation}
               onChange={handleSearchChangeLocation}
              />
            </div>
            <li
              onClick={() => {
                setLocationId(null);
                setMe(1);
              }}
            >
              <b>All</b>
            </li>
            <hr />
            <div className="dropdown-item-content">
              {filteredLocations?.map((location, i) => (
                <li key={i} onClick={() => handleLocationClick(location.id)} style={{ padding: "6px 0px", fontSize: "14px" }}>
                  {location.locationName}
                </li>
              ))}
            </div>
          </ul>
        </div>
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Priority</span>
            <span className="fs-14 d-flex align-items-center gap-1">
            {ticketPriorityId === null ? "All" : priority?.find(item => item.id === ticketPriorityId)?.ticketPrioritiesName} <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu select-menu">
          <div
              className="dropdown-search"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                color: "#c5c7cd",
                position: "relative",
                alignItems: "center",
                borderRadius: "5px",
                border: "0.5px solid #f1efef",
                background: "#fff",
                marginBottom: "10px",
                padding: "5px",
                paddingLeft: "15px",
              }}
            >
                <SearchIcon />
              <input 
               type="text"
               placeholder="Search"
               style={{ border: "none", outline: "none" }}
               className="dropDownSearch"
               value={searchTermPriority}
               onChange={handleSearchChangePriority}
              />
            </div>
            <li onClick={() => {
              setTicketPriorityId(null)
              setMe(!me)
              }}>
              <b>All</b>
            </li>
            <hr />
            <div className="dropdown-item-content">
              {filteredPriorities?.map((priority, i) => (
                <li key={i} onClick={() => handlePriorityClick(priority.id)} style={{ padding: "6px 0px", fontSize: "14px" }}>
                  {priority.ticketPrioritiesName}
                </li>
              ))}
            </div>
          </ul>
        </div>

        <div className="select-dropdown">
          <div className="select-title">
            <div className="fs-13">Closed From:</div>
            <div className="format-date">
              <DatePicker
                maxDate={endDate}
                selected={startDate}
                onChange={(date) => handleStartDateChange(date)}
                customInput={<ExampleCustomInput />}
              />
            </div>
          </div>
        </div>
        <div className="select-dropdown">
          <div className="select-title">
            <div className="fs-13">Closed To:</div>
            <div className="format-date">
              <DatePicker
                minDate={startDate}
                selected={endDate}
                onChange={(date) => handleEndDateChange(date)}
                customInput={<ExampleCustomInput />}
              />
            </div>
          </div>
        </div>
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Assigned To</span>
            <span className="fs-14 d-flex align-items-center gap-1">
            {assignedToId === null ? "All" : assignedTo?.find(item => item.id === assignedToId)?.name}<DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu select-menu">
          <div
              className="dropdown-search"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                color: "#c5c7cd",
                position: "relative",
                alignItems: "center",
                borderRadius: "5px",
                border: "0.5px solid #f1efef",
                background: "#fff",
                marginBottom: "10px",
                padding: "5px",
                paddingLeft: "15px",
              }}
            >
                <SearchIcon />
              <input 
               type="text"
               placeholder="Search"
               style={{ border: "none", outline: "none" }}
               className="dropDownSearch"
               value={searchTermAssignedTo}
               onChange={handleSearchChangeAssignedTo}
              />
            </div>
            <li onClick={() => handleAssignedClick("")}>
              <b>All</b>
            </li>
            <hr />
            <div className="dropdown-item-content">
              {filteredAssignedTo?.map((assigned, i) => (
                <li key={i} onClick={() => handleAssignedClick(assigned.id)} style={{ padding: "6px 0px", fontSize: "14px" }}>
                  {assigned.name}
                </li>
              ))}
            </div>
          </ul>
        </div>

        <form action="" className="search__box">
          <button disabled type="submit">
            <SearchIcon />
          </button>
          <input
            onChange={(e) => setSearch(e.target.value)}
            onKeyPress={handleKeyPress}
            type="search"
            placeholder="Search"
          />
        </form>
      </div>
      <div className="inspection-tables-content">
        <div className="table-responsive">
          <table className="table">
            <thead>
            <tr>
                <th
                  className={
                    sortBy === "ticketRef" ? `table-sort-${sortOrder}` : "table-sort"
                  }
                  onClick={() => handleSort("ticketRef")}
                >
                  <span>Ref</span>
                </th>
                <th
                  className={
                    sortBy === "assetNamesCombined"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("assetNamesCombined")}
                >
                  <span>Asset</span>
                </th>
                <th
                  className={
                    sortBy === "locationName"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("locationName")}
                >
                  <span>Location</span>
                </th>
                <th>Title</th>
                <th
                  className={
                    sortBy === "priorityName"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("priorityName")}
                >
                  <span>Priority</span>
                </th>
                <th
                  className={
                    sortBy === "currentAssignedTeamUserName"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("currentAssignedTeamUserName")}
                >
                  <span>Assigned To</span>
                </th>
                <th
                  className={
                    sortBy === "dateSubmitted"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("dateSubmitted")}
                >
                  <span>Due Date</span>
                </th>
                <th
                  className={
                    sortBy === "dateSubmitted"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("dateSubmitted")}
                >
                  <span>Date Closed</span>
                </th>
                <th className="table-th"></th>
              </tr>
            </thead>
            {isLoading &&  <tbody>
              <tr>
                <td colSpan="9" className="text-center">
                  <Spinner animation="border"  style={{color: "#D57D2A"}} />
                </td>
              </tr>
            </tbody>}
            {workOrderData?.length ===0 &&  <tbody>
              <tr>
                <td colSpan="9" className="text-center">
                <div className="text-center" >No data found</div>
                </td>
              </tr>
            </tbody>}
            <tbody>
            {workOrderData?.map((item, i) => (
                <tr key={i}>
                  <td >{item?.ticketRef}</td>
                  <td style={style}>{item?.assetNamesCombined}</td>
                  <td style={style}>{item?.locationName}</td>
                  <td style={style}>{item?.ticketTitle}</td>
                  <td style={style}>{item?.priorityName}</td>
                  <td style={style}>{item?.currentAssignedTeamUserName}</td>
                  <td style={style}>{formatDate(item?.dueDate)}</td>
                  <td style={style}>{formatDate(item?.dateClosed)}</td>
                  <td>
                    <div className="table-last-btn d-flex align-items-center justify-content-end">
                      {item?.rejected && (
                        <span
                          style={{
                            background: "#FFBBBD",
                            color: "#F40",
                            fontSize: "12px",
                            fontWeight: "600",
                            padding: "5px 10px",
                            display: "inline-block",
                            borderRadius: "15px",
                            marginRight: "7px",
                          }}
                        >
                          {item?.rejected}
                        </span>
                      )}
                      <div className="dropdown table-edit-dropdown">
                        <button className="" data-bs-toggle="dropdown">
                          <HiDotsVertical />
                        </button>

                        <ul className="dropdown-menu dropdown-menu-end">
                          <li>
                          <Link className="dropdown-item" to={`/work-orders/work-view/${item?.id}`}>
                              <ViewIcon2 /> View
                            </Link>
                          </li>
                          {/*<li>
                            <Link
                              to="/work-orders/add-work-order"
                              className="dropdown-item"
                            >
                              <EditIcon2 /> Edit
                            </Link>
                    </li>*/}
                          <li>
                            <button
                              className="dropdown-item"
                              onClick={() => {
                                setRequestsShow(true)
                                setItemIdToDelete(item.id)
                              }}
                            >
                              <DelateIcon2 /> Delete
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="table-pagination pagination-center">
          <div className="pagination-row">
            Rows per page:
            <div className="dropdown select-dropdown">
              <button className="select-title" data-bs-toggle="dropdown">
                <span className="fs-14 d-flex align-items-center gap-2">
                  {RequestsPerPage} <DropdownIcon />
                </span>
              </button>
              <ul
                className="dropdown-menu select-menu"
                style={{ minWidth: "25px" }}
              >
                <li onClick={() => setRequestsPerPage(5)}>5</li>
                <li onClick={() => setRequestsPerPage(10)}>10</li>
                <li onClick={() => setRequestsPerPage(15)}>15</li>
                <li onClick={() => setRequestsPerPage(20)}>20</li>
              </ul>
            </div>
          </div>
          <div className="pagination-number">
            <div className="fs-14">
            {currentPage} of {allResponses?.totalPages}
            </div>
            <div className="arrow-btn">
              <button
                onClick={() =>
                 handlePageChange(currentPage - 1)
                }
              >
                {
                  prevPageCheck && <ArrowLeft />
                }
              </button>
              <button
                onClick={() =>
                  handlePageChange(currentPage + 1)
                }
              >
                {
                  nextPageCheck && <ArrowRight />
                }
              </button>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal show={RequestsShow} onHide={() => setRequestsShow(false)} onDelete={handleDelete}/>
    </>
  );
};

export default ClosedWork;

