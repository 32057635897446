/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import DownIcon from "../../Assets/Icons/DownIcon";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import { HiDotsVertical } from "react-icons/hi";
import ViewIcon2 from "../../Assets/Icons/ViewIcon2";
import EditIcon2 from "../../Assets/Icons/EditIcon2";
import DelateIcon2 from "../../Assets/Icons/DelateIcon2";
import ArrowLeft from "../../Assets/Icons/ArrowLeft";
import ArrowRight from "../../Assets/Icons/ArrowRight";
import DropdownIcon from "../../Assets/Icons/DropdownIcon";
import getUniqueProperty from "../../utls/getUniqueProprty";
import filterViaProperty from "../../utls/filterViaProperty";
import searchAllProperty from "../../utls/searchAllProperty";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { get, post } from "../../Services/ApiHelper";
import {
  DELETE_PM_TEMPLATE,
  GET_ASSET_CATEGORIES,
  GET_CATEGORY_OF_WORKS,
  GET_MAINTENANCE,
  GET_MAINTENANCE_BY_ASSET_CATEGORY,
  GET_MAINTENANCE_BY_PRIORITY,
  GET_MAINTENANCE_BY_WORK_CATEGORY,
  GET_PM_TEMPLATES,
  GET_PRIORITIES,
} from "../../utls/constants";
import { debounce, set } from "lodash";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import DeleteModal from "../Requests/Modal/DeleteModal";

const WorkScheduleTable = ({
  data,
  isLoading,
  setIsLoading,
  setAllResponses,
  allResponses,
}) => {
  const [workCategory, setWorkCategory] = useState(""); //Asset Select
  const [categoryOfWorkId, setCategoryOfWorkId] = useState(null); //Asset Select
  const [selectedAssetCategory, setSelectedAssetCategory] = useState(""); //Location Select
  const [assetCategoryId, setAssetCategoryId] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState(""); //Priority Select
  const [priorityId, setPriorityId] = useState(null); //Priority Select
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1); //for pagination
  const [RequestsPerPage, setRequestsPerPage] = useState(10); //for pagination
  const [search, setSearch] = useState(""); //for search
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [RequestsShow, setRequestsShow] = useState(false); //for modal
  const [workScheduleTableData, setWorkScheduleTableData] = useState([]); //for table data
  const [error, setError] = useState([]);
  const [allPriorities, setAllPriorities] = useState([]);
  const [allAssetCat, setAllAssetCat] = useState([]);
  const [allWorkCat, setAllWorkCat] = useState([]);
  const [reload, setReload] = useState(false);

  const token = localStorage.getItem("bearerToken");
  useEffect(() => {
    setIsLoading(true);
    setWorkScheduleTableData(data);

    get(GET_PRIORITIES, localStorage.getItem("bearerToken")).then((res) => {
      setAllPriorities(res);
      setIsLoading(false);
    });

    get(GET_ASSET_CATEGORIES, localStorage.getItem("bearerToken")).then(
      (res) => {
        setAllAssetCat(res);
        setIsLoading(false);
      }
    );

    get(GET_CATEGORY_OF_WORKS, localStorage.getItem("bearerToken")).then(
      (res) => {
        setAllWorkCat(res);
        setIsLoading(false);
      }
    );
  }, [data, debouncedSearch, RequestsPerPage, currentPage, reload]);

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder((prevSortOrder) =>
        prevSortOrder === "asc" ? "desc" : "asc"
      );
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const sortedRequestsData = workScheduleTableData.sort((a, b) => {
    if (sortBy) {
      const valueA = a[sortBy];
      const valueB = b[sortBy];
      return sortOrder === "asc"
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    }
    return 0;
  });

  // const handleAssetCategoryClick = (location) => {
  //   setSelectedAssetCategory(location);
  // };

  const handleWorkCategoryClick = (categoryOfWorkId) => {
    setIsLoading(true);

    get(
      GET_MAINTENANCE_BY_WORK_CATEGORY(
        debouncedSearch,
        currentPage,
        RequestsPerPage,
        categoryOfWorkId
      ),
      localStorage.getItem("bearerToken")
    )
      .then((response) => {
        const sortedData = response.data;

        setWorkScheduleTableData(sortedData);
        setCategoryOfWorkId(categoryOfWorkId);
        setIsLoading(false);
        setAllResponses(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error);
        setIsLoading(false);
      });
  };
  const handleAssetCategoryClick = (assetCategoryId) => {
    setIsLoading(true);

    get(
      GET_MAINTENANCE_BY_ASSET_CATEGORY(
        debouncedSearch,
        currentPage,
        RequestsPerPage,
        assetCategoryId
      ),
      localStorage.getItem("bearerToken")
    )
      .then((response) => {
        const sortedData = response.data;

        setWorkScheduleTableData(sortedData);
        setAssetCategoryId(assetCategoryId);
        setIsLoading(false);
        setAllResponses(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error);
        setIsLoading(false);
      });
  };

  const handlePriorityClick = (priorityId) => {
    setIsLoading(true);

    get(
      GET_MAINTENANCE_BY_PRIORITY(
        debouncedSearch,
        currentPage,
        RequestsPerPage,
        priorityId
      ),
      localStorage.getItem("bearerToken")
    )
      .then((response) => {
        const sortedData = response.data;

        setWorkScheduleTableData(sortedData);
        setPriorityId(priorityId);
        setIsLoading(false);
        setAllResponses(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error);
        setIsLoading(false);
      });
  };

  const debouncedSearchFunction = debounce((searchValue) => {
    setSearch(searchValue);
  }, 500);

  useEffect(() => {
    debouncedSearchFunction(debouncedSearch);
    return () => {
      debouncedSearchFunction.cancel();
    };
  }, [debouncedSearch]);

  //get unique category & filter by category
  const workCategories = getUniqueProperty(
    sortedRequestsData,
    "categoryOfWorkId",
    "categoryOfWorkName"
  );
  const filterByCategory = filterViaProperty(
    sortedRequestsData,
    workCategory,
    "work_category"
  );

  //get request locations & filter by location
  const asset_categories = getUniqueProperty(
    filterByCategory,
    "assetCategoryId",
    "assetCategoryName"
  );
  const filterByLocation = filterViaProperty(
    filterByCategory,
    selectedAssetCategory,
    "asset_category"
  );

  //get priority & filter by priority
  const priority = getUniqueProperty(
    filterByLocation,
    "id",
    "ticketPrioritiesName"
  );
  const filterByPriority = filterViaProperty(
    filterByLocation,
    selectedPriority,
    "priority"
  );

  // filter via search
  const filteredBySearch = searchAllProperty(filterByPriority, search);

  //for pagination
  const indexOfLastRequests = currentPage * RequestsPerPage;
  const indexOfFirstRequests = indexOfLastRequests - RequestsPerPage;
  const currentRequests = filteredBySearch.slice(
    indexOfFirstRequests,
    indexOfLastRequests
  );

  // Calculate total pages
  const totalPages = Math.ceil(filteredBySearch.length / RequestsPerPage);

  // Update current page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const [itemTodeleteId, setItemTodeleteId] = useState();

  const nextPageCheck = allResponses?.hasNextPage;
  const prevPageCheck = allResponses?.hasPreviousPage;

  const handleDeleteModal = async () => {
    try {
      const bearerToken = localStorage.getItem("bearerToken");

      const data = { id: itemTodeleteId };

      await post(DELETE_PM_TEMPLATE, data, bearerToken);

      toast.success("Data Deleted Successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setTimeout(() => {}, 3000);
      setWorkScheduleTableData((prevRequestsData) =>
        prevRequestsData?.filter((item) => item.id !== itemTodeleteId)
      );
      setRequestsShow(!RequestsShow);
    } catch (error) {
      console.error("Error deleting item:", error);
      toast.error("An error occured", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setTimeout(() => {
        setRequestsShow(false);
      }, 3000);
    }
  };

  const style = {
    maxWidth: "120px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };

  return (
    <>
      <div className="selection-grope mt-2">
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Category of Work</span>
            <span className="fs-14 d-flex align-items-center gap-1">
              {categoryOfWorkId === null
                ? "All"
                : allAssetCat?.find((item) => item.id === categoryOfWorkId)
                    ?.assetCategoryName}{" "}
              <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu select-menu">
            <li
              onClick={() => {
                setCategoryOfWorkId(null);
                setReload(!reload);
              }}
            >
              <b>All</b>
            </li>
            <hr />
            <div className="dropdown-item-content">
              {allAssetCat.map((category, i) => (
                <li
                  style={{ whiteSpace: "nowrap" }}
                  key={i}
                  onClick={() => handleWorkCategoryClick(category.id)}
                >
                  {category.assetCategoryName}
                </li>
              ))}
            </div>
          </ul>
        </div>
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Asset Category</span>
            <span className="fs-14 d-flex align-items-center gap-1">
              {assetCategoryId === null
                ? "All"
                : allAssetCat?.find((item) => item.id === assetCategoryId)
                    ?.assetCategoryName}{" "}
              <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu select-menu">
            <li
              onClick={() => {
                setAssetCategoryId(null);
                setReload(!reload);
              }}
            >
              <b>All</b>
            </li>
            <hr />
            <div className="dropdown-item-content">
              {allAssetCat.map((location, i) => (
                <li
                  key={i}
                  onClick={() => handleAssetCategoryClick(location.id)}
                >
                  {location.assetCategoryName}
                </li>
              ))}
            </div>
          </ul>
        </div>
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Priority</span>
            <span className="fs-14 d-flex align-items-center gap-1">
              {priorityId === null
                ? "All"
                : allPriorities?.find((item) => item.id === priorityId)
                    ?.ticketPrioritiesName}{" "}
              <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu select-menu">
            <li
              onClick={() => {
                setPriorityId(null);
                setReload(!reload);
              }}
            >
              <b>All</b>
            </li>
            <hr />
            <div className="dropdown-item-content">
              {allPriorities.map((priority, i) => (
                <li key={i} onClick={() => handlePriorityClick(priority.id)}>
                  {priority.ticketPrioritiesName}
                </li>
              ))}
            </div>
          </ul>
        </div>

        <form action="" className="search__box">
          <button disabled type="submit">
            <SearchIcon />
          </button>
          <input
            onChange={(e) => setDebouncedSearch(e.target.value)}
            type="search"
            placeholder="Search"
          />
        </form>
      </div>
      <div className="inspection-tables-content">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th
                  className={
                    sortBy === "templateName"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("templateName")}
                >
                  <span>Name</span>
                </th>
                <th
                  className={
                    sortBy === "categoryOfWorkName"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("categoryOfWorkName")}
                >
                  <span>Category of Work</span>
                </th>
                <th
                  className={
                    sortBy === "assetCategoryName"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("assetCategoryName")}
                >
                  <span>Asset Category</span>
                </th>
                <th>Recurs Every</th>
                <th
                  className={
                    sortBy === "priorityName"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("priorityName")}
                >
                  <span>Priority</span>
                </th>
                <th
                  className={
                    sortBy === "estimatedHours"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("estimatedHours")}
                >
                  <span>Time Est. (Hrs)</span>
                </th>
                <th className="table-th"></th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan="9" className="text-center">
                    <Spinner animation="border" style={{ color: "#D57D2A" }} />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {currentRequests?.length === 0 && (
                  <tr style={{ width: "100%" }}>
                    <td
                      colSpan="15"
                      className="text-center"
                      style={{ width: "100%" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItem: "center",
                        }}
                      >
                        No data found
                      </div>
                    </td>
                  </tr>
                )}

                {currentRequests.map((item, i) => (
                  <tr key={i}>
                    <td>{item.templateName}</td>
                    <td style={style}>{item.categoryOfWorkName}</td>
                    <td style={style}>{item.assetCategoryName}</td>
                    <td style={style}>{item.recurringPeriod}(s) </td>
                    <td style={style}>{item.priorityName}</td>
                    <td style={style}>{item.estimatedHours}</td>
                    <td>
                      <div className="table-last-btn d-flex align-items-center justify-content-end">
                        {item.rejected && (
                          <span
                            style={{
                              background: "#FFBBBD",
                              color: "#F40",
                              fontSize: "12px",
                              fontWeight: "600",
                              padding: "5px 10px",
                              display: "inline-block",
                              borderRadius: "15px",
                              marginRight: "7px",
                            }}
                          >
                            {item.rejected}
                          </span>
                        )}
                        <div className="dropdown table-edit-dropdown">
                          <button className="" data-bs-toggle="dropdown">
                            <HiDotsVertical />
                          </button>

                          <ul className="dropdown-menu dropdown-menu-end">
                            <li>
                              <Link
                                className="dropdown-item"
                                to={`/work-schedule-templates/${item.id}`}
                              >
                                <ViewIcon2 /> View
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={`/work-schedule-templates/edit-template/${item.id}`}
                                className="dropdown-item"
                              >
                                <EditIcon2 /> Edit
                              </Link>
                            </li>
                            <li>
                              <button
                                className="dropdown-item"
                                onClick={() => {
                                  // Modal(item.id);
                                  setRequestsShow(true);
                                  setItemTodeleteId(item.id);
                                }}
                              >
                                <DelateIcon2 /> Delete
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>

        <div className="table-pagination pagination-center">
          <div className="pagination-row">
            Rows per page:
            <div className="dropdown select-dropdown">
              <button className="select-title" data-bs-toggle="dropdown">
                <span className="fs-14 d-flex align-items-center gap-2">
                  {RequestsPerPage} <DropdownIcon />
                </span>
              </button>
              <ul
                className="dropdown-menu select-menu"
                style={{ minWidth: "25px" }}
              >
                <li onClick={() => setRequestsPerPage(5)}>5</li>
                <li onClick={() => setRequestsPerPage(10)}>10</li>
                <li onClick={() => setRequestsPerPage(15)}>15</li>
                <li onClick={() => setRequestsPerPage(20)}>20</li>
              </ul>
            </div>
          </div>
          <div className="pagination-number">
            <div className="fs-14">
              {currentPage} of {allResponses?.totalPages}
            </div>
            <div className="arrow-btn">
              <button onClick={() => handlePageChange(currentPage - 1)}>
                {prevPageCheck && <ArrowLeft />}
              </button>
              <button onClick={() => handlePageChange(currentPage + 1)}>
                {nextPageCheck && <ArrowRight />}
              </button>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        show={RequestsShow}
        itemId={itemTodeleteId}
        onHide={() => setRequestsShow(false)}
        onDelete={handleDeleteModal}
      />
    </>
  );
};

export default WorkScheduleTable;
