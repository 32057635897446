import React, { useContext, useEffect, useState,useRef } from "react";
import { Link } from "react-router-dom";
import ArrowLeft from "../../../Assets/Icons/ArrowLeft";
import ArrowRight from "../../../Assets/Icons/ArrowRight";
import RoutineInspectionCategoryContext from "../../../Context/Inspections/RoutineInspectionCategoryContext";
import { GET_FORM_AND_SECTION_TASK_ANSWERS } from "../../../utls/constants";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import { useReactToPrint } from "react-to-print";


const ComponentToPrint = React.forwardRef((props, ref) => {
  const CompanyDetails = JSON.parse(localStorage.getItem('company'));
  return(
  <div ref={ref}>
    <style type="text/css" media="print">
      {"@page { margin: 20px 20px 20px 25px; }}"}
    </style>
    <div className="fs-15 fw-bold" style={{ color: "#6C5B51", textAlign: "center", margin: "10px 0px 5px 0px" }}>
      <div>{CompanyDetails?.companyName+" "+CompanyDetails?.street+" "+CompanyDetails?.address1+","+CompanyDetails?.city+","+CompanyDetails?.country}</div>
      <div>{props.data.formSectionName}</div>
    </div>
    <hr></hr>
    <div className="response-list-content">
    <SectionOne data={props.data} currentIndex={props.currentIndex} setCurrentIndex={props.setCurrentIndex} showPDF={true}/>
    </div>
    <br/><br/>
    <div className="fs-15 fw-bold" style={{ color: "#6C5B51", marginLeft: "20px", display: "flex"}}>
      <div style={{flexGrow: 1}}>
        <div>Created By: ..................................</div>
        <br/>
        <div>Date: ..............................................</div>
        <br/>
        <div>Sign: ..............................................</div>
      </div>
      <div style={{flexGrow: 1}}>
      <div>Created By: ..................................</div>
        <br/>
        <div>Date: ..............................................</div>
        <br/>
        <div>Sign: ..............................................</div>
      </div>
    </div>
  </div>
  );
});

const ResponsesContent = ({inspection}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Continuous  Checks Checklist',
   });
  

  const {ongoingInspection} = useContext(RoutineInspectionCategoryContext);



  const transactionId = ongoingInspection.routineInspectionId
  const checklistId = ongoingInspection.checkListFormId

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const token = localStorage.getItem("bearerToken");
  const totalSections = data?.formDetails?.length;
  const handleNextClick = () => {
    if(currentIndex < (totalSections - 1)){
      setCurrentIndex((prevIndex) => Math.min(prevIndex + 1));
    }
  };

  const handleBackClick = () => {
    if(currentIndex > 0){
      setCurrentIndex(() => Math.max(currentIndex - 1));
    }
  };

  useEffect(() => {
    fetch(
      GET_FORM_AND_SECTION_TASK_ANSWERS(transactionId,checklistId),
      {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => res.json())
      .then((responseData) => {
        const result = responseData["data"];
        setIsLoading(false);
        setData(result);
      });
  }, []);


  return (
    <>
      <div className="order-request washroom-right-content responses-cont">
        <div className="washroom-title">
          <div className="fs-13 fw-medium">CONTINUOUS CHECK RESPONSE</div>
          <div className="response-details d-flex gap-4">
            <li onClick={handlePrint}><Link to="#">Print PDF</Link></li>
          </div>
        </div>
        <div className="response-checklist">
          <div className="fs-15 fw-bold" style={{ color: "#6C5B51" }}>{inspection.checklistFormName}</div>
          <div className="response-pagination d-flex align-items-center">
            <div className="page-number fs-14">
              Page {currentIndex + 1} of {totalSections}
            </div>
            <div className="pagination-btn">
              {currentIndex > 0 ?
              (<button className="back-btn" onClick={handleBackClick} >
                Back <ArrowLeft />
              </button>) :
              (<button className="back-btn" onClick={handleBackClick}disabled={true} >
                Back <ArrowLeft />
               </button>)
              }
              {currentIndex < (totalSections - 1) ? 
              (<button className="next-btn" onClick={handleNextClick} >
                <ArrowRight /> Next
              </button>) : 
              (<button className="next-btn" onClick={handleNextClick} disabled={true}>
              <ArrowRight /> Next
              </button>) 
              }             
            </div>
          </div>
        </div>
        <div className="response-list-content">
         <SectionOne data={data} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} showPDF={false}/>
        </div>
        <div style={{ display: "none" }}><ComponentToPrint data={data} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} ref={componentRef} /></div>

      </div>
    </>
  );
};

export default ResponsesContent;
