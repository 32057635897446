import React from 'react';

const CloudAddIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47" fill="none">
            <path d="M14.7991 37.2814H11.119C5.484 36.8789 2.9541 32.5472 2.9541 28.6947C2.9541 24.8422 5.48403 20.4914 11.0232 20.108C11.809 20.0314 12.499 20.6447 12.5565 21.4497C12.614 22.2355 12.0199 22.9255 11.2149 22.983C7.4966 23.2514 5.8291 26.0497 5.8291 28.7139C5.8291 31.378 7.4966 34.1764 11.2149 34.4447H14.7991C15.5849 34.4447 16.2366 35.0964 16.2366 35.8822C16.2366 36.668 15.5849 37.2814 14.7991 37.2814Z" fill="#292D32" />
            <path d="M32.4507 37.2816C32.4124 37.2816 32.3932 37.2816 32.3549 37.2816C31.5691 37.2816 30.8408 36.63 30.8408 35.8441C30.8408 35.02 31.4541 34.4066 32.2591 34.4066C34.6166 34.4066 36.7249 33.5825 38.3733 32.1066C41.3633 29.5 41.5549 25.7433 40.7499 23.0983C39.9449 20.4725 37.7024 17.4633 33.7924 16.9841C33.1599 16.9075 32.6615 16.4283 32.5465 15.7958C31.7799 11.1958 29.3074 8.01414 25.5508 6.86414C21.6791 5.65664 17.1557 6.84497 14.3382 9.79663C11.5974 12.6525 10.9649 16.6583 12.5557 21.0666C12.824 21.8141 12.4408 22.6383 11.6933 22.9066C10.9458 23.175 10.1216 22.7916 9.85326 22.0441C7.91743 16.6391 8.79911 11.4641 12.2683 7.82247C15.8141 4.10414 21.5066 2.62829 26.3941 4.12329C30.8791 5.50329 34.0415 9.20246 35.1915 14.3391C39.1015 15.2208 42.2449 18.1916 43.4907 22.2933C44.8516 26.7591 43.6249 31.3591 40.2707 34.2725C38.1432 36.1891 35.364 37.2816 32.4507 37.2816Z" fill="#292D32" />
            <path d="M23.4998 42.9167C19.6473 42.9167 16.044 40.8658 14.0699 37.55C13.859 37.2242 13.6482 36.8408 13.4757 36.4192C12.824 35.0583 12.479 33.5058 12.479 31.8958C12.479 25.82 17.424 20.875 23.4998 20.875C29.5757 20.875 34.5207 25.82 34.5207 31.8958C34.5207 33.525 34.1757 35.0583 33.4857 36.4767C33.3324 36.8408 33.1216 37.2242 32.8916 37.5883C30.9557 40.8658 27.3523 42.9167 23.4998 42.9167ZM23.4998 23.75C19.0148 23.75 15.354 27.4108 15.354 31.8958C15.354 33.0842 15.6032 34.1958 16.0823 35.2117C16.2357 35.5375 16.3698 35.8058 16.5231 36.055C17.9798 38.5275 20.644 40.0417 23.4807 40.0417C26.3173 40.0417 28.9815 38.5275 30.419 36.0933C30.5915 35.8058 30.7449 35.5375 30.8599 35.2692C31.3774 34.215 31.6265 33.1033 31.6265 31.915C31.6457 27.4108 27.9848 23.75 23.4998 23.75Z" fill="#292D32" />
            <path d="M22.4074 35.2302C22.0432 35.2302 21.6791 35.096 21.3916 34.8085L19.494 32.911C18.9382 32.3552 18.9382 31.4352 19.494 30.8793C20.0499 30.3235 20.9699 30.3235 21.5257 30.8793L22.4458 31.7993L25.5124 28.9627C26.1066 28.426 27.0074 28.4643 27.544 29.0393C28.0807 29.6143 28.0424 30.5343 27.4674 31.071L23.3849 34.8468C23.0974 35.096 22.7524 35.2302 22.4074 35.2302Z" fill="#292D32" />
        </svg>
    );
};

export default CloudAddIcon;