import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom";
import {get, post } from "../../../Services/ApiHelper";
import { toast } from "react-toastify";
import { set } from "lodash";
import { EXTEND_RFQ_TIME } from "../../../utls/constants";
import { RequestDetailsContext } from "../../../Context/Inspections/WorkOrderContext";
import { Spinner } from "react-bootstrap";
import { GET_REQUEST_SUMMARY_BY_ID } from "../../../utls/constants";
import Astericks from "../../Common/Asterick";

const ExtendRFQTimeModal = (props) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [hourError, setHourError] = useState(null);
  const [extendingTime, setExtendTime] = useState(0);
  const { request, setRequest } = useContext(RequestDetailsContext);

  useEffect(() => {
    setExtendTime(0);
    setHourError(null);
  }, [props.show]);

  const validateInputs = () => {
    let isValid = true;

    setHourError(null);

    if (!extendingTime || extendingTime === 0) {
      setHourError("Required *");
      isValid = false;
    }

    return isValid;
  };
  const handleExtension = () => {
    const isValid = validateInputs();
    if (!isValid) {
      return;
    }
    setIsLoading(true);
    post(
      EXTEND_RFQ_TIME,
      { requestId: id, hoursExtension: extendingTime },
      localStorage.getItem("bearerToken")
    )
      .then((response) => {
        if (response.succeeded) {
          setExtendTime(0);
          get(GET_REQUEST_SUMMARY_BY_ID(id), localStorage.getItem("bearerToken"))
            .then((result) => {
              setRequest(result);
            })
            .catch((error) => {
              console.log(error);
          });
          props.onHide();
          setExtendTime(0);
          setIsLoading(false);
          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });
        } else {
          setExtendTime(0);
          props.onHide();
          setIsLoading(false);
          toast.error(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });
        }
      })
      .catch((error) => {
        setExtendTime(0);
        props.onHide();
        setIsLoading(false);
      });
  };
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="">
            <span className="fs-16 fw-bold ">Extend RFQ/ Diagnosis Time</span>
            {/* <span className="fs-14 ps-3">End the quoting session</span> */}
          </div>
          <div className="fs-14 pt-4" style={{ color: "#72777A" }}>
            Add more time to get quotes
          </div>
          <div className="pt-4">
            <div className="fs-14 fw-medium text-black pb-3">
              Current Deadline
            </div>
            <div className="fs-20 fw-bold" style={{ color: "#D57D2A" }}>
              {request?.requestQuoteList?.length > 0
                  ? new Date(
                      request?.requestQuoteList[0]?.deadLineDate
                    ).toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    })
                  : "Not Available"}
            </div>
          </div>
          <div className="pt-4">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">Time Extension (Hours)<Astericks/></label>
                {hourError && <p style={{ color: "red" }}>{hourError}</p>}
            </div>
            <input
              className="modal-input-box"
              type="number"
              min="0"
              value={extendingTime}
              onChange={(e) => setExtendTime(e.target.value)}
              style={{
                background: "#F1EFEF",
                width: "100%",
                border: "0",
                height: "50px",
                borderRadius: "5px",
                padding: "0 15px",
              }}
              placeholder="Enter the time extension in hours"
              onKeyPress={(event) => {
                // Prevent typing of negative sign
                if (event.key === '-') {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            {isLoading ? (
              <Spinner
                animation="border"
                style={{
                  color: "#D57D2A",
                  //position: "absolute",
                  //right: "10%",
                  //top: "10%",
                }}
              />
            ) : (
              <button
                className="delate-btn"
                onClick={() => {
                  handleExtension();
                }}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ExtendRFQTimeModal;
