import React from "react";
import "../Components/InspectionCategory/InspectionCategory.scss";
import NewRequestHeader from "../Components/Requests/NewRequest/NewRequestHeader";
import NewRequestNavbar from "../Components/Requests/NewRequest/NewRequestNavbar";
import { Link } from "react-router-dom";
import NewRequestContent from "../Components/Requests/NewRequest/NewRequestContent";

const NewRequest = () => {
  return (
    <div className="work-orders-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <NewRequestHeader />
          </div>
          <div className="col-lg-12">
            <NewRequestNavbar />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="add-pm-area create__area">
            <div className="add-pm-sidebar order-nav-content">
                <div className="fs-19 fw-bold">NEW REQUEST</div>
                <div className="order-nav-lists">
                  <Link className="active">Request Details</Link>
                </div> 
              </div>
              <NewRequestContent />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewRequest;
