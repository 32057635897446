import React, { useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { FaCircleCheck } from "react-icons/fa6";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { REQUEST_ARRIVAL } from "../../../utls/constants";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";

const RequestPtwModal = (props) => {
  const [loading, setLoading] = useState(false);
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);
  const onSubmit = () => {
    const body = {
      ticketId: ticketSummary?.workOrderSummary?.ticketId,
    };
    setLoading(true);
    fetch(REQUEST_ARRIVAL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: "Bearer " + localStorage.getItem("bearerToken"),
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.succeeded === true) {
          //   setTicketSummary({
          //     ...ticketSummary,
          //     workOrderSummary: {
          //       ...ticketSummary.workOrderSummary,
          //       currentTicketActivity: "ARRIVAL REQUESTED",
          //     },
          //   });
          setLoading(false);
          props.onHide();
          toast.success("Site Arrival Requested Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });
        } else {
          setLoading(false);
          props.onHide();
          toast.error("Site Arrival Request failed", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });
        }
      });
  };
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="">
            <span className="fs-16 fw-bold ">REQUEST PERMIT TO WORK</span>
            <span className="fs-14 ps-3">
              Confirm request for a permit to work
            </span>
          </div>
          <div
            className="delate-warning"
            style={{
              background: "#F1EFEF",
              height: "267px",
              display: "grid",
              alignItems: "center",
              textAlign: "center",
              padding: "20px",
              marginTop: "30px",
              borderRadius: "5px",
            }}
          >
            <div>
              <FaCircleCheck style={{ color: "#D57D2A", fontSize: "70px" }} />
              <div
                className="fs-20 fw-medium "
                style={{
                  lineHeight: "1.4",
                  marginTop: "10px",
                  color: "#72777A",
                }}
              >
                Do you want to request for a permit to work for this work order.
              </div>
            </div>
          </div>
          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            {loading ? (
              <button className="delate-btn" disabled={loading}>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Requesting...
              </button>
            ) : (
              <button className="delate-btn" onClick={onSubmit}>
                Request
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RequestPtwModal;
