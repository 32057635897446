import React, { useEffect, useState } from "react";
import "../Components/PreventiveWorks/PreventiveWorks.scss";
import { Link, NavLink } from "react-router-dom";
import ScheduleTemplateDetails from "../Components/WorkScheduleTemplate/EditWorkScheduleTemplate/TemplateDetails";
import { useParams } from "react-router-dom";
import { get } from "../Services/ApiHelper";
import { GET_PM_TEMPLATE_BY_ID } from "../utls/constants";
import { TemplateDetailsContext } from "../Context/Inspections/WorkOrderContext";
const EditTemplate = () => {
  const { id } = useParams();
  const bearerToken = localStorage.getItem("bearerToken");
  const templateDto = () => ({
    details: {},
    checklists: [],
    projectedParts: [],
  });
  const [template, setTemplate] = useState(templateDto);
  let details = {};
  useEffect(() => {
    get(GET_PM_TEMPLATE_BY_ID(id), bearerToken)
      .then((response) => {
        details.id = response.templateId;
        details.templateName = response.templateName;
        details.description = response.description;
        details.assetCategoryId = response.assetCategoryId;
        details.assetCategoryName = response.assetCategoryName;
        details.calendarTypeId = response.calendarTypeId;
        details.calendarTypeName = response.calendarTypeName;
        details.categoryOfWorkName = response.categoryOfWorkName;
        details.categoryOfWorkId = response.categoryOfWorkId;
        details.estimatedHours = response.estimatedHours;
        details.priorityName = response.priorityName;
        details.recursEvery = response.recursEvery;
        details.ticketPriorityId = response.ticketPriorityId;
        console.log("response", response);
        setTemplate({
          details: details,
          checklists: response?.checkLists,
          projectedParts: response?.projectedParts,
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  }, []);

  return (
    <TemplateDetailsContext.Provider
      value={{
        template,
        setTemplate,
      }}
    >
      <div className="work-orders-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="work-header">
                <div className="fs-20">Edit Schedule Template</div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="other-nav">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/work-schedule-templates">Work</Link>
                  </li>
                  <li>
                    <Link to="/work-schedule-templates">
                      Schedule Templates
                    </Link>
                  </li>
                  <li>
                    <Link>Edit Template</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="order-details-area ">
                <div className="order-nav-content">
                  <div className="fs-19 fw-bold">EDIT SCHEDULE TEMPLATE</div>
                  <div className="order-nav-lists">
                    <NavLink
                      to={`/work-schedule-templates/edit-template/${id}`}
                      end
                    >
                      Template Details
                    </NavLink>
                  </div>
                </div>
                <div className="work-order-contents">
                  <ScheduleTemplateDetails />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TemplateDetailsContext.Provider>
  );
};

export default EditTemplate;
