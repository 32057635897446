import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import { Dropdown } from "react-bootstrap";
import usefetchData from "../../hooks/useFetchData";
import { flattenObject } from "../../utls/flattenObject";
import qs from "qs";

// Set your Mapbox access token here
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API;

const LocationMapView = () => {
  const mapContainerRef = useRef(null); // Ref for the map container
  const mapRef = useRef(null); // Ref for the map instance
  const [filters, setFilters] = useState({});
  const [selectLocation, setSelectLocation] = useState("Location Grouping");
  const [locationId, setLocationId] = useState(null);
  const [search, setSearch] = useState("");
  const [selectLocationSearch, setSelectLocationSearch] = useState(""); // Search input state

  // Fetch paginated locations
  const { data, isLoading, isError } = usefetchData(
    ["locations-data", filters],
    `/Locations/GetPaginatedLocations`,
    {
      params: {
        ...filters,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    },
    "Failed to get locations-data",
    true,
    true
  );

  // Fetch location regions for filtering
  const { data: locationData, isLoading: isLoadingLocation } = usefetchData(
    ["location-regions"],
    `/Locations/GetAllRegions`,
    {},
    "Error getting location data",
    true
  );

  // Initialize the map
  useEffect(() => {
    if (mapContainerRef.current && !mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [36.8219, -1.2921], // Default center (Nairobi, Kenya)
        zoom: 10,
      });
    }

    // Clean up on unmount
    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, []);

  // Add markers to the map based on fetched location data
  useEffect(() => {
    if (mapRef.current && data?.data) {
      // Remove any existing markers
      document.querySelectorAll('.mapboxgl-marker').forEach(marker => marker.remove());

      data.data.forEach((location) => {
        const { longitude, latitude } = location;
        // Validate longitude and latitude
        if (
          longitude >= -180 &&
          longitude <= 180 &&
          latitude >= -90 &&
          latitude <= 90
        ) {
          new mapboxgl.Marker()
            .setLngLat([longitude, latitude])
            .addTo(mapRef.current);
        }
      });
    }
  }, [data]);

  // Handle filter change to refetch data
  useEffect(() => {
    const filterObject = {
      filter: {
        RegionId: locationId,
      },
      pageSize: 1000,
      search,
    };

    setFilters(flattenObject(filterObject));
  }, [locationId, search]);

  // Handle location filter selection
  const handleSelectLocation = (eventKey) => {
    setSelectLocation(eventKey);
    setSelectLocationSearch("");
    const selectedRegion = locationData?.find(
      (region) => region.name === eventKey
    );
    setLocationId(selectedRegion?.id || null); // Set location ID for filtering
  };

  // Filter dropdown data based on search input
  const filterCatagoryWork = locationData?.filter((region) =>
    region.regionName.toLowerCase().includes(selectLocationSearch.toLowerCase())
  ) || [];

  return (
    <>
      <div className="location-map-content">
        <div className="map-content">
          <div className="map-dropdown">
            <Dropdown className="select__form" onSelect={handleSelectLocation}>
              <Dropdown.Toggle
                className={`select-title ${
                  selectLocation !== "Location Grouping" ? "selected" : ""
                }`}
                style={{ height: "35px" }}
              >
                {selectLocation}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <form className="dropdown-search">
                  <button disabled>
                    <SearchIcon />
                  </button>
                  <input
                    onChange={(e) => setSelectLocationSearch(e.target.value)}
                    value={selectLocationSearch}
                    type="text"
                    placeholder="Search"
                  />
                </form>
                <div className="dropdown-item-content">
                  {filterCatagoryWork.map((item, index) => (
                    <Dropdown.Item key={index} eventKey={item.regionName}>
                      {item.regionName}
                    </Dropdown.Item>
                  ))}
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <form action="" className="search__box">
              <button disabled type="submit">
                <SearchIcon />
              </button>
              <input
                type="search"
                placeholder="Search"
                onChange={(e) => setSearch(e.target.value)}
              />
            </form>
          </div>
          <div
            ref={mapContainerRef}
            className="map-container"
            style={{ width: "100%", height: "500px" }}
          ></div>
        </div>
        <ul className="map-details-info">
          <li>
            Grouping:{" "}
            <strong className="fw-regular">
              {selectLocation !== "Location Grouping" ? selectLocation : "ALL"}
            </strong>
          </li>
        </ul>
      </div>
    </>
  );
};

export default LocationMapView;
