import React, { useMemo } from "react";
import {
  Category,
  ChartComponent,
  Inject,
  Legend,
  SeriesCollectionDirective,
  SeriesDirective,
  StackingAreaSeries,
  Tooltip,
} from "@syncfusion/ej2-react-charts";
import { format } from "date-fns";

const PMCMChart = ({
  workOrder,
  maxScheduled,
  filters,
  categories,
  categoryOfWorkIds,
  maxCM
}) => {
  const workOrderData = workOrder?.data || [];
  const interval = maxScheduled/10
  const max = Math.max(maxCM, maxScheduled)
  const roundedUpToNext10 = Math.ceil(max / 10) * 10;
  const primaryXAxis = { valueType: "Category", title: "Calendar Period" };
  const primaryYAxis = {
    interval: roundedUpToNext10/10,
    maximum: Math.ceil(max / interval) * interval ,
    title: "PM Work",
  };

  const tooltip = { enable: true };

  const formatDate = (dateString) => {
    return format(new Date(dateString), "dd/MM/yyyy");
  };

  const getCategoryNames = () => {
    return categoryOfWorkIds.map(
      (id) =>
        categories.find((category) => category.id === id)?.categoryOfWorkName ??
        ""
    );
  };

  const formattedFilters = useMemo(() => {
    if (!filters) return null;

    return {
      startDate: formatDate(filters["filter.StartDate"]),
      endDate: formatDate(filters["filter.EndDate"]),
      calendarGrouping: filters["filter.CalendarGrouping"],
      categoryOfWork: getCategoryNames(
        filters["filter.CategoryOfWorkIds"]
      ).join(", "),
    };
  }, [filters]);

  const transformedData = workOrderData?.map((data) => ({
    calendarPeriod: data.calendarPeriod,
    completedCM: data.completedCM,
    completedPM: data.completedPM,
  }));

  return (
    <div id="pm-cm-performance-graph">
       <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyItems: "start",
        }}
      >
        <h3>PM vs CM Performance Report</h3>
        <div
          className=""
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gridColumnGap: "20px",
            gridRowGap: "4px",
            fontSize: "14px",
            fontWeight: 400,
            marginBottom: "10px",
            width: "80%",
          }}
        >
          <p style={{ color: "gray" }}>Start Date: <span style={{fontWeight: "bold", color: "black"}}>{formattedFilters?.startDate || ""}</span> </p>
          <p style={{ color: "gray" }}>Category of Work: <span style={{fontWeight: "bold", color: "black"}}>{formattedFilters?.categoryOfWork || "All"}</span></p>
          <p style={{ color: "gray" }}>End Date: <span style={{fontWeight: "bold", color: "black"}}>{formattedFilters?.endDate || ""}</span></p>
          <p style={{ color: "gray" }}>Calendar Grouping: <span style={{fontWeight: "bold", color: "black"}}>{formattedFilters?.calendarGrouping || "None"}</span></p>
        </div>
        <hr />
      </div>
       <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "50vh",
      }}
    >
      <div style={{ width: "100%" }}>
        <ChartComponent
          id="charts"
          primaryXAxis={{...primaryXAxis, labelIntersectAction: "Rotate45"}}
          primaryYAxis={primaryYAxis}
          title="PM CM Performance Report"
          subTitle="Completed PM vs Completed CM"
          legendSettings={{ visible: true }}
          width="1200px"
          height="400px"
          tooltip={tooltip}
        >
          <Inject services={[StackingAreaSeries, Category, Legend, Tooltip]} />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={transformedData}
              xName="calendarPeriod"
              yName="completedPM"
              name="Completed PM"
              type="StackingArea"
              fill="rgba(88, 69, 57, 0.87)"
            />
            <SeriesDirective
              dataSource={transformedData}
              xName="calendarPeriod"
              yName="completedCM"
              name="Completed CM"
              type="StackingArea"
              fill="#D57D2A"
            />
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>
    </div>
    </div>
   
  );
};

export default PMCMChart;
