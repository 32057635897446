import React,{useContext} from "react";
import { Link } from "react-router-dom";
import RoutineInspectionCategoryContext from "../../../Context/Inspections/RoutineInspectionCategoryContext";
const F3WashroomNavbar = () => {
  const {routineInspectionCategoryData} = useContext(RoutineInspectionCategoryContext);
  const {inspectionDetailData} = useContext(RoutineInspectionCategoryContext);

  return (
    <div className="other-nav">
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/inspection-category">
            Inspection Categories
          </Link>
        </li>
        {/* <li>
          <Link to="/inspection-category/washroom" state={routineInspectionCategoryData} > Washroom</Link>
        </li> */}
        <li>
          <Link>{inspectionDetailData.routineInspectionName}</Link>
        </li>
      </ul>
    </div>
  );
};

export default F3WashroomNavbar;
