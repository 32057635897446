import React, { useState } from "react";
import { Link } from "react-router-dom";
import AddIcon from "../../../Assets/Icons/AddIcon";
import { Modal } from "bootstrap/dist/js/bootstrap.bundle";
import ModalInspection from "../ModalInspection";
const F3WashroomHeader = () => {
  // const [inspectionShow, setInspectionShow] = useState(false);
  // const [Item, setItem] = useState({});
  return (
    <>
      <div className="work-header">
        <div className="fs-20">Inspection Details</div>
        {/* <button
          onClick={() => {
            setInspectionShow(true);
            setItem(null);
          }}
        >
          <AddIcon /> Add Inspection
        </button> */}
      </div>
      {/* <ModalInspection
        show={inspectionShow}
        onHide={() => setInspectionShow(false)}
        item={null}
      /> */}
    </>
  );
};

export default F3WashroomHeader;
