import React from "react"; 
 const CreateHeader = ({item}) => { 
  return (
    <>
      <div className="work-header">
        <div className="fs-20">{item ===null ? "Create":"Edit" }  Inspection Category</div>
        
      </div>
     </>
  );
};

export default CreateHeader;
