import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Spinner, Tab } from "react-bootstrap";
import useMutateData from "../../../hooks/useMutateData";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const ApproveCostsModal = ({ data, onHide, show }) => {
  const [comments, setComments] = useState("");

  const { id } = useParams();

  const ticketId = Number(id);

  const { mutate: approve, isLoading: isApproving } = useMutateData({
    url: `Tickets/ApproveTicketCosting`,
    method: "POST",
    onSuccessfullMutation: (data) => {
      if (data?.data?.succeeded === true) {
        toast.success(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        onHide();
      } else {
        toast.error(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
    successMessage: `Successfully created a work order`,
    errorMessage: "Work order creation failed",
    queryKeysToInvalidate: [["costing-summary", ticketId]],
  });

  const handleApproveCosts = () => {
    const requestBody = {
      costingBasisGroupId: data?.costingBasisGroupId,
      comments: comments,
      isApproved: true,
    };
    approve(requestBody);
  };

  const { mutate: reject, isLoading: isRejecting } = useMutateData({
    url: `Tickets/ApproveTicketCosting`,
    method: "POST",
    onSuccessfullMutation: (data) => {
      if (data?.data?.succeeded === true) {
        toast.success(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        onHide();
      } else {
        toast.error(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
    successMessage: `Successfully created a work order`,
    errorMessage: "Work order creation failed",
    queryKeysToInvalidate: [["costing-summary", ticketId]],
  });

  const handleRejectCosts = () => {
    const requestBody = {
      costingBasisGroupId: data?.costingBasisGroupId,
      comments: comments,
      isApproved: false,
    };
    reject(requestBody);
  };

  return (
    <>
      <Modal
        onHide={onHide}
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="medium-modal"
      >
        <Modal.Body style={{ padding: "28px 30px" }}>
          <div className="delate-content">
            <div className="d-md-flex align-items-center gap-4">
              <span className="fs-16 fw-bold">Approve Costs</span>
              <div className="fs-14 pt-2 pt-md-0" style={{ color: "#72777A" }}>
                Approve or reject costs
              </div>
            </div>
            <div className="fs-16 fw-semibold" style={{ paddingTop: "30px" }}>
              Approval summary
            </div>
            <div className="fs-14 fw-normal" style={{ paddingTop: "10px" }}>
              Approval stage: {data?.approvalLevelStateName}
            </div>
            <div
              className="fs-16 fw-light"
              style={{ paddingTop: "10px", paddingBottom: "30px" }}
            >
              Previous stage approval by: {data?.approvedRejectedBy}
            </div>
            <Tab.Container defaultActiveKey="">
              <Tab.Content>
                <Tab.Pane eventKey="first"></Tab.Pane>
                <Tab.Pane eventKey="second"></Tab.Pane>
              </Tab.Content>
              <div className="row row-gap">
                <div className="col-md-12">
                  <label className="fw-medium pb-2 fs-14">
                    Add comments for approving or rejecting the work
                  </label>
                  <textarea
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    style={{
                      background: "#F1EFEF",
                      width: "100%",
                      border: "0",
                      height: "150px",
                      borderRadius: "5px",
                      padding: "15px",
                    }}
                    placeholder="Enter comment"
                  ></textarea>
                </div>
              </div>
            </Tab.Container>

            <div
              className="button-group"
              style={{
                marginTop: "25px",
                display: "flex",
                justifyContent: "end",
                gap: "30px",
              }}
            >
              <button className="border-btn" onClick={onHide}>
                Cancel
              </button>

              <button className="cancel-btn" onClick={handleRejectCosts}>
                {isRejecting ? (
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Reject"
                )}
              </button>
              <button to="" className="delate-btn" onClick={handleApproveCosts}>
                {isApproving ? (
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Approve"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ApproveCostsModal;
