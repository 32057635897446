import React, { useContext, useEffect } from "react";
import InspectionDetailContext from "../../../Context/Inspections/InspectionDetailsContext";
import RoutineInspectionCategoryContext from "../../../Context/Inspections/RoutineInspectionCategoryContext";
import { get } from "../../../Services/ApiHelper";

const F3WashroomSidebar = ({ category }) => {
  const { inspectionDetailData } = useContext(RoutineInspectionCategoryContext);

  return (
    <div className="order-sidebar-content pe-2">
      <div className="fs-13 fw-medium">INSPECTION DETAILS</div>
      <div className="fs-16 fw-bold pt-3 pb-2" style={{ color: "#6C5B51" }}>
        {category.routineInspectionName}
      </div>
      <div className="fs-20 fw-bold " style={{ color: "#D57D2A" }}>
        {category.checklistFormName}
      </div>
      <div className="washroom-sidebar mt-3">
        <div>
          {/* <div className="text-ii">
            <div className="fs-14 fw-medium">Location: </div>
            <div className="fs-14 fw-medium text-black">UAP Towers</div>
          </div> */}
          <div className="text-ii">
            <div className="fs-14 fw-medium"> Occurs Every:</div>
            <div className="fs-14 fw-medium text-black">
              {inspectionDetailData.frequencyType}
            </div>
          </div>
          <div className="text-ii">
            <div className="fs-14 fw-medium">Equipment Category:</div>
            {/* <div className="fs-14 fw-medium">Asset Category:</div> */}
            <div className="fs-14 fw-medium text-black">
              {category.assetCategoryName}
            </div>
          </div>
        </div>
        <div>
          <div className="text-ii">
            <div className="fs-14 fw-medium">Starts on</div>
            <div className="fs-14 fw-medium text-black">
              {new Date(category.startsOn).toLocaleDateString("en-GB")}
            </div>
          </div>
          <div className="text-ii">
            <div className="fs-14 fw-medium">Ends on</div>
            <div className="fs-14 fw-medium text-black">
              {new Date(category.endsOn).toLocaleDateString("en-GB")}
            </div>
          </div>
          {/* <div className="text-ii">
            <div className="fs-14 fw-medium">Last Inspection</div>
            <div className="fs-14 fw-medium text-black">
            {new Date(inspectionDetailData.endsOn).toLocaleDateString('en-GB')}
            </div>
          </div> */}
        </div>
      </div>
      <div className="text-ii">
        <div className="fs-14 fw-medium"> Description: </div>
        <div className="fs-14 fw-medium text-black">
          {inspectionDetailData.routineInspectionDescription}
        </div>
      </div>
    </div>
  );
};

export default F3WashroomSidebar;
