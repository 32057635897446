/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useContext,useEffect } from "react";
import { HiDotsVertical } from "react-icons/hi";
import ViewIcon2 from "../../Assets/Icons/ViewIcon2";
import EditIcon2 from "../../Assets/Icons/EditIcon2";
import DelateIcon2 from "../../Assets/Icons/DelateIcon2";
import ModalInspection from "./ModalInspection";
import ArrowLeft from "../../Assets/Icons/ArrowLeft";
import ArrowRight from "../../Assets/Icons/ArrowRight";
import DropdownIcon from "../../Assets/Icons/DropdownIcon";
import { Link, useNavigate } from "react-router-dom";
import RoutineInspectionCategoryContext from "../../Context/Inspections/RoutineInspectionCategoryContext";
import InspectionDetailContext from "../../Context/Inspections/InspectionDetailsContext";
import { Modal, Alert, Toast, Spinner } from "react-bootstrap";
import { DELETE_INSPECTION_CATEGORY } from "../../utls/constants";
import { update } from "../../Services/ApiHelper";
import { toast } from "react-toastify";

const InspectionTable = ({ inspectionData, allResponses, updatePageSize, updatePageNumber, pageSize, pageNumber }) => {
  const [currentPage, setCurrentPage] = useState(1); //for pagination
  const [inspectionPerPage, setInspectionPerPage] = useState(10); //for pagination
  const [inspectionShow, setInspectionShow] = useState(false); //for modal
  const [sortBy, setSortBy] = useState(null); //for sorting
  const [sortOrder, setSortOrder] = useState("asc"); //for sorting
  const [deleteModal, setDeleteModal] = useState(false); //for modal
  const [id, setId] = useState(0); //for modal
  const [isLoading, setIsLoading] = useState(true); //for modal
  const [item, setItem] = useState(null);

  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState(null);
  const [showAlert, setShowAlert] = useState(false);



  const navigate = useNavigate();

  useEffect(() => {
    if (inspectionData) {
      setIsLoading(false);
    }
  }, [inspectionData]);

  const handleDelete = (id) => {
    update(DELETE_INSPECTION_CATEGORY, {
      "id": id,
    },localStorage.getItem("bearerToken"))

    .then((data) => {
      if (data.succeeded === true) {
        setDeleteModal(false);
        window.scrollTo(0, 0);
        toast.success(data.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        setDeleteSuccessMessage(data.messages[0]);
      } else {
        setDeleteModal(false);
        window.scrollTo(0, 0);
       toast.error(data.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setDeleteErrorMessage(data.messages[0]);
      }
    })
      .catch((error) => {
        setDeleteErrorMessage("Failed to delete Inspection");
        console.error("Error deleting inspection:", error);
      });
   
  };

  //for sorting
  const handleSort = (column) => {
    
    if (sortBy === column) {
      setSortOrder((prevSortOrder) =>
        prevSortOrder === "asc" ? "desc" : "asc"
      );
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const sortedInspectionData = Array.isArray(inspectionData) ? inspectionData.sort((a, b) => {
    if (sortBy) {
      const valueA = a[sortBy];
      const valueB = b[sortBy];
      return sortOrder === "asc"
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    }
    return 0;
  }) : []


  //for pagination
  const indexOfLastInspection = currentPage * inspectionPerPage;
  const indexOfFirstInspection = indexOfLastInspection - inspectionPerPage;
  const currentInspection = sortedInspectionData?.slice(
    indexOfFirstInspection,
    indexOfLastInspection
  );

  // Calculate total pages
  const totalPages = Math.ceil(sortedInspectionData?.length / inspectionPerPage);

  // Update current page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const {setCategoryData} = useContext(RoutineInspectionCategoryContext);
  const {setInspectionData} = useContext(RoutineInspectionCategoryContext)
  const toggleModal = () => {
    setDeleteModal(!deleteModal);
  };


  const handleLinkClick = (item) => {
    localStorage.setItem('inspectionData', JSON.stringify(item));

    setInspectionData(item);
  };

  const handleEditCategory = (item) => {
    localStorage.setItem('editedInspectionCat', JSON.stringify(item));
    navigate("/inspection-category/editCategoryInspection");
  };

  const handlePageSizeChange = (size) => {
    updatePageSize(size);
  };

  const handlePageNumberChange = (size) => {
    updatePageNumber(size);
  };

  const nextPageCheck = allResponses?.hasNextPage
const prevPageCheck = allResponses?.hasPreviousPage


  

  return (
    <>
    {/* <Toast onClose={() => setShowAlert(false)} show={showAlert} delay={3000} autohide/>
    {deleteSuccessMessage && (
      <Alert show={showAlert} delay={3000} variant="success">{deleteSuccessMessage}</Alert>)}
    {deleteErrorMessage && (
      <Alert show={showAlert} delay={3000} variant="danger">{deleteErrorMessage}</Alert>)} */}
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th
                className={
                  sortBy === "name" ? `table-sort-${sortOrder}` : "table-sort"
                }
                onClick={() => handleSort("assetCategoryName")}
              >
                <span>Name</span>
              </th>
              <th className="table-th">Description</th>
              <th
                className={
                  sortBy === "routineInspectionDescription"
                    ? `table-sort-${sortOrder}`
                    : "table-sort"
                }
                onClick={() => handleSort("routineInspectionDescription")}
              >
                <span>Checklist</span>
              </th>
              <th
                className={
                  sortBy === "assetCategoryName"
                    ? `table-sort-${sortOrder}`
                    : "table-sort"
                }
                onClick={() => handleSort("assetCategoryName")}
              >
                <span>Equipment Category</span>
                {/* <span>Asset Category</span> */}
              </th>
              <th className="table-th">Recurs Every</th>
              <th className="table-th">Starts</th>
              <th className="table-th">Ends</th>
              <th className="table-th"></th>
            </tr>
          </thead>
          {isLoading &&  <tbody>
              <tr>
                <td colSpan="9" className="text-center">
                  <Spinner animation="border"  style={{color: "#D57D2A"}} />
                </td>
              </tr>
            </tbody>}
            {currentInspection?.length ===0 &&  <tbody>
              <tr>
                <td colSpan="9" className="text-center">
                <div className="text-center" >No data found</div>
                </td>
              </tr>
            </tbody>}
          <tbody >
            
            {currentInspection.map((item) => (
              <tr key={item.id}>
                <td className="table-ellipsis" >{item.routineInspectionName}</td>
                <td className="table-ellipsis">{item.routineInspectionDescription}</td>
                <td className="table-ellipsis">{item.checklistFormName}</td>
                <td className="table-ellipsis">{item.assetCategoryName}</td>
                <td className="table-ellipsis">{item.frequencyType}</td>
                <td className="table-ellipsis">{new Date(item.startsOn).toLocaleDateString('en-GB')}</td>
                <td className="table-ellipsis">{new Date(item.endsOn).toLocaleDateString('en-GB')}</td>
                <td>
                  <div className="dropdown table-edit-dropdown">
                    <button className="" data-bs-toggle="dropdown">
                      <HiDotsVertical />
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <Link
                          className="dropdown-item"
                          to= {`/inspection-category/inspections/${item.id}`}
                          onClick={() => handleLinkClick(item)}
                        >
                          <ViewIcon2 /> View
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/inspection-category/create"
                          state={{ item }}
                          // onClick={() => handleEditCategory(item)}
                          
                         
                        >
                          <EditIcon2 /> Edit
                        </Link>
                      </li>
                      {/* <li>
                        <button
                          className="dropdown-item"
                          onClick={() => setInspectionShow(true)}
                        >
                          <EditIcon2 /> Edit
                        </button>
                      </li> */}
                      <li
                      onClick={() => {
                        setId(item.id);
                        toggleModal();
                      }}
                      >
                        <Link className="dropdown-item" >
                          <DelateIcon2 
                          /> Delete
                        </Link>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            )
            )}
          </tbody>
        </table>
      </div>
      
      <Modal
        show={deleteModal}
        onHide={toggleModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Inspection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this inspection?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={()=>{
            // alert('cancel')
           
            toggleModal()
          }}>
            Cancel
          </button>
          <button  className="btn btn-danger" onClick={() => handleDelete(id)}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
      
      <div className="table-pagination pagination-center">
        <div className="pagination-row">
          Rows per page:
          <div className="dropdown select-dropdown">
            <button className="select-title" data-bs-toggle="dropdown">
              <span className="fs-14 d-flex align-items-center gap-2">
                {pageSize} <DropdownIcon />
              </span>
            </button>
            <ul
              className="dropdown-menu select-menu"
              style={{ minWidth: "25px" }}
            >
              <li onClick={() => handlePageSizeChange(5)}>5</li>
              <li onClick={() => handlePageSizeChange(10)}>10</li>
              <li onClick={() => handlePageSizeChange(15)}>15</li>
              <li onClick={() => handlePageSizeChange(20)}>20</li>
            </ul>
          </div>
        </div>
        <div className="pagination-number">
          <div className="fs-14">
            {pageNumber} of {allResponses?.totalPages}
          </div>
          <div className="arrow-btn">
            <button
              onClick={() => handlePageNumberChange(pageNumber - 1)
              }
            >
                {
                  prevPageCheck && <ArrowLeft />
                }
            </button>
            {/* <button
              onClick={() =>
                totalPages !== pageNumber && updatePageNumber(pageNumber + 1)
              }
            >
              {
                  nextPageCheck && <ArrowRight />
                }
            </button> */}
            <button
                onClick={() =>{
                  handlePageNumberChange(pageNumber + 1)
                }
                }
              >
                {
                  nextPageCheck && <ArrowRight />
                }
              </button>
          </div>
        </div>
      </div>

      <ModalInspection
        show={inspectionShow}
        onHide={() => setInspectionShow(false)}
      />
      <Modal>
        <Modal.Header>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete </p>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={toggleModal} >Close</button>
          <button>Save changes</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InspectionTable;

