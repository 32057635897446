import React, { useContext, useState } from "react";
import { useReactToPrint } from "react-to-print";
import CheckIcon from "../../../Assets/Icons/CheckIcon";
import DownloadIcon from "../../../Assets/Icons/DownloadIcon";
import ApproveQuoteModal from "../../WorkOrders/Modal/ApproveQuoteModal";
import PDFDocument from "./PDFDocument";
import { RequestDetailsContext } from "../../../Context/Inspections/WorkOrderContext";

const QuotesDownload = (props) => {
  const [ApproveShow, setApproveShow] = useState(false);
  const { request, setRequest } = useContext(RequestDetailsContext);
  const [quoteId, setQuoteId] = useState(0);
  const handlePrint = useReactToPrint({
    content: () => document.getElementById("quotes-pad"),
  });
  const claims = (claimValue) => {
    //Check if value is not null or undefined
    if (localStorage.getItem("claims")) {
      // Parse the string as a JSON array
      const claimsArray = JSON.parse(localStorage.getItem("claims"));
      if (claimsArray.includes(claimValue)) {
        // The string is present in the array
        return true;
      } else {
        return false;
      }
    } else {
      // Handle the case where the "claims" key is not present in local storage
      return false;
    }
  };

  return (
    <>
      <div className="quotes-download">
        <div className="quotes-head d-flex flex-wrap justify-content-between">
          <div className="fs-15 text-black">DOCUMENT VIEW</div>
          <div className="d-flex gap-4 pt-2 pt-lg-0">
          {(claims("Can_Approve_Quotes") && props?.quote?.isSubmitted === true && request?.requestSummaryDetailDto?.workOrderId === null) && (
            <button
              className="fs-15 text-black fw-bold"
              onClick={() => {setQuoteId(props?.quote?.quoteId); setApproveShow(true);}}
            >
              Approve Quote <CheckIcon />
            </button>
          )}
            <button onClick={handlePrint} className="fs-15 text-black fw-bold">
              Download in PDF <DownloadIcon />
            </button>
          </div>
        </div>
      </div>
      <ApproveQuoteModal
        quoteId = {quoteId}
        show={ApproveShow}
        onHide={() => setApproveShow(false)}
      />
      <PDFDocument quote={props?.quote} />
    </>
  );
};

export default QuotesDownload;
