import React from "react";
import "../Components/AddPMCaregory/AddPMCaregory.scss";
import AddPMHeader from "../Components/AddPMCaregory/AddPMHeader";
import AddPmNavbar from "../Components/AddPMCaregory/AddPmNavbar";
import AddPMSidebar from "../Components/AddPMCaregory/AddPMSidebar";
import AddPMCaregoryContent from "../Components/AddPMCaregory/AddPMCaregoryContent";

const AddPMCaregory = () => {
  return (
    <div className="work-orders-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <AddPMHeader/>
          </div>
          <div className="col-lg-12">
            <AddPmNavbar/>    
        </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="add-pm-area">
              <AddPMSidebar/>
              <AddPMCaregoryContent/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPMCaregory;
