import React from "react";
import "../Components/PreventiveWorks/PreventiveWorks.scss";
import { Link, NavLink, Outlet } from "react-router-dom";

const CreateTemplate = () => {
  return (
    <div className="work-orders-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="work-header">
              <div className="fs-20">Create Schedule Template</div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="other-nav">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/work-schedule-templates">Work</Link>
                </li>
                <li>
                  <Link to="/work-schedule-templates">Schedule Templates</Link>
                </li>
                <li>
                  <Link>Create Template</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="order-details-area ">
              <div className="order-nav-content">
                <div className="fs-19 fw-bold">SCHEDULE TEMPLATE</div>
                <div className="order-nav-lists">
                  <NavLink to="/work-schedule-templates/create-template" end>
                    Template Details
                  </NavLink>
                  <NavLink to="/work-schedule-templates/create-template/summary">
                    Summary
                  </NavLink>
                </div>
              </div>
              <div className="work-order-contents">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTemplate;
