import React, { useContext, useState } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { post } from "../../../Services/ApiHelper";
import { TICKET_CONFIRM_REJECT_ON_HOLD } from "../../../utls/constants";
import { set } from "lodash";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";

const AcceptHoldModal = (props) => {
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [comment, setComment] = useState("");
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);

  const handleAccept = async (action) => {
    action === "accept" ? setIsLoading2(true) : setIsLoading1(true);
    const bearerToken = localStorage.getItem("bearerToken");

    const data = {
      ticketId: props.ticketId,
      comment: comment,
      isOnHoldConfirmed: action === "accept" ? true : false,
    };

    post(TICKET_CONFIRM_REJECT_ON_HOLD, data, bearerToken)
      .then((response) => {
        if (response.succeeded === true) {
          if (action === "accept") {
            setTicketSummary({
              ...ticketSummary,
              workOrderSummary: {
                ...ticketSummary.workOrderSummary,
                currentTicketActivity: "ON HOLD",
              },
            });
          } else {
            setTicketSummary({
              ...ticketSummary,
              workOrderSummary: {
                ...ticketSummary.workOrderSummary,
                currentTicketActivity: "ON HOLD REJECTED",
              },
            });
          }
          props.onHide();
          action === "accept" ? setIsLoading2(false) : setIsLoading1(false);
          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          props.onHide();
          action === "accept" ? setIsLoading2(false) : setIsLoading1(false);
          toast.error(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((error) => {
        action === "accept" ? setIsLoading2(false) : setIsLoading1(false);
        props.onHide();
        toast.error("An error occured", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .finally(() => {
        props.onHide();
        action === "accept" ? setIsLoading2(false) : setIsLoading1(false);
        setComment("");
      });
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="">
            <span className="fs-16 fw-bold ">Accept Hold Work</span>
            <span style={{ color: "#72777A" }} className="fs-14 ps-3">
              Accept or reject putting work on hold
            </span>
          </div>
          <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
            <div className="col-md-12">
              <label className="fw-medium pb-2 fs-14">
                Add comments for accepting or rejecting putting work on hold
              </label>
              <textarea
                name=""
                onChange={(e) => setComment(e.target.value)}
                value={comment}
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "150px",
                  borderRadius: "5px",
                  padding: "15px 15px",
                }}
                placeholder="Enter comment"
              ></textarea>
            </div>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="border-btn" onClick={props.onHide}>
              Cancel
            </button>
            {isLoading1 ? (
              <Spinner
                animation="border"
                style={{
                  color: "#D57D2A",
                }}
              />
            ) : (
              <button
                className="cancel-btn"
                onClick={() => handleAccept("reject")}
              >
                Reject Hold
              </button>
            )}
            {isLoading2 ? (
              <Spinner
                animation="border"
                style={{
                  color: "#D57D2A",
                }}
              />
            ) : (
              <button
                className="delate-btn"
                onClick={() => handleAccept("accept")}
              >
                Accept Hold
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AcceptHoldModal;
