import React, { useState } from "react";
import DownIcon from "../../Assets/Icons/DownIcon"; 
import MoreVertical from "../../Assets/Icons/MoreVertical";
import AddIcon from "../../Assets/Icons/AddIcon";
import AddScheduleModal from "./AddScheduleModal";
const AddPMHeader = () => {
  const [scheduleShow, setScheduleShow] = useState(false);

  return (
    <>
      <div className="work-header">
        <div className="fs-20">Schedule Categories</div>
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-15 d-flex align-items-center gap-2">
              <MoreVertical /> Actions <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu dropdown-menu-end select-menu">
            <li className="text-center">
              <button onClick={() => setScheduleShow(true)}>
                <AddIcon /> Add Schedule
              </button>
            </li>
          </ul>
        </div>
      </div>
      <AddScheduleModal show={scheduleShow} onHide={() => setScheduleShow(false)} />
    </>
  );
};

export default AddPMHeader;
