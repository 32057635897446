import React from "react";
import Modal from "react-bootstrap/Modal";
import { PiWarningCircleFill } from "react-icons/pi";
import { post } from "../../../Services/ApiHelper";
import {
  DELETE_PM_PROJECTED_PART,
  DELETE_PM_TEMPLATE,
} from "../../../utls/constants";
import { toast } from "react-toastify";
import { TemplateDetailsContext } from "../../../Context/Inspections/WorkOrderContext";

const DeleteModal = (props) => {
  const { template, setTemplate } = React.useContext(TemplateDetailsContext);
  const handleDelete = (event) => {
    if (
      props.fromScheduleTemplate != null &&
      props.fromScheduleTemplate === "fromScheduleTemplate"
    ) {
      let projectedParts = template.projectedParts?.filter((item) => {
        return item.id !== props.id;
      });

      post(
        DELETE_PM_PROJECTED_PART,
        { id: props.id },
        localStorage.getItem("bearerToken")
      ).then((data) => {
        if (data.succeeded) {
          setTemplate({
            ...template,
            projectedParts: [...projectedParts],
          });
          toast.success(data.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(data.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });

      props.onHide();
    } else if (
      props.fromScheduleTemplate != null &&
      props.fromScheduleTemplate === "fromScheduleTemplateCreate"
    ) {
      var localStorageParts = JSON.parse(
        localStorage.getItem("selectedProjectedPartsNew")
      );

      var filteredParts = localStorageParts.filter((item) => {
        return item.partId !== props.id;
      });
      localStorage.setItem(
        "selectedProjectedPartsNew",
        JSON.stringify(filteredParts)
      );
    } else {
      event.preventDefault();
      post(
        DELETE_PM_TEMPLATE,
        { id: props.id },
        localStorage.getItem("bearerToken")
      ).then((data) => {
        if (data.succeeded === true) {
          // setDeleteModal(false);
          window.scrollTo(0, 0);
          toast.success(data.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          // setDeleteSuccessMessage(data.messages[0]);
        } else {
          // setDeleteModal(false);
          window.scrollTo(0, 0);
          toast.error(data.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    }
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="">
            <span className="fs-16 fw-bold ">Delete</span>
            <span className="fs-14 ps-3">
              Confirm deletion of selected item
            </span>
          </div>
          <div
            className="delate-warning"
            style={{
              background: "#F1EFEF",
              height: "267px",
              display: "grid",
              alignItems: "center",
              textAlign: "center",
              padding: "20px",
              marginTop: "30px",
              borderRadius: "5px",
            }}
          >
            <div>
              <PiWarningCircleFill
                style={{ color: "#D57D2A", fontSize: "70px" }}
              />
              <div
                className="fs-20 fw-medium "
                style={{ lineHeight: "1.4", marginTop: "10px" }}
              >
                Please confirm that you want to DELETE <br /> the selected item
              </div>
            </div>
          </div>
          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            <button
              className="delate-btn"
              onClick={(e) => {
                handleDelete(e);
                props.onHide();
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteModal;
