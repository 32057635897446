import React from 'react';

const CompletedIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M8.00016 1.33301C4.32683 1.33301 1.3335 4.32634 1.3335 7.99967C1.3335 11.673 4.32683 14.6663 8.00016 14.6663C11.6735 14.6663 14.6668 11.673 14.6668 7.99967C14.6668 4.32634 11.6735 1.33301 8.00016 1.33301ZM11.1868 6.46634L7.40683 10.2463C7.3135 10.3397 7.18683 10.393 7.0535 10.393C6.92016 10.393 6.7935 10.3397 6.70016 10.2463L4.8135 8.35967C4.62016 8.16634 4.62016 7.84634 4.8135 7.65301C5.00683 7.45967 5.32683 7.45967 5.52016 7.65301L7.0535 9.18634L10.4802 5.75968C10.6735 5.56634 10.9935 5.56634 11.1868 5.75968C11.3802 5.95301 11.3802 6.26634 11.1868 6.46634Z" fill="#D57D2A" />
        </svg>
    );
};

export default CompletedIcon;