import React from "react";
import "../Components/InspectionCategory/InspectionCategory.scss";
 
import EditHeader from "../Components/InspectionCategory/Edit/EditHeader";
import EditNavbar from "../Components/InspectionCategory/Edit/EditNavbar";
import EditSidebar from "../Components/InspectionCategory/Edit/EditSidebar";
import EditCategoryInspection from "../Components/InspectionCategory/Edit/EditCategoryInspection";


const EditInspectionCategory = () => {
  return (
    <div className="work-orders-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <EditHeader />
          </div>
          <div className="col-lg-12">
            <EditNavbar />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="add-pm-area Edit__area">
              <EditSidebar />
              <EditCategoryInspection />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditInspectionCategory;