import React, { useState } from "react";
import { Dropdown, Modal, Nav, Tab } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import DownIcon from "../../../Assets/Icons/DownIcon";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";

const ReturnAssetModal = (props) => {
  const [returnDate, setReturnDate] = useState(null);
  const [timeSelected, setTimeSelected] = useState(false);
  const [selectedHour, setSelectedHour] = useState(12);
  const [selectedMinute, setSelectedMinute] = useState(0);
  const [period, setPeriod] = useState("AM");
  const ExampleCustomInput = ({ value, onClick }) => (
    <button
      className={`select-date-colander ${value ? "active" : ""}`}
      onClick={onClick}
    >
      {value ? value : "Select date"} <DownIcon />
    </button>
  );

  const toggleTimeDropdown = () => {
    setTimeSelected(true);
  };

  const handleHourChange = (increment) => {
    setSelectedHour((prevHour) => {
      let newHour = prevHour + increment;
      if (newHour > 12) newHour = 1;
      if (newHour < 1) newHour = 12;
      return newHour;
    });
  };

  const handleMinuteChange = (increment) => {
    setSelectedMinute((prevMinute) => {
      let newMinute = prevMinute + increment;
      if (newMinute >= 60) newMinute = 0;
      if (newMinute < 0) newMinute = 59;
      return newMinute;
    });
  };

  const handlePeriodChange = () => {
    setPeriod((prevPeriod) => (prevPeriod === "AM" ? "PM" : "AM"));
  };

  const handleHourInputChange = (e) => {
    const value = Math.max(1, Math.min(12, Number(e.target.value)));
    setSelectedHour(value);
  };

  const handleMinuteInputChange = (e) => {
    const value = Math.max(0, Math.min(59, Number(e.target.value)));
    setSelectedMinute(value);
  };

  const formatTime = () => {
    const formattedHour = selectedHour.toString().padStart(2, "0");
    const formattedMinute = selectedMinute.toString().padStart(2, "0");
    return `${formattedHour}:${formattedMinute} ${period}`;
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal add-asset-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-md-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Return Asset</span>
            <div className="fs-14 pt-2 pt-md-0" style={{ color: "#72777A" }}>
              Return asset from user or team
            </div>
          </div>

          <div className="fs-16 fw-semibold" style={{ paddingTop: "30px" }}>
            Return Time
          </div>
          <Tab.Container defaultActiveKey="first">
            <Nav variant="pills" className="existing-tab-nav row">
              <Nav.Item className="col-md-6">
                <Nav.Link eventKey="first">
                  <div className="fs-15 fw-medium title-text">
                    Use current date and time
                  </div>
                  <div className="fs-13 fs-italic">
                    This option will automatically pick the current date and
                    time on returning the asset. A previous date cannot be
                    picked
                  </div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="col-md-6">
                <Nav.Link eventKey="second">
                  <div className="fs-15 fw-medium title-text">
                    Specify return date and time
                  </div>
                  <div className="fs-13 fs-italic">
                    This option will allows you to choose the date and time the
                    asset was returned. A previous date can be picked.
                  </div>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="first"></Tab.Pane>
              <Tab.Pane eventKey="second">
                <div className="row row-gap-3">
                  <div className="col-md-6">
                    <div className="return-calender d-grid">
                      <label className="fw-medium pb-2 fs-14">
                        Return Date
                      </label>
                      <DatePicker
                        selected={returnDate}
                        onChange={(date) => setReturnDate(date)}
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Select date"
                        /* minDate={new Date()} */
                        customInput={<ExampleCustomInput />}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <label className="fw-medium pb-2 fs-14">
                        Return Time
                      </label>
                      <Dropdown className="return-dropdown">
                        <Dropdown.Toggle
                          className={`time-check ${
                            timeSelected ? "forTime" : ""
                          }`}
                        >
                          <div onClick={toggleTimeDropdown}>
                            {timeSelected ? formatTime() : "Select time"}
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <div className="time-dropdown">
                            <div className="d-grid">
                              <button onClick={() => handleHourChange(1)}>
                                <FaChevronUp />
                              </button>
                              <input
                                type="number"
                                value={selectedHour}
                                onChange={handleHourInputChange}
                              />
                              <button onClick={() => handleHourChange(-1)}>
                                <FaChevronDown />
                              </button>
                            </div>
                            <div className="d-grid">
                              <button onClick={() => handleMinuteChange(1)}>
                                <FaChevronUp />
                              </button>
                              <input
                                type="number"
                                value={selectedMinute}
                                onChange={handleMinuteInputChange}
                              />
                              <button onClick={() => handleMinuteChange(-1)}>
                                <FaChevronDown />
                              </button>
                            </div>
                            <div className="d-grid">
                              <button onClick={handlePeriodChange}>
                                <FaChevronUp />
                              </button>
                              <input type="text" value={period} readOnly />
                              <button onClick={handlePeriodChange}>
                                <FaChevronDown />
                              </button>
                            </div>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            <Link to="" className="delate-btn" onClick={props.onHide}>
              Return
            </Link>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ReturnAssetModal;
