import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import useMutateData from "../../../hooks/useMutateData";
import { toast } from "react-toastify";

const EditIdentityInfoThreeModal = ({ show, onHide }) => {
  const [assetMake, setAssetMake] = useState("");

  // add new asset make mutation
  const { mutate, isLoading } = useMutateData({
    url: `/Assets/AddAssetMake`,
    method: "POST",
    onSuccessfullMutation: (data) => {
      console.log("Recall mutation data", data);
      if (data?.data?.succeeded === true) {
        toast.success(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        onHide(); // Hide edit modal
      } else {
        toast.error(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
    successMessage: `Successfully updated basic info`,
    errorMessage: "Basic info editing failed",
    queryKeysToInvalidate: [["asset-make"]],
  });

  const handleAddNewMake = () => {
    const requestBody = {
      assetMake: assetMake,
    };
    mutate(requestBody);
  };

  return (
    <>
      <Modal
        // {...props}
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="medium-modal add-asset-modal"
      >
        <Modal.Body style={{ padding: "28px 30px" }}>
          <div className="delate-content">
            <div className="d-md-flex align-items-center gap-4">
              <span className="fs-16 fw-bold">Edit Identity Info</span>
              <div className="fs-14 pt-2 pt-md-0" style={{ color: "#72777A" }}>
                Add new asset make
              </div>
            </div>
            <div className="mt-4 pt-2 row row-gap-4 modal-forms-content">
              <div className="col-md-12">
                <label className="fw-medium fs-14 pb-2">Make</label>
                <input
                  className="modal-input-box"
                  type="text"
                  style={{
                    background: "#F1EFEF",
                    width: "100%",
                    border: "0",
                    height: "50px",
                    borderRadius: "5px",
                    padding: "0 15px",
                    fontSize: "14px",
                  }}
                  placeholder="Enter new make name"
                  onChange={(e) => setAssetMake(e.target.value)}
                />
              </div>
            </div>

            <div
              className="button-group"
              style={{
                marginTop: "25px",
                display: "flex",
                justifyContent: "end",
                gap: "30px",
              }}
            >
              <button className="cancel-btn" onClick={onHide}>
                Cancel
              </button>
              <button className="delate-btn" onClick={() => handleAddNewMake()}>
                {isLoading ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditIdentityInfoThreeModal;
