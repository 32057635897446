import React, {useEffect,useState,useContext} from "react";
import "../Components/PumpCalibration/PumpCalibration.scss";
import WashroomHeader from "../Components/InspectionCategory/Washroom/WashroomHeader";
import WashroomNavbar from "../Components/InspectionCategory/Washroom/WashroomNavbar";
import WashroomSidebar from "../Components/InspectionCategory/Washroom/WashroomSidebar";
import WashroomContent from "../Components/InspectionCategory/Washroom/WashroomContent";
import { useLocation } from "react-router-dom";
import RoutineInspectionCategoryContext from "../Context/Inspections/RoutineInspectionCategoryContext";

const WashroomInspection = () => {
 
  const {routineInspectionCategoryData} = useContext(RoutineInspectionCategoryContext);

 
  return (
    <div className="work-orders-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <WashroomHeader />
          </div>
          <div className="col-lg-12">
            <WashroomNavbar  />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="order-request-area">
            <WashroomSidebar />
              <WashroomContent/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WashroomInspection;
