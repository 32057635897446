import React, { useContext, useEffect, useState } from "react";
import "../Components/Inspections/Inspections.scss"; 
import ResponsesHeader from "../Components/Inspections/Responses/ResponsesHeader";
import ResponsesNavbar from "../Components/Inspections/Responses/ResponsesNavbar";
import ResponsesSidebar from "../Components/Inspections/Responses/ResponsesSidebar";
import ResponsesContent from "../Components/Inspections/Responses/ResponsesContent";
import RoutineInspectionCategoryContext from "../Context/Inspections/RoutineInspectionCategoryContext";
import { useParams } from "react-router-dom";
import { get } from "../Services/ApiHelper";
import { GET_INSPECTIONS_BY_CATEGORY_ID, GET_INSPECTION_BY_ID } from "../utls/constants";
import { useLocation } from "react-router-dom";


const InspectionsResponses = () => {
  const {id} = useParams();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const categoryId = location.state?.category.id;

  useEffect(() => {
    get(GET_INSPECTION_BY_ID(id),localStorage.getItem("bearerToken")).then((data) => {
      var result = data["data"];
      setData(result);
      setIsLoading(false);
    }
    );
  }
  ,[id]);

  const {ongoingInspection} = useContext(RoutineInspectionCategoryContext);


    return (
        <div className="work-orders-area f3washroom-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ResponsesHeader />
            </div>
            <div className="col-lg-12">
              <ResponsesNavbar categoryId={categoryId} />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="order-request-area">
                <ResponsesSidebar inspection ={data} />
                <ResponsesContent inspection ={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default InspectionsResponses;