import React, { useState } from "react";
import { Link } from "react-router-dom";
import DownIcon from "../../Assets/Icons/DownIcon";

const OrderSidebar = () => {
  const [status, setStatus] = useState("Open");
  return (
    <div className="order-sidebar-content">
      <div className="fs-13 fw-medium">GENERAL INFORMATION</div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="text-ii">
          <div className="fs-14 fw-medium">Reported on: </div>
          <div className="fs-14 fw-medium text-black">19/01/2022 10:33 AM </div>
        </div>
        <div className="text-dd">
          <div className="fs-14 fw-medium">Status:</div>
          <div class="dropdown">
            <button className="drop-title" data-bs-toggle="dropdown">
              {status} <DownIcon />
            </button>
            <ul className="dropdown-menu dropdown-menu-end">
              <li onClick={() => setStatus("In Progress")}>
                <Link className="dropdown-item" to="">
                  In Progress
                </Link>
              </li>
              <li onClick={() => setStatus("On Hold")}>
                <Link className="dropdown-item" to="">
                  On Hold
                </Link>
              </li>
              <li onClick={() => setStatus("Closed")}>
                <Link className="dropdown-item" to="">
                  Closed
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="text-ii">
        <div className="fs-14 fw-medium"> Location: </div>
        <div className="fs-14 fw-medium text-black">Gigiri</div>
      </div>
      <div className="text-ii">
        <div className="fs-14 fw-medium"> Incidence Reference:</div>
        <div className="fs-14 fw-medium text-black">ACJ3123</div>
      </div>
      <div className="text-ii">
        <div className="fs-14 fw-medium"> Incidence Description:</div>
        <div className="fs-14 fw-medium text-black">Leaking Pipes</div>
      </div>
      <div className="text-ii">
        <div className="fs-14 fw-medium"> Incidence Comment:</div>
        <div className="fs-14 fw-medium text-black">
          Leaking Pipes on Pump A. Very Urgent.
        </div>
      </div>
      <br />
      <br />
      <div className="fs-13 fw-medium">TECHNICIAN/VENDOR INFORMATION</div>
      <div className="text-ii">
        <div className="fs-14 fw-medium"> Assigned To:</div>
        <div className="fs-14 fw-medium text-black">Nexgen</div>
      </div>
      <div className="text-ii">
        <div className="fs-14 fw-medium">Technician Assigned: </div>
        <div className="fs-14 fw-medium text-black">0723234234</div>
      </div>
      <div className="text-ii">
        <div className="fs-14 fw-medium"> Technician Assigned:</div>
        <div className="fs-14 fw-medium text-black">Abdalla Abdi</div>
      </div>
      <div className="d-flex order-sidebar-btn">
        <button>Assign Technician</button>
        <button>Close Work Order</button>
      </div>
    </div>
  );
};

export default OrderSidebar;
