import React, { useState } from "react";
import EditIcon from "../../../Assets/Icons/EditIcon";
import DeleteIcon from "../../../Assets/Icons/DeleteIcon";

const Diagnosis = () => {
  const [diagnosis, setDiagnosis] = useState("Broken pipe nozzle");
  const [part, setPart] = useState("Pipe nozzle");
  const [diagnosis2, setDiagnosis2] = useState("Broken pipe nozzle");
  const [part2, setPart2] = useState("Pipe nozzle");
  const [isEdit, setIsEdit] = useState(false);
  const [isEdit2, setIsEdit2] = useState(false);
  return (
    <>
      <div className="table-responsive-sm request_table">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Diagnosis</th>
              <th scope="col">Part</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {" "}
                <input
                  type="text"
                  readOnly={!isEdit}
                  value={diagnosis}
                  className={isEdit ? "border edit-input" : "edit-input"}
                  onChange={(e) => setDiagnosis(e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  readOnly={!isEdit}
                  value={part}
                  className={isEdit ? "border edit-input" : "edit-input"}
                  onChange={(e) => setPart(e.target.value)}
                />
              </td>
              <td>
                <div className="button-con">
                  <button onClick={() => setIsEdit(!isEdit)}>
                    <EditIcon />
                  </button>
                  <button>
                    <DeleteIcon />
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                {" "}
                <input
                  type="text"
                  readOnly={!isEdit2}
                  value={diagnosis2}
                  className={isEdit2 ? "border edit-input" : "edit-input"}
                  onChange={(e) => setDiagnosis2(e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  readOnly={!isEdit2}
                  value={part2}
                  className={isEdit2 ? "border edit-input" : "edit-input"}
                  onChange={(e) => setPart2(e.target.value)}
                />
              </td>
              <td>
                <div className="button-con">
                  <button onClick={() => setIsEdit2(!isEdit2)}>
                    <EditIcon />
                  </button>
                  <button>
                    <DeleteIcon />
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Diagnosis;
