import React from "react";
import DownIcon from "../../Assets/Icons/DownIcon";
import AddIcon from "../../Assets/Icons/AddIcon";
import { Link } from "react-router-dom";
import exportToExcel from "../../utls/exportToExcel";
import AddLocationGroupingModal from "../WorkOrders/Modal/AddLocationGroupingModal";
const LocationsHeader = () => {
  const [addGroupModlaShow, setAddGroupModlaShow] = React.useState(false);
  return (
    <>
      <div className="work-header">
        <div className="fs-20">Locations</div>
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-15 d-flex align-items-center gap-2">
              Actions <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu dropdown-menu-end select-menu">
            <li>
              <Link to="/locations/new-location">
                <AddIcon /> New Location
              </Link>
            </li>
            <li>
              <Link className="text-nowrap" onClick={() => setAddGroupModlaShow(true)}>
                <AddIcon /> New Location Group
              </Link>
            </li>
            <li onClick={() => exportToExcel(RequestData, "Requests")}>
              <Link to="">
                <AddIcon />
                Export to Excel
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <AddLocationGroupingModal
        show={addGroupModlaShow}
        onHide={() => setAddGroupModlaShow(false)}
      />
    </>
  );
};

const RequestData = [
  {
    Name: "Rubis Rongo",
    Address: "Rongo Town, Homabay Rd.",
    Grouping: "Western Region",
    "Parent Location": "None",
    Coordinates: [
      { latitude: -1.3027746034926533, longitude: 36.821664336613566 },
      { latitude: -1.301743293465485, longitude: 36.8255377672569 },
    ],
  },
  {
    Name: "Rubis Kisumu East",
    Address: "Kisumu Nairobi Highway",
    Grouping: "Western Region",
    "Parent Location": "None",
    Coordinates: [],
  },
  {
    Name: "Rubis Kisii West",
    Address: "Kisii Nairobi Highway",
    Grouping: "Western Region",
    "Parent Location": "None",
    Coordinates: [
      { latitude: -1.2535081168107072, longitude: 36.78990049134051 },
    ],
  },
  {
    Name: "Rubis Eldoret 1",
    Address: "Maili Saba Rd.",
    Grouping: "North Rift Region",
    "Parent Location": "None",
    Coordinates: [
      { latitude: -1.33644182485335, longitude: 37.07327959813835 },
    ],
  },
  {
    Name: "Rubis Eldoret 2",
    Address: "Maili Tisa Rd.",
    Grouping: "North Rift Region",
    "Parent Location": "None",
    Coordinates: [{ latitude: -4.1985398109521, longitude: 39.61535511028749 }],
  },
  {
    Name: "Rubis Nakuru East",
    Address: "Naivasha Rd.",
    Grouping: "South Rift Region",
    "Parent Location": "None",
    Coordinates: [
      { latitude: -4.165041253752075, longitude: 39.558305489292145 },
    ],
  },
  {
    Name: "Rubis Nakuru West",
    Address: "Off Nakuru Rd.",
    Grouping: "South Rift Region",
    "Parent Location": "None",
    Coordinates: [
      { latitude: -3.55916690353848, longitude: 38.75530206337638 },
    ],
  },
  {
    Name: "Rubis Kisaju",
    Address: "Old Namanga Rd.",
    Grouping: "Nairobi Region",
    "Parent Location": "None",
    Coordinates: [
      { latitude: -1.33644182485335, longitude: 37.07327959813835 },
    ],
  },
  {
    Name: "Rubis Kitengela",
    Address: "Kitengela Town, Namanga Rd.",
    Grouping: "Nairobi Region",
    "Parent Location": "None",
    Coordinates: [
      { latitude: -1.33644182485335, longitude: 37.07327959813835 },
    ],
  },
];

export default LocationsHeader;
