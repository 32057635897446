import React, { useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { FaCircleCheck } from "react-icons/fa6";
import { toast } from "react-toastify";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";
import { Spinner } from "react-bootstrap";
import { CONFIRMREJECT_ARRIVAL, GET_WORKORDER_DETAILS_BY_ID } from "../../../utls/constants";
import { useNavigate } from "react-router-dom";
import { get } from "../../../Services/ApiHelper";
import { useParams } from "react-router-dom";

const ConfirmModal = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);
  const {id} = useParams();
  const onSubmit = (status) => {
    const body = {
      ticketId: ticketSummary?.workOrderSummary?.ticketId,
      isArrivalConfirmed: status,
    };
    console.log(body);
    setLoading(true);
    fetch(CONFIRMREJECT_ARRIVAL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: "Bearer " + localStorage.getItem("bearerToken"),
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.succeeded === true) {
          /*if (status) {
            setTicketSummary({
              ...ticketSummary,
              workOrderSummary: {
                ...ticketSummary.workOrderSummary,
                currentTicketActivity: "ARRIVAL CONFIRMED",
              },
            });
          } else {
            setTicketSummary({
              ...ticketSummary,
              workOrderSummary: {
                ...ticketSummary.workOrderSummary,
                currentTicketActivity: "ARRIVAL REJECTED",
              },
            });
          }*/
          get(GET_WORKORDER_DETAILS_BY_ID(id), localStorage.getItem("bearerToken"))
            .then((result) => {
              setTicketSummary(result);
            })
            .catch((error) => {
              console.log(error);
          });
          props.onHide();
          setLoading(false);
          toast.success(
            status
              ? "Site Arrival Confirmed Successfully"
              : "Site Arrival Rejected Successfully",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: false,
            }
          );
        } else {
          setLoading(false);
          props.onHide();
          toast.error(
            status ? "Arrival Confirmation failed" : "Arrival Rejection failed",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: false,
            }
          );
        }
      });
  };
  const handleAccept = () => {
    setIsAccepted(true);
    // Call onSubmit with the updated value of isAccepted
    onSubmit(true);
  };

  const handleReject = () => {
    setIsAccepted(false);
    // Call onSubmit with the updated value of isAccepted
    onSubmit(false);
  };
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="">
            <span className="fs-16 fw-bold ">Confirm Arrival</span>
            <span className="fs-14 ps-3">Confirm arrival on site</span>
          </div>
          <div
            className="delate-warning"
            style={{
              background: "#F1EFEF",
              height: "267px",
              display: "grid",
              alignItems: "center",
              textAlign: "center",
              padding: "20px",
              marginTop: "30px",
              borderRadius: "5px",
            }}
          >
            <div>
              <FaCircleCheck style={{ color: "#D57D2A", fontSize: "70px" }} />
              <div
                className="fs-20 fw-medium "
                style={{
                  lineHeight: "1.4",
                  marginTop: "10px",
                  color: "#72777A",
                }}
              >
                Would you like to confirm workers arrival on <br /> site?
              </div>
            </div>
          </div>
          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            {!loading ? (
              <>
                <button className="border-btn" onClick={props.onHide}>
                  Cancel
                </button>
                <button className="cancel-btn" onClick={handleReject}>
                  Not Arrived
                </button>
                <button className="delate-btn" onClick={handleAccept}>
                  Arrived
                </button>
              </>
            ) : loading && isAccepted ? (
              <button className="delate-btn" disabled={loading}>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Confirming Arrival...
              </button>
            ) : (
              <button className="cancel-btn" disabled={loading}>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Rejecting Arrival...
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmModal;
