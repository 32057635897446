import React from 'react'
import { Alert, Modal } from 'react-bootstrap'
import { DELETE_ROUTINE_INSPECTION_SCHEDULE } from '../../utls/constants';
import { toast } from 'react-toastify';

function DeleteModal(props) {
    const handleDeleteItem = () => {
        fetch(DELETE_ROUTINE_INSPECTION_SCHEDULE, {
          method: "DELETE",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            "Authorization": "Bearer " + localStorage.getItem("bearerToken"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: props.Item.routineInspectionId,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.succeeded === true) {
                props.onHide();
                toast.success(data.messages[0], { 
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setTimeout(() => {
                  window.location.reload();
                }, 3000);
            }else{
              toast.error(data.messages[0], { 
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }

          });
      
      };

  return (
    <div>
        
     <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this item?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={props.onHide}>Cancel</button>
          <button className="btn btn-danger" onClick={()=>{
            handleDeleteItem(props.Item.id);
            props.onHide();
          }}>Delete</button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default DeleteModal
