import React, { useContext } from "react";
import { Accordion } from "react-bootstrap";
import { RequestDetailsContext } from "../../../Context/Inspections/WorkOrderContext";

const ViewRequestDoagnosis = () => {
  const { request, setRequest } = useContext(RequestDetailsContext);

  console.log("requestdd", request);
  return (
    <>
      <div className="order-request washroom-right-content responses-cont">
        <div className="washroom-title">
          <div className="fs-13 fw-medium">DIAGNOSIS DETAILS</div>
        </div>

        <div className="order-request mt-4 p-0">
          <Accordion defaultActiveKey="1">
            {/* DIAGNOSIS */}
            <Accordion.Item
              eventKey="1"
              className="request-box horizonScroll pe-0"
            >
              <Accordion.Header className="request-box-title pe-3">
                <div className="fs-13 fw-medium">DIAGNOSIS</div>
              </Accordion.Header>
              <Accordion.Body className="request-box-body p-0">
                <div
                  className="table-responsive-sm request_table mb-3"
                  style={{ maxWidth: "100%" }}
                >
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">
                          {request?.requestDiagnosisList?.length > 0
                            ? "Issue diagnosis and solution"
                            : "Issue diagnosis and solution Not Available"}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {request?.requestDiagnosisList?.map((item) => (
                        <tr key={item.id}>
                          <td>
                            <div className="d-grid">
                              <div
                                className="fs-14"
                                style={{ color: "#1F2024" }}
                              >
                                Diagnosis By: <b>{item.teamName ?? "N/A"}</b>
                              </div>
                              <div
                                className="fs-14"
                                style={{ color: "#1F2024" }}
                              >
                                PART: {item?.faultyPart}
                              </div>
                              <div
                                className="fs-13"
                                style={{ color: "#71727A" }}
                              >
                                <b>Diagnosis:</b> {item?.diagnosis}
                              </div>
                              <div
                                className="fs-13 pt-2"
                                style={{ color: "#71727A" }}
                              >
                                <b>Proposed Solution:</b>{" "}
                                {item?.proposedSolution}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {/* <div className="text-end">
                  <button className="add-button my-3">Add Diagnosis</button>
                </div> */}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
};

const diagnosisData = [
  {
    id: 0,
    part: "Mechanical Meter",
    diagnosis: "No movement on mechanical meter",
    solution:
      "Dust and debris to be cleared. This is preventing the proper rotation of the meter sprockets and related...",
  },
  {
    id: 1,
    part: "Dispenser Board",
    diagnosis: "Burnt communication IC due to over current ",
    solution:
      "Connect dispenser to a voltage stabilizer and replaced the burnt IC. Dispenser still on bypass power...",
  },
  {
    id: 2,
    part: "Flow Meter",
    diagnosis: "Standard calibration",
    solution: "Calibration to be done and certificate provided.  ",
  },
  {
    id: 0,
    part: "Mechanical Meter",
    diagnosis: "No movement on mechanical meter",
    solution:
      "Dust and debris to be cleared. This is preventing the proper rotation of the meter sprockets and related...",
  },
  {
    id: 1,
    part: "Dispenser Board",
    diagnosis: "Burnt communication IC due to over current ",
    solution:
      "Connect dispenser to a voltage stabilizer and replaced the burnt IC. Dispenser still on bypass power...",
  },
  {
    id: 2,
    part: "Flow Meter",
    diagnosis: "Standard calibration",
    solution: "Calibration to be done and certificate provided.  ",
  },
];

export default ViewRequestDoagnosis;
