const getUniqueProperty = (array, assetCategoryId, assetCategoryName) => {
  return array?.reduce((uniqueItems, currentItem) => {
    const { [assetCategoryId]: id, [assetCategoryName]: name } = currentItem;
    const isItemExist = uniqueItems.some(item => item.id === id);
    if (!isItemExist) {
      uniqueItems.push({ id, name });
    }
    return uniqueItems;
  }, []);
};

export default getUniqueProperty;

