import React from "react"; 

const ViewWorkCostsHeader = () => { 
  return (
    <>
      <div className="work-header">
        <div className="fs-20">View Costing</div>
        <div className="dropdown select-dropdown">
           
        </div>
      </div>
    </>
  );
};

export default ViewWorkCostsHeader;
