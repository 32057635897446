import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import SearchIcon from "../../../Assets/Icons/SearchIcon";

const AddAssetModelModal = (props) => {
  const [selectedCost, setselectedCost] = useState("Select asset make");

  //search inputs
  const [costSearch, setCostSearch] = useState("");
  const costData = [{ name: "Gilbarco Veeder Root" }, { name: "Wayne Helix" }];

  const filteredCostData = costData.filter((item) =>
    item.name.toLowerCase().includes(costSearch.toLowerCase())
  );
  const handleCostSelect = (eventKey) => {
    setselectedCost(eventKey);
    setCostSearch("");
  };
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal add-asset-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-md-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Add Asset Model</span>
            <div className="fs-14 pt-2 pt-md-0" style={{ color: "#72777A" }}>
              Add a new asset model
            </div>
          </div>
          <div className="mt-4">
            <label className="fw-medium pb-2 fs-14">Asset Make</label>
            <Dropdown className="select__form" onSelect={handleCostSelect}>
              <Dropdown.Toggle
                className={`select-title ${
                  selectedCost !== "Select asset make" ? "selected" : ""
                }`}
                style={{ height: "50px", fontSize: "14px" }}
              >
                {selectedCost}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <form className="dropdown-search">
                  <button disabled>
                    <SearchIcon />
                  </button>
                  <input
                    value={costSearch}
                    onChange={(e) => setCostSearch(e.target.value)}
                    type="text"
                    placeholder="Search"
                  />
                </form>
                <div className="dropdown-item-content">
                  {filteredCostData.map((item, index) => (
                    <Dropdown.Item
                      className="fs-14"
                      key={index}
                      eventKey={item.name}
                    >
                      {item.name}
                    </Dropdown.Item>
                  ))}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="mt-4">
            <label className="fw-medium pb-2 fs-14">Asset Model</label>
            <input
              className="modal-input-box"
              type="text"
              style={{
                background: "#F1EFEF",
                width: "100%",
                border: "0",
                height: "50px",
                borderRadius: "5px",
                padding: "0 15px",
                fontSize: "14px",
              }}
              placeholder="Enter asset model"
            />
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            <Link to="" className="delate-btn" onClick={props.onHide}>
              Add
            </Link>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddAssetModelModal;
