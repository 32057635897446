import React from "react";
import { Link } from "react-router-dom";

const AddPmNavbar = () => {
  return (
    <div className="other-nav">
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="" className="text-black">PM Category Schedule</Link>
        </li>
        <li>
          <Link className="text-black">Add PM Category Schedule</Link>
        </li>
      </ul>
    </div>
  );
};

export default AddPmNavbar;
