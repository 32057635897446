import React, { useEffect,useContext } from "react";
import { Accordion } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import { DiagnosisDetailsContext } from "../../../Context/Inspections/WorkOrderContext";

const ViewDiagnosisQuotesSidebar = () => {
  const [eventKey, setEventKey] = React.useState("");
  const { diagnosisSummary, setDiagnosisSummary } = useContext(DiagnosisDetailsContext);
  //get url by using useLocation
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === `/diagnosis-quotes/view/${diagnosisSummary?.requestDetailSummary?.id}`) {
      setEventKey("0");
    } else if (location.pathname === `/diagnosis-quotes/view/${diagnosisSummary?.requestDetailSummary?.id}/diagnosis`) {
      setEventKey("1");
    } else if (location.pathname === `/diagnosis-quotes/view/${diagnosisSummary?.requestDetailSummary?.id}/parts`) {
      setEventKey("2");
    } else if (location.pathname === `/diagnosis-quotes/view/${diagnosisSummary?.requestDetailSummary?.id}/quotes`) {
      setEventKey("3");
    }
  }, [location, diagnosisSummary]);

  const claims = (claimValue) => {
    //Check if value is not null or undefined
    if (localStorage.getItem("claims")) {
      // Parse the string as a JSON array
      const claimsArray = JSON.parse(localStorage.getItem("claims"));
      if (claimsArray.includes(claimValue)) {
        // The string is present in the array
        return true;
      } else {
        return false;
      }
    } else {
      // Handle the case where the "claims" key is not present in local storage
      return false;
    }
  };


  return (
    eventKey && (
      <>
        <div className="order-sidebar-content p-0">
          <div className="p-4">
            <div className="fs-13 fw-medium">
              DIAGNOSIS & QUOTES INFORMATION
            </div>
            <div className="washroom-sidebar mt-3 align-items-end">
              <div>
                <div
                  className="fs-16 fw-bold pb-2"
                  style={{ color: "#6C5B51" }}
                >
                  Request Reference
                </div>
                <div className="fs-40 fw-bold" style={{ color: "#D57D2A" }}>
                  {diagnosisSummary?.requestDetailSummary?.requestRef}
                </div>
              </div>
            </div>
          </div>

          <Accordion defaultActiveKey={eventKey} className="view-work-sidebar">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <NavLink to={`/diagnosis-quotes/view/${diagnosisSummary?.requestDetailSummary?.id}`} end>
                  Request Summary
                </NavLink>
              </Accordion.Header>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <NavLink to={`/diagnosis-quotes/view/${diagnosisSummary?.requestDetailSummary?.id}/diagnosis`}>
                  Diagnosis
                </NavLink>
              </Accordion.Header>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <NavLink to={`/diagnosis-quotes/view/${diagnosisSummary?.requestDetailSummary?.id}/parts`}>Parts</NavLink>
              </Accordion.Header>
            </Accordion.Item>
            {claims("Can_View_Quotes") && (
             <Accordion.Item eventKey="3">
              <Accordion.Header>
                <NavLink to={`/diagnosis-quotes/view/${diagnosisSummary?.requestDetailSummary?.id}/quotes`}>Quotes</NavLink>
              </Accordion.Header>
             </Accordion.Item>
            )}
          </Accordion>
        </div>
      </>
    )
  );
};

export default ViewDiagnosisQuotesSidebar;
