import React from "react";
import "../Components/WorkOrders/WorkOrders.scss";
import "react-datepicker/dist/react-datepicker.css";
import { Link, NavLink, Outlet } from "react-router-dom";
import AssetsHeader from "../Components/Assets/AssetsHeader";
import assImg from "../Assets/Images/Gilbarco1.png";
import assImg2 from "../Assets/Images/Gilbarco2.png";

const Assets = () => {
  return (
    <div className="work-orders-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <AssetsHeader assetTable={AssetsData} />
          </div>
          <div className="col-lg-12">
            <div className="other-nav">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="">Assets</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="inspection-cate">
              <div className="work-table-nav">
                <NavLink to="/assets" end>
                  Assets
                </NavLink>
                <NavLink to="/assets/assets-categories">
                  Asset Categories
                </NavLink>
                {/* <NavLink to="/assets/assets-transfers">Asset Transfers</NavLink> */}
              </div>
              <div className="work-table-tables">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Assets;

const AssetsData = [
  {
    id: 1,
    assetLogo: assImg,
    asset: "Dispenser 1",
    idnumber: "10000001",
    serial: "540019382",
    tag: "SD24A001",
    make: "Gilbarco Frontier",
    model: "Series I - F160",
    category: "Pumps & Dispensers",
    condition: "New",
    location: "NA",
    parent: "NA",
    status: "Retired",
    created: "14/FEB/2024",
  },
  {
    id: 2,
    assetLogo: assImg,
    asset: "Dispenser 2",
    idnumber: "10000002",
    serial: "640019382",
    tag: "SD24A002",
    make: "Gilbarco Frontier",
    model: "Series I - F160",
    category: "Pumps & Dispensers",
    condition: "New",
    location: "Rubis Kisaju",
    parent: "COCO Sites",
    status: "Retired",
    created: "14/FEB/2023",
  },
  {
    id: 3,
    assetLogo: assImg2,
    asset: "Dispenser 3",
    idnumber: "10000003",
    serial: "2950123811",
    tag: "SD24A0101",
    make: "Wayne Helix",
    model: "3G HS Series",
    category: "Pumps & Dispensers",
    condition: "Used",
    location: "Rubis Kisaju",
    parent: "COCO Sites",
    status: "Active",
    created: "14/FEB/2022",
  },
  {
    id: 4,
    assetLogo: null,
    asset: "AGO Tank 1",
    idnumber: "10000001",
    serial: "540019382",
    tag: "SD24A001",
    make: "Craftsman Tank",
    model: "2185-20,000L",
    category: "Fuel Tanks",
    condition: "Used",
    location: "Rubis Machakos",
    parent: "DODO Sites",
    status: "Retired",
    created: "14/FEB/2023",
  },
  {
    id: 5,
    assetLogo: null,
    asset: "AGO Tank 2",
    idnumber: "10000001",
    serial: "540019382",
    tag: "SD24A002",
    make: "Gilbarco Frontier",
    model: "2185-20,000L",
    category: "Fuel Tanks",
    condition: "Used",
    location: "Rubis Machakos",
    parent: "DODO Sites",
    status: "Active",
    created: "14/FEB/2024",
  },
  {
    id: 6,
    assetLogo: null,
    asset: "PMS Tank 1",
    idnumber: "10000001",
    serial: "540019382",
    tag: "SD24A001",
    make: "Craftsman Tank",
    model: "2185-20,000L",
    category: "Fuel Tanks",
    condition: "Used",
    location: "Rubis Machakos",
    parent: "DODO Sites",
    status: "Retired",
    created: "14/FEB/2023",
  },
  {
    id: 7,
    assetLogo: null,
    asset: "PMS Tank 2",
    idnumber: "10000001",
    serial: "540019382",
    tag: "SD24A002",
    make: "Gilbarco Frontier",
    model: "2185-20,000L",
    category: "Fuel Tanks",
    condition: "Used",
    location: "Rubis Machakos",
    parent: "DODO Sites",
    status: "Active",
    created: "14/FEB/2024",
  },
  {
    id: 8,
    assetLogo: null,
    asset: "HVAC AC 101",
    idnumber: "10000001",
    serial: "540019382",
    tag: "SD24A001",
    make: "Craftsman Tank",
    model: "2185-20,000L",
    category: "Fuel Tanks",
    condition: "Used",
    location: "Rubis Machakos",
    parent: "DODO Sites",
    status: "Retired",
    created: "14/FEB/2023",
  },
  {
    id: 9,
    assetLogo: null,
    asset: "HVAC AC 202",
    idnumber: "10000001",
    serial: "540019382",
    tag: "SD24A002",
    make: "Gilbarco Frontier",
    model: "2185-20,000L",
    category: "Fuel Tanks",
    condition: "Used",
    location: "Rubis Machakos",
    parent: "DODO Sites",
    status: "Active",
    created: "14/FEB/2024",
  },
];
