import React from "react";

import { Nav, Tab } from "react-bootstrap";
import Teams from "./TeamComponent/Teams";
import Users from "./TeamComponent/Users";
import Contractors from "./TeamComponent/Contractors";
import Customers from "./TeamComponent/Customers";

const TeamContent = () => {
  return (
    <div className="order-request">
      <div className="request-box">
        <Tab.Container id="team-tab" defaultActiveKey="first">
          <Nav variant="pills" className="team-table-nav">
            <Nav.Item>
              <Nav.Link eventKey="first">Teams</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">Users</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="third">Contractors</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="fourth">Customers</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <Teams />
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <Users />
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              <Contractors />
            </Tab.Pane>
            <Tab.Pane eventKey="fourth">
              <Customers />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

export default TeamContent;
