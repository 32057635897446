import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { NavLink, useLocation, useParams } from "react-router-dom";
import AttachIcon from "../../../Assets/Icons/AttachIcon";
import gallery from "../../../Assets/Images/frontier.png";
import AssetLocationModal from "../../WorkOrders/Modal/AssetLocationModal";
import AssetPhotoModal from "../../WorkOrders/Modal/AssetPhotoModal";
import usefetchData from "../../../hooks/useFetchData";

const AssetsProfileSide = () => {
  const [assetLocationShow, setAssetLocationShow] = useState(false);
  const [eventKey, setEventKey] = useState("");
  const location = useLocation();
  const [assetPhotoShow, setAssetPhotoShow] = useState(false);

  const {id} = useParams()

  console.log("asset params", id)

  useEffect(() => {
    if (location.pathname === `/assets/work-view/${id}`) {
      setEventKey("0");
    } else if (location.pathname === `/assets/work-view/${id}/parts`) {
      setEventKey("1");
    } else if (location.pathname === `/assets/work-view/${id}/work`) {
      setEventKey("2");
    } else if (location.pathname === `/assets/work-view/${id}/assignment`) {
      setEventKey("3");
    }
  }, [location]);

  // get asset data by id
  const { data: assetSummary, isLoading: isLoadingCondition } = usefetchData(
    ["single-asset", parseInt(id)],
    `/Assets/GetAssetSummaryById/${id}`,
    {},
    "Error fetching asset data by id"
  );


  return (
    eventKey && (
      <>
        <div className="order-sidebar-content p-0">
          <div className="p-4">
            <div className="fs-13 fw-medium">ASSET PROFILE</div>
            <div className="washroom-sidebar mt-3 align-items-center">
              <div>
                <div
                  className="fs-14 fw-bold pb-2"
                  style={{ color: "#6C5B51" }}
                >
                  Asset
                </div>
                <div className="fs-17 fw-bold" style={{ color: "#D57D2A" }}>
                  {assetSummary?.assetName}
                </div>
              </div>
              <div style={{ textAlign: "right" }}>
                <button
                  onClick={() => setAssetLocationShow(true)}
                  className="fs-14 fw-bold"
                  style={{ color: "#D57D2A", textDecoration: "underline" }}
                >
                  Asset Location
                </button>
              </div>
            </div>
          </div>

          <Accordion
            defaultActiveKey={eventKey}
            className="view-work-sidebar pt-0"
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <NavLink to={``} end>
                  Summary
                </NavLink>
              </Accordion.Header>
              <Accordion.Body>
                <div className="row pb-2">
                  <div className="col-12">
                    <div className="gallery-add">
                      <div className="d-flex flex-wrap align-items-center gap-4">
                        <img src={assetSummary?.assetPhotoURL ?? ""} alt={assetSummary?.assetName} className="object-fit-contain" style={{ width: '80px', height: '120px' }}  />

                        <div className="attach-file mt-3">
                          <label 
                            className="attach-button"
                            onClick={() => setAssetPhotoShow(true)}
                            style={{
                              cursor: "pointer",
                              background: "#F1EFEF",
                              padding: "10px 15px",
                              borderRadius: "5px",
                            }}
                          >
                            <AttachIcon /> Replace photo 
                          </label>
                           
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="fs-14 fw-medium">Asset Status</div>
                    <div
                      className="fs-14 fw-medium"
                      style={{ color: "#6C5B51" }}
                    >
                      {assetSummary?.assetStatusName}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="fs-14 fw-medium">Created On</div>
                    <div
                      className="fs-14 fw-medium"
                      style={{ color: "#6C5B51" }}
                    >
                      {assetSummary?.createdDate && new Date(assetSummary?.createdDate).toDateString() }
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="fs-14 fw-medium">Last Maintenance</div>
                    <div
                      className="fs-14 fw-medium"
                      style={{ color: "#6C5B51" }}
                    >
                      {assetSummary?.lastMaintenanceDate}
                    </div>
                  </div>
                  
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <NavLink to={`/assets/work-view/${id}/parts`}>Parts</NavLink>
              </Accordion.Header>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <NavLink to={`/assets/work-view/${id}/work`}>Work</NavLink>
              </Accordion.Header>
            </Accordion.Item>
            {/* <Accordion.Item eventKey="3">
              <Accordion.Header>
                <NavLink to={`/assets/work-view/${id}/assignment`}>Assignment</NavLink>
              </Accordion.Header>
            </Accordion.Item> */}
          </Accordion>
        </div>

        <AssetLocationModal
          show={assetLocationShow}
          onHide={() => setAssetLocationShow(false)}
          data={assetSummary}
        />
        <AssetPhotoModal
        show={assetPhotoShow}
        onHide={() => setAssetPhotoShow(false)}
        mode="upload"
        assetId={id}
      />
      </>
    )
  );
};

export default AssetsProfileSide;
