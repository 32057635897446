import React, { useState } from "react";
import DownIcon from "../../Assets/Icons/DownIcon";
import AddIcon from "../../Assets/Icons/AddIcon";
import { Link } from "react-router-dom";
import exportToExcel from "../../utls/exportToExcel";
import AddAssetCategoryModal from "../WorkOrders/Modal/AddAssetCategoryModal";
import AssetTransferModal2 from "../WorkOrders/Modal/AssetTransferModal2";
const AssetsHeader = ({ assetTable }) => {
  const [assetCategoryShow, setAssetCategoryShow] = useState(false); //for modal
  const [assetTransferShow, setAssetTransferShow] = useState(false); //for modal
  return (
    <>
      <div className="work-header">
        <div className="fs-20"> Assets</div>
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-15 d-flex align-items-center gap-2">
              Actions <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu dropdown-menu-end select-menu">
            <li>
              <Link to="/assets/create-new-asset">
                <AddIcon /> Add Asset
              </Link>
            </li>
            <li>
              <Link onClick={() => setAssetCategoryShow(true)}>
                <AddIcon /> Add Asset Category
              </Link>
            </li>
            {/* <li>
              <Link > 
                <AddIcon /> Transfer Asset
              </Link>
            </li> */}
            <li onClick={() => exportToExcel(assetTable, "asset-table")}>
              <Link to="#">
                <AddIcon /> Export Assets to Excel
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <AddAssetCategoryModal
        show={assetCategoryShow}
        onHide={() => setAssetCategoryShow(false)}
      />
      <AssetTransferModal2
        show={assetTransferShow}
        onHide={() => setAssetTransferShow(false)}
      />
    </>
  );
};

export default AssetsHeader;
