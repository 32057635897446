import React, { useState, useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { FaCircleCheck } from "react-icons/fa6";
import { toast } from "react-toastify";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";
import { Spinner } from "react-bootstrap";
import { CONFIRMREJECT_CLOSURE } from "../../../utls/constants";
import DeleteIcon from "../../../Assets/Icons/DeleteIcon";
import { ProgressBar } from "react-bootstrap";
import CloseIcon from "../../../Assets/Icons/CloseIcon";
import LoadingIcon from "../../../Assets/Icons/LoadingIcon";
import { FileUploader } from "react-drag-drop-files";
import ImageIcon from "../../../Assets/Icons/ImageIcon";

const ConfirmWorkModal = (props) => {
  const fileTypes = ["JPEG", "JPG", "PNG"];
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);
  const [comment, setComment] = useState("");
  const [fileObj, setFileObj] = useState({});

  const handleChange = (newFiles) => {
    // Ensure newFiles is an array
    const filesToAdd = Array.isArray(newFiles) ? newFiles : [newFiles];

    // Filter out files that already exist based on name and size
    const filteredFiles = filesToAdd.filter((newFile) => {
      return !files.some(
        (existingFile) =>
          existingFile.name === newFile.name &&
          existingFile.size === newFile.size
      );
    });

    // Verify the file type
    filteredFiles.forEach((file) => {
      const fileType = file.name.split(".").pop().toUpperCase();
      if (!fileTypes.includes(fileType)) {
        toast.error("Invalid file type");
        return;
      }
    });

    // Verify the file size
    if (filteredFiles.some((file) => file.size > 2 * 1024 * 1024)) {
      toast.error("File size should not exceed 2MB");
      return; // Stop further execution of the function
    }

    // Add status property to the every filteredFiles
    filteredFiles.forEach((file) => {
      file.status = "Uploading...";
      file.uploading = 50;
    });

    // Concatenate the filtered files with the existing files and set to state
    setFiles([...filteredFiles]);
  };
  // change the status of the file after 2 seconds of adding the file and also update the uploading percentage from 0 to 50 to 100
  useEffect(() => {
    const timer = setTimeout(() => {
      const newFiles = files.map((file) => {
        if (file.status === "Uploading...") {
          file.status = "Completed";
          file.uploading = 100;
        }
        return file;
      });
      setFiles(newFiles);
    }, 2000);
    let reader = new FileReader();
    files.forEach((file) => {
      reader.readAsDataURL(file);

      const fileType = file.type.split("/")[1];
      reader.onload = () => {
        const base64 = reader.result.split(",")[1];
        const fileInfo = {
          fileName: file.name,
          url: "",
          fileType: fileType,
          encodedFile: base64,
        };
        setFileObj(fileInfo);
      };
    });
    return () => clearTimeout(timer);
  }, [files]);

  //handle delete file
  const handleDelete = (file) => {
    const newFiles = files.filter((f) => f.name !== file.name);
    setFiles(newFiles);
  };
  const onSubmit = (status) => {
    const body = {
      ticketId: ticketSummary?.workOrderSummary?.ticketId,
      isClosureConfirmed: status,
      comment: comment,
      signatureFile: fileObj?.fileName == undefined ? null : fileObj,
    };

    console.log(body);
    setLoading(true);
    fetch(CONFIRMREJECT_CLOSURE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: "Bearer " + localStorage.getItem("bearerToken"),
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.succeeded === true) {
          setTicketSummary({
            ...ticketSummary,
            workOrderSummary: {
              ...ticketSummary.workOrderSummary,
              currentTicketActivity: "CLOSED",
            },
          });
          setLoading(false);
          props.onHide();
          toast.success(
            status
              ? "Ticket Closure Confirmed Successfully"
              : "Ticket Closure Rejected Successfully",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: false,
            }
          );
        } else {
          setLoading(false);
          props.onHide();
          toast.error(
            status
              ? "Ticket Closure Confirmation failed"
              : "Ticket Closure Rejection failed",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: false,
            }
          );
        }
      })
      .catch((error) => {
        setLoading(false);
        props.onHide();
        toast.error(
          status
            ? "Ticket Closure Confirmation failed"
            : "Ticket Closure Rejection failed",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          }
        );
      });
  };
  const handleAccept = () => {
    setIsAccepted(true);
    // Call onSubmit with the updated value of isAccepted
    onSubmit(true);
  };

  const handleReject = () => {
    setIsAccepted(false);
    // Call onSubmit with the updated value of isAccepted
    onSubmit(false);
  };
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="">
            <span className="fs-16 fw-bold ">Confirm Work</span>
            <span className="fs-14 ps-3">
              Confirm work done and provide comment
            </span>
          </div>
          <div className="browse-file-upload mt-4">
            <FileUploader
              hoverTitle=" "
              children={
                <div>
                  <ImageIcon />
                  <div
                    className="fs-16 fw-medium pt-3 pb-2"
                    style={{ color: "#292D32" }}
                  >
                    Choose your signature from file or drag & drop it here
                  </div>
                  <div className="fs-16 fw-medium" style={{ color: "#A9ACB4" }}>
                    JPEG or PNG up to 2MB
                  </div>
                  <input
                    fileOrFiles="files"
                    type="file"
                    accept="image/*"
                    /* onChange={handleFileChange} */
                    style={{ display: "none" }}
                    id="fileInput"
                  />
                  <label
                    htmlFor="fileInput"
                    className="browse-file cursor-pointer"
                  >
                    Browse File
                  </label>
                </div>
              }
              classes="cursor-pointer"
              maxFileSize={50}
              multiple={false}
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              onTypeError={(error) => toast.error(error)}
              onSizeError={(error) => toast.error(error)}
            />
          </div>
          <div className="d-grid gap-3 mt-3">
            {files.map((f) =>
              f.status === "Uploading..." ? (
                <div className="uploading-content">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <div
                        className="fs-16 fw-medium"
                        style={{ color: "#292D32" }}
                      >
                        {f.name}
                      </div>
                      <div
                        className="fs-13 pt-1 pb-2"
                        style={{ color: "#A9ACB4" }}
                      >
                        {/* {uploadedImage.size} */}
                        {parseInt(f.size / 1024) * (f.uploading / 100)} KB of{" "}
                        {parseInt(f.size / 1024)} KB •
                        <span style={{ color: "#292D32", paddingLeft: "10px" }}>
                          <LoadingIcon /> Uploading...
                        </span>
                      </div>
                    </div>
                    <button>
                      <CloseIcon />
                    </button>
                  </div>
                  <ProgressBar variant="warning" now={f.uploading} />
                </div>
              ) : (
                <div className="uploading-content d-flex align-items-center justify-content-between">
                  {/* <img src={signature} alt="" height={43} /> */}
                  {/* use the file as source of image */}
                  <img
                    src={URL.createObjectURL(f)}
                    alt=""
                    height={43}
                    width={43}
                  />
                  <button
                    onClick={() => handleDelete(f)}
                    style={{ color: "#6C5B51" }}
                  >
                    <DeleteIcon />
                  </button>
                </div>
              )
            )}
          </div>
          <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
            <div className="col-md-12">
              <label className="fw-medium pb-2">
                Add approval or rejection comments
              </label>
              <textarea
                name=""
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "85px",
                  borderRadius: "5px",
                  padding: "15px 15px",
                }}
                placeholder="Enter comment"
              ></textarea>
            </div>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            {!loading ? (
              <>
                <button className="border-btn" onClick={props.onHide}>
                  Cancel
                </button>
                <button className="cancel-btn" onClick={handleReject}>
                  Reject
                </button>
                <button className="delate-btn" onClick={handleAccept}>
                  Confirm Work
                </button>
              </>
            ) : loading && isAccepted ? (
              <button className="delate-btn" disabled={loading}>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Confirming...
              </button>
            ) : (
              <button className="cancel-btn" disabled={loading}>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Rejecting...
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmWorkModal;
