import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link, useParams } from "react-router-dom";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import usefetchData from "../../../hooks/useFetchData";
import { toast } from "react-toastify";
import useMutateData from "../../../hooks/useMutateData";

const AddAssetPartsModal = (props) => {
  const [selectCategory, setSelectCategory] = useState();
  const [selectTeam, setSelectTeam] = useState();
  const [categoryId, setCategoryId] = useState(null)
  const [teamId, setTeamId] = useState(null)

  const {id} = useParams()

  // get asset category data
  const { data: assetCatagory, isLoading: isLoadingCow } = usefetchData(
    ["part-category"],
    `/Parts/GetAllPartsCategory`,
    {},
    "Error fetching category of work"
  );

  // get location filter data
  const { data: catagoryWork } = usefetchData(
    ["parts-data"],
    `/Parts/GetPartsByCategory/${categoryId}`,
    {},
    "Error getting location data",
    !!categoryId
  );

  //get search input
  const [selectCategorySearch, setSelectCategorySearch] = useState("");
  const [selectTeamSearch, setSelectTeamSearch] = useState("");

  //filter via search input
  const filterassetCatagory = assetCatagory && assetCatagory?.filter((item) =>
    item.partCategoryName?.toLowerCase().includes(selectCategorySearch?.toLowerCase())
  );
  const filtercatagoryWork = catagoryWork && catagoryWork?.filter((item) =>
    item.partName?.toLowerCase().includes(selectTeamSearch?.toLowerCase())
  );

  //handle select
  const handleSelectCategory = (eventKey) => {
    const category = assetCatagory.find((item)=> item.partCategoryName === eventKey)
    setSelectCategory(eventKey);
    setCategoryId(category.id)
    setSelectCategorySearch("");
  };
  const handleSelectTeam = (eventKey) => {
    const part = catagoryWork.find((item)=> item.partName === eventKey)
    setSelectTeam(eventKey);
    setTeamId(part.id)
    setSelectTeamSearch("");
  };

      // add new asset part mutation
      const { mutate, isLoading } = useMutateData({
        url: `/Assets/AddAssetPart`,
        method: "POST",
        onSuccessfullMutation: (data) => {
          if (data?.data?.succeeded === true) {
            toast.success(data?.data?.messages[0], {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            props.onHide(); // Hide modal
          } else {
            toast.error(data?.data?.messages[0], {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        },
        successMessage: `Successfully updated basic info`,
        errorMessage: "Basic info editing failed",
        queryKeysToInvalidate: [["asset-make"]],
      });

      const addPart = ()=> {
        const requestBody = {
          assetId: parseInt(id),
          partId: teamId
        }
        mutate(requestBody)
      }

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal add-asset-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Add Asset Parts</span>
            <span className="fs-14" style={{ color: "#72777A" }}>
              Add parts used in maintaining the asset
            </span>
          </div>
          <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
            <div className="col-md-12">
              <label className="fw-medium fs-15 pb-2">Part Category</label>
              <Dropdown className="select__form" onSelect={handleSelectCategory}>
                <Dropdown.Toggle
                  className={`select-title`}
                  style={{ height: "50px" }}
                >
                  <div>
                    <span className="selected">Select</span>
                    <span
                      style={{
                        color: "#6C5B51",
                        fontWeight: "700",
                        paddingLeft: "10px",
                      }}
                    >
                      {selectCategory}
                    </span>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      onChange={(e) => setSelectTeamSearch(e.target.value)}
                      value={selectTeamSearch}
                      type="text"
                      placeholder="Search"
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filterassetCatagory && filterassetCatagory.map((item, index) => (
                      <Dropdown.Item key={index} eventKey={item.partCategoryName}>
                        {item.partCategoryName}
                      </Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-12">
              <label className="fw-medium fs-15 pb-2">Part Name</label>
              <Dropdown
                className="select__form"
                onSelect={handleSelectTeam}
              >
                <Dropdown.Toggle
                  className={`select-title`}
                  style={{ height: "50px" }}
                >
                  <div>
                    <span className="selected">Select</span>
                    <span
                      style={{
                        color: "#6C5B51",
                        fontWeight: "700",
                        paddingLeft: "10px",
                      }}
                    >
                      {selectTeam}
                    </span>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      onChange={(e) => setSelectCategorySearch(e.target.value)}
                      value={selectCategorySearch}
                      type="text"
                      placeholder="Search"
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filtercatagoryWork && filtercatagoryWork.map((item, index) => (
                      <Dropdown.Item key={index} eventKey={item.partName}>
                        {item.partName}
                      </Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            <Link to="" className="delate-btn" onClick={()=> addPart()}>
              {isLoading ? "Saving" :"Save"}
            </Link>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddAssetPartsModal;
