import React from "react";
import { Link, Outlet } from "react-router-dom"; 
import AssetsProfileSide from "../Components/Assets/AssetsProfile/AssetsProfileSide";

const AssetProfile = () => {
  return (
    <div className="work-orders-area f3washroom-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="work-header">
              <div className="fs-20">View Asset Profile</div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="other-nav">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/assets">Asset</Link>
                </li>
                <li>
                  <Link> View Asset Profile</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="order-request-area view-order-content assets-profile__content">
              <AssetsProfileSide />
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetProfile;
