import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import useMutateData from '../../../hooks/useMutateData';
import { toast } from 'react-toastify';
import { resetAssetState } from '../../../redux/assetSlice';

const CreateSummary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const assetState = useSelector((state) => state.asset);

  const [imageShow, setImageShow] = useState(false);
  const [videoShow, setVideoShow] = useState(false);
  const [documentShow, setDocumentShow] = useState(false);
  const handleClose = () => {
    setImageShow(false);
    setVideoShow(false);
    setDocumentShow(false);
  };

  const { mutate, isLoading: isSubmitting } = useMutateData({
    url: '/Assets/AddAsset',
    method: 'POST',
    onSuccessfullMutation: (data) => {
      if (data?.data?.succeeded === true) {
        toast.success(data?.data?.messages[0], {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        dispatch(resetAssetState());
        navigate('/assets');
      } else {
        toast.error(data?.data?.messages[0], {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
    successMessage: 'Successfully added asset',
    errorMessage: 'Asset creation failed',
  });

  const handleSubmit = () => {
    const payload = {
      assetName: assetState.assetName,
      assetDescription: assetState.assetDescription,
      assetCategoryId: assetState.assetCategoryId,
      assetLocationId: assetState.assetLocationId,
      assetStatusId: assetState.assetStatusId,
      assetConditionId: assetState.assetConditionId,
      serialNumber: assetState.serialNumber,
      saharaDeskTag: assetState.saharaDeskTag,
      barCode: assetState.barCode,
      assetModelId: assetState.assetModelId,
      parentAssetId: assetState.parentAssetId,
      assetPhoto: assetState.assetPhoto,
      assetFiles: assetState.assetFiles,
    };

    mutate(payload);
  };

  return (
    <>
      <div className="order-details-content pb-lg-4">
        <div className="fs-16 fw-semibold">Summary</div>

        <div className="fs-13 fw-semibold pt-5 gray-c">ASSET BASIC INFO</div>
        <hr style={{ margin: '10px 0 30px', borderColor: '#D0D0D0' }} />

        <div className="row" style={{ rowGap: '24px' }}>
          <div className="col-md-2">
            {assetState.assetPhoto.url ? (
              <img src={assetState.assetPhoto.url} alt="Asset" width="120px" />
            ) : (
              <img src="/default-image.png" alt="Default" width="120px" />
            )}
          </div>
          <div className="col-md-10 row row-gap-4">
            <div className="col-md-6">
              <div className="fs-14 fw-semibold pb-2" style={{ color: '#00000061' }}>
                Asset Name:
              </div>
              <div className="fs-14 fw-medium" style={{ lineHeight: '1.3' }}>
                {assetState.assetName}
              </div>
            </div>
            <div className="col-md-3">
              <div className="fs-14 fw-semibold pb-2" style={{ color: '#00000061' }}>
                Asset Category
              </div>
              <div className="fs-14 fw-medium">{assetState.assetCategoryName}</div>
            </div>
            <div className="col-md-3">
              <div className="fs-14 fw-semibold pb-2" style={{ color: '#00000061' }}>
                Asset Location
              </div>
              <div className="fs-14 fw-medium">{assetState.assetLocationName}</div>
            </div>
            <div className="col-md-6">
              <div className="fs-14 fw-semibold pb-2" style={{ color: '#00000061' }}>
                Description:
              </div>
              <div className="fs-14 fw-medium" style={{ lineHeight: '1.3' }}>
                {assetState.assetDescription}
              </div>
            </div>
            <div className="col-md-3">
              <div className="fs-14 fw-semibold pb-2" style={{ color: '#00000061' }}>
                Asset Status
              </div>
              <div className="fs-14 fw-medium">{assetState.assetStatusName}</div>
            </div>
            <div className="col-md-3">
              <div className="fs-14 fw-semibold pb-2" style={{ color: '#00000061' }}>
                Asset Condition
              </div>
              <div className="fs-14 fw-medium">{assetState.assetConditionName}</div>
            </div>
          </div>
        </div>

        <div className="fs-13 fw-semibold pt-5 gray-c">ASSET IDENTIFICATION, MAKE & MODEL</div>
        <hr style={{ margin: '10px 0 30px', borderColor: '#D0D0D0' }} />
        <div className="row row-gap-4">
          <div className="col-md-4">
            <div className="fs-14 fw-semibold pb-2" style={{ color: '#00000061', letterSpacing: '-.4px' }}>
              Serial Number
            </div>
            <div className="fs-14 fw-medium">{assetState.serialNumber}</div>
          </div>
          <div className="col-md-4">
            <div className="fs-14 fw-semibold pb-2" style={{ color: '#00000061', letterSpacing: '-.4px' }}>
              Tag ID
            </div>
            <div className="fs-14 fw-medium">{assetState.saharaDeskTag}</div>
          </div>
          <div className="col-md-4">
            <div className="fs-14 fw-semibold pb-2" style={{ color: '#00000061', letterSpacing: '-.4px' }}>
              Make
            </div>
            <div className="fs-14 fw-medium">{assetState.assetModelName}</div>
          </div>
          <div className="col-md-4">
            <div className="fs-14 fw-semibold pb-2" style={{ color: '#00000061', letterSpacing: '-.4px' }}>
              Model
            </div>
            <div className="fs-14 fw-medium">{assetState.assetModelName}</div>
          </div>
        </div>

        <div className="fs-13 fw-semibold pt-5 gray-c">ASSET DOCUMENTS</div>
        <hr style={{ margin: '10px 0 30px', borderColor: '#D0D0D0' }} />
        <div className="row ">
          <div className="col-md-12">
            <div className="fs-14 fw-medium" style={{ color: 'rgba(0, 0, 0, 0.38)' }}>
              Documents uploaded
            </div>
            <div className="d-grid gap-3 mt-3">
              {assetState.assetFiles.map((file) => (
                <button
                  key={file.fileName}
                  onClick={() => {
                    if (file.fileType.includes('image')) {
                      setImageShow(true);
                    } else if (file.fileType.includes('video')) {
                      setVideoShow(true);
                    } else {
                      setDocumentShow(true);
                    }
                  }}
                  className="fs-14 fw-medium text-start"
                  style={{ color: '#D57D2A' }}
                >
                  {file.fileName}
                </button>
              ))}
            </div>
          </div>
        </div>

        <Modal show={imageShow} onHide={handleClose} centered>
          <Modal.Body>
            <div className="delate-content p-2">
              <div className="d-flex align-items-center gap-4">
                <span className="fs-16 fw-bold">Image</span>
                <span className="fs-14" style={{ color: '#72777A' }}>
                  {assetState.assetPhoto.fileName}
                </span>
              </div>
              <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
                <div className="col-md-12">
                  <img src={assetState.assetPhoto.url} alt="" style={{ width: '100%' }} />
                </div>
              </div>
              <div
                className="button-group"
                style={{
                  marginTop: '25px',
                  display: 'flex',
                  justifyContent: 'end',
                  gap: '30px',
                }}
              >
                <a
                  href={assetState.assetPhoto.url}
                  download
                  className="cancel-btn download-btn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download
                </a>
                <button className="delate-btn" onClick={handleClose}>
                  Close
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={videoShow} onHide={handleClose} centered size="lg">
          <Modal.Body>
            <div className="delate-content p-2">
              <div className="d-flex align-items-center gap-4">
                <span className="fs-16 fw-bold">Video</span>
                <span className="fs-14" style={{ color: '#72777A' }}>
                  {assetState.assetPhoto.fileName}
                </span>
              </div>
              <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
                <div className="col-md-12">
                  <video width="100%" height="auto" controls>
                    <source src={assetState.assetPhoto.url} type={assetState.assetPhoto.fileType} />
                  </video>
                </div>
              </div>
              <div
                className="button-group"
                style={{
                  marginTop: '25px',
                  display: 'flex',
                  justifyContent: 'end',
                  gap: '30px',
                }}
              >
                <a
                  href={assetState.assetPhoto.url}
                  download
                  className="cancel-btn download-btn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download
                </a>
                <button className="delate-btn" onClick={handleClose}>
                  Close
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={documentShow} onHide={handleClose} centered size="lg">
          <Modal.Body>
            <div className="delate-content p-2">
              <div className="d-flex align-items-center gap-4">
                <span className="fs-16 fw-bold">Document</span>
                <span className="fs-14" style={{ color: '#72777A' }}>
                  {assetState.assetPhoto.fileName}
                </span>
              </div>
              <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
                <div className="col-md-12">
                  <iframe
                    title="pdf-viewer"
                    src={assetState.assetPhoto.url}
                    width="100%"
                    height="500px"
                  ></iframe>
                </div>
              </div>
              <div
                className="button-group"
                style={{
                  marginTop: '25px',
                  display: 'flex',
                  justifyContent: 'end',
                  gap: '30px',
                }}
              >
                <a
                  href={assetState.assetPhoto.url}
                  download
                  className="cancel-btn download-btn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download
                </a>
                <button className="delate-btn" onClick={handleClose}>
                  Close
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <div className="details-buttons pt-lg-5 d-flex justify-content-between">
          <Link to="/assets/create-new-asset/documents" className="next-btn ms-0">
            Previous
          </Link>
          <button onClick={handleSubmit} className="next-btn ms-0" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateSummary;
