/* eslint-disable no-template-curly-in-string */
import React, { useMemo } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  DetailRow,
  Inject,
  Page,
} from "@syncfusion/ej2-react-grids";
import { format } from "date-fns";
import './Syncfusion.scss'
import { Spinner } from "react-bootstrap";

const EquipmentPmPerformanceGrid = ({
    workOrder,
    filters,
    categories,
    categoryOfWorkIds,
    isLoading
}) => {

    const data = workOrder?.data || [];
    console.log(
      "user pm performance",
      data.map((user) => user.pmPerformanceList).flat()
    );
  
    const formatDate = (dateString) => {
      return format(new Date(dateString), "dd/MM/yyyy");
    };
  
    const getCategoryNames = () => {
      return categoryOfWorkIds.map(
        (id) =>
          categories.find((category) => category.id === id)?.categoryOfWorkName ??
          ""
      );
    };
  
    const formattedFilters = useMemo(() => {
      if (!filters) return null;
  
      return {
        startDate: formatDate(filters["filter.StartDate"]),
        endDate: formatDate(filters["filter.EndDate"]),
        calendarGrouping: filters["filter.CalendarGrouping"],
        categoryOfWork: getCategoryNames(
          filters["filter.CategoryOfWorkIds"]
        ).join(", "),
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);
  
  
  let flattenedData = [];
  
  data.forEach(team => {
    if (team.pmPerformanceList && team.pmPerformanceList.length > 0) {
        team.pmPerformanceList.forEach(performance => {
            flattenedData.push({
                // teamId: team.teamId,
                // teamName: team.teamName,
                assetCategoryId: team.assetCategoryId,
                assetCategoryName: team.assetCategoryName,
                calendarPeriod: performance.calendarPeriod,
                scheduled: performance.scheduled,
                completed: performance.completed,
                onHold: performance.onHold,
                mtfr: performance.mtfr
            });
        });
    }
  });
  
  console.log('flattened array',flattenedData);
  return (
    <div>
      {filters && isLoading ? (
        <div style={{display: "flex", width: "100%", justifyContent: "center", alignItems: "center"}}>
        <Spinner animation="border" />
      </div>
      ) : (
        <div id="user-pm-performance">
      <h3>PM Performance by Equipment Report</h3>
      <div
        className=""
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gridColumnGap: "20px",
          gridRowGap: "4px",
          fontSize: "14px",
          fontWeight: 400,
          marginBottom: "10px",
          width: "60%",
        }}
      >
        <p style={{ color: "gray" }}>Start Date: <span style={{fontWeight: "bold", color: "black"}}>{formattedFilters?.startDate || ""}</span> </p>
        <p style={{ color: "gray" }}>Category of Work: <span style={{fontWeight: "bold", color: "black"}}>{formattedFilters?.categoryOfWork || "All"}</span></p>
        <p style={{ color: "gray" }}>End Date: <span style={{fontWeight: "bold", color: "black"}}>{formattedFilters?.endDate || ""}</span></p>
        <p style={{ color: "gray" }}>Calendar Grouping: <span style={{fontWeight: "bold", color: "black"}}>{formattedFilters?.calendarGrouping || "None"}</span></p>
      </div>
      <GridComponent
        dataSource={data}
        childGrid={{
          dataSource: flattenedData,
          queryString: "assetCategoryName",
          columns: [
            {
              field: "calendarPeriod",
              headerText: "Calendar Period",
              width: 120,
            },
            { field: "scheduled", headerText: "Scheduled", width: 120 },
            { field: "completed", headerText: "Completed", width: 120 },
            { field: "onHold", headerText: "Waiting for Parts", width: 120 },
            { field: "mtfr", headerText: "MTRF", width: 120 },
          ],
        }}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="assetCategoryName"
            headerText="Equipment PM Perforrmance"
            width="200"
            style={{ backgroundColor: "#d57d2ade" }}
          />
        </ColumnsDirective>
        <Inject services={[DetailRow, Page]} />
      </GridComponent>
    </div>
      )}
    </div>
    
  )
}

export default EquipmentPmPerformanceGrid