import React, { useContext, useEffect, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate, useParams } from "react-router-dom";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import { get, post } from "../../../Services/ApiHelper";
import {
  EDIT_PM_TEMPLATE,
  GET_ASSET_CATEGORIES,
  GET_CALENDAR_TYPES,
  GET_CATEGORY_OF_WORKS,
  GET_CHECKLISTS,
  GET_PRIORITIES,
} from "../../../utls/constants";
import { set } from "lodash";
import { toast } from "react-toastify";
import { TemplateDetailsContext } from "../../../Context/Inspections/WorkOrderContext";

const EditTemplateDetailsModal = (props) => {
  const { template, setTemplate } = useContext(TemplateDetailsContext);
  const [selectedWork, setselectedWork] = useState("Select");
  const [selectedCOW, setselectedCOW] = useState(
    template?.details?.categoryOfWorkName
  );
  const [categoryOfWorkId, setCategoryOfWorkId] = useState(
    template?.details?.categoryOfWorkId
  );
  const [assetCategoryId, setAssetCategoryId] = useState(
    template?.details?.assetCategoryId
  );
  const [selectTeam, setSelectTeam] = useState("Pump Maintenance");
  const [selectAssetCategoryName, setselectAssetCategoryName] = useState(
    template?.details?.assetCategoryName
  );
  const [selectedPriority, setSelectedPriority] = useState(
    template?.details?.priorityName
  );
  const [priorityId, setPriorityId] = useState(
    template?.details?.ticketPriorityId
  );
  const [selectedday, setselectedday] = useState(
    template?.details?.calendarTypeName
  );
  const [daySearch, setdaySearch] = useState("");
  const [name, setName] = useState(template?.details?.templateName);
  const [description, setDescription] = useState(
    template?.details?.description
  );
  const [occurrence, setoccurrence] = useState(template?.details?.recursEvery);
  const [estimate, setestimate] = useState(template?.details?.estimatedHours);
  const [categoryOdWorks, SetCategoryOfWorks] = useState([]);
  const [workData, setWorkData] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [calenderType, setCalenderType] = useState([]);
  const [checkList, setCheckList] = useState([]);
  const [COWSearch, setCOWSearch] = useState("");
  let [submit, setSubmit] = useState(false);
  const [workSearch, setWorkSearch] = useState("");
  const [descriptionStatus, setdescriptionStatus] = useState(true);
  const [priorityStatus, setPriorityStatus] = useState(true);
  const [estimateStatus, setEstimateStatus] = useState(true);
  const [calendarTypeId, setCalendarTypeId] = useState(
    template?.details?.calendarTypeId
  );

  const [recerrenceStatus, setRecerrenceStatus] = useState(true);
  const [nameStatus, setnameStatus] = useState(true);
  const [ddayStatus, setDdayStatus] = useState(true);
  useEffect(() => {
    setselectedCOW(template?.details?.categoryOfWorkName);
    setselectAssetCategoryName(template?.details?.assetCategoryName);
    setselectedday(template?.details?.calendarTypeName);
    setSelectedPriority(template?.details?.priorityName);

    setCategoryOfWorkId(template?.details?.categoryOfWorkId);
    setAssetCategoryId(template?.details?.assetCategoryId);
    setCalendarTypeId(template?.details?.calendarTypeId);
    setPriorityId(template?.details?.ticketPriorityId);
    setoccurrence(template?.details?.recursEvery);
    setestimate(template?.details?.estimatedHours);
    setName(template?.details?.templateName);
    setDescription(template?.details?.description);

    get(GET_CATEGORY_OF_WORKS, bearerToken)
      .then((response) => {
        SetCategoryOfWorks(response);
      })
      .catch((error) => console.log("An error occured"));

    get(GET_PRIORITIES, bearerToken)
      .then((response) => {
        setPriorities(response);
      })
      .catch((error) => console.log(error));

    get(GET_CALENDAR_TYPES, bearerToken)
      .then((response) => {
        setCalenderType(response.data);
      })
      .catch((error) => console.log(error));

    get(GET_ASSET_CATEGORIES, bearerToken)
      .then((response) => {
        setWorkData(response);
      })
      .catch((error) => console.log(error));

    get(GET_CHECKLISTS, bearerToken)
      .then((response) => {
        setCheckList(response["data"]);
      })
      .catch((error) => console.log("An error occured"));
  }, [props]);
  const handlePriorityClick = (priority, priorityId) => {
    setSubmit(true);
    setSelectedPriority(priority);
    setPriorityId(priorityId);
    // setSelectValue({ ...selectValue, priority: priority },()=>{});
    // updateLocalStorage()
  };

  const handleSelect = (eventKey) => {
    const teamObject = filteredDayData?.find((item) => item.name === eventKey);

    setselectedday(eventKey);
    setCalendarTypeId(teamObject.id);
    // setSelectValue({ ...selectValue, calenderType: eventKey });
    // setdaySearch("");
  };

  const filteredDayData = calenderType?.filter((item) => {
    return item?.name?.toLowerCase().includes(daySearch.toLowerCase());
  });

  //get search input
  const [selectTeamSearch, setSelectTeamSearch] = useState("");
  const [selectAssetCategoryNameSearch, setselectAssetCategoryNameSearch] =
    useState("");

  //filter via search input
  const filteredCOWData = categoryOdWorks?.filter((item) =>
    item?.categoryOfWorkName
      .toLowerCase()
      .includes(selectTeamSearch.toLowerCase())
  );
  const filteredWorkData = workData?.filter((item) => {
    return item?.assetCategoryName
      ?.toLowerCase()
      .includes(workSearch.toLowerCase());
  });

  //handle select
  const handleCOWSelect = (eventKey) => {
    const teamObject = filteredCOWData?.find(
      (item) => item.categoryOfWorkName === eventKey
    );

    setSubmit(true);
    setselectedCOW(eventKey);
    setCategoryOfWorkId(teamObject.id);
    // setSelectValue({ ...selectValue, cow: eventKey }, () => {});
    setCOWSearch("");
    // updateLocalStorage();
  };
  const bearerToken = localStorage.getItem("bearerToken");
  const handleWorkSelect = (eventKey) => {
    const teamObject = filteredWorkData?.find(
      (item) => item.assetCategoryName === eventKey
    );

    setSubmit(true);
    setAssetCategoryId(teamObject.id);
    setselectedWork(eventKey);
    // setSelectValue({ ...selectValue, category: eventKey },()=>{});
    setWorkSearch("");
    // updateLocalStorage()
  };
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const handleSave = (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (name === "") {
      setnameStatus(false);
    } else if (description === "") {
      setdescriptionStatus(false);
    } else if (estimate === 0) {
      setEstimateStatus(false);
    } else if (occurrence === 0) {
      setRecerrenceStatus(false);
    } else {
      // setSubmit(false);
      const data = {
        id: id,
        templateName: name,
        templateDescription: description,
        assetCategoryId: assetCategoryId,
        calendarTypeId: calendarTypeId,
        categoryOfWorkId: categoryOfWorkId,
        estimatedHours: estimate,
        recurring: occurrence,
        ticketPriorityId: priorityId,
      };
      post(EDIT_PM_TEMPLATE, data, localStorage.getItem("bearerToken"))
        .then((response) => {
          const contextData = {
            templateName: name,
            templateDescription: description,
            categoryOfWorkName: selectedCOW,
            assetCategoryName: selectAssetCategoryName,
            estimatedHours: estimate,
            priorityName: selectedPriority,
            recursEvery: occurrence,
          };
          if (response.succeeded === true) {
            setTemplate((prevTemplate) => ({
              ...prevTemplate,
              details: contextData,
            }));
            props.onHide();
            toast.success(response.messages[0], {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            props.onHide();
            toast.error(response.messages[0], {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
      // setTemplate((prevTemplate) => ({
      //   ...prevTemplate,
      //   details: data,
      // }));
    }
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal modal-size-780"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-md-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Edit Template Detailsk</span>
            <div className="fs-14 pt-2 pt-md-0" style={{ color: "#72777A" }}>
              Edit the template details
            </div>
          </div>
          <div className="mt-4 pt-2 row row-gap-4 modal-forms-content">
            <div className="col-md-12">
              <label className="fw-medium pb-2">Template Name</label>
              {!nameStatus && (
                <p
                  style={{ color: "red", fontSize: "0.9em", fontWeight: "300" }}
                >
                  Required *
                </p>
              )}
              <input
                className="modal-input-box"
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "0 15px",
                }}
                placeholder=""
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </div>
            <div className="col-md-12">
              <label className="fw-medium pb-2">Description</label>
              {!descriptionStatus && (
                <p
                  style={{ color: "red", fontSize: "0.9em", fontWeight: "300" }}
                >
                  Required *
                </p>
              )}
              <textarea
                className="modal-input-box"
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "90px",
                  borderRadius: "5px",
                  padding: "15px 15px",
                }}
                placeholder=""
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            </div>
            <div className="col-md-6 ">
              <label>Category of Work</label>

              <Dropdown className="select__form" onSelect={handleCOWSelect}>
                <Dropdown.Toggle
                  className={`select-title ${
                    selectedCOW !== "Select" ? "selected" : ""
                  }`}
                >
                  {selectedCOW}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      value={workSearch}
                      onChange={(e) => setWorkSearch(e.target.value)}
                      type="text"
                      placeholder="Search"
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filteredCOWData.map((item, index) => (
                      <Dropdown.Item
                        key={index}
                        eventKey={item.categoryOfWorkName}
                      >
                        {item.categoryOfWorkName}
                      </Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-6">
              <label className="fw-medium pb-2">Asset Category</label>

              <Dropdown className="select__form" onSelect={handleWorkSelect}>
                <Dropdown.Toggle
                  className={`select-title ${
                    selectAssetCategoryName !== "Pumps & Dispenser"
                      ? "selected"
                      : ""
                  }`}
                  style={{ height: "50px" }}
                >
                  {selectAssetCategoryName}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      onChange={(e) => setWorkSearch(e.target.value)}
                      type="text"
                      placeholder="Search"
                      value={selectAssetCategoryNameSearch}
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filteredWorkData.map((item, index) => (
                      <Dropdown.Item
                        key={index}
                        eventKey={item.assetCategoryName}
                      >
                        {item.assetCategoryName}
                      </Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-lg-6">
              <label className="fw-medium pb-2">Work recurs every</label>
              {recerrenceStatus && ddayStatus ? (
                ""
              ) : (
                <p
                  style={{
                    color: "red",
                    fontSize: "0.9em",
                    fontWeight: "300",
                  }}
                >
                  Required *
                </p>
              )}
              <div className="d-flex justify-content-between gap-3 w-100">
                <input
                  className="modal-input-box"
                  type="number"
                  style={{
                    background: "#F1EFEF",
                    width: "100px",
                    border: "0",
                    height: "50px",
                    borderRadius: "5px",
                    padding: "0 15px",
                  }}
                  min={0}
                  placeholder=""
                  value={
                    occurrence == ""
                      ? template?.details?.recursEvery
                      : occurrence
                  }
                  onChange={(e) => setoccurrence(e.target.event)}
                  inputValue={
                    occurrence == "" ? template?.details?.recursEvery : estimate
                  }
                />
                <Dropdown
                  className="select__form w-100"
                  onSelect={handleSelect}
                >
                  <Dropdown.Toggle
                    style={{ height: "50px" }}
                    className={`select-title ${
                      selectedday !== "Select" ? "selected" : ""
                    }`}
                  >
                    {selectedday}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {filteredDayData.map((item, index) => (
                      <Dropdown.Item key={index} eventKey={item.name}>
                        {item.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="col-lg-6">
              <label className="fw-medium pb-2">Time Estimate Hours</label>
              {!estimateStatus && (
                <p
                  style={{ color: "red", fontSize: "0.9em", fontWeight: "300" }}
                >
                  Required *
                </p>
              )}
              <input
                className="modal-input-box"
                type="number"
                min={0}
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "0 15px",
                }}
                placeholder=""
                onChange={(e) => setestimate(e.target.value)}
                value={
                  estimate == "" ? template?.details?.estimatedHours : estimate
                }
              />
            </div>
            <div className="col-lg-7">
              <label className="fw-medium pb-2">Priority</label>
              <ul className="priority-list">
                {priorities?.map((item) => {
                  return (
                    <li key={item.id}>
                      <button
                        className={
                          selectedPriority === item?.ticketPrioritiesName
                            ? "active"
                            : ""
                        }
                        onClick={() => {
                          setSubmit(true);
                          handlePriorityClick(
                            item?.ticketPrioritiesName,
                            item?.id
                          );
                        }}
                      >
                        {item?.ticketPrioritiesName}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            {isLoading ? (
              <Spinner animation="border" style={{ color: "#D57D2A" }} />
            ) : (
              <button className="delate-btn" onClick={handleSave}>
                Save
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditTemplateDetailsModal;
