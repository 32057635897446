import React from "react";
import notfoundlogo from "../Assets/Images/notfound.png";
import { Image } from "react-bootstrap";
import { Link, useNavigate} from "react-router-dom";

export default function PageNotFound() {
    const navigate = useNavigate();
    return (
        <div>
            <div style={{ textAlign: "center", marginTop: "50px"}}>
                <Image src={notfoundlogo} fluid style={{width: "30%"}} />
           </div>
           <div className="details-buttons" style={{ justifyContent: "center", marginTop: "20px"}}>
                <Link to="/inspection-category" className="next-btn">Back to Home</Link>
            </div>
         </div>
    );
};