/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import DownIcon from "../../Assets/Icons/DownIcon";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import { HiDotsVertical } from "react-icons/hi";
import ViewIcon2 from "../../Assets/Icons/ViewIcon2";
import EditIcon2 from "../../Assets/Icons/EditIcon2";
import DelateIcon2 from "../../Assets/Icons/DelateIcon2";
import ArrowLeft from "../../Assets/Icons/ArrowLeft";
import ArrowRight from "../../Assets/Icons/ArrowRight";
import DropdownIcon from "../../Assets/Icons/DropdownIcon";
import getUniqueProperty from "../../utls/getUniqueProprty";
import filterViaProperty from "../../utls/filterViaProperty";
import searchAllProperty from "../../utls/searchAllProperty";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import DeleteModal from "../WorkOrders/Modal/DeleteModal";
import assImg from "../../Assets/Images/Gilbarco1.png";
import assImg2 from "../../Assets/Images/Gilbarco2.png";
import transfer from "../../Assets/Images/transfer.svg";
import EditAssetCategoryModal from "../WorkOrders/Modal/EditAssetCategoryModal";
import AddAssetCategoryModal from "../WorkOrders/Modal/AddAssetCategoryModal";
import AssetTransferModal from "../WorkOrders/Modal/AssetTransferModal";
import generateTextLogoFromName from "../../utls/generateTextLogo";
import usefetchData from "../../hooks/useFetchData";
import { format } from "date-fns";
import qs from "qs";
import { flattenObject } from "../../utls/flattenObject";
import { Dropdown } from "react-bootstrap";
import useMutateData from "../../hooks/useMutateData";
import { toast } from "react-toastify";

const AssetsContent = () => {
  const [selectedmodel, setSelectedmodel] = useState(""); //model Select
  const [assetCategory, setAssetCategory] = useState(""); //Asset Category
  const [selectedAsset, setSelectedAsset] = useState(""); //Asset
  const [selectedLocation, setSelectedLocation] = useState(""); //Location
  const [selectedCondition, setSelectedCondition] = useState(""); //Condition
  const [selectedStatus, setSelectedStatus] = useState(""); //Status
  const [type, setType] = useState(""); //Type Select
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("");
  const [currentPage, setCurrentPage] = useState(1); //for pagination
  const [RequestsPerPage, setRequestsPerPage] = useState(10); //for pagination
  const [search, setSearch] = useState(""); //for search
  const [RequestsShow, setRequestsShow] = useState(false); //for modal
  const [editCategoryShow, setEditCategoryShow] = useState(false); //for modal
  const [transferShow, setTransferShow] = useState(false); //for modal
  const [filters, setFilters] = useState({});
  const [locationId, setLocationId] = useState(null);
  const [assetMakeId, setAssetMakeId] = useState(null);
  const [assetConditionId, setAssetConditionId] = useState(null);
  const [assetCategoryId, setAssetCategoryId] = useState(null);
  const [assetStatusId, setAssetStatusId] = useState(null);
  const [assetId, setAssetId] = useState(null);
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchTerm, setSearchTerm] = useState("");
  const [selectValue, setSelectValue] = useState({
    location: "Location",
    assetCategory: "",
    make: "",
    condition: "",
    status: "",
  });


  // GET asset data api request
  const { data, isLoading } = usefetchData(
    ["asset-data", filters],
    `/Assets/GetAllAssets`,
    {
      params: {
        ...filters,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    },
    "Failed to get asset data",
    true,
    true
  );
    // handle filter change to refetch data
    useEffect(() => {
      const filterObject = {
        filter: {
          AssetMakeId: assetMakeId,
          AssetCategoryId: assetCategoryId,
          AssetConditionId: assetConditionId,
          LocationId: locationId,
          AssetStatusId: assetStatusId,
        },
        pageNumber,
        pageSize,
        sortOrder,
        search
      };
  
      setFilters(flattenObject(filterObject));
    }, [assetCategoryId, assetConditionId, assetMakeId, assetStatusId, locationId, pageSize, pageNumber, sortOrder, search]);

  const assetData = data?.data;

  // get asset category data
  const { data: categoryOfWork, isLoading: isLoadingCow } = usefetchData(
    ["asset-category"],
    `/Assets/Categories`,
    {},
    "Error fetching category of work"
  );

  // get location filter data
  const { data: locationData, isLoading: isLoadingLocation } = usefetchData(
    ["location"],
    `/Locations/GetSimpleLocationList`,
    {},
    "Error getting location data",
    true
  );

  // get asset conditions data data
  const { data: conditions, isLoading: isLoadingCondition } = usefetchData(
    ["asset-condition"],
    `/Assets/GetAllAssetConditions`,
    {},
    "Error fetching category of work"
  );

  // get asset status data data
  const { data: status, isLoading: isLoadingStatus } = usefetchData(
    ["asset-status"],
    `/Assets/GetAllAssetStatuses`,
    {},
    "Error fetching category of work"
  );

  // get asset make data data
  const { data: models, isLoading: isLoadingModel } = usefetchData(
    ["asset-make"],
    `/Assets/GetAllAssetMakes`,
    {},
    "Error fetching category of work"
  );



  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder((prevSortOrder) =>
        prevSortOrder === "asc" ? "desc" : "asc"
      );
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const sortedRequestsData =
    assetData &&
    assetData?.sort((a, b) => {
      if (sortBy) {
        const valueA = a[sortBy];
        const valueB = b[sortBy];
        return sortOrder === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }
      return 0;
    });

  const handlemodelClick = (model) => {
    setSelectedmodel(model.makeName);
    setAssetMakeId(model.id);
  };

  const handleCategoryClick = (category) => {
    console.log("category click", category);
    setAssetCategory(category.assetCategoryName);
    setAssetCategoryId(category.id);
  };

  const handleAssetClick = (asset) => {
    setSelectedAsset(asset);
  };

  // handle state setting for location filters
  const handleLocationClick = (location) => {
    setSelectedLocation(location.locationName);
    setLocationId(location.id);
  };

  // handle state change for condition filters
  const handleConditionClick = (condition) => {
    setSelectedCondition(condition.assetConditionName);
    setAssetConditionId(condition.id);
  };

  // handle state change for status filters
  const handleStatusClick = (status) => {
    setSelectedStatus(status.assetStatusName);
    setAssetStatusId(status.id);
  };

  // Update current page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // delete asset mutation
  const { mutate, isLoading: isDeletingAsset } = useMutateData({
    url: `/Assets/DeleteAsset`,
    method: "POST",
    onSuccessfullMutation: (data) => {
      console.log("Recall mutation data", data);
      if (data?.data?.succeeded === true) {
        toast.success(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setRequestsShow(false); // Hide delete modal
      } else {
        toast.error(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
    successMessage: `Successfully recalled costing`,
    errorMessage: "Work order creation failed",
    queryKeysToInvalidate: [["asset-data", filters]],
  });

  const onDelete = async () => {
    const requestBody = {
      id: assetId,
    };
    mutate(requestBody);
  };

  console.log("page number", pageNumber)

  return (
    <>
      <div className="selection-grope">
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Make</span>
            <span className="fs-14 d-flex align-items-center gap-1">
              {selectedmodel} <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu select-menu">
            <li onClick={() => handlemodelClick("")}>
              <b>All</b>
            </li>
            <hr />
            <div className="dropdown-item-content">
              {!isLoadingModel && models?.map((model, i) => (
                <li key={model.id} onClick={() => handlemodelClick(model)}>
                  {model.makeName}
                </li>
              ))}
            </div>
          </ul>
        </div>

        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Category</span>
            <span className="fs-14 d-flex align-items-center gap-1">
              {assetCategory} <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu select-menu">
            <li onClick={() => handleCategoryClick("")}>
              <b>All</b>
            </li>
            <hr />
            <div className="dropdown-item-content">
              {!isLoadingCow && categoryOfWork?.length > 0 && categoryOfWork?.map((category, i) => (
                <li
                  key={category.id}
                  onClick={() => handleCategoryClick(category)}
                >
                  {category.assetCategoryName}
                </li>
              ))}
            </div>
          </ul>
        </div>
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Location</span>
            <span className="fs-14 d-flex align-items-center gap-1">
              {selectedLocation} <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu select-menu">
            <li onClick={() => handleLocationClick("")}>
              <b>All</b>
            </li>
            <hr />
            <div className="dropdown-item-content">
              {!isLoadingLocation && locationData?.length > 0 && locationData?.map((location, i) => (
                <li key={i} onClick={() => handleLocationClick(location)}>
                  {location?.locationName}
                </li>
              ))}
            </div>
          </ul>
        </div>

        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Condition</span>
            <span className="fs-14 d-flex align-items-center gap-1">
              {selectedCondition} <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu select-menu">
            <li onClick={() => handleConditionClick("")}>
              <b>All</b>
            </li>
            <hr />
            <div className="dropdown-item-content">
              {!isLoadingCondition && conditions?.length > 0 && conditions?.map((condition, i) => (
                <li key={i} onClick={() => handleConditionClick(condition)}>
                  {condition?.assetConditionName}
                </li>
              ))}
            </div>
          </ul>
        </div>

        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Status</span>
            <span className="fs-14 d-flex align-items-center gap-1">
              {selectedStatus} <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu select-menu">
            <li onClick={() => handleStatusClick("")}>
              <b>All</b>
            </li>
            <hr />
            <div className="dropdown-item-content">
              {!isLoadingStatus && status?.length > 0 && status?.map((status, i) => (
                <li key={i} onClick={() => handleStatusClick(status)}>
                  {status?.assetStatusName}
                </li>
              ))}
            </div>
          </ul>
        </div>

        <form action="" className="search__box">
          <button disabled type="submit">
            <SearchIcon />
          </button>
          <input
            onChange={(e) => setSearch(e.target.value)}
            type="search"
            placeholder="Search"
          />
        </form>
      </div>
      <div className="inspection-tables-content">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th
                  className={
                    sortBy === "asset"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("asset")}
                >
                  <span>Asset</span>
                </th>
                <th
                  className={
                    sortBy === "serial"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("serial")}
                >
                  <span>Serial</span>
                </th>
                <th
                  className={
                    sortBy === "make" ? `table-sort-${sortOrder}` : "table-sort"
                  }
                  onClick={() => handleSort("make")}
                >
                  <span>Make & Model</span>
                </th>
                <th
                  className={
                    sortBy === "condition"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("condition")}
                >
                  <span>Category & Condition</span>
                </th>
                <th
                  className={
                    sortBy === "location"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("location")}
                >
                  <span>Location</span>
                </th>
                <th>Status</th>
                <th className="table-th"></th>
              </tr>
            </thead>
            <tbody>
              {!isLoading && assetData?.length > 0 && assetData?.map((item, i) => (
                <tr key={i}>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <div className="assets-img">
                        {item.assetPhotoURL ? (
                          <img
                            src={item.assetPhotoURL}
                            alt={item.assetPhotoURL}
                            className="object-fit-contain"
                            style={{ width: '40px', height: '40px' }}
                          />
                        ) : (
                          <span className="assetText">
                            {generateTextLogoFromName(item.assetName)}
                          </span>
                        )}
                      </div>
                      <div>
                        <div className="fw-semibold">{item.assetName}</div>
                        <div className="fw-regular gray-c">
                          ID: {item.assetBarcode}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="fw-semibold">{item.serialNumber}</div>
                    <div className="fw-regular gray-c">
                      Tag: {item.saharaDeskTag}
                    </div>
                  </td>
                  <td>
                    <div className="fw-semibold">{item.makeName}</div>
                    <div className="fw-regular gray-c">
                      Model: {item.modelName}
                    </div>
                  </td>
                  <td>
                    <div className="fw-semibold">{item.assetCategoryName}</div>
                    <div className="fw-regular gray-c">
                      Condition: {item.assetConditionName}
                    </div>
                  </td>
                  <td>
                    <div className="fw-semibold">{item.assetLocationName}</div>
                    <div className="fw-regular gray-c">
                      Parent: {item.parentAssetName}
                    </div>
                  </td>
                  <td>
                    <div
                      className={`fw-semibold asset-active ${item.assetStatusName}`}
                    >
                      {item.assetStatusName}
                    </div>
                    <div className="fw-regular gray-c">
                      Created:{" "}
                      {item.createdDate &&
                        new Date(item.createdDate).toLocaleDateString()}
                    </div>
                  </td>
                  <td>
                    <div className="table-last-btn d-flex align-items-center justify-content-end">
                      {item.rejected && (
                        <span
                          style={{
                            background: "#FFBBBD",
                            color: "#F40",
                            fontSize: "12px",
                            fontWeight: "600",
                            padding: "5px 10px",
                            display: "inline-block",
                            borderRadius: "15px",
                            marginRight: "7px",
                          }}
                        >
                          {item.rejected}
                        </span>
                      )}
                      <div className="dropdown table-edit-dropdown">
                        <button className="" data-bs-toggle="dropdown">
                          <HiDotsVertical />
                        </button>

                        <ul className="dropdown-menu dropdown-menu-end">
                          <li>
                            <Link
                              className="dropdown-item"
                              to={`/assets/work-view/${item.id}`}
                            >
                              <ViewIcon2 /> View
                            </Link>
                          </li>
                          <li>
                            <button
                              className="dropdown-item"
                              onClick={() => {
                                setAssetId(item.id);
                                setRequestsShow(true);
                              }}
                            >
                              <DelateIcon2 /> Delete
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="table-pagination pagination-center">
          <div className="pagination-row">
            Rows per page:
            <div className="dropdown select-dropdown">
              <button className="select-title" data-bs-toggle="dropdown">
                <span className="fs-14 d-flex align-items-center gap-2">
                  {pageSize} <DropdownIcon />
                </span>
              </button>
              <ul
                className="dropdown-menu select-menu"
                style={{ minWidth: "25px" }}
              >
                <li onClick={() => setPageSize(5)}>5</li>
                <li onClick={() => setPageSize(10)}>10</li>
                <li onClick={() => setPageSize(15)}>15</li>
                <li onClick={() => setPageSize(20)}>20</li>
              </ul>
            </div>
          </div>
          <div className="pagination-number">
            <div className="fs-14">
              {pageNumber} of {data?.totalPages}
            </div>
            <div className="arrow-btn">
              <button
                onClick={() =>
                  pageNumber !== 1 && setPageNumber(pageNumber - 1)
                }
              >
                <ArrowLeft />
              </button>
              <button
                onClick={() =>
                  data?.totalPages !== pageNumber &&
                  setPageNumber(pageNumber + 1)
                }
              >
                <ArrowRight />
              </button>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        show={RequestsShow}
        onHide={() => setRequestsShow(false)}
        onDelete={onDelete}
        isDeleting={isDeletingAsset}
      />
      <AddAssetCategoryModal
        show={editCategoryShow}
        onHide={() => setEditCategoryShow(false)}
      />
      <AddAssetCategoryModal
        show={editCategoryShow}
        onHide={() => setEditCategoryShow(false)}
      />
      <AssetTransferModal
        show={transferShow}
        onHide={() => setTransferShow(false)}
      />
    </>
  );
};

export default AssetsContent;
