import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  assetName: '',
  assetDescription: '',
  assetCategoryId: 0,
  assetCategoryName: '',
  assetLocationId: 0,
  assetLocationName: '',
  assetStatusId: 0,
  assetStatusName: '',
  assetConditionId: 0,
  assetConditionName: '',
  serialNumber: '',
  saharaDeskTag: '',
  barCode: '',
  assetModelId: 0,
  assetModelName: '',
  parentAssetId: 0,
  assetPhoto: {
    encodedFile: '',
    fileName: '',
    url: '',
    fileType: ''
  },
  assetFiles: []
};

const assetSlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    setAssetName(state, action) {
      state.assetName = action.payload;
    },
    setAssetDescription(state, action) {
      state.assetDescription = action.payload;
    },
    setAssetCategory(state, action) {
      state.assetCategoryId = action.payload.id;
      state.assetCategoryName = action.payload.name;
    },
    setAssetLocation(state, action) {
      state.assetLocationId = action.payload.id;
      state.assetLocationName = action.payload.name;
    },
    setAssetStatus(state, action) {
      state.assetStatusId = action.payload.id;
      state.assetStatusName = action.payload.name;
    },
    setAssetCondition(state, action) {
      state.assetConditionId = action.payload.id;
      state.assetConditionName = action.payload.name;
    },
    setSerialNumber(state, action) {
      state.serialNumber = action.payload;
    },
    setSaharaDeskTag(state, action) {
      state.saharaDeskTag = action.payload;
    },
    setBarCode(state, action) {
      state.barCode = action.payload;
    },
    setAssetModel(state, action) {
      state.assetModelId = action.payload.id;
      state.assetModelName = action.payload.name;
    },
    setParentAssetId(state, action) {
      state.parentAssetId = action.payload;
    },
    setAssetPhoto(state, action) {
      state.assetPhoto = action.payload;
    },
    setAssetFiles(state, action) {
      state.assetFiles = action.payload;
    },
    addAssetFile(state, action) {
      state.assetFiles.push(action.payload);
    },
    removeAssetFile(state, action) {
      state.assetFiles = state.assetFiles.filter(file => file.fileName !== action.payload);
    },
    resetAssetState(state) {
      return initialState;
    }
  }
});

export const {
  setAssetName,
  setAssetDescription,
  setAssetCategory,
  setAssetLocation,
  setAssetStatus,
  setAssetCondition,
  setSerialNumber,
  setSaharaDeskTag,
  setBarCode,
  setAssetModel,
  setParentAssetId,
  setAssetPhoto,
  setAssetFiles,
  addAssetFile,
  removeAssetFile,
  resetAssetState
} = assetSlice.actions;

export default assetSlice.reducer;
