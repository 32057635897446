import React, { useState } from "react";
import DeleteIcon from "../../Assets/Icons/DeleteIcon";
import DownIcon from "../../Assets/Icons/DownIcon";
import EditIcon from "../../Assets/Icons/EditIcon";
import NameDescritpion from "./AddPMCom/NameDescritpion";
import Recurrence from "./AddPMCom/Recurrence";
import SearchIcon from "../../Assets/Icons/SearchIcon";

const AddPMCaregoryContent = () => {
  const [quantity, setQuantity] = useState(3);
  const [parts, setParts] = useState("Part A");
  const [amount, setAmount] = useState("@332,000");
  const [isEditPojectedPard, setIsPojectedPartEdit] = useState(false);

  const [selectValue, setSelectValue] = useState({
    asset: "Select",
    category: "Select",
  });

  
  return (
    <div className="order-details-content add-pm-content">
      <div className="fs-16 fw-semibold">Schedule Details</div>
      <div className="row details-forms-one mt-5">
        <NameDescritpion />
      </div>
      <hr />
      <div className="row recurrence-con">
        <Recurrence />
      </div>
      <hr />
      <div className="row completion-con">
        <div className="col-lg-6">
          <div className="select-box">
            <label>Asset Category</label>
            <select
              className="form-select gray-color"
              style={{ color: selectValue?.asset !== "Select" ? "#000" : "" }}
              onChange={(e) =>
                setSelectValue({ ...selectValue, asset: e.target.value })
              }
            >
              <option selected value="Select">
                Select
              </option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="select-box">
            <label>Category of Work</label>
            <select
              className="form-select gray-color"
              style={{
                color: selectValue?.category !== "Select" ? "#000" : "",
              }}
              onChange={(e) =>
                setSelectValue({ ...selectValue, category: e.target.value })
              }
            >
              <option selected value="Select">
                Select
              </option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
        </div>
      </div>
      <hr />
      <div className="row projected-con">
        <div className="col-lg-12">
          <div className="fs-16 fw-semibold projected-title">
            Projected Parts
          </div>
          <div className="parts-box table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Parts</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Amount(Ksh)</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="text"
                      className={isEditPojectedPard && "border"}
                      value={parts}
                      readOnly={!isEditPojectedPard}
                      onChange={(e) => setParts(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={quantity}
                      className={isEditPojectedPard && "border"}
                      max={100}
                      readOnly={!isEditPojectedPard}
                      onChange={(e) => setQuantity(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className={isEditPojectedPard && "border"}
                      value={amount}
                      readOnly={!isEditPojectedPard}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </td>
                  <td>
                    <div className="button-con">
                      <button
                        onClick={() =>
                          setIsPojectedPartEdit(!isEditPojectedPard)
                        }
                      >
                        <EditIcon />
                      </button>
                      <button>
                        <DeleteIcon />
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="text-end">
              <button className="add-part">Add Part</button>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="row checklists-con">
        <div className="col-lg-12">
          <div className="fs-16 fw-semibold">Tasks and Checklists</div>
          <div className="dropdown Checklists-dropdown">
            <button className="btn checklists-btn" data-bs-toggle="dropdown">
              Add Checklists <DownIcon />
            </button>
            <ul class="dropdown-menu">
              <li>
                <form className="dropdown-search">
                  <button type="submit">
                    <SearchIcon />
                  </button>
                  <input type="search" placeholder="Search" />
                </form>
              </li>
              <li>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="Pump"
                  />
                  <label className="form-check-label" for="Pump">
                    Pump Calibration Checklist
                  </label>
                </div>
              </li>
              <li>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="Compressor"
                  />
                  <label className="form-check-label" for="Compressor">
                    Compressor Checklist
                  </label>
                </div>
              </li>
              <li>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="Checklist"
                  />
                  <label className="form-check-label" for="Checklist">
                    Laptop Checklist
                  </label>
                </div>
              </li>
            </ul>
          </div>
          <div className="checklists-box">
            <div className="fs-15 checklists-title">Add Checklists</div>
            <ul className="checklists-list">
              <li>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="Nozzle"
                  />
                  <label className="form-check-label" for="Nozzle">
                    Pump Nozzle Checklist
                  </label>
                </div>
              </li>
              <li>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="Monitor"
                  />
                  <label className="form-check-label" for="Monitor">
                    Pump Monitor Checklist
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="details-buttons">
        <button className="next-btn">Save</button>
      </div>
    </div>
  );
};

export default AddPMCaregoryContent;
