import React, { useMemo, useRef } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Toolbar,
  PdfExport,
  Page,
  Aggregate,
  AggregateColumnsDirective,
  AggregateColumnDirective,
  AggregateDirective,
  AggregatesDirective,
} from "@syncfusion/ej2-react-grids";
import { Spinner } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import * as XLSX from "xlsx";
import { format } from "date-fns";

const PMPerformanceGrid = ({
  workOrder,
  isLoading,
  filters,
  categories,
  categoryOfWorkIds,
}) => {
  const workOrderData = workOrder?.data || [];
  const componentRef = useRef();

  const toolbarOptions = [];

  let gridInstance;

  const formatDate = (dateString) => {
    return format(new Date(dateString), "dd/MM/yyyy");
  };

  const getCategoryNames = () => {
    return categoryOfWorkIds.map(
      (id) =>
        categories.find((category) => category.id === id)?.categoryOfWorkName ??
        ""
    );
  };

  const formattedFilters = useMemo(() => {
    if (!filters) return null;

    return {
      startDate: formatDate(filters["filter.StartDate"]),
      endDate: formatDate(filters["filter.EndDate"]),
      calendarGrouping: filters["filter.CalendarGrouping"],
      categoryOfWork: getCategoryNames(
        filters["filter.CategoryOfWorkIds"]
      ).join(", "),
    };
  }, [filters]);

  console.log("formatted filters", formattedFilters);
  console.log("raw filters", filters); // Added this line to check the raw filters

  const handleExcelExport = () => {
    // Create a new workbook and a new worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([]);

    // Add custom headers
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        ["PM Performance Report"],
        [
          `Start Date: ${formattedFilters?.startDate} ?? ""}`,
          `Category of Work: ${formattedFilters?.categoryOfWork ?? "All"}`,
        ],
        [
          `End Date: ${formattedFilters?.endDate ?? ""}`,
          `Calendar Grouping: ${
            formattedFilters?.calendarGrouping ?? "Weekly"
          }`,
        ],
        [],
      ],
      { origin: "A1" }
    );

    // Convert work order data to sheet
    const dataSheet = XLSX.utils.json_to_sheet(workOrderData, { origin: -1 });

    // Append data to the main sheet
    XLSX.utils.sheet_add_json(ws, workOrderData, {
      origin: "A5",
      skipHeader: true,
    });

    // Merge both sheets
    Object.assign(ws, dataSheet);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "PM Performance Report");

    // Write the workbook
    XLSX.writeFile(wb, "PM_Performance_Report.xlsx");
  };

  const handlePdf = useReactToPrint({
    content: () => document.getElementById("pm-performance"),
  });

  const settings = { type: "Multiple" };

  const headerTemplate = () => {
    return (
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <div>
          <h2>PM Performance Report</h2>
          <div className="filters">
            <p>Work Category: {filters?.categoryOfWork ?? "All"}</p>
            <p>Contractor: {filters?.contractor ?? "All"}</p>
            <p>Station: {filters?.station ?? "All"}</p>
            <p>Status: {filters?.status ?? "All"}</p>
            <p>Start Date: {filters?.startDate ?? ""}</p>
            <p>End Date: {filters?.endDate ?? ""}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {filters && isLoading ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <div ref={componentRef} id="pm-performance">
            <GridComponent
              dataSource={workOrderData}
              ref={(g) => (gridInstance = g)}
              // allowPaging={true}
              pageSettings={{
                pageSize: workOrder?.pageSize || 10,
                pageCount: workOrder?.totalPages || 1,
                currentPage: workOrder?.currentPage || 1,
                enableQueryString: true,
              }}
              selectionSettings={settings}
              allowTextWrap={true}
            >
              <ColumnDirective
                template={headerTemplate}
                headerText="Report Info"
                width="800"
                textAlign="Center"
              />
              <h3>PM Performance Report</h3>
              <div
                className=""
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gridColumnGap: "20px",
                  gridRowGap: "4px",
                  fontSize: "14px",
                  fontWeight: 400,
                  marginBottom: "10px",
                  width: "60%",
                }}
              >
                <p style={{ color: "gray" }}>Start Date: <span style={{fontWeight: "bold", color: "black"}}>{formattedFilters?.startDate || ""}</span> </p>
                <p style={{ color: "gray" }}>Category of Work: <span style={{fontWeight: "bold", color: "black"}}>{formattedFilters?.categoryOfWork || "All"}</span></p>
                <p style={{ color: "gray" }}>End Date: <span style={{fontWeight: "bold", color: "black"}}>{formattedFilters?.endDate || ""}</span></p>
                <p style={{ color: "gray" }}>Calendar Grouping: <span style={{fontWeight: "bold", color: "black"}}>{formattedFilters?.calendarGrouping || "All"}</span></p>
              </div>
              <ColumnsDirective>
                <ColumnDirective
                  field="calendarPeriod"
                  headerText="Calendar Period"
                  width="150"
                  textAlign="Center"
                />
                <ColumnDirective
                  field="scheduled"
                  headerText="Scheduled"
                  width="150"
                  textAlign="Center"
                />
                <ColumnDirective
                  field="completed"
                  headerText="Completed"
                  width="150"
                  textAlign="Center"
                />
                <ColumnDirective
                  field="onHold"
                  headerText="On Hold"
                  width="150"
                  textAlign="Center"
                />
                <ColumnDirective field="mtfr" headerText="MTFR" width="150" textAlign="Center" />
              </ColumnsDirective>
              <AggregatesDirective>
                <AggregateDirective>
                  <AggregateColumnsDirective>
                    <AggregateColumnDirective
                      field="scheduled"
                      type="Sum"
                      format="N0"
                      footerTemplate={(props) => (
                        <div style={{ color: "black", padding: "5px" }}>
                          {props.Sum}
                        </div>
                      )}
                    />
                    <AggregateColumnDirective
                      field="completed"
                      type="Sum"
                      format="N0"
                      footerTemplate={(props) => (
                        <div style={{ color: "black", padding: "5px" }}>
                          {props.Sum}
                        </div>
                      )}
                    />
                    <AggregateColumnDirective
                      field="onHold"
                      type="Sum"
                      format="N0"
                      footerTemplate={(props) => (
                        <div style={{ color: "black", padding: "5px" }}>
                          {props.Sum}
                        </div>
                      )}
                    />
                    <AggregateColumnDirective
                      field="mtfr"
                      type="Sum"
                      format="N0"
                      footerTemplate={(props) => (
                        <div style={{ color: "black", padding: "5px" }}>
                          {props.Sum}
                        </div>
                      )}
                    />
                  </AggregateColumnsDirective>
                </AggregateDirective>
              </AggregatesDirective>
              <Inject services={[Toolbar, PdfExport, Aggregate, Page]} />
            </GridComponent>
          </div>
        </>
      )}
    </div>
  );
};

export default PMPerformanceGrid;
