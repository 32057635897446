import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { PiWarningCircleFill } from "react-icons/pi";
import { Spinner } from "react-bootstrap";
import EditRequestModal from "./EditRequestModal";

const CompleteRequestModal = ({ show, onHide }) => { 
    const [editSHow, setEditShow] = useState(false)

    const handleEdit = () => {
        setEditShow(!editSHow)
    }
  return (
    <>
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div>
            <span className="fs-16 fw-bold">REQUEST DATA INCOMPLETE</span>
            <span className="fs-14 ps-3">Request data incomplete error</span>
          </div>
          <div
            className="delate-warning"
            style={{
              background: "#F1EFEF",
              height: "267px",
              display: "grid",
              alignItems: "center",
              textAlign: "center",
              padding: "20px",
              marginTop: "30px",
              borderRadius: "5px",
            }}
          >
            <div>
              <PiWarningCircleFill
                style={{ color: "#D57D2A", fontSize: "70px" }}
              />
              <div
                className="fs-20 fw-medium"
                style={{ lineHeight: "1.4", marginTop: "10px" }}
              >
                This action is only possible after you complete the request data by ensuring you have capture the Faulty asset, Fault Description and Fault Location
              </div>
            </div>
          </div>
          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={onHide}>
              Cancel
            </button>
            <button className="delate-btn" onClick={handleEdit}>
            Edit Request
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    <EditRequestModal show={editSHow} onHide={handleEdit} />
    </>
  );
};

export default CompleteRequestModal;
