/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import DownIcon from "../../Assets/Icons/DownIcon";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import { HiDotsVertical } from "react-icons/hi";
import ViewIcon2 from "../../Assets/Icons/ViewIcon2";
import ArrowLeft from "../../Assets/Icons/ArrowLeft";
import ArrowRight from "../../Assets/Icons/ArrowRight";
import DropdownIcon from "../../Assets/Icons/DropdownIcon";
import getUniqueProperty from "../../utls/getUniqueProprty";
import filterViaProperty from "../../utls/filterViaProperty";
import searchAllProperty from "../../utls/searchAllProperty";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import {
  GET_RFQDIAGNOSIS_LIST,
  GET_SIMPLE_LOCATION_LIST,
} from "../../utls/constants";
import { get } from "../../Services/ApiHelper";
import { Spinner } from "react-bootstrap";

const DignosisQuotesTable = () => {
  const [selectedLocation, setSelectedLocation] = useState(null); //Location Select
  const [assetCategory, setAssetCategory] = useState(null); //Location Select
  const [submittedById, setSubmittedById] = useState(null); //Location Select
  const [modifiedById, setModifiedById] = useState(null); //Location Select
  const [requestActionId, setRequestActionId] = useState(null); //Location Select
  const [selectedStatus, setSelectedStatus] = useState(""); //Submitted Select
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1); //for pagination
  const [RequestsPerPage, setRequestsPerPage] = useState(10); //for pagination
  const [search, setSearch] = useState(""); //for search
  const [debouncedSearch, setDebouncedSearch] = useState(""); //for search

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const ExampleCustomInput = ({ value, onClick }) => (
    <button className="example-custom-input" onClick={onClick}>
      {value ? value : ""} <DownIcon />
    </button>
  );

  const [shortListLocations, setShortListLocations] = useState([]);

  useEffect(() => {
    get(GET_SIMPLE_LOCATION_LIST, localStorage.getItem("bearerToken"))
      .then((response) => {
        console.log("response", response);
        setShortListLocations(response);
      })
      .catch((err) => {});
  }, []);

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder((prevSortOrder) =>
        prevSortOrder === "asc" ? "desc" : "asc"
      );
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  console.log("selectedLocation", selectedLocation);

  const [diagnosisTableData, setDiagnosisTableData] = useState([]); //for table data
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("bearerToken");
  const [crudData, setCrudData] = useState([]);
  const [allResponses, setAllResponses] = useState({}); //Determines previous,next pages and total
  useEffect(() => {
    let newStartDate;
    let newEndDate;

    if (startDate != null ) { 
       newStartDate = startDate.toISOString();
    }
    if (endDate != null ) { 
     newEndDate = endDate.toISOString();
    }

    setIsLoading(true);
    get(
      GET_RFQDIAGNOSIS_LIST(
        RequestsPerPage,
        currentPage,
        debouncedSearch,
        assetCategory,
        newStartDate,
        newEndDate,
        selectedLocation,
        submittedById,
        modifiedById,
        sortOrder,
        requestActionId
      ),
      token
    )
      .then((res) => {
        setIsLoading(false);
        setDiagnosisTableData(res.data);
        setCrudData(res);
        setAllResponses(res);
      })
      .catch((error) => console.log("An error has occured"));
  }, [
    RequestsPerPage,
    currentPage,
    debouncedSearch,
    assetCategory,
    startDate,
    endDate,
    selectedLocation,
    submittedById,
    modifiedById,
    sortOrder,
    requestActionId,
  ]);

  console.log(crudData);

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle empty date strings

    const date = new Date(dateString);
    const day = date?.getDate().toString().padStart(2, "0");
    const month = (date?.getMonth() + 1).toString().padStart(2, "0");
    const year = date?.getFullYear().toString().slice(-2);

    return `${day}/${month}/${year}`;
  };

  const sortedRequestsData = diagnosisTableData?.sort((a, b) => {
    if (sortBy) {
      const valueA = a[sortBy];
      const valueB = b[sortBy];
      return sortOrder === "asc"
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    }
    return 0;
  });

  /* const handleAssetClick = (team) => {
    setSelectedAsset(team);
  }; */
  const handleLocationClick = (locationId) => {
    setSelectedLocation(locationId);
  };
  const handleStatusClick = (submitted) => {
    setSelectedStatus(submitted);
  };

  //get request locations & filter by location
  const locations = getUniqueProperty(sortedRequestsData, "locationName");
  const filterByLocation = filterViaProperty(
    sortedRequestsData,
    selectedLocation,
    "locationName"
  );

  //filter via start date if date is not null
  const filteredByStartDate = startDate
    ? filterByLocation.filter((item) => {
        const date = new Date(item.submitDate);
        return date.getTime() >= startDate.getTime();
      })
    : filterByLocation;

  //filter via end date if date is not null
  const filteredByEndDate = endDate
    ? filteredByStartDate.filter((item) => {
        const date = new Date(item.submitDate);
        return date.getTime() <= endDate.getTime();
      })
    : filteredByStartDate;

  // get unique submitted by & filter by submitted by
  const submittedBy = getUniqueProperty(filteredByEndDate, "status");
  const filteredBySubmitted = filterViaProperty(
    filteredByEndDate,
    selectedStatus,
    "status"
  );

  // filter via search
  const filteredBySearch = searchAllProperty(filteredBySubmitted, search);

  //for pagination
  const indexOfLastRequests = currentPage * RequestsPerPage;
  const indexOfFirstRequests = indexOfLastRequests - RequestsPerPage;
  const currentRequests = filteredBySearch?.slice(
    indexOfFirstRequests,
    indexOfLastRequests
  );

  // Calculate total pages
  const totalPages = Math.ceil(filteredBySearch?.length / RequestsPerPage);

  // Update current page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  console.log("locations diagnosis", submittedBy);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    const formattedDate = date.toISOString();

    if (endDate === null || endDate === undefined) {
      fetchDataWithStartDate(formattedDate, "");
    } else {
      fetchDataWithStartDate(formattedDate, endDate.toISOString());
    }
  };

  const fetchDataWithStartDate = (from, to) => {
    console.log("date");
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);

    const formattedDate = date.toISOString();
    if (startDate === null || startDate === undefined) {
      fetchDataWithEndDate("", formattedDate);
    } else {
      fetchDataWithEndDate(startDate.toISOString(), formattedDate);
    }
  };

  const [locationId, setLocationId] = useState(null);
  const [me, setMe] = useState(false);
  const fetchDataWithEndDate = (from, to) => {
    console.log("date");
  };

  const [searchTermLocation, setSearchTermLocation] = useState("");

  const handleSearchChangeLocation = (e) => {
    setSearchTermLocation(e.target.value);
    setCurrentPage(1);
  };

  const filteredLocations = shortListLocations?.filter((location) =>
    location?.locationName?.toLowerCase().includes(searchTermLocation.toLowerCase())
  );

  return (
    <>
      <div className="selection-grope">
        {/* <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Status</span>
            <span className="fs-14 d-flex align-items-center gap-1">
              {selectedStatus} <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu select-menu">
            <li onClick={() => handleStatusClick("")}>
              <b>All</b>
            </li>
            <hr /> */}
        {/* {submittedBy.map((submitted, i) => (
              <li
                className="text-nowrap"
                key={i}
                onClick={() => handleStatusClick(submitted)}
              >
                {submitted}
              </li>
            ))} */}
        {/* </ul>
        </div> */}
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Location</span>
            <span className="fs-14 d-flex align-items-center gap-1">
              {locationId === null
                ? "All"
                : shortListLocations?.find(
                    (location) => location?.id === selectedLocation
                  )?.locationName}{" "}
              <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu select-menu">
          <div
              className="dropdown-search"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                color: "#c5c7cd",
                position: "relative",
                alignItems: "center",
                borderRadius: "5px",
                border: "0.5px solid #f1efef",
                background: "#fff",
                marginBottom: "10px",
                padding: "5px",
                paddingLeft: "15px",
              }}
            >
              <SearchIcon />
              <input
                type="text"
                placeholder="Search"
                style={{ border: "none", outline: "none" }}
                className="dropDownSearch"
                value={searchTermLocation}
                onChange={handleSearchChangeLocation}
              />
            </div>
            <li
              onClick={() => {
                setSelectedLocation(null);
              }}
            >
              <b>All</b>
            </li>
            <hr />
            <div className="dropdown-item-content">
              {filteredLocations?.map((location, i) => (
                <li key={i} onClick={() => handleLocationClick(location?.id)} style={{ padding: "6px 0px", fontSize: "14px" }}>
                  {location.locationName}
                </li>
              ))}
            </div>
          </ul>
        </div>

        <div className="select-dropdown">
          <div className="select-title">
            <div className="fs-13">Date From:</div>
            <div className="format-date">
              <DatePicker
                maxDate={endDate}
                selected={startDate}
                onChange={(date) => handleStartDateChange(date)}
                customInput={<ExampleCustomInput />}
              />
            </div>
          </div>
        </div>

        <div className="select-dropdown">
          <div className="select-title">
            <div className="fs-13">Date To:</div>
            <div className="format-date">
              <DatePicker
                minDate={startDate}
                selected={endDate}
                onChange={(date) => handleEndDateChange(date)}
                customInput={<ExampleCustomInput />}
              />
            </div>
          </div>
        </div>

        <form action="" className="search__box">
          <button disabled type="submit">
            <SearchIcon />
          </button>
          <input
            type="search"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                setDebouncedSearch(search);
                setDebouncedSearch(search);
              }
            }}
          />
        </form>
      </div>
      <div className="inspection-tables-content">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th
                  className={
                    sortBy === "requestRef"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("requestRef")}
                >
                  <span>Ref</span>
                </th>
                <th>
                  <span>Fault</span>
                </th>
                <th
                  className={
                    sortBy === "locationName"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("locationName")}
                >
                  <span>Location</span>
                </th>
                <th
                  className={
                    sortBy === "rfqDiagnosisRequestStatus"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("rfqDiagnosisRequestStatus")}
                >
                  <span>Status</span>
                </th>
                <th
                  className={
                    sortBy === "submittedBy"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("submittedBy")}
                >
                  <span>Submitted By</span>
                </th>
                <th
                  className={
                    sortBy === "submittedDate"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("submittedDate")}
                >
                  <span>Date Submitted</span>
                </th>
                <th className="table-th"></th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan="9" className="text-center">
                    <Spinner animation="border" style={{ color: "#D57D2A" }} />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {diagnosisTableData?.map((item, i) => (
                  <tr key={i}>
                    <td>{item.requestRef}</td>
                    <td>{item.requestDetails}</td>
                    <td>{item.locationName}</td>
                    <td>{item.rfqDiagnosisRequestStatus}</td>
                    <td>{item.submittedBy}</td>
                    <td>{formatDate(item.submittedDate)}</td>
                    <td>
                      <div className="table-last-btn">
                        <div className="dropdown table-edit-dropdown">
                          <button className="" data-bs-toggle="dropdown">
                            <HiDotsVertical />
                          </button>
                          <ul className="dropdown-menu dropdown-menu-end">
                            <li>
                              <Link
                                className="dropdown-item"
                                to={`/diagnosis-quotes/view/${item?.id}`}
                              >
                                <ViewIcon2 /> View
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>

        <div className="table-pagination pagination-center">
          <div className="pagination-row">
            Rows per page:
            <div className="dropdown select-dropdown">
              <button className="select-title" data-bs-toggle="dropdown">
                <span className="fs-14 d-flex align-items-center gap-2">
                  {RequestsPerPage} <DropdownIcon />
                </span>
              </button>
              <ul
                className="dropdown-menu select-menu"
                style={{ minWidth: "25px" }}
              >
                <li onClick={() => setRequestsPerPage(5)}>5</li>
                <li onClick={() => setRequestsPerPage(10)}>10</li>
                <li onClick={() => setRequestsPerPage(15)}>15</li>
                <li onClick={() => setRequestsPerPage(20)}>20</li>
              </ul>
            </div>
          </div>
          <div className="pagination-number">
            <div className="fs-14">
              {currentPage} of {crudData?.totalPages}
            </div>
            <div className="arrow-btn">
              {crudData?.hasPreviousPage === true && (
                <button onClick={() => handlePageChange(currentPage - 1)}>
                  <ArrowLeft />
                </button>
              )}
              {crudData?.hasNextPage === true && (
                <button onClick={() => handlePageChange(currentPage + 1)}>
                  <ArrowRight />
                </button>
              )}
              {crudData?.hasPreviousPage === true && (
                <button onClick={() => handlePageChange(currentPage - 1)}>
                  <ArrowLeft />
                </button>
              )}
              {crudData?.hasNextPage === true && (
                <button onClick={() => handlePageChange(currentPage + 1)}>
                  <ArrowRight />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DignosisQuotesTable;
