import React, { useContext, useState } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { post } from "../../../Services/ApiHelper";
import { Add_Ticket_TO_ONHOLD, TICKET_ON_HOLD } from "../../../utls/constants";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";

const HoldModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState("");
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);

  const handleHold = async () => {
    setIsLoading(true);

    const bearerToken = localStorage.getItem("bearerToken");

    const data = {
      ticketId: ticketSummary?.workOrderSummary?.ticketId,
      comment: comment,
    };

    await post(TICKET_ON_HOLD, data, bearerToken)
      .then((response) => {
        console.log(response);
        if (response.succeeded === true) {
          setTicketSummary({
            ...ticketSummary,
            workOrderSummary: {
              ...ticketSummary.workOrderSummary,
              currentTicketActivity: "ON HOLD REQUESTED",
            },
          });
          props.onHide();
          setIsLoading(false);
          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          props.onHide();
          setIsLoading(false);
          toast.error(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((error) => {
        console.error("Error deleting item:", error);
        toast.error("An error occured", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
        props.onHide();
        setComment("");
      });
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="">
            <span className="fs-16 fw-bold ">Hold Work</span>
            <span style={{ color: "#72777A" }} className="fs-14 ps-3">
              Put work in progress on hold
            </span>
          </div>
          <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
            <div className="col-md-12">
              <label className="fw-medium pb-2 fs-14">
                Add comments for putting work on hold
              </label>
              <textarea
                name=""
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "150px",
                  borderRadius: "5px",
                  padding: "15px 15px",
                }}
                placeholder="Enter comment"
              ></textarea>
            </div>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            {isLoading ? (
              <Spinner
                animation="border"
                style={{
                  color: "#D57D2A",
                }}
              />
            ) : (
              <button className="delate-btn" onClick={handleHold}>
                Hold
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default HoldModal;
