import React from "react";
import { Accordion, Nav, Tab } from "react-bootstrap";
import CorrectiveWork from "./CorrectiveWork";
import PreventiveWork from "./PreventiveWork";

const ProfileWork = () => {
  return (
    <>
      <div className="order-request washroom-right-content responses-cont">
        <div className="washroom-title">
          <div className="fs-13 fw-medium">ASSET WORK</div>
        </div>
        <div className="order-request mt-4 p-0">
          <Accordion defaultActiveKey="0">
            {/* PARTS LIST */}
            <Accordion.Item eventKey="0" className="request-box">
              <Accordion.Header className="request-box-title">
                <div className="fs-13 fw-medium">WORK LIST</div>
              </Accordion.Header>
              <Accordion.Body className="request-box-body p-0">
                <Tab.Container defaultActiveKey="first">
                  <Nav variant="pills" className="profile-work-nav">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Corrective Work</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Preventive Work</Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <CorrectiveWork />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <PreventiveWork />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default ProfileWork;
