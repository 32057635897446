import React from "react";
import DownIcon from "../../Assets/Icons/DownIcon";
import AddIcon from "../../Assets/Icons/AddIcon";
import { Link } from "react-router-dom";
import exportToExcel from "../../utls/exportToExcel";
import exportToExcelMain from "../../utls/exportToExecelMain";
const RequestsHeader = (report) => {
  
  return (
    <>
      <div className="work-header">
        <div className="fs-20">Requests</div>
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-15 d-flex align-items-center gap-2">
              Actions <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu dropdown-menu-end select-menu">
            <li>
              <Link to="/requests/new-request">
                <AddIcon /> New Request
              </Link>
            </li>
            <li onClick={() => exportToExcelMain(report.report, "Requests")}>
              <Link to="">
                <AddIcon />
                Export to Excel
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};



export default RequestsHeader;
