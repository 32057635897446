import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  CREATE_ROUTINE_INSPECTION_SCHEDULE,
  EDIT_ROUTINE_INSPECTION_SCHEDULE,
  GET_ALL_USERS_BY_TEAM,
  GET_ASSETS_BY_LOCATION,
  GET_SIMPLE_LOCATION_LIST,
  GET_TEAMS_TO_ASSIGN_TICKET,
} from "../../utls/constants";
import RoutineInspectionCategoryContext from "../../Context/Inspections/RoutineInspectionCategoryContext";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { get } from "../../Services/ApiHelper";
import ReactDatePicker from "react-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ModalInspection = (props) => {
  const item = props.item;

  let [selectValue, setSelectValue] = useState({
    asset: [],
    assetName: "",
    location: 0,
    locationName: "",
    assignTeam: 0,
    assignTeamName: "",
    assignWorker: 0,
    assignWorkerName: "",
    routineInspectionScheduleCategoryId: 0,
    startsOn: null,
    endsOn: null,
  });

  const [locationList, setLocationList] = useState([]);
  const [assetList, setAssetList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [workerList, setWorkerList] = useState([]);

  useEffect(() => {
    if (item != null) {
      setSelectValue({
        ...selectValue,
        asset: item.assetId,
        assetName: item.assetName,
        location: item.locationId,
        locationName: item.locationName,
        assignTeam: item.assignedTeamId,
        assignTeamName: item.assignedTeamName,
        assignWorker: item.assignedUserId,
        assignWorkerName: item.assignedUserName,
        routineInspectionScheduleCategoryId: props.category?.id,
        startsOn: item.lastInspectionDate,
        endsOn: item.nextInspectionDate,
      });
    }
    fetch(GET_SIMPLE_LOCATION_LIST, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: "Bearer " + localStorage.getItem("bearerToken"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setLocationList(data);
      });

    fetch(GET_TEAMS_TO_ASSIGN_TICKET, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: "Bearer " + localStorage.getItem("bearerToken"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setTeamList(data);
      });
  }, []);

  const getAssetsByLocation = (locationId) => {
    setAssetList([]);
    fetch(GET_ASSETS_BY_LOCATION(locationId), {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: "Bearer " + localStorage.getItem("bearerToken"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAssetList(data);
      });
  };

  const getUserByTeamId = (teamId) => {
    setWorkerList([]);
    fetch(GET_ALL_USERS_BY_TEAM(teamId), {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: "Bearer " + localStorage.getItem("bearerToken"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        data.map((userTeamList) => {
          const userName =
            userTeamList.user.userFirstName +
            " " +
            userTeamList.user.userLastName;
          const id = userTeamList.user.id;
          setWorkerList([...workerList, { id: id, userName: userName }]);
        });
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const routineInspectionCategoryData = useContext(
    RoutineInspectionCategoryContext
  );

  const onSubmit = (data) => {
    if (props.item == null) {
      const body = {
        locationId: parseInt(selectValue.location),
        locationName: selectValue.locationName,
        routineInspectionScheduleCategoryId:
          routineInspectionCategoryData.inspectionDetailData.id,
        assetId: [parseInt(selectValue.asset)],
        teamId: parseInt(selectValue.assignTeam),
        teamName: selectValue.assignTeamName,
        userToAssignId: parseInt(selectValue.assignWorker),
        userToAssignName: selectValue.assignWorkerName,
        lastInspectionDate: selectValue.startsOn,
        nextInspectionDate: selectValue.endsOn,
        createdBy: JSON.parse(localStorage.getItem("user")).id,
        useCategorySettings: false,
      };

      fetch(CREATE_ROUTINE_INSPECTION_SCHEDULE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: "Bearer " + localStorage.getItem("bearerToken"),
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.succeeded === true) {
            props.onHide();
            toast.success("Inspection Schedule Added Successfully", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: false,
            });
          } else {
            props.onHide();
            toast.error("Failed to Add Inspection Schedule", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: false,
            });
          }
        });
    } else {
      const body = {
        id: props.item.routineInspectionId,
        locationId: parseInt(selectValue.location),
        locationName: selectValue.locationName,
        routineInspectionScheduleCategoryId:
          routineInspectionCategoryData.inspectionDetailData.id,
        assetId: [parseInt(selectValue.asset)],
        teamId: parseInt(selectValue.assignTeam),
        teamName: selectValue.assignTeamName,
        userToAssignId: parseInt(selectValue.assignWorker),
        userToAssignName: selectValue.assignWorkerName,
        lastInspectionDate: selectValue.startsOn,
        nextInspectionDate: selectValue.endsOn,
        createdBy: JSON.parse(localStorage.getItem("user")).id,
        useCategorySettings: false,
      };

      fetch(EDIT_ROUTINE_INSPECTION_SCHEDULE, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: "Bearer " + localStorage.getItem("bearerToken"),
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 0) {
            props.onHide();
            toast.success("Inspection Schedule Updated Successfully", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: false,
            });
            setTimeout(() => {
              // window.location.reload();
            }, 3000);
          } else {
            props.onHide();
            toast.error("Failed to Update Inspection Schedule", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: false,
            });
          }
        });
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add-schedule-modal"
    >
      <Modal.Body>
        <form className="add-schedule" onSubmit={handleSubmit(onSubmit)}>
          <div className="fs-16 fw-medium">
            {props.item == null
              ? "Add Inspection Schedule"
              : "Edit Inspection Schedule"}
          </div>
          <div className="row">
            <div className="col-md-12">
              <label>Location</label>
              <div className="text-danger float-md-end">
                {errors.location && <span>{errors.location.message}</span>}
              </div>
              <select
                className="form-select gray-color"
                style={{
                  color: selectValue?.location !== "Select" ? "#000" : "",
                }}
                {...register("location", { required: "* required" })}
                onChange={(e) => {
                  setSelectValue({
                    ...selectValue,
                    location: e.target.value,
                    locationName: e.target.options[e.target.selectedIndex].text,
                  });
                  getAssetsByLocation(e.target.value);
                }}
              >
                <option value={props.item != null ? props.item.locationId : ""}>
                  {props.item != null ? props.item.locationName : "Select"}
                </option>
                {locationList.map((location) => (
                  <option value={location.id}>{location.locationName}</option>
                ))}
              </select>
            </div>
            <div className="col-md-12">
              <label>Asset</label>
              <div className="text-danger float-md-end">
                {errors.asset && <span>{errors.asset.message}</span>}
              </div>
              <select
                className="form-select gray-color"
                style={{ color: selectValue?.asset !== "Select" ? "#000" : "" }}
                {...register("asset", { required: "* required" })}
                onChange={(e) => {
                  setSelectValue({
                    ...selectValue,
                    asset: e.target.value,
                    assetName: e.target.options[e.target.selectedIndex].text,
                  });
                }}
              >
                <option
                  selected
                  value={props.item != null ? props.item.assetId : ""}
                >
                  {props.item != null ? props.item.assetName : "Select"}
                </option>

                {assetList.map((asset) => (
                  <option value={asset.id}>{asset.assetName}</option>
                ))}
              </select>
            </div>
            {props.item == null ? (
              <div
                className="col-md-12"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label>Last Inspection Date</label>
                <ReactDatePicker
                  value={selectValue.startsOn}
                  onChange={(date) =>
                    setSelectValue({ ...selectValue, startsOn: date })
                  }
                  minDate={selectValue.endsOn}
                  selected={selectValue.startsOn}
                  className="last-date"
                  dateFormat="yyyy-MM-dd"
                  style={{ color: "black" }}
                />
              </div>
            ) : (
              <div
                className="col-md-12"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label>Last Inspection Date</label>
                <ReactDatePicker
                  value={
                    selectValue.startsOn == undefined ||
                    selectValue.startsOn == null
                      ? props.category?.startsOn
                      : selectValue.startsOn
                  }
                  onChange={(date) =>
                    setSelectValue({ ...selectValue, startsOn: date })
                  }
                  selected={selectValue.startsOn}
                  className="last-date"
                  dateFormat="yyyy-MM-dd"
                  style={{ color: "black" }}
                />
              </div>
            )}
            {props.item == null ? (
              <div
                className="col-md-12"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label>Next Inspection Date</label>
                <ReactDatePicker
                  value={
                    selectValue.endsOn !== undefined
                      ? selectValue.endsOn
                      : props.category?.endsOn
                  }
                  onChange={(date) =>
                    setSelectValue({ ...selectValue, endsOn: date })
                  }
                  selected={selectValue.endsOn}
                  className="last-date"
                  dateFormat="yyyy-MM-dd"
                  style={{ color: "#000" }}
                />
              </div>
            ) : (
              <div
                className="col-md-12"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label>Next Inspection Date</label>
                <ReactDatePicker
                  value={
                    selectValue.endsOn == undefined ||
                    selectValue.endsOn == null
                      ? props.category?.endsOn
                      : selectValue.endsOn
                  }
                  onChange={(date) =>
                    setSelectValue({ ...selectValue, endsOn: date })
                  }
                  selected={selectValue.endsOn}
                  type="date"
                  className="last-date"
                  dateFormat="yyyy-MM-dd"
                  style={{ color: "#000" }}
                />
              </div>
            )}

            <div className="col-md-6">
              <label>Assign Team</label>
              <div className="text-danger float-md-end">
                {errors.team && <span>{errors.team.message}</span>}
              </div>
              <select
                className="form-select gray-color"
                style={{
                  color: selectValue?.assignTeam !== "Select" ? "#000" : "",
                }}
                {...register("team", { required: "* required" })}
                onChange={(e) => {
                  setSelectValue({
                    ...selectValue,
                    assignTeam: e.target.value,
                    assignTeamName:
                      e.target.options[e.target.selectedIndex].text,
                  });
                  getUserByTeamId(e.target.value);
                }}
              >
                <option
                  value={props.item != null ? props.item.assignedTeamId : ""}
                >
                  {props.item != null ? props.item.assignedTeamName : "Select"}
                </option>
                {teamList.map((team) => (
                  <option value={team.id}>{team.teamName}</option>
                ))}
              </select>
            </div>
            <div className="col-md-6">
              <label>Assign Worker</label>
              <div className="text-danger float-md-end">
                {errors.worker && <span>{errors.worker.message}</span>}
              </div>
              <select
                className="form-select gray-color"
                style={{
                  color: selectValue?.assignWorker !== "Select" ? "#000" : "",
                }}
                {...register("worker", { required: "* required" })}
                onChange={(e) => {
                  setSelectValue({
                    ...selectValue,
                    assignWorker: e.target.value,
                    assignWorkerName:
                      e.target.options[e.target.selectedIndex].text,
                  });
                }}
              >
                <option
                  value={props.item != null ? props.item.assignedUserId : ""}
                >
                  {props.item != null ? props.item.assignedUserName : "Select"}
                </option>

                {workerList.map((worker) => (
                  <option value={worker.id}>{worker.userName}</option>
                ))}
              </select>
            </div>

            <div className="col-lg-12">
              <div className="text-end">
                <button className="next-btn mt-3" type="submit">
                  {props.item == null ? "Add" : "Edit"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalInspection;
