import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import DownIcon from "../../../Assets/Icons/DownIcon";
import { get, post } from "../../../Services/ApiHelper";
import {
  ADD_TICKET_COSTED_ITEMS,
  GET_TICKET_LABOUR_COST,
  GET_TICKET_OTHERCOST_BY_TICKET_ID,
  GET_TICKET_OTHER_COST_BY_TICKET_ID,
  GET_WORKORDER_DETAILS_BY_ID,
} from "../../../utls/constants";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";

import { toast } from "react-toastify";

const AddOtherCostsModal = (props) => {
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [showChecklistList, setShowChecklistList] = useState(false);
  const [selectOtherCostSearch, setSelectOtherCostSearch] = useState("");
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);
  const [otherCostData, setotherCostData] = useState([]);
  const token = localStorage.getItem("bearerToken");
  const [selectedAssetIds, setSelectedAssetIds] = useState([]);
  const [submit, setSubmit] = useState(false);

  const ticketId = ticketSummary?.workOrderSummary?.ticketId;

  const [labourItems, setLabourItems] = useState([]);
  const handleCheckboxChange = (asset, item) => {
    setSubmit(true);
    const id = item.ticketItemId;
    setSelectedAssets((prevSelectedAssets) => {
      if (prevSelectedAssets.includes(asset)) {
        return prevSelectedAssets.filter(
          (selectedAsset) => selectedAsset !== asset
        );
      } else {
        return [...prevSelectedAssets, asset];
      }
    });
    setSelectedAssetIds((prevSelectedAssetIds) => {
      if (prevSelectedAssetIds.includes(id)) {
        return prevSelectedAssetIds.filter(
          (selectedAssetId) => selectedAssetId !== id
        );
      } else {
        return [...prevSelectedAssetIds, id];
      }
    });
  };
  const costedLabourList = [];
  const obj = {
    category: "",
    description: "",
    quantity: 0,
    unitCost: 0,
    totalCost: 0,
  };
  useEffect(() => {
    get(GET_TICKET_OTHERCOST_BY_TICKET_ID(ticketId), token)
      .then((result) => {
        const existingData = ticketSummary.costingSummary.costedOtherList;

        const uniqueData = result?.filter(
          (obj) =>
            !existingData?.some((obj2) => obj.description === obj2.description)
        );
        setotherCostData(uniqueData);
      })
      .catch((error) => console.log(error));
  }, []);

  //filter other cost data  by search input
  const filteredOtherCostData = otherCostData.filter((item) =>
    item.description.toLowerCase().includes(selectOtherCostSearch.toLowerCase())
  );
  useEffect(() => {
    if (submit) {
      // get all items from labourData where id is in selectedAssetIds
      const selectedLabourItems = otherCostData.filter((item) =>
        selectedAssetIds.includes(item.ticketItemId)
      );
      setLabourItems(selectedLabourItems);
    }
    setSubmit(false);
  }, [submit]);
  const handleSubmit = (e) => {
    e.preventDefault();

    const selectedLabourItems = otherCostData.filter((item) =>
      selectedAssetIds.includes(item.ticketItemId)
    );

    post(ADD_TICKET_COSTED_ITEMS, { ticketItemIdList: selectedAssetIds }, token)
      .then((result) => {
        if (result.succeeded === true) {
          const selectedLabourItems = otherCostData.filter((item) =>
            selectedAssetIds.includes(item.ticketItemId)
          );

          const updatedTicketSummary = { ...ticketSummary };

          const mappedLabourItems = selectedLabourItems.map((item) => ({
            quantity: item.quantity,
            unitCost: item.unitCost,
            otherCostCategoryName: item.category,
            description: item.description,
          }));

          updatedTicketSummary.costingSummary.costedOtherList.push(
            ...mappedLabourItems
          );

          toast.success(result.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          get(GET_WORKORDER_DETAILS_BY_ID(ticketId), token)
            .then((data) => {
              setTicketSummary({
                id: data.id,
                workOrderSummary: data.workOrderSummary,
                assetSummary: data.assetSummary,
                costingSummary: data.costingSummary,
                jobCardSummary: data.jobCardSummary,
              });
              props.onHide();
            })
            .catch((err) => {
              console.log(err);
            });
          setTicketSummary(updatedTicketSummary);
        } else {
          toast.error(result.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((error) => console.log(error));
    props.onHide();
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Add Other Costs</span>
            <span className="fs-14" style={{ color: "#72777A" }}>
              You can only include costs from other costs list
            </span>
          </div>
          <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
            <div className="col-md-12">
              <label className="fw-medium pb-2">Select Other Costs</label>
              <div className="checklist-box select__form">
                <button
                  className="checklist-btn"
                  onClick={() => setShowChecklistList(!showChecklistList)}
                >
                  {selectedAssets.length === 0 ? (
                    <div>
                      <span style={{ color: "#C5C7CD" }}>
                        Select costs from list
                      </span>
                      {selectedAssets.length > 0 && (
                        <span style={{ color: "#000" }}>
                          {selectedAssets.join(", ")}
                        </span>
                      )}
                    </div>
                  ) : (
                    <span style={{ color: "#000" }}>
                      {selectedAssets.join(", ")}
                    </span>
                  )}
                  <DownIcon />
                </button>
                <div
                  className={`checklist-list ${
                    showChecklistList ? "" : "hide d-none"
                  }`}
                >
                  <form className="dropdown-search ps-0">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      onChange={(e) => setSelectOtherCostSearch(e.target.value)}
                      type="text"
                      placeholder="Search"
                    />
                  </form>
                  <ul className="dropdown-item-content mt-2">
                    {filteredOtherCostData.map((item, index) => (
                      <li key={index}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id={item.description}
                            checked={selectedAssets.includes(item.description)}
                            onChange={() =>
                              handleCheckboxChange(item.description, item)
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor={item.description}
                          >
                            {item.description +
                              ` (${item.totalCost.toLocaleString("en-US", {
                                style: "currency",
                                currency: "KES",
                              })})`}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            <Link to="" className="delate-btn" onClick={(e) => handleSubmit(e)}>
              Add
            </Link>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddOtherCostsModal;
