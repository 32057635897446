import React, { useEffect, useState } from "react";
import "../Components/WorkOrders/WorkOrders.scss";
import "react-datepicker/dist/react-datepicker.css";
import { Link, NavLink, Outlet } from "react-router-dom";
import WorkHeader from "../Components/WorkOrders/WorkHeader";
import { get } from "../Services/ApiHelper";
import {
  GET_CLOSED_WORKORDERS,
  GET_NEW_WORKORDERS,
  GET_ONGOING_WORKORDERS,
  GET_PENDING_WORKORDERS,
} from "../utls/constants";

const WorkOrders = () => {
  const [report, setReport] = useState([]);
  const bearerToken = localStorage.getItem("bearerToken");
  useEffect(() => {
    handleNewWorkOrderRepotrt();
  }, []);
  const handleNewWorkOrderRepotrt = () => {
    get(GET_NEW_WORKORDERS("", 1000, 1), bearerToken)
      .then((response) => {
        setReport(response["data"]);
      })
      .catch((error) => console.log("An error has occured"));
  };
  const handleOngoingWorkOrderRepotrt = () => {
    get(GET_ONGOING_WORKORDERS("", 1000, 1), bearerToken)
      .then((response) => {
        setReport(response["data"]);
      })
      .catch((error) => console.log("An error has occured"));
  };
  const handlePendingClosureWorkOrderRepotrt = () => {
    get(GET_PENDING_WORKORDERS("", 1000, 1), bearerToken)
      .then((response) => {
        setReport(response["data"]);
      })
      .catch((error) => console.log("An error has occured"));
  };
  const handleClosedWorkOrderRepotrt = () => {
    get(GET_CLOSED_WORKORDERS("", 1000, 1), bearerToken)
      .then((response) => {
        setReport(response["data"]);
      })
      .catch((error) => console.log("An error has occured"));
  };
  const handleUpcomingWorkOrderRepotrt = () => {
    setReport([]);
  };

  return (
    <div className="work-orders-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <WorkHeader report={report} />
          </div>
          <div className="col-lg-12">
            <div className="other-nav">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="">CM Work</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="inspection-cate">
              <div className="work-table-nav">
                <NavLink
                  to="/work-orders"
                  onClick={() => {
                    handleNewWorkOrderRepotrt();
                  }}
                  end
                >
                  New Work
                </NavLink>
                <NavLink
                  to="/work-orders/ongoing-work"
                  onClick={() => {
                    handleOngoingWorkOrderRepotrt();
                  }}
                >
                  Ongoing Work
                </NavLink>
                <NavLink
                  to="/work-orders/pending-closure"
                  onClick={() => {
                    handlePendingClosureWorkOrderRepotrt();
                  }}
                >
                  Pending Closure
                </NavLink>
                <NavLink
                  to="/work-orders/closed-work"
                  onClick={() => {
                    handleClosedWorkOrderRepotrt();
                  }}
                >
                  Closed Work
                </NavLink>
                {/* <NavLink to="/work-orders/upcoming-work" onClick={()=>{
                  handleUpcomingWorkOrderRepotrt()
                }}>Upcoming Work</NavLink> */}
              </div>
              <div className="work-table-tables">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkOrders;
