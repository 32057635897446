import React from "react";
import { Link,useNavigate } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import{toast} from 'react-toastify';
import { resetLocationState } from "../../../redux/locationSlice";
import useMutateData from "../../../hooks/useMutateData";


const LocationSummary = () => {
  const dispatch=useDispatch();
  const navigate=useNavigate();
  const locationState=useSelector((state)=>state.location);

  const { mutate, isLoading: isSubmitting } = useMutateData({
    url: '/Locations/AddLocation',
    method: 'POST',
    onSuccessfullMutation: (data) => {
      if (data?.data?.succeeded === true) {
        toast.success(data?.data?.messages[0], {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        dispatch(resetLocationState());
        navigate('/locations');
      } else {
        toast.error(data?.data?.messages[0], {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
    successMessage: 'Successfully added Location',
    errorMessage: 'Location creation failed',
  });
  const handleSubmit = () => {
    const payload = {
     locationName:locationState.locationName,
     locationAddress:locationState.locationAddress,
     longitude:locationState.longitude,
     latitude:locationState.latitude,
     regionName:locationState.regionName,
     parentLocationName:locationState.parentLocationName,
     contactPerson:locationState.contactPerson
    };

    mutate(payload);
  };
  
  return (
    <>
      <div className="order-details-content PMWorkScheduling pb-lg-4">
        <div className="work-order-summary">
          <div className="fs-16 fw-bold" style={{paddingBottom: "35px"}}>Summary</div>
          <div
            className="fs-14 fw-semibold pb-4"
            style={{ color: "#00000061" }}
          >
            LOCATION DETAILS
          </div>
          <div className="row" style={{ rowGap: "24px" }}>
            <div className="col-md-3">
              <div
                className="fs-14 fw-semibold pb-2"
                style={{ color: "#00000061" }}
              >
                Location Name
              </div>
              <div className="fs-14 fw-medium">{locationState.locationName}</div>
            </div>
            <div className="col-md-3">
              <div
                className="fs-14 fw-semibold pb-2"
                style={{ color: "#00000061" }}
              >
                {locationState.locationRegion}
              </div>
              <div className="fs-14 fw-medium">{locationState.regionName}</div>
            </div>
            <div className="col-md-3">
              <div
                className="fs-14 fw-semibold pb-2"
                style={{ color: "#00000061" }}
              >
                Parent Location
              </div>
              <div className="fs-14 fw-medium">{locationState.parentLocationName}</div>
            </div>
            <div className="col-md-3">
              <div
                className="fs-14 fw-semibold pb-2"
                style={{ color: "#00000061" }}
              >
                Parent in Charge
              </div>
              <div className="fs-14 fw-medium"></div>
            </div>
            <div className="col-md-3">
              <div
                className="fs-14 fw-semibold pb-2"
                style={{ color: "#00000061" }}
              >
                ContactPerson
              </div>
              <div className="fs-14 fw-medium"></div>
            </div>
          </div>
          <br /> <br />
          <div className="row" style={{ rowGap: "24px" }}>
            <div className="col-md-6">
              <div
                className="fs-14 fw-semibold pb-2"
                style={{ color: "#00000061" }}
              >
                Address:
              </div>
              <div className="fs-14 fw-medium" style={{ lineHeight: "1.3" }}>
                {locationState.locationAddress}
              </div>
            </div>
            <div className="col-md-3">
              <div
                className="fs-14 fw-semibold pb-2"
                style={{ color: "#00000061" }}
              >
                Map Coordinates
              </div>
              <div className="fs-14 fw-medium text-nowrap">
                {locationState.longitude}
              </div>
              <div className="fs-14 fw-medium text-nowrap">
                {locationState.latitude}
              </div>
            </div>
          </div>
        </div>

        <div className="details-buttons pt-lg-3 d-flex justify-content-between">
          <Link to="/locations/new-location" className="next-btn ms-0">
            Previous
          </Link>
          <Link to="/locations" className="next-btn ms-0" onClick={handleSubmit} disabled={isSubmitting} >
          {isSubmitting ? 'Submitting...' : 'Submit'}
          </Link>
         
        </div>
      </div>
    </>
  );
};

export default LocationSummary;
