import React from "react"; 
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom"; 

const AddAssetMakeModal = (props) => {
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal add-asset-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-md-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Add Asset Make</span>
            <div className="fs-14 pt-2 pt-md-0" style={{ color: "#72777A" }}>
              Add a new asset make
            </div>
          </div>
          <div className="mt-4">
            <label className="fw-medium pb-2 fs-14">Asset Make</label>
            <input
              className="modal-input-box"
              type="text"
              style={{
                background: "#F1EFEF",
                width: "100%",
                border: "0",
                height: "50px",
                borderRadius: "5px",
                padding: "0 15px",
                fontSize: "14px",
              }}
              placeholder="Enter asset make"
            />
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            <Link to="" className="delate-btn" onClick={props.onHide}>
              Add
            </Link>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddAssetMakeModal;
