import React from "react";

const Checklists = () => {
  return (
    <>
      <div className="fs-14 fw-medium">Checklists:</div>
      <ul className="checklists-list">
        <li>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="Nozzle"
              // checked
            />
            <label className="form-check-label" for="Nozzle">
              Pump Calibration Checklist
            </label>
          </div>
        </li>
        <li>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="Monitor"
              // checked
            />
            <label className="form-check-label" for="Monitor">
              Pump Compressor Checklist
            </label>
          </div>
        </li>
      </ul>
    </>
  );
};

export default Checklists;
