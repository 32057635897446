import React, { useState, useContext } from "react";
import { Accordion } from "react-bootstrap";
import EyesIcon from "../../../Assets/Icons/EyesIcon";
import DelateIcon2 from "../../../Assets/Icons/DelateIcon2";
import DeleteRFQModal from "../../WorkOrders/Modal/DeleteRFQModal";
import DiagnosisRFQDetailsModal from "../../WorkOrders/Modal/DiagnosisRFQDetailsModal";
import AddDiagnosisQuotesModal from "../../WorkOrders/Modal/AddDiagnosisQuotesModal";
import { DiagnosisDetailsContext } from "../../../Context/Inspections/WorkOrderContext";
import { DELETE_RFQ_DIAGNOSIS } from "../../../utls/constants";
import { post } from "../../../Services/ApiHelper";
import { toast } from "react-toastify";

const ViewDiagnosis = () => {
  const [delateModalShow, setDelateModalShow] = useState(false);
  const [diagnosisDetailsShow, setDiagnosisDetailsShow] = useState(false);
  const [addDiagnosisShow, setAddDiagnosisShow] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState(false);
  const [viewItem, setViewItem] = useState({});
  const { diagnosisSummary, setDiagnosisSummary,isLoading, setIsLoading } = useContext(DiagnosisDetailsContext);
  const deadLineDate = new Date(diagnosisSummary?.requestQuoteSummary?.deadLineDate);
  const currentDate = new Date();
  const handleDelete = async () => {
    setIsLoading(true);
    const bearerToken = localStorage.getItem("bearerToken");
    const endPoint = DELETE_RFQ_DIAGNOSIS;
    const data = { id: itemIdToDelete };

    post(endPoint, data, bearerToken)
    .then((response) => {
      if(response.succeeded === true){
        setDelateModalShow(false);
        setIsLoading(false);
        toast.success(response.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setDiagnosisSummary((prevDiagnosisSummary) => ({
          ...prevDiagnosisSummary,
          requestDiagnosisSummary: prevDiagnosisSummary.requestDiagnosisSummary.filter(a =>
            a.id !== itemIdToDelete
          ),
        }));
      } else {
        setDelateModalShow(false);
        setIsLoading(false);
        toast.error(response.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }).catch((error) => {
      setDelateModalShow(false);
      setIsLoading(false);
      toast.error("An error occurred", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }).finally(() => {
      setDelateModalShow(false);
      setIsLoading(false);
    });
  };

  return (
    <>
      <div className="order-request washroom-right-content responses-cont">
        <div className="washroom-title">
          <div className="fs-13 fw-medium">DIAGNOSIS DETAILS</div>
        </div>

        <div className="order-request mt-4 p-0">
          <Accordion defaultActiveKey="1">
            {/* DIAGNOSIS */}
            <Accordion.Item
              eventKey="1"
              className="request-box horizonScroll pe-0"
            >
              <Accordion.Header className="request-box-title pe-3">
                <div className="fs-13 fw-medium">DIAGNOSIS</div>
              </Accordion.Header>
              <Accordion.Body className="request-box-body p-0">
                <div
                  className="table-responsive-sm request_table mb-3"
                  style={{ maxWidth: "100%", height: "auto" }}
                >
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Issue diagnosis and solution</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {diagnosisSummary?.requestDiagnosisSummary?.map((item) => (
                        <tr key={item.id}>
                          <td>
                            <div className="d-grid">
                              <div
                                className="fs-14"
                                style={{ color: "#1F2024" }}
                              >
                                PART: {item.faultyPart}
                              </div>
                              <div
                                className="fs-13"
                                style={{ color: "#71727A" }}
                              >
                                <b>Diagnosis:</b> {item.diagnosis}
                              </div>
                              <div
                                className="fs-13 pt-2"
                                style={{ color: "#71727A" }}
                              >
                                <b>Proposed Solution:</b> {item.proposedSolution}
                              </div>
                            </div>
                          </td>
                          <td>
                            {(diagnosisSummary?.requestQuoteSummary?.isOpen === true) && 
                            (diagnosisSummary?.requestQuoteSummary?.blockQuoteAfterDeadLine === true ? currentDate < deadLineDate : true) && 
                            (
                              <div className="button-con gap-3">
                                <button
                                  onClick={() => {setDiagnosisDetailsShow(true);setViewItem(item);}}
                                >
                                  <EyesIcon />
                                </button>
                                <button onClick={() => {setItemIdToDelete(item.id); setDelateModalShow(true);}}>
                                  <DelateIcon2 />
                                </button>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {(diagnosisSummary?.requestQuoteSummary?.isOpen === true) && 
                (diagnosisSummary?.requestQuoteSummary?.blockQuoteAfterDeadLine === true ? currentDate < deadLineDate : true) && 
                (
                <div className="text-end pe-4">
                  <button
                    className="add-button my-3"
                    onClick={() => setAddDiagnosisShow(true)}
                  >
                    Add Diagnosis
                  </button>
                </div>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>

      <DeleteRFQModal
        show={delateModalShow}
        onHide={() => setDelateModalShow(false)}
        onDelete={handleDelete}
      />
      <DiagnosisRFQDetailsModal
        show={diagnosisDetailsShow}
        onHide={() => setDiagnosisDetailsShow(false)}
        data={viewItem}
      />
      <AddDiagnosisQuotesModal
        requestAssetId={diagnosisSummary?.requestDetailSummary?.requestAssetId}
        show={addDiagnosisShow}
        onHide={() => setAddDiagnosisShow(false)}
      />
    </>
  );
};

export default ViewDiagnosis;
