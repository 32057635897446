import React, { useState, useEffect, useContext } from "react";

import { Link,useLocation } from "react-router-dom";
import {
  CREATE_ROUTINE_INSPECTION_CATEGORY,
  GET_CALENDAR_TYPES,
  GET_CATEGORIES,
  GET_CHECKLISTS,
} from "../../../utls/constants";
import RoutineInspectionCategoryContext from "../../../Context/Inspections/RoutineInspectionCategoryContext";
import CreateRecurrence from "../Create/CreateRecurrence";
import { ToastContainer, toast } from "react-toastify";

const EditCategoryInspection = () => {
  const [selectValue, setSelectValue] = useState({
    asset: "",
    category: "",
  });
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [assetCategories, setAssetCategories] = useState([]);
  const [checklists, setChecklists] = useState([]);
  
  const [data, setData] = useState({});
  const [recurrenceData, setRecurrenceData] = useState(null); // New state for recurrence data
  const token = localStorage.getItem("bearerToken");

  const storedStartDateData = window.localStorage.getItem("startDate");
  const storedEndDateData = window.localStorage.getItem("endsDate");

  const storedEditedInspectionCatData = window.localStorage.getItem("editedInspectionCat");
  const editedInspectionCatData = JSON.parse(storedEditedInspectionCatData);
  

  useEffect(() => {
    
  
    try {
      
  
      if (editedInspectionCatData) {
        const {
          routineInspectionName,
          routineInspectionDescription,
          assetCategoryId,
          checklistId,
          startsOn,
          endsOn,
        } = editedInspectionCatData;
  
        setName(routineInspectionName);
        setDescription(routineInspectionDescription);
        setSelectValue({
          asset: checklistId,  
          category: assetCategoryId
        });
  
        
        window.localStorage.setItem("startDate", startsOn);
        window.localStorage.setItem("endsDate", endsOn);
      }
    } catch (error) {
      console.error("Error parsing JSON from localStorage:", error);
      // Handle the error (e.g., set default values or redirect)
    }
  }, []);
  
  

  useEffect(() => {
    if (!token) {
      window.location.href = "/sign-in";
    }
    

    fetch(GET_CATEGORIES, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        setAssetCategories(data);
      });
    fetch(GET_CHECKLISTS, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        var result = data["data"];
        setIsLoading(false);
        setChecklists(result);
      });
  }, []);

  const handleRecurrenceChange = (recurrenceValue) => {
    setRecurrenceData(recurrenceValue);
  };
  

  const handleSave = () => {

    const payload = {
      id: editedInspectionCatData.id,
      name: name,
      description: description,
      assetCategoryId: parseInt(selectValue.category, 10) || 0,
      checklistId: parseInt(selectValue.asset, 10) || 0,
      startsOn: storedStartDateData,
      endsOn: storedEndDateData,
      endsAfter: 0,
      calendarTypeId: parseInt(recurrenceData?.type, 10) || 0,
      calendarTypeName: "string",
      frequency: parseInt(recurrenceData?.number, 10) || 0,
      createdBy:JSON.parse(localStorage.getItem("userDetails")).id,
    };

    fetch(CREATE_ROUTINE_INSPECTION_CATEGORY, {
      method: "PUT",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        if(response.code ===0){
          window.location.href = "/inspection-category";
          toast.success("Inspection Category Updated Successfully",{
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });
            }
            else{
              toast.error(response.message,{
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: false,
              });
            }
      })
      .catch((error) => {
        console.error(error);
      });
  };



  
  return (
    <div className="order-details-content add-pm-content">
      <div className="fs-16 fw-bold">Inspection Details</div>
      <div className="row details-forms-one mt-5">
        <div className="col-md-6">
          <label>Names</label>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className="col-md-6">
          <label>Description</label>
          <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
        </div>
        <div className="col-lg-6">
          <div className="select-box">
            <label>Checklist</label>
            <select
              className="form-select gray-color"
              style={{ color: selectValue?.asset !== "Select" ? "#000" : "" }}
              onChange={(e) =>
                setSelectValue({ ...selectValue, asset: e.target.value })
              }
            >
              <option selected value={ editedInspectionCatData ? editedInspectionCatData.checkListFormId : ""}>
              { editedInspectionCatData ? editedInspectionCatData.checklistFormName : "Select"}
              </option>
              {checklists.map((checklist) => (
                <option value={checklist.id} >{checklist.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="select-box">
            <label>Equipment Category</label>
            {/* <label>Asset Category</label> */}
            <select
              className="form-select gray-color"
              style={{
                color: selectValue?.category !== "Select" ? "#000" : "",
              }}
              onChange={(e) =>
                setSelectValue({ ...selectValue, category: e.target.value })
              }
            >
             <option selected value={ editedInspectionCatData ? editedInspectionCatData.assetCategoryId : ""}>
              { editedInspectionCatData ? editedInspectionCatData.assetCategoryName : "Select"}
              </option>
              {assetCategories.map((category) => (
                <option value={category.id}>{category.assetCategoryName}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <hr />
      <div className="row recurrence-con">
        <CreateRecurrence
          onRecurrenceChange={handleRecurrenceChange}
         />
      </div>
      <div className="details-buttons">
        <Link to="/inspection-category" className="next-btn" onClick={handleSave}>
          Save
        </Link>
      </div>
    </div>
  );
};

export default EditCategoryInspection;