import React from "react";
import { Link } from "react-router-dom";

const OtherPageNav = () => {
  return (
    <div className="other-nav">
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/preventive-works">Preventive Maintenance Work</Link>
        </li>
        <li>
          <Link>Create PM Work</Link>
        </li>
      </ul>
    </div>
  );
};

export default OtherPageNav;
