import React, { useState, useContext, useEffect } from "react";
import DeleteIcon from "../../../Assets/Icons/DeleteIcon";
import AddPartsCostsModal from "../../WorkOrders/Modal/AddPartsCostsModal";
import AddLabourCostsModal from "../../WorkOrders/Modal/AddLabourCostsModal";
import AddOtherCostsModal from "../../WorkOrders/Modal/AddOtherCostsModal";
import DeleteModal from "../../WorkOrders/Modal/DeleteModal";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";
import { useLocation } from "react-router-dom";
import {
  DELETE_TICKET_OTHER_COST,
  DELETE_TICKET_PART_COST,
} from "../../../utls/constants";
import { toast } from "react-toastify";
import { post } from "../../../Services/ApiHelper";

const ViewPmCostings = () => {
  const { ticketSummary, setTicketSummary, setIsLoading } =
    useContext(WorkOrderContext);
  const [eventKey, setEventKey] = React.useState("");
  const [costsShow, addCostsShow] = useState(false);
  const [labourCostsShow, setLabourCostsShow] = useState(false);
  const [otherCostsShow, setOtherCostsShow] = useState(false);
  const [delateModalShow, setDelateModalShow] = useState(false);
  const [partsCosting, setPartsCosting] = useState(false);
  const [labourCosting, setLabourCosting] = useState(false);
  const [otherCosting, setOtherCosting] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const CompanyDetails = JSON.parse(localStorage.getItem("company"));
  //get url by using useLocation
  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname ===
      `/work-orders/pmwork-view/${ticketSummary?.workOrderSummary?.ticketId}`
    ) {
      setEventKey("0");
    } else if (
      location.pathname ===
      `/work-orders/pmwork-view/${ticketSummary?.workOrderSummary?.ticketId}/assets`
    ) {
      setEventKey("1");
    } else if (
      location.pathname ===
      `/work-orders/pmwork-view/${ticketSummary?.workOrderSummary?.ticketId}/costings`
    ) {
      setEventKey("2");
    } else if (
      location.pathname ===
      `/work-orders/pmwork-view/${ticketSummary?.workOrderSummary?.ticketId}/job-cards`
    ) {
      setEventKey("3");
    }
  }, [location, ticketSummary]);

  const allPartData = ticketSummary?.costingSummary;

  const partsData = ticketSummary?.costingSummary?.costedUsedPartList;
  const labourData = ticketSummary?.costingSummary?.costedLabourList;
  const otherData = ticketSummary?.costingSummary?.costedOtherList;
  // Function to sum up the total values in an array of items
  const getTotalSum = (dataArray) => {
    if (!dataArray) return 0;
    return dataArray.reduce((totalSum, currentItem) => {
      return totalSum + currentItem.quantity * currentItem.unitCost;
    }, 0);
  };
  // Get the total sum of each array
  const totalPartsSum = getTotalSum(partsData);
  const totalLabourSum = getTotalSum(labourData);
  const totalOtherSum = getTotalSum(otherData);
  // Get the overall total sum
  const overallTotalSum = totalPartsSum + totalLabourSum + totalOtherSum;

  const handleDelete = async () => {
    setIsLoading(false);
    try {
      const bearerToken = localStorage.getItem("bearerToken");

      const endPoint = partsCosting
        ? DELETE_TICKET_PART_COST
        : labourCosting
        ? DELETE_TICKET_OTHER_COST
        : otherCosting
        ? DELETE_TICKET_OTHER_COST
        : "";

      const data = { id: itemIdToDelete };
      await post(endPoint, data, bearerToken);

      let updatedCostingList = null;

      if (partsCosting) {
        updatedCostingList =
          ticketSummary.costingSummary.costedUsedPartList.filter(
            (item) => item.id !== itemIdToDelete
          );
      } else if (labourCosting) {
        updatedCostingList =
          ticketSummary.costingSummary.costedLabourList.filter(
            (item) => item.id !== itemIdToDelete
          );
      } else if (otherCosting) {
        updatedCostingList =
          ticketSummary.costingSummary.costedOtherList.filter(
            (item) => item.id !== itemIdToDelete
          );
      }

      setTicketSummary((prevState) => ({
        ...prevState,
        costingSummary: {
          ...prevState.costingSummary,
          costedUsedPartList: partsCosting
            ? updatedCostingList
            : prevState.costingSummary.costedUsedPartList,
          costedLabourList: labourCosting
            ? updatedCostingList
            : prevState.costingSummary.costedLabourList,
          costedOtherList: otherCosting
            ? updatedCostingList
            : prevState.costingSummary.costedOtherList,
        },
      }));

      toast.success("Data Deleted Successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setIsLoading(true);

      setTimeout(() => {
        setDelateModalShow(false);
        setIsLoading(false);
      }, 3000);

      setPartsCosting(false);
      setLabourCosting(false);
      setOtherCosting(false);
    } catch (error) {
      console.error("Error deleting item:", error);
      toast.error("An error occured", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    eventKey && (
      <>
        <div className="order-request washroom-right-content responses-cont">
          <div className="washroom-title">
            <div className="fs-13 fw-medium">WORK ORDER COSTINGS</div>
            <div className="fs-13 fw-bold">
              Status:{" "}
              <button className="primary-text fw-bold">
                {ticketSummary?.workOrderSummary?.currentTicketActivity}
              </button>
            </div>
          </div>
          <div className="order-request mt-4 p-0 view-costings">
            <div className="request-box">
              <div className="request-box-body py-25 px-10">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="fs-15 fw-medium black-38">PARTS COSTING</div>
                  <button
                    onClick={() => addCostsShow(true)}
                    className="add-button my-2"
                    style={{ fontSize: "15px", fontWeight: "500" }}
                  >
                    Add Cost
                  </button>
                  <AddPartsCostsModal
                    show={costsShow}
                    onHide={() => addCostsShow(false)}
                  />
                </div>
                <div
                  className="table-responsive-sm request_table"
                  style={{ maxWidth: "100%" }}
                >
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Parts</th>
                        <th scope="col">Part No.</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Unit Cost</th>
                        <th scope="col">Total Cost</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {partsData && partsData?.map((item) => (
                        <tr key={item.id}>
                          <td>{item.partName}</td>
                          <td>{item.partNumber}</td>
                          <td>{item.quantity}</td>
                          <td>
                            {CompanyDetails?.currency && item.unitCost
                              ? CompanyDetails.currency +
                                " " +
                                item.unitCost.toLocaleString()
                              : "N/A"}
                          </td>
                          <td>
                            {CompanyDetails?.currency +
                              " " +
                              (item.quantity * item.unitCost).toLocaleString()}
                          </td>
                          <td>
                            <div className="button-con">
                              <button
                                onClick={() => {
                                  setDelateModalShow(true);
                                  setItemIdToDelete(item.id);
                                  setPartsCosting(true);
                                }}
                              >
                                <DeleteIcon />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="fs-15 fw-medium black-38">LABOUR COSTING</div>
                  <button
                    onClick={() => setLabourCostsShow(true)}
                    className="add-button my-2"
                    style={{ fontSize: "15px", fontWeight: "500" }}
                  >
                    Add Cost
                  </button>
                  <AddLabourCostsModal
                    show={labourCostsShow}
                    onHide={() => setLabourCostsShow(false)}
                  />
                </div>
                <div
                  className="table-responsive-sm request_table"
                  style={{ maxWidth: "100%" }}
                >
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Staff Name</th>
                        <th scope="col">Team</th>
                        <th scope="col">Hours</th>
                        <th scope="col">Rate per Hour</th>
                        <th scope="col">Amount</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {labourData && labourData?.map((item) => (
                        <tr key={item.id}>
                          <td>{item.userName}</td>
                          <td>{item.teamName}</td>
                          <td>{item.quantity}</td>
                          <td>
                            {CompanyDetails?.currency && item.unitCost
                              ? CompanyDetails.currency +
                                " " +
                                item.unitCost.toLocaleString()
                              : "N/A"}
                          </td>
                          <td>
                            {CompanyDetails?.currency +
                              " " +
                              (item.quantity * item.unitCost).toLocaleString()}
                          </td>
                          <td>
                            <div className="button-con">
                              <button
                                onClick={() => {
                                  setDelateModalShow(true);
                                  setItemIdToDelete(item.id);
                                  setLabourCosting(true);
                                }}
                              >
                                <DeleteIcon />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="fs-15 fw-medium black-38">OTHER COSTS</div>
                  <button
                    onClick={() => setOtherCostsShow(true)}
                    className="add-button my-2"
                    style={{ fontSize: "15px", fontWeight: "500" }}
                  >
                    Add Cost
                  </button>
                  <AddOtherCostsModal
                    show={otherCostsShow}
                    onHide={() => setOtherCostsShow(false)}
                  />
                </div>
                <div
                  className="table-responsive-sm request_table"
                  style={{ maxWidth: "100%" }}
                >
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Category</th>
                        <th scope="col">Description</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Unit Cost</th>
                        <th scope="col">Total Cost</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {otherData && otherData?.map((item) => (
                        <tr key={item.id}>
                          <td>{item.otherCostCategoryName}</td>
                          <td>{item.description}</td>
                          <td>{item.quantity}</td>
                          <td>
                            {CompanyDetails?.currency && item.unitCost
                              ? CompanyDetails.currency +
                                " " +
                                item.unitCost.toLocaleString()
                              : "N/A"}
                          </td>
                          <td>
                            {CompanyDetails?.currency +
                              " " +
                              (item.quantity * item.unitCost).toLocaleString()}
                          </td>
                          <td>
                            <div className="button-con">
                              <button
                                onClick={() => {
                                  setDelateModalShow(true);
                                  setItemIdToDelete(item.id);
                                  setOtherCosting(true);
                                }}
                              >
                                <DeleteIcon />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex align-items-center justify-content-between pt-2 pb-3">
                  <div className="fs-20 fw-bold black-38">TOTAL COST</div>
                  <div className="fs-20 fw-bold black-38">
                    {CompanyDetails?.currency +
                      " " +
                      overallTotalSum.toLocaleString()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <DeleteModal
          show={delateModalShow}
          onHide={() => setDelateModalShow(false)}
          onDelete={handleDelete}
        />
      </>
    )
  );
};

export default ViewPmCostings;
