import { useState } from "react";
import * as constants from "../utls/constants.js";
import {useNavigate} from "react-router-dom";
import { setUserSession } from "../utls/sessionVariables.js";
import { useDispatch } from "react-redux";
import { setResponseType } from "../redux/formSlice.js";

export function LoginService() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
  
    const fetchData = async (username, password) => {
      console.log("fetchData called"); // Debug log
      try {
        setLoading(true);
        console.log("Sending request"); // Debug log
        const response = await fetch(constants.BASE_URL + 'Auth', {
          method: 'post',
          headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
          body: JSON.stringify({
            "userName": username,
            "password": password,
            "accountName": ""
          })
        });
        if (!response.ok) {
          throw new Error('Wrong Username or Password');
        }
        const newData = await response.json();
        console.log("Received response:", newData); // Debug log
        setError(null);
        setUserSession(newData.bearerToken, newData.userAccouns, newData.claims, newData.user, newData.company);
        console.log("Dispatching setResponseType"); // Debug log
        dispatch(setResponseType(newData?.company?.slaPredefinedOrManual));
        console.log("Dispatched setResponseType"); // Debug log
        navigate("/");
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
  
    return { fetchData, error, loading };
  }
  



