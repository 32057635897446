import React, { useState, useMemo } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useFetchData from "../../hooks/useFetchData";
import Dropdown from "react-bootstrap/Dropdown";
import ArrowLeft from "../../Assets/Icons/ArrowLeft";
import ArrowRight from "../../Assets/Icons/ArrowRight";
import DownIcon from "../../Assets/Icons/DownIcon";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import { Nav, Tab } from "react-bootstrap";
import "./Reports.scss";
import PMCMChart from "./PMCMReportGraph";
import PMCMGrid from "./PMCPMReportGrid";
import { Link } from "react-router-dom";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useReactToPrint } from "react-to-print";
import * as XLSX from "xlsx";
import { format } from "date-fns";
import PMCMCategoryGrid from "./PMCMCategoryGrid";
import PMCMCategoryGraph from "./PMCMCategoryGraph";
import qs from "qs";

// function to flattten the filter object
const flattenObject = (obj, parent, res = {}) => {
  for (let key in obj) {
    let propName = parent ? parent + "." + key : key;
    if (
      typeof obj[key] === "object" &&
      obj[key] !== null &&
      !Array.isArray(obj[key])
    ) {
      flattenObject(obj[key], propName, res);
    } else {
      res[propName] = obj[key];
    }
  }
  return res;
};

const PMCMCategory = () => {
  const [endDate, setEndDate] = useState(null);
  const [calendarGrouping, setCalendarGrouping] = useState("weekly");
  const [categoryOfWorkIds, setCategoryOfWorkIds] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState(null);
  const [validationMessage, setValidationMessage] = useState("");
  const [activeTab, setActiveTab] = useState("grid");

  const ExampleCustomInput = ({ value, onClick }) => (
    <button className="example-custom-input" onClick={onClick}>
      {value ? value : ""} <DownIcon />
    </button>
  );

  const handleRun = () => {
    if (!startDate || !endDate) {
      setValidationMessage("Start Date and End Date are required.");
      return;
    }
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays > 365) {
      setValidationMessage("The date range should not be longer than a year.");
      return;
    }

    setValidationMessage("");
    handleRunClick();
  };

  const handleRunClick = () => {
    const filterObject = {
      // pageNumber: currentPage,
      // pageSize: requestsPerPage,
      filter: {
        StartDate: startDate
          ? new Date(
              startDate.getTime() - startDate.getTimezoneOffset() * 60000
            ).toISOString()
          : null,
        EndDate: endDate
          ? new Date(
              endDate.getTime() - endDate.getTimezoneOffset() * 60000
            ).toISOString()
          : null,
        CalendarGrouping: calendarGrouping,
        CategoryOfWorkIds: categoryOfWorkIds.map((category) => category.id),
      },
    };

    setFilters(flattenObject(filterObject));
  };

  console.log("filters applied", filters);

  const {
    data: workOrder,
    isLoading,
    error,
  } = useFetchData(
    ["pmcm-category-report", filters],
    `/Report/GetPMCMPerformanceByCategoryOfWork`,
    {
      params: {
        ...filters,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    },
    "Error fetching work orders",
    !!filters,
    true
  );

  const {
    data: categoryOfWork,
    isLoading: isLoadingCow,
    error: cowError,
  } = useFetchData(
    ["work-category"],
    `/CategoryOfWorks/GetAllWorkCategories`,
    {},
    "Error fetching category of work"
  );

  const workOrderData = workOrder?.data;

  const formatDate = (dateString) => {
    return format(new Date(dateString), "dd/MM/yyyy");
  };

  const getCategoryNames = () => {
    return categoryOfWorkIds.map(
      (id) =>
        categoryOfWork.find((category) => category.id === id)
          ?.categoryOfWorkName ?? ""
    );
  };

  const formattedFilters = useMemo(() => {
    if (!filters) return null;

    return {
      startDate: formatDate(filters["filter.StartDate"]),
      endDate: formatDate(filters["filter.EndDate"]),
      calendarGrouping: filters["filter.CalendarGrouping"],
      categoryOfWork: getCategoryNames(
        filters["filter.CategoryOfWorkIds"]
      ).join(", "),
    };
  }, [filters]);

  const handleExcelExport = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([]);

    XLSX.utils.sheet_add_aoa(
      ws,
      [
        ["PM vs CM by Category Report"],
        [
          `Start Date: ${formattedFilters?.startDate ?? ""}`,
          `Category of Work: ${formattedFilters?.categoryOfWork ?? "All"}`,
        ],
        [
          `End Date: ${formattedFilters?.endDate ?? ""}`,
          `Calendar Grouping: ${
            formattedFilters?.calendarGrouping ?? "Weekly"
          }`,
        ],
        [],
      ],
      { origin: "A1" }
    );

    let jsonData = [];

    if (activeTab === "grid") {
      jsonData = workOrder?.data || [];
    } else if (activeTab === "graph") {
      // Placeholder: Modify this to export the graph data if applicable
      jsonData = [["Graph Data Placeholder"]];
    }

    // Extract specific fields and format createdDate
    const formattedData = jsonData.map((item) => ({
      "Category of Work": item.categoryOfWorkName,
      "Completed PM": item.completedPM,
      "On Hold PM": item.onHoldPM,
      "Completed CM": item.completedCM,
      "On Hold CM": item.onHoldCM,
    }));

    // Add headers manually
    const headers = [
      "Category of Work",
      "Completed PM",
      "On Hold PM",
      "Completed CM",
      "On Hold CM",
    ];
    XLSX.utils.sheet_add_aoa(ws, [headers], { origin: "A5" });

    // Add data below the headers
    XLSX.utils.sheet_add_json(ws, formattedData, {
      origin: "A6",
      skipHeader: true, // Skip default header
    });

    // Calculate maximum column widths
    const calculateMaxWidths = (data) => {
      const maxLengths = [];

      data.forEach((row) => {
        Object.values(row).forEach((cell, colIndex) => {
          const cellValue = String(cell || "");
          const length = cellValue.length;

          if (!maxLengths[colIndex] || length > maxLengths[colIndex]) {
            maxLengths[colIndex] = length;
          }
        });
      });

      return maxLengths.map((length) => ({ wch: length + 2 })); // Add padding
    };

    // Get maximum widths for both the header and the content
    const headerWidths = calculateMaxWidths([headers]);
    const contentWidths = calculateMaxWidths(formattedData.map(Object.values));

    const maxColumnWidths = headerWidths.map((width, index) => ({
      wch: Math.max(width.wch, contentWidths[index]?.wch || 0),
    }));

    // Set column widths and enable text wrapping
    ws["!cols"] = maxColumnWidths;
    ws["!rows"] = [{ hpx: 20 }]; // Example: set height for header row

    // Add color to the header
    const headerRange = XLSX.utils.decode_range("A5:H5"); // Adjust range as needed

    for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
      const cellAddress = XLSX.utils.encode_cell({ r: 4, c: C }); // Row 5 is index 4
      if (!ws[cellAddress]) continue;

      ws[cellAddress].s = {
        fill: {
          fgColor: { rgb: "d57d2a" },
        },
        font: {
          bold: true,
          color: { rgb: "FFFFFF" },
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center",
        },
      };
    }

    XLSX.utils.book_append_sheet(wb, ws, "PM CM Category");
    XLSX.writeFile(wb, "PM_CM_Category.xlsx");
  };

  const handlePdf = useReactToPrint({
    content: () =>
      document.getElementById(
        activeTab === "grid" ? "pm-cm-category" : "pm-cm-category-graph"
      ),
  });

  const maxCompletedPM =
    workOrderData &&
    Math.max(...workOrderData?.map((data) => data.completedPM));

  const maxCM =
    workOrderData &&
    Math.max(...workOrderData?.map((data) => data.completedCM));

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  return (
    <div className="work-orders-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="work-header">
              <div className="fs-20">
                Reports - PM vs CM by Category of Work Performance
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="other-nav">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="">PM vs CM by Category of Work Reports</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="inspection-cate">
              <div className="request-box-body p-0">
                <Tab.Container
                  defaultActiveKey="grid"
                  onSelect={(k) => setActiveTab(k)}
                >
                  <Nav className="workCostings-nav-report">
                    <Nav.Item>
                      <Nav.Link eventKey="grid">
                        PM vs CM by Category of Work Report
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="graph">
                        PM vs CM by Category of Work Graph
                      </Nav.Link>
                    </Nav.Item>
                    <div
                      className="selection-grope"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyItems: "center",
                        justifyContent: "start",
                      }}
                    >
                      <div>
                        <p>Report Filters: </p>
                      </div>

                      <div className="select-dropdown">
                        <div className="select-title">
                          <div className="fs-13">Date From:</div>
                          <div className="format-date">
                            <DatePicker
                              maxDate={endDate}
                              selected={startDate}
                              onChange={(date) => handleStartDateChange(date)}
                              customInput={<ExampleCustomInput />}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="select-dropdown">
                        <div className="select-title">
                          <div className="fs-13">Date To:</div>
                          <div className="format-date">
                            <DatePicker
                              minDate={startDate}
                              selected={endDate}
                              onChange={(date) => handleEndDateChange(date)}
                              customInput={<ExampleCustomInput />}
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        className="add-button my-2"
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                          background: "rgba(213, 125, 42, 0.87)",
                          padding: "8px 24px 8px 24px",
                          color: "white",
                          borderRadius: "8px",
                        }}
                        onClick={handleRun}
                      >
                        Run
                      </button>

                      {validationMessage && (
                        <div style={{ color: "red", marginTop: "10px" }}>
                          {validationMessage}
                        </div>
                      )}
                    </div>
                    <div
                      className="flex icon-thin"
                      style={{
                        color: "gray",
                        fontWeight: 100,
                        fontSize: "15px",
                        display: "flex",
                        gap: "20px",
                        alignItems: "center",
                        paddingTop: "15px",
                      }}
                    >
                      <FirstPageIcon
                        className="icon-thin"
                        style={{
                          color: workOrder?.hasPreviousPage
                            ? "gray"
                            : "lightgray",
                          cursor: workOrder?.hasPreviousPage
                            ? "pointer"
                            : "not-allowed",
                        }}
                        onClick={() => {
                          if (workOrder?.hasPreviousPage) {
                            setCurrentPage(1);
                            setFilters((prev) => ({ ...prev, pageNumber: 1 }));
                          }
                        }}
                      />
                      <button
                        style={{
                          color: workOrder?.hasPreviousPage
                            ? "gray"
                            : "lightgray",
                          cursor: workOrder?.hasPreviousPage
                            ? "pointer"
                            : "not-allowed",
                        }}
                        onClick={() => {
                          if (workOrder?.hasPreviousPage) {
                            setCurrentPage((prev) => {
                              const newPage = prev - 1;
                              setFilters((prevFilters) => ({
                                ...prevFilters,
                                pageNumber: newPage,
                              }));
                              return newPage;
                            });
                          }
                        }}
                        disabled={!workOrder?.hasPreviousPage}
                      >
                        <ArrowLeft className="icon-thin" />
                      </button>
                      <button
                        style={{
                          color: workOrder?.hasNextPage ? "gray" : "lightgray",
                          cursor: workOrder?.hasNextPage
                            ? "pointer"
                            : "not-allowed",
                        }}
                        onClick={() => {
                          if (workOrder?.hasNextPage) {
                            setCurrentPage((prev) => {
                              const newPage = prev + 1;
                              setFilters((prevFilters) => ({
                                ...prevFilters,
                                pageNumber: newPage,
                              }));
                              return newPage;
                            });
                          }
                        }}
                        disabled={!workOrder?.hasNextPage}
                      >
                        <ArrowRight className="icon-thin" />
                      </button>

                      <LastPageIcon
                        className="icon-thin"
                        style={{
                          color: workOrder?.hasNextPage ? "gray" : "lightgray",
                          cursor: workOrder?.hasNextPage
                            ? "pointer"
                            : "not-allowed",
                        }}
                        onClick={() => {
                          if (workOrder?.hasNextPage) {
                            setCurrentPage(workOrder.totalPages);
                            setFilters((prev) => ({
                              ...prev,
                              pageNumber: workOrder.totalPages,
                            }));
                          }
                        }}
                      />
                      <form action="" style={{ width: "30px", height: "30px" }}>
                        <input
                          type="text"
                          placeholder="1"
                          value={workOrder?.currentPage}
                          readOnly
                          style={{
                            width: "100%",
                            height: "100%",
                            textAlign: "center",
                            padding: 0,
                          }}
                        />
                      </form>
                      <p style={{ margin: 0 }}>
                        of {workOrder?.totalPages || "1"}
                      </p>
                      <div
                        style={{
                          borderLeft: "1px solid gray",
                          height: "24px",
                          margin: "0 10px",
                        }}
                      ></div>
                      <Dropdown>
                        <Dropdown.Toggle
                          className="icon-thin"
                          style={{
                            background: "none",
                            border: "none",
                            padding: 0,
                            color: "gray",
                          }}
                        >
                          <SaveAltIcon className="icon-thin" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={handlePdf}>PDF</Dropdown.Item>
                          <Dropdown.Item onClick={handleExcelExport}>
                            Excel
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Nav>
                  <hr />
                  <Tab.Content>
                    <Tab.Pane eventKey="grid">
                      <PMCMCategoryGrid
                        workOrder={workOrder}
                        categories={categoryOfWork}
                        filters={filters}
                        categoryOfWorkIds={categoryOfWorkIds}
                        isLoading={isLoading}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="graph">
                      <PMCMCategoryGraph
                        workOrder={workOrder}
                        maxScheduled={maxCompletedPM}
                        maxCM={maxCM}
                        // maxCM={maxCompletedCM}
                        categories={categoryOfWork}
                        filters={filters}
                        categoryOfWorkIds={categoryOfWorkIds}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PMCMCategory;
