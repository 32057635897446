import React from "react"; 
import { Link } from "react-router-dom";
 const ResponsesHeader = () => { 
  return (
    <>
      <div className="work-header">
        <div className="fs-20">Continuous  Check Response</div>
      
      </div>
     </>
  );
};

export default ResponsesHeader;
