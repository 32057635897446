import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FaCircleExclamation } from "react-icons/fa6";
import DownIcon from "../../../Assets/Icons/DownIcon";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import { Dropdown, Form } from "react-bootstrap";
import ApproveQuoteErrorModal from "./ApproveQuoteErrorModal";

const AssignAssetModal = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [selectTeamWork, setSelectTeamWork] = useState("Select team from list");
  const [selectUserWork, setSelectUserWork] = useState("Select user from list");
  const [selectCOWSearch, setSelectCOWSearch] = useState("");
  const [selectUserSearch, setSelectUserSearch] = useState("");

  const handleselectTeamWorkData = (eventKey) => {
    setSelectTeamWork(eventKey);
    setSelectCOWSearch("");
  };
  const handleselectUserWorkData = (eventKey) => {
    setSelectUserWork(eventKey);
    setSelectUserSearch("");
  };
  const teamWorkData = [
    { name: "Engineering Team" },
    { name: "Maintenance Team" },
    { name: "Safaricom Team" },
  ];
  const userData = [
    { name: "Leah Kihoro" },
    { name: "Kevin Odero" },
    { name: "James Mburu" },
  ];
  const filterselectTeamWorkData = teamWorkData.filter((item) =>
    item.name.toLowerCase().includes(selectCOWSearch.toLowerCase())
  );
  const filterselectUserData = userData.filter((item) =>
    item.name.toLowerCase().includes(selectUserSearch.toLowerCase())
  );

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="medium-modal add-asset-modal"
      >
        <Modal.Body style={{ padding: "28px 30px" }}>
          <div className="delate-content">
            <div className="">
              <span className="fs-16 fw-bold ">Assign Asset</span>
              <span className="fs-14 ps-3">Assign asset to a user or team</span>
            </div>
            <div
              className="delate-warning"
              style={{
                background: "#F1EFEF",
                minHeight: "230px",
                display: "grid",
                alignItems: "center",
                textAlign: "center",
                padding: "20px",
                marginTop: "30px",
                borderRadius: "5px",
              }}
            >
              <div>
                <FaCircleExclamation
                  style={{ color: "#D57D2A", fontSize: "70px" }}
                />
                <div
                  className="fs-19 fw-medium "
                  style={{
                    lineHeight: "1.4",
                    marginTop: "10px",
                    color: "#292D32",
                  }}
                >
                  Note that assigning the asset to a new user/ team will
                  automatically unassign it from any current user/ team it has
                  been assigned to.
                </div>
              </div>
            </div>
            <div className="row row-gap mt-4">
              <div className="col-md-12">
                <label className="fw-medium pb-2">Teams</label>
                <Dropdown
                  className="select__form"
                  onSelect={handleselectTeamWorkData}
                >
                  <Dropdown.Toggle
                    className={`select-title ${
                      selectTeamWork !== "Select team from list"
                        ? "selected"
                        : ""
                    }`}
                    style={{ height: "50px" }}
                  >
                    {selectTeamWork}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <form className="dropdown-search">
                      <button disabled>
                        <SearchIcon />
                      </button>
                      <input
                        value={selectCOWSearch}
                        onChange={(e) => setSelectCOWSearch(e.target.value)}
                        type="text"
                        placeholder="Search"
                      />
                    </form>
                    <div className="dropdown-item-content">
                      {filterselectTeamWorkData.map((item, index) => (
                        <Dropdown.Item key={index} eventKey={item.name}>
                          {item.name}
                        </Dropdown.Item>
                      ))}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="col-md-12">
                <label className="fw-medium pb-2">User</label>
                <Dropdown
                  className="select__form"
                  onSelect={handleselectUserWorkData}
                >
                  <Dropdown.Toggle
                    className={`select-title ${
                      selectUserWork !== "Select user from list"
                        ? "selected"
                        : ""
                    }`}
                    style={{ height: "50px" }}
                  >
                    {selectUserWork}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <form className="dropdown-search">
                      <button disabled>
                        <SearchIcon />
                      </button>
                      <input
                        value={selectUserSearch}
                        onChange={(e) => setSelectUserSearch(e.target.value)}
                        type="text"
                        placeholder="Search"
                      />
                    </form>
                    <div className="dropdown-item-content">
                      {filterselectUserData.map((item, index) => (
                        <Dropdown.Item key={index} eventKey={item.name}>
                          {item.name}
                        </Dropdown.Item>
                      ))}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div
              className="button-group"
              style={{
                marginTop: "25px",
                display: "flex",
                justifyContent: "end",
                gap: "20px",
              }}
            >
              <button className="cancel-btn" onClick={props.onHide}>
                Cancel
              </button>
              <button className="delate-btn" onClick={props.onHide}>
                Assign
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ApproveQuoteErrorModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default AssignAssetModal;
