import React, { useState } from "react";
import DownIcon from "../../../Assets/Icons/DownIcon";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import ArrowLeft from "../../../Assets/Icons/ArrowLeft";
import ArrowRight from "../../../Assets/Icons/ArrowRight";
import DropdownIcon from "../../../Assets/Icons/DropdownIcon";
import searchAllProperty from "../../../utls/searchAllProperty";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EyesIcon from "../../../Assets/Icons/EyesIcon";
import { useNavigate, useParams } from "react-router-dom";
import usefetchData from "../../../hooks/useFetchData";

const CorrectiveWork = () => {
  const [currentPage, setCurrentPage] = useState(1); //for pagination
  const [RequestsPerPage, setRequestsPerPage] = useState(10); //for pagination
  const [search, setSearch] = useState(""); //for search

  const {id} = useParams()
  const navigate = useNavigate()

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const ExampleCustomInput = ({ value, onClick }) => (
    <button className="example-custom-input" onClick={onClick}>
      {value ? value : ""} <DownIcon />
    </button>
  );

  const { data: workData, isLoading: isLoadingWork } = usefetchData(
    ["corrective-asset", parseInt(id)],
    `/Assets/GetAllWorkByAssetQuery`,
    {
      params: {
        assetId: parseInt(id),
        ticketType: "corrective"

      }
    },
    "Error fetching asset data by id"
  );

  //filter via start date if date is not null
  const filteredByStartDate = startDate
    ? RequestsData.filter((item) => {
        const date = new Date(item.date);
        return date.getTime() >= startDate.getTime();
      })
    : RequestsData;

  //filter via end date if date is not null
  const filteredByEndDate = endDate
    ? filteredByStartDate.filter((item) => {
        const date = new Date(item.date);
        return date.getTime() <= endDate.getTime();
      })
    : filteredByStartDate;

  // filter via search
  const filteredBySearch = searchAllProperty(filteredByEndDate, search);

  //for pagination
  const indexOfLastRequests = currentPage * RequestsPerPage;
  const indexOfFirstRequests = indexOfLastRequests - RequestsPerPage;
  const currentRequests = filteredBySearch.slice(
    indexOfFirstRequests,
    indexOfLastRequests
  );

  // Calculate total pages
  const totalPages = Math.ceil(filteredBySearch.length / RequestsPerPage);

  // Update current page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div className="ProfileParts">
      <div className="selection-grope ">
        <div className="select-dropdown">
          <div className="select-title">
            <div className="fs-13">Date From:</div>
            <div className="format-date">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                customInput={<ExampleCustomInput />}
              />
            </div>
          </div>
        </div>
        <div className="select-dropdown">
          <div className="select-title">
            <div className="fs-13">Date To:</div>
            <div className="format-date">
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                customInput={<ExampleCustomInput />}
              />
            </div>
          </div>
        </div>

        <form action="" className="search__box">
          <button disabled type="submit">
            <SearchIcon />
          </button>
          <input
            onChange={(e) => setSearch(e.target.value)}
            type="search"
            placeholder="Search"
          />
        </form>
      </div>
      <div className="inspection-tables-content">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>
                  <span>Ref</span>
                </th>
                <th>
                  <span>Asset</span>
                </th>
                <th>
                  <span>Work done by</span>
                </th>
                <th className="table-th"></th>
              </tr>
            </thead>
            <tbody>
              {workData?.data?.length > 0 && workData?.data?.map((item, i) => (
                <tr key={i}>
                  <td style={{ minWidth: "160px" }}>
                    <div>
                      <div className="fw-semibold">{item.ticketRef}</div>
                      <div className="fw-regular gray-c">
                        Date: {new Date(item.dateClosed).toLocaleDateString()}
                      </div>
                    </div>
                  </td>
                  <td style={{ minWidth: "160px" }}>
                    <div>
                      <div className="fw-semibold">{item.assetName}</div>
                      <div className="fw-regular gray-c">
                        Issue: {item.ticketTitle}
                      </div>
                    </div>
                  </td>
                  <td style={{ minWidth: "160px" }}>
                    <div>
                      <div className="fw-semibold">{item.currentAssignedTeam}</div>
                      <div className="fw-regular gray-c">
                        Worker: {item.currentAssignedUser}
                      </div>
                    </div>
                  </td>

                  <td>
                    <div className="d-flex gap-1 upload-icons">
                      <button
                        className="dropdown-item"
                        style={{ color: "#6C5B51" }}
                        onClick={()=> navigate(`/work-orders/work-view/${item?.ticketId}`)}
                      >
                        <EyesIcon />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="table-pagination pagination-center">
          <div className="pagination-row">
            Rows per page:
            <div className="dropdown select-dropdown">
              <button className="select-title" data-bs-toggle="dropdown">
                <span className="fs-14 d-flex align-items-center gap-2">
                  {RequestsPerPage} <DropdownIcon />
                </span>
              </button>
              <ul
                className="dropdown-menu select-menu"
                style={{ minWidth: "25px" }}
              >
                <li onClick={() => setRequestsPerPage(5)}>5</li>
                <li onClick={() => setRequestsPerPage(10)}>10</li>
                <li onClick={() => setRequestsPerPage(15)}>15</li>
                <li onClick={() => setRequestsPerPage(20)}>20</li>
              </ul>
            </div>
          </div>
          <div className="pagination-number">
            <div className="fs-14">
              {currentPage} of {totalPages}
            </div>
            <div className="arrow-btn">
              <button
                onClick={() =>
                  currentPage !== 1 && handlePageChange(currentPage - 1)
                }
              >
                <ArrowLeft />
              </button>
              <button
                onClick={() =>
                  totalPages !== currentPage &&
                  handlePageChange(currentPage + 1)
                }
              >
                <ArrowRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorrectiveWork;

const RequestsData = [
  {
    refTitle: "CM1000009",
    date: "04/15/2024",
    assetTitle: "Pump 1 - 540019382",
    assetDesc: "Pump leakage",
    workDoneTitle: "Projects",
    workDoneDesc: "Cathy Mua",
  },
  {
    refTitle: "CM1000009",
    date: "04/15/2024",
    assetTitle: "Pump 1 - 540019382",
    assetDesc: "Pump leakage",
    workDoneTitle: "Projects",
    workDoneDesc: "Cathy Mua",
  },
  {
    refTitle: "CM1000009",
    date: "04/15/2024",
    assetTitle: "Pump 1 - 540019382",
    assetDesc: "Pump leakage",
    workDoneTitle: "Projects",
    workDoneDesc: "Cathy Mua",
  },
  {
    refTitle: "CM1000009",
    date: "04/15/2024",
    assetTitle: "Pump 1 - 540019382",
    assetDesc: "Pump leakage",
    workDoneTitle: "Projects",
    workDoneDesc: "Cathy Mua",
  },
  {
    refTitle: "CM1000009",
    date: "04/15/2024",
    assetTitle: "Pump 1 - 540019382",
    assetDesc: "Pump leakage",
    workDoneTitle: "Projects",
    workDoneDesc: "Cathy Mua",
  },
  {
    refTitle: "CM1000009",
    date: "04/15/2024",
    assetTitle: "Pump 1 - 540019382",
    assetDesc: "Pump leakage",
    workDoneTitle: "Projects",
    workDoneDesc: "Cathy Mua",
  },
  {
    refTitle: "CM1000009",
    date: "04/15/2024",
    assetTitle: "Pump 1 - 540019382",
    assetDesc: "Pump leakage",
    workDoneTitle: "Projects",
    workDoneDesc: "Cathy Mua",
  },
  {
    refTitle: "CM1000009",
    date: "04/15/2024",
    assetTitle: "Pump 1 - 540019382",
    assetDesc: "Pump leakage",
    workDoneTitle: "Projects",
    workDoneDesc: "Cathy Mua",
  },
  {
    refTitle: "CM1000009",
    date: "04/15/2024",
    assetTitle: "Pump 1 - 540019382",
    assetDesc: "Pump leakage",
    workDoneTitle: "Projects",
    workDoneDesc: "Cathy Mua",
  },
];
