import React from "react";
import DownIcon from "../../Assets/Icons/DownIcon";
import { Link } from "react-router-dom";
import MoreVertical from "../../Assets/Icons/MoreVertical";
import AddIcon from "../../Assets/Icons/AddIcon";
const AddPMHeader = () => {
  return (
    <div className="work-header">
      <div className="fs-20">Add PM Category Schedule</div>
      <div className="dropdown select-dropdown">
        <button className="select-title" data-bs-toggle="dropdown">
          <span className="fs-15 d-flex align-items-center gap-2">
            <MoreVertical /> Actions <DownIcon />
          </span>
        </button>
        <ul className="dropdown-menu dropdown-menu-end select-menu">
          <li>
            <Link to="">
              <AddIcon /> Add Schedule
            </Link>
          </li> 
        </ul>
      </div>
    </div>
  );
};

export default AddPMHeader;
