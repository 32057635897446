import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import useMutateData from '../../../hooks/useMutateData';

const AddLocationGroupingModal = (props) => {
  const [regionName,setRegionName]=useState("");
  const [regionDescription,setRegionDescription]=useState("");


  const { mutate, isLoading: isSubmitting } = useMutateData({
    url: `/Locations/AddLocationGroup`,
    method: 'POST',
    onSuccessfullMutation: (data) => {
      if (data?.data?.succeeded === true) {
        toast.success('Location Group added successfully', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        props.onHide();
      } else {
        toast.error('Failed to add Location Group ', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
    successMessage: 'Location Group added successfully',
    errorMessage: 'Failed to add location Group',
    queryKeysToInvalidate: [["locationsgrouping-data", ""]]
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
     regionName,
     regionDescription,
    };
    mutate(payload);
  };
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
      <form onSubmit={handleSubmit}>
        <div className="delate-content">
          <div className="d-md-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Add Location Grouping</span>
            <div className="fs-14 pt-2 pt-md-0" style={{ color: "#72777A" }}>
              Add a new location grouping
            </div>
          </div>
          <div className="mt-4 pt-2 row row-gap-4 modal-forms-content">
            <div className="col-md-12">
              <label className="fw-medium pb-2">Location Grouping </label>
              <input
                className="modal-input-box"
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "0 15px",
                }}
                placeholder="Enter location grouping name"
                value={regionName}
                onChange={(e)=>
                  setRegionName(e.target.value)
      
                }
              />
            </div>
            <div className="col-md-12">
              <label className="fw-medium pb-2">Description</label>
              <textarea
                className="modal-input-box"
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "90px",
                  borderRadius: "5px",
                  padding: "15px 15px",
                }}
                placeholder="Enter location grouping description"
                value={regionDescription}
                onChange={(e)=>
                  setRegionDescription(e.target.value)
                 
                }
              />
            </div>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide} >
              Cancel
            </button>
            <button  type="submit" className="delate-btn"  disabled={isSubmitting}>
            {isSubmitting ? 'Saving...' : 'Save'}
            </button>
           
          </div>
        </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddLocationGroupingModal;
