import React from "react";
import Modal from "react-bootstrap/Modal";

const AssetLocationModal = (props) => {
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal modal-size-780"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="">
            <span className="fs-16 fw-bold ">Asset Location </span>
            <span style={{ color: "#72777A" }} className="fs-14 ps-3">
              View current location of the asset
            </span>
          </div>
          <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
            <div className="col-md-12">
              <div className="location-map-modal">
                <iframe
                  src={`https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d63819.741815474146!2d${props?.data?.longitude}!3d${props?.data?.latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1713539533418!5m2!1sen!2sbd`}
                  style={{ border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Location Map"
                ></iframe>
                <div className="location-add">
                  <div className="text-center">
                    <img src={props?.data?.assetPhotoURL ?? ""} alt={props?.data?.assetName ?? "Asset Image"} />
                  </div>
                  <div className="fs-14 fw-medium d-flex gap-2 pt-2">
                    <span className="gray-c">Asset:</span> {props?.data?.assetName}
                  </div>
                  <div className="fs-14 fw-medium d-flex gap-2 pt-2">
                    <span className="gray-c">Location:</span> {props?.data?.assetLocationName}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="delate-btn" onClick={props.onHide}>
              Close
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AssetLocationModal;
