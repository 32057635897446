import React, { useState, useContext, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import EyesIcon from "../../../Assets/Icons/EyesIcon";
import DownloadIcon from "../../../Assets/Icons/DownloadIcon";
import DeleteIcon from "../../../Assets/Icons/DeleteIcon";
import AddJobCardModal from "../Modal/AddJobCardModal";
import DeleteModal from "../Modal/DeleteModal";
import ViewImage from "../Modal/ViewImage";
import ViewVideo from "../Modal/ViewVideo";
import ViewDocument from "../Modal/ViewDocument";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { DELETE_TICKET_JOB_CARDS } from "../../../utls/constants";
import { post } from "../../../Services/ApiHelper";
import { isNullOrUndefined } from "util";

const ViewJob = () => {
  const {ticketSummary,setTicketSummary,isLoading, setIsLoading} = useContext(WorkOrderContext);
  const [eventKey, setEventKey] = React.useState("");
  const [jobCardShow, setJobCardShow] = useState(false);
  const [delateModalShow, setDelateModalShow] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState(isNullOrUndefined);
  const [imageShow, setImageShow] = useState({
    isShow: false,
    image: "",
    file_name: "",
  });
  const [videoShow, setVideoShow] = useState({
    isShow: false,
    video: "",
    file_name: "",
  });
  const [documentShow, setDocumentShow] = useState({
    isShow: false,
    document: "",
    file_name: "",
  });
  //get url by using useLocation
  const location = useLocation();
  useEffect(() =>{
    if (location.pathname === `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}`) {
      setEventKey("0");
    } else if (location.pathname === `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}/assets`) {
      setEventKey("1");
    } else if (location.pathname === `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}/costings`) {
      setEventKey("2");
    } else if (location.pathname === `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}/job-cards`) {
      setEventKey("3");
    }
  },[location,ticketSummary])

  const fileData = ticketSummary?.jobCardSummary;
 

  //function to download files
  const handleDownload = (fileUrl) => {
    try {
      // Extract the file name from the URL
      const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
  
      // Create a temporary <a> element
      const link = document.createElement("a");
      link.href = fileUrl; // Set the href to the file URL
      link.setAttribute("download", fileName); // Set the download attribute with the file name
      document.body.appendChild(link); // Append the link to the body
      link.click(); // Simulate a click to trigger the download
      document.body.removeChild(link); // Remove the link from the body
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.error("Error downloading file.");
    }
  };
  
  

  const handleDelete = async () => {
    setIsLoading(true)
    try {
      const bearerToken = localStorage.getItem("bearerToken");
    
      const data = { id: itemIdToDelete };
      await post(DELETE_TICKET_JOB_CARDS, data, bearerToken); 
  
      const updatedFileData = fileData.filter(item => item.id !== itemIdToDelete);
  
      setTicketSummary(prevState => ({
        ...prevState,
        jobCardSummary: updatedFileData
      }));
  
      toast.success("Data Deleted Successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setIsLoading(true);
      
      setTimeout(() => {
        setDelateModalShow(false)
        setIsLoading(false)
      }, 3000);

      
      
    } catch (error) {
      toast.error("An error occured", {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
  

  return (
    eventKey && (
    <>
      <div className="order-request washroom-right-content responses-cont">
      <div className="washroom-title">
            <div className="fs-13 fw-medium">WORK ORDER JOB CARDS</div>
            <div className="fs-13 fw-bold">
              Status:{" "}
              <button className="primary-text fw-bold">
                {ticketSummary?.workOrderSummary?.currentTicketActivity}
              </button>
            </div>
          </div>
        <div className="order-request mt-4 p-0">
          <Accordion defaultActiveKey="0">
            {/* JOB CARDS */}
            <Accordion.Item eventKey="0" className="request-box">
              <Accordion.Header className="request-box-title">
                <div className="fs-13 fw-medium">JOB CARDS</div>
              </Accordion.Header>
              <Accordion.Body className="request-box-body p-0">
                <div className="fs-14 fw-medium black-38">
                  Job cards uploaded
                </div>
                {fileData.map((item) => (
                  <div className="upload-details" key={item.id}>
                    <div>
                      <div className="fs-14" style={{ color: "#1F2024" }}>
                        {item.fileName}
                      </div>
                      <div className="fs-13 gray-c">
                        Added: {item.createdDate}{" "}
                      </div>
                    </div>
                    <div className="upload-icons">
                      <button
                        onClick={() =>
                          {
                          const url = item.url;
                          const ext = url.substring(url.lastIndexOf('.') + 1);
                          
                          (ext === "jpg" || ext === "jpeg" || ext === "png" || ext === "JPG" || ext === "JPEG" || ext === "PNG")
                            ? setImageShow({
                                isShow: true,
                                image: item.url,
                                file_name: item.fileName,
                              })
                            : (ext==="pdf" || ext=== "PDF")
                            ? setDocumentShow({
                                isShow: true,
                                document: item.url,
                                file_name: item.fileName,
                              })
                            : (ext===
                                "mp4" ||
                                ext==="AVI" ||
                                ext=== "3GP" ||
                                ext===  "MP4" ||
                                ext=== "avi" ||
                                ext=== "3gp"
                              ) &&
                              setVideoShow({
                                isShow: true,
                                video: item.url,
                                file_name: item.fileName,
                              })}
                        }
                      >
                        <EyesIcon />
                      </button>
                      <button onClick={() => handleDownload(item.url)}>
                        <DownloadIcon />
                      </button>
                      <button onClick={() => {
                        setDelateModalShow(true)
                        setItemIdToDelete(item.id)
                        }}>
                        <DeleteIcon />
                      </button>
                    </div>
                  </div>
                ))}
                <div className="text-end">
                  <button
                    className="add-button my-3 "
                    onClick={() => setJobCardShow(true)}
                  >
                    Add Job Card
                  </button>
                  <AddJobCardModal
                    show={jobCardShow}
                    onHide={() => setJobCardShow(false)}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>

      {/* Modal function */}
      <ViewImage show={imageShow} onHide={() => setImageShow(false)} />
      <ViewVideo show={videoShow} onHide={() => setVideoShow(false)} />
      <ViewDocument show={documentShow} onHide={() => setDocumentShow(false)} />
      <DeleteModal
        show={delateModalShow}
        onHide={() => setDelateModalShow(false)}
        onDelete={handleDelete}
      />
    </>
    )
  );
};

export default ViewJob;
