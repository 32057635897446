import React from "react";
import { Link } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import NoSchedule from "./WorkScheduling/NoSchedule";
import CreateSchedule from "./WorkScheduling/CreateSchedule";
import UseSchedule from "./WorkScheduling/UseSchedule";
import { useSelector, useDispatch } from "react-redux";
import { setSchedule } from "../../../redux/pmFormSlice";

const PMWorkScheduling = () => {
  const dispatch = useDispatch();
  const formState = useSelector((state) => state.pmform);
  const activeTab = formState.schedule;

  const clear = () => {
    localStorage.removeItem("ScheduleBasis");
  };

  // Function to handle tab click and update Redux state
  const handleTabChange = (key) => {
    clear(); // Clear local storage
    dispatch(setSchedule(key)); // Update the active tab in Redux
  };

  return (
    <div className="order-details-content PMWorkScheduling pb-lg-4">
      <div>
        <div className="fs-16 fw-bold">Work Scheduling</div>
        <Tab.Container activeKey={activeTab}>
          <Nav variant="pills" className="scheduling-nav">
            <Nav.Item>
              <Nav.Link
                eventKey="first"
                onClick={() => handleTabChange("first")}
              >
                No Schedule
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="second"
                onClick={() => handleTabChange("second")}
              >
                Create Schedule
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="third"
                onClick={() => handleTabChange("third")}
              >
                Use Schedule
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="first">
              <NoSchedule />
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <CreateSchedule />
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              <UseSchedule />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>

      <div className="details-buttons d-flex justify-content-between pt-lg-5">
        <Link to="/preventive-works" className="next-btn ms-0">
          Previous
        </Link>
        <Link
          to="/preventive-works/create-pm-work/assets"
          className="next-btn mt-lg-5"
          state={{ routFrom: 1 }}
        >
          Next
        </Link>
      </div>
    </div>
  );
};

export default PMWorkScheduling;