import { createSlice } from '@reduxjs/toolkit';

const initialState={
  locationName: "",
  locationDescription: "",
  parentLocationId: 0,
  parentLocationName:"",
  locationAddress: "",
  longitude: "",
  latitude: "",
  personInChargeId: 0, 
  contactPersonId:0,
  contactPersonName:"",
  regionId: 0,
  regionName:''
 
  
}

const locationSlice=createSlice({
  name:'location',
  initialState,
  reducers:{
    setLocationName(state,action){
      state.locationName=action.payload
    },
    setLocationDesription(state,action){
      state.locationDescription=action.payload
    },

    setLocationAddress(state,action){
      state.locationAddress=action.payload
    },

    setLongitude(state,action){
      state.longitude=action.payload
    },

    setLatitude(state,action){
      state.latitude=action.payload
    },

    setParentLocation(state,action){
      state.parentLocationId=action.payload.id;
      state.parentLocationName=action.payload.name;
    },

    setRegion(state,action){
      state.regionId=action.payload.id;
      state.regionName=action.payload.name;
    },
   setEngineerInCharge(state,action){
    state.engineerInCharge=action.payload;

   },
   setContactPerson(state,action){
    state.contactPersonId=action.payload.id;
    state.contactPersonName=action.payload.name;
  },
   setInchargeName(state,action){
    state.inChargeName=action.payload
   }, 
   setLocationFiles(state, action) {
    state.locationFiles = action.payload;
  },
  addLocationFile(state, action) {
    state.locationFiles.push(action.payload);
  },
  removeLocationFile(state, action) {
    state.locationFiles = state.locationFiles.filter(file => file.fileName !== action.payload);
  },
  resetLocationState(state) {
    return initialState;
  }

  }
});

export const{
  setLocationName,
  setLocationDesription,
  setLocationAddress,
  setLongitude,
  setLatitude,
  setParentLocation,
  setRegion,
  setEngineerInCharge,
  setInchargeName,
  setContactPerson,
  setLocationFiles,
  addLocationFile,
  removeLocationFile,
  resetLocationState
}=locationSlice.actions;

export default locationSlice.reducer;