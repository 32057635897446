import React from "react"; 
import { Link } from "react-router-dom"; 
import WorkCostsHeader from "../Components/Work-Costs/WorkCostsHeader";
import WorkCostsTable from "../Components/Work-Costs/WorkCostsTable";

const WorkCosts = () => {
  return (
    <div className="work-orders-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <WorkCostsHeader />
          </div>
          <div className="col-lg-12">
            <div className="other-nav">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link>Work Costs</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="inspection-cate " style={{ paddingTop: "1px" }}>
              <div className="f3washroom-min-content request-tab-content">
                <WorkCostsTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkCosts;
