import React, { useContext, useEffect, useState } from "react";
import { Nav, Tab, Form, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link, useParams } from "react-router-dom";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import DownIcon from "../../../Assets/Icons/DownIcon";
import { get, post } from "../../../Services/ApiHelper";
import {
  GET_CATEGORY_OF_WORKS,
  GET_TEAMS,
  GET_TEAM_TO_ASSIGN_BY_CATEGORY,
  INVITE_RFQ_DIAGNOSIS,
  GET_REQUEST_SUMMARY_BY_ID,
} from "../../../utls/constants";
import { set } from "lodash";
import { toast } from "react-toastify";
import { RequestDetailsContext } from "../../../Context/Inspections/WorkOrderContext";
import Astericks from "../../Common/Asterick";

const RequestQuotesDiagnosisModal = (props) => {
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedTeamIds, setSelectedTeamIds] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [showChecklistList, setShowChecklistList] = useState(false);
  const [showCheckCategory, setShowCheckCategory] = useState(false);
  const [selectLabourCostSearch, setSelectLabourCostSearch] = useState("");
  const [selectTeamSearch, setSelectTeamSearch] = useState("");
  const [extendingTime, setExtendTime] = useState(0);
  const [timeError, setTimeError] = useState(null);
  const [teamError, setTeamError] = useState(null);
  const [submissionDeadline, setSubmissionDeadline] = useState(true);
  const [team, setTeam] = useState([]);
  const [categoryWork, setCategoryWork] = useState([]);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [inviteAllTeams, setInviteAllTeams] = useState(false);
  const { request, setRequest } = useContext(RequestDetailsContext);
  const [activeKey, setActiveKey] = useState("first"); // Initial active key state

  const handleCheckboxChange = (teamName) => {
    const id = team?.find((item) => item.teamName === teamName).id;

    setSelectedTeams((prevselectedTeams) => {
      if (prevselectedTeams.includes(teamName)) {
        return prevselectedTeams.filter(
          (selecteTeam) => selecteTeam !== teamName
        );
      } else {
        return [...prevselectedTeams, teamName];
      }
    });
    setSelectedTeamIds((prevSelectedTeamIds) => {
      if (prevSelectedTeamIds.includes(id)) {
        return prevSelectedTeamIds.filter((selecteTeam) => selecteTeam !== id);
      } else {
        return [...prevSelectedTeamIds, id];
      }
    });
  };

  const handleCheckboxCategory = (category) => {
    const id = categoryWork?.find(
      (item) => item.categoryOfWorkName === category
    ).id;
    setSelectedCategory((prevSelectedCategory) => {
      if (prevSelectedCategory?.includes(category)) {
        return prevSelectedCategory?.filter(
          (selectedCategory) => selectedCategory !== category
        );
      } else {
        return [...prevSelectedCategory, category];
      }
    });
    setSelectedCategoryIds((prevSelectedCategoryIds) => {
      if (prevSelectedCategoryIds?.includes(id)) {
        return prevSelectedCategoryIds?.filter(
          (selectedCategory) => selectedCategory !== id
        );
      } else {
        return [...prevSelectedCategoryIds, id];
      }
    });
  };

  const token = localStorage.getItem("bearerToken");
  useEffect(() => {
    get(GET_TEAMS, token)
      .then((response) => {
        setTeam(response);
      })
      .catch((error) => {
        console.log(error);
      });
    get(GET_CATEGORY_OF_WORKS, token)
      .then((response) => {
        setCategoryWork(response);
      })
      .catch((error) => {
        console.log(error);
      });

    setExtendTime(0);
    setSelectLabourCostSearch("");
    setSelectTeamSearch("");
    setSelectedTeams([]);
    setSelectedTeamIds([]);
    setSelectedCategory([]);
    setSelectedCategoryIds([]);
    setSubmissionDeadline(true);
    setTimeError(null);
    setTeamError(null);
  }, [props.show]);

  const validateInputs = (activeOption) => {
    let isValid = true;
    setTimeError(null);
    setTeamError(null);

    if (!extendingTime || extendingTime === 0) {
      setTimeError("Required *");
      isValid = false;
    }
    if (
      activeOption === "first" &&
      Array.isArray(selectedTeams) &&
      selectedTeams.length === 0
    ) {
      setTeamError("Required *");
      isValid = false;
    }
    return isValid;
  };

  //Below should filter Teams based on Category;Check why not working??
  useEffect(() => {
    const payload = {
      categoryOfWorkIds: selectedCategoryIds,
    };
    post(GET_TEAM_TO_ASSIGN_BY_CATEGORY, payload, token)
      .then((response) => {
        setTeam(response);
      })
      .catch((error) => {});
  }, [selectedCategoryIds]);

  // Filter labour cost data by search input
  const filteredTeam = team?.filter((item) =>
    item?.teamName
      ?.toLowerCase()
      ?.includes(selectLabourCostSearch.toLowerCase())
  );

  // Filter category data by search input
  const filteredCategory = categoryWork?.filter((item) =>
    item?.categoryOfWorkName
      .toLowerCase()
      .includes(selectTeamSearch.toLowerCase())
  );
  const handleInvite = (activeOption) => {
    // e.preventDefault();
    const isValid = validateInputs(activeOption);
    if (!isValid) {
      return;
    }
    setIsLoading(true);
    let data = {};
    if (activeOption === "second") {
      data = {
        requestId: id,
        estimatedHours: parseInt(extendingTime),
        allEligibleTeams: true,
        blockQuotePastDeadLine: submissionDeadline,
        teamListIds: [],
      };
    } else {
      data = {
        requestId: id,
        estimatedHours: parseInt(extendingTime),
        allEligibleTeams: false,
        blockQuotePastDeadLine: submissionDeadline,
        teamListIds: selectedTeamIds,
      };
    }
    post(INVITE_RFQ_DIAGNOSIS, data, token)
      .then((response) => {
        if (response.succeeded) {
          /*setRequest({
            ...request,
            requestSummaryDetailDto: {
              ...request.requestSummaryDetailDto,
              statusName: "Quoting",
            },
          });
          setExtendTime(0);
          setSelectedTeams([]);
          setSelectedTeamIds([]);
          setSelectedCategory([]);
          setSelectedCategoryIds([]);
          setSubmissionDeadline(true);*/
          get(GET_REQUEST_SUMMARY_BY_ID(id), token)
            .then((result) => {
              setRequest(result);
            })
            .catch((error) => {
              console.log(error);
            });
          props.onHide();
          setIsLoading(false);
          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });
        } else {
          props.onHide();
          setIsLoading(false);
          toast.error(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });
        }
      })
      .catch((error) => {
        props.onHide();
        setIsLoading(false);
        toast.error("An error occured", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
      });
  };
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal modal-size-780 add-asset-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-md-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Request Quotes & Diagnosis</span>
            <div className="fs-14 pt-2 pt-md-0" style={{ color: "#72777A" }}>
              Request contractors/ teams to send quotes and diagnosis for the
              job
            </div>
          </div>
          <div className="fs-16 fw-semibold" style={{ paddingTop: "30px" }}>
            Invite
          </div>
          <Tab.Container
            defaultActiveKey="first"
            activeKey={activeKey} // Pass activeKey state here
            onSelect={(key) => setActiveKey(key)} // Update activeKey state when a tab is selected
          >
            <Nav variant="pills" className="existing-tab-nav row">
              <Nav.Item className="col-md-6">
                <Nav.Link eventKey="first">
                  <div className="fs-15 fw-medium title-text">
                    Selected Teams/ Contractors
                  </div>
                  <div className="fs-13 fs-italic">
                    Only those teams/ contractors chosen here will get the
                    request to send a diagnosis and quote.
                  </div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="col-md-6">
                <Nav.Link eventKey="second">
                  <div className="fs-15 fw-medium title-text">
                    All Teams/ Contractors
                  </div>
                  <div className="fs-13 fs-italic">
                    All teams/ contractors in the system will get the request to
                    send a diagnosis and quote.
                  </div>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div className="row row-gap">
                  <div className="col-md-6">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <label className="fw-medium pb-2 fs-14">
                        RFQ/ Diagnosis deadline in hours
                        <Astericks />
                      </label>
                      {timeError && <p style={{ color: "red" }}>{timeError}</p>}
                    </div>
                    <input
                      type="number"
                      min={0}
                      input={extendingTime}
                      onChange={(e) => {
                        setExtendTime(e.target.value);
                      }}
                      style={{
                        background: "#F1EFEF",
                        width: "100%",
                        border: "0",
                        height: "50px",
                        borderRadius: "5px",
                        padding: "0 15px",
                      }}
                      placeholder="Enter the RFQ/ Diagnosis deadline in hours"
                      onKeyPress={(event) => {
                        // Prevent typing of negative sign
                        if (event.key === "-") {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="fw-medium pb-2 fs-14">
                      Block RFQ/ Diagnosis submission after deadline
                    </label>
                    <Form className="row check-icons-modal">
                      <div className="col-5">
                        <Form.Check
                          value={submissionDeadline}
                          inline
                          label="Yes"
                          onChange={(e) => {
                            setSubmissionDeadline(!submissionDeadline);
                          }}
                          checked={submissionDeadline}
                          name="group1"
                          type="radio"
                          id="inline-radio-1"
                        />
                      </div>
                      <div className="col-6">
                        <Form.Check
                          value={submissionDeadline}
                          onChange={(e) => {
                            setSubmissionDeadline(!submissionDeadline);
                          }}
                          inline
                          label="No"
                          name="group1"
                          type="radio"
                          id="inline-radio-2"
                        />
                      </div>
                    </Form>
                  </div>
                  <div className="fs-16 pt-3 fw-semibold">
                    Choose Team/ Contractor
                  </div>
                  <div className="col-md-6">
                    <label className="fw-medium pb-2 fs-14">
                      Category of Works
                    </label>
                    <div className="checklist-box select__form">
                      <button
                        className="checklist-btn"
                        onClick={() => setShowCheckCategory(!showCheckCategory)}
                      >
                        {selectedCategory.length === 0 ? (
                          <div>
                            <span style={{ color: "#C5C7CD" }}>
                              Select category of work from list
                            </span>
                            {selectedCategory.length > 0 && (
                              <span style={{ color: "#000" }}>
                                {selectedCategory.join(", ")}
                              </span>
                            )}
                          </div>
                        ) : (
                          <span style={{ color: "#000" }}>
                            {selectedCategory.join(", ")}
                          </span>
                        )}
                        <DownIcon />
                      </button>
                      <div
                        className={`checklist-list ${
                          showCheckCategory ? "" : "hide d-none"
                        }`}
                      >
                        <form className="dropdown-search ps-0">
                          <button disabled>
                            <SearchIcon />
                          </button>
                          <input
                            onChange={(e) =>
                              setSelectTeamSearch(e.target.value)
                            }
                            type="text"
                            placeholder="Search"
                          />
                        </form>
                        <ul className="dropdown-item-content mt-2">
                          {filteredCategory?.map((item, index) => (
                            <li key={index}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id={item.categoryOfWorkName}
                                  checked={selectedCategory.includes(
                                    item.categoryOfWorkName
                                  )}
                                  onChange={() =>
                                    handleCheckboxCategory(
                                      item.categoryOfWorkName
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  style={{ fontSize: "14px" }}
                                  htmlFor={item.categoryOfWorkName}
                                >
                                  {item.categoryOfWorkName}
                                </label>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <label className="fw-medium pb-2 fs-14">
                        Team/ Contractor
                        <Astericks />
                      </label>
                      {teamError && <p style={{ color: "red" }}>{teamError}</p>}
                    </div>
                    <div className="checklist-box select__form">
                      <button
                        className="checklist-btn"
                        onClick={() => setShowChecklistList(!showChecklistList)}
                      >
                        {selectedTeams.length === 0 ? (
                          <div>
                            <span style={{ color: "#C5C7CD" }}>
                              Select contractor from list
                            </span>
                            {selectedTeams.length > 0 && (
                              <span style={{ color: "#000" }}>
                                {selectedTeams.join(", ")}
                              </span>
                            )}
                          </div>
                        ) : (
                          <span style={{ color: "#000" }}>
                            {selectedTeams.join(", ")}
                          </span>
                        )}
                        <DownIcon />
                      </button>
                      <div
                        className={`checklist-list ${
                          showChecklistList ? "" : "hide d-none"
                        }`}
                      >
                        <form className="dropdown-search ps-0">
                          <button disabled>
                            <SearchIcon />
                          </button>
                          <input
                            onChange={(e) =>
                              setSelectLabourCostSearch(e.target.value)
                            }
                            type="text"
                            placeholder="Search"
                          />
                        </form>
                        <ul className="dropdown-item-content mt-2">
                          {filteredTeam?.map((item, index) => (
                            <li key={index}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id={item.teamName}
                                  checked={selectedTeams.includes(
                                    item.teamName
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange(item.teamName)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  style={{ fontSize: "14px" }}
                                  htmlFor={item.teamName}
                                >
                                  {item.teamName}
                                </label>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <div className="row row-gap mt-3">
                  <div className="col-md-6">
                    <label className="fw-medium pb-2 fs-14">
                      RFQ/ Diagnosis deadline in hours
                    </label>
                    <input
                      className="modal-input-box"
                      type="number"
                      min={0}
                      input={extendingTime}
                      onChange={(e) => {
                        setExtendTime(e.target.value);
                      }}
                      style={{
                        background: "#F1EFEF",
                        width: "100%",
                        border: "0",
                        height: "50px",
                        borderRadius: "5px",
                        padding: "0 15px",
                      }}
                      placeholder="Enter the quote deadline in hours"
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="fw-medium pb-2 fs-14">
                      Block RFQ/ Diagnosis submission after deadline
                    </label>
                    <Form>
                      <div className="row check-icons-modal">
                        <div className="col-5">
                          <Form.Check
                            inline
                            checked
                            label="Yes"
                            name="group1"
                            type="radio"
                            id="inline-radio-3"
                          />
                        </div>
                        <div className="col-6">
                          <Form.Check
                            inline
                            label="No"
                            name="group1"
                            type="radio"
                            id="inline-radio-4"
                          />
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            {isLoading ? (
              <Spinner
                animation="border"
                style={{
                  color: "#D57D2A",
                }}
              />
            ) : (
              <button
                className="delate-btn"
                onClick={() => handleInvite(activeKey)}
              >
                Invite
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RequestQuotesDiagnosisModal;
