import React, { useEffect, useRef, useState, useContext } from "react";
import logo from "../../../Assets/Images/logo.png";
import { Table } from "react-bootstrap";
import { DiagnosisDetailsContext } from "../../../Context/Inspections/WorkOrderContext";

const PDFDocument = (props) => {
  const componentRef = useRef();
  const [company, setCompany] = useState({});
  const { diagnosisSummary, setDiagnosisSummary } = useContext(DiagnosisDetailsContext);
  useEffect(() => {
    setCompany(JSON.parse(localStorage.getItem("company")));
  }, []);
  return (
    <>
      <div className="pad-overflow">
        <div className="quotes-pad" id="quotes-pad" ref={componentRef}>
          <div className="auto-gap">
            <div className="pad-header d-flex flex-wrap justify-content-between align-items-center">
              <div className="pad-logo">
                <img src={logo} alt={logo} />
              </div>
              <address className="fs-13 text-black lh-base text-end">
                <div className="fs-24 text-black text-uppercase fw-bold">
                  quote
                </div>
                {diagnosisSummary?.requestQuoteSummary?.quotingTeamName} <br />
                  {diagnosisSummary?.requestQuoteSummary?.quotingTeamPhoneNumber} <br />
                  {diagnosisSummary?.requestQuoteSummary?.quotingTeamEmail} <br />
                  {diagnosisSummary?.requestQuoteSummary?.quotingTeamAddress1} {diagnosisSummary?.requestQuoteSummary?.quotingTeamAddress2}
              </address>
            </div>
            <hr />
            <div className="quotes-total d-flex flex-wrap justify-content-between align-items-center">
              <div className="quotes-name d-flex flex-wrap gap-4 align-items-center">
                <div className="lh-sm">
                    <div className="fs-13 fw-medium">{diagnosisSummary?.requestQuoteSummary?.quoteRequestorName}</div>
                    <div className="fs-11">{diagnosisSummary?.requestQuoteSummary?.quoteRequestorAddress1} {diagnosisSummary?.requestQuoteSummary?.quoteRequestorAddress2}</div>
                    <div className="fs-12">{diagnosisSummary?.requestQuoteSummary?.quoteRequestorStreet} {diagnosisSummary?.requestQuoteSummary?.quoteRequestorCity} {diagnosisSummary?.requestQuoteSummary?.quoteRequestorCountry}</div>
                </div>
                <div className="lh-sm">
                  <div className="fs-13 fw-medium">
                    Quotation - {diagnosisSummary?.requestQuoteSummary?.quoteRef}
                  </div>
                  <div className="fs-12">
                    {new Date(
                        diagnosisSummary?.requestQuoteSummary?.rfqCreateDate
                      ).toLocaleDateString("en-Gb", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                    })}{" "}
                  </div>
                </div>
              </div>
              <div
                className="total-price fs-20 fw-medium"
                style={{ color: "#D57D2A" }}
              >
                <b className="pe-4">Total</b> {company?.currency && diagnosisSummary?.requestQuoteSummary?.total ? company.currency + " " + diagnosisSummary?.requestQuoteSummary?.total.toLocaleString() : 'N/A'}
              </div>
            </div>
            <hr />
            <Table responsive style={{ background: "none" }}>
              <thead>
                <tr>
                  <th>Item Description</th>
                  <th>Qty</th>
                  <th>Amount</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {diagnosisSummary?.requestQuoteSummary?.quoteItems?.map((item) => (
                    <tr>
                      <td>{item.description}</td>
                      <td>{item.quantity}</td>
                      <td>{company.currency + " " + item.unitCost.toLocaleString()}</td>
                      <td>{company.currency + " " + item.totalCost.toLocaleString()}</td>
                    </tr>
                ))}
              </tbody>
            </Table>
            <hr />
            <div className="nots-pard">
              <div className="">
                <div className="fs-11">Notes</div>
                <div className="fs-10 pt-2">{diagnosisSummary?.requestQuoteSummary?.notes == null ? "No extra notes" : diagnosisSummary?.requestQuoteSummary?.notes}</div>
              </div>
              <ul>
                {/* <li>
                  <span>SUb Total</span>
                  <span>
                    {company?.currency} {props?.quote?.total}
                  </span>
                </li>
                <li>
                  <span>Tax</span>
                  <span>
                    {company.currency} {props?.quote?.total}
                  </span>
                </li> */}
                <li>
                  <span>Grand Total</span>
                  <span>
                    {company?.currency && diagnosisSummary?.requestQuoteSummary?.total ? company.currency + " " + diagnosisSummary?.requestQuoteSummary?.total.toLocaleString() : 'N/A'}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="quotes-footer">
            <span>Prepared By: {diagnosisSummary?.requestQuoteSummary?.quotingUserName}</span>
            {/*<span>{props?.quote?.quotingTeamPhoneNumber}</span>
            <span>{props?.quote?.quotingTeamEmail}</span>*/}
          </div>
        </div>
      </div>
    </>
  );
};
export default PDFDocument;
