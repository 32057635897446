import React, { useState } from "react";
import { Accordion, Nav, Tab } from "react-bootstrap";
import EyesIcon from "../../../Assets/Icons/EyesIcon";
import DatePicker from "react-datepicker";
import DownIcon from "../../../Assets/Icons/DownIcon";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import { Link } from "react-router-dom";
import searchAllProperty from "../../../utls/searchAllProperty";
import ArrowLeft from "../../../Assets/Icons/ArrowLeft";
import ArrowRight from "../../../Assets/Icons/ArrowRight";
import DropdownIcon from "../../../Assets/Icons/DropdownIcon";
import { useParams } from "react-router-dom";
import usefetchData from "../../../hooks/useFetchData";

const ViewWork = () => {
  const [correctiveStartDate, setCorrectiveStartDate] = useState(null);
  const [correctiveEndDate, setCorrectiveEndDate] = useState(null);
  const [preventiveStartDate, setPreventiveStartDate] = useState(null);
  const [preventiveEndDate, setPreventiveEndDate] = useState(null);
  const [correctiveSearch, setCorrectiveSearch] = useState("");
  const [preventiveSearch, setPreventiveSearch] = useState("");
  const [currentPageCorrecive, setCurrentPageCorrective] = useState(1); //for pagination
  const [RequestsPerPageCorrective, setRequestsPerPageCorrective] =
    useState(10); //for pagination
  const [currentPagePreventive, setCurrentPagePreventive] = useState(1); //for pagination
  const [RequestsPerPagePreventive, setRequestsPerPagePreventive] =
    useState(10); //for pagination


     // get id from params
  const {id} = useParams()

  const { data: workData, isLoading: isLoadingWorkC } = usefetchData(
    ["corrective-work", parseInt(id)],
    `Locations/GetAllWorkByLocationQuery`,
    {
      params: {
        locationId: parseInt(id),
        ticketType: "corrective"
      }
    },
    "Error fetching locationwork data by id",
    true
  );
  const { data: partsData, isLoading: isLoadingWorkP } = usefetchData(
    ["preventive-work", parseInt(id)],
    `Locations/GetAllWorkByLocationQuery`,
    {
      params: {
        locationId: parseInt(id),
        ticketType: "preventive"
      }
    },
    "Error fetching locationwork data by id",
    true
  );

console.log(workData,"no data ")
    
  const ExampleCustomInput = ({ value, onClick }) => (
    <button className="example-custom-input" onClick={onClick}>
      {value ? value : ""} <DownIcon />
    </button>
  );

  //filter via start date if date is not null {Corrective}
  const filteredByStartDate = correctiveStartDate
    ? partsData.filter((item) => {
        const date = new Date(item.Date);
        return date.getTime() >= correctiveStartDate.getTime();
      })
    : partsData;

  //filter via end date if date is not null {Corrective}
  const filteredByEndDate = correctiveEndDate
    ? filteredByStartDate.filter((item) => {
        const date = new Date(item.Date);
        return date.getTime() <= correctiveEndDate.getTime();
      })
    : filteredByStartDate;
  //Filter by search term {Corrective}
  const filteredBySearchCorrective = searchAllProperty(
    filteredByEndDate,
    correctiveSearch
  );

  //filter via start date if date is not null {Preventive}
  const filteredByStartDatePreventive = preventiveStartDate
    ? partsData.filter((item) => {
        const date = new Date(item.Date);
        return date.getTime() >= preventiveStartDate.getTime();
      })
    : partsData;

  //filter via end date if date is not null {Preventive}
  const filteredByEndDatePreventive = preventiveEndDate
    ? filteredByStartDatePreventive.filter((item) => {
        const date = new Date(item.Date);
        return date.getTime() <= preventiveEndDate.getTime();
      })
    : filteredByStartDatePreventive;

  //Filter by search term {Preventive}'
  const filteredBySearchPreventive = searchAllProperty(
    filteredByEndDatePreventive,
    preventiveSearch
  );

  // pagination for corrective work
  const indexOfLastRequestsCorrective =
    currentPageCorrecive * RequestsPerPageCorrective;
  const indexOfFirstRequestsCorrective =
    indexOfLastRequestsCorrective - RequestsPerPageCorrective;
  // const currentRequestsCorrective = filteredBySearchCorrective?.slice(
  //   indexOfFirstRequestsCorrective,
  //   indexOfLastRequestsCorrective
  // );

  // Calculate total pages
  const totalPagesCorrective = Math.ceil(
    filteredBySearchCorrective?.length / RequestsPerPageCorrective
  );

  // Update current page
  const handlePageChangeCorrective = (pageNumber) => {
    setCurrentPageCorrective(pageNumber);
  };

  // pagination for preventive work
  const indexOfLastRequestsPreventive =
    currentPagePreventive * RequestsPerPagePreventive;
  const indexOfFirstRequestsPreventive =
    indexOfLastRequestsPreventive - RequestsPerPagePreventive;
  // const currentRequestsPreventive = filteredBySearchPreventive?.slice(
  //   indexOfFirstRequestsPreventive,
  //   indexOfLastRequestsPreventive
  // );

  // Calculate total pages
  const totalPagesPreventive = Math.ceil(
    filteredBySearchPreventive?.length / RequestsPerPagePreventive
  );

  // Update current page
  const handlePageChangePreventive = (pageNumber) => {
    setCurrentPagePreventive(pageNumber);
  };

  return (
    <>
      <div className="order-request washroom-right-content responses-cont">
        <div className="washroom-title">
          <div className="fs-13 fw-medium">LOCATION WORK</div>
        </div>
        <div className="order-request mt-4 p-0">
          <Accordion defaultActiveKey="1">
            {/* PARTS INFORMATION */}
            <Accordion.Item eventKey="1" className="request-box">
              <Accordion.Header className="request-box-title">
                <div className="fs-13 fw-medium">WORK</div>
              </Accordion.Header>
              <Accordion.Body className="request-box-body p-0">
                <Tab.Container defaultActiveKey="second">
                  <Nav className="table__names" variant="pills">
                    <Nav.Item>
                      <Nav.Link eventKey="second">Corrective Work</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Preventive Work</Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content>
                    <Tab.Pane eventKey="second">
                      <div className="view-work-search">
                        <div className="select-dropdown">
                          <div className="select-title">
                            <div
                              className="fs-13"
                              style={{ color: "rgba(88, 69, 57, 0.50)" }}
                            >
                              Date From:
                            </div>
                            <div className="format-date">
                              <DatePicker
                                selected={correctiveStartDate}
                                onChange={(date) =>
                                  setCorrectiveStartDate(date)
                                }
                                customInput={<ExampleCustomInput />}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="select-dropdown">
                          <div className="select-title">
                            <div
                              className="fs-13"
                              style={{ color: "rgba(88, 69, 57, 0.50)" }}
                            >
                              Date To:
                            </div>
                            <div className="format-date">
                              <DatePicker
                                selected={correctiveEndDate}
                                onChange={(date) => setCorrectiveEndDate(date)}
                                customInput={<ExampleCustomInput />}
                              />
                            </div>
                          </div>
                        </div>
                        <form action="" className="search__box">
                          <button disabled type="submit">
                            <SearchIcon />
                          </button>
                          <input
                            onChange={(e) =>
                              setCorrectiveSearch(e.target.value)
                            }
                            type="text"
                            placeholder="Search"
                            value={correctiveSearch}
                          />
                        </form>
                      </div>
                      <div
                        className="table-responsive-sm request_table"
                        style={{ maxWidth: "100%" }}
                      >
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Ref</th>
                              <th scope="col">Asset</th>
                              <th scope="col">Work done by</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {workData?.data?.length > 0 && workData?.data?.map((item,i) => (
                              <tr key={i}>
                                <td>
                                  <div
                                    style={{ color: "#000", fontSize: "15px" }}
                                  >
                                    {item.ticketRef}
                                  </div>
                                  <div
                                    style={{
                                      color: "#6B788E",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Date: {item.Date}
                                  </div>
                                </td>
                                <td>
                                  <div
                                    style={{ color: "#000", fontSize: "15px" }}
                                  >
                                    {item.asset}
                                  </div>
                                  <div
                                    style={{
                                      color: "#6B788E",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Issue: {item.lssue}
                                  </div>
                                </td>
                                <td>
                                  <div
                                    style={{ color: "#000", fontSize: "15px" }}
                                  >
                                    {item.workDone}
                                  </div>
                                  <div
                                    style={{
                                      color: "#6B788E",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Worker: {item.worker}
                                  </div>
                                </td>
                                <td>
                                  <div className="button-con">
                                    <Link
                                      to="/work-orders/work-view"
                                      style={{ color: "#6C5B51" }}
                                    >
                                      <EyesIcon />
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="table-pagination pagination-center mt-0">
                        <div className="pagination-row">
                          Rows per page:
                          <div className="dropdown select-dropdown">
                            <button
                              className="select-title"
                              data-bs-toggle="dropdown"
                            >
                              <span className="fs-14 d-flex align-items-center gap-2 text-black">
                                {RequestsPerPageCorrective} <DropdownIcon />
                              </span>
                            </button>
                            <ul
                              className="dropdown-menu select-menu"
                              style={{ minWidth: "25px" }}
                            >
                              <li
                                onClick={() => setRequestsPerPageCorrective(5)}
                              >
                                5
                              </li>
                              <li
                                onClick={() => setRequestsPerPageCorrective(10)}
                              >
                                10
                              </li>
                              <li
                                onClick={() => setRequestsPerPageCorrective(15)}
                              >
                                15
                              </li>
                              <li
                                onClick={() => setRequestsPerPageCorrective(20)}
                              >
                                20
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="pagination-number">
                          <div className="fs-14">
                            {currentPageCorrecive} of {totalPagesCorrective}
                          </div>
                          <div className="arrow-btn">
                            <button
                              onClick={() =>
                                currentPageCorrecive !== 1 &&
                                handlePageChangeCorrective(
                                  currentPageCorrecive - 1
                                )
                              }
                            >
                              <ArrowLeft />
                            </button>
                            <button
                              onClick={() =>
                                totalPagesCorrective !== currentPageCorrecive &&
                                handlePageChangeCorrective(
                                  currentPageCorrecive + 1
                                )
                              }
                            >
                              <ArrowRight />
                            </button>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <div className="view-work-search">
                        <div className="select-dropdown">
                          <div className="select-title">
                            <div className="fs-13">Date From:</div>
                            <div className="format-date">
                              <DatePicker
                                selected={preventiveStartDate}
                                onChange={(date) =>
                                  setPreventiveStartDate(date)
                                }
                                customInput={<ExampleCustomInput />}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="select-dropdown">
                          <div className="select-title">
                            <div className="fs-13">Date To:</div>
                            <div className="format-date">
                              <DatePicker
                                selected={preventiveEndDate}
                                onChange={(date) => setPreventiveEndDate(date)}
                                customInput={<ExampleCustomInput />}
                              />
                            </div>
                          </div>
                        </div>
                        <form action="" className="search__box">
                          <button disabled type="submit">
                            <SearchIcon />
                          </button>
                          <input
                            onChange={(e) =>
                              setPreventiveSearch(e.target.value)
                            }
                            type="text"
                            placeholder="Search"
                            value={preventiveSearch}
                          />
                        </form>
                      </div>
                      <div
                        className="table-responsive-sm request_table"
                        style={{ maxWidth: "100%" }}
                      >
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Ref</th>
                              <th scope="col">Asset</th>
                              <th scope="col">Work done by</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {partsData?.data?.length > 0 && partsData?.data?.map((item,i) => (
                              <tr key={i}>
                                <td>
                                  <div
                                    style={{ color: "#000", fontSize: "15px" }}
                                  >
                                    {item.ref}
                                  </div>
                                  <div
                                    style={{
                                      color: "#6B788E",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Date: {item.Date}
                                  </div>
                                </td>
                                <td>
                                  <div
                                    style={{ color: "#000", fontSize: "15px" }}
                                  >
                                    {item.asset}
                                  </div>
                                  <div
                                    style={{
                                      color: "#6B788E",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Issue: {item.lssue}
                                  </div>
                                </td>
                                <td>
                                  <div
                                    style={{ color: "#000", fontSize: "15px" }}
                                  >
                                    {item.workDone}
                                  </div>
                                  <div
                                    style={{
                                      color: "#6B788E",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Worker: {item.worker}
                                  </div>
                                </td>
                                <td>
                                  <div className="button-con">
                                    <Link
                                      to="/work-orders/work-view"
                                      style={{ color: "#6C5B51" }}
                                    >
                                      <EyesIcon />
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="table-pagination pagination-center mt-0">
                        <div className="pagination-row">
                          Rows per page:
                          <div className="dropdown select-dropdown">
                            <button
                              className="select-title"
                              data-bs-toggle="dropdown"
                            >
                              <span className="fs-14 d-flex align-items-center gap-2 text-black">
                                {RequestsPerPagePreventive} <DropdownIcon />
                              </span>
                            </button>
                            <ul
                              className="dropdown-menu select-menu"
                              style={{ minWidth: "25px" }}
                            >
                              <li
                                onClick={() => setRequestsPerPagePreventive(5)}
                              >
                                5
                              </li>
                              <li
                                onClick={() => setRequestsPerPagePreventive(10)}
                              >
                                10
                              </li>
                              <li
                                onClick={() => setRequestsPerPagePreventive(15)}
                              >
                                15
                              </li>
                              <li
                                onClick={() => setRequestsPerPagePreventive(20)}
                              >
                                20
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="pagination-number">
                          <div className="fs-14">
                            {currentPagePreventive} of {totalPagesPreventive}
                          </div>
                          <div className="arrow-btn">
                            <button
                              onClick={() =>
                                currentPagePreventive !== 1 &&
                                handlePageChangePreventive(
                                  currentPagePreventive - 1
                                )
                              }
                            >
                              <ArrowLeft />
                            </button>
                            <button
                              onClick={() =>
                                totalPagesPreventive !==
                                  currentPagePreventive &&
                                handlePageChangePreventive(
                                  currentPagePreventive + 1
                                )
                              }
                            >
                              <ArrowRight />
                            </button>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
};



export default ViewWork;
