import React,{useEffect,useState,useContext} from "react";
import "../Components/PumpCalibration/PumpCalibration.scss";
import F3WashroomHeader from "../Components/InspectionCategory/F3Washrooms/F3WashroomHeader";
import F3WashroomSidebar from "../Components/InspectionCategory/F3Washrooms/F3WashroomSidebar";
import F3WashroomsContent from "../Components/InspectionCategory/F3Washrooms/F3WashroomsContent";
import F3WashroomNavbar from "../Components/InspectionCategory/F3Washrooms/F3WashroomNavbar";
import { Route, useLocation, useParams } from "react-router-dom";
import RoutineInspectionCategoryContext from "../Context/Inspections/RoutineInspectionCategoryContext";
import InspectionDetailContext from "../Context/Inspections/InspectionDetailsContext";
import { GET_INSPECTIONS_BY_CATEGORY_ID, GET_INSPECTION_CATEGORY_BY_ID } from "../utls/constants";
import { get } from "../Services/ApiHelper";
const F3Washroom = () => {
  const location = useLocation();
  const [data, setData] = useState({});
  const [Id, setId] = useState(0);
  const [Category, setCategory] = useState({});
  const [ allResponses, setAllResponses] = useState(null);
  const {id} = useParams();
  

  // useEffect(() => {
  //   // Update state when location state changes
  //   setData(location.state?.data || null);
  //   setId(location.state?.data?.id || 0);
  // }, [location.state]);

  const {inspectionDetailData, categoryId} = useContext(RoutineInspectionCategoryContext);
  useEffect(() => {
    const token = localStorage.getItem("bearerToken");
    get(GET_INSPECTION_CATEGORY_BY_ID(id),token).then((data) => {
      var result = data["data"];
      setCategory(result);
      setAllResponses(data)
    });
  }, [id]);


  return (
    <div className="work-orders-area f3washroom-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <F3WashroomHeader />
          </div>
          <div className="col-lg-12">
            <F3WashroomNavbar />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="order-request-area">
              <F3WashroomSidebar category = {Category} />
              <F3WashroomsContent ScheduleId = {id} category={Category} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default F3Washroom;
