import React, { useContext } from "react";
import { WorkOrderContext } from "../../../../Context/Inspections/WorkOrderContext";

const ViewResponsesOne = ({checkListData, workOrderAssetId, currentPage}) => {
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);
  const workOrderAsset = ticketSummary.assetSummary.workOrderAssetList.find(
    (asset) => asset.id === workOrderAssetId
  );
  const ticketSummaryCheckList = workOrderAsset.checkLists.find(
    (checkList) => checkList.checkListTransactionId === checkListData.checkListTransactionId
  );
  //const section = checkListData.checkListSections[currentPage - 1];
  const section = ticketSummaryCheckList.checkListSections[currentPage - 1];
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <div
          className="fs-13 fw-bold text-uppercase"
          style={{ color: "#D57D2A" }}
        >
          {section?.sectionName}
        </div>
      </div>
      <ul className="different-data ">
        {section?.questions?.map((item) => (
          <li key={item.checkListSectionTaskId}>
            <div className="fs-15 text-black">{item.questionName}</div>
            <div className="fs-15" style={{color: "#71727A"}}>
              <b>Response:</b> {item.questionAnswer}
            </div>
            <div className="fs-15" style={{color: "#71727A"}}>
              <b>Comment:</b> {item.questionComment}
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default ViewResponsesOne;
