import React from "react";
import { Link } from "react-router-dom";

const ResponsesNavbar = ({categoryId}) => {
  
  return (
    <div className="other-nav">
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to={`/inspection-category/inspections/${categoryId}`}>
          Continuous  Check
          </Link>
        </li>
        <li>
          <Link> Responses</Link>
        </li>
      </ul>
    </div>
  );
};

export default ResponsesNavbar;
