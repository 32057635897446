import React from "react"; 
 const WashroomHeader = () => { 
  return (
    <>
      <div className="work-header">
        <div className="fs-20">Inspection Category Details</div>
        
      </div>
     </>
  );
};

export default WashroomHeader;
