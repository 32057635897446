import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
// import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";
import { get, post } from "../../../Services/ApiHelper";
import {
  ADD_TICKET_OTHER_COSTS,
  GET_TICKET_OTHER_COST_BY_TICKET_ID,
} from "../../../utls/constants";
import Astericks from "../../Common/Asterick";
import { toast } from "react-toastify";

const SummaryAddOtherCostsModal = (props) => {
  const [selectCostCat, setSelectCat] = useState("Select cost category");
  const [selectCostCatId, setSelectCatId] = useState(0);
  const [description, setDescription] = useState("");
  const [costCategoryData, setCostCategoryData] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [unitCost, setUnitCost] = useState(0);
  const token = localStorage.getItem("bearerToken");
  const [isLoading, setIsLoading] = useState(false);
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);

  const ticketId = ticketSummary?.workOrderSummary?.ticketId;

  //demo data

  useEffect(() => {
    get(GET_TICKET_OTHER_COST_BY_TICKET_ID, token)
      .then((response) => setCostCategoryData(response))
      .catch((error) => console.log(error));
    setSelectCat("Select cost category");
    setSelectCatId(0);
    setDescription("");
    setQuantity(0);
    setUnitCost(0);
  }, [props.show]);

  //get search input
  const [costCategorySearch, setCostCategorySearch] = useState("");

  const filterCostCategoryData = costCategoryData.filter((item) =>
    item?.ticketCostItemTypeName
      ?.toLowerCase()
      .includes(costCategorySearch.toLowerCase())
  );

  //handle select

  const handleSelectWorker = (eventKey) => {
    const selectedCostCategory = costCategoryData.find(
      (category) => category.ticketCostItemTypeName === eventKey
    );

    if (selectedCostCategory) {
      setSelectCat(eventKey);
      setSelectCatId(selectedCostCategory.id);
    }

    setCostCategorySearch("");
  };

  const [catError, setCatError] = useState(null);
  const [desError, setDesError] = useState(null);
  const [quantError, setQuantError] = useState(null);
  const [unitError, setUnitError] = useState(null);

  const validateInputs = () => {
    let isValid = true;

    setCatError(null);
    setDesError(null);
    setQuantError(null);
    setUnitError(null);

    if (selectCostCat === "Select cost category") {
      setCatError("Required *");
      isValid = false;
    }

    if (!description) {
      setDesError("Required *");
      isValid = false;
    }

    if (!quantity) {
      setQuantError("Required *");
      isValid = false;
    }

    if (!unitCost) {
      setUnitError("Required *");
      isValid = false;
    }

    return isValid;
  };

  const onSubmit = () => {
    const isValid = validateInputs();

    if (!isValid) {
      return;
    }

    setIsLoading(true);
    const payload = {
      ticketId: ticketId,
      description: description,
      costCategoryId: selectCostCatId,
      quantity: quantity,
      unitCost: unitCost,
    };

    post(ADD_TICKET_OTHER_COSTS, payload, token)
      .then((response) => {
        if (response.succeeded === true) {
          setIsLoading(false);
          props.onHide();
          setSelectCat("Select cost category");
          setSelectCatId(0);
          setDescription("");
          setQuantity(0);
          setUnitCost(0);
          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });

          setTicketSummary((prevTicketSummary) => {
            const updatedTicketSummary = { ...prevTicketSummary };
            updatedTicketSummary.workOrderSummary.ticketOtherCostList.push({
              id: response.data,
              costItemTypeId: selectCostCatId,
              costItemTypeName: selectCostCat,
              description: description,
              quantity: quantity,
              ticketId: ticketId,
              unitCost: unitCost,
            });
            return updatedTicketSummary;
          });
        } else {
          setIsLoading(false);
          props.onHide();
          setSelectCat("Select cost category");
          setSelectCatId(0);
          setDescription("");
          setQuantity(0);
          setUnitCost(0);
          toast.error(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        props.onHide();
        setSelectCat("Select cost category");
        setSelectCatId(0);
        setDescription("");
        setQuantity(0);
        setUnitCost(0);
        toast.error("An error occured", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Add Other Costs</span>
            <span className="fs-14" style={{ color: "#72777A" }}>
              Provide any extra costs incurred in doing the work.
            </span>
          </div>
          <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">
                  Cost Description
                  <Astericks />
                </label>
                {desError && <p style={{ color: "red" }}>{desError}</p>}
              </div>
              <input
                onChange={(e) => setDescription(e.target.value)}
                className="modal-input-box"
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "0 15px",
                }}
                placeholder="Enter cost description"
              />
            </div>
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">
                  Cost Category
                  <Astericks />
                </label>
                {catError && <p style={{ color: "red" }}>{catError}</p>}
              </div>
              <Dropdown className="select__form" onSelect={handleSelectWorker}>
                <Dropdown.Toggle
                  className={`select-title ${
                    selectCostCat !== "Select cost category" ? "selected" : ""
                  }`}
                  style={{ height: "50px" }}
                >
                  {selectCostCat}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      onChange={(e) =>
                        setCostCategorySearch(e.target.value, 10)
                      }
                      value={costCategorySearch}
                      type="text"
                      placeholder="Search"
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filterCostCategoryData.map((item, index) => (
                      <Dropdown.Item
                        key={index}
                        eventKey={item.ticketCostItemTypeName}
                      >
                        {item.ticketCostItemTypeName}
                      </Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">
                  Quantity
                  <Astericks />
                </label>
                {quantError && <p style={{ color: "red" }}>{quantError}</p>}
              </div>
              <input
                onChange={(e) => setQuantity(parseInt(e.target.value, 10))}
                className="modal-input-box"
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "0 15px",
                }}
                placeholder="Enter quantity"
              />
            </div>
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">
                  Unit Cost (KES)
                  <Astericks />
                </label>
                {unitError && <p style={{ color: "red" }}>{unitError}</p>}
              </div>
              <input
                onChange={(e) => setUnitCost(parseInt(e.target.value, 10))}
                className="modal-input-box"
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "0 15px",
                }}
                placeholder="Enter unit cost"
              />
            </div>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            {isLoading ? (
              <Spinner
                animation="border"
                style={{
                  color: "#D57D2A",
                }}
              />
            ) : (
              <Link to="" className="delate-btn" onClick={onSubmit}>
                Add
              </Link>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SummaryAddOtherCostsModal;
