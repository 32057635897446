import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DownIcon from "../../../Assets/Icons/DownIcon";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import usefetchData from "../../../hooks/useFetchData";
import {
  setAssetCategory,
  setLocation,
  addSelectedAssets,
  addAssetListId,
  removeAssetListId,
  removeSelectedAsset,
} from "../../../redux/formSlice";
import {
  GET_ASSET_BY_LOCATIO_AND_ASSET_CATEGORY,
  GET_ASSET_CATEGORIES,
  GET_SIMPLE_LOCATION_LIST,
} from "../../../utls/constants";

const WorkOrderAssets = () => {
  const dispatch = useDispatch();
  const formState = useSelector((state) => state.form);

  const [selectValue, setSelectValue] = useState({
    asset: "Select",
    location: "Select",
    assetCategory: "Select",
    assignAdditionalTeam: "Select",
  });

  const [selectedLocation, setSelectedLocation] = useState(
    formState.location || "Select"
  );
  const [selectedLocationId, setSelectedLocationId] = useState(
    formState.locationId
  );
  const [selectedAssetCategory, setSelectedAssetCategory] = useState(
    formState.assetCategory || "Select"
  );
  const [selectedAssetCategoryId, setSelectedAssetCategoryId] = useState(
    formState.assetCategoryId
  );

  //location search input
  const [locationSearch, setLocationSearch] = useState("");
  //asset category search input
  const [assetSearch, setAssetSearch] = useState("");
  //asset search input
  const [assetCheckSearch, setAssetCheckSearch] = useState("");

  const routeState = {
    locationId: selectedLocationId,
    categoryofworkId: selectedAssetCategoryId,
  };

  const handleChecklistSelect = (event) => {
    console.log("asset", event?.target?.value);
    const assetId = parseInt(event.target.value);
    const assetName = event.target.id;
    if (event.target.checked) {
      dispatch(addAssetListId(assetId));
      dispatch(addSelectedAssets(assetName));
    } else {
      dispatch(removeAssetListId(assetId));
      dispatch(removeSelectedAsset(assetName));
    }
  };

  const handleLocationSelect = (eventKey, event) => {
    const location = locationData.find(
      (item) => item.locationName === eventKey
    );
    setSelectedLocation(location.locationName);
    setSelectedLocationId(location.id);
    setSelectValue({ ...selectValue, location: location.locationName });
    dispatch(
      setLocation({ location: location.locationName, locationId: location.id })
    );
    setLocationSearch("");
  };

  const handleAssetCategorySelect = (eventKey, event) => {
    const assetCategory = assetData.find(
      (item) => item.assetCategoryName === eventKey
    );
    setSelectedAssetCategory(assetCategory.assetCategoryName);
    setSelectedAssetCategoryId(assetCategory.id);
    setSelectValue({
      ...selectValue,
      assetCategory: assetCategory.assetCategoryName,
    });
    setAssetSearch("");
    dispatch(
      setAssetCategory({
        assetCategory: assetCategory.assetCategoryName,
        assetCategoryId: assetCategory.id,
      })
    );
    setAssetSearch("");
  };

  const { data: locationData } = usefetchData(
    ["location"],
    `${GET_SIMPLE_LOCATION_LIST}`,
    {},
    "Couldn't get location data. Please try again!",
    true
  );

  const { data: assetData } = usefetchData(
    ["assets"],
    `${GET_ASSET_CATEGORIES}`,
    {},
    "Couldn't get asset category data. Please try again!",
    true
  );

  const { data: assetCheckData } = usefetchData(
    ["asset-check", selectedLocationId, selectedAssetCategoryId],
    `${GET_ASSET_BY_LOCATIO_AND_ASSET_CATEGORY(
      selectedLocationId,
      selectedAssetCategoryId
    )}`,
    {},
    "Couldn't get asset data. Please try again!",
    !!selectedLocationId && !!selectedAssetCategoryId
  );

  // Filter location data
  const filteredLocationData =
    locationData &&
    locationData.filter((item) => {
      return item.locationName
        .toLowerCase()
        .includes(locationSearch.toLowerCase());
    });

  // Filter asset data
  const filteredAssetData =
    assetData &&
    assetData.filter((item) => {
      return item.assetCategoryName
        .toLowerCase()
        .includes(assetSearch.toLowerCase());
    });

  // Filter asset check data
  const filteredAssetCheckData =
    assetCheckData &&
    assetCheckData.filter((item) => {
      return item.assetName
        .toLowerCase()
        .includes(assetCheckSearch.toLowerCase());
    });

  // form validation
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!formState.location) newErrors.location = "Location is required.";
    if (!formState.assetCategory)
      newErrors.assetCategory = "Asset Category is required.";
    if (!formState.selectedAssets.length)
      newErrors.selectedAssets = "At least one asset must be selected.";
    return newErrors;
  };

  const handleNextClick = (e) => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      e.preventDefault();
      setErrors(validationErrors);
    }
  };

  return (
    <div className="order-details-content pb-lg-4">
      <div className="fs-16 fw-semibold">Assets</div>
      <div className="row details-forms-one pt-5">
        <div className="fs-15 fw-regular">
          Work Request Ref ID:{" "}
          <span className="fw-bold ps-4" style={{ color: "#867D77" }}>
            Not Available
          </span>
        </div>
        <div className="col-md-6">
          <label>Location</label>
          <Dropdown className="select__form" onSelect={handleLocationSelect}>
            <Dropdown.Toggle
              className={`select-title ${
                selectedLocation !== "Select" && formState.location !== ""
                  ? "selected"
                  : ""
              }`}
            >
              {console.log("formState", selectedLocation)}
              {formState.location === "" && selectedLocation === "Select"}
              {formState.location || "Select"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <form className="dropdown-search">
                <button disabled>
                  <SearchIcon />
                </button>
                <input
                  value={locationSearch}
                  onChange={(e) => setLocationSearch(e.target.value)}
                  type="text"
                  placeholder="Search"
                />
              </form>
              <div className="dropdown-item-content">
                {filteredLocationData &&
                  filteredLocationData.map((item, index) => (
                    <Dropdown.Item key={index} eventKey={item.locationName}>
                      {item.locationName}
                    </Dropdown.Item>
                  ))}
              </div>
            </Dropdown.Menu>
          </Dropdown>
          {errors.location && <div className="error">{errors.location}</div>}
        </div>
        <div className="col-lg-6 m-none"></div>
        <div className="col-md-6">
          <label>Asset Category</label>
          <Dropdown
            className="select__form"
            onSelect={handleAssetCategorySelect}
          >
            <Dropdown.Toggle
              className={`select-title ${
                selectedAssetCategory !== "Select" &&
                formState.assetCategory !== ""
                  ? "selected"
                  : ""
              }`}
            >
              {formState.assetCategory || "Select"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <form className="dropdown-search">
                <button disabled>
                  <SearchIcon />
                </button>
                <input
                  onChange={(e) => setAssetSearch(e.target.value)}
                  type="text"
                  placeholder="Search"
                  value={assetSearch}
                />
              </form>
              <div className="dropdown-item-content">
                {filteredAssetData &&
                  filteredAssetData.map((item, index) => (
                    <Dropdown.Item
                      key={index}
                      eventKey={item.assetCategoryName}
                    >
                      {item.assetCategoryName}
                    </Dropdown.Item>
                  ))}
              </div>
            </Dropdown.Menu>
          </Dropdown>
          {errors.assetCategory && (
            <div className="error">{errors.assetCategory}</div>
          )}
        </div>
        {formState.location !== "" && formState.assetCategory !== "" && (
          <div className="col-md-6">
            <label>Asset (s)</label>
            <div className="dropdown Checklists-dropdown assets-checklist select__form">
              <button className="btn checklists-btn" data-bs-toggle="dropdown">
                <div>
                  Select
                  {formState?.selectedAssets.length > 0 && (
                    <span style={{ color: "#000" }}>
                      {"  "}
                      {formState?.selectedAssets.join(", ")}
                    </span>
                  )}
                </div>
                <DownIcon />
              </button>
              <ul className="dropdown-menu">
                <form className="dropdown-search ps-0">
                  <button disabled>
                    <SearchIcon />
                  </button>
                  <input
                    onChange={(e) => setAssetCheckSearch(e.target.value)}
                    type="text"
                    placeholder="Search"
                    value={formState?.selectedAsset?.join(", ")}
                  />
                </form>
                <ul className="dropdown-item-content">
                  {filteredAssetCheckData &&
                    filteredAssetCheckData.map((item, index) => (
                      <li key={index}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={item.id}
                            id={item.assetName}
                            checked={formState.assetListIds.includes(item.id)}
                            onChange={handleChecklistSelect}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={item.assetName}
                          >
                            {item.assetName}
                          </label>
                        </div>
                      </li>
                    ))}
                </ul>
              </ul>
            </div>
            {errors.selectedAssets && (
              <div className="error">{errors.selectedAssets}</div>
            )}
          </div>
        )}
      </div>
      <div className="details-buttons pt-lg-5">
        <Link
          to="/work-orders/add-work-order/details"
          className="next-btn mt-lg-5"
          state={routeState}
          onClick={handleNextClick}
        >
          Next
        </Link>
      </div>
    </div>
  );
};

export default WorkOrderAssets;
