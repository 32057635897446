import React, { useState, useContext, useEffect, useRef } from "react";
import DeleteIcon from "../../../Assets/Icons/DeleteIcon";
import EditIcon2 from "../../../Assets/Icons/EditIcon2";
import { Accordion, Nav, Tab } from "react-bootstrap";
import ViewIcon2 from "../../../Assets/Icons/ViewIcon2";
import ArrowRight from "../../../Assets/Icons/ArrowRight";
import ArrowLeft from "../../../Assets/Icons/ArrowLeft";
import AddUsedPartsModal from "../../WorkOrders/Modal/AddUsedPartsModal";
import AddReturnedPartsModal from "../../WorkOrders/Modal/AddReturnedPartsModal";
import EditReturnedPartsModal from "../../WorkOrders/Modal/EditReturnedPartsModal";
import AddDiagnosisModal from "../../WorkOrders/Modal/AddDiagnosisModal";
import DiagnosisDetailsModal from "../../WorkOrders/Modal/DiagnosisDetailsModal";
import DeleteModal from "../../WorkOrders/Modal/DeleteModal";
import AddChecklistModal from "../../WorkOrders/Modal/AddChecklistModal";
import DownloadIcon from "../../../Assets/Icons/DownloadIcon";
import EditUsedPartsModal from "../../WorkOrders/Modal/EditUsedPartsModal";
import ViewChecklistModal from "../../WorkOrders/Modal/ViewChecklistModal";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";
import { useLocation } from "react-router-dom";
import { get, post } from "../../../Services/ApiHelper";
import {
  DELETE_TICKET_DIAGNOSIS,
  DELETE_TICKET_RETURNED_PARTS,
  DELETE_TICKET_USED_PARTS,
  GET_CHECKLIST_FORM_ANSWERS_BY_INSPECTION_ID,
} from "../../../utls/constants";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import ViewAssetsChecklist from "../../WorkOrders/ViewWorkOrder/ViewAssetsChecklist";

const ComponentToPrint = React.forwardRef((props, ref) => {
  return (
    <div ref={ref}>
    <style type="text/css" media="print">
    {"@page { margin: 20px 20px 20px 25px; }}"}
    </style>
    {props?.checkListData?.checkListSections?.map((section) => (
      <>
      <div className="d-flex align-items-center justify-content-between">
        <div
          className="fs-13 fw-bold text-uppercase"
          style={{ color: "#D57D2A" }}
        >
          {section?.sectionName}
        </div>
      </div>
      <ul className="different-data ">
        {section?.questions?.map((item) => (
          <li key={item.checkListSectionTaskId}>
            <div className="fs-15 text-black">{item.questionName}</div>
            <div className="fs-15" style={{color: "#71727A"}}>
              <b>Response:</b> {item.questionAnswer}
            </div>
            <div className="fs-15" style={{color: "#71727A"}}>
              <b>Comment:</b> {item.questionComment}
            </div>
          </li>
        ))}
      </ul>
      </>
    ))}
    </div>
  );
});

const ViewPmAssets = () => {
  const { ticketSummary, setTicketSummary, isLoading, setIsLoading } =
    useContext(WorkOrderContext);
  const [eventKey, setEventKey] = React.useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [addUsedPartsShow, setAddUsedPartsShow] = useState(false);
  const [addReturnedPartsShow, setAddReturnedPartsShow] = useState(false);
  const [editReturnedPartsShow, setEditReturnedPartsShow] = useState(false);
  const [addDiagnosisShow, setAddDiagnosisShow] = useState(false);
  const [diagnosisDetailsShow, setDiagnosisDetailsShow] = useState(false);
  const [delateModalShow, setDelateModalShow] = useState(false);
  const [checkListShow, setCheckListShow] = useState(false);
  const [viewCheckListShow, setViewCheckListShow] = useState(false);
  const [editUsedShow, setEditUsedShow] = useState(false);
  const [isBackActive, setIsBackActive] = useState(true);
  const [editData, setEditData] = useState({});
  const [editReturnData, setEditReturnData] = useState({});
  const [used, setUsed] = useState(false);
  const [returned, setReturned] = useState(false);
  const [diagnosis, setDiagnosis] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState(false);
  const [viewItem, setViewItem] = useState({});
  const [showChecklist, setShowChecklist] = useState(false);
  const [shouldPrint, setShouldPrint] = useState(false);
  const [checkListData, setCheckListData] = useState({});
  const [workOrderAssetId, setWorkOrderAssetId] = useState(0);
  const style = {
    maxWidth: "120px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };
  //get url by using useLocation
  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname ===
      `/work-orders/pmwork-view/${ticketSummary?.workOrderSummary?.ticketId}`
    ) {
      setEventKey("0");
    } else if (
      location.pathname ===
      `/work-orders/pmwork-view/${ticketSummary?.workOrderSummary?.ticketId}/assets`
    ) {
      setEventKey("1");
    } else if (
      location.pathname ===
      `/work-orders/pmwork-view/${ticketSummary?.workOrderSummary?.ticketId}/costings`
    ) {
      setEventKey("2");
    } else if (
      location.pathname ===
      `/work-orders/pmwork-view/${ticketSummary?.workOrderSummary?.ticketId}/job-cards`
    ) {
      setEventKey("3");
    }
  }, [location, ticketSummary]);

  const assetData = ticketSummary?.assetSummary?.workOrderAssetList;

  const totalSections = assetData?.length;
  const handleNextClick = () => {
    if (currentIndex < totalSections - 1) {
      setCurrentIndex((prevIndex) => Math.min(prevIndex + 1));
    }
  };
  const handleBackClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(() => Math.max(currentIndex - 1));
    }
  };
  const activateBackButton = () => {
    setIsBackActive(true);
  };

  const activateNextButton = () => {
    setIsBackActive(false);
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    if (shouldPrint && checkListData) {
      handlePrint();
      setShouldPrint(false); // Reset the flag
    }
  }, [shouldPrint, checkListData]);
  const handleDownloadClick = (item) => {
    setCheckListData(item);
    setShouldPrint(true);
  };

  const handleDelete = async () => {
    setIsLoading(false);
    try {
      const bearerToken = localStorage.getItem("bearerToken");

      const endPoint = used
        ? DELETE_TICKET_USED_PARTS
        : returned
        ? DELETE_TICKET_RETURNED_PARTS
        : diagnosis
        ? DELETE_TICKET_DIAGNOSIS
        : "";

      const data = { id: itemIdToDelete };
      await post(endPoint, data, bearerToken);

      const updatedAssetData = assetData.map((asset) => {
        if (asset.partsUsedList) {
          asset.partsUsedList = asset.partsUsedList.filter(
            (item) => item.id !== itemIdToDelete
          );
        }
        if (asset.partsReturnedList) {
          asset.partsReturnedList = asset.partsReturnedList.filter(
            (item) => item.id !== itemIdToDelete
          );
        }
        if (asset.assetDiagnosisList) {
          asset.assetDiagnosisList = asset.assetDiagnosisList.filter(
            (item) => item.id !== itemIdToDelete
          );
        }
        return asset;
      });

      setTicketSummary((prevState) => ({
        ...prevState,
        assetSummary: {
          ...prevState.assetSummary,
          workOrderAssetList: updatedAssetData,
        },
      }));

      toast.success("Data Deleted Successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setIsLoading(true);

      setTimeout(() => {
        setDelateModalShow(false);
        setIsLoading(false);
      }, 3000);
    } catch (error) {
      console.error("Error deleting item:", error);
      toast.error("An error occured", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      // setTimeout(() => {
      //   setRequestsShow(false)
      // }, 3000);
    }
  };

  const [inspectionId, setInspectionId] = useState(0);
  const [inspection, setInspection] = useState({});
  const toggleChecklist = () => {
    setShowChecklist(!showChecklist);
  };
  return (
    eventKey && (
      <>
        {!showChecklist && (
          <div className="order-request washroom-right-content responses-cont">
            <div className="washroom-title">
              <div className="fs-13 fw-medium">WORK ORDER ASSETS</div>
              <div className="fs-13 fw-bold">
                Status:{" "}
                <button className="primary-text fw-bold">
                  {ticketSummary?.workOrderSummary?.currentTicketActivity}
                </button>
              </div>
            </div>
            <div className="order-request mt-4 p-0">
              <div className="response-checklist view-asset-button">
                <div className="fs-15 fw-bold">
                  {assetData?.[currentIndex].assetName}
                </div>
                <div className="response-pagination d-flex align-items-center">
                  <div className="pagination-btn">
                    {currentIndex > 0 ? (
                      <button
                        className="back-btn"
                        style={{ color: "#D57D2A" }}
                        onClick={handleBackClick}
                      >
                        Back <ArrowLeft />
                      </button>
                    ) : (
                      <button
                        className="back-btn"
                        onClick={handleBackClick}
                        disabled={true}
                      >
                        Back <ArrowLeft />
                      </button>
                    )}
                    {currentIndex < totalSections - 1 ? (
                      <button
                        className="next-btn"
                        style={{ color: "#D57D2A" }}
                        onClick={handleNextClick}
                      >
                        <ArrowRight /> Next
                      </button>
                    ) : (
                      <button
                        className="next-btn"
                        onClick={handleNextClick}
                        disabled={true}
                      >
                        <ArrowRight /> Next
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <Accordion defaultActiveKey="1">
                {/* PARTS INFORMATION */}
                <Accordion.Item eventKey="1" className="request-box">
                  <Accordion.Header className="request-box-title">
                    <div className="fs-13 fw-medium">
                      ASSET CHECKLIST INFORMATION
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className="request-box-body">
                    <div
                      className="table-responsive-sm request_table"
                      style={{ maxWidth: "100%" }}
                    >
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Filled Asset Checklists</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {assetData?.[currentIndex]?.checkLists?.map(
                            (item) => (
                              <tr key={item.checkListTransactionId}>
                                <td>{item.checkListName}</td>
                                <td></td>
                                <td>
                                  <div className="button-con">
                                    <button onClick={() =>{setCheckListData(item);setWorkOrderAssetId(assetData?.[currentIndex].id);toggleChecklist();}}>
                                      <ViewIcon2 />
                                    </button>
                                    <button onClick={() => handleDownloadClick(item)}>
                                      <DownloadIcon />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="text-end">
                      {/* <button
                      className="add-button my-3"
                      onClick={() => setCheckListShow(true)}
                    >
                      Add Checklist
                    </button> */}
                      {/*<AddChecklistModal
                        ticketAssetId={assetData?.[currentIndex].id}
                        index={currentIndex}
                        show={checkListShow}
                        onHide={() => {
                          setCheckListShow(false);
                        }}
                      />*/}
                      <ViewChecklistModal
                        inspectionId={inspectionId}
                        show={viewCheckListShow}
                        onHide={() => setViewCheckListShow(false)}
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                {/* CHECKLIST INFORMATION */}
                <Accordion.Item eventKey="2" className="request-box">
                  <Accordion.Header className="request-box-title">
                    <div className="fs-13 fw-medium">PARTS INFORMATION</div>
                  </Accordion.Header>
                  <Accordion.Body className="request-box-body p-0">
                    <Tab.Container defaultActiveKey="second">
                      <Nav className="table__names" variant="pills">
                        <Nav.Item>
                          <Nav.Link eventKey="second">Used Parts</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="third">Returned parts</Nav.Link>
                        </Nav.Item>
                      </Nav>

                      <Tab.Content>
                        <Tab.Pane eventKey="second">
                          <div
                            className="table-responsive-sm request_table"
                            style={{ maxWidth: "100%" }}
                          >
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Parts</th>
                                  <th scope="col">Location</th>
                                  <th scope="col">Quantity</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {assetData?.[currentIndex].partsUsedList.map(
                                  (item) => (
                                    <tr key={item.id}>
                                      <td>
                                        {item.partSerialNummber == null
                                          ? item.partName
                                          : item.partName -
                                            item.partSerialNummber}
                                      </td>
                                      <td>{item.locationName}</td>
                                      <td>{item.quantityUsed}</td>
                                      <td>
                                        <div className="button-con">
                                          <button
                                            onClick={() => {
                                              setEditData(item);
                                              setEditUsedShow(true);
                                            }}
                                          >
                                            <EditIcon2 />
                                          </button>
                                          <button
                                            onClick={() => {
                                              setDelateModalShow(true);
                                              console.log("item", item);
                                              setItemIdToDelete(item.id);
                                              setUsed(true);
                                            }}
                                          >
                                            <DeleteIcon />
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="text-end">
                            <button
                              className="add-button my-3"
                              onClick={() => setAddUsedPartsShow(true)}
                            >
                              Add Parts
                            </button>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                          <div
                            className="table-responsive-sm request_table"
                            style={{ maxWidth: "100%" }}
                          >
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Parts</th>
                                  {/* <th scope="col">Serial No.</th> */}
                                  <th scope="col">Location</th>
                                  <th scope="col">Quantity</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {assetData?.[
                                  currentIndex
                                ].partsReturnedList.map((item) => (
                                  <tr key={item.id}>
                                    <td>
                                      {item.partSerialNummber == null
                                        ? item.partName
                                        : item.partName -
                                          item.partSerialNummber}
                                    </td>
                                    <td>{item.locationName}</td>
                                    <td>{item.quantityReturned}</td>
                                    <td>
                                      <div className="button-con">
                                        <button
                                          onClick={() => {
                                            setEditReturnData(item);
                                            setEditReturnedPartsShow(true);
                                          }}
                                        >
                                          <EditIcon2 />
                                        </button>
                                        <button
                                          onClick={() => {
                                            setDelateModalShow(true);
                                            setItemIdToDelete(item.id);
                                            setReturned(true);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="text-end">
                            <button
                              className="add-button my-3"
                              onClick={() => setAddReturnedPartsShow(true)}
                            >
                              Add Parts
                            </button>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Accordion.Body>
                </Accordion.Item>

                {/* DIAGNOSIS */}
                <Accordion.Item eventKey="3" className="request-box">
                  <Accordion.Header className="request-box-title">
                    <div className="fs-13 fw-medium">DIAGNOSIS</div>
                  </Accordion.Header>
                  <Accordion.Body className="request-box-body">
                    <div
                      className="table-responsive-sm request_table"
                      style={{ maxWidth: "100%" }}
                    >
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Issue diagnosis and solution</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {assetData?.[currentIndex].assetDiagnosisList.map(
                            (item) => (
                              <tr key={item.id}>
                                <td>
                                  <div className="d-grid">
                                    <div
                                      className="fs-14"
                                      style={{ color: "#1F2024" }}
                                    >
                                      PART: {item.part}
                                    </div>
                                    <div
                                      className="fs-13"
                                      style={{ color: "#71727A" }}
                                    >
                                      <b>Diagnosis:</b> {item.diagnosis}
                                    </div>
                                    <div
                                      className="fs-13 pt-2"
                                      style={{ color: "#71727A" }}
                                    >
                                      <b>Solution:</b> {item.solution}
                                    </div>
                                  </div>
                                </td>
                                <td></td>
                                <td>
                                  <div className="button-con">
                                    <button
                                      onClick={() => {
                                        setDiagnosisDetailsShow(true);
                                        setViewItem(item);
                                      }}
                                    >
                                      <ViewIcon2 />
                                    </button>

                                    <button
                                      onClick={() => {
                                        setDelateModalShow(true);
                                        setItemIdToDelete(item.id);
                                        setDiagnosis(true);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                      <DiagnosisDetailsModal
                        show={diagnosisDetailsShow}
                        onHide={() => setDiagnosisDetailsShow(false)}
                        data={viewItem}
                      />
                    </div>
                    <div className="text-end">
                      <button
                        className="add-button my-3"
                        onClick={() => setAddDiagnosisShow(true)}
                      >
                        Add Diagnosis
                      </button>
                      <AddDiagnosisModal
                        ticketAssetId={assetData?.[currentIndex].id}
                        show={addDiagnosisShow}
                        onHide={() => setAddDiagnosisShow(false)}
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        )}
        {showChecklist && (
          <ViewAssetsChecklist checkListData={checkListData} workOrderAssetId={workOrderAssetId} toggleChecklist={toggleChecklist}  />
        )}
        <div style={{ display: "none" }}><ComponentToPrint checkListData={checkListData} ref={componentRef} /></div>

        <AddUsedPartsModal
          ticketAssetId={assetData?.[currentIndex].id}
          index={currentIndex}
          show={addUsedPartsShow}
          onHide={() => setAddUsedPartsShow(false)}
        />
        <EditUsedPartsModal
          data={editData}
          show={editUsedShow}
          onHide={() => setEditUsedShow(false)}
        />

        <AddReturnedPartsModal
          ticketAssetId={assetData?.[currentIndex].id}
          show={addReturnedPartsShow}
          onHide={() => setAddReturnedPartsShow(false)}
        />
        <EditReturnedPartsModal
          data={editReturnData}
          show={editReturnedPartsShow}
          onHide={() => setEditReturnedPartsShow(false)}
        />

        <DeleteModal
          show={delateModalShow}
          onHide={() => setDelateModalShow(false)}
          onDelete={handleDelete}
        />
      </>
    )
  );
};

export default ViewPmAssets;
