import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  asset: "",
  location: "",
  locationId: null,
  assetCategory: "",
  assetCategoryId: null,
  selectedAssets: [],
  assetListIds: [],
  ticketTitle: "",
  TicketTypeDescription: "",
  assignedTeam: {},
  assignedUser: {},
  signatureRequiredToCompleteWork: false,
  estimatedHours: null,
  projectedParts: [],
  checklistIds: [],
  checklists: [],
  ticketPriorityId: null,
  ticketPriority: "",
  files: [],
  categoryOfWorkId: null,
  categoryOfWork: "",
  ticketSLA: {},
  expectedTimeValue: "",
  responseType: "",
  selectedExpectedTime: "Select",
  selectedExpectedTimeId: null,
  expectedTime2Value: "",
  selectedExpectedTime2: "Select",
  selectedExpectedTime2Id: null,
  estimateTimeValue: "",
  selectedEstimateType: "Select",
  selectedEstimateTypeId: null,
  startDate: null,
  endDate: null,
  previousDate: null,
  selectedday: "Select",
  selectedday2: "Select",
  calenderType: [],
  calendarTypeId: null,
  occurence: 0,
  daySearch: "",
  daySearch2: "",
  nextPMOnCompletion: true,
  schedule: "first",
};

const pmFormSlice = createSlice({
  name: "pmform",
  initialState,
  reducers: {
    setLocation: (state, action) => {
      state.location = action.payload.location;
      state.locationId = action.payload.locationId;
    },
    setAssetCategory: (state, action) => {
      state.assetCategory = action.payload.assetCategory;
      state.assetCategoryId = action.payload.assetCategoryId;
    },
    setCategory: (state, action) => {
      state.categoryOfWork = action.payload.categoryOfWork;
      state.categoryOfWorkId = action.payload.categoryOfWorkId;
    },
    setSelectedAssets: (state, action) => {
      state.selectedAssets = action.payload;
    },
    addSelectedAssets: (state, action) => {
      state.selectedAssets.push(action.payload);
    },
    removeSelectedAsset: (state, action) => {
      state.selectedAssets = state.selectedAssets.filter(
        (asset) => asset !== action.payload
      );
    },
    setAssetListId: (state, action) => {
      state.assetListIds = action.payload;
    },
    addAssetListId: (state, action) => {
      state.assetListIds.push(action.payload);
    },
    removeAssetListId: (state, action) => {
      state.assetListIds = state.assetListIds.filter(
        (id) => id !== action.payload
      );
    },
    setTicketTitle: (state, action) => {
      state.ticketTitle = action.payload;
    },
    setTicketDescription: (state, action) => {
      state.TicketTypeDescription = action.payload;
    },
    setAssignedTeam: (state, action) => {
      state.assignedTeam = action.payload;
    },
    setAssignedUser: (state, action) => {
      state.assignedUser = action.payload;
    },
    clearAssignedUser: (state) => {
      state.assignedUser = {};
    },
    setSignatureRequired: (state, action) => {
      state.signatureRequiredToCompleteWork = action.payload;
    },
    setEstimatedHours: (state, action) => {
      state.estimatedHours = action.payload;
    },
    setProjectedParts: (state, action) => {
      state.projectedParts = action.payload;
    },
    setChecklistIds: (state, action) => {
      state.checklistIds = action.payload;
    },
    setChecklists: (state, action) => {
      state.checklists = action.payload;
    },
    setTicketPriority: (state, action) => {
      state.ticketPriorityId = action.payload.priorityId;
      state.ticketPriority = action.payload.priority;
    },
    setFiles: (state, action) => {
      state.files = action.payload;
    },
    addFile: (state, action) => {
      state.files.push(action.payload);
    },
    deleteFile: (state, action) => {
      state.files = state.files.filter(
        (file) => file.fileName !== action.payload
      );
    },
    addProjectedPart: (state, action) => {
      state.projectedParts.push(action.payload);
    },
    addChecklistId: (state, action) => {
      state.checklistIds.push(action.payload);
    },
    removeChecklistId: (state, action) => {
      state.checklistIds = state.checklistIds.filter(
        (id) => id !== action.payload
      );
    },
    removeChecklist: (state, action) => {
      state.checklists = state.checklists.filter(
        (name) => name !== action.payload
      );
    }, 
    setTicketSLA: (state, action) => {
      state.ticketSLA = action.payload;
    },
    setExpectedTimeValue: (state, action) => {
      state.expectedTimeValue = action.payload;
    },
    setResponseType: (state, action) => {
      state.responseType = action.payload;
    },
    setSelectedExpectedTime: (state, action) => {
      state.selectedExpectedTime = action.payload;
    },
    setSelectedExpectedTimeId: (state, action) => {
      state.selectedExpectedTimeId = action.payload;
    },
    setExpectedTime2Value: (state, action) => {
      state.expectedTime2Value = action.payload;
    },
    setSelectedExpectedTime2: (state, action) => {
      state.selectedExpectedTime2 = action.payload;
    },
    setSelectedExpectedTime2Id: (state, action) => {
      state.selectedExpectedTime2Id = action.payload;
    },
    setEstimateTimeValue: (state, action) => {
      state.estimateTimeValue = action.payload;
    },
    setSelectedEstimateType: (state, action) => {
      state.selectedEstimateType = action.payload;
    },
    setSelectedEstimateTypeId: (state, action) => {
      state.selectedEstimateTypeId = action.payload;
    },
    setStartDate: (state, action) => {
        state.startDate = action.payload;
      },
      setEndDate: (state, action) => {
        state.endDate = action.payload;
      },
      setPreviousDate: (state, action) => {
        state.previousDate = action.payload;
      },
      setSelectedDay: (state, action) => {
        state.selectedday = action.payload;
      },
      setSelectedDay2: (state, action) => {
        state.selectedday2 = action.payload;
      },
      setCalenderType: (state, action) => {
        state.calenderType = action.payload;
      },
      setCalendarTypeId: (state, action) => {
        state.calendarTypeId = action.payload;
      },
      setOccurence: (state, action) => {
        state.occurence = action.payload;
      },
      setDaySearch: (state, action) => {
        state.daySearch = action.payload;
      },
      setDaySearch2: (state, action) => {
        state.daySearch2 = action.payload;
      },
      setNextPMOnCompletion: (state, action) => {
        state.nextPMOnCompletion = action.payload;
      },
      setSchedule: (state, action) =>{
        state.schedule = action.payload
      },
    resetForm: () => initialState,
  },
});

export const {
    setLocation,
    setAssetCategory,
    setSelectedAssets,
    setTicketTitle,
    setTicketDescription,
    setAssignedTeam,
    setAssignedUser,
    setSignatureRequired,
    setEstimatedHours,
    setProjectedParts,
    setChecklistIds,
    setTicketPriority,
    setFiles,
    setCategory,
    addFile,
    deleteFile,
    addProjectedPart,
    resetForm,
    addChecklistId,
    removeChecklistId,
    setAssetListId,
    addAssetListId,
    addSelectedAssets,
    removeAssetListId,
    removeSelectedAsset,
    setTicketSLA,
    setExpectedTimeValue,
    setResponseType,
    setSelectedExpectedTime,
    setSelectedExpectedTimeId,
    setExpectedTime2Value,
    setSelectedExpectedTime2,
    setSelectedExpectedTime2Id,
    setEstimateTimeValue,
    setSelectedEstimateType,
    setSelectedEstimateTypeId,
    setStartDate,
    setEndDate,
    setPreviousDate,
    setSelectedDay,
    setSelectedDay2,
    setCalenderType,
    setCalendarTypeId,
    setOccurence,
    setDaySearch,
    setDaySearch2,
    setNextPMOnCompletion,
    setChecklists,
    removeChecklist,
    clearAssignedUser,
    setSchedule,
} = pmFormSlice.actions;

export default pmFormSlice.reducer;
