import React, { useEffect, useRef, useState } from "react";
import logo from "../../../Assets/Images/logo.png";
import { Table } from "react-bootstrap";

const PDFDocument = (props) => {
  const componentRef = useRef();
  const [company, setCompany] = useState({});
  useEffect(() => {
    setCompany(JSON.parse(localStorage.getItem("company")));
  }, []);
  return (
    <>
      <div className="pad-overflow">
        <div className="quotes-pad" id="quotes-pad" ref={componentRef}>
          <div className="auto-gap">
            <div className="pad-header d-flex flex-wrap justify-content-between align-items-center">
              <div className="pad-logo">
                <img src={logo} alt={logo} />
              </div>
              <address className="fs-13 text-black lh-base text-end">
                <div className="fs-24 text-black text-uppercase fw-bold">
                  quote
                </div>
                {props?.quote?.quotingTeamName}
                <br />
                {props?.quote?.quotingTeamPhoneNumber}
                <br />
                {props?.quote?.quotingTeamEmail} <br />
                {props?.quote?.quotingTeamAddress1}
                {props?.quote?.quotingTeamAddress2}
              </address>
            </div>
            <hr />
            <div className="quotes-total d-flex flex-wrap justify-content-between align-items-center">
              <div className="quotes-name d-flex flex-wrap gap-4 align-items-center">
                <div className="lh-sm">
                  <div className="fs-13 fw-medium">{company?.companyName}</div>
                  <div className="fs-11">{company?.address1}</div>
                  <div className="fs-12">
                    {company?.street}, {company?.city}, {company?.country}
                  </div>
                </div>
                <div className="lh-sm">
                  <div className="fs-13 fw-medium">
                    Quotation - {props?.quote?.quoteRef}
                  </div>
                  <div className="fs-12">
                    {new Date(props?.quote?.rfqCreateDate).toLocaleDateString(
                      "en-GB",
                      {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                        //hour: "numeric",
                        //minute: "numeric",
                        //hour12: true,
                      }
                    )}
                  </div>
                </div>
              </div>
              <div
                className="total-price fs-20 fw-medium"
                style={{ color: "#D57D2A" }}
              >
                <b className="pe-4">Total</b> {company?.currency && props?.quote?.total ? company.currency + " " + props?.quote?.total.toLocaleString() : 'N/A'}
              </div>
            </div>
            <hr />
            <Table responsive style={{ background: "none" }}>
              <thead>
                <tr>
                  <th>Item Description</th>
                  <th>Qty</th>
                  <th>Amount</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {props?.quote?.quoteItems?.map((item) => (
                  <tr key={item.item}>
                    <td>{item?.description}</td>
                    <td>{item?.quantity}</td>
                    <td>{company.currency + " " + item?.unitCost.toLocaleString()}</td>
                    <td>{company.currency + " " + item?.totalCost.toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <hr />
            <div className="nots-pard">
              <div className="">
                <div className="fs-11">Notes</div>
                <div className="fs-10 pt-2">{props?.quote?.notes}</div>
              </div>
              <ul>
                {/* <li>
                  <span>SUb Total</span>
                  <span>
                    {company?.currency} {props?.quote?.total}
                  </span>
                </li>
                <li>
                  <span>Tax</span>
                  <span>
                    {company.currency} {props?.quote?.total}
                  </span>
                </li> */}
                <li>
                  <span>Grand Total</span>
                  <span>
                    {company?.currency && props?.quote?.total ? company.currency + " " + props?.quote?.total.toLocaleString() : 'N/A'}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="quotes-footer">
            <span>Prepared By: {props?.quote?.quotingUserName}</span>
            {/*<span>{props?.quote?.quotingTeamPhoneNumber}</span>
            <span>{props?.quote?.quotingTeamEmail}</span>*/}
          </div>
        </div>
      </div>
    </>
  );
};

export default PDFDocument;
