import React from 'react';

const GridIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M18 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H18C19.1 20 20 19.1 20 18V2C20 0.9 19.1 0 18 0ZM6 18H2V14H6V18ZM6 12H2V8H6V12ZM6 6H2V2H6V6ZM12 18H8V14H12V18ZM12 12H8V8H12V12ZM12 6H8V2H12V6ZM18 18H14V14H18V18ZM18 12H14V8H18V12ZM18 6H14V2H18V6Z" fill="currentColor" fill-opacity="0.87"/>
        </svg>
    );
};

export default GridIcon;