import React, { useEffect, useState, useContext } from "react";
import { Tab, Nav } from "react-bootstrap";
import NewTable from "./NewTable";
import OngoingTable from "./OngoingTable";
import CompletedTable from "./CompletedTable";
import UpcomingTable from "./UpcomingTable";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import exportToExcel from "../../../utls/exportToExcel";

import RoutineInspectionCategoryContext from "../../../Context/Inspections/RoutineInspectionCategoryContext";
import AddIcon from "../../../Assets/Icons/AddIcon";
import ModalInspection from "../ModalInspection";
import {
  GET_FORM_AND_SECTION_TASK_ANSWERS,
  GET_INSPECTIONS_BY_CATEGORY_ID,
  GET_INSPECTIONS_BY_CATEGORY_IDs,
} from "../../../utls/constants";
import { get } from "../../../Services/ApiHelper";

const F3WashroomsContent = ({ ScheduleId, category }) => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const [completedTableContent, setCompletedTableContent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inspectionShow, setInspectionShow] = useState(false);
  const [Item, setItem] = useState({});
  const [inspectionData, setInspectionData] = useState([]);
  const { id } = useParams();
  let [report, setReport] = useState([
    {
      Filled_By: "",
      Asset_Name: "",
      Inspection_Code: "",
      Timestamp: "",
      Assigned_Worker: "",
      otherDetails: [{ section: "",question: "", answer: "" }],
    },
  ]);

  const {
    inspectionDetailData,
    setCompletedInspections,
    completedInspectionsData,
  } = useContext(RoutineInspectionCategoryContext);
  const handleSetItem = (item) => {
    setItem(item);
  };
  const navigate = useNavigate();
  const handleCompletedInspections = () => {};

  const handleExport = async () => {
    const count = 0;
    let obj = {};
    let quizAnsArray = [];
    setIsLoading(true);
    try {
      const result = await get(
        GET_INSPECTIONS_BY_CATEGORY_IDs(id, "Complete", 1, 1000),
        localStorage.getItem("bearerToken")
      );
      const inspectionData = result.data;

      let updatedReport = [];
      for (const inspection of inspectionData) {
        const formDataResult = await get(
          GET_FORM_AND_SECTION_TASK_ANSWERS(
            inspection.routineInspectionId,
            inspection.checkListFormId
          ),
          localStorage.getItem("bearerToken")
        );
        const formData = formDataResult.data.formDetails;
        for (const item of formData) {
          
          const formModel = item.formDataModel;
          const questionsAnswers = formModel.map((item) => {
            return { section:item.formsAndSectionsSubsName,
              question: item.task, answer: item.answer };
              
          });

          updatedReport.push({
            Filled_By: inspection.assignedUserName,
            Asset_Name: inspection.assetName,
            Inspection_Code: inspection.inspectionCode,
            Timestamp: new Date(inspection.dueDate).toLocaleString("en-GB"),
            Assigned_Worker: inspection.assignedUserName,
            otherDetails: questionsAnswers,
          });
        }
      }
      setReport(updatedReport);
      exportToExcel(updatedReport);
    } catch (error) {
      console.error("Error exporting data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="order-request washroom-right-content">
        <div className="washroom-title">
          <Link to="#" className="border-0">
            <div className="fs-13 fw-medium">CONTINUOUS CHECK</div>
          </Link>

          {tab !== "completed-inspections" && (
            <button
              onClick={() => {
                setInspectionShow(true);
                setItem(null);
                handleSetItem(null);
              }}
            >
              {/* <AddIcon />  */}
              Add Continuous Checks
            </button>
          )}

          <ModalInspection
            show={inspectionShow}
            onHide={() => setInspectionShow(false)}
            item={Item}
          />

          {tab === "completed-inspections" && (
            <div className="d-flex justify-content-center gap-3">
              <>
                <Link
                  onClick={() => {
                    handleExport();
                  }}
                  to={"?tab=completed-inspections"}
                >
                  Open in Excel
                </Link>
                <button
                  onClick={() => {
                    setInspectionShow(true);
                    setItem(Item);
                  }}
                >
                  {/* <AddIcon />  */}
                  Add Continuous Checks
                </button>
              </>
            </div>
          )}
        </div>
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <Tab.Container
            id="f3washroom-table"
            defaultActiveKey={tab ? tab : "new-inspections"}
          >
            <Nav variant="pills" className="f3washroom-table-nav">
              <Nav.Item>
                <Nav.Link
                  onClick={() => navigate("?tab=new-inspections")}
                  eventKey="new-inspections"
                >
                  New Checks
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={() => navigate("?tab=ongoing-inspections")}
                  eventKey="ongoing-inspections"
                >
                  Ongoing Checks
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={() => {
                    handleCompletedInspections();
                    navigate("?tab=completed-inspections");
                  }}
                  eventKey="completed-inspections"
                >
                  Completed Checks
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={() => navigate("?tab=upcoming-inspections")}
                  eventKey="upcoming-inspections"
                >
                  Upcoming Checks
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="new-inspections">
                <div className="f3washroom-min-content">
                  <NewTable ScheduleId={ScheduleId} category={category} />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="ongoing-inspections">
                <div className="f3washroom-min-content">
                  <OngoingTable ScheduleId={ScheduleId} category={category} />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="completed-inspections">
                <div className="f3washroom-min-content">
                  <CompletedTable
                    ScheduleId={ScheduleId}
                    inspectionData={completedTableContent}
                    category={category}
                  />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="upcoming-inspections">
                <div className="f3washroom-min-content">
                  <UpcomingTable ScheduleId={ScheduleId} category={category} />
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        )}
      </div>
    </>
  );
};

export default F3WashroomsContent;
