import React, { useState, useEffect, useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DownIcon from "../../../Assets/Icons/DownIcon";
import RoutineInspectionCategoryContext from "../../../Context/Inspections/RoutineInspectionCategoryContext";
import { GET_CALENDAR_TYPES } from "../../../utls/constants";

const CreateRecurrence = ({ onRecurrenceChange, startDateProp, endsDateProp }) => {
  const [startDate, setStartDate] = useState(startDateProp || new Date());
  const [endsDate, setEndsDate] = useState(endsDateProp || new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [calendarTypes, setCalendarTypes] = useState([]);
  const ExampleCustomInput = ({ value, onClick }) => (
    <button className="example-custom-input" onClick={onClick}>
      {value} <DownIcon />
    </button>
  );

  // Add state for recurrence values
  const [recurrenceValue, setRecurrenceValue] = useState({
    number: 5,
    type: "Select",
    startDate,
    endsDate
  });

  useEffect(() => {
    // Fetch calendar types and update state
    const token = localStorage.getItem("bearerToken");
    if (!token) {
      window.location.href = "/sign-in";
    }

    fetch(GET_CALENDAR_TYPES, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        var result = data["data"];
        setIsLoading(false);
        setCalendarTypes(result);
      });
  }, []);

  // Notify the parent component about the recurrence changes
  useEffect(() => {
    onRecurrenceChange(recurrenceValue);
  }, [recurrenceValue, onRecurrenceChange]);

  useEffect(() => {
    // Save to local storage whenever startDate changes
    localStorage.setItem("startDate", startDate.toISOString());
  }, [startDate]);

  useEffect(() => {
    // Save to local storage whenever endsDate changes
    localStorage.setItem("endsDate", endsDate.toISOString());
  }, [endsDate]);

  const storedStartDateData = window.localStorage.getItem("startDate");



  return (
    <>
      <div className="col-lg-12"> 
        <div className="select-box recurr-input">
          <label>Recurrence</label>
          <input
          type="number"
            className="form-select number-select"
            onChange={(e) =>
              setRecurrenceValue({
                ...recurrenceValue,
                number: parseInt(e.target.value),
              })
            }
          />

          <select
            className="form-select recurr-selec"
            onChange={(e) =>
              setRecurrenceValue({
                ...recurrenceValue,
                type: e.target.value,
              })
            }
          >
            <option selected value="Select">
              Select
            </option>
            {calendarTypes.map((item) => (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-lg-12">
          <div className="create-calender">
            <div className="pm-calender">
              <label>Starts on</label>
              <DatePicker
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
                onRecurrenceChange({ ...recurrenceValue, startDate: date });
              }}
              customInput={<ExampleCustomInput />}
            />
            </div>
            <div className="pm-calender">
              <label>Ends on</label>
              <DatePicker
              selected={endsDate}
              onChange={(date) => {
                setEndsDate(date);
                onRecurrenceChange({ ...recurrenceValue, endsDate: date });
              }}
              customInput={<ExampleCustomInput />}
            />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateRecurrence;
