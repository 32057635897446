import React, { useContext, useEffect, useState } from "react";
import DeleteModal from "../../Modal/DeleteModal";
import DeleteIcon from "../../../../Assets/Icons/DeleteIcon";
import AddItemCostsModal from "../../Modal/AddItemCostsModal";
import SubmitCostsModal from "../../Modal/SubmitCostsModal";
import ConfirmSubmitModal from "../../Modal/ConfirmSubmitModal";
import RecallCostsModal from "../../Modal/RecallCostsModal";
import { WorkOrderContext } from "../../../../Context/Inspections/WorkOrderContext";
import usefetchData from "../../../../hooks/useFetchData";
// import toast from "react-hot-toast";
import useMutateData from "../../../../hooks/useMutateData";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const AgreedRates = ({  ticketSummary }) => {
  //currency
  const company = localStorage.getItem("company");
  const { setTicketSummary, setIsLoading } = useContext(WorkOrderContext);
  const companyObj = company && JSON.parse(company);
  const currency = companyObj.currency;
  const [showNav, setShowNav] = useState(true);
  const { id } = useParams();
  const ticketId = Number(id);

  const basicGroupId =
    ticketSummary?.costingSummary?.priceListCostingBasis?.costingBasisGroupId;

  const agreedRates =
    ticketSummary?.costingSummary?.priceListCostingBasis?.priceListItems;

  const locationId = ticketSummary?.workOrderSummary?.locationId;
  const categoryId = ticketSummary?.workOrderSummary?.categoryOfWorkId;

  const { data: pricelist, isLoading: isLoadingPricelist } = usefetchData(
    ["price-list", categoryId],
    `Tickets/GetPriceListItemsByCategory/${categoryId}`,
    {},
    "Couldn't get",
    !!categoryId
  );

  const { data: costData, isLoading: isLoadingcostData } = usefetchData(
    ["items-type", categoryId],
    `TicketCostings/GetAllTicketCostItemsTypes`,
    {},
    "Couldn't get",
    true
  );

  const { data: mileage, isLoading: isLoadingMileage } = usefetchData(
    ["category-id", categoryId],
    `Tickets/GetMileageByLocationCategory`,
    { LocationId: locationId, CategoryId: categoryId },
    "Couldn't get  mileage data",
    !!locationId && !!categoryId
  );

  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [addItemCostModalShow, setAddItemCostModalShow] = useState(false);
  const [submitCostsModalShow, setSubmitCostsModalShow] = useState(false);
  const [confirmSubmitModalShow, setConfirmSubmitModalShow] = useState(false);
  const [recallCostsModalShow, setRecallCostsModalShow] = useState(false);
  const [showSubmitCostsContent, setShowSubmitCostsContent] = useState(true);
  const [itemId, setItemId] = useState(null);

  const handleSubmitCosts = () => {
    setConfirmSubmitModalShow(false);
    setShowSubmitCostsContent(false);
    setShowNav(false); // Hide the nav bar
  };

  const handleRecallCosts = () => {
    setRecallCostsModalShow(false);
    setShowSubmitCostsContent(true);
    setShowNav(true); // Show the nav bar
  };

  const handleOkayClick = () => {
    setSubmitCostsModalShow(false);
    setConfirmSubmitModalShow(true);
  };

  const handleShowRecall = () => {
    setRecallCostsModalShow(true);
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength - 3) + "...";
  };

  // submit constings
  const { mutate, isLoading: isSubmitting } = useMutateData({
    url: `Tickets/SubmitTicketCostedItems`,
    method: "POST",
    onSuccessfullMutation: (data) => {
      setConfirmSubmitModalShow(false);
      if (data?.data?.succeeded === true) {
        toast.success(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setConfirmSubmitModalShow(false);
        setShowSubmitCostsContent(false);
        setShowNav(false);
        // Hide the nav bar
      } else {
        setConfirmSubmitModalShow(false);
        toast.error(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
    successMessage: `Quote submitted successfully`,
    errorMessage: "Submission failed",
    queryKeysToInvalidate: [["ticket-summary", ticketId]],
  });

  // recall constings
  const { mutate: recall, isLoading: isRecalling } = useMutateData({
    url: `Tickets/RecallTicketCostedItems`,
    method: "POST",
    onSuccessfullMutation: (data) => {
      console.log("Recall mutation data", data);
      if (data?.data?.succeeded === true) {
        toast.success(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setRecallCostsModalShow(false);
        setShowSubmitCostsContent(true);
        setShowNav(true); // Show the nav bar
      } else {
        toast.error(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
    successMessage: `Quote Recalled successfully`,
    errorMessage: "Recall failed",
    queryKeysToInvalidate: [
      ["price-list", categoryId],
      ["ticket-summary", ticketId],
    ],
  });

  // delete cost entry
  const { mutate: deleteItem, isLoading: isDeleting } = useMutateData({
    url: `Tickets/DeleteTicketCostedItemById`,
    method: "POST",
    onSuccessfullMutation: (data) => {
      console.log("Delete mutation data", data?.data?.succeeded);
      if (data?.data?.succeeded === true) {
        toast.success(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setDeleteModalShow(false);
        setShowSubmitCostsContent(true);
        setShowNav(true); // Show the nav bar
      } else {
        toast.error(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
    successMessage: `Successfully created a work order`,
    errorMessage: "Work order creation failed",
    queryKeysToInvalidate: [["ticket-summary", ticketId]],
  });

  const onDeleteCostItem = () => {
    const requestBody = {
      id: itemId,
    };
    deleteItem(requestBody);
  };

  const onSubmitCostings = () => {
    const requestBody = {
      costingBasisGroupId: basicGroupId,
      ticketId: ticketId,
    };
    mutate(requestBody);
  };

  const onRecallCostings = () => {
    const requestBody = {
      ticketId: ticketId,
      costingBasisGroupId: basicGroupId,
    };
    recall(requestBody);
  };

  // Function to calculate the total cost
  const calculateTotalCost = () => {
    return agreedRates?.reduce((total, part) => {
      return total + part.quantity * part.unitCost;
    }, 0);
  };

  // Calculate the total cost
  const totalCost = calculateTotalCost();

  return (
    <>
      <>
        <div className="d-flex align-items-center justify-content-between gap-3 pt-3 pb-3">
          <div className=""></div>
          {ticketSummary?.costingSummary?.priceListCostingBasis
            ?.approvalLevelStateName === "Not Approved" &&
            (ticketSummary?.costingSummary?.priceListCostingBasis
              ?.isSubmitted ? (
              <button
                className="fs-15 fw-bold"
                style={{ color: "#D57D2A" }}
                onClick={() => setRecallCostsModalShow(true)}
              >
                RECALL COST
              </button>
            ) : (
              <button
                className="fs-15 fw-bold"
                style={{ color: "#D57D2A" }}
                onClick={() => setSubmitCostsModalShow(true)}
              >
                SUBMIT COST
              </button>
            ))}
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="fs-15 fw-medium black-38">ITEM COSTING</div>
          {!ticketSummary?.costingSummary?.priceListCostingBasis
            ?.isSubmitted && (
            <button
              className="add-button my-2"
              style={{ fontSize: "15px", fontWeight: "500" }}
              onClick={() => setAddItemCostModalShow(true)}
            >
              Add Cost
            </button>
          )}
        </div>
        <div
          className="table-responsive-sm request_table"
          style={{ maxWidth: "100%" }}
        >
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Item</th>
                <th scope="col">Quantity</th>
                <th scope="col">Unit Cost</th>
                <th scope="col">Total Cost</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {agreedRates?.map((item) => (
                <tr key={item.id}>
                  <td className="hover-text" style={{ width: "310px" }}>
                    <div>
                      {truncateText(item.description, 38)}
                      <span class="tooltiptext">{item.description}</span>
                    </div>
                  </td>
                  <td>{item.quantity}</td>
                  <td>
                    {currency} {item.unitCost}
                  </td>
                  <td>
                    {currency} {item.unitCost * item?.quantity}
                  </td>
                  <td>
                    {!ticketSummary?.costingSummary?.priceListCostingBasis
                      ?.isSubmitted && (
                      <div className="button-con">
                        <button
                          onClick={() => {
                            setItemId(item?.id);
                            setDeleteModalShow(true);
                          }}
                        >
                          <DeleteIcon />
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="d-flex align-items-center justify-content-between pt-2 pb-3">
          <div className="fs-20 fw-bold black-38">TOTAL COST</div>
          <div className="fs-20 fw-bold black-38">
            {currency} {totalCost}
          </div>
        </div>
      </>
      {/* {showSubmitCostsContent ? (
        <>
          <div className="d-flex align-items-center justify-content-between gap-3 pt-3 pb-3">
            <div className=""></div>
            <button
              className="fs-15 fw-bold"
              style={{ color: "#D57D2A" }}
              onClick={() => setSubmitCostsModalShow(true)}
            >
              {ticketSummary?.costingSummary?.priceListCostingBasis?.isSubmitted
                ? "RECALL COST"
                : "SUBMIT COST"}
            </button>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="fs-15 fw-medium black-38">ITEM COSTING</div>
            {!ticketSummary?.costingSummary?.priceListCostingBasis
              ?.isSubmitted && (
              <button
                className="add-button my-2"
                style={{ fontSize: "15px", fontWeight: "500" }}
                onClick={() => setAddItemCostModalShow(true)}
              >
                Add Cost
              </button>
            )}
          </div>
          <div
            className="table-responsive-sm request_table"
            style={{ maxWidth: "100%" }}
          >
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Item</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Unit Cost</th>
                  <th scope="col">Total Cost</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {agreedRates?.map((item) => (
                  <tr key={item.id}>
                    <td className="hover-text" style={{ width: "310px" }}>
                      <div>
                        {truncateText(item.description, 38)}
                        <span class="tooltiptext">{item.description}</span>
                      </div>
                    </td>
                    <td>{item.quantity}</td>
                    <td>{currency} {item.unitCost}</td>
                    <td>{currency} {item.unitCost * item?.quantity}</td>
                    <td>
                      <div className="button-con">
                        <button onClick={() => setDeleteModalShow(true)}>
                          <DeleteIcon />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex align-items-center justify-content-between pt-2 pb-3">
            <div className="fs-20 fw-bold black-38">TOTAL COST</div>
            <div className="fs-20 fw-bold black-38">{currency} {totalCost}</div>
          </div>
        </>
      ) : (
        <>
          <div
            className="fs-15 fw-medium py-4 mb-3"
            style={{
              borderBottom: "1px solid #f1efef",
            }}
          >
            Based on agreed rates
          </div>

          <div className="d-flex align-items-center justify-content-between gap-3 pt-3 pb-3">
            <div className=""></div>
            <button
              className="fs-15 fw-bold"
              style={{ color: "#D57D2A" }}
              onClick={handleShowRecall}
            >
              RECALL COST
            </button>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="fs-15 fw-medium black-38">ITEM COSTING</div>
          </div>
          <div
            className="table-responsive-sm request_table"
            style={{ maxWidth: "100%" }}
          >
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Item</th>
                  <th scope="col">Quantity</th>
                  <th scope="col" style={{ textAlign: "center" }}>
                    Unit Cost
                  </th>
                  <th scope="col" style={{ width: "auto", textAlign: "right" }}>
                    Total Cost
                  </th>
                </tr>
              </thead>
              <tbody>
                {partsData.map((item) => (
                  <tr key={item.id}>
                    <td style={{ width: "310px" }}>
                      {truncateText(item.parts, 38)}
                    </td>
                    <td>{item.quantity}</td>
                    <td style={{ textAlign: "center" }}>KES {item.unit}</td>
                    <td style={{ width: "auto", textAlign: "right" }}>
                      KES {item.amount}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex align-items-center justify-content-between pt-2 pb-3">
            <div className="fs-20 fw-bold black-38">TOTAL COST</div>
            <div className="fs-20 fw-bold black-38">KES 116,850</div>
          </div>
        </>
      )} */}

      <DeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        onDelete={onDeleteCostItem}
      />
      <AddItemCostsModal
        show={addItemCostModalShow}
        onHide={() => setAddItemCostModalShow(false)}
        partData={pricelist}
        costData={costData}
        currency={currency}
        ticketId={ticketId}
        mileage={mileage}
        categoryId={categoryId}
        locationId={locationId}
      />
      {/* <SubmitCostsModal
        show={submitCostsModalShow}
        onHide={() => setSubmitCostsModalShow(false)}
        handleOkayClick={handleOkayClick}
      /> */}
      <ConfirmSubmitModal
        show={submitCostsModalShow}
        onHide={() => setSubmitCostsModalShow(false)}
        handleSubmitCosts={onSubmitCostings}
      />
      <RecallCostsModal
        show={recallCostsModalShow}
        onHide={() => setRecallCostsModalShow(false)}
        handleRecallCosts={onRecallCostings}
      />
    </>
  );
};

export default AgreedRates;
