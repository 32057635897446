import React, { useState } from "react";
import EditIcon from "../../../Assets/Icons/EditIcon";
import DeleteIcon from "../../../Assets/Icons/DeleteIcon";
import DownIcon from "../../../Assets/Icons/DownIcon";
import DropdownIcon from "../../../Assets/Icons/DropdownIcon";
import ArrowLeft from "../../../Assets/Icons/ArrowLeft";
import ArrowRight from "../../../Assets/Icons/ArrowRight";
import SearchIcon from "../../../Assets/Icons/SearchIcon";

const Teams = () => {
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [selectedPerPage, setSelectedPerPage] = useState("7");
  const handlePerClick = (per) => {
    setSelectedPerPage(per);
  };
  const handleLocationClick = (location) => {
    setSelectedLocation(location);
  };

  const handleTeamClick = (team) => {
    setSelectedTeam(team);
  };
  return (
    <>
      <div className="request-box-body">
        <div className="schedules-selection">
          <div className="schedules-dropdown">
            <div className="dropdown select-dropdown">
              <button className="select-title" data-bs-toggle="dropdown">
                <span className="fs-13">Location:</span>
                <span className="fs-14 d-flex align-items-center gap-1">
                  {selectedLocation} <DownIcon />
                </span>
              </button>
              <ul className="dropdown-menu select-menu">
                <li onClick={() => handleLocationClick("Narok")}>Narok</li>
                <li onClick={() => handleLocationClick("Narok 1")}>Narok 1</li>
                <li onClick={() => handleLocationClick("Narok 2")}>Narok 2</li>
              </ul>
            </div>
            <div className="dropdown select-dropdown">
              <button className="select-title" data-bs-toggle="dropdown">
                <span className="fs-13">Category of Work</span>
                <span className="fs-14 d-flex align-items-center gap-1">
                  {selectedTeam} <DownIcon />
                </span>
              </button>
              <ul className="dropdown-menu select-menu">
                <li onClick={() => handleTeamClick("Category of Work")}>
                  Category of Work
                </li>
                <li onClick={() => handleTeamClick("Category of Work")}>
                  Category of Work
                </li>
                <li onClick={() => handleTeamClick("Category of Work")}>
                  Category of Work
                </li>
              </ul>
            </div>
          </div>
          <div>
            <form action="" className="search__box">
              <button type="submit">
                <SearchIcon />
              </button>
              <input type="search" placeholder="Search" />
            </form>
          </div>
        </div>

        <div className="table-responsive request_table table_fulls">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Category of Work</th>
                <th scope="col">Locations</th>
                <th scope="col">Team Lead</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Electrical Engineers</td>
                <td>Electrical</td>
                <td>Nairobi HQ</td>
                <td>John Doe</td>
                <td>
                  <div className="button-con">
                    <button>
                      <EditIcon />
                    </button>
                    <button>
                      <DeleteIcon />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="table-pagination pagination-center mt-180">
        <div className="pagination-row">
          Rows per page:
          <div className="dropdown select-dropdown">
            <button className="select-title" data-bs-toggle="dropdown">
              <span className="fs-14 d-flex align-items-center gap-2">
                {selectedPerPage} <DropdownIcon />
              </span>
            </button>
            <ul className="dropdown-menu select-menu">
              <li onClick={() => handlePerClick("5")}>5</li>
              <li onClick={() => handlePerClick("10")}>10</li>
              <li onClick={() => handlePerClick("15")}>15</li>
              <li onClick={() => handlePerClick("20")}>20</li>
            </ul>
          </div>
        </div>
        <div className="pagination-number">
          <div className="fs-14">1-3 of 6</div>
          <div className="arrow-btn">
            <button>
              <ArrowLeft />
            </button>
            <button>
              <ArrowRight />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Teams;
