import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";
import { post } from "../../../Services/ApiHelper";
import { ADD_TICKET_DIAGNOSIS } from "../../../utls/constants";
import Astericks from "../../Common/Asterick";

const AddDiagnosisModal = (props) => {
  const [fault, setFault] = useState("");
  const [cause, setCause] = useState("");
  const [solution, setSolution] = useState("");
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);
  const token = localStorage.getItem("bearerToken");
  const [isLoading, setIsLoading] = useState(false);

  const ticketId = ticketSummary?.workOrderSummary?.ticketId;

  const [faultError, setFaultError] = useState(null);
  const [causeError, setCauseError] = useState(null);
  const [solutionError, setSolutionError] = useState(null);
  useEffect(() => {
    setSolution("");
    setCause("");
    setFault("");
  }, [props.show]);
  const validateInputs = () => {
    let isValid = true;

    setFaultError(null);
    setCauseError(null);
    setSolutionError(null);

    if (!fault) {
      setFaultError("Required *");
      isValid = false;
    }

    if (!cause) {
      setCauseError("Required *");
      isValid = false;
    }

    if (!solution) {
      setSolutionError("Required *");
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = () => {
    const isValid = validateInputs();

    if (!isValid) {
      return;
    }
    setIsLoading(true);
    const payload = {
      ticketAssetId: props.ticketAssetId,
      part: fault,
      diagnosis: cause,
      diagnosisDescription: cause,
      solution: solution,
    };
    console.log(payload);
    setSolution("");
    setCause("");
    setFault("");
    post(ADD_TICKET_DIAGNOSIS, payload, token)
      .then((response) => {
        if (response.succeeded === true) {
          setIsLoading(false);
          props.onHide();
          setSolution("");
          setCause("");
          setFault("");

          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          const newPartsData = {
            id: response.data,
            diagnosis: cause,
            diagnosisDescription: cause,
            part: fault,
            solution: solution,
            ticketAssetId: props.ticketAssetId,
          };

          const updatedWorkOrderAssetList =
            ticketSummary.assetSummary.workOrderAssetList.map((asset) => {
              if (asset.assetDiagnosisList) {
                return {
                  ...asset,
                  assetDiagnosisList: [
                    ...asset.assetDiagnosisList,
                    newPartsData,
                  ],
                };
              }
              return asset;
            });

          setTicketSummary((prevTicketSummary) => ({
            ...prevTicketSummary,
            assetSummary: {
              ...prevTicketSummary.assetSummary,
              workOrderAssetList: updatedWorkOrderAssetList,
            },
          }));
        } else {
          setIsLoading(false);
          props.onHide();
          setSolution("");
          setCause("");
          setFault("");

          toast.error(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        props.onHide();
        setSolution("");
        setCause("");
        setFault("");
        toast.error("An error occurred", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
    setIsLoading(false);
    props.onHide();
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Add Diagnosis</span>
            <span className="fs-14" style={{ color: "#72777A" }}>
              Provide diagnosis for work done
            </span>
          </div>
          <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">
                  What was faulty?
                  <Astericks />
                </label>
                {faultError && <p style={{ color: "red" }}>{faultError}</p>}
              </div>
              <input
                onChange={(e) => setFault(e.target.value)}
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "0 15px",
                }}
                placeholder="Enter the part that was worked on"
              />
            </div>
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">
                  What caused the fault (Diagnosis)?
                  <Astericks />
                </label>
                {causeError && <p style={{ color: "red" }}>{causeError}</p>}
              </div>
              <textarea
                className="modal-input-box"
                onChange={(e) => setCause(e.target.value)}
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "150px",
                  borderRadius: "5px",
                  padding: "15px 15px",
                }}
                placeholder="Enter description of the issue"
              />
            </div>
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">
                  How did you solve the fault (Solution)?
                  <Astericks />
                </label>
                {solutionError && (
                  <p style={{ color: "red" }}>{solutionError}</p>
                )}
              </div>
              <textarea
                className="modal-input-box"
                onChange={(e) => setSolution(e.target.value)}
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "150px",
                  borderRadius: "5px",
                  padding: "15px 15px",
                }}
                placeholder="Enter solution provided"
              />
            </div>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            {isLoading ? (
              <Spinner
                animation="border"
                style={{
                  color: "#D57D2A",
                }}
              />
            ) : (
              <Link to="" className="delate-btn" onClick={handleSubmit}>
                Add
              </Link>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddDiagnosisModal;
