import React, { useState, useContext } from "react";
import DownIcon from "../../../Assets/Icons/DownIcon";
import AddIcon from "../../../Assets/Icons/AddIcon";
import AssingModal from "../../WorkOrders/Modal/AssingModal";
import AcceptModal from "../../WorkOrders/Modal/AcceptModal";
import RequestModal from "../../WorkOrders/Modal/RequestModal";
import ConfirmModal from "../../WorkOrders/Modal/ConfirmModal";
import RequestClosureModal from "../../WorkOrders/Modal/RequestClosureModal";
import StartWorkModal from "../../WorkOrders/Modal/StartWorkModal";
import ConfirmWorkModal from "../../WorkOrders/Modal/ConfirmWorkModal";
import CloseWorkModal from "../../WorkOrders/Modal/CloseWorkModal";
import ViewImage from "../../WorkOrders/Modal/ViewImage";
import ViewVideo from "../../WorkOrders/Modal/ViewVideo";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";
import HoldModal from "../../WorkOrders/Modal/HoldModal";
import AcceptHoldModal from "../../WorkOrders/Modal/AcceptHoldModal";
import ResumeModal from "../../WorkOrders/Modal/ResumeModal";
import { toast } from "react-toastify";
import { Add_Ticket_TO_ONHOLD } from "../../../utls/constants";
import { post } from "../../../Services/ApiHelper";

const ViewPmHeader = () => {
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);
  const [assignShow, setAssignShow] = useState(false);
  const [acceptShow, setAcceptShow] = useState(false);
  const [requestShow, setRequestShow] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);
  const [startWorkShow, setStartWorkShow] = useState(false);
  const [holdShow, setHoldShow] = useState(false);
  const [acceptHoldShow, setAcceptHoldShow] = useState(false);
  const [resumeShow, setResumeShow] = useState(false);
  const [requestClosureShow, setRequestClosureShow] = useState(false);
  const [confirmWorkShow, setConfirmWorkShow] = useState(false);
  const [closeWorkShow, setCloseWorkShow] = useState(false);
  const [imageShow, setImageShow] = useState(false);
  const [videoShow, setVideoShow] = useState(false);
  const claims = (claimValue) => {
    //Check if value is not null or undefined
    if (localStorage.getItem("claims")) {
      // Parse the string as a JSON array
      const claimsArray = JSON.parse(localStorage.getItem("claims"));
      if (claimsArray.includes(claimValue)) {
        // The string is present in the array
        return true;
      } else {
        return false;
      }
    } else {
      // Handle the case where the "claims" key is not present in local storage
      return false;
    }
  };

  const ticketId = ticketSummary?.workOrderSummary?.ticketId;

  return (
    <>
      <div className="work-header">
        <div className="fs-20">View Work Orders</div>
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-15 d-flex align-items-center gap-2">
              Actions <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu dropdown-menu-end select-menu">
            {!ticketSummary?.workOrderSummary?.acceptOptionOnly &&
              claims("Can_Assign_Ticket") && (
                <li>
                  <button onClick={() => setAssignShow(true)}>
                    <AddIcon /> Assign Work
                  </button>
                  <AssingModal
                    show={assignShow}
                    onHide={() => setAssignShow(false)}
                  />
                </li>
              )}
            {ticketSummary?.workOrderSummary?.acceptOptionOnly && (
              <li>
                <button onClick={() => setAcceptShow(true)}>
                  <AddIcon /> Accept Work
                </button>
                <AcceptModal
                  show={acceptShow}
                  onHide={() => setAcceptShow(false)}
                />
              </li>
            )}
            {!ticketSummary?.workOrderSummary?.acceptOptionOnly &&
              claims("Can_Request_ArrivalOnSite") && (
                <li>
                  <button onClick={() => setRequestShow(true)}>
                    <AddIcon /> Request Arrival
                  </button>
                  <RequestModal
                    show={requestShow}
                    onHide={() => setRequestShow(false)}
                  />
                </li>
              )}
            {!ticketSummary?.workOrderSummary?.acceptOptionOnly &&
              claims("Can_Confirm_ArrivalOnsite") && (
                <li>
                  <button onClick={() => setConfirmShow(true)}>
                    <AddIcon /> Confirm Arrival
                  </button>
                  <ConfirmModal
                    show={confirmShow}
                    onHide={() => setConfirmShow(false)}
                  />
                </li>
              )}
            {!ticketSummary?.workOrderSummary?.acceptOptionOnly &&
              claims("Can_Start_Work") && (
                <li>
                  <button onClick={() => setStartWorkShow(true)}>
                    <AddIcon /> Start Work
                  </button>
                  <StartWorkModal
                    show={startWorkShow}
                    onHide={() => setStartWorkShow(false)}
                  />
                </li>
              )}
            {!ticketSummary?.workOrderSummary?.acceptOptionOnly &&
              claims("Can_Hold_Ticket") && (
                <li>
                  <button onClick={() => setHoldShow(true)}>
                    <AddIcon /> Hold Work
                  </button>
                  <HoldModal
                    show={holdShow}
                    onHide={() => setHoldShow(false)}
                    ticketId={ticketId}
                  />
                </li>
              )}
            {!ticketSummary?.workOrderSummary?.acceptOptionOnly &&
              claims("Can_Approve_HoldTicket") && (
                <li>
                  <button onClick={() => setAcceptHoldShow(true)}>
                    <AddIcon /> Confirm Hold Work
                  </button>
                  <AcceptHoldModal
                    show={acceptHoldShow}
                    onHide={() => setAcceptHoldShow(false)}
                    ticketId={ticketId}
                  />
                </li>
              )}
            {!ticketSummary?.workOrderSummary?.acceptOptionOnly &&
              claims("Can_Resume_HeldTicket") && (
                <li>
                  <button onClick={() => setResumeShow(true)}>
                    <AddIcon /> Resume Work
                  </button>
                  <ResumeModal
                    show={resumeShow}
                    onHide={() => setResumeShow(false)}
                    ticketId={ticketId}
                  />
                </li>
              )}
            {!ticketSummary?.workOrderSummary?.acceptOptionOnly &&
              claims("Can_Request_JobClosure") && (
                <li>
                  <button onClick={() => setRequestClosureShow(true)}>
                    <AddIcon /> Request Closure
                  </button>
                  <RequestClosureModal
                    show={requestClosureShow}
                    onHide={() => setRequestClosureShow(false)}
                  />
                </li>
              )}
            {!ticketSummary?.workOrderSummary?.acceptOptionOnly &&
              claims("Can_Confirm_WorkDone") && (
                <li>
                  <button onClick={() => setConfirmWorkShow(true)}>
                    <AddIcon /> Confirm Work
                  </button>
                  <ConfirmWorkModal onHide={() => setConfirmWorkShow(false)} />
                </li>
              )}
            {/*
            <li>
              <button onClick={() => setCloseWorkShow(true)}>
                <AddIcon /> Close Work
              </button>
              <CloseWorkModal
                show={closeWorkShow}
                onHide={() => setCloseWorkShow(false)}
              />
            </li>
            */}
            {/* <li>
              <button onClick={() => setImageShow(true)}>
                <AddIcon /> Image
              </button>
              <ViewImage show={imageShow} onHide={() => setImageShow(false)} />
            </li>
            <li>
              <button onClick={() => setVideoShow(true)}>
                <AddIcon /> Video
              </button>
              <ViewVideo show={videoShow} onHide={() => setVideoShow(false)} />
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ViewPmHeader;
