import React, { useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import EditIcon from "../../../Assets/Icons/EditIcon";
import DeleteIcon from "../../../Assets/Icons/DeleteIcon";

const PartInformation = () => {
  // Projected
  const [quantity, setQuantity] = useState(3);
  const [parts, setParts] = useState("Part A");
  const [amount, setAmount] = useState("@332,000");
  const [isEditPojectedPard, setIsPojectedPartEdit] = useState(false);
  return (
    <>
      <Tab.Container id="PartInformation-table" defaultActiveKey="second">
        <Nav variant="pills">
          <Nav.Item>
            <Nav.Link eventKey="first">PROJECTED</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="second">REQUESTED</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="thead">RECEIVED</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="fore">USED</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="five">RETURNED</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="first">
            <div className="table-responsive-sm request_table">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Parts</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Amount(Ksh)</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="text"
                        className={
                          isEditPojectedPard
                            ? "border edit-input"
                            : "edit-input"
                        }
                        value={parts}
                        readOnly={!isEditPojectedPard}
                        onChange={(e) => setParts(e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={quantity}
                        className={
                          isEditPojectedPard
                            ? "border edit-input"
                            : "edit-input"
                        }
                        max={100}
                        readOnly={!isEditPojectedPard}
                        onChange={(e) => setQuantity(e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className={
                          isEditPojectedPard
                            ? "border edit-input"
                            : "edit-input"
                        }
                        value={amount}
                        readOnly={!isEditPojectedPard}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </td>
                    <td>
                      <div className="button-con">
                        <button
                          onClick={() =>
                            setIsPojectedPartEdit(!isEditPojectedPard)
                          }
                        >
                          <EditIcon />
                        </button>
                        <button>
                          <DeleteIcon />
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="second">
            <div className="table-responsive-sm request_table">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Parts</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Amount(Ksh)</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="text"
                        className={
                          isEditPojectedPard
                            ? "border edit-input"
                            : "edit-input"
                        }
                        value={parts}
                        readOnly={!isEditPojectedPard}
                        onChange={(e) => setParts(e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={quantity}
                        className={
                          isEditPojectedPard
                            ? "border edit-input"
                            : "edit-input"
                        }
                        max={100}
                        readOnly={!isEditPojectedPard}
                        onChange={(e) => setQuantity(e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className={
                          isEditPojectedPard
                            ? "border edit-input"
                            : "edit-input"
                        }
                        value={amount}
                        readOnly={!isEditPojectedPard}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </td>
                    <td>
                      <div className="button-con">
                        <button
                          onClick={() =>
                            setIsPojectedPartEdit(!isEditPojectedPard)
                          }
                        >
                          <EditIcon />
                        </button>
                        <button>
                          <DeleteIcon />
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="thead">
            <div className="table-responsive-sm request_table">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Parts</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Amount(Ksh)</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="text"
                        className={
                          isEditPojectedPard
                            ? "border edit-input"
                            : "edit-input"
                        }
                        value={parts}
                        readOnly={!isEditPojectedPard}
                        onChange={(e) => setParts(e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={quantity}
                        className={
                          isEditPojectedPard
                            ? "border edit-input"
                            : "edit-input"
                        }
                        max={100}
                        readOnly={!isEditPojectedPard}
                        onChange={(e) => setQuantity(e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className={
                          isEditPojectedPard
                            ? "border edit-input"
                            : "edit-input"
                        }
                        value={amount}
                        readOnly={!isEditPojectedPard}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </td>
                    <td>
                      <div className="button-con">
                        <button
                          onClick={() =>
                            setIsPojectedPartEdit(!isEditPojectedPard)
                          }
                        >
                          <EditIcon />
                        </button>
                        <button>
                          <DeleteIcon />
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="fore">
            <div className="table-responsive-sm request_table">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Parts</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Amount(Ksh)</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="text"
                        className={
                          isEditPojectedPard
                            ? "border edit-input"
                            : "edit-input"
                        }
                        value={parts}
                        readOnly={!isEditPojectedPard}
                        onChange={(e) => setParts(e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={quantity}
                        className={
                          isEditPojectedPard
                            ? "border edit-input"
                            : "edit-input"
                        }
                        max={100}
                        readOnly={!isEditPojectedPard}
                        onChange={(e) => setQuantity(e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className={
                          isEditPojectedPard
                            ? "border edit-input"
                            : "edit-input"
                        }
                        value={amount}
                        readOnly={!isEditPojectedPard}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </td>
                    <td>
                      <div className="button-con">
                        <button
                          onClick={() =>
                            setIsPojectedPartEdit(!isEditPojectedPard)
                          }
                        >
                          <EditIcon />
                        </button>
                        <button>
                          <DeleteIcon />
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="five">
            <div className="table-responsive-sm request_table">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Parts</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Amount(Ksh)</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="text"
                        className={
                          isEditPojectedPard
                            ? "border edit-input"
                            : "edit-input"
                        }
                        value={parts}
                        readOnly={!isEditPojectedPard}
                        onChange={(e) => setParts(e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={quantity}
                        className={
                          isEditPojectedPard
                            ? "border edit-input"
                            : "edit-input"
                        }
                        max={100}
                        readOnly={!isEditPojectedPard}
                        onChange={(e) => setQuantity(e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className={
                          isEditPojectedPard
                            ? "border edit-input"
                            : "edit-input"
                        }
                        value={amount}
                        readOnly={!isEditPojectedPard}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </td>
                    <td>
                      <div className="button-con">
                        <button
                          onClick={() =>
                            setIsPojectedPartEdit(!isEditPojectedPard)
                          }
                        >
                          <EditIcon />
                        </button>
                        <button>
                          <DeleteIcon />
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default PartInformation;
