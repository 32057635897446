import React, { useEffect, useState } from "react";
import RoutineInspectionCategoryContext from "./RoutineInspectionCategoryContext";
import InspectionDetailContext from "./InspectionDetailsContext";

const RoutineInspectionCategoryContextProvider = ({ children }) => {
  const [routineInspectionCategoryData, setRoutineInspectionCategoryData] =
    useState({});
  // const data = JSON.parse(window.localStorage.getItem("inspectionDetailData"));
  const [inspectionDetailData, setInspectionDetailData] = useState({});
  const [ongoingInspection, setOngoingInspection] = useState({});
  const [categoryId, setCategoryId] = useState();
  const [startDatez, setStartDate] = useState();
  const [endsDatez, setEndsDate] = useState();
  const [completedInspectionsData, setCompletedInspectionsData] = useState([]);
  const [selectedInfoz, setSelectedInfoz] = useState({})

  // ...
  
  const setCompletedInspections= (results)=>{
    setCompletedInspectionsData(results);
  }
  useEffect(() => {
    const storedStartDateData = window.localStorage.getItem("startDate");
    setStartDate(storedStartDateData ? new Date(storedStartDateData) : null);

    const storedEndsDateData = window.localStorage.getItem("endsDate");
    setEndsDate(storedEndsDateData ? new Date(storedEndsDateData) : null);

    // ...
  }, []);

  useEffect(() => {
    try {
      const storedInspectionData = JSON.parse(
        window.localStorage.getItem("inspectionDetailData")
      );

      if (storedInspectionData) {
        setInspectionDetailData(storedInspectionData);
      }
    } catch (error) {
      console.error(
        "Error parsing inspectionDetailData from local storage:",
        error
      );
    }
  }, []);


  useEffect(() => {
    try {
      const storedOngoingInspection = JSON.parse(
        window.localStorage.getItem("ongoingInspection")
      );

      if (storedOngoingInspection) {
        setOngoingInspection(storedOngoingInspection);
      }
    } catch (error) {
      console.error(
        "Error parsing ongoingInspection from local storage:",
        error
      );
    }
  }, []);

  // ...

  useEffect(() => {
    try {
      const storedCategoryId = JSON.parse(
        window.localStorage.getItem("categoryId")
      );

      if (storedCategoryId) {
        setCategoryId(storedCategoryId);
      }
    } catch (error) {
      console.error("Error parsing categoryId from local storage:", error);
    }
  }, []);

  const setCategoryData = (item) => {
    setRoutineInspectionCategoryData(item);
  };
  const setInspectionData = (item) => {
    setInspectionDetailData(item);

    window.localStorage.setItem("inspectionDetailData", JSON.stringify(item));
  };
  const setContextOngoingInspection = (item) => {
    setOngoingInspection(item);

    window.localStorage.setItem("ongoingInspection", JSON.stringify(item));
  };

  const setCategoryIdData = (item) => {
    setCategoryId(item);

    window.localStorage.setItem("categoryId", JSON.stringify(item));
  };

  return (
    <RoutineInspectionCategoryContext.Provider
      value={{
        routineInspectionCategoryData,
        setCategoryData,
        inspectionDetailData,
        selectedInfoz,
        setSelectedInfoz,
        setInspectionData,
        setContextOngoingInspection,
        completedInspectionsData,
        setCompletedInspections,
        ongoingInspection,
        categoryId,
        setCategoryIdData,
        startDatez,
        endsDatez,
      }}
    >
      {children}
    </RoutineInspectionCategoryContext.Provider>
  );
};

export default RoutineInspectionCategoryContextProvider;
