import React, { useContext, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import ActivityModal from "../Modal/ActivityModal";
import ViewWorkOrderModal from "../../WorkOrders/Modal/ViewWorkOrderModal";
import { RequestDetailsContext } from "../../../Context/Inspections/WorkOrderContext";
import usefetchData from "../../../hooks/useFetchData";
import CompleteRequestModal from "../../WorkOrders/Modal/CompleteRequestModal";

const ViewRequestSide = () => {
  const [activityShow, setActivityShow] = React.useState(false);
  const [modalShow, setModalShow] = React.useState(false); // State for the modal
  const [eventKey, setEventKey] = React.useState("");
  const location = useLocation();
  const { id } = useParams();
  const { request, setRequest } = useContext(RequestDetailsContext);

  useEffect(() => {
    if (location.pathname === `/requests/view-request/${id}`) {
      setEventKey("0");
    } else if (location.pathname === `/requests/view-request/${id}/diagnosis`) {
      setEventKey("1");
    } else if (location.pathname === `/requests/view-request/${id}/parts`) {
      setEventKey("2");
    } else if (location.pathname === `/requests/view-request/${id}/quotes`) {
      setEventKey("3");
    }
  }, [location]);

  const claims = (claimValue) => {
    if (localStorage.getItem("claims")) {
      const claimsArray = JSON.parse(localStorage.getItem("claims"));
      return claimsArray.includes(claimValue);
    }
    return false;
  };

  const { data, isLoading } = usefetchData(
    ["request-data", parseInt(id)],
    `Requests/RequestDetails/${parseInt(id)}`,
    {},
    "Couldn't get",
    true
  );

  const isDataValid = !!(
    data?.data?.requestDetails && 
    data?.data?.location && data?.data?.location?.id &&
    data?.data?.asset && data?.data?.asset?.id
  );

  // Function to handle clicks on disabled accordion items
  const handleDisabledClick = () => {
    if (!isDataValid) {
      setModalShow(true); // Show modal if the item is disabled
    }
  };

  return (
    eventKey && (
      <>
        <div className="order-sidebar-content p-0">
          <div className="p-4">
            <div className="fs-13 fw-medium">REQUEST INFORMATION</div>
            <div className="washroom-sidebar mt-3 align-items-end">
              <div>
                <div
                  className="fs-16 fw-bold pb-2"
                  style={{ color: "#6C5B51" }}
                >
                  Request Reference
                </div>
                <div className="fs-40 fw-bold" style={{ color: "#D57D2A" }}>
                  {request?.requestSummaryDetailDto?.requestRef}
                </div>
              </div>
              <div style={{ textAlign: "right" }}>
                {request?.requestSummaryDetailDto?.workOrderId ? (
                  <Link
                    to={`/work-orders/work-view/${request?.requestSummaryDetailDto?.workOrderId}`}
                    className="fs-16 fw-bold"
                    style={{ color: "#D57D2A", textDecoration: "underline" }}
                  >
                    View Work Order
                  </Link>
                ) : null}

                <ViewWorkOrderModal
                  show={activityShow}
                  onHide={() => setActivityShow(false)}
                />
              </div>
            </div>
          </div>

          <Accordion defaultActiveKey={eventKey} className="view-work-sidebar">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <NavLink to={`/requests/view-request/${id}`} end>
                  Request Summary
                </NavLink>
              </Accordion.Header>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header
                onClick={!isDataValid ? handleDisabledClick : undefined} // Trigger modal if disabled
              >
                {isDataValid ? (
                  <NavLink to={`/requests/view-request/${id}/diagnosis`}>
                    Diagnosis
                  </NavLink>
                ) : (
                  <NavLink to={`#`} className="disabled-text">Diagnosis</NavLink> // Display text if disabled
                )}
              </Accordion.Header>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header
                onClick={!isDataValid ? handleDisabledClick : undefined} // Trigger modal if disabled
              >
                {isDataValid ? (
                  <NavLink to={`/requests/view-request/${id}/parts`}>
                    Parts
                  </NavLink>
                ) : (
                  <NavLink to={`#`} className="disabled-text">Parts</NavLink> // Display text if disabled
                )}
              </Accordion.Header>
            </Accordion.Item>
            {claims("Can_View_Quotes") && (
              <Accordion.Item eventKey="3">
                <Accordion.Header
                  onClick={!isDataValid ? handleDisabledClick : undefined} // Trigger modal if disabled
                >
                  {isDataValid ? (
                    <NavLink to={`/requests/view-request/${id}/quotes`}>
                      Quotes
                    </NavLink>
                  ) : (
                    <NavLink to={`#`} className="disabled-text">Quotes</NavLink> // Display text if disabled
                  )}
                </Accordion.Header>
              </Accordion.Item>
            )}
          </Accordion>

          {/* Modal to be displayed when clicking disabled items */}
          <CompleteRequestModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            title="Action Not Allowed"
            message="You cannot access this section until all necessary information is provided."
          />
        </div>
      </>
    )
  );
};

export default ViewRequestSide;
