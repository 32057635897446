import React from "react";
import { Link, Outlet } from "react-router-dom"; 
import ViewWorkCostsHeader from "../Components/Work-Costs/ViewRequests/ViewWorkCostsHeader";
import ViewWorkCostsSide from "../Components/Work-Costs/ViewRequests/ViewWorkCostsSide";

const ViewWorkCosts = () => {
  return (
    <div className="work-orders-area f3washroom-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <ViewWorkCostsHeader />
          </div>
          <div className="col-lg-12">
            <div className="other-nav">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/work-costs">Work Cost</Link>
                </li>
                <li>
                  <Link>View Costing</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="order-request-area view-order-content">
              <ViewWorkCostsSide />
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewWorkCosts;
