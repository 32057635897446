import React from "react";
import "../Components/Team/Team.scss"; 
import TeamNavbar from "../Components/Team/TeamNavbar";
import TeamHeader from "../Components/Team/TeamHeader";
import TeamContent from "../Components/Team/TeamContent";

const Team = () => {
  return (
    <div className="work-orders-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <TeamHeader />
          </div>
          <div className="col-lg-12">
            <TeamNavbar />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="team-content">
              <TeamContent />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
