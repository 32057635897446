import React, { useState, useEffect, useRef } from "react";
import usefetchData from "../../../hooks/useFetchData";
import { useParams } from "react-router-dom";
import EditLocationModal from "../../WorkOrders/Modal/EditLocationModal";
import mapboxgl from "mapbox-gl";  // Importing Mapbox GL
import 'mapbox-gl/dist/mapbox-gl.css';  // Importing Mapbox GL CSS

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API;  // Replace with your Mapbox access token

const ViewLocationSummary = () => {
  const [basicInfoShow, setBasicInfoShow] = useState(false);
  const [locationData, setLocationData] = useState(null);  // Local state to store location data for the modal
  const { id } = useParams();
  const { data, isLoading } = usefetchData(
    ["location-summary", parseInt(id)],
    `Locations/GetLocationSummaryById/${id}`,
    {},
    "Error getting location data",
    true
  );

  const mapContainer = useRef(null);  // Reference for the map container
  const map = useRef(null);  // Reference for the map instance
  const [lng, setLng] = useState(0);  // Longitude state
  const [lat, setLat] = useState(0);  // Latitude state
  const [zoom, setZoom] = useState(12);  // Zoom level state

  // Initialize map and marker
  useEffect(() => {
    if (!data || !data.locationSummary) return; // Ensure data is loaded

    const longitude = parseFloat(data?.locationSummary?.longitude);
    const latitude = parseFloat(data?.locationSummary?.latitude);

    // Check if longitude and latitude are valid numbers
    if (
      isNaN(longitude) ||
      isNaN(latitude) ||
      longitude < -180 ||
      longitude > 180 ||
      latitude < -90 ||
      latitude > 90
    ) {
      console.warn("Invalid coordinates provided:", { longitude, latitude });
      return; // Skip initialization if coordinates are invalid
    }

    // Set the coordinates from the API data
    setLng(longitude);
    setLat(latitude);
    setLocationData(data?.locationSummary); // Update local state with location data

    // Initialize the map only once
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11", // Map style
      center: [longitude, latitude],
      zoom: zoom,
    });

    // Add navigation control (the +/- zoom buttons)
    map.current.addControl(new mapboxgl.NavigationControl(), "top-right");

    // Add marker to the map
    new mapboxgl.Marker({ color: "red" })
      .setLngLat([longitude, latitude])
      .addTo(map.current);

    // Clean up on unmount
    return () => map.current.remove();
  }, [data, zoom]); // Re-run if data or zoom changes

  return (
    <>
      <div className="order-request washroom-right-content responses-cont">
        <div className="washroom-title">
          <div className="fs-13 fw-medium">LOCATION SUMMARY</div>
        </div>
        <div className="fs-13 fw-semibold pt-5 gray-c d-flex flex-wrap justify-content-between">
          LOCATION INFO
          <button
            onClick={() => setBasicInfoShow(true)}
            style={{
              fontWeight: "500",
              fontSize: "14px",
              color: "#D57D2A",
              borderBottom: "1px solid #D57D2A",
            }}
          >
            Edit Info
          </button>
        </div>
        <hr style={{ margin: "10px 0 10px", borderColor: "#D0D0D0" }} />

        <div className="row row-gap-4 pt-3">
          {/* Location Information Display */}
          <div className="col-md-4">
            <div className="fs-14 fw-semibold pb-2" style={{ color: "#00000061" }}>Location Name</div>
            <div className="fs-14 fw-medium" style={{ lineHeight: "1.3" }}>
              {data?.locationSummary?.locationName}
            </div>
          </div>
          <div className="col-md-4">
            <div className="fs-14 fw-semibold pb-2" style={{ color: "#00000061" }}>Location Code</div>
            <div className="fs-14 fw-medium">
              {data?.locationSummary?.locationCode ?? "N/A"}
            </div>
          </div>
          <div className="col-md-4">
            <div className="fs-14 fw-semibold pb-2" style={{ color: "#00000061" }}>Location Grouping</div>
            <div className="fs-14 fw-medium whitespace-wrap">
              {data?.locationSummary?.regionName ?? "Not Assigned"}
            </div>
          </div>
          <div className="col-md-12">
            <div className="fs-14 fw-semibold pb-2" style={{ color: "#00000061" }}>Address</div>
            <div className="fs-14 fw-medium" style={{ lineHeight: "1.3" }}>
              {data?.locationSummary?.locationAddress}
            </div>
          </div>
          <div className="col-md-4">
            <div className="fs-14 fw-semibold pb-2" style={{ color: "#00000061" }}>Parent Location</div>
            <div className="fs-14 fw-medium">
              {data?.locationSummary?.parentLocationName ?? "NONE"}
            </div>
          </div>
          <div className="col-md-4">
            <div className="fs-14 fw-semibold pb-2" style={{ color: "#00000061" }}>Map Coordinates - longitude</div>
            <div className="fs-14 fw-medium">
              {data?.locationSummary?.longitude ?? "N/A"}
            </div>
          </div>
          <div className="col-md-4">
            <div className="fs-14 fw-semibold pb-2" style={{ color: "#00000061" }}>Map Coordinates - latitude</div>
            <div className="fs-14 fw-medium">
              {data?.locationSummary?.latitude ?? "N/A"}
            </div>
          </div>
          <div className="col-md-4">
            <div className="fs-14 fw-semibold pb-2" style={{ color: "#00000061" }}>Person in charge of location</div>
            <div className="fs-14 fw-medium">
              {data?.locationSummary?.personInChargeName ?? "NONE"}
            </div>
          </div>
          <div className="col-md-4">
            <div className="fs-14 fw-semibold pb-2" style={{ color: "#00000061" }}>Location Contact person</div>
            <div className="fs-14 fw-medium">
              {data?.locationSummary?.contactPersonName || "NONE"}
            </div>
          </div>
        </div>

        {/* Map Component */}
        <div className="order-request mt-4 p-0">
          <div ref={mapContainer} style={{ width: "100%", height: "400px", borderRadius: "8px" }} />
        </div>
      </div>

      {/* Edit Location Modal */}
      <EditLocationModal
        show={basicInfoShow}
        onHide={() => setBasicInfoShow(false)}
        data={locationData}  // Pass the local state data
      />
    </>
  );
};

export default ViewLocationSummary;