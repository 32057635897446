import React from "react";
import { Link } from "react-router-dom";

const CreateNavbar = ({item}) => {
  return (
    <div className="other-nav">
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        {/* <li>
          <Link to="" className="text-black">
            Inspection Categories
          </Link>
        </li> */}
        <li>
          <Link to="#"> {item ===null ? "Create":"Edit" }  Inspection Category</Link>
        </li>
      </ul>
    </div>
  );
};

export default CreateNavbar;
