import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import useMutateData from '../../../hooks/useMutateData';

const AddAssetCategoryModal = (props) => {
  const [assetCategoryName, setAssetCategoryName] = useState('');
  const [assetCategoryDescription, setAssetCategoryDescription] = useState('');

  const { mutate, isLoading: isSubmitting } = useMutateData({
    url: '/Assets/AddAssetCategory',
    method: 'POST',
    onSuccessfullMutation: (data) => {
      if (data?.data?.succeeded === true) {
        toast.success('Asset category added successfully', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        props.onHide();
      } else {
        toast.error('Failed to add asset category', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
    successMessage: 'Asset category added successfully',
    errorMessage: 'Failed to add asset category',
    queryKeysToInvalidate: [["asset-category", ""]]
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      assetCategoryName,
      assetCategoryDescription,
    };
    mutate(payload);
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: '28px 30px' }}>
        <form onSubmit={handleSubmit}>
          <div className="delate-content">
            <div className="d-md-flex align-items-center gap-4">
              <span className="fs-16 fw-bold">Add Asset Category</span>
              <div className="fs-14 pt-2 pt-md-0" style={{ color: '#72777A' }}>
                Add a new asset category
              </div>
            </div>
            <div className="mt-4 pt-2 row row-gap-4 modal-forms-content">
              <div className="col-md-12">
                <label className="fw-medium fs-14 pb-2">Asset Category</label>
                <input
                  className="modal-input-box"
                  type="text"
                  style={{
                    background: '#F1EFEF',
                    width: '100%',
                    border: '0',
                    height: '50px',
                    borderRadius: '5px',
                    padding: '0 15px',
                    fontSize: '14px',
                  }}
                  placeholder="Enter Asset Category"
                  value={assetCategoryName}
                  onChange={(e) => setAssetCategoryName(e.target.value)}
                />
              </div>
              <div className="col-md-12">
                <label className="fw-medium fs-14 pb-2">Description</label>
                <textarea
                  className="modal-input-box"
                  type="text"
                  style={{
                    background: '#F1EFEF',
                    width: '100%',
                    border: '0',
                    height: '140px',
                    borderRadius: '5px',
                    padding: '15px 15px',
                    fontSize: '14px',
                  }}
                  placeholder="Enter asset category description"
                  value={assetCategoryDescription}
                  onChange={(e) => setAssetCategoryDescription(e.target.value)}
                />
              </div>
            </div>

            <div
              className="button-group"
              style={{
                marginTop: '25px',
                display: 'flex',
                justifyContent: 'end',
                gap: '30px',
              }}
            >
              <button type="button" className="cancel-btn" onClick={props.onHide}>
                Cancel
              </button>
              <button type="submit" className="delate-btn" disabled={isSubmitting}>
                {isSubmitting ? 'Adding...' : 'Add'}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddAssetCategoryModal;
