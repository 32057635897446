import React, { useState } from "react";
import DeleteModal from "../../Modal/DeleteModal";
import DeleteIcon from "../../../../Assets/Icons/DeleteIcon";
import AddItemCostsModal from "../../Modal/AddItemCostsModal";
import ConfirmSubmitModal from "../../Modal/ConfirmSubmitModal";
import RecallCostsModal from "../../Modal/RecallCostsModal";
import usefetchData from "../../../../hooks/useFetchData";
import useMutateData from "../../../../hooks/useMutateData";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AddManualItemCostModal from "../../Modal/AddManualItemCostModal";

const Manual = ({ ticketSummary }) => {
  //currency
  const company = localStorage.getItem("company");
  const companyObj = company && JSON.parse(company);
  const currency = companyObj.currency;

  const { id } = useParams();
  const ticketId = Number(id);

  const basicGroupId =
    ticketSummary?.costingSummary?.manualCostingBasis?.costingBasisGroupId;

  const agreedRates =
    ticketSummary?.costingSummary?.manualCostingBasis?.manualItems;

  const locationId = ticketSummary?.workOrderSummary?.locationId;
  const categoryId = ticketSummary?.workOrderSummary?.categoryOfWorkId;

  const [showNav, setShowNav] = useState(true);
  const { data: pricelist, isLoading: isLoadingPricelist } = usefetchData(
    ["price-list", categoryId],
    `Tickets/GetPriceListItemsByCategory/${categoryId}`,
    {},
    "Couldn't get",
    !!categoryId
  );

  const { data: costData, isLoading: isLoadingcostData } = usefetchData(
    ["items-type", categoryId],
    `TicketCostings/GetAllTicketCostItemsTypes`,
    {},
    "Couldn't get",
    true
  );

  const { data: mileage, isLoading: isLoadingMileage } = usefetchData(
    ["category-id", categoryId],
    `Tickets/GetMileageByLocationCategory`,
    { LocationId: locationId, CategoryId: categoryId },
    "Couldn't get  mileage data",
    !!locationId && !!categoryId
  );

  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [addItemCostModalShow, setAddItemCostModalShow] = useState(false);
  const [submitCostsModalShow, setSubmitCostsModalShow] = useState(false);
  const [confirmSubmitModalShow, setConfirmSubmitModalShow] = useState(false);
  const [recallCostsModalShow, setRecallCostsModalShow] = useState(false);
  const [showSubmitCostsContent, setShowSubmitCostsContent] = useState(true);
  const [itemId, setItemId] = useState(null);

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength - 3) + "...";
  };

  // submit constings
  const { mutate, isLoading: isSubmitting } = useMutateData({
    url: `Tickets/SubmitTicketCostedItems`,
    method: "POST",
    onSuccessfullMutation: (data) => {
      if (data?.data?.succeeded === true) {
        toast.success(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setConfirmSubmitModalShow(false);
        setShowSubmitCostsContent(false);
        setShowNav(false);
        // Hide the nav bar
      } else {
        toast.error(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
    successMessage: `Successfully submitted costing`,
    errorMessage: "Work order creation failed",
    queryKeysToInvalidate: [["ticket-summary", ticketId]],
  });

  // recall constings
  const { mutate: recall, isLoading: isRecalling } = useMutateData({
    url: `Tickets/RecallTicketCostedItems`,
    method: "POST",
    onSuccessfullMutation: (data) => {
      console.log("Recall mutation data", data);
      if (data?.data?.succeeded === true) {
        toast.success(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setRecallCostsModalShow(false);
        setShowSubmitCostsContent(true);
        setShowNav(true); // Show the nav bar
      } else {
        toast.error(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
    successMessage: `Successfully recalled costing`,
    errorMessage: "Work order creation failed",
    queryKeysToInvalidate: [
      ["price-list", categoryId],
      ["ticket-summary", ticketId],
    ],
  });

  // delete cost entry
  const { mutate: deleteItem, isLoading: isDeleting } = useMutateData({
    url: `Tickets/DeleteTicketCostedItemById`,
    method: "POST",
    onSuccessfullMutation: (data) => {
      console.log("Delete mutation data", data?.data?.succeeded);
      if (data?.data?.succeeded === true) {
        toast.success(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setDeleteModalShow(false);
        setShowSubmitCostsContent(true);
        setShowNav(true); // Show the nav bar
      } else {
        toast.error(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
    successMessage: `Successfully created a work order`,
    errorMessage: "Work order creation failed",
    queryKeysToInvalidate: [["ticket-summary", ticketId]],
  });

  const onDeleteCostItem = () => {
    const requestBody = {
      id: itemId,
    };
    deleteItem(requestBody);
  };

  const onSubmitCostings = () => {
    const requestBody = {
      costingBasisGroupId: basicGroupId,
      ticketId: ticketId,
    };
    mutate(requestBody);
  };

  const onRecallCostings = () => {
    const requestBody = {
      ticketId: ticketId,
      costingBasisGroupId: basicGroupId,
    };
    recall(requestBody);
  };

  // Function to calculate the total cost
  const calculateTotalCost = () => {
    return agreedRates?.reduce((total, part) => {
      return total + part.quantity * part.unitCost;
    }, 0);
  };

  // Calculate the total cost
  const totalCost = calculateTotalCost();

  return (
    <>
      <>
        <div className="d-flex align-items-center justify-content-between gap-3 pt-3 pb-3">
          <div className=""></div>
          {ticketSummary?.costingSummary?.manualCostingBasis
            ?.approvalLevelStateName === "Not Approved" &&
            (ticketSummary?.costingSummary?.manualCostingBasis?.isSubmitted ? (
              <button
                className="fs-15 fw-bold"
                style={{ color: "#D57D2A" }}
                onClick={() => setRecallCostsModalShow(true)}
              >
                RECALL COST
              </button>
            ) : (
              <button
                className="fs-15 fw-bold"
                style={{ color: "#D57D2A" }}
                onClick={() => setSubmitCostsModalShow(true)}
              >
                SUBMIT COST
              </button>
            ))}
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="fs-15 fw-medium black-38">ITEM COSTING</div>
          {!ticketSummary?.costingSummary?.manualCostingBasis?.isSubmitted && (
            <button
              className="add-button my-2"
              style={{ fontSize: "15px", fontWeight: "500" }}
              onClick={() => setAddItemCostModalShow(true)}
            >
              Add Cost
            </button>
          )}
        </div>
        <div
          className="table-responsive-sm request_table"
          style={{ maxWidth: "100%" }}
        >
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Item</th>
                <th scope="col">Quantity</th>
                <th scope="col">Unit Cost</th>
                <th scope="col">Total Cost</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {agreedRates?.map((item) => (
                <tr key={item.id}>
                  <td className="hover-text" style={{ width: "310px" }}>
                    <div>
                      {truncateText(item.description, 38)}
                      <span class="tooltiptext">{item.description}</span>
                    </div>
                  </td>
                  <td>{item.quantity}</td>
                  <td>
                    {currency} {item.unitCost}
                  </td>
                  <td>
                    {currency} {item.unitCost * item?.quantity}
                  </td>
                  <td>
                    {!ticketSummary?.costingSummary?.priceListCostingBasis
                      ?.isSubmitted && (
                      <div className="button-con">
                        <button
                          onClick={() => {
                            setItemId(item?.id);
                            setDeleteModalShow(true);
                          }}
                        >
                          <DeleteIcon />
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="d-flex align-items-center justify-content-between pt-2 pb-3">
          <div className="fs-20 fw-bold black-38">TOTAL COST</div>
          <div className="fs-20 fw-bold black-38">
            {currency} {totalCost}
          </div>
        </div>
      </>
      <DeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        onDelete={onDeleteCostItem}
      />
      <AddManualItemCostModal
        show={addItemCostModalShow}
        onHide={() => setAddItemCostModalShow(false)}
        partData={pricelist}
        costData={costData}
        currency={currency}
        ticketId={ticketId}
        mileage={mileage}
        categoryId={categoryId}
        locationId={locationId}
      />
      <ConfirmSubmitModal
        show={submitCostsModalShow}
        onHide={() => setSubmitCostsModalShow(false)}
        handleSubmitCosts={onSubmitCostings}
      />
      <RecallCostsModal
        show={recallCostsModalShow}
        onHide={() => setRecallCostsModalShow(false)}
        handleRecallCosts={onRecallCostings}
      />
    </>
  );
};
export default Manual;
