import React, { useState, useContext } from "react";
import ArrowLeft from "../../../../Assets/Icons/ArrowLeft";
import ArrowRight from "../../../../Assets/Icons/ArrowRight";
import { WorkOrderContext } from "../../../../Context/Inspections/WorkOrderContext";
 
import SectionOne from "./SectionOne";


const FileChecklist = ({ checkListData, toggleChecklist }) => {
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);
  const [currentPage, setCurrentPage] = useState(1);
  const totalSections = checkListData.checkListSections?.length;

  const handleNextClick = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalSections));
  };

  const handleBackClick = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  const mainNextClick = () => {
    toggleChecklist();
  };
  return (
    <div className="view-checklist-box checklist-view-work">
      <div className="order-request p-0">
        <div className="response-checklist">
          <div className="fs-15 fw-bold" style={{ color: "#6C5B51" }}>
            {checkListData.checkListName}
          </div>
          <div className="response-pagination d-flex align-items-center">
            <div className="page-number fs-14">
              Page {currentPage} of {totalSections}
            </div>
            <div className="pagination-btn">
              <button
                className="back-btn"
                onClick={handleBackClick}
                disabled={currentPage === 1}
              >
                Back <ArrowLeft />
              </button>
              <button
                className="next-btn"
                onClick={handleNextClick}
                disabled={currentPage === totalSections}
              >
                <ArrowRight /> Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="response-list-content ">
        <SectionOne checkListData={checkListData} currentPage={currentPage} toggleChecklist={mainNextClick}/>
      </div>
    </div>
  );
};

export default FileChecklist;
