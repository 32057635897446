import React from "react";
import DownIcon from "../../Assets/Icons/DownIcon";
import { Link } from "react-router-dom";
import AddIcon from "../../Assets/Icons/AddIcon";
import exportToExcel from "../../utls/exportToExcel";
import exportToExcelMain from "../../utls/exportToExecelMain";

const WorkScheduleHeader = ({ workTable }) => {
  const value = { value: 0 };
  return (
    <div className="work-header">
      <div className="fs-20">Work Schedule Templates</div>
      <div className="dropdown select-dropdown">
        <button className="select-title" data-bs-toggle="dropdown">
          <span className="fs-15 d-flex align-items-center gap-2">
            Actions <DownIcon />
          </span>
        </button>
        <ul className="dropdown-menu dropdown-menu-end select-menu">
          <li>
            <Link to="/work-schedule-templates/create-template" state={value}>
              <AddIcon /> New Schedule
            </Link>
          </li>
          <li
            onClick={() => exportToExcelMain(workTable, "work-schedule-templates")}
          >
            <Link to="#">
              <AddIcon /> Export to Excel
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default WorkScheduleHeader;
