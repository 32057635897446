import React, {useState, useEffect} from "react";
import { Link, Outlet } from "react-router-dom";
import ViewDiagnosisQuotesHeader from "../Components/Diagnosis-Quotes/ViewRequests/ViewHeader";
import ViewDiagnosisQuotesSidebar from "../Components/Diagnosis-Quotes/ViewRequests/ViewWorkSide";
import { DiagnosisDetailsContext } from "../Context/Inspections/WorkOrderContext";
import { useParams } from "react-router-dom";
import { GET_DIAGNOSIS_SUMMARY_BY_ID } from "../utls/constants";
import { get } from "../Services/ApiHelper";

const DiagnosisSummaryDto = () => ({
  requestDetailSummary: {},
  requestDiagnosisSummary: [],
  requestPartsSummary: [],
  requestQuoteSummary: {},
});

const ViewDiagnosisQuotes = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [diagnosisSummary, setDiagnosisSummary] = useState(DiagnosisSummaryDto());
  useEffect(() => {
    setIsLoading(true)
    get(GET_DIAGNOSIS_SUMMARY_BY_ID(id), localStorage.getItem("bearerToken"))
      .then((data) => {
        // Populate the DiagnosisSummary ObjectsetIsLoading
        setIsLoading(false)
        setDiagnosisSummary({
          requestDetailSummary: data.requestSummaryDetail,
          requestDiagnosisSummary: data.requestDiagnosisList,
          requestPartsSummary: data.requestProjectedPartList,
          requestQuoteSummary: data.requestQuote,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);
  return (
    <div className="work-orders-area f3washroom-area">
      <DiagnosisDetailsContext.Provider
      value={{
        diagnosisSummary,
        setDiagnosisSummary,
        isLoading,
        setIsLoading
      }}
      >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <ViewDiagnosisQuotesHeader />
          </div>
          <div className="col-lg-12">
            <div className="other-nav">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/diagnosis-quotes">Diagnosis & Quotes</Link>
                </li>
                <li>
                  <Link>View Diagnosis & Quotes</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="order-request-area view-order-content">
              <ViewDiagnosisQuotesSidebar />
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      </DiagnosisDetailsContext.Provider>
    </div>
  );
};

export default ViewDiagnosisQuotes;
