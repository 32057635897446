import React from "react";
import "../Components/PumpCalibration/PumpCalibration.scss";
import AddPMHeader from "../Components/PumpCalibration/AddPMHeader";
import AddPmNavbar from "../Components/PumpCalibration/AddPmNavbar"; 
import PumpSidebar from "../Components/PumpCalibration/PumpSidebar";
import PumpContent from "../Components/PumpCalibration/PumpContent";

const PumpCalibration = () => {
  return (
    <div className="work-orders-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <AddPMHeader />
          </div>
          <div className="col-lg-12">
            <AddPmNavbar />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="order-request-area">
              <PumpSidebar />
              <PumpContent />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PumpCalibration;
