import React, {useEffect,useState} from "react";
import "../Components/InspectionCategory/InspectionCategory.scss";
import InspectionHeader from "../Components/InspectionCategory/InspectionHeader";
import InspectionNavbar from "../Components/InspectionCategory/InspectionNavbar";
import InspectionContent from "../Components/InspectionCategory/InspectionContent";
import { GET_INSPECTION_CATEGORIES } from "../utls/constants";
import { get } from "../Services/ApiHelper";
import { ChecklistService } from "../Services/ApiServiceCalls/checklistService";


const InspectionCategory = () => {
  
  const [isLoading, setIsLoading] = useState(true);
  const [inspectionData, setInspectionData] = useState([]);
  const token = localStorage.getItem("bearerToken");
  const {checklistService} = ChecklistService();
  const [report, setReport]= useState()
 
  useEffect(() => {
    get(GET_INSPECTION_CATEGORIES(1000,1,0,""),token).then((data) => {
      var result = data["data"];
      setReport(result);
    });

    get(GET_INSPECTION_CATEGORIES(10,1,0,""),token).then((data) => {
      var result = data["data"];
      setIsLoading(false);
      setInspectionData(result);
    });

  }, []);
  return (
    <div className="work-orders-area">
      <div className="container">
        
        <div className="row">
          <div className="col-lg-12">
            <InspectionHeader inspectionData={report} />
          </div>
          <div className="col-lg-12">
            <InspectionNavbar />
          </div>
        </div>
        {/* {isLoading && <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>} */}
        <div className="row">
          <div className="col-lg-12">
            <InspectionContent inspectionData={inspectionData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InspectionCategory;
