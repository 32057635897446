import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ProgressBar } from 'react-bootstrap';
import { FileUploader } from 'react-drag-drop-files';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setAssetPhoto } from '../../../redux/assetSlice';
import useMutateData from '../../../hooks/useMutateData';
import { fileToBase64 } from '../../../utls/convertToBase64';

const AssetPhotoModal = ({ mode, ...props }) => {
  const fileTypes = ['JPEG', 'JPG', 'PNG'];
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();
  const assetPhoto = useSelector((state) => state.asset.assetPhoto);

  const { mutate, isLoading: isSubmitting } = useMutateData({
    url: '/Assets/EditAssetPhoto',
    method: 'POST',
    onSuccessfullMutation: (data) => {
      if (data?.data?.succeeded === true) {
        toast.success('Photo uploaded successfully', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        props.onHide();
      } else {
        toast.error('Failed to upload photo', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
    successMessage: 'Photo uploaded successfully',
    errorMessage: 'Failed to upload photo',
    queryKeysToInvalidate: [["single-asset", parseInt(props.assetId)]],
  });

  const handleChange = (newFiles) => {
    const filesToAdd = Array.isArray(newFiles) ? newFiles : [newFiles];
    const filteredFiles = filesToAdd.filter((newFile) => {
      return !files.some(
        (existingFile) =>
          existingFile.name === newFile.name &&
          existingFile.size === newFile.size
      );
    });

    filteredFiles.forEach((file) => {
      const fileType = file.name.split('.').pop().toUpperCase();
      if (!fileTypes.includes(fileType)) {
        toast.error('Invalid file type');
        return;
      }
    });

    if (filteredFiles.some((file) => file.size > 2 * 1024 * 1024)) {
      toast.error('File size should not exceed 2MB');
      return;
    }

    filteredFiles.forEach((file) => {
      file.status = 'Uploading...';
      file.uploading = 50;
    });

    setFiles([...filteredFiles]);
  };

  useEffect(() => {
    const processFiles = async () => {
      const newFiles = await Promise.all(
        files.map(async (file) => {
          if (file.status === 'Uploading...') {
            const encodedFile = await fileToBase64(file);
            const fileExtension = file.name.split('.').pop().toLowerCase();
            file.status = 'Completed';
            file.uploading = 100;

            if (mode === 'store') {
              dispatch(
                setAssetPhoto({
                  encodedFile,
                  fileName: file.name,
                  url: URL.createObjectURL(file),
                  fileType: file.type,
                })
              );
            } else if (mode === 'upload') {
              const payload = {
                assetId: Number(props.assetId),
                assetPhoto: {encodedFile,
                fileName: file.name,
                fileType: fileExtension,}
              };
              mutate(payload);
            }
          }
          return file;
        })
      );
      setFiles(newFiles);
    };

    const timer = setTimeout(() => {
      processFiles();
    }, 2000);
    return () => clearTimeout(timer);
  }, [files, dispatch, mutate, mode, props.assetId]);

  const truncateFileName = (fileName, maxLength) => {
    if (fileName.length > maxLength) {
      const endChars = fileName.slice(-4);
      const truncatedName = fileName.slice(0, maxLength - 4);
      return truncatedName + '...' + endChars;
    }
    return fileName;
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: '28px 30px' }}>
        <div className="delate-content">
          <div>
            <span className="fs-16 fw-bold">Asset Photo</span>
            <span className="fs-14 ps-3">Select the asset photo to attach</span>
          </div>
          <div className="browse-file-upload mt-4">
            <FileUploader
              hoverTitle=" "
              children={
                <div>
                  <div
                    className="fs-16 fw-medium pt-3 pb-2"
                    style={{ color: '#292D32' }}
                  >
                    Choose your signature from file or drag & drop it here
                  </div>
                  <div className="fs-16 fw-medium" style={{ color: '#A9ACB4' }}>
                    JPEG or PNG up to 2MB
                  </div>
                  <input
                    fileOrFiles="files"
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="fileInput"
                  />
                  <label
                    htmlFor="fileInput"
                    className="browse-file cursor-pointer"
                  >
                    Browse File
                  </label>
                </div>
              }
              classes="cursor-pointer"
              maxFileSize={50}
              multiple={false}
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              onTypeError={(error) => toast.error(error)}
              onSizeError={(error) => toast.error(error)}
            />
          </div>
          <div className="d-grid gap-3 mt-3">
            {files.map((f) =>
              f.status === 'Uploading...' ? (
                <div className="uploading-content" key={f.name}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <div
                        className="fs-16 fw-medium"
                        style={{ color: '#292D32' }}
                      >
                        {truncateFileName(f.name, 40)}
                      </div>
                      <div
                        className="fs-13 pt-1 pb-2"
                        style={{ color: '#A9ACB4' }}
                      >
                        {parseInt(f.size / 1024) * (f.uploading / 100)} KB of{' '}
                        {parseInt(f.size / 1024)} KB •
                        <span style={{ color: '#292D32', paddingLeft: '10px' }}>
                          Uploading...
                        </span>
                      </div>
                    </div>
                  </div>
                  <ProgressBar variant="warning" now={f.uploading} />
                </div>
              ) : (
                <div
                  className="uploading-content d-flex align-items-center justify-content-between"
                  key={f.name}
                >
                  <img src={URL.createObjectURL(f)} alt="" height={43} width={43} />
                </div>
              )
            )}
          </div>
          <div
            className="button-group"
            style={{
              marginTop: '25px',
              display: 'flex',
              justifyContent: 'end',
              gap: '30px'
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            <button className="delate-btn" onClick={props.onHide} disabled={isSubmitting}>
              {isSubmitting ? 'Saving...' : 'Save'}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AssetPhotoModal;
