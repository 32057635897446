import React, { useContext, useEffect, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";
import { get, post } from "../../../Services/ApiHelper";
import {
  EDIT_LABOUR_OTHER_COST,
  GET_TICKET_OTHER_COST_BY_TICKET_ID,
} from "../../../utls/constants";

const SummaryEditOtherCostsModal = ({ show, data, onHide }) => {
  const [selectCostCat, setSelectCat] = useState("Select cost category");
  const [selectCostCatId, setSelectCatId] = useState(0);
  const [description, setDescription] = useState("");
  const [costCategoryData, setCostCategoryData] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [unitCost, setUnitCost] = useState(0);
  const token = localStorage.getItem("bearerToken");
  const [isLoading, setIsLoading] = useState(false);
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);

  const ticketId = ticketSummary?.workOrderSummary?.ticketId;

  //demo data

  useEffect(() => {
    get(GET_TICKET_OTHER_COST_BY_TICKET_ID, token)
      .then((response) => setCostCategoryData(response))
      .catch((error) => console.log(error));
  }, []);

  //get search input
  const [costCategorySearch, setCostCategorySearch] = useState("");

  const filterCostCategoryData = costCategoryData.filter((item) =>
    item?.ticketCostItemTypeName
      ?.toLowerCase()
      .includes(costCategorySearch.toLowerCase())
  );

  //handle select

  const handleSelectWorker = (eventKey) => {
    const selectedCostCategory = costCategoryData.find(
      (category) => category.ticketCostItemTypeName === eventKey
    );

    if (selectedCostCategory) {
      setSelectCat(eventKey);
      setSelectCatId(selectedCostCategory.id);
    }

    setCostCategorySearch("");
  };
  const onSubmit = () => {
    setIsLoading(true);
    const payload = {
      id: data.id,
      description: description === "" ? data.description : description,
      costCategoryId:
        selectCostCatId == 0 ? data.costItemTypeId : selectCostCatId,
      quantity: quantity == 0 ? data.quantity : quantity,
      unitCost: unitCost == 0 ? data.unitCost : unitCost,
    };

    post(EDIT_LABOUR_OTHER_COST, payload, token)
      .then((response) => {
        if (response.succeeded === true) {
          setIsLoading(false);
          onHide();
          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          const index =
            ticketSummary.workOrderSummary.ticketOtherCostList.findIndex(
              (item) => item.id === data.id
            );

          if (index !== -1) {
            setTicketSummary((prevTicketSummary) => {
              const updatedTicketSummary = { ...prevTicketSummary };
              updatedTicketSummary.workOrderSummary.ticketOtherCostList[index] =
                {
                  ...data,
                  costItemTypeId:
                    selectCostCatId == 0
                      ? data.costItemTypeId
                      : selectCostCatId,
                  costItemTypeName:
                    selectCostCat == "Select cost category"
                      ? data.costItemTypeName
                      : selectCostCat,
                  description:
                    description === "" ? data.description : description,
                  quantity: quantity == 0 ? data.quantity : quantity,
                  ticketId: ticketId,
                  unitCost: unitCost == 0 ? data.unitCost : unitCost,
                };
              return updatedTicketSummary;
            });
          }

          setTimeout(() => (onHide, 3000));
        } else {
          setIsLoading(false);
          onHide();
          toast.error(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <Modal
      onHide={onHide}
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Edit Other Costs</span>
            <span className="fs-14" style={{ color: "#72777A" }}>
              Provide any extra costs incurred in doing the work.
            </span>
          </div>
          <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
            <div className="col-md-12">
              <label className="fw-medium pb-2">Cost Description</label>
              <input
                defaultValue={data?.description}
                className="modal-input-box"
                onChange={(e) => setDescription(e.target.value)}
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "0 15px",
                }}
                placeholder="Enter cost description"
              />
            </div>
            <div className="col-md-12">
              <label className="fw-medium pb-2">Cost Category</label>
              <Dropdown className="select__form" onSelect={handleSelectWorker}>
                <Dropdown.Toggle
                  className={`select-title ${
                    selectCostCat !== "Select cost category" ? "selected" : ""
                  }`}
                  style={{ height: "50px" }}
                >
                  {selectCostCat !== "Select cost category"
                    ? selectCostCat
                    : data.costItemTypeName}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      onChange={(e) => setCostCategorySearch(e.target.value)}
                      value={costCategorySearch}
                      type="text"
                      placeholder="Search"
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filterCostCategoryData.map((item, index) => (
                      <Dropdown.Item
                        key={index}
                        eventKey={item.ticketCostItemTypeName}
                      >
                        {item.ticketCostItemTypeName}
                      </Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-12">
              <label className="fw-medium pb-2">Quantity</label>
              <input
                defaultValue={data?.quantity}
                className="modal-input-box"
                onChange={(e) => setQuantity(parseInt(e.target.value), 10)}
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "0 15px",
                }}
                placeholder="Enter quantity"
              />
            </div>
            <div className="col-md-12">
              <label className="fw-medium pb-2">Unit Cost (KES)</label>
              <input
                defaultValue={data.unitCost}
                onChange={(e) => setUnitCost(parseInt(e.target.value), 10)}
                className="modal-input-box"
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "0 15px",
                }}
                placeholder="Enter unit cost"
              />
            </div>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={onHide}>
              Cancel
            </button>
            {isLoading ? (
              <Spinner
                animation="border"
                style={{
                  color: "#D57D2A",
                }}
              />
            ) : (
              <Link to="" className="delate-btn" onClick={onSubmit}>
                Add
              </Link>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SummaryEditOtherCostsModal;
