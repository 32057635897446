import React from "react";
import { Link } from "react-router-dom";

const InspectionNavbar = () => {
  return (
    <div className="other-nav">
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        {/* <li>
          <Link to="" className="text-black">
            Inspection Categories
          </Link>
        </li> */}
        <li>
          {/* <Link> Inspection Categories</Link> */}
          <Link> Continuous  Check Categories</Link>
        </li>
      </ul>
    </div>
  );
};

export default InspectionNavbar;
