/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import DownIcon from "../../../Assets/Icons/DownIcon";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import { HiDotsVertical } from "react-icons/hi";
import ViewIcon2 from "../../../Assets/Icons/ViewIcon2";
import EditIcon2 from "../../../Assets/Icons/EditIcon2";
import DelateIcon2 from "../../../Assets/Icons/DelateIcon2";
import ModalInspection from "../ModalInspection";
import ArrowLeft from "../../../Assets/Icons/ArrowLeft";
import ArrowRight from "../../../Assets/Icons/ArrowRight";
import DropdownIcon from "../../../Assets/Icons/DropdownIcon";
import getUniqueProperty from "../../../utls/getUniqueProprty";
import filterViaProperty from "../../../utls/filterViaProperty";
import searchAllProperty from "../../../utls/searchAllProperty";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import RoutineInspectionCategoryContext from "../../../Context/Inspections/RoutineInspectionCategoryContext";
import DeleteModal from "../../Modals/DeleteModal";
import { GET_INSPECTIONS_BY_ASSETS_ID_FILTER, GET_INSPECTIONS_BY_CATEGORY_ID, GET_INSPECTIONS_BY_CATEGORY_ID_FILTER, GET_ROUTINE_INSPECTIONS_BY_CATEGORY_ID } from "../../../utls/constants";
import { get } from "../../../Services/ApiHelper";

const CompletedTable = ({ ScheduleId,category }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [inspectionData, setInspectionData] = useState([]);
  const [inspectionDatas, setInspectionDatas] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [inspectionShow, setInspectionShow] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1); //for pagination
  const [inspectionPerPage, setInspectionPerPage] = useState(5); //for pagination
  const [search, setSearch] = useState(""); //for search
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [Item, setItem] = useState(0);
  const [teamId, setTeamId] = useState(null);
  const [assetId, setAssetId] = useState(null);
  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };
  const ExampleCustomInput = ({ value, onClick }) => (
    <button className="example-custom-input" onClick={onClick}>
      {value ? value : ""} <DownIcon />
    </button>
  );

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder((prevSortOrder) =>
        prevSortOrder === "asc" ? "desc" : "asc"
      );
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const sortedInspectionData = inspectionData.sort((a, b) => {
    
    if (sortBy) {
      const valueA = a[sortBy];
      const valueB = b[sortBy];
      return sortOrder === "asc"
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    }
    return 0;
  });

  const handleTeamClick = (team) => {
  
    get(GET_INSPECTIONS_BY_CATEGORY_ID_FILTER(ScheduleId,"Complete",1,10,team),token).then(data=>{
        var result=data["data"];

      setIsLoading(false);

        setIsLoading(false);

        setTeamId(team)
      
        setInspectionData(result);
    })
  };

  const handleAssetClick = (asset) => {
  
    get(GET_INSPECTIONS_BY_ASSETS_ID_FILTER(inspectionDetailData.id,"Complete",1,10,asset),token).then(data=>{
        var result=data["data"];

      setIsLoading(false);

        setIsLoading(false);

        setAssetId(asset)
      
        setInspectionData(result);
    })
  };

   

  //filter via start date if date is not null
  const filteredByStartDate = startDate
    ? sortedInspectionData.filter((item) => {
        const date = new Date(item.date.slice(0, 10));
        return date.getTime() >= startDate.getTime();
      })
    : sortedInspectionData;

  //filter via end date if date is not null
  const filteredByEndDate = endDate
    ? filteredByStartDate.filter((item) => {
        const date = new Date(item.date.slice(0, 10));
        return date.getTime() <= endDate.getTime();
      })
    : filteredByStartDate;

  //get teams & filter by team
  const assets = getUniqueProperty(sortedInspectionData,  "assetId", "assetName");

  const teams = getUniqueProperty(sortedInspectionData,  "assignedTeamId", "assignedTeamName");
  
  const filteredByTeam = filterViaProperty(
    filteredByEndDate,
    selectedTeam,
    "assignedTeamName"
  );

  

  // filter via search
  const filteredBySearch = searchAllProperty(filteredByTeam, search);

  //for pagination
  const indexOfLastInspection = currentPage * inspectionPerPage;
  const indexOfFirstInspection = indexOfLastInspection - inspectionPerPage;
  const currentInspection = filteredBySearch.slice(
    indexOfFirstInspection,
    indexOfLastInspection
  );

  // Calculate total pages
  const totalPages = Math.ceil(filteredBySearch.length / inspectionPerPage);

  // Update current page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  
  var token= localStorage.getItem("bearerToken");
  const {inspectionDetailData} = useContext(RoutineInspectionCategoryContext);
  useEffect(()=>{
    get(GET_INSPECTIONS_BY_CATEGORY_ID(ScheduleId,"Complete",1,1000),token).then(data=>{
      var result=data["data"];
      setIsLoading(false);
      setInspectionData(result);
    }).catch((error)=>{
      console.log("An Error Occured")
    });
  },[ScheduleId]);
  const {setContextOngoingInspection} = useContext(RoutineInspectionCategoryContext);

  const handleLinkClick = (item) => {
    localStorage.setItem('ongoingInspection', JSON.stringify(item));

    setContextOngoingInspection(item);
  };
  
const handleAssetAllClick = () => {
  setAssetId(null);
  get(GET_INSPECTIONS_BY_CATEGORY_ID(ScheduleId,"Complete",1,1000),token).then(data=>{
    var result=data["data"];
    setIsLoading(false);
    setInspectionData(result);
  })
}
const handleTeamAllClick = () => {
  setTeamId(null);
  get(GET_INSPECTIONS_BY_CATEGORY_ID(ScheduleId,"Complete",1,1000),token).then(data=>{
    var result=data["data"];
    setIsLoading(false);
    setInspectionData(result);
  })
}
const handleSetItem = (item) => {
  setItem(item);
}


  return (
    <>
      <div className="selection-grope">
      <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Asset:</span>
            <span className="fs-14 d-flex align-items-center gap-1">
            {assetId === null ? "All" : assets.find(asset => asset.id === assetId).name} <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu select-menu">
            <li onClick={() => handleAssetAllClick()}>
              <b>All</b>
            </li>
            <hr />
            {assets.map((asset) => (
              <li key={asset.id} onClick={() => handleAssetClick(asset.id)}>{asset.name}</li>
            ))}
          </ul>
        </div>
        <div className="select-dropdown">
          <div className="select-title">
            <div className="fs-13">Date From:</div>
            <div className="format-date">
              <DatePicker
              maxDate={endDate}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                customInput={<ExampleCustomInput />}
              />
            </div>
          </div>
        </div>
        <div className="select-dropdown">
          <div className="select-title">
            <div className="fs-13">Date To:</div>
            <div className="format-date">
              <DatePicker
              minDate={startDate}
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                customInput={<ExampleCustomInput />}
              />
            </div>
          </div>
        </div>

        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Team:</span>
            <span className="fs-14 d-flex align-items-center gap-1">
            {teamId === null ? "All" : teams.find(team => team.id === teamId).name} <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu select-menu">
            <li onClick={() => handleTeamAllClick()}>
              <b>All</b>
            </li>
            <hr />
            {teams.map((team) => (
              <li key={team.id} onClick={() => handleTeamClick(team.id)}>{team.name}</li>
            ))}
          </ul>
        </div>
        <form action="" className="search__box">
          <button disabled type="submit">
            <SearchIcon />
          </button>
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            type="search"
            placeholder="Search"
          />
        </form>
      </div>
      <div className="inspection-tables-content">
        <div className="table-responsive">
          <table className="table">
          <thead>
              <tr>
              
                <th
                  className={
                    sortBy === "inspectionCode" ? `table-sort-${sortOrder}` : "table-sort"
                  }
                  onClick={() => handleSort("inspectionCode")}
                >
                  <span>Reference</span>
                </th>
                <th
                  className={
                    sortBy === "assetName" ? `table-sort-${sortOrder}` : "table-sort"
                  }
                  onClick={() => handleSort("assetName")}
                >
                  <span>Asset</span>
                </th>
                {/* <th
                  className={
                    sortBy === "date" ? `table-sort-${sortOrder}` : "table-sort"
                  }
                  onClick={() => handleSort("date")}
                >
                  <span>Asset Name</span>
                </th> */}
                <th
                  className={
                    sortBy === "assignedTeamName" ? `table-sort-${sortOrder}` : "table-sort"
                  }
                  onClick={() => handleSort("assignedTeamName")}
                >
                  <span>Assigned Team</span>
                </th>
                <th
                  className={
                    sortBy === "assignedUserName" ? `table-sort-${sortOrder}` : "table-sort"
                  }
                  onClick={() => handleSort("assignedUserName")}
                >
                  <span>Assigned Worker</span>
                </th>
                <th className="table-th"></th>
              </tr>
            </thead>
            <tbody>
              {currentInspection.map((item) => (
                <tr key={item.id}>
                  <td className="table-ellipsis">{item.inspectionCode}</td>
                  <td className="table-ellipsis">{item.assetName}</td>
                  <td className="table-ellipsis">{item.assignedTeamName}</td>
                  <td className="table-ellipsis">{item.assignedUserName}</td>
                  <td>
                    <div className="table-last-btn">
                      <div className="dropdown table-edit-dropdown">
                        <button className="" data-bs-toggle="dropdown">
                          <HiDotsVertical />
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end">
                          <li>
                            <Link
                              className="dropdown-item"
                              to={`/inspections/responses/${item.routineInspectionId}`}
                              onClick={() => handleLinkClick(item)}
                              state={{category:category}}
                            >
                              <ViewIcon2 /> View
                            </Link>
                          </li>
                          <li>
                          <button
                              className="dropdown-item"
                              onClick={() => {
                                setInspectionShow(true);
                                handleSetItem(item);
                                
                              }}
                            >
                              <EditIcon2 /> Edit
                            </button>
                          </li>
                          <li onClick={() => {
                            setItem(item);
                            toggleDeleteModal();
                          }}  >
                            <a className="dropdown-item" href="#">
                              <DelateIcon2 /> Delete
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <DeleteModal show={deleteModal} onHide={toggleDeleteModal}  Item={Item} />                 
        <div className="table-pagination pagination-center">
          <div className="pagination-row">
            Rows per page:
            <div className="dropdown select-dropdown">
              <button className="select-title" data-bs-toggle="dropdown">
                <span className="fs-14 d-flex align-items-center gap-2">
                  {inspectionPerPage} <DropdownIcon />
                </span>
              </button>
              <ul
                className="dropdown-menu select-menu"
                style={{ minWidth: "25px" }}
              >
                <li onClick={() => setInspectionPerPage(5)}>5</li>
                <li onClick={() => setInspectionPerPage(10)}>10</li>
                <li onClick={() => setInspectionPerPage(15)}>15</li>
                <li onClick={() => setInspectionPerPage(20)}>20</li>
              </ul>
            </div>
          </div>
          <div className="pagination-number">
            <div className="fs-14">
              {currentPage} of {totalPages}
            </div>
            <div className="arrow-btn">
              <button
                onClick={() =>
                  currentPage !== 1 && handlePageChange(currentPage - 1)
                }
              >
                <ArrowLeft />
              </button>
              <button
                onClick={() =>
                  totalPages !== currentPage &&
                  handlePageChange(currentPage + 1)
                }
              >
                <ArrowRight />
              </button>
            </div>
          </div>
        </div>

        <ModalInspection
          show={inspectionShow}
          onHide={() => setInspectionShow(false)
          }
          category={category}
          item={Item}
        />
      </div>
    </>
  );
};

export default CompletedTable;
