import React, { useRef } from "react";
import logo from "../../../Assets/Images/logo.png";
import { Table } from "react-bootstrap";

const PDFDocument = ({ quotesData }) => {
  const componentRef = useRef();
  const company = localStorage.getItem("company");
  const companyObj = company && JSON.parse(company);
  const currency = companyObj.currency;

  const workData = quotesData?.workDetails;
  const costData = quotesData?.costDetails;
  const quotes = quotesData?.costDetails?.quoteItemList;

  // Function to calculate the total cost
  const calculateTotalCost = () => {
    return quotes?.reduce((total, part) => {
      return total + part.quantity * part.unitCost;
    }, 0);
  };

  // Calculate the total cost
  const totalCost = calculateTotalCost();

  console.log("pdf ducomuent work data", workData);
  console.log("pdf ducomuent cost data", costData);
  return (
    <>
      <div className="pad-overflow">
        <div className="quotes-pad" id="quotes-pad" ref={componentRef}>
          <div className="auto-gap">
            <div className="pad-header d-flex flex-wrap justify-content-between align-items-center">
              <div className="pad-logo">
                <img src={logo} alt={logo} />
              </div>
              <address className="fs-13 text-black lh-base text-end">
                <div className="fs-24 text-black text-uppercase fw-bold">
                  quote
                </div>
                {costData?.costingTeamName && (
                  <>
                    {costData.costingTeamName} <br />
                  </>
                )}
                {costData?.costingTeamPhoneNumber && (
                  <>
                    {costData.costingTeamPhoneNumber} <br />
                  </>
                )}
                {costData?.costingTeamEmail && (
                  <>
                    {costData.costingTeamEmail} <br />
                  </>
                )}
                {(costData?.costsRequestorAddress1 ||
                  costData?.costsRequestorCity) && (
                  <>
                    {costData?.costsRequestorAddress1}
                    {costData?.costsRequestorAddress1 &&
                      costData?.costsRequestorCity &&
                      ", "}
                    {costData?.costsRequestorCity}
                  </>
                )}
              </address>
            </div>
            <hr />
            <div className="quotes-total d-flex flex-wrap justify-content-between align-items-center">
              <div className="quotes-name d-flex flex-wrap gap-4 align-items-center">
                <div className="lh-sm">
                  <div className="fs-13 fw-medium">{workData?.assignedTeamName}</div>
                  <div className="fs-11">{workData?.assignedUserName}</div>
                  <div className="fs-12">Kilimani Avenue, Nairobi</div>
                </div>
                <div className="lh-sm">
                  <div className="fs-13 fw-medium">Work Ref: {workData?.ticketRef}</div>
                  <div className="fs-12">{new Date(costData?.submittedDate).toLocaleDateString()}</div>
                </div>
              </div>
              <div
                className="total-price fs-20 fw-medium"
                style={{ color: "#D57D2A" }}
              >
                <b className="pe-4">Total</b> {currency} {totalCost}
              </div>
            </div>
            <hr />
            <Table responsive style={{ background: "none" }}>
              <thead>
                <tr>
                  <th>Item Description</th>
                  <th>Qty</th>
                  <th>Amount</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {quotes?.map((item) => (
                  <tr key={item.id}>
                    <td>{item.description}</td>
                    <td>{item.quantity}</td>
                    <td>{item.unitCost}</td>
                    <td>{item.quantity * item.unitCost}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <hr />
            <div className="nots-pard">
              <div className="">
              </div>
              <ul>
                <li style={{ gap: "50px" }}>
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "700",
                      textTransform: "uppercase",
                    }}
                  >
                    Total
                  </span>
                  <span style={{ fontSize: "12px", fontWeight: "700" }}>
                    {currency} {totalCost}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="quotes-footer">
            <span>Prepared By: {costData?.costingUserName}</span>
            <span>{costData?.costingTeamPhoneNumber}</span>
          </div>
        </div>
      </div>
    </>
  );
};
// const quotesData = [
//   {
//     item: "Replacement Nozzle Part - 23841001",
//     qty: "2",
//     amount: "25,000",
//     total: "50,000",
//   },
//   {
//     item: "Technician Service Charge",
//     qty: "1",
//     amount: "12,000",
//     total: "12,000",
//   },
//   {
//     item: "Mileage to and from site",
//     qty: "1",
//     amount: "5,000",
//     total: "5,000",
//   },
// ];
export default PDFDocument;
