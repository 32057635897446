import React, { useEffect, useState } from "react";
import "../Components/PreventiveWorks/PreventiveWorks.scss";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import WorkScheduleHeader from "../Components/WorkScheduleTemplate/WorkScheduleHeader";
import WorkScheduleTable from "../Components/WorkScheduleTemplate/WorkScheduleTable";
import { get } from "../Services/ApiHelper";
import { GET_PM_TEMPLATES } from "../utls/constants";

const ScheduleWorks = () => {
  const [workScheduleTableData, setWorkScheduleTableData] = useState([]); //for table data
  const [isLoading, setIsLoading] = useState(false)
  const [allResponses, setAllResponses] = useState({}); 
  const token = localStorage.getItem("bearerToken");
    useEffect(() => {
      setIsLoading(true)
      get(GET_PM_TEMPLATES,token).then((res) => {
        setIsLoading(false)
        setWorkScheduleTableData(res.data);
        setAllResponses(res)
        
      });
    }, []);
  return (
    <div className="work-orders-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <WorkScheduleHeader workTable={workScheduleTableData} />
          </div>
          <div className="col-lg-12">
            <div className="other-nav">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/work-schedule-templates">Work</Link>
                </li>
                <li>
                  <Link to="">Schedule Templates</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="inspection-cate pt-lg-4">
              <div className="work-table-tables">
                <WorkScheduleTable data={workScheduleTableData} isLoading={isLoading} setIsLoading={setIsLoading} allResponses={allResponses} setAllResponses={setAllResponses}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleWorks;

const workScheduleTableData = [
  {
    name: "Pump Calibration",
    work_category: "Pump maintenance",
    asset_category: "Dispensers",
    recurs_every: "6 months",
    priority: "Medium",
    est_time_hrs: "2 Hours",
  },
  {
    name: "Tank Calibration",
    work_category: "Tank maintenance",
    asset_category: "Tanks",
    recurs_every: "1 year",
    priority: "High",
    est_time_hrs: "8 Hours",
  },
  {
    name: "Plumbing Works",
    work_category: "Plumping maintenance",
    asset_category: "Pipes",
    recurs_every: "1 year",
    priority: "Medium",
    est_time_hrs: "4 Hours",
  },
  {
    name: "Factory Shutdown",
    work_category: "Factory maintenance",
    asset_category: "Line Machines",
    recurs_every: "6 months",
    priority: "High",
    est_time_hrs: "48 Hours",
  },
  {
    name: "Restroom & Cleaning",
    work_category: "Cleaning Services",
    asset_category: "Toilets and Bath",
    recurs_every: "3 hours",
    priority: "Low",
    est_time_hrs: "1 Hours",
  },
  {
    name: "Vehicle Servicing",
    work_category: "Vehicle maintenance",
    asset_category: "Vehicles",
    recurs_every: "6 months",
    priority: "Medium",
    est_time_hrs: "3 Hours",
  },
  {
    name: "Elevator Servicing",
    work_category: "Escalator maintenance",
    asset_category: "Escalators",
    recurs_every: "3 months",
    priority: "Medium",
    est_time_hrs: "4 Hours",
  },
  {
    name: "Sewer servicing",
    work_category: "Sewer Lines maintenance",
    asset_category: "Sewer lines",
    recurs_every: "1 year",
    priority: "Low",
    est_time_hrs: "48 Hours",
  },
  {
    name: "HVAC works",
    work_category: "HVAC maintenance",
    asset_category: "HVAC equipment",
    recurs_every: "3 months",
    priority: "Medium",
    est_time_hrs: "2 Hours",
  },
];
