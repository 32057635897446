import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import { RiErrorWarningFill } from "react-icons/ri";
import "./modals.scss";
import { Spinner } from "react-bootstrap";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";
import { START_PM_WORK } from "../../../utls/constants";
import { toast } from "react-toastify";
import { post } from "../../../Services/ApiHelper";

const StartUpCommmingModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleStartWork = async () => {
    setIsLoading(true);

    const bearerToken = localStorage.getItem("bearerToken");
    const data = {
      ticketId: props.ticketId,
    };

    post(START_PM_WORK, data, bearerToken)
      .then((response) => {
        if (response.succeeded === true) {
          props.onHide();
          setIsLoading(false);

          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else {
          toast.error(response.messages[0],  {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((error) => {
        props.onHide();
        setIsLoading(false);
        console.error("Error deleting item:", error);
        toast.error("An error occured", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .finally(() => {
        props.onHide();
        setIsLoading(false);
      });
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className=" start-upcomming-modal">
          <div className="">
            <span className="fs-16 fw-bold ">Start Upcoming Work Now</span>
            <span style={{ color: "#72777A" }} className="fs-14 ps-3">
              Confirm conversion of work order to new
            </span>
          </div>
          <div
            className="delate-warning"
            style={{
              background: "#F1EFEF",
              minHeight: "267px",
              display: "grid",
              alignItems: "center",
              textAlign: "center",
              padding: "20px",
              marginTop: "20px",
              borderRadius: "5px",
            }}
          >
            <div>
              <RiErrorWarningFill
                style={{ color: "#D57D2A", fontSize: "70px" }}
              />
              <div
                className="fs-20 fw-medium "
                style={{
                  lineHeight: "1.4",
                  marginTop: "10px",
                  color: "#72777A",
                }}
              >
                Please confirm conversion of this work from upcoming to new.
              </div>
              <p>
                <b>Note:</b> Due date will change to today's date and the users
                assigned will be alerted to start work. The work will also be
                moved to active work tabs.
              </p>
            </div>
          </div>
          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            {isLoading ? (
              <Spinner animation="border" style={{ color: "#D57D2A" }} />
            ) : (
              <button className="delate-btn" onClick={handleStartWork}>
                Confirm
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default StartUpCommmingModal;
