import React, { useMemo, useRef } from "react";
import {
  Aggregate,
  AggregateColumnsDirective,
  AggregateColumnDirective,
  AggregateDirective,
  AggregatesDirective,
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Toolbar,
  PdfExport,
  Page,
} from "@syncfusion/ej2-react-grids";
import { format } from "date-fns";
import { Spinner } from "react-bootstrap";

const CorrectiveWSGrid = ({
  workOrder,
  isLoading,
  filters,
  categories,
  categoryOfWorkIds,
  equipment,
  equipmentIds,
  location,
  locationIds,
  equimentCategories,
  equipmentCategoriesId,
}) => {
  const workOrderData = workOrder?.data || [];
  const componentRef = useRef();

  const data = workOrderData.map(item => ({
    ...item,
    createdDate: new Date(item.createdDate)
  }));

  const dateTemplate = (props) => {
    return format(new Date(props.createdDate), 'yMd');
  };

  // eslint-disable-next-line no-unused-vars
  let gridInstance;

  const formatDate = (dateString) => {
    return format(new Date(dateString), "dd/MM/yyyy");
  };

  const getCategoryNames = () => {
    return categoryOfWorkIds.map(
      (id) =>
        categories.find((category) => category.id === id)?.categoryOfWorkName ??
        ""
    );
  };

  const getEquipmentCategoryNames = () => {
    return equipmentCategoriesId.map(
      (id) =>
        equimentCategories.find((category) => category.id === id)
          ?.assetCategoryName ?? ""
    );
  };

  const getLocationNames = () => {
    return locationIds.map(
      (id) =>
        location.find((category) => category.id === id)?.locationName ?? ""
    );
  };

  const getEquipmentNames = () => {
    return equipmentIds.map(
      (id) => categories.find((category) => category.id === id)?.assetName ?? ""
    );
  };

  const formattedFilters = useMemo(() => {
    if (!filters) return null;

    return {
      startDate: formatDate(filters["filter.StartDate"]),
      endDate: formatDate(filters["filter.EndDate"]),
      calendarGrouping: filters["filter.CalendarGrouping"],
      categoryOfWork: getCategoryNames(
        filters["filter.CategoryOfWorkIds"]
      ).join(", "),
      equipmentCategory: getEquipmentCategoryNames(
        filters["filter.EquipmentCategoryIds"]
      ).join(", "),
      locations: getLocationNames(filters["filter.LocationIds"]).join(", "),
      equipments: getEquipmentNames(filters["filter.EquipmentIds"]).join(", "),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const settings = { type: "Multiple" };

  const headerTemplate = () => {
    return (
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <div>
          <h2>PM Performance Report</h2>
          <div className="filters">
            <p>Work Category: {filters?.categoryOfWork ?? "All"}</p>
            <p>Contractor: {filters?.contractor ?? "All"}</p>
            <p>Station: {filters?.station ?? "All"}</p>
            <p>Status: {filters?.status ?? "All"}</p>
            <p>Start Date: {filters?.startDate ?? ""}</p>
            <p>End Date: {filters?.endDate ?? ""}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {filters && isLoading ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <div ref={componentRef} id="corrective-work">
            <GridComponent
              dataSource={data}
              ref={(g) => (gridInstance = g)}
              // allowPaging={true}
              pageSettings={{
                pageSize: workOrder?.pageSize || 10,
                pageCount: workOrder?.totalPages || 1,
                currentPage: workOrder?.currentPage || 1,
                enableQueryString: true,
              }}
              selectionSettings={settings}
              allowTextWrap={true}
            >
              <ColumnDirective
                template={headerTemplate}
                headerText="Report Info"
                width="800"
                textAlign="Center"
              />
              <h3>Corrective Work Status Report</h3>
              <div
                className=""
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gridColumnGap: "20px",
                  gridRowGap: "4px",
                  fontSize: "14px",
                  fontWeight: 400,
                  marginBottom: "10px",
                  width: "60%",
                }}
              >
                <p style={{ color: "gray" }}>Start Date: <span style={{fontWeight: "bold", color: "black"}}>{formattedFilters?.startDate || ""}</span> </p>
                <p style={{ color: "gray" }}>Category of Work: <span style={{fontWeight: "bold", color: "black"}}>{formattedFilters?.categoryOfWork || "All"}</span></p>
                <p style={{ color: "gray" }}>End Date: <span style={{fontWeight: "bold", color: "black"}}>{formattedFilters?.endDate || ""}</span></p>
                <p style={{ color: "gray" }}>Location: <span style={{fontWeight: "bold", color: "black"}}>{formattedFilters?.locations || "All"}</span></p>
                <p style={{ color: "gray" }}>Team: <span style={{fontWeight: "bold", color: "black"}}>{formattedFilters?.equipments || "All"}</span></p>
                <p style={{ color: "gray" }}>Work Status: <span style={{fontWeight: "bold", color: "black"}}>{formattedFilters?.status || "All"}</span></p>
              </div>
              <ColumnsDirective>
                <ColumnDirective
                  field="ticketRef"
                  headerText="Ref No."
                  width="100"
                />
                <ColumnDirective
                  field="locationName"
                  headerText="Location"
                  width="100"
                />
                <ColumnDirective
                  field="categoryOfWorkName"
                  headerText="Category of Work"
                  width="100"
                />
                <ColumnDirective
                  field="ticketDescription"
                  headerText="Call Description"
                  width="120"
                />
                <ColumnDirective
                  field="teamName"
                  headerText="Assigned To"
                  width="100"
                />
                <ColumnDirective
                  field="createdDate"
                  headerText="Date Created"
                  format={'yMd'}
                  width="100"
                />
                <ColumnDirective
                  field="status"
                  headerText="Status"
                  width="100"
                />
              </ColumnsDirective>
              <AggregatesDirective>
                <AggregateDirective>
                  <AggregateColumnsDirective>
                    <AggregateColumnDirective
                      field="scheduled"
                      type="Sum"
                      format="N0"
                      footerTemplate={(props) => (
                        <div style={{ color: "black", padding: "5px" }}>
                          {props.Sum}
                        </div>
                      )}
                    />
                    <AggregateColumnDirective
                      field="completed"
                      type="Sum"
                      format="N0"
                      footerTemplate={(props) => (
                        <div style={{ color: "black", padding: "5px" }}>
                          {props.Sum}
                        </div>
                      )}
                    />
                    <AggregateColumnDirective
                      field="onHold"
                      type="Sum"
                      format="N0"
                      footerTemplate={(props) => (
                        <div style={{ color: "black", padding: "5px" }}>
                          {props.Sum}
                        </div>
                      )}
                    />
                    <AggregateColumnDirective
                      field="mtfr"
                      type="Sum"
                      format="N0"
                      footerTemplate={(props) => (
                        <div style={{ color: "black", padding: "5px" }}>
                          {props.Sum}
                        </div>
                      )}
                    />
                  </AggregateColumnsDirective>
                </AggregateDirective>
              </AggregatesDirective>
              <Inject services={[Toolbar, PdfExport, Aggregate, Page]} />
            </GridComponent>
          </div>
        </>
      )}
    </div>
  );
};

export default CorrectiveWSGrid;
