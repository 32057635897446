import React, { useState, useContext, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import {
  GET_TEAMS_TO_ASSIGN_TICKET,
  GET_ALL_USERS_BY_TEAM,
  ASSIGN_WORK,
  ASSIGN_WORK_WITH_PTW,
} from "../../../utls/constants";
import { get } from "../../../Services/ApiHelper";
import { toast } from "react-toastify";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";
import { Spinner } from "react-bootstrap";
import InputError from "../../Common/InputError";
import Astericks from "../../Common/Asterick";

const AssingModal = (props) => {
  const [loading, setLoading] = useState(false);
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);
  const [selectTeam, setSelectTeam] = useState("Select team");
  const [selectWorker, setSelectWorker] = useState("Select worker");
  let [selectValue, setSelectValue] = useState({
    assignedTeamId: 0,
    assignedTeamName: "",
    assignedUserId: 0,
    assignedUserName: "",
  });
  const [teamList, setTeamList] = useState([]);
  const [workerList, setWorkerList] = useState([]);
  const [assignClicked, setAssignClicked] = useState(false);
  const IsPTWGenerated = ticketSummary?.workOrderSummary?.isPTWGenerated;

  useEffect(() => {
    fetch(GET_TEAMS_TO_ASSIGN_TICKET, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: "Bearer " + localStorage.getItem("bearerToken"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setTeamList(data);
      });
  }, []);

  const getUserByTeamId = (teamId) => {
    setWorkerList([]);
    fetch(GET_ALL_USERS_BY_TEAM(teamId), {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: "Bearer " + localStorage.getItem("bearerToken"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setWorkerList(data);
      });
  };
  const onSubmit = () => {
    const body = {
      ticketId: ticketSummary?.workOrderSummary?.ticketId,
      teamId: parseInt(selectValue.assignedTeamId),
      userId: parseInt(selectValue.assignedUserId),
      isPTWRequired: isPTWRequired,
    };
    setLoading(true);
    fetch(ASSIGN_WORK_WITH_PTW, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: "Bearer " + localStorage.getItem("bearerToken"),
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.succeeded === true) {
          setTicketSummary({
            ...ticketSummary,
            workOrderSummary: {
              ...ticketSummary.workOrderSummary,
              assignedTeamName: selectValue.assignedTeamName,
              assignedUserName: selectValue.assignedUserName,
              currentTicketActivity: "ASSIGNED",
            },
          });
          console.log(ticketSummary);
          setLoading(false);
          props.onHide();
          toast.success("Ticket Assigned Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });
        } else {
          setLoading(false);
          props.onHide();
          toast.error("Ticket Assignment failed", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });
        }
      });
  };

  const [selectTeamSearch, setSelectTeamSearch] = useState("");
  const [selectWorkerSearch, setSelectWorkerSearch] = useState("");
  const filterSelectTeamData = teamList?.filter((item) =>
    item?.teamName?.toLowerCase().includes(selectTeamSearch.toLowerCase())
  );
  const filterSelectWorkdersData = workerList?.filter((item) =>
    (item?.user?.userFirstName + " " + item?.user?.userLastName)
      .toLowerCase()
      .includes(selectWorkerSearch.toLowerCase())
  );

  const handleSelectTeam = (item) => {
    setSelectTeam(item);
    setSelectTeamSearch("");
    setSelectWorker("");
  };
  const handleSelectWorker = (eventKey) => {
    setSelectWorker(eventKey);
    setSelectWorkerSearch("");
  };

  const [isPTWRequired, setPTWRequired] = useState(false);
  const handlePtwChange = () => {
    setPTWRequired(!isPTWRequired);
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Assign Work</span>
            <span className="fs-14" style={{ color: "#72777A" }}>
              Select the team and worker to execute the work order.
            </span>
          </div>
          <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
            <div className="col-md-12">
              <label className="fw-medium pb-2">
                Select Team
                <Astericks />
              </label>
              {selectTeam === "Select team" && assignClicked && (
                <InputError>
                  <span>required *</span>
                </InputError>
              )}
              <Dropdown className="select__form" onSelect={handleSelectTeam}>
                <Dropdown.Toggle
                  className={`select-title ${
                    selectTeam !== "Select team" ? "selected" : ""
                  }`}
                  style={{ height: "50px" }}
                >
                  {selectTeam}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      onChange={(e) => setSelectTeamSearch(e.target.value)}
                      value={selectTeamSearch}
                      type="text"
                      placeholder="Search"
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filterSelectTeamData.map((item, index) => (
                      <Dropdown.Item
                        key={index}
                        eventKey={item.teamName}
                        onClick={() => {
                          handleSelectTeam(item);
                          getUserByTeamId(item.id);
                          setSelectValue({
                            ...selectValue,
                            assignedTeamId: item.id,
                            assignedTeamName: item.teamName,
                          });
                        }}
                      >
                        {item.teamName}
                      </Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-12">
              <label className="fw-medium pb-2">Select Worker</label>

              <Dropdown className="select__form" onSelect={handleSelectWorker}>
                <Dropdown.Toggle
                  className={`select-title ${
                    selectWorker !== "Select worker" ? "selected" : ""
                  }`}
                  style={{ height: "50px" }}
                >
                  {selectWorker}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      onChange={(e) => setSelectWorkerSearch(e.target.value)}
                      type="text"
                      placeholder="Search"
                      value={selectWorkerSearch}
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filterSelectWorkdersData.map((item, index) => (
                      <Dropdown.Item
                        key={index}
                        eventKey={
                          item.user.userFirstName + " " + item.user.userLastName
                        }
                        onClick={() => {
                          handleSelectWorker(item);
                          setSelectValue({
                            ...selectValue,
                            assignedUserId: item.user.id,
                            assignedUserName:
                              item.user.userFirstName +
                              " " +
                              item.user.userLastName,
                          });
                        }}
                      >
                        {item.user.userFirstName + " " + item.user.userLastName}
                      </Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            {IsPTWGenerated === false && (
              <div className="col-md-12">
                <div className="col-lg-6">
                  <div className="fw-medium pb-2">Permit to Work</div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={ticketSummary?.workOrderSummary?.isPTWRequired}
                      id="required"
                      onChange={handlePtwChange}
                    />
                    <label className="form-check-label" htmlFor="required">
                      Is permit to work required?
                    </label>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            {loading ? (
              <Link
                to=""
                className="delate-btn"
                onClick={(e) => e.preventDefault()}
              >
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Assigning...
              </Link>
            ) : (
              <Link
                to=""
                className="delate-btn"
                onClick={() => {
                  // Set assignClicked to true when the Assign button is clicked
                  setAssignClicked(true);
                  if (selectTeam === "Select team") {
                    // If the Select Team dropdown has not been selected, show an error message or handle the situation accordingly
                    console.log("Please select a team");
                  } else {
                    // If a team has been selected, call the onSubmit function
                    onSubmit();
                  }
                }}
              >
                Assign
              </Link>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AssingModal;
