import React , {useContext,useState,useEffect} from "react";
import Modal from "react-bootstrap/Modal";
import { FiPlus } from "react-icons/fi";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";
import { GET_TICKETASSIGNMENTS_BY_TICKETID } from "../../../utls/constants";
import { useParams } from "react-router-dom";

const ActivityModal = (props) => {
  const {ticketSummary,setTicketSummary} = useContext(WorkOrderContext);
  const [ticketAssignments, setTicketAssignments] = useState([]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: 'numeric',
      hour12: true,
    };
    let formattedDate = date.toLocaleString('en-GB', options).replace(/\//g, '-').replace(',', '');
    // Adjust hour if it's midnight
    if (date.getHours() === 12) {
      formattedDate = formattedDate.replace('00:', '12:');
    }
    return formattedDate;
  };

  const {id} = useParams()

  const ticketId = Number(id)


  useEffect(() => {
    fetch(GET_TICKETASSIGNMENTS_BY_TICKETID(ticketId), {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
          "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: "Bearer " + localStorage.getItem("bearerToken"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setTicketAssignments(data);
      });
  },[]);
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-flex align-items-center gap-4 mb-2">
            <span className="fs-16 fw-bold">Activity Logs</span>
            <span className="fs-14" style={{ color: "#72777A" }}>
              Work order activity logs
            </span>
          </div>
          <div className="activity-4">
            {ticketAssignments?.map((item,index) => (
              <div className="activity-contents" key={index}>
                <div className="pluse-icon">
                  <FiPlus />
                </div>
                <div className="activity-text">
                  <div className="d-flex align-items-center justify-content-between pb-2">
                    <div className="fs-16 fw-bold orange-c">{item.createdByName}</div>
                    <div className="fs-14" style={{ color: "#6C5B51" }}>
                      {formatDate(item.createdDate)}
                    </div>
                  </div>
                  <div className="fs-16 fw-bold" style={{ color: "#6C5B51" }}>
                    {item.actionName} Work Order - {ticketSummary?.workOrderSummary?.ticketRef}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="delate-btn" onClick={props.onHide}>
              Close
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

/*const activityData = [
  {
    id: 0,
    name: "Vivian Keya",
    orderTitle: "CM-23-07-0005",
    desc: "Pump Leakage at Rongai Station",
    time: "23-01-2024 8:55 AM",
  },
  {
    id: 1,
    name: "Vivian Keya",
    orderTitle: "CM-23-07-0005",
    desc: "Pump Leakage at Rongai Station",
    time: "23-01-2024 8:55 AM",
  },
  {
    id: 2,
    name: "Vivian Keya",
    orderTitle: "CM-23-07-0005",
    desc: "Pump Leakage at Rongai Station",
    time: "23-01-2024 8:55 AM",
  },
  {
    id: 3,
    name: "Vivian Keya",
    orderTitle: "CM-23-07-0005",
    desc: "Pump Leakage at Rongai Station",
    time: "23-01-2024 8:55 AM",
  },
  {    
    id: 4,
    name: "Vivian Keya",
    orderTitle: "CM-23-07-0005",
    desc: "Pump Leakage at Rongai Station",
    time: "23-01-2024 8:55 AM",
  },
];*/

export default ActivityModal;
