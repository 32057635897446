import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import LoadingIcon from '../../../Assets/Icons/LoadingIcon';
import { ProgressBar } from 'react-bootstrap';
import DeleteIcon from '../../../Assets/Icons/DeleteIcon';
import CloseIcon from '../../../Assets/Icons/CloseIcon';
import CompletedIcon from '../../../Assets/Icons/CompletedIcon';
import CloudAddIcon from '../../../Assets/Icons/CloudAddIcon';
import { FileUploader } from 'react-drag-drop-files';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { addAssetFile, removeAssetFile } from '../../../redux/assetSlice';
import useMutateData from '../../../hooks/useMutateData';
import { fileToBase64 } from '../../../utls/convertToBase64';

const AddDocumentModal = ({ mode, ...props }) => {
  const fileTypes = ['PDF', 'JPEG', 'JPG', 'PNG', 'DOC', 'DOCX'];
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();
  const { mutate, isLoading: isSubmitting } = useMutateData({
    url: '/Assets/AddAssetDocument',
    method: 'POST',
    onSuccessfullMutation: (data) => {
      if (data?.data?.succeeded === true) {
        toast.success('Document added successfully', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        props.onHide();
      } else {
        toast.error('Failed to add document', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        props.onHide();
      }
    },
    successMessage: 'Document added successfully',
    errorMessage: 'Failed to add document',
    queryKeysToInvalidate: [["single-asset", parseInt(props.assetId)]],
  });

  const handleChange = (newFiles) => {
    const filesToAdd = Array.isArray(newFiles) ? newFiles : [...newFiles];

    const filteredFiles = filesToAdd.filter((newFile) => {
      return !files.some(
        (existingFile) =>
          existingFile.name === newFile.name &&
          existingFile.size === newFile.size
      );
    });

    filteredFiles.forEach((file) => {
      const fileType = file.name.split('.').pop().toUpperCase();
      if (!fileTypes.includes(fileType)) {
        toast.error('Invalid file type');
        return;
      }
    });

    if (filteredFiles.some((file) => file.size > 5 * 1024 * 1024)) {
      toast.error('File size should not exceed 5MB');
      return;
    }

    filteredFiles.forEach((file) => {
      file.status = 'Selected';
      file.uploading = 0;
    });

    setFiles((prevFiles) => [...prevFiles, ...filteredFiles]);
  };

  const handleDelete = (file) => {
    const newFiles = files.filter((f) => f.name !== file.name);
    setFiles(newFiles);
    if (mode === 'store') {
      dispatch(removeAssetFile(file.name));
    }
  };

  useEffect(() => {
    if (mode === 'store') {
      const convertFilesToBase64 = async () => {
        const newFiles = await Promise.all(
          files.map(async (file) => {
            if (file.status === 'Selected') {
              const encodedFile = await fileToBase64(file);
              const fileExtension = file.name.split('.').pop().toLowerCase();
              file.status = 'Completed';
              file.uploading = 100;

              dispatch(
                addAssetFile({
                  encodedFile,
                  fileName: file.name,
                  url: "",
                  fileType: fileExtension,
                })
              );
            }
            return file;
          })
        );
        setFiles(newFiles);
      };

      const timer = setTimeout(() => {
        convertFilesToBase64();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [files, dispatch, mode]);

  const handleSubmit = async() => {
    if (props.assetId && mode === 'upload') {
      const file = files[0]; // Only upload the first selected file
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const encodedFile = await fileToBase64(file);

      const payload = {
        assetId: Number(props.assetId),
        assetDocument: {
          encodedFile,
          fileName: file.name,
          fileType: "Image",
        },
      };
      mutate(payload);
      
    } else if (mode === 'store') {
      props.onHide();
    }
  };

  const truncateFileName = (fileName, maxLength) => {
    if (fileName.length > maxLength) {
      const endChars = fileName.slice(-4);
      const truncatedName = fileName.slice(0, maxLength - 4);
      return truncatedName + '...' + endChars;
    }
    return fileName;
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: '28px 30px' }}>
        <div className="delate-content">
          <div className="">
            <span className="fs-16 fw-bold ">Add Document</span>
            <span className="fs-14 ps-3">Select asset documents to add.</span>
          </div>
          <div className="browse-file-upload mt-4">
            <FileUploader
              hoverTitle=" "
              children={
                <div>
                  <CloudAddIcon />
                  <div
                    className="fs-16 fw-medium pt-3 pb-2"
                    style={{ color: '#292D32' }}
                  >
                    Choose a file or drag & drop it here
                  </div>
                  <div className="fs-16 fw-medium" style={{ color: '#A9ACB4' }}>
                    PDF, JPEG, PNG, DOC, and DOCX up to 5MB
                  </div>
                  <input
                    fileOrFiles="files"
                    type="file"
                    accept=".pdf,.jpeg,.jpg,.png,.doc,.docx"
                    style={{ display: 'none' }}
                    id="fileInput"
                  />
                  <label
                    htmlFor="fileInput"
                    className="browse-file cursor-pointer"
                  >
                    Browse File
                  </label>
                </div>
              }
              classes="cursor-pointer"
              maxFileSize={50}
              multiple={true}
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              onTypeError={(error) => toast.error(error)}
              onSizeError={(error) => toast.error(error)}
            />
          </div>
          <div className="d-grid gap-3 mt-3">
            {files.map((f) => (
              <div
                className="uploading-content d-flex align-items-center justify-content-between"
                key={f.name}
              >
                <div>
                  <div className="fs-16 fw-medium" style={{ color: '#292D32' }}>
                    {truncateFileName(f.name, 40)}
                  </div>
                  <div
                    className="fs-13 pt-1 pb-2"
                    style={{ color: '#A9ACB4' }}
                  >
                    {parseInt(f.size / 1024)} KB •
                    {f.status === 'Completed' && (
                      <span style={{ color: '#292D32', paddingLeft: '10px' }}>
                        <CompletedIcon /> {f.status}
                      </span>
                    )}
                    {f.status === 'Selected' && mode === 'upload' && (
                      <span style={{ color: '#292D32', paddingLeft: '10px' }}>
                        Selected
                      </span>
                    )}
                  </div>
                </div>
                <button
                  onClick={() => handleDelete(f)}
                  style={{ color: '#6C5B51' }}
                >
                  <DeleteIcon />
                </button>
              </div>
            ))}
          </div>
          <div
            className="button-group"
            style={{
              marginTop: '25px',
              display: 'flex',
              justifyContent: 'end',
              gap: '30px',
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            <button
              className="delate-btn"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Adding...' : 'Add'}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddDocumentModal;
