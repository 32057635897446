import React, { useState, useEffect } from "react";
import DownIcon from "../../Assets/Icons/DownIcon";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import InspectionTable from "./InspectionTable";
import getUniqueProperty from "../../utls/getUniqueProprty";
import { GET_INSPECTION_CATEGORIES } from "../../utls/constants";
import { get } from "../../Services/ApiHelper";

const InspectionContent = ({ inspectionData }) => {
  const [assetCategory, setAssetCategory] = useState(null);
  const [inspectionDataList, setInspectionDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const token = localStorage.getItem("bearerToken");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [allResponses, setAllResponses] = useState(null);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  useEffect(() => {
    setIsLoading(true);
    fetchInspectionData();
  }, [assetCategory, pageSize, pageNumber, debouncedSearchTerm, token]);

  const fetchInspectionData = () => {
    
    get(GET_INSPECTION_CATEGORIES(pageSize, pageNumber, debouncedSearchTerm, assetCategory), token).then((data) => {
      var result = data["data"];
      setInspectionDataList(result);
      setIsLoading(false);
      setAllResponses(data)
    });

  };

  const fetchInspectionData2 = () => {
    
    get(GET_INSPECTION_CATEGORIES(pageSize, pageNumber, debouncedSearchTerm), token).then((data) => {
      var result = data["data"];
      setInspectionDataList(result);
      setIsLoading(false);
      setAllResponses(data)
    });

  };

  
  const handleAssetClick = (assetId) => {
    setAssetCategory(assetId);
  };

  const handleAssetClick2 = () => {
    setAssetCategory(null)
    fetchInspectionData2();
  };

  const uniqueCategories = getUniqueProperty(inspectionData, "assetCategoryId", "assetCategoryName");

  const updatePageSize = (size) => {
    setPageSize(size);
  };

  const updatePageNumber = (number) => {
    setPageNumber(number);
  };


  return (
    <>
      <div className="inspection-cate">
        <div className="inspection-dropdown">
          <div className="dropdown select-dropdown">
            <button className="select-title" data-bs-toggle="dropdown">
              <span className="fs-13">Equipment Category</span>
              <span className="fs-14 d-flex align-items-center gap-1">
                {assetCategory === null ? "All" : uniqueCategories.find(category => category.id === assetCategory).name} <DownIcon />
              </span>
            </button>
            <ul className="dropdown-menu select-menu">
              <li onClick={() => handleAssetClick2()}>
                <b>All</b>
              </li>
              <hr />
              {uniqueCategories.map((item) => (
                <li key={item.id} onClick={() => handleAssetClick(item.id)}>
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
          <div>
            <form action="" className="search__box">
              <button type="submit">
                <SearchIcon />
              </button>
              <input
                type="search"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </form>
          </div>
        </div>
        <div className="inspection-tables-content">
          <InspectionTable
            inspectionData={allResponses?.data}
            allResponses={allResponses}
            updatePageSize={updatePageSize}
            pageSize={pageSize}
            pageNumber={pageNumber}
            updatePageNumber={updatePageNumber}
          />
        </div>
      </div>
    </>
  );
};

export default InspectionContent;