import React, { useState } from "react";
import DownIcon from "../../../Assets/Icons/DownIcon";
import SearchIcon from "../../../Assets/Icons/SearchIcon"; 
import ArrowLeft from "../../../Assets/Icons/ArrowLeft";
import ArrowRight from "../../../Assets/Icons/ArrowRight";
import DropdownIcon from "../../../Assets/Icons/DropdownIcon";
import getUniqueProperty from "../../../utls/getUniqueProprty";
import filterViaProperty from "../../../utls/filterViaProperty";
import searchAllProperty from "../../../utls/searchAllProperty"; 
import "react-datepicker/dist/react-datepicker.css"; 
import { Accordion } from "react-bootstrap";
import DeleteModal from "../../WorkOrders/Modal/DeleteModal";
import EyesIcon from "../../../Assets/Icons/EyesIcon";
import AddAssetPartsModal from "../../WorkOrders/Modal/AddAssetPartsModal";
import returned from "../../../Assets/Images/returnedIcon.svg"
import AssignAssetModal from "../../WorkOrders/Modal/AssignAssetModal";
import ReturnAssetModal from "../../WorkOrders/Modal/ReturnAssetModal";

const Assignment = () => {
  const [selectedteamTitle, setSelectedteamTitle] = useState(""); //category Desc Select
  const [selecteduserName, setSelecteduserName] = useState(""); //make Desc Select
  const [type, setType] = useState(""); //Type Select
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1); //for pagination
  const [RequestsPerPage, setRequestsPerPage] = useState(10); //for pagination
  const [search, setSearch] = useState(""); //for search
  const [RequestsShow, setRequestsShow] = useState(false); //for modal
  const [assetPartsShow, setAssetPartsShow] = useState(false); //for modal

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const ExampleCustomInput = ({ value, onClick }) => (
    <button className="example-custom-input" onClick={onClick}>
      {value ? value : ""} <DownIcon />
    </button>
  );

  const sortedRequestsData = RequestsData.sort((a, b) => {
    if (sortBy) {
      const valueA = a[sortBy];
      const valueB = b[sortBy];
      return sortOrder === "asc"
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    }
    return 0;
  });

  const handleteamTitleClick = (teamTitle) => {
    setSelectedteamTitle(teamTitle);
  };
  const handleuserNameClick = (userName) => {
    setSelecteduserName(userName);
  };

  //get request teamTitle & filter by teamTitle
  const teamTitles = getUniqueProperty(sortedRequestsData, "teamTitle");
  const filterByteamTitle = filterViaProperty(
    sortedRequestsData,
    selectedteamTitle,
    "teamTitle"
  );

  //get userName & filter by userName
  const userNames = getUniqueProperty(filterByteamTitle, "userName");
  const filterByuserName = filterViaProperty(
    filterByteamTitle,
    selecteduserName,
    "userName"
  );
  //Filter by type
  const filterByType = filterViaProperty(filterByuserName, type, "rejected");

  //filter via start date if date is not null
  const filteredByStartDate = startDate
    ? filterByType.filter((item) => {
        const date = new Date(item.submitDate);
        return date.getTime() >= startDate.getTime();
      })
    : filterByType;

  //filter via end date if date is not null
  const filteredByEndDate = endDate
    ? filteredByStartDate.filter((item) => {
        const date = new Date(item.submitDate);
        return date.getTime() <= endDate.getTime();
      })
    : filteredByStartDate;

  // filter via search
  const filteredBySearch = searchAllProperty(filteredByEndDate, search);

  //for pagination
  const indexOfLastRequests = currentPage * RequestsPerPage;
  const indexOfFirstRequests = indexOfLastRequests - RequestsPerPage;
  const currentRequests = filteredBySearch.slice(
    indexOfFirstRequests,
    indexOfLastRequests
  );

  // Calculate total pages
  const totalPages = Math.ceil(filteredBySearch.length / RequestsPerPage);

  // Update current page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <>
      <div className="order-request washroom-right-content responses-cont">
        <div className="washroom-title">
          <div className="fs-13 fw-medium">ASSET ASSIGNMENT</div>
        </div>
        <div className="order-request ProfileParts mt-4 p-0">
          <Accordion defaultActiveKey="0">
            {/* ASSIGNMENT LIST & HISTORY */}
            <Accordion.Item eventKey="0" className="request-box">
              <Accordion.Header className="request-box-title">
                <div className="fs-13 fw-medium">ASSIGNMENT LIST & HISTORY</div>
              </Accordion.Header>
              <Accordion.Body className="request-box-body p-0">
                <div className="text-end">
                  <button
                    onClick={() => setAssetPartsShow(true)}
                    style={{
                      fontSize: "14px",
                      color: "#D57D2A",
                      borderBottom: "1px solid #D57D2A",
                    }}
                  >
                    Assign Asset
                  </button>
                </div>
                <div className="selection-grope mt-3">
                  <div className="dropdown select-dropdown">
                    <button className="select-title" data-bs-toggle="dropdown">
                      <span className="fs-13">Team</span>
                      <span className="fs-14 d-flex align-items-center gap-1">
                        {selectedteamTitle} <DownIcon />
                      </span>
                    </button>
                    <ul className="dropdown-menu select-menu">
                      <li onClick={() => handleteamTitleClick("")}>
                        <b>All</b>
                      </li>
                      <hr />
                      <div className="dropdown-item-content">
                        {teamTitles.map((teamTitle, i) => (
                          <li
                            key={i}
                            onClick={() => handleteamTitleClick(teamTitle)}
                          >
                            {teamTitle}
                          </li>
                        ))}
                      </div>
                    </ul>
                  </div>

                  <div className="dropdown select-dropdown">
                    <button className="select-title" data-bs-toggle="dropdown">
                      <span className="fs-13">User</span>
                      <span className="fs-14 d-flex align-items-center gap-1">
                        {selecteduserName} <DownIcon />
                      </span>
                    </button>
                    <ul className="dropdown-menu select-menu">
                      <li onClick={() => handleuserNameClick("")}>
                        <b>All</b>
                      </li>
                      <hr />
                      <div className="dropdown-item-content">
                        {userNames.map((userName, i) => (
                          <li
                            key={i}
                            onClick={() => handleuserNameClick(userName)}
                          >
                            {userName}
                          </li>
                        ))}
                      </div>
                    </ul>
                  </div>

                  <form action="" className="search__box">
                    <button disabled type="submit">
                      <SearchIcon />
                    </button>
                    <input
                      onChange={(e) => setSearch(e.target.value)}
                      type="search"
                      placeholder="Search"
                    />
                  </form>
                </div>
                <div className="inspection-tables-content">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>
                            <span>User/ Team Assigned</span>
                          </th>
                          <th>
                            <span>Date Assigned</span>
                          </th>
                          <th>
                            <span>Date Returned</span>
                          </th>
                          <th className="table-th"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentRequests.map((item, i) => (
                          <tr key={i}>
                            <td style={{ minWidth: "160px" }}>
                              <div>
                                <div className="fw-semibold">
                                  {item.userName}
                                </div>
                                <div className="fw-regular gray-c">
                                  Team: {item.teamTitle}
                                </div>
                              </div>
                            </td>
                            <td style={{ minWidth: "160px" }}>
                              <div>
                                <div className="fw-semibold">
                                  {item.assigned}
                                </div>
                              </div>
                            </td>
                            <td style={{ minWidth: "160px" }}>
                              <div>
                                <div className="fw-semibold">
                                  {item.returned}
                                </div>
                              </div>
                            </td>

                            <td>
                              <div className="d-flex gap-1 upload-icons">
                                <button
                                  className="dropdown-item"
                                  style={{ color: "#6C5B51" }}
                                  onClick={() => setRequestsShow(true)}
                                >
                                  <img src={returned} alt="" />
                                </button> 
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="table-pagination pagination-center">
                    <div className="pagination-row">
                      Rows per page:
                      <div className="dropdown select-dropdown">
                        <button
                          className="select-title"
                          data-bs-toggle="dropdown"
                        >
                          <span className="fs-14 d-flex align-items-center gap-2">
                            {RequestsPerPage} <DropdownIcon />
                          </span>
                        </button>
                        <ul
                          className="dropdown-menu select-menu"
                          style={{ minWidth: "25px" }}
                        >
                          <li onClick={() => setRequestsPerPage(5)}>5</li>
                          <li onClick={() => setRequestsPerPage(10)}>10</li>
                          <li onClick={() => setRequestsPerPage(15)}>15</li>
                          <li onClick={() => setRequestsPerPage(20)}>20</li>
                        </ul>
                      </div>
                    </div>
                    <div className="pagination-number">
                      <div className="fs-14">
                        {currentPage} of {totalPages}
                      </div>
                      <div className="arrow-btn">
                        <button
                          onClick={() =>
                            currentPage !== 1 &&
                            handlePageChange(currentPage - 1)
                          }
                        >
                          <ArrowLeft />
                        </button>
                        <button
                          onClick={() =>
                            totalPages !== currentPage &&
                            handlePageChange(currentPage + 1)
                          }
                        >
                          <ArrowRight />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      <ReturnAssetModal show={RequestsShow} onHide={() => setRequestsShow(false)} />
      <AssignAssetModal
        show={assetPartsShow}
        onHide={() => setAssetPartsShow(false)}
      />
    </>
  );
};

export default Assignment;

const RequestsData = [
  {
    userName: "James Mburu",
    teamTitle: "Engineering",
    assigned: "04/05/2024 12:00 PM",
    returned: "Not Available",
  },
  {
    userName: "Kelvin Odero",
    teamTitle: "Engineering",
    assigned: "12/04/2024 8:50 AM",
    returned: "02/05/2024 10:45 AM",
  },
  {
    userName: "NONE",
    teamTitle: "Safaricom PLC",
    assigned: "01/03/2024 4:30 PM",
    returned: "10/04/2024 12:00 PM",
  },
  {
    userName: "Milkah Mumbi",
    teamTitle: "Maintenance",
    assigned: "20/02/2024 6:00 PM",
    returned: "01/03/2024 4:30 PM",
  },
  {
    userName: "Leah Kihoro",
    teamTitle: "Maintenance",
    assigned: "10/02/2024 12:00 PM",
    returned: "20/02/2024 6:00 PM",
  },
  {
    userName: "Kelvin Odero",
    teamTitle: "Engineering",
    assigned: "12/04/2024 8:50 AM",
    returned: "02/05/2024 10:45 AM",
  },
  {
    userName: "NONE",
    teamTitle: "Safaricom PLC",
    assigned: "01/03/2024 4:30 PM",
    returned: "10/04/2024 12:00 PM",
  },
  {
    userName: "Milkah Mumbi",
    teamTitle: "Maintenance",
    assigned: "20/02/2024 6:00 PM",
    returned: "01/03/2024 4:30 PM",
  },
  {
    userName: "Leah Kihoro",
    teamTitle: "Maintenance",
    assigned: "10/02/2024 12:00 PM",
    returned: "20/02/2024 6:00 PM",
  },
];
