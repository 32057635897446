import React, { useContext } from "react";
import RoutineInspectionCategoryContext from "../../../Context/Inspections/RoutineInspectionCategoryContext";
import useFormattedDate from "../../../Services/useFormattedDate";

const ResponsesSidebar = ({inspection}) => {

  const {inspectionDetailData, ongoingInspection} = useContext(RoutineInspectionCategoryContext)
  
  return (
    <div className="order-sidebar-content pe-2">
      <div className="fs-13 fw-medium">CONTINUOUS CHECKS DETAILS</div>

      <div className="washroom-sidebar mt-3">
        <div>
          <div className="fs-16 fw-bold pb-2" style={{ color: "#6C5B51" }}>{inspection.routineInspectionName}</div>
          <div className="fs-14 fw-bold mb-3" style={{ color: "#D57D2A" }}>{inspection.assetName
}</div>
          <div className="text-ii">
            <div className="fs-14 fw-medium">Location: </div>
            <div className="fs-14 fw-medium text-black">{inspection.locationName}</div>
          </div>
          <div className="text-ii">
            <div className="fs-14 fw-medium"> Description: </div>
            <div className="fs-14 fw-medium text-black">{inspection?.routineInspectionDescription}</div>
          </div>
          <div className="text-ii">
            <div className="fs-14 fw-medium"> Checklist:</div>
            <div className="fs-14 fw-medium text-black">
              {inspection?.checklistFormName}
            </div>
          </div>
          <div className="text-ii">
            <div className="fs-14 fw-medium">Equipment Category:</div>
            {/* <div className="fs-14 fw-medium">Asset Category:</div> */}
            <div className="fs-14 fw-medium text-black">
              {inspection?.assetCategoryName}
            </div>
          </div>
        </div>
        <div>
          <div className="fs-16 fw-bold pb-2" style={{ color: "#6C5B51" }}>Response</div>
          <div className="fs-16 fw-bold mb-3" style={{ color: "#D57D2A" }}>{ongoingInspection.inspectionCode}</div>
          <div className="text-ii">
            <div className="fs-14 fw-medium">Assigned Team</div>
            <div className="fs-14 fw-medium text-black">
              {inspection?.assignedTeamName}
            </div>
          </div>
          <div className="text-ii">
            <div className="fs-14 fw-medium"> Assigned Worker</div>
            <div className="fs-14 fw-medium text-black">
              {inspection?.assignedUserName}
            </div>
          </div>
          {inspection?.filledBy  && <div className="text-ii">
            <div className="fs-14 fw-medium">Completed By</div>
            <div className="fs-14 fw-medium text-black">
              {inspection?.filledBy}
            </div>
          </div>}
          
          {inspection.completedDate && <div className="text-ii">
            <div className="fs-14 fw-medium">Completed On</div>
            <div className="fs-14 fw-medium text-black">
            {new Date(inspection?.completedDate).toLocaleDateString("en-GB")}
            </div>
          </div> }

        </div>
      </div>


    </div>
  );
};

export default ResponsesSidebar;
