import React from "react"; 
 const EditHeader = () => { 
  return (
    <>
      <div className="work-header">
        <div className="fs-20">Edit Inspection Category</div>
        
      </div>
     </>
  );
};

export default EditHeader;