import React, { useContext, useState } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FaCircleExclamation } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import { RequestDetailsContext } from "../../../Context/Inspections/WorkOrderContext";
import { CLOSE_RFQ } from "../../../utls/constants";
import { toast } from "react-toastify";
import { get,post } from "../../../Services/ApiHelper";
import { GET_REQUEST_SUMMARY_BY_ID } from "../../../utls/constants";

const CloseQuotingModal = (props) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [extendingTime, setExtendTime] = useState(0);
  const { request, setRequest } = useContext(RequestDetailsContext);

  const handleClose = () => {
    setIsLoading(true);
    post(CLOSE_RFQ, { requestId: id }, localStorage.getItem("bearerToken"))
      .then((response) => {
        console.log(response, id);
        if (response.succeeded) {
          get(GET_REQUEST_SUMMARY_BY_ID(id), localStorage.getItem("bearerToken"))
            .then((result) => {
              setRequest(result);
            })
            .catch((error) => {
              console.log(error);
          });
          props.onHide();
          setIsLoading(false);
          //setExtendTime(0);
          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });
        } else {
          props.onHide();
          setIsLoading(false);
          //setExtendTime(0);
          toast.error(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });
        }
      })
      .catch((error) => {
        props.onHide();
        setIsLoading(false);
        //setExtendTime(0);
      });
  };
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="">
            <span className="fs-16 fw-bold ">Close Quoting</span>
            <span className="fs-14 ps-3">End the quoting session</span>
          </div>
          <div
            className="delate-warning"
            style={{
              background: "#F1EFEF",
              height: "267px",
              display: "grid",
              alignItems: "center",
              textAlign: "center",
              padding: "20px",
              marginTop: "30px",
              borderRadius: "5px",
            }}
          >
            <div>
              <FaCircleExclamation
                style={{ color: "#D57D2A", fontSize: "70px" }}
              />
              <div
                className="fs-20 fw-medium "
                style={{
                  lineHeight: "1.4",
                  marginTop: "10px",
                  color: "#292D32",
                }}
              >
                Please note that by closing RFQ/ Diagnosis, teams/contractors
                will not be able to <br className="d-none d-lg-block" /> submit
                any further quotes.
              </div>
            </div>
          </div>
          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            {isLoading ? (
              <Spinner
                animation="border"
                style={{
                  color: "#D57D2A",
                  //position: "absolute",
                  //right: "10%",
                  //top: "10%",
                }}
              />
            ) : (
              <button
                className="delate-btn"
                onClick={() => {
                  handleClose();
                }}
              >
                Close RFQ/ Diagnosis
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CloseQuotingModal;
