import React from 'react';

const NotificationIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="19" viewBox="0 0 14 19" fill="none">
            <path d="M7.00003 18.5C8.10378 18.5 8.9991 17.4928 8.9991 16.25H5.00097C5.00097 17.4928 5.89628 18.5 7.00003 18.5ZM13.731 13.2368C13.1272 12.5069 11.9975 11.409 11.9975 7.8125C11.9975 5.08086 10.295 2.89414 7.99941 2.35766V1.625C7.99941 1.00379 7.55191 0.5 7.00003 0.5C6.44815 0.5 6.00065 1.00379 6.00065 1.625V2.35766C3.70503 2.89414 2.00252 5.08086 2.00252 7.8125C2.00252 11.409 0.872835 12.5069 0.269084 13.2368C0.0815842 13.4635 -0.0015409 13.7346 2.16059e-05 14C0.00345911 14.5766 0.405647 15.125 1.00315 15.125H12.9969C13.5944 15.125 13.9969 14.5766 14 14C14.0016 13.7346 13.9185 13.4632 13.731 13.2368Z" fill="currentColor" />
        </svg>
    );
};

export default NotificationIcon;