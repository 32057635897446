import React, { useState } from "react";

const NameDescription = () => {
  const [selectedPriority, setSelectedPriority] = useState(null);

  const handlePriorityClick = (priority) => {
    setSelectedPriority(priority);
  };

  return (
    <>
      <div className="col-md-6">
        <label>Name</label>
        <input type="text" />
      </div>
      <div className="col-md-6">
        <label>Description</label>
        <input type="text" />
      </div>
      <div className="col-md-6">
        <label>Priority</label>
        <ul className="priority-list">
          <li>
            <button
              className={selectedPriority === "Low" ? "active" : ""}
              onClick={() => handlePriorityClick("Low")}
            >
              Low
            </button>
          </li>
          <li>
            <button
              className={selectedPriority === "Medium" ? "active" : ""}
              onClick={() => handlePriorityClick("Medium")}
            >
              Medium
            </button>
          </li>
          <li>
            <button
              className={selectedPriority === "High" ? "active" : ""}
              onClick={() => handlePriorityClick("High")}
            >
              High
            </button>
          </li>
          <li>
            <button
              className={selectedPriority === "Critical" ? "active" : ""}
              onClick={() => handlePriorityClick("Critical")}
            >
              Critical
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default NameDescription;
