import React from "react";

import { Link, NavLink, Outlet } from "react-router-dom";

const CreateNewAsset = () => {
  return (
    <div className="work-orders-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="work-header">
              <div className="fs-20"> Create Asset</div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="other-nav">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/assets">Assets</Link>
                </li>
                <li>
                  <Link>Create New Asset</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="order-details-area ">
              <div className="order-nav-content">
                <div className="fs-19 fw-bold">CREATE ASSET</div>
                <div className="order-nav-lists">
                  <NavLink to="/assets/create-new-asset" end>
                    Asset Profile
                  </NavLink>

                  <NavLink to="/assets/create-new-asset/documents">
                    Asset Documents
                  </NavLink>

                  <NavLink to="/assets/create-new-asset/summary">
                    Summary
                  </NavLink>
                </div>
              </div>
              <div className="work-order-contents">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewAsset;
