import React from "react";
import { Link, Outlet } from "react-router-dom";
import ViewLocationSidebar from "../Components/Locations/ViewLocation/ViewLocationSidebar";

const ViewLocation = () => {
  return (
    <div className="work-orders-area f3washroom-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="work-header">
              <div className="fs-20">View Location</div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="other-nav">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/locations">Locations</Link>
                </li>
                <li>
                  <Link>View Location</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="order-request-area view-order-content">
              <ViewLocationSidebar />
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewLocation;
