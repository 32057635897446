import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import useMutateData from "../../../hooks/useMutateData";
import { toast } from "react-toastify";

const EditIdentityInfoTwoModal = ({show, onHide, assetMake}) => {
  const [selectedMake, setSelectedMake] = useState("Select make");
  const [modelName, setModelName] = useState("")
  const [makeId, setMakeId] = useState(null)

  //asset category search input
  const [makeSearch, setMakeSearch] = useState("");

  const handleMakeSelect = (eventKey) => {
    const make = assetMake?.find((make)=> make?.makeName === eventKey)
    setSelectedMake(eventKey);
    setMakeId(make?.id)
    setMakeSearch("");
  };

  //Filter asset Make
  const filteredAssetMake = assetMake && assetMake?.filter((item) => {
    return item?.makeName?.toLowerCase().includes(makeSearch.toLowerCase());
  });

    // add new asset model mutation
    const { mutate, isLoading } = useMutateData({
      url: `/Assets/AddAssetModel`,
      method: "POST",
      onSuccessfullMutation: (data) => {
        if (data?.data?.succeeded === true) {
          toast.success(data?.data?.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          onHide(); // Hide modal
        } else {
          toast.error(data?.data?.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      },
      successMessage: `Successfully updated basic info`,
      errorMessage: "Basic info editing failed",
      queryKeysToInvalidate: [["asset-model"]],
    });

  const handleAddNewMake = () => {
    const requestBody = {
      assetMakeId: makeId,
      assetModel: modelName
    }
    mutate(requestBody)
  };

  return (
    <>
      <Modal
        // {...props}
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="medium-modal add-asset-modal"
      >
        <Modal.Body style={{ padding: "28px 30px" }}>
          <div className="delate-content">
            <div className="d-md-flex align-items-center gap-4">
              <span className="fs-16 fw-bold">Edit Identity Info</span>
              <div className="fs-14 pt-2 pt-md-0" style={{ color: "#72777A" }}>
                Add new asset model based on asset make
              </div>
            </div>
            <div className="mt-4 pt-2 row row-gap-4 modal-forms-content">

              <div className="col-md-12">
                <label className="d-flex align-items-center justify-content-between mb-2 fw-medium fs-14 pb-2">
                  Make
                </label>
                <Dropdown className="select__form" onSelect={handleMakeSelect}>
                  <Dropdown.Toggle
                    style={{
                      height: "50px",
                    }}
                    className={`select-title ${
                      selectedMake !== "Select make" ? "selected" : ""
                    }`}
                  >
                    {selectedMake}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <form className="dropdown-search">
                      <button disabled>
                        <SearchIcon />
                      </button>
                      <input
                        onChange={(e) => setMakeSearch(e.target.value)}
                        type="text"
                        placeholder="Search"
                        value={makeSearch}
                      />
                    </form>
                    <div className="dropdown-item-content">
                      {filteredAssetMake && filteredAssetMake?.map((item, index) => (
                        <Dropdown.Item key={index} eventKey={item?.makeName}>
                          {item?.makeName}
                        </Dropdown.Item>
                      ))}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="col-md-12">
                <label className="fw-medium fs-14 pb-2">Model</label>
                <input
                  className="modal-input-box"
                  type="text"
                  style={{
                    background: "#F1EFEF",
                    width: "100%",
                    border: "0",
                    height: "50px",
                    borderRadius: "5px",
                    padding: "0 15px",
                    fontSize: "14px",
                  }}
                  placeholder="Enter new model name"
                  onChange={(e)=> setModelName(e.target.value)}
                />
              </div>
            </div>

            <div
              className="button-group"
              style={{
                marginTop: "25px",
                display: "flex",
                justifyContent: "end",
                gap: "30px",
              }}
            >
              <button className="cancel-btn" onClick={onHide}>
                Cancel
              </button>
              <button className="delate-btn" onClick={()=> handleAddNewMake()}>
                Save
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditIdentityInfoTwoModal;
