import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import { toast } from "react-toastify";
import usefetchData from "../../../hooks/useFetchData";
import useMutateData from "../../../hooks/useMutateData";
import { useParams } from "react-router-dom";

const EditLocationModal = (props) => {
  const [selectCategory, setSelectCategory] = useState(props?.data?.regionName ?? "Select");
  const [selectMake, setSelectMake] = useState(props?.data?.parentLocationName ?? "Select");


  useEffect(()=> {
    console.log("props data", props?.data)
  },[props.data])

  const {id} = useParams()

  const locationId = parseInt(id);
  const [locationName, setLocationName] = useState(props?.data?.locationName ?? "");
  const [locationCode, setLocationCode] = useState(props?.data?.locationCode ?? "");
  const [locationAddress, setLocationAddress] = useState(props?.data?.locationAddress ?? "");
  const [longitude, setLongitude] = useState(props?.data?.longitude ?? null);
  const [latitude, setLatitude] = useState(props?.data?.latitude ?? null);
  const [regionId, setRegionId] = useState(props?.data?.regionId ?? null);
  const [parentLocationId, setParentLocationId] = useState(props?.data?.parentLocationId ?? null);
  const [personInChargeId, setPersonInChargeId] = useState(props?.data?.personInChargeId ?? null);
  const [contactPersonId, setContactPersonId] = useState(props?.data?.contactPersonId ?? null);
  const [contactPerson, setContactPerson] = useState(props?.data?.contactPersonName ?? "Select");
  const [personInCharge, setPersonInCharge] = useState(props?.data?.personInChargeName ?? "Select");

  // Fetch necessary data
  const { data: AssetMakeData } = usefetchData(["parent-location"], `Locations/GetAllParentLocations`, {}, "Error fetching AssetsMake grouping data");
  const { data: AssetCategoryData } = usefetchData(["location-regions"], `Locations/GetAllRegions`, {}, "Error fetching Asset Category data");
  const { data: contactPersonData } = usefetchData(["in-charge"], `Users/GetAllInternalUsers`, {}, "Error fetching internal users", true);

  // State for search inputs
  const [ACSearch, setACSearch] = useState("");
  const [AMSearch, setAMSearch] = useState("");
  const [personSearch, setPersonSearch] = useState("");

  // Filtered data for dropdowns
  const filteredACDData = AssetCategoryData?.filter((item) => item.regionName?.toLowerCase().includes(ACSearch.toLowerCase()));
  const filteredAMData = AssetMakeData?.filter((item) => item.parentLocationName?.toLowerCase().includes(AMSearch.toLowerCase()));
  const filteredInternalUsers = contactPersonData?.filter((item) => item.userFirstName.toLowerCase().includes(personSearch.toLowerCase()) || item.userLastName.toLowerCase().includes(personSearch.toLowerCase()));

  // Mutate function to edit location
  const { mutate, isLoading: isSubmitting } = useMutateData({
    url: "/Locations/EditLocation",
    method: "POST",
    onSuccessfullMutation: (data) => {
      if (data?.data?.succeeded === true) {
        toast.success("Location edited successfully", { position: "top-right", autoClose: 3000 });
        props.onHide();
      } else {
        toast.error("Failed to edit location", { position: "top-right", autoClose: 3000 });
      }
    },
    successMessage: "Location edited successfully",
    errorMessage: "Failed to edit location",
    queryKeysToInvalidate: [["location-summary", parseInt(locationId)]],
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      locationId,
      locationName,
      locationCode,
      locationAddress,
      longitude,
      latitude,
      regionId,
      parentLocationId,
      personInChargeId,
      contactPersonId,
    };
    console.log("payload edit", payload)
    mutate(payload);
  };

  // Handle select changes
  const handleSelectCategory = (eventKey) => {
    const selectedCategory = AssetCategoryData?.find((asset) => asset.regionName === eventKey);
    if (selectedCategory) {
      setSelectCategory(selectedCategory.regionName);
      setRegionId(selectedCategory.id);
      setACSearch("");
    }
  };

  const handleSelectMake = (eventKey) => {
    const selectMake = AssetMakeData?.find((asset) => asset.parentLocationName === eventKey);
    if (selectMake) {
      setSelectMake(eventKey);
      setParentLocationId(selectMake?.id);
      setAMSearch("");
    }
  };

  const handleSelectPersonInCharge = (eventKey) => {
    const selectedPerson = contactPersonData?.find((person) => `${person.userFirstName} ${person.userLastName}` === eventKey);
    if (selectedPerson) {
      setPersonInCharge(eventKey);
      setPersonInChargeId(selectedPerson.id);
      setPersonSearch("");
    }
  };

  const handleSelectContactPerson = (eventKey) => {
    const selectedContact = contactPersonData?.find((person) => `${person.userFirstName} ${person.userLastName}` === eventKey);
    if (selectedContact) {
      setContactPerson(eventKey);
      setContactPersonId(selectedContact.id);
      setPersonSearch("");
    }
  };

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered className="medium-modal modal-size-780 add-asset-modal">
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-md-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Edit Location</span>
            <div className="fs-14 pt-2 pt-md-0" style={{ color: "#72777A" }}>
              Edit location information
            </div>
          </div>
          <div className="row row-gap mt-3">
            {/* Location Name */}
            <div className="col-md-6">
              <label className="fw-medium pb-2">Location Name</label>
              <input
                className="modal-input-box"
                type="text"
                value={locationName}
                onChange={(e) => setLocationName(e.target.value)}
                style={{ background: "#F1EFEF", width: "100%", border: "0", height: "50px", borderRadius: "5px", padding: "0 15px" }}
                placeholder="Enter location name"
              />
            </div>
            {/* Location Code */}
            <div className="col-md-6">
              <label className="fw-medium pb-2">Location Code</label>
              <input
                className="modal-input-box"
                type="text"
                value={locationCode}
                onChange={(e) => setLocationCode(e.target.value)}
                style={{ background: "#F1EFEF", width: "100%", border: "0", height: "50px", borderRadius: "5px", padding: "0 15px" }}
                placeholder="Enter location code"
              />
            </div>
             {/* Location Address */}
             <div className="col-md-12">
              <label className="fw-medium pb-2">Location Address</label>
              <input
                className="modal-input-box"
                type="text"
                value={locationAddress}
                onChange={(e) => setLocationAddress(e.target.value)}
                style={{ background: "#F1EFEF", width: "100%", border: "0", height: "50px", borderRadius: "5px", padding: "0 15px" }}
                placeholder="Enter location address"
              />
            </div>
            {/* Longitude */}
            <div className="col-md-6">
              <label className="fw-medium pb-2">Longitude</label>
              <input
                className="modal-input-box"
                type="text"
                value={longitude}
                onChange={(e) => setLongitude(e.target.value)}
                style={{ background: "#F1EFEF", width: "100%", border: "0", height: "50px", borderRadius: "5px", padding: "0 15px" }}
                placeholder="Enter longitude"
              />
            </div>
            {/* Latitude */}
            <div className="col-md-6">
              <label className="fw-medium pb-2">Latitude</label>
              <input
                className="modal-input-box"
                type="text"
                value={latitude}
                onChange={(e) => setLatitude(e.target.value)}
                style={{ background: "#F1EFEF", width: "100%", border: "0", height: "50px", borderRadius: "5px", padding: "0 15px" }}
                placeholder="Enter latitude"
              />
            </div>
            {/* Location Grouping */}
            <div className="col-md-6">
              <label className="fw-medium pb-2">Location Grouping</label>
              <Dropdown className="select__form" onSelect={handleSelectCategory}>
                <Dropdown.Toggle className={`select-title ${selectCategory !== "Select location region" ? "selected" : ""}`} style={{ height: "50px" }}>
                  {selectCategory}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled><SearchIcon /></button>
                    <input onChange={(e) => setACSearch(e.target.value)} value={ACSearch} type="text" placeholder="Search" />
                  </form>
                  <div className="dropdown-item-content">
                    {filteredACDData && filteredACDData.map((item, index) => (
                      <Dropdown.Item key={index} eventKey={item.regionName}>{item.regionName}</Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {/* Parent Location */}
            <div className="col-md-6">
              <label className="fw-medium pb-2">Parent Location</label>
              <Dropdown className="select__form" onSelect={handleSelectMake}>
                <Dropdown.Toggle className={`select-title ${selectMake !== "Select parent location" ? "selected" : ""}`} style={{ height: "50px" }}>
                  {selectMake}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled><SearchIcon /></button>
                    <input onChange={(e) => setAMSearch(e.target.value)} value={AMSearch} type="text" placeholder="Search" />
                  </form>
                  <div className="dropdown-item-content">
                    {filteredAMData && filteredAMData.map((item, index) => (
                      <Dropdown.Item key={index} eventKey={item.parentLocationName}>{item.parentLocationName}</Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {/* Person In-Charge */}
            <div className="col-md-6">
              <label className="fw-medium pb-2">Person In-Charge</label>
              <Dropdown className="select__form" onSelect={handleSelectPersonInCharge}>
                <Dropdown.Toggle className={`select-title ${personInCharge !== "Select person in-charge" ? "selected" : ""}`} style={{ height: "50px" }}>
                  {personInCharge}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled><SearchIcon /></button>
                    <input onChange={(e) => setPersonSearch(e.target.value)} value={personSearch} type="text" placeholder="Search" />
                  </form>
                  <div className="dropdown-item-content">
                    {filteredInternalUsers && filteredInternalUsers.map((item, index) => (
                      <Dropdown.Item key={index} eventKey={`${item.userFirstName} ${item.userLastName}`}>{`${item.userFirstName} ${item.userLastName}`}</Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {/* Contact Person */}
            <div className="col-md-6">
              <label className="fw-medium pb-2">Contact Person</label>
              <Dropdown className="select__form" onSelect={handleSelectContactPerson}>
                <Dropdown.Toggle className={`select-title ${contactPerson !== "Select contact person" ? "selected" : ""}`} style={{ height: "50px" }}>
                  {contactPerson}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled><SearchIcon /></button>
                    <input onChange={(e) => setPersonSearch(e.target.value)} value={personSearch} type="text" placeholder="Search" />
                  </form>
                  <div className="dropdown-item-content">
                    {filteredInternalUsers && filteredInternalUsers.map((item, index) => (
                      <Dropdown.Item key={index} eventKey={`${item.userFirstName} ${item.userLastName}`}>{`${item.userFirstName} ${item.userLastName}`}</Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="button-group" style={{ marginTop: "25px", display: "flex", justifyContent: "end", gap: "30px" }}>
            <button className="cancel-btn" onClick={props.onHide}>Cancel</button>
            <button className="delate-btn" onClick={handleSubmit} disabled={isSubmitting}>Edit</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditLocationModal;
