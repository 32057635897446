/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from "react";
import { HiDotsVertical } from "react-icons/hi";
import ViewIcon2 from "../../../Assets/Icons/ViewIcon2";
import EditIcon2 from "../../../Assets/Icons/EditIcon2";
import DelateIcon2 from "../../../Assets/Icons/DelateIcon2";
import ModalInspection from "../ModalInspection";
import ArrowLeft from "../../../Assets/Icons/ArrowLeft";
import ArrowRight from "../../../Assets/Icons/ArrowRight";
import DropdownIcon from "../../../Assets/Icons/DropdownIcon";
import { Link } from "react-router-dom";
import RoutineInspectionCategoryContext from "../../../Context/Inspections/RoutineInspectionCategoryContext";

const WashroomTable = ({ washroomData }) => {
  const [currentPage, setCurrentPage] = useState(1); //for pagination
  const [washroomPerPage, setWashroomPerPage] = useState(5); //for pagination
  const [inspectionShow, setInspectionShow] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder((prevSortOrder) =>
        prevSortOrder === "asc" ? "desc" : "asc"
      );
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const sortedWashroomData = washroomData.sort((a, b) => {
    if (sortBy) {
      const valueA = a[sortBy];
      const valueB = b[sortBy];
      return sortOrder === "asc"
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    }
    return 0;
  });

  //for pagination
  const indexOfLastInspection = currentPage * washroomPerPage;
  const indexOfFirstInspection = indexOfLastInspection - washroomPerPage;
  const currentWashrooms = sortedWashroomData.slice(
    indexOfFirstInspection,
    indexOfLastInspection
  );

  // Calculate total pages
  const totalPages = Math.ceil(sortedWashroomData.length / washroomPerPage);

  // Update current page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const {setCategoryIdData} = useContext(RoutineInspectionCategoryContext);

  const handleLinkClick = (item) => {
    // Store data in local storage
    localStorage.setItem('categoryId', JSON.stringify(item));

    // Set data in context
    setCategoryIdData(item);
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th
                className={
                  sortBy === "asset" ? `table-sort-${sortOrder}` : "table-sort"
                }
                onClick={() => handleSort("asset")}
              >
                <span>Asset</span>
              </th>
              <th
                className={
                  sortBy === "location"
                    ? `table-sort-${sortOrder}`
                    : "table-sort"
                }
                onClick={() => handleSort("location")}
              >
                <span>Location</span>
              </th>
              <th className="table-th">Last Inspection</th>
              <th className="table-th">Next Inspection</th>
              {/* <th
                className={
                  sortBy === "team" ? `table-sort-${sortOrder}` : "table-sort"
                }
                onClick={() => handleSort("team")}
              >
                <span>Team</span>
              </th>
              <th
                className={
                  sortBy === "worker" ? `table-sort-${sortOrder}` : "table-sort"
                }
                onClick={() => handleSort("worker")}
              >
                <span>Worker</span>
              </th> */}
              <th className="table-th"></th>
            </tr>
          </thead>
          <tbody>
            {currentWashrooms.map((item) => (
              <tr key={item.id}>
                <td>{item.assetName}</td>
                <td>{item.locationName}</td>
                <td>{item.lastInspetionDate ?? "Not Available"}</td>
                <td>{item.nextInspectionDate ?? "Not Available"}</td>
                {/* <td>{item.assignedTeamName ?? "Not Available"}</td>
                <td>{item.assignedUserName?? "Not Available"}</td> */}
                <td>
                  <div className="table-last-btn">
                    <span className="number-new">{item.count} New</span>
                    <div className="dropdown table-edit-dropdown">
                      <button className="" data-bs-toggle="dropdown">
                        <HiDotsVertical />
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end">
                        <li>
                          <Link className="dropdown-item" 
                          to="/inspection-category/washroom/f3washroom"
                          state={{ data: item }}
                          >
                            <ViewIcon2 /> View
                          </Link>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            onClick={() => setInspectionShow(true)}
                          >
                            <EditIcon2 /> Edit
                          </button>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <DelateIcon2 /> Delete
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="table-pagination pagination-center">
        <div className="pagination-row">
          Rows per page:
          <div className="dropdown select-dropdown">
            <button className="select-title" data-bs-toggle="dropdown">
              <span className="fs-14 d-flex align-items-center gap-2">
                {washroomPerPage} <DropdownIcon />
              </span>
            </button>
            <ul
              className="dropdown-menu select-menu"
              style={{ minWidth: "25px" }}
            >
              <li onClick={() => setWashroomPerPage(5)}>5</li>
              <li onClick={() => setWashroomPerPage(10)}>10</li>
              <li onClick={() => setWashroomPerPage(15)}>15</li>
              <li onClick={() => setWashroomPerPage(20)}>20</li>
            </ul>
          </div>
        </div>
        <div className="pagination-number">
          <div className="fs-14">
            {currentPage} of {totalPages}
          </div>
          <div className="arrow-btn">
            <button
              onClick={() =>
                currentPage !== 1 && handlePageChange(currentPage - 1)
              }
            >
              <ArrowLeft />
            </button>
            <button
              onClick={() =>
                totalPages !== currentPage && handlePageChange(currentPage + 1)
              }
            >
              <ArrowRight />
            </button>
          </div>
        </div>
      </div>

      <ModalInspection
        show={inspectionShow}
        onHide={() => setInspectionShow(false)}
      />
    </>
  );
};

export default WashroomTable;
