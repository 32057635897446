import React, { useState } from "react";
import DownIcon from "../../../Assets/Icons/DownIcon";
import AddIcon from "../../../Assets/Icons/AddIcon";
import ApproveRequestModal from "../../WorkOrders/Modal/ApproveRequestModal";
import CreateOrderModal from "../../WorkOrders/Modal/CreateOrderModal";
import RequestQuotesDiagnosisModal from "../../WorkOrders/Modal/RequestQuotesDiagnosisModal";
import CloseQuotingModal from "../../WorkOrders/Modal/CloseQuotingModal";
import ExtendRFQTimeModal from "../../WorkOrders/Modal/ExtendRFQTimeModal";

const ViewDiagnosisQuotesHeader = () => {
  const [approveShow, setApproveShow] = useState(false);
  const [createOrderShow, setCreateOrderShow] = useState(false);
  const [createOrderErrorShow, setCreateOrderErrorShow] = useState(false);
  const [closeQuotingShow, setCloseQuotingShow] = useState(false);
  const [ExtendTime, setExtendTime] = useState(false);

  return (
    <>
      <div className="work-header">
        <div className="fs-20">View Diagnosis & Quotes</div>
        <div className="dropdown select-dropdown">
          {/* <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-15 d-flex align-items-center gap-2">
              Actions <DownIcon />
            </span>
          </button> */}
          {/* <ul className="dropdown-menu dropdown-menu-end select-menu">
            <li>
              <button onClick={() => setApproveShow(true)}>
                <AddIcon /> Approve/ Decline
              </button>
              <ApproveRequestModal
                show={approveShow}
                onHide={() => setApproveShow(false)}
              />
            </li>
            <li>
              <button onClick={() => setCreateOrderShow(true)}>
                <AddIcon /> Create Work Order
              </button>
              <CreateOrderModal
                show={createOrderShow}
                onHide={() => setCreateOrderShow(false)}
              />
            </li>
            <li>
              <button onClick={() => setCreateOrderErrorShow(true)}>
                <AddIcon /> RFQ & Diagnosis
              </button>
              <RequestQuotesDiagnosisModal
                show={createOrderErrorShow}
                onHide={() => setCreateOrderErrorShow(false)}
              />
            </li>
            <li>
              <button
                className="text-nowrap"
                onClick={() => setCloseQuotingShow(true)}
              >
                <AddIcon /> Close RFQ & Diagnosis
              </button>
              <CloseQuotingModal
                show={closeQuotingShow}
                onHide={() => setCloseQuotingShow(false)}
              />
            </li>
            <li>
              <button
                className="text-nowrap"
                onClick={() => setExtendTime(true)}
              >
                <AddIcon /> Extend Quoting Time
              </button>
              <ExtendRFQTimeModal
                show={ExtendTime}
                onHide={() => setExtendTime(false)}
              />
            </li>
          </ul> */}
        </div>
      </div>
    </>
  );
};

export default ViewDiagnosisQuotesHeader;
