import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import useMutateData from "../../../hooks/useMutateData";
import { toast } from "react-toastify";

const EditAssetCategoryModal = (props) => {
  console.log("props categorry data", props);
  const [categoryName, setCategoryName] = useState("");
  const [description, setDescription] = useState("");

  // edit asset category mutation
  const { mutate, isLoading } = useMutateData({
    url: `/Assets/EditAssetCategory`,
    method: "POST",
    onSuccessfullMutation: (data) => {
      console.log("Recall mutation data", data);
      if (data?.data?.succeeded === true) {
        toast.success(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        props.onHide();
      } else {
        toast.error(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
    successMessage: `Successfully recalled costing`,
    errorMessage: "Work order creation failed",
    queryKeysToInvalidate: [["asset-category", props.searchText]],
  });

  const editCategory = () => {
    const requestBody = {
      id: props.data.id,
      assetCategoryName: categoryName || props?.data?.assetCategoryName,
      assetCategoryDescription:
        description || props?.data?.assetCategoryDescription,
    };
    mutate(requestBody);
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-md-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Edit Asset Category</span>
            <div className="fs-14 pt-2 pt-md-0" style={{ color: "#72777A" }}>
              Edit asset category information
            </div>
          </div>
          <div className="mt-4 pt-2 row row-gap-4 modal-forms-content">
            <div className="col-md-12">
              <label className="fw-medium fs-14 pb-2">Asset Category</label>
              <input
                className="modal-input-box"
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "0 15px",
                  fontSize: "14px",
                }}
                placeholder="Enter Asset Category"
                defaultValue={props?.data?.assetCategoryName ?? ""}
                onChange={(e) => setCategoryName(e.target.value)}
              />
            </div>
            <div className="col-md-12">
              <label className="fw-medium fs-14 pb-2">Description</label>
              <textarea
                className="modal-input-box"
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "140px",
                  borderRadius: "5px",
                  padding: "15px 15px",
                  fontSize: "14px",
                }}
                placeholder="Enter asset category description"
                defaultValue={props?.data?.assetCategoryDescription ?? ""}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            <button className="delate-btn" onClick={() => editCategory()}>
              {isLoading ? <div>Editting...</div> : "Edit"}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditAssetCategoryModal;
