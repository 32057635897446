import React, { useState } from "react";
import EditIcon from "../../../Assets/Icons/EditIcon";
import DeleteIcon from "../../../Assets/Icons/DeleteIcon";

const OtherInformation = () => {
  const [description, setDescription] = useState("Lunch");
  const [category, setCategory] = useState("Daily Upkeep");
  const [amount, setAmount] = useState("2,000");
  const [isEdit, setIsEdit] = useState(false);
  return (
    <div className="table-responsive-sm request_table">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Description</th>
            <th scope="col">Category</th>
            <th scope="col">Amount(Ksh)</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input
                type="text"
                readOnly={!isEdit}
                value={description}
                className={isEdit ? "border edit-input" : "edit-input"}
                onChange={(e) => setDescription(e.target.value)}
              />
            </td>
            <td>
              <input
                type="text"
                readOnly={!isEdit}
                value={category}
                className={isEdit ? "border edit-input" : "edit-input"}
                onChange={(e) => setCategory(e.target.value)}
              />
            </td>
            <td>
              <input
                type="text"
                readOnly={!isEdit}
                value={amount}
                className={isEdit ? "border edit-input" : "edit-input"}
                onChange={(e) => setAmount(e.target.value)}
              />
            </td>
            <td>
              <div className="button-con">
                <button onClick={() => setIsEdit(!isEdit)}>
                  <EditIcon />
                </button>
                <button>
                  <DeleteIcon />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default OtherInformation;
