import React, { useContext, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { RequestDetailsContext } from "../../../Context/Inspections/WorkOrderContext";
import EyesIcon from "../../../Assets/Icons/EyesIcon";
import ViewImage from "../../WorkOrders/Modal/ViewImage";
import ViewVideo from "../../WorkOrders/Modal/ViewVideo";
import ViewDocument from "../../WorkOrders/Modal/ViewDocument";
import CompleteRequestModal from "../../WorkOrders/Modal/CompleteRequestModal";
import EditRequestModal from "../../WorkOrders/Modal/EditRequestModal";
import { RiDeleteBinLine } from "react-icons/ri";
import AttachIcon from "../../../Assets/Icons/AttachIcon";
import { toast } from "react-toastify";
import { get, post } from "../../../Services/ApiHelper";
import { FaUpload } from "react-icons/fa6";
import { BASE_URL, BASE_URL1, GET_REQUEST_SUMMARY_BY_ID } from "../../../utls/constants";
import AddJobCardModal from "../../WorkOrders/Modal/AddJobCardModal";
import AddFileModal from "../../WorkOrders/Modal/AddFileModal";
import AddRequestsFileModal from "../../WorkOrders/Modal/AddRequestFilesModal";

const ViewRequestSummary = () => {
  const [imageShow, setImageShow] = useState({
    isShow: false,
    image: "",
    file_name: "",
    isFileLoading: false,
  });
  const [videoShow, setVideoShow] = useState({
    isShow: false,
    video: "",
    file_name: "",
  });
  const [documentShow, setDocumentShow] = useState({
    isShow: false,
    document: "",
    file_name: "",
  });

  const { request, setRequest } = useContext(RequestDetailsContext);
  const [editShow, setEditShow] = useState(false);

  const [reFetch, setReFetch] = useState(false);
  const [loading, setLoading] = useState(true);

  // const id = request?.requestSummaryDetailDto?.id

  const fetchRequestSummaryById = (id) => {
    get(GET_REQUEST_SUMMARY_BY_ID(id), localStorage.getItem("bearerToken"))
      .then((response) => {
        setRequest(response);
      })
      .catch((error) => {
        console.log("An Error Occurred");
        setLoading(false);
      });
  };

  const handleModalSuccess = () => {
    fetchRequestSummaryById(request?.requestSummaryDetailDto?.id); 
  };

  useEffect(() => {}, [request]);

  const toggleEdit = () => {
    setEditShow(!editShow);
  };

  console.log("request", request);
  useEffect(() => {
    console.log(
      "request",
      request?.requestSummaryDetailDto?.requestFiles
    );
  }, [request]);

  const [attachments, setAttachments] = useState([]);
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const bearerToken = localStorage.getItem("bearerToken");

  // Handle file input change
  const handleFileChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    const newFiles = selectedFiles.filter(
      (file) => !attachments.includes(file.name)
    );

    // Update state with file names and files
    setAttachments((prevAttachments) => [
      ...prevAttachments,
      ...newFiles.map((file) => file.name),
    ]);
    setAttachmentFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  // Convert file to Base64 format
  const encodeFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]); // Get only Base64 part
      reader.onerror = (error) => reject(error);
    });
  };

  const Base_Url = BASE_URL;
  const Base_Url1 = BASE_URL1;

  // Prepare payload and post files
  const postFiles = async () => {
    const files = await Promise.all(
      attachmentFiles.map(async (file) => ({
        encodedFile: await encodeFileToBase64(file),
        fileName: file.name,
        url: "", // Include if there is an URL to reference
        fileType: file.type,
      }))
    );

    const payload = {
      requestId: request?.requestSummaryDetailDto?.id,
      files: files,
    };

    // Here you would typically post the payload to your endpoint
    console.log("Payload:", payload);
    // Example POST request (uncomment and replace with your actual endpoint)
    fetch(Base_Url + "/Requests/AddRequestFile", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bearerToken}`, // Add token here
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // setReFetch(!reFetch);
      })
      .catch((error) => console.error("Error posting files:", error));
  };

  // const deleteFileUpload = async (fileId) => {

  //   const payload = {
  //     requestFileId: parseInt(fileId),
  //   }

  //   try {
  //     const response = await fetch("https://saharadesktestapi.azurewebsites.net/api/Requests/DeleteRequestFile", {
  //       method: "POST",
  //       headers: {
  //         "Authorization": `Bearer ${bearerToken}`,
  //       },
  //       body: JSON.stringify(payload),
  //     });

  //     if (!response.ok) {
  //       throw new Error("Failed to delete file");
  //     }

  //     setAttachments((prev) => prev.filter((file) => file.id !== fileId));
  //     console.log("File deleted successfully");
  //   } catch (error) {
  //     console.error("Error deleting file:", error);
  //   }
  // };

  const URL_LINK = Base_Url + "/Requests/DeleteRequestFile";

  const deleteFileUpload = async (fileId) => {
    try {
      const bearerToken = localStorage.getItem("bearerToken");
      const payload = {
        requestFileId: parseInt(fileId),
      };
      await post(URL_LINK, payload, bearerToken); // Use the post helper function
  
      toast.success("File Deleted Successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
  
      // Update the request context by removing the deleted file
      setRequest((prevRequest) => {
        if (!prevRequest || !prevRequest.requestSummaryDetailDto) return prevRequest;
        
        // Filter out the deleted file from requestFiles
        const updatedRequestFiles = prevRequest.requestSummaryDetailDto.requestFiles.filter(
          (file) => file.id !== fileId
        );
  
        // Return the updated request object
        return {
          ...prevRequest,
          requestSummaryDetailDto: {
            ...prevRequest.requestSummaryDetailDto,
            requestFiles: updatedRequestFiles,
          },
        };
      });
    } catch (error) {
      console.error("Error deleting item:", error);
      toast.error("An error occurred", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
  

  const deleteFile = (file) => {
    setAttachments((prevAttachments) =>
      prevAttachments.filter((attachment) => attachment !== file)
    );
    setAttachmentFiles((prevFiles) =>
      prevFiles.filter((attachment) => attachment !== file)
    );
  };

  const [filesCardShow, setFilesCardShow] = useState(false);

  return (
    <>
      <div className="order-request washroom-right-content responses-cont">
        <div className="washroom-title">
          <div
            className="fs-13 fw-medium"
            // style={{ marginBottom: "30px" }}
          >
            REQUEST DETAILS
          </div>
          <div className="fs-13 fw-bold">
            Status:{" "}
            <button className="primary-text fw-bold">
              {request?.requestSummaryDetailDto?.defaultRequestStatus?.toUpperCase()}
            </button>
          </div>
        </div>
        <div className="fs-13 fw-semibold pt-5 gray-c d-flex flex-wrap justify-content-between">
          REQUEST INFORMATION
          <button
            className="primary-text fw-bold"
            style={{
              fontWeight: "500",
              fontSize: "14px",
              color: "#D57D2A",
              borderBottom: "1px solid #D57D2A",
            }}
            onClick={toggleEdit}
          >
            Edit
          </button>
        </div>
        <hr style={{ margin: "10px 0 10px", borderColor: "#D0D0D0" }} />
        <div className="row row-gap-4 pt-3">
          <div className="col-md-6">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061" }}
            >
              Faulty Asset:
            </div>
            <div className="fs-14 fw-medium" style={{ lineHeight: "1.3" }}>
              {request?.requestSummaryDetailDto?.asset?.name}
            </div>
          </div>

          <div className="col-md-3">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061" }}
            >
              Fault(s):
            </div>
            {request?.requestSummaryDetailDto?.requestFaults?.map(
              (item, index) => (
                <div key={index} className="fs-14 fw-medium">{item.name}</div>
              )
            )}
          </div>
          <div className="col-md-3">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061" }}
            >
              Recurrence:
            </div>
            <div className="fs-14 fw-medium">
              {request?.requestSummaryDetailDto?.recurrenceStatus
                ? "Recurring Fault"
                : "First Time Fault"}
            </div>
          </div>
          <div className="col-md-6">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061" }}
            >
              Fault Description:
            </div>
            <div className="fs-14 fw-medium" style={{ lineHeight: "1.3" }}>
              {request?.requestSummaryDetailDto?.faultDescription}
            </div>
          </div>
          <div className="col-md-3">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061" }}
            >
              Location:
            </div>
            <div className="fs-14 fw-medium">
              {request?.requestSummaryDetailDto?.location?.name}{" "}
            </div>
          </div>
          <div className="col-md-3">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061" }}
            >
              Submitted By:
            </div>
            <div className="fs-14 fw-medium">
              {request?.requestSummaryDetailDto?.submittedBy}
            </div>
          </div>
          <div className="col-md-3">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061" }}
            >
              Date Submitted:
            </div>
            <div className="fs-14 fw-medium">
              {new Date(
                request?.requestSummaryDetailDto?.submittedDate
              ).toLocaleDateString("en-Gb", {
                day: "numeric",
                month: "short",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}{" "}
            </div>
          </div>
          <div className="col-md-3">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061" }}
            >
              Operational Status:
            </div>
            <div className="fs-14 fw-medium">
              {request?.requestSummaryDetailDto?.operationalStatus?.name ||
                "N/A"}
            </div>
          </div>
        </div>

        {/* ASSET DOCUMENTS */}
        <div className="fs-13 fw-semibold pt-5 gray-c d-flex flex-wrap justify-content-between">
          ATTACHED FILES
          <button
            onClick={() => setFilesCardShow(true)}
            style={{
              fontWeight: "500",
              fontSize: "14px",
              color: "#D57D2A",
              borderBottom: "1px solid #D57D2A",
            }}
          >
            Add Files
          </button>
        </div>
        <hr style={{ margin: "10px 0 10px", borderColor: "#D0D0D0" }} />

        <div
          className=""
          style={{ position: "relative"}}
        >
          {/* <label
            htmlFor="fileInput"
            className=""
            style={{ cursor: "pointer" }}
          >
            <input
              type="file"
              id="fileInput"
              onChange={handleFileChange}
              multiple
              style={{ display: "none" }}
            />
          </label> */}
          <span
            className="d-grid gap-2"
            style={{ width: "100%" }}
          >
            {attachments.length > 0 ? (
              <div
                style={{
                  width: "100%",
                  top: "140%",
                  height: "fit-content",
                  overflow: "scroll",
                }}
              >
                {/* <p style={{ marginBottom: "20px", color: "#00000061" }}>
                  Documents Uploaded
                </p> */}
                {attachments.map((attachment, index) => (
                  <div
                    key={index}
                    className="fs-14 fw-medium text-black"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      height: "54px",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      borderBottom: "1px solid #00000061",
                      marginTop: "10px",
                    }}
                  >
                    <p
                      style={{
                        color: "#D57D2A",
                        textDecoration: "underline",
                        fontSize: "16px",
                        fontWeight: "500",
                        letterSpacing: "-0.33px",
                        lineHeight: "18.83px",
                      }}
                    >
                      {attachment}
                    </p>
                    <button
                      onClick={() => deleteFile(attachment)}
                      className="ms-2"
                      style={{
                        color: "#D57D2A",
                        fontSize: "16px",
                        gap: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {/* <IoIosEye size={25} color="#6C5B51" onClick={() => handleShowImg()}/> */}
                      <RiDeleteBinLine size={22} color="#6C5B51" />
                    </button>
                  </div>
                ))}
              </div>
            ) : (
              <span
                className="fs-15 fw-medium text-black "
                style={{ fontStyle: "italic" }}
              >
                {/* No file chosen */}
              </span>
            )}
          </span>
        </div>

        {request?.requestSummaryDetailDto?.requestFiles.length > 0 ? (
          <>
            <div className="py-3">
              <div className="fs-14 fw-medium black-38">Files Uploaded</div>

              {request?.requestSummaryDetailDto?.requestFiles?.map((x) => (
                <div className="upload-details" key={x.id}>
                  <div
                    className="fs-14"
                    href={x.url}
                    download
                    style={{ color: "#D57D2A" }}
                  >
                    {x.fileName}
                  </div>
                  <div className="upload-icons" style={{ float: "right" }}>
                    <button
                      onClick={() => {
                        const url = x.url;
                        const ext = url.substring(url.lastIndexOf(".") + 1);
                        ext === "jpg" ||
                        ext === "jpeg" ||
                        ext === "png" ||
                        ext === "JPG" ||
                        ext === "JPEG" ||
                        ext === "PNG"
                          ? setImageShow({
                              isShow: true,
                              image: x.url,
                              file_name: x.fileName,
                            })
                          : ext === "pdf" || ext === "PDF"
                          ? setDocumentShow({
                              isShow: true,
                              document: x.url,
                              file_name: x.fileName,
                            })
                          : (ext === "mp4" ||
                              ext === "AVI" ||
                              ext === "3GP" ||
                              ext === "MP4" ||
                              ext === "avi" ||
                              ext === "3gp") &&
                            setVideoShow({
                              isShow: true,
                              video: x.url,
                              file_name: x.fileName,
                            });
                      }}
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <EyesIcon />
                    </button>
                    <button>
                      <RiDeleteBinLine
                        size={22}
                        color="#6C5B51"
                        onClick={() => deleteFileUpload(x.id)}
                      />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div
            className="fs-14 fw-medium"
            style={{
              color: "rgba(0, 0, 0, 0.38)",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          >
            No Uploaded Files
          </div>
        )}
        <div className="fs-13 fw-semibold pt-5 gray-c d-flex flex-wrap justify-content-between">
          APPROVAL INFORMATION
        </div>
        <hr style={{ margin: "10px 0 10px", borderColor: "#D0D0D0" }} />
        <div className="row row-gap-4 pt-3">
          <div className="col-md-6">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061" }}
            >
              Approval Status:
            </div>
            <div className="fs-14 fw-medium" style={{ lineHeight: "1.3" }}>
              {request?.requestSummaryDetailDto?.statusName}
            </div>
          </div>
          <div className="col-md-3">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061" }}
            >
              Approved/Declined By:
            </div>
            <div className="fs-14 fw-medium">
              {request?.requestSummaryDetailDto?.modifiedBy ?? "N/A"}
            </div>
          </div>
          <div className="col-md-3">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061" }}
            >
              Work Order Ref:
            </div>
            <div className="fs-14 fw-medium">
              {request?.requestSummaryDetailDto?.workOrderRef ?? "N/A"}
            </div>
          </div>
          <div className="col-md-6">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061" }}
            >
              Approval/Declining Comments:
            </div>

            <div className="fs-14 fw-medium" style={{ lineHeight: "1.3" }}>
              {request?.requestSummaryDetailDto?.declineApproveComments ??
                "N/A"}
            </div>
          </div>
          {/* {approval.map((item, index) => (
                    <div className="request_item" style={{marginBottom: "10px"}} key={index}>
                      <div
                        className="fs-14 fw-medium"
                        style={{ color: "rgba(0, 0, 0, 0.38)" }}
                      >
                        {item.title}
                      </div>
                      <div
                        className="fs-14 fw-medium"
                        style={{ color: "rgba(88, 69, 57, 0.87)" }}
                      >
                        {item.desc}
                      </div>
                    </div>
                  ))} */}
        </div>
      </div>
      <AddRequestsFileModal
        show={filesCardShow}
        onHide={() => setFilesCardShow(false)}
        onSuccess={handleModalSuccess}
        requestId={request?.requestSummaryDetailDto?.id}
      />
      <ViewImage show={imageShow} onHide={() => setImageShow(false)} />
      <ViewVideo show={videoShow} onHide={() => setVideoShow(false)} />
      <ViewDocument show={documentShow} onHide={() => setDocumentShow(false)} />
      <EditRequestModal show={editShow} onHide={toggleEdit} requestData={request}/>
    </>
  );
};

export default ViewRequestSummary;
