import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import usefetchData from "../../../hooks/useFetchData";
import {
  setLocationName,
  setLocationDesription,
  setLocationAddress,
  setLongitude,
  setLatitude,
  setParentLocation,
  setContactPerson,
  setRegion,
  setEngineerInCharge,
  setInchargeName
} from '../../../redux/locationSlice'
const LocationDetails = () => {
  const dispatch = useDispatch();
  const locationState=useSelector((state)=>state.location);

  const[locationName,setLocationNameInput]=useState(locationState.locationName || '')
  const[locationAddress,setLocationAddressInput]=useState(locationState.locationAddress || '');
  const[longitude,setLongitudeInput]=useState(locationState.longitude || '')
  const[latitude,setLatitudeInput]=useState(locationState.latitude || '')
  const[InChargeName,setInchargeNameInput]=useState(locationState.InChargeName || '')
  const[contactPerson,setContactPersonInput]=useState(locationState.contactPerson || '')
   //asign team search input
   const [LGSearch, setLGSearch] = useState("");

   //category of work search input
   const [PLSearch, setPPLSearch] = useState("");

  const { data: LGData = [] } = usefetchData(
    ['locationsgrouping-data'],
    `/Locations/GetAllRegionsWithSearch`,
    {},
    'Error fetching locations grouping data'
  );
  const { data: PLData = [] } = usefetchData(
    ['locations-data'],
    `/Locations/GetAllParentLocations`,
    {},
    'Error fetching parent location data'
  );
  const { data: ContactPersonData = [] } = usefetchData(
    ['locations-data'],
    `/Users/GetAllInternalUsers`,
    {},
    'Error fetching parent location data'
  );
  const { data: PersonInChargeData = [] } = usefetchData(
    ['locations-data'],
    `/Users/GetAllInternalUsers`,
    {},
    'Error fetching parent location data'
  );
console.log(LGData)
  const [selectedParentLocation, setselectedParentLocation] = useState(
    ""
  );
  

  useEffect(()=>{
    setLocationNameInput(locationState.locationName);
    setLocationAddressInput(locationState.locationAddress);
    setLongitudeInput(locationState.longitude)
    setLatitudeInput(locationState.latitude);
    setInchargeNameInput(locationState.InChargeName);
  },[locationState]);

  //filter category of work data
  const filteredWorkData = PLData.filter((item) => {
    return item.parentLocationName?.toLowerCase().includes(PLSearch.toLowerCase());
  });
  //filter assign team data
  const filteredCOWData = LGData.filter((item) => {
    return item.regionName?.toLowerCase().includes(LGSearch.toLowerCase());
  });
  //  //filter person in charge data
  //  const filteredPICData = PersonInChargeData.filter((item) => {
  //   return item.PersonInCharge?.toLowerCase().includes(LGSearch.toLowerCase());
  // });
  // //filter person in charge data
  // const filteredCIPData = ContactPersonData.filter((item) => {
  //   return item.contactPerson?.toLowerCase().includes(LGSearch.toLowerCase());
  // });


  const handleLocationGroupingSelect = (eventKey) => {
    const selectedLocationGrouping = LGData.find(location => location.regionName === eventKey);
    if (selectedLocationGrouping) {
      dispatch(setRegion({ id: selectedLocationGrouping.id, name: selectedLocationGrouping.regionName }));
      setLGSearch('');
    }
  };
  const handleParentLocation = (eventKey) => {
    const selectedParentLocation =PLData.find(location => location.parentLocationName === eventKey);
    if (selectedParentLocation) {
      dispatch(setParentLocation({ id: selectedParentLocation.id, name: selectedParentLocation.parentLocationName }));
      setPPLSearch('');
    } 
  };
  // const handlePersonInCharge = (eventKey) => {
  //   const selectedPersonInCharge =PLData.find(location => location.parentLocationName === eventKey);
  //   if (selectedParentLocation) {
  //     dispatch(setParentLocation({ id: selectedParentLocation.id, name: selectedParentLocation.parentLocationName }));
  //     setPPLSearch('');
  //   } 
  // };
  // const ContactPerson = (eventKey) => {
  //   const selectedParentLocation =PLData.find(location => location.pName === eventKey);
  //   if (selectedParentLocation) {
  //     dispatch(setParentLocation({ id: selectedParentLocation.id, name: selectedParentLocation.parentLocationName }));
  //     setPPLSearch('');
  //   } 
  // };

  return (
    <>
    <div className="order-details-content PMWorkScheduling pb-lg-4">
      <div className="fs-16 fw-bold" style={{paddingBottom: "34px"}}>Location Details</div>

      <div className="row details-forms-one">
        <div className="col-12">
          <label>Location Name</label>
          <input type="text"
          placeholder="Enter Location Name"
          value={locationName}
          onChange={(e)=>{
            setLocationNameInput(e.target.value);
            dispatch(setLocationName(e.target.value));
          }} />
        </div>
        <div className="col-md-6 ">
          <label>Location Grouping</label>
          <Dropdown
            className="select__form"
            onSelect={handleLocationGroupingSelect}
          >
            <Dropdown.Toggle
              className={`select-title ${
                locationState.regionName  !== ""? "Select": ''  }`}
            >
             {locationState.regionName || 'select  location grouping from list'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <form className="dropdown-search">
                <button disabled>
                  <SearchIcon />
                </button>
                <input
                  value={LGSearch}
                  onChange={(e) => setLGSearch(e.target.value)}
                  type="text"
                  placeholder="Search"
                />
              </form>
              <div className="dropdown-item-content">
                {filteredCOWData.map((item, index) => (
                  <Dropdown.Item key={index} eventKey={item.regionName}>
                    {item.regionName}
                  </Dropdown.Item>
                ))}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-md-6">
          <label>Parent Location</label>
          <Dropdown className="select__form" onSelect={handleParentLocation}>
            <Dropdown.Toggle
              className={`select-title ${
                locationState.parentLocationName  !== ""? "Select": '' }`}
            >
            {locationState.parentLocationName || 'select parent location from list'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <form className="dropdown-search">
                <button disabled>
                  <SearchIcon />
                </button>
                <input
                  value={PLSearch}
                  onChange={(e) => setPPLSearch(e.target.value)}
                  type="text"
                  placeholder="Search"
                />
              </form>
              <div className="dropdown-item-content">
                {filteredWorkData.map((item, index) => (
                  <Dropdown.Item key={index} eventKey={item.parentLocationName}>
                    {item.parentLocationName}
                  </Dropdown.Item>
                ))}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-md-6 ">
          <label>Longitude</label>
          <input type="text"
          placeholder="Enter Longitude"
          value={longitude}
          onChange={(e)=>{
            setLongitudeInput(e.target.value);
            dispatch(setLongitude(e.target.value));
          }} />
        </div>
        <div className="col-md-6">
        <label>Latitude</label>
        <input type="text"
          placeholder="Enter Latitude"
          value={latitude} 
          onChange={(e)=>{
            setLatitudeInput(e.target.value);
            dispatch(setLatitude(e.target.value));
          }} />
        </div>
        <div className="col-12">
          <label>Address</label>
          <textarea name="Description"
          placeholder="Enter Address"
          value={locationAddress}
          onChange={(e)=>{
            setLocationAddressInput(e.target.value)
            dispatch(setLocationAddress(e.target.value))
          }}></textarea>
        </div>
        <div className="col-md-6 ">
          <label>Person in Charge</label>
          <Dropdown
            className="select__form"
            // onSelect={handleLocationGroupingSelect}
          >
            <Dropdown.Toggle
              className={`select-title ${
                selectedParentLocation !== "Select grouping from list"
                  ? "selected"
                  : ""
              }`}
            >
              {/* {selectedLocationGrouping} */}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <form className="dropdown-search">
                <button disabled>
                  <SearchIcon />
                </button>
                <input
                  // value={LGSearch}
                  // onChange={(e) => setLGSearch(e.target.value)}
                  // type="text"
                  // placeholder="Search"
                />
              </form>
              <div className="dropdown-item-content">
                {/* {filteredCOWData.map((item, index) => (
                  <Dropdown.Item key={index} eventKey={item.name}>
                    {item.name}
                  </Dropdown.Item>
                ))} */}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-md-6">
          <label>Contact Person</label>
          <input type="text"
          placeholder="Enter Contact person"
          value={contactPerson} 
          onChange={(e)=>{
            setContactPersonInput(e.target.value);
            dispatch(setContactPerson(e.target.value));
          }} />
        </div>
       
      </div>

      <div className="details-buttons d-flex justify-content-end">
        <Link to="/locations/new-location/summary" className="next-btn ms-0">
          Next
        </Link>
      </div>
    </div>
  </>
  );
};

export default LocationDetails;
