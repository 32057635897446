import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { NavLink, useLocation, useParams } from "react-router-dom";
import ActivityModal from "../../WorkOrders/Modal/ActivityModal";

const ViewWorkCostsSide = () => {
  const [eventKey, setEventKey] = useState("");
  const [activityShow, setActivityShow] = useState(false);
  //get url by using useLocation
  const location = useLocation();
  const { id } = useParams();

  useEffect(() => {
    if (location.pathname === `/work-costs/view/${id}`) {
      setEventKey("0");
    } else if (location.pathname === `/work-costs/view/${id}/costing`) {
      setEventKey("1");
    }
  }, [id, location]);

  return (
    eventKey && (
      <>
        <div className="order-sidebar-content p-0">
          <div className="p-4">
            <div className="fs-13 fw-medium">
              DIAGNOSIS & QUOTES INFORMATION
            </div>
            <div className="washroom-sidebar mt-3 align-items-end">
              <div>
                <div
                  className="fs-16 fw-bold pb-2"
                  style={{ color: "#6C5B51" }}
                >
                  Request Reference
                </div>
                <div className="fs-20 fw-bold" style={{ color: "#D57D2A" }}>
                  1000001
                </div>
              </div>
              <div style={{ textAlign: "right" }}>
                <button
                  onClick={() => setActivityShow(true)}
                  className="fs-16 fw-bold"
                  style={{ color: "#D57D2A", textDecoration: "underline" }}
                >
                  Activity Tracker
                </button>
                <ActivityModal
                  show={activityShow}
                  onHide={() => setActivityShow(false)}
                />
              </div>
            </div>
          </div>

          <Accordion defaultActiveKey={eventKey} className="view-work-sidebar">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <NavLink to={`/work-costs/view/${id}`} end>
                  Work Summary
                </NavLink>
              </Accordion.Header>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <NavLink to={`/work-costs/view/${id}/costing`}>Costing</NavLink>
              </Accordion.Header>
            </Accordion.Item>
          </Accordion>
        </div>
      </>
    )
  );
};

export default ViewWorkCostsSide;
