import React, { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import DownIcon from "../../../Assets/Icons/DownIcon";
import { useEffect } from "react";
import { get, post } from "../../../Services/ApiHelper";
import {
  ADD_TICKET_COSTED_ITEMS,
  GET_TICKET_LABOUR_COST,
  GET_TICKET_LABOUR_COST_BY_TICKET_ID,
  GET_WORKORDER_DETAILS_BY_ID,
} from "../../../utls/constants";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";
import { set, update } from "lodash";

import { toast } from "react-toastify";

import { Spinner } from "react-bootstrap";
import Astericks from "../../Common/Asterick";
import { useDispatch } from "react-redux";
import useMutateData from "../../../hooks/useMutateData";
import usefetchData from "../../../hooks/useFetchData";

const AddLabourCostsModal = (props) => {
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [selectedAssetIds, setSelectedAssetIds] = useState([]);
  const [labour, setLabour] = useState([]);
  const [showChecklistList, setShowChecklistList] = useState(false);
  const [selectLabourCostSearch, setSelectLabourCostSearch] = useState("");
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);
  const [labourCostdata, setLabourCostData] = useState([]);
  const [submit, setSubmit] = useState(false);
  const token = localStorage.getItem("bearerToken");
  // const [isLoading, setIsLoading] = useState(false);

  const ticketId = ticketSummary?.workOrderSummary?.ticketId;

  useEffect(() => {
    let existingData = [];
    setLabourCostData([]);
    get(GET_TICKET_LABOUR_COST_BY_TICKET_ID(ticketId), token)
      .then((result) => {
        existingData =
          ticketSummary?.costingSummary?.workOrderCostingBasis
            ?.costedLabourList || [];

        const uniqueData =
          result.length !== 0
            ? result?.filter(
                (item) =>
                  !existingData?.some((t) => {
                    return t.userName === item.userName;
                  })
              )
            : [];

        // Set the filtered data
        setLabourCostData(existingData.length === 0 ? result : uniqueData);
      })
      .catch((error) => console.log("Error fetching data:", error));
    setSelectedAssetIds([]);
    setSelectedAssets([]);
    setLabourItems([]);
  }, [props.show]);

  const [labourItems, setLabourItems] = useState([]);
  const handleCheckboxChange = (asset, item) => {
    setSubmit(true);
    const id = item.ticketItemId;
    setSelectedAssets((prevSelectedAssets) => {
      if (prevSelectedAssets.includes(asset)) {
        return prevSelectedAssets.filter(
          (selectedAsset) => selectedAsset !== asset
        );
      } else {
        return [...prevSelectedAssets, asset];
      }
    });
    setSelectedAssetIds((prevSelectedAssetIds) => {
      if (prevSelectedAssetIds.includes(id)) {
        return prevSelectedAssetIds.filter(
          (selectedAssetId) => selectedAssetId !== id
        );
      } else {
        return [...prevSelectedAssetIds, id];
      }
    });
  };
  const costedLabourList = [];
  const obj = {
    quantity: 0,
    unitCost: 0,
    teamName: "",
    ticketItemId: 0,
    totalCost: 0,
    userName: "",
    userId: 0,
  };
  useEffect(() => {
    if (submit) {
      // get all items from labourData where id is in selectedAssetIds
      const selectedLabourItems = labourCostdata?.filter((item) =>
        selectedAssetIds.includes(item.ticketItemId)
      );

      setLabourItems(selectedLabourItems);
    }
    setSubmit(false);
  }, [submit]);

  const [assetError, setAssetError] = useState(null);

  const validateInputs = () => {
    let isValid = true;

    setAssetError(null);

    if (selectedAssets.length === 0) {
      setAssetError("Required *");
      isValid = false;
    }

    return isValid;
  };

  //filter labour cost data  by search input
  const filteredLabourCostData = labourCostdata?.filter((item) =>
    item.userName.toLowerCase().includes(selectLabourCostSearch.toLowerCase())
  );
  const { mutate, isLoading } = useMutateData({
    url: ADD_TICKET_COSTED_ITEMS,
    method: "POST",
    onSuccessfullMutation: (data) => {
      if (data?.succeeded === true) {
        toast.success(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        toast.success(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
    successMessage: `Otek`,
    errorMessage: "dad",
    queryKeysToInvalidate: [["ticket-summary", ticketId]],
  });

  const onSubmit = () => {
    const requestBody = { ticketItemIdList: selectedAssetIds };

    mutate(requestBody);
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Add Labour Costs</span>
            <span className="fs-14" style={{ color: "#72777A" }}>
              You can only include costs from labour cost list
            </span>
          </div>
          <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">
                  Select Labour Costs
                  <Astericks />
                </label>
                {assetError && <p style={{ color: "red" }}>{assetError}</p>}
              </div>
              <div className="checklist-box select__form">
                <button
                  className="checklist-btn"
                  onClick={() => setShowChecklistList(!showChecklistList)}
                >
                  {selectedAssets.length === 0 ? (
                    <div>
                      <span style={{ color: "#C5C7CD" }}>
                        Select costs from list
                      </span>
                      {selectedAssets.length > 0 && (
                        <span style={{ color: "#000" }}>
                          {selectedAssets.join(", ")}
                        </span>
                      )}
                    </div>
                  ) : (
                    <span style={{ color: "#000" }}>
                      {selectedAssets.join(", ")}
                    </span>
                  )}
                  <DownIcon />
                </button>
                <div
                  className={`checklist-list ${
                    showChecklistList ? "" : "hide d-none"
                  }`}
                >
                  <form className="dropdown-search ps-0">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      onChange={(e) =>
                        setSelectLabourCostSearch(e.target.value)
                      }
                      type="text"
                      placeholder="Search"
                    />
                  </form>
                  <ul className="dropdown-item-content mt-2">
                    {filteredLabourCostData?.map((item, index) => (
                      <li key={index}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id={item.userName}
                            checked={selectedAssets.includes(item.userName)}
                            onChange={() =>
                              handleCheckboxChange(item.userName, item)
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor={item.userName}
                          >
                            {item.userName +
                              ` (${item.totalCost.toLocaleString("en-US", {
                                style: "currency",
                                currency: "KES",
                              })})`}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            {/* <Link to="" className="delate-btn" onClick={(e)=>handleSubmit(e)}> */}
            {isLoading ? (
              <Spinner
                animation="border"
                style={{
                  color: "#D57D2A",
                }}
              />
            ) : (
              <Link to="" className="delate-btn" onClick={onSubmit}>
                Add
              </Link>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddLabourCostsModal;
