import React, { useState } from "react";
import EditIcon from "../../../Assets/Icons/EditIcon";
import DeleteIcon from "../../../Assets/Icons/DeleteIcon";

const Labour = () => {
  const [staffName, setStaffName] = useState("John Doe");
  const [team, setTeam] = useState("A - Z Engineering");
  const [amount, setAmount] = useState("Ksh 32,000");
  const [isEdit, setIsEdit] = useState(false);
  return (
    <>
      <div className="table-responsive-sm request_table">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Staff Name</th>
              <th scope="col">Team</th>
              <th scope="col">Cost</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input
                  type="text"
                  readOnly={!isEdit}
                  value={staffName}
                  className={isEdit ? "border edit-input" : "edit-input"}
                  onChange={(e) => setStaffName(e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  readOnly={!isEdit}
                  value={team}
                  className={isEdit ? "border edit-input" : "edit-input"}
                  onChange={(e) => setTeam(e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  readOnly={!isEdit}
                  value={amount}
                  className={isEdit ? "border edit-input" : "edit-input"}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </td>
              <td>
                <div className="button-con">
                  <button onClick={() => setIsEdit(!isEdit)}>
                    <EditIcon />
                  </button>
                  <button>
                    <DeleteIcon />
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Labour;
