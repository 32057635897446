import React, { useState, useContext, useEffect } from "react";
import DeleteIcon from "../../../Assets/Icons/DeleteIcon";
import AddPartsCostsModal from "../Modal/AddPartsCostsModal";
import AddLabourCostsModal from "../Modal/AddLabourCostsModal";
import AddOtherCostsModal from "../Modal/AddOtherCostsModal";
import DeleteModal from "../Modal/DeleteModal";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";
import { useLocation, useParams } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import AgreedRates from "./Costings/AgreedRates";
import Quote from "./Costings/Quote";
import ManualInput from "./Costings/ManualInput";
import {
  DELETE_TICKET_LABOUR_COST,
  DELETE_TICKET_OTHER_COST,
  DELETE_TICKET_PART_COST,
} from "../../../utls/constants";
import { toast } from "react-toastify";
import { post } from "../../../Services/ApiHelper";
import usefetchData from "../../../hooks/useFetchData";
import useMutateData from "../../../hooks/useMutateData";
import Manual from "./Costings/Manual";

const ViewCostings = () => {
  const { setTicketSummary, isLoading, setIsLoading } =
    useContext(WorkOrderContext);
  const [eventKey, setEventKey] = React.useState("");
  const [costsShow, addCostsShow] = useState(false);
  const [labourCostsShow, setLabourCostsShow] = useState(false);
  const [otherCostsShow, setOtherCostsShow] = useState(false);
  const [delateModalShow, setDelateModalShow] = useState(false);
  const [partsCosting, setPartsCosting] = useState(false);
  const [labourCosting, setLabourCosting] = useState(false);
  const [otherCosting, setOtherCosting] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const CompanyDetails = JSON.parse(localStorage.getItem("company"));
  const [showNav, setShowNav] = useState(true);
  //get url by using useLocation

  const { id } = useParams();
  const ticketId = Number(id);

  // ticket summary data
  const { data: ticketSummary, isLoading: isLoadingTicket } = usefetchData(
    ["ticket-summary", ticketId],
    `Tickets/GetTicketSummaryById/${id}`,
    {},
    "Couldn't get ticket summary",
    true
  );

  console.log("view costing page", ticketSummary)

  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname ===
      `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}`
    ) {
      setEventKey("0");
    } else if (
      location.pathname ===
      `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}/assets`
    ) {
      setEventKey("1");
    } else if (
      location.pathname ===
      `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}/costings`
    ) {
      setEventKey("2");
    } else if (
      location.pathname ===
      `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}/job-cards`
    ) {
      setEventKey("3");
    }
  }, [location, ticketSummary]);

  const allPartData = ticketSummary?.costingSummary;

  const partsData = ticketSummary?.costingSummary?.costedUsedPartList;
  const labourData = ticketSummary?.costingSummary?.costedLabourList;
  const otherData = ticketSummary?.costingSummary?.costedOtherList;
  // Function to sum up the total values in an array of items
  const getTotalSum = (dataArray) => {
    if (!dataArray) return 0;
    return dataArray.reduce((totalSum, currentItem) => {
      return totalSum + currentItem.quantity * currentItem.unitCost;
    }, 0);
  };
  // Get the total sum of each array
  const totalPartsSum = getTotalSum(partsData);
  const totalLabourSum = getTotalSum(labourData);
  const totalOtherSum = getTotalSum(otherData);
  // Get the overall total sum
  const overallTotalSum = totalPartsSum + totalLabourSum + totalOtherSum;

  const handleDelete = async () => {
    setIsLoading(false);
    try {
      const bearerToken = localStorage.getItem("bearerToken");

      const endPoint = partsCosting
        ? DELETE_TICKET_PART_COST
        : labourCosting
        ? DELETE_TICKET_OTHER_COST
        : otherCosting
        ? DELETE_TICKET_OTHER_COST
        : "";

      const data = { id: itemIdToDelete };
      await post(endPoint, data, bearerToken);

      let updatedCostingList = null;

      if (partsCosting) {
        updatedCostingList =
          ticketSummary.costingSummary.costedUsedPartList.filter(
            (item) => item.id !== itemIdToDelete
          );
      } else if (labourCosting) {
        updatedCostingList =
          ticketSummary.costingSummary.costedLabourList.filter(
            (item) => item.id !== itemIdToDelete
          );
      } else if (otherCosting) {
        updatedCostingList =
          ticketSummary.costingSummary.costedOtherList.filter(
            (item) => item.id !== itemIdToDelete
          );
      }

      setTicketSummary((prevState) => ({
        ...prevState,
        costingSummary: {
          ...prevState.costingSummary,
          costedUsedPartList: partsCosting
            ? updatedCostingList
            : prevState.costingSummary.costedUsedPartList,
          costedLabourList: labourCosting
            ? updatedCostingList
            : prevState.costingSummary.costedLabourList,
          costedOtherList: otherCosting
            ? updatedCostingList
            : prevState.costingSummary.costedOtherList,
        },
      }));

      toast.success("Data Deleted Successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setIsLoading(true);

      setTimeout(() => {
        setDelateModalShow(false);
        setIsLoading(false);
      }, 3000);

      setPartsCosting(false);
      setLabourCosting(false);
      setOtherCosting(false);
    } catch (error) {
      console.error("Error deleting item:", error);
      toast.error("An error occured", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const { priceListCostingBasis, workOrderCostingBasis, manualCostingBasis, quoteCostingBasis } = ticketSummary?.costingSummary || {};

  const submittedBasis = [priceListCostingBasis, workOrderCostingBasis, manualCostingBasis, quoteCostingBasis].find(basis => basis?.isSubmitted);

  const result = submittedBasis 
    ? submittedBasis.approvalLevelStateName === "Approved" || submittedBasis.approvalLevelStateName === "Rejected"
      ? submittedBasis.approvalLevelStateName
      : "Submitted Pending Approval"
    : "Pending Submission";

  const getDefaultActiveKey = () => {
    if (submittedBasis) {
      if (priceListCostingBasis?.isSubmitted) return "first";
      if (quoteCostingBasis?.isSubmitted) return "second";
      if (workOrderCostingBasis?.isSubmitted) return "third";
      if (manualCostingBasis?.isSubmitted) return "fourth";
    }
    return "first";
  };

  const shouldShowTab = (key) => {
    if (!submittedBasis) return true;
    if (key === "first" && priceListCostingBasis?.isSubmitted) return true;
    if (key === "second" && quoteCostingBasis?.isSubmitted) return true;
    if (key === "third" && workOrderCostingBasis?.isSubmitted) return true;
    if (key === "fourth" && manualCostingBasis?.isSubmitted) return true;
    return false;
  };

  return (
    eventKey && (
      <>
        <div className="order-request washroom-right-content responses-cont">
          <div className="washroom-title">
            <div className="fs-13 fw-medium">WORK ORDER COSTINGS</div>
            <div className="fs-13 fw-bold">
              Status:
              <button className="primary-text fw-bold">
                {result}
              </button>
            </div>
          </div>
          <div className="order-request mt-4 p-0 view-costings">
            <div className="request-box">
              <div className="request-box-body py-3 mt-0 px-10">
                {!submittedBasis && (
                  <div className="fs-15 fw-bold" style={{ color: "#6C5B51" }}>
                    Choose costing basis
                  </div>
                )}

                <Tab.Container defaultActiveKey={getDefaultActiveKey()}>
                  <Nav variant="pills" className="workCostings-nav">
                    {shouldShowTab("first") && (
                      <Nav.Item>
                        <Nav.Link eventKey="first">
                          Agreed rates
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {shouldShowTab("second") && (
                      <Nav.Item>
                        <Nav.Link eventKey="second">Quote</Nav.Link>
                      </Nav.Item>
                    )}
                    {shouldShowTab("third") && (
                      <Nav.Item>
                        <Nav.Link eventKey="third">
                          Work Costs
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {shouldShowTab("fourth") && (
                      <Nav.Item>
                        <Nav.Link eventKey="fourth">
                          Manual input
                        </Nav.Link>
                      </Nav.Item>
                    )}
                  </Nav>

                  <Tab.Content>
                    {shouldShowTab("first") && (
                      <Tab.Pane eventKey="first">
                        <AgreedRates
                          setShowNav={true}
                          ticketSummary={ticketSummary}
                        />
                      </Tab.Pane>
                    )}
                    {shouldShowTab("second") && (
                      <Tab.Pane eventKey="second">
                        <Quote setShowNav={setShowNav} ticketSummary={ticketSummary} />
                      </Tab.Pane>
                    )}
                    {shouldShowTab("third") && (
                      <Tab.Pane eventKey="third">
                        <ManualInput
                          setShowNav={setShowNav}
                          ticketSummary={ticketSummary}
                          ticketId={ticketId}
                        />
                      </Tab.Pane>
                    )}
                    {shouldShowTab("fourth") && (
                      <Tab.Pane eventKey="fourth">
                        <Manual
                          setShowNav={setShowNav}
                          ticketSummary={ticketSummary}
                          ticketId={ticketId}
                        />
                      </Tab.Pane>
                    )}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>

        <DeleteModal
          show={delateModalShow}
          onHide={() => setDelateModalShow(false)}
          onDelete={handleDelete}
        />
      </>
    )
  );
};

export default ViewCostings;
