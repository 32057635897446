import React, { useEffect, useState } from "react";
import DownIcon from "../../../Assets/Icons/DownIcon";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import AddPartsModal from "../modals/AddPartsModal";
import { Link, useNavigate } from "react-router-dom";
import EditIcon2 from "../../../Assets/Icons/EditIcon2";
import DelateIcon2 from "../../../Assets/Icons/DelateIcon2";
import { Dropdown, Modal } from "react-bootstrap";
import DeleteModal from "../modals/DeleteModal";
import { get } from "../../../Services/ApiHelper";
import {
  GET_ALL_PARTS,
  GET_ASSET_CATEGORIES,
  GET_CALENDAR_TYPES,
  GET_CATEGORY_OF_WORKS,
  GET_CHECKLISTS,
  GET_PRIORITIES,
  GET_TEAMS_TO_ASSIGN_TICKET,
} from "../../../utls/constants";
import { set, update } from "lodash";
import { useLocation } from "react-router-dom";
const ScheduleTemplateDetails = () => {
  const location = useLocation();

  let [submit, setSubmit] = useState(false);
  const [addPartShow, setAddPartShow] = useState(false);
  const [selectedWork, setselectedWork] = useState("Select");
  const [selectedCOW, setselectedCOW] = useState("Select");
  const [categoryOfWorkId, setCategoryOfWorkId] = useState(null);
  const [assetCategoryId, setAssetCategoryId] = useState(null);
  const [priorityId, setPriorityId] = useState(null);
  const [calendarTypeId, setCalendarTypeId] = useState(null);
  const [selectedAssignWorker, setselectedAssignWorker] = useState("Select");
  const [selectedPart, setselectedPart] = useState("Select");
  const [selectedday, setselectedday] = useState("Select");
  const [daySearch, setdaySearch] = useState("");
  const [delateModalShow, setDelateModalShow] = useState(false);
  //part search input
  const [partSearch, setPartSearch] = useState("");

  const [projectParts, setProjectParts] = useState([]);
  const [projectedParts, setProjectedParts] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //checklist search
  const [search, setSearch] = useState("");

  const [selectedPriority, setSelectedPriority] = useState(null);

  const handlePriorityClick = (priority, priorityId) => {
    setSubmit(true);
    setSelectedPriority(priority);
    setPriorityId(priorityId);
    setSelectValue({ ...selectValue, priority: priority }, () => {});
    updateLocalStorage();
  };

  const [selectValue, setSelectValue] = useState({
    categoryId: "Select",
    category: "Select",
    cow: "Select",
    calenderType: "Select",
    part: "Select Part",
    priority: "",
    priorityId: null,
  });

  const [checkList, setCheckList] = useState([]);
  const [categoryOdWorks, SetCategoryOdWorks] = useState([]);
  const [workSearch, setWorkSearch] = useState("");
  const [COWSearch, setCOWSearch] = useState("");
  const [assignWorkerSearch, setAssignWorkerSearch] = useState("");
  const [workData, setWorkData] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [calenderType, setCalenderType] = useState([]);
  const [selectedPriorityId, setSelectedPriorityId] = useState([]);
  const [recurrenceTime, setRecurrenceTime] = useState(1);
  const [estimateTime, setEstimateTime] = useState(null);
  const bearerToken = localStorage.getItem("bearerToken");

  //filter checklist by search herere
  const updateLocalStorage = () => {
    const selectedInfo = {
      tempName: templateName,
      templateDescription: templateDescription,
      recurrenceTime: recurrenceTime,
      calenderType: selectedday,
      calenderTypeId: calendarTypeId,
      cow: selectedCOW,
      cowId: categoryOfWorkId,
      assetCategory: selectedWork,
      assetCategoryId: assetCategoryId,
      estimateTime: estimateTime,
      priority: selectedPriority,
      priorityId: priorityId,
      projectedParts: projectParts,
    };
    localStorage.setItem("scheduleTemplate", JSON.stringify(selectedInfo));
  };

  const filteredCOWData = categoryOdWorks?.filter((item) => {
    return item?.categoryOfWorkName
      ?.toLowerCase()
      .includes(COWSearch.toLowerCase());
  });
  const filteredWorkData = workData?.filter((item) => {
    return item?.assetCategoryName
      ?.toLowerCase()
      .includes(workSearch.toLowerCase());
  });
  const handlePartSelect = (eventKey) => {
    setselectedPart(eventKey);
    setSelectValue({ ...selectValue, part: eventKey });
  };

  const handleSelect = (eventKey) => {
    const teamObject = filteredDayData?.find((item) => item.name === eventKey);

    setselectedday(eventKey);
    setCalendarTypeId(teamObject.id);
    setSelectValue({ ...selectValue, calenderType: eventKey });
    setdaySearch("");
  };

  const filteredProjectParts = projectedParts?.filter((item) => {
    return item.partName.toLowerCase().includes(partSearch.toLowerCase());
  });

  const handleWorkSelect = (eventKey) => {
    const teamObject = filteredWorkData?.find(
      (item) => item.assetCategoryName === eventKey
    );

    setSubmit(true);
    setAssetCategoryId(teamObject.id);
    setselectedWork(eventKey);
    setSelectValue({ ...selectValue, category: eventKey }, () => {});
    setWorkSearch("");
    updateLocalStorage();
  };

  const handleCOWSelect = (eventKey) => {
    const teamObject = filteredCOWData?.find(
      (item) => item.categoryOfWorkName === eventKey
    );

    setSubmit(true);
    setselectedCOW(eventKey);
    setCategoryOfWorkId(teamObject.id);
    setSelectValue({ ...selectValue, cow: eventKey }, () => {});
    setCOWSearch("");
    updateLocalStorage();
  };

  const filteredDayData = calenderType?.filter((item) => {
    return item?.name?.toLowerCase().includes(daySearch.toLowerCase());
  });
  const [templateName, setTemplateName] = useState("");
  const [templateDescription, setTemplateDescription] = useState("");

  useEffect(() => {
    const ScheduleTemplateDetails = JSON.parse(
      localStorage.getItem("scheduleTemplate")
    );
    if (ScheduleTemplateDetails) {
      const storageData = localStorage.getItem("templateSchedule");
      const parsedStoragedata = JSON.parse(storageData);
      setTemplateName(ScheduleTemplateDetails.tempName);
      setTemplateDescription(ScheduleTemplateDetails.templateDescription);
      setRecurrenceTime(ScheduleTemplateDetails.recurrenceTime ?? 1);
      setEstimateTime(ScheduleTemplateDetails.estimateTime);
      setselectedCOW(ScheduleTemplateDetails.cow);
      setCategoryOfWorkId(ScheduleTemplateDetails.cowId);
      setselectedWork(ScheduleTemplateDetails.assetCategory);
      setAssetCategoryId(ScheduleTemplateDetails.assetCategoryId);
      setselectedday(ScheduleTemplateDetails.calenderType);
      setCalendarTypeId(ScheduleTemplateDetails.calenderTypeId);
      setSelectedPriority(ScheduleTemplateDetails.priority);
      setPriorityId(ScheduleTemplateDetails.priorityId);
      setProjectParts(ScheduleTemplateDetails.projectedParts);
      setselectedChecklistIds(parsedStoragedata?.selectedChecklistIds);
      setSelectedChecklists(parsedStoragedata?.selectedChecklists);
    }

    get(GET_ALL_PARTS, localStorage.getItem("bearerToken"))
      .then((response) => {
        setProjectedParts(response);
      })
      .catch((error) => console.log("An error occured"));
    get(GET_CATEGORY_OF_WORKS, bearerToken)
      .then((response) => {
        SetCategoryOdWorks(response);
      })
      .catch((error) => console.log("An error occured"));

    get(GET_PRIORITIES, bearerToken)
      .then((response) => {
        setPriorities(response);
      })
      .catch((error) => console.log(error));

    get(GET_CALENDAR_TYPES, bearerToken)
      .then((response) => {
        setCalenderType(response.data);
      })
      .catch((error) => console.log(error));

    get(GET_ASSET_CATEGORIES, bearerToken)
      .then((response) => {
        setWorkData(response);
      })
      .catch((error) => console.log(error));

    get(GET_TEAMS_TO_ASSIGN_TICKET, bearerToken)
      .then((response) => {
        setWorkers(response);
      })
      .catch((error) => console.log(error));

    get(GET_CHECKLISTS, bearerToken)
      .then((response) => {
        setCheckList(response["data"]);
      })
      .catch((error) => console.log("An error occured"));
  }, []);
  const filteredCheckList = checkList?.filter((check) =>
    check.name.toLowerCase().includes(search.toLowerCase())
  );

  useEffect(() => {
    setProjectParts(
      JSON.parse(localStorage.getItem("selectedProjectedPartsNew"))
    );
  }, [addPartShow, delateModalShow]);

  const handleDelete = (partId) => {
    const updatedParts = projectedParts.filter(
      (part) => part.partId !== partId
    );
    setProjectParts(updatedParts);

    localStorage.setItem(
      "selectedProjectedPartsNew",
      JSON.stringify(updatedParts)
    );
  };
  const [selectedChecklists, setSelectedChecklists] = useState([]);
  const [selectedChecklistIds, setselectedChecklistIds] = useState([]);

  const [checklistState, setChecklistState] = useState(false);
  const [parsedDetail, setParsedDetail] = useState(null);
  useEffect(() => {
    setChecklistState(!checklistState);
  }, [selectedChecklists]);

  useEffect(() => {
    setSubmit(true);
    const detailsInfo = localStorage.getItem("templateSchedule");
    const checkListTable = JSON.parse(detailsInfo);
    setParsedDetail(checkListTable);
  }, [checklistState]);
  const updateSelectedInfoInLocalStorage = () => {
    const selectedInfo = {
      selectedChecklistIds: selectedChecklistIds,
      selectedChecklists: selectedChecklists,
    };

    localStorage.setItem("templateSchedule", JSON.stringify(selectedInfo));
  };

  const handleChecklistCheckboxChange = (check, checklistName) => {
    setSubmit(true);
    setSelectedChecklists((prevSelectedChecklists) => {
      if (prevSelectedChecklists.includes(checklistName)) {
        return prevSelectedChecklists.filter(
          (selectedChecklist) => selectedChecklist !== checklistName
        );
      } else {
        const assetIndex = prevSelectedChecklists?.findIndex(
          (selectedChecklist) => selectedChecklist?.name === checklistName
        );
        if (assetIndex !== -1) {
          updateSelectedInfoInLocalStorage();
          return prevSelectedChecklists.filter(
            (_, index) => index !== assetIndex
          );
        } else {
          updateSelectedInfoInLocalStorage();
          return [...prevSelectedChecklists, checklistName];
        }
      }
    });

    setselectedChecklistIds((prevSelectedChecklists) => {
      let checklistId = check.id;
      if (prevSelectedChecklists.includes(checklistId)) {
        return prevSelectedChecklists.filter(
          (selectedChecklist) => selectedChecklist !== checklistId
        );
      } else {
        const assetIndex = prevSelectedChecklists?.findIndex(
          (selectedChecklist) => selectedChecklist.name === checklistId
        );
        if (assetIndex !== -1) {
          updateSelectedInfoInLocalStorage();
          return prevSelectedChecklists?.filter(
            (_, index) => index !== assetIndex
          );
        } else {
          updateSelectedInfoInLocalStorage();
          return [...prevSelectedChecklists, checklistId];
        }
      }
    });
  };
  useEffect(() => {
    if (submit) {
      updateLocalStorage();
      updateSelectedInfoInLocalStorage();
      const detailsInfo = localStorage.getItem("templateSchedule");
      const checkListTable = JSON.parse(detailsInfo);
      setParsedDetail(checkListTable);
    }
  }, [
    selectValue,
    templateDescription,
    templateName,
    recurrenceTime,
    estimateTime,
    selectedChecklists,
    checklistState,
  ]);
  const [nameStatus, setNameStatus] = useState(true);
  const [descStatus, setDescStatus] = useState(true);
  const [recerrenceStatus, setRecerrenceStatus] = useState(true);
  const [priorityStatus, setPriorityStatus] = useState(true);
  const [cowStatus, setCowStatus] = useState(true);
  const [workStatus, setWorkStatus] = useState(true);
  const [ddayStatus, setDdayStatus] = useState(true);
  const [assignedStatus, setAssignedtStatus] = useState(true);
  const [estimateStatus, setEstimateStatus] = useState(true);
  const [partProjectedQuantity, setPartProjectedQuantity] = useState(0);
  const [partName, setPartName] = useState("");
  const [selectedPartId, setSelectedPartId] = useState(0);
  const navigate = useNavigate();
  const handleMainDataSet = (e) => {
    e.preventDefault();

    if (!templateName) {
      setNameStatus(false);
    } else {
      setNameStatus(true);
    }
    if (!templateDescription) {
      setDescStatus(false);
    } else {
      setDescStatus(true);
    }
    if (!recurrenceTime) {
      setRecerrenceStatus(false);
    } else {
      setRecerrenceStatus(true);
    }
    if (selectedPriority === null) {
      setPriorityStatus(false);
    } else {
      setPriorityStatus(true);
    }
    if (selectedCOW === "Select") {
      setCowStatus(false);
    } else {
      setCowStatus(true);
    }
    if (selectedWork === "Select") {
      setWorkStatus(false);
    } else {
      setWorkStatus(true);
    }
    if (selectedday === "Select") {
      setDdayStatus(false);
    } else {
      setDdayStatus(true);
    }

    if (!estimateTime) {
      setEstimateStatus(false);
    } else {
      setEstimateStatus(true);
    }

    if (
      !templateName ||
      !templateDescription ||
      !recurrenceTime ||
      selectedPriority === null ||
      selectedCOW === "Select" ||
      selectedWork === "Select" ||
      selectedday === "Select" ||
      !estimateTime
    ) {
      return; // Exit the function if any field is not filled
    } else {
      navigate("/work-schedule-templates/create-template/summary");
    }
  };
  if (
    location.state.value === 0 &&
    templateName === "" &&
    templateDescription === "" &&
    recurrenceTime === null &&
    selectedday === "Select" &&
    selectedCOW === "Select" &&
    selectedWork === "Select" &&
    selectedPriority === null &&
    estimateTime === null &&
    projectParts?.length === 0
  ) {
    localStorage.removeItem("scheduleTemplate");
    localStorage.removeItem("selectedProjectedPartsNew");
    localStorage.removeItem("templateSchedule");
  }
  const [selectedPartQuantity, setSelectedPartQuantity] = useState(0);
  const handleProjectedPartEdit = (part) => {
    
    setselectedPart(part.part);
    setSelectedPartId(part.partId);
    setSelectedPartQuantity(part.quantity);
    handleShow();
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    const updatedPart = {
      partId: selectedPartId,
      part: selectedPart,
      quantity: selectedPartQuantity,
    };
    localStorage.getItem("selectedProjectedPartsNew");
    const updatedParts = projectParts.map((part) =>
      part.partId === selectedPartId ? updatedPart : part
    );
    setProjectParts(updatedParts);
    localStorage.setItem(
      "selectedProjectedPartsNew",
      JSON.stringify(updatedParts)
    );

    handleClose();
  };
  const handleChecklistDelete = (itemName) => {
    let localStorageChecklist = JSON.parse(
      localStorage.getItem("templateSchedule")
    );
    const updatedChecklist = localStorageChecklist.selectedChecklists.filter(
      (checklist) => checklist !== itemName
    );
    const updatedChecklistIds =
      localStorageChecklist.selectedChecklistIds.filter(
        (checklist) => checklist !== itemName
      );
    setselectedChecklistIds(updatedChecklistIds);
    setSelectedChecklists(updatedChecklist);
    updateSelectedInfoInLocalStorage();
  };
  return (
    <>
      <div className="order-details-content PMWorkScheduling pb-lg-4">
        <div className="fs-16 fw-bold">Schedule Template Details</div>

        <div className="row details-forms-one pt-5">
          <div className="col-12">
            <label>Template Name</label>
            {!nameStatus && (
              <p style={{ color: "red", fontSize: "0.9em", fontWeight: "300" }}>
                Required *
              </p>
            )}
            <input
              type="text"
              onChange={(e) => {
                setSubmit(true);
                setTemplateName(e.target.value);
                updateLocalStorage();
              }}
              value={templateName}
            />
          </div>
          <div className="col-12">
            <label>Description</label>
            {!descStatus && (
              <p style={{ color: "red", fontSize: "0.9em", fontWeight: "300" }}>
                Required *
              </p>
            )}
            <textarea
              name="Description"
              onChange={(e) => {
                setSubmit(true);
                setTemplateDescription(e.target.value);
                updateLocalStorage();
              }}
              value={templateDescription}
            ></textarea>
          </div>
          <div className="col-md-6 ">
            <label>Category of Work</label>
            {!cowStatus && (
              <p style={{ color: "red", fontSize: "0.9em", fontWeight: "300" }}>
                Required *
              </p>
            )}
            <Dropdown className="select__form" onSelect={handleCOWSelect}>
              <Dropdown.Toggle
                className={`select-title ${
                  selectedCOW !== "Select" ? "selected" : ""
                }`}
              >
                {selectedCOW}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <form className="dropdown-search">
                  <button disabled>
                    <SearchIcon />
                  </button>
                  <input
                    value={workSearch}
                    onChange={(e) => setWorkSearch(e.target.value)}
                    type="text"
                    placeholder="Search"
                  />
                </form>
                <div className="dropdown-item-content">
                  {filteredCOWData.map((item, index) => (
                    <Dropdown.Item
                      key={index}
                      eventKey={item.categoryOfWorkName}
                    >
                      {item.categoryOfWorkName}
                    </Dropdown.Item>
                  ))}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-md-6">
            <label>Asset Category</label>
            {!workStatus && (
              <p style={{ color: "red", fontSize: "0.9em", fontWeight: "300" }}>
                Required *
              </p>
            )}
            <Dropdown className="select__form" onSelect={handleWorkSelect}>
              <Dropdown.Toggle
                className={`select-title ${
                  selectedWork !== "Select" ? "selected" : ""
                }`}
              >
                {selectedWork}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <form className="dropdown-search">
                  <button disabled>
                    <SearchIcon />
                  </button>
                  <input
                    value={workSearch}
                    onChange={(e) => setWorkSearch(e.target.value)}
                    type="text"
                    placeholder="Search"
                  />
                </form>
                <div className="dropdown-item-content">
                  {filteredWorkData.map((item, index) => (
                    <Dropdown.Item
                      key={index}
                      eventKey={item.assetCategoryName}
                    >
                      {item.assetCategoryName}
                    </Dropdown.Item>
                  ))}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-lg-6 scheduling-fff">
            <label htmlFor="" className="fs-15 ">
              Work recurs every
            </label>
            {recerrenceStatus && ddayStatus ? (
              ""
            ) : (
              <p
                style={{
                  color: "red",
                  fontSize: "0.9em",
                  fontWeight: "300",
                }}
              >
                Required *
              </p>
            )}
            <div className="scheduling-input">
              <input
                type="number"
                className="input-black"
                value={recurrenceTime}
                min={1}
                onChange={(e) => {
                  setSubmit(true);
                  setRecurrenceTime(parseInt(e.target.value, 10));
                  updateLocalStorage();
                }}
              />
              <Dropdown className="select__form" onSelect={handleSelect}>
                <Dropdown.Toggle
                  className={`select-title ${
                    selectedday !== "Select" ? "selected" : ""
                  }`}
                >
                  {selectedday}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="dropdown-item-content">
                    {filteredDayData.map((item, index) => (
                      <Dropdown.Item key={index} eventKey={item.name}>
                        {item.name}
                      </Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="col-lg-6 scheduling-fff">
            <label htmlFor="" className="fs-15 ">
              Time Estimate Hours
            </label>
            {!estimateStatus && (
              <p style={{ color: "red", fontSize: "0.9em", fontWeight: "300" }}>
                Required *
              </p>
            )}
            <div className="scheduling-input">
              <input
                type="number"
                className="input-black"
                class="w-100"
                value={estimateTime}
                min={0}
                onChange={(e) => {
                  setSubmit(true);
                  setEstimateTime(parseInt(e.target.value, 10));
                  updateLocalStorage();
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <label>Priority</label>
            {!priorityStatus && (
              <p style={{ color: "red", fontSize: "0.9em", fontWeight: "300" }}>
                Required *
              </p>
            )}
            <ul className="priority-list">
              {priorities?.map((item) => {
                return (
                  <li key={item.id}>
                    <button
                      className={
                        selectedPriority === item?.ticketPrioritiesName
                          ? "active"
                          : ""
                      }
                      onClick={() => {
                        setSubmit(true);
                        handlePriorityClick(
                          item?.ticketPrioritiesName,
                          item?.id
                        );
                      }}
                    >
                      {item?.ticketPrioritiesName}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <hr />

        <div className="row projected-con">
          <div className="col-lg-12">
            <div className="fs-16 fw-semibold projected-title">
              Projected Parts
            </div>
            <div className="parts-box table-responsive-sm">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Parts</th>
                    <th scope="col">Quantity</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {projectParts?.map((part, index) => (
                    <tr key={index}>
                      <td>
                        <input readOnly type="text" value={part.part} />
                      </td>
                      <td>
                        <input readOnly type="number" value={part.quantity} />
                      </td>
                      <td>
                        <div className="button-con">
                          <button
                            onClick={() => {
                              handleProjectedPartEdit(part);
                            }}
                          >
                            <EditIcon2 />
                          </button>
                          <Modal
                            show={show}
                            onHide={handleClose}
                            centered
                            className="medium-modal"
                          >
                            <div
                              className="delete-content"
                              style={{ padding: "28px 30px" }}
                            >
                              <div className="d-flex align-items-center gap-4">
                                <span className="fs-16 fw-bold">
                                  Edit Projected Parts
                                </span>
                                <span
                                  className="fs-14"
                                  style={{ color: "#72777A" }}
                                >
                                  Select parts and quantities required.
                                </span>
                              </div>

                              <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
                                <div className="col-md-12">
                                  <label className="fw-medium pb-2">
                                    Select Part
                                  </label>
                                  <Dropdown
                                    className="select__form"
                                    onSelect={handlePartSelect}
                                  >
                                    <Dropdown.Toggle
                                      className={`select-title ${
                                        selectedPart !== "Select Part"
                                          ? "selected"
                                          : ""
                                      }`}
                                      style={{ height: "50px" }}
                                    >
                                      {selectedPart}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <form className="dropdown-search">
                                        <button disabled>
                                          <SearchIcon />
                                        </button>
                                        <input
                                          value={partSearch}
                                          onChange={(e) =>
                                            setPartSearch(e.target.value)
                                          }
                                          type="text"
                                          placeholder="Search"
                                        />
                                      </form>
                                      <div className="dropdown-item-content">
                                        {filteredProjectParts.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={item.id}
                                              eventKey={item.partName}
                                            >
                                              {item.partName}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                                <div className="col-md-12">
                                  <label className="fw-medium pb-2">
                                    Quantity
                                  </label>
                                  <input
                                    type="number"
                                    className="modal-input-box"
                                    style={{
                                      background: "#F1EFEF",
                                      width: "100%",
                                      border: "0",
                                      height: "50px",
                                      borderRadius: "5px",
                                      padding: "0 15px",
                                    }}
                                    min={0}
                                    value={selectedPartQuantity}
                                    onChange={(e) =>
                                      setSelectedPartQuantity(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                className="button-group"
                                style={{
                                  marginTop: "25px",
                                  display: "flex",
                                  justifyContent: "end",
                                  gap: "30px",
                                }}
                              >
                                <button
                                  className="cancel-btn"
                                  onClick={handleClose}
                                >
                                  Cancel
                                </button>
                                <Link
                                  to=""
                                  className="delate-btn"
                                  onClick={(e) => handleUpdate(e)}
                                >
                                  Update
                                </Link>
                              </div>
                            </div>
                          </Modal>
                          <button
                            onClick={() => {
                              setSelectedPartId(part.partId);
                              setDelateModalShow(true);
                            }}
                          >
                            <DelateIcon2 />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="text-end">
                <button
                  className="add-part"
                  onClick={() => setAddPartShow(true)}
                >
                  Add Part
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row checklists-con">
          <div className="col-lg-12">
            <div className="fs-16 fw-semibold">Checklists</div>
            <div className="dropdown Checklists-dropdown">
              <button className="btn checklists-btn" data-bs-toggle="dropdown">
                Select Checklist <DownIcon />
              </button>
              <ul class="dropdown-menu pb-2">
                <li>
                  <form className="dropdown-search">
                    <button type="submit">
                      <SearchIcon />
                    </button>
                    <input
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      type="search"
                      placeholder="Search"
                    />
                  </form>
                </li>
                <ul className="dropdown-item-content my-2">
                  {filteredCheckList.map((check, index) => (
                    <li key={index}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id={check.id}
                          checked={selectedChecklists?.includes(check.name)}
                          onChange={() =>
                            handleChecklistCheckboxChange(check, check.name)
                          }
                        />
                        <label className="form-check-label" for={check.name}>
                          {check.name}
                        </label>
                      </div>
                    </li>
                  ))}
                </ul>
              </ul>
            </div>
            <div className="checklists-box">
              <div className="fs-15 checklists-title">Checklists</div>
              <ul className="checklists-list">
                {parsedDetail?.selectedChecklists?.map((item, index) => (
                  <li key={index}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Nozzle"
                      />
                      <label className="form-check-label" for="Nozzle">
                        {item}
                      </label>
                    </div>
                    <button
                      onClick={() => {
                        handleChecklistDelete(item);
                      }}
                    >
                      <DelateIcon2 />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="details-buttons d-flex justify-content-between">
          <Link className="next-btn ms-0" onClick={handleMainDataSet}>
            Next
          </Link>
        </div>
      </div>
      <AddPartsModal show={addPartShow} onHide={() => setAddPartShow(false)} />
      <DeleteModal
        show={delateModalShow}
        id={selectedPartId}
        fromScheduleTemplate={"fromScheduleTemplateCreate"}
        onHide={() => setDelateModalShow(false)}
      />
    </>
  );
};

export default ScheduleTemplateDetails;
