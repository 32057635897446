import React, { useEffect, useState } from "react";
import "../Components/PreventiveWorks/PreventiveWorks.scss";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams } from "react-router-dom";
import ViewTemplate from "../Components/WorkScheduleTemplate/ViewTemplate";
import { get } from "../Services/ApiHelper";
import { GET_PM_TEMPLATE_BY_ID } from "../utls/constants";
import { set } from "lodash";

const WorkViewTemplate = () => {
  const [template, setTemplate] = useState();
  const { id } = useParams();

  useEffect(() => {
    get(GET_PM_TEMPLATE_BY_ID(id), localStorage.getItem("bearerToken")).then(
      (res) => {
        setTemplate(res);
      }
    );
  }, [id]);

  return (
    <div className="work-orders-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="work-header">
              <div className="fs-20">View Schedule Template</div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="other-nav">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/work-schedule-templates">Work</Link>
                </li>
                <li>
                  <Link to="/work-schedule-templates">Schedule Templates</Link>
                </li>
                <li>
                  <Link to="">View Template</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="order-request-area view-order-content">
              <ViewTemplate template={template} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkViewTemplate;
