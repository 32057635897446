import { utils as XLSXUtils, write as writeXLSX } from "xlsx";
import { saveAs } from "file-saver";

const exportToExcel = (exportData) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  

const sectionNames = Array.from(
  new Set(
    exportData.reduce(
      (acc, curr) => {
        return acc.concat(
          curr.otherDetails.map((item) => item.section)
        );
      },
      []
    )
  )
);

 
exportData = exportData.map((item) => {
  return {
    ...item,
    ...item.otherDetails.reduce((acc, curr, index) => {
      // acc[curr.question] = curr.answer;
     if (sectionNames.length > 1) {
      for (let i = 0; i < sectionNames.length; i++) {
        if (sectionNames[i] === curr.section) {
          // remove all questions and answers for the section not in the current iteration
          acc[curr.section + "_" + curr.question] = curr.answer;
        }
      }
    } else {
      acc[curr.question] = curr.answer;
      // acc[curr.section] = curr.section;

    }

      return acc;
    }, {}),
  };
});
  const keys = Array.from(
    new Set(
      exportData.reduce(
        (acc, curr) => {
          // remove otherDetails from  headers
          return acc.concat(Object.keys(curr).filter((key) => key !== "otherDetails"));
          // acc.concat(Object.keys(curr)),
        },
        []
      )
    )
  );

  

  const ws = XLSXUtils.json_to_sheet(exportData, {
    header: keys,
    blankrows: false,

  });

  const wscols = keys.map((key) => {
    const maxColumnWidth = Math.max(
      ...exportData.map((item) => (item[key] ? item[key].toString().length : 0))
    );
    return { wch: Math.min(20, Math.max(10, maxColumnWidth)) }; // Set a minimum and maximum width
  });

  ws["!cols"] = wscols;
  ws["!autofilter"] = { ref: `A1:${XLSXUtils.encode_col(keys.length - 1)}1` };
  // color the header row
  ws["A1"].s = {
    font: { bold: true },
    fill: { fgColor: { rgb: "FF939393" } },
  };

  const wb = XLSXUtils.book_new();
  // for (let i = 0; i < sectionNames.length; i++) {
  //   XLSXUtils.book_append_sheet(wb, ws, sectionNames[i]);
  // }
  XLSXUtils.book_append_sheet(wb, ws, "inspectionData");
 


  const excelBuffer = writeXLSX(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });


  saveAs(data, "InspectionData_Export" + fileExtension);
};

export default exportToExcel;
