import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { GET_FORM_AND_SECTION_TASK_ANSWERS } from '../../../utls/constants';

const SectionOne = ({data, currentIndex, setCurrentIndex, showPDF}) => {
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem("bearerToken");


  const currentFormData = data.formDetails?.[currentIndex]; 
  const isLoaded = data.formDetails ? true : false;

  if (!currentFormData) {
    return <p>No data available.</p>;
  }

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, data.formDetails.length - 1));
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };
    
  return (
    <>
      {isLoading && !showPDF && (
        <div className="response-item">
          <div className="fs-13 fw-bold" style={{ color: "#D57D2A" }}>
            {currentFormData.formSubSectionName}
          </div>
          {currentFormData.formDataModel.map((formData, index) => (
            <div key={index}>
              <div className="response-title fs-16 fw-semibold text-black pl-25">
                {formData.task}
              </div>
              <Form.Check
                inline
                label={formData.task}
                name={`group${index}`}
                type="checkbox"
                id={`response-checkbox-${index}`}
              />
              <div className="pl-25">
                <input className="res-input" type="text" value={formData.answer} />
              </div>
            </div>
          ))}
        </div>
      )}
      {isLoading && showPDF && isLoaded && (
        data.formDetails.map((inspectionitem) => (
        <div className="response-item">
          <div className="fs-13 fw-bold" style={{ color: "#D57D2A" }}>
            {inspectionitem.formSubSectionName}
          </div>
          {inspectionitem.formDataModel.map((formData, index) => (
            <div key={index}>
              <div className="response-title fs-16 fw-semibold text-black pl-25">
                {formData.task}
              </div>
              <Form.Check
                inline
                label={formData.task}
                name={`group${index}`}
                type="checkbox"
                id={`response-checkbox-${index}`}
              />
              <div className="pl-25">
                <input className="res-input" type="text" value={formData.answer} />
              </div>
            </div>
          ))}
        </div>
        ))
      )}
    </>
  );
};

export default SectionOne;
