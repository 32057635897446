import React from "react";
import "../Components/Locations/Locations.scss";
import { Nav, Tab } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import LocationsHeader from "../Components/Locations/LocationsHeader";
import LocationsTab from "../Components/Locations/Locations";
import LocationGrouppingTab from "../Components/Locations/LocationGrouppings";
import LocationMapView from "../Components/Locations/LocationMapView";

const Locations = () => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const navigate = useNavigate();

  return (
    <div className="work-orders-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <LocationsHeader />
          </div>
          <div className="col-lg-12">
            <div className="other-nav">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link> Locations</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="inspection-cate">
              <Tab.Container
                id="f3washroom-table"
                defaultActiveKey={tab ? tab : "locations"}
              >
                <Nav variant="pills" className="f3washroom-table-nav mt-0">
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => navigate("?tab=locations")}
                      eventKey="locations"
                    >
                      Locations
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => navigate("?tab=locations-grouppings")}
                      eventKey="locations-grouppings"
                    >
                      Location Groupings
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => navigate("?tab=location-map-view")}
                      eventKey="location-map-view"
                    >
                      Location Map View
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="locations">
                    <div className="f3washroom-min-content request-tab-content">
                      <LocationsTab />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="locations-grouppings">
                    <div className="f3washroom-min-content request-tab-content">
                      <LocationGrouppingTab />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="location-map-view">
                    <div className="f3washroom-min-content request-tab-content">
                      <LocationMapView />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Locations;
