import React from 'react';

const CheckIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M10 0C4.47853 0 0 4.47853 0 10C0 15.5215 4.47853 20 10 20C15.5215 20 20 15.5215 20 10C20 4.47853 15.5215 0 10 0ZM10 18.3027C5.41922 18.3027 1.67689 14.5808 1.67689 10C1.69734 5.41922 5.41922 1.67689 10 1.67689C14.5808 1.67689 18.3027 5.39877 18.3027 9.97955C18.3231 14.5808 14.5808 18.3027 10 18.3027Z" fill="#6C5B51" />
            <path d="M14.4572 7.60672C14.4572 7.40222 14.3754 7.19772 14.2322 7.05457C13.9459 6.76827 13.4347 6.76827 13.1279 7.05457L8.91525 11.2672L6.87026 9.22226C6.72711 9.07911 6.52261 8.99731 6.31811 8.99731C6.11361 8.99731 5.92956 9.07911 5.76596 9.22226C5.62282 9.36541 5.54102 9.56991 5.54102 9.7744C5.54102 9.9789 5.62282 10.1834 5.76596 10.3266L8.3631 12.9237C8.6494 13.21 9.1811 13.21 9.4674 12.9237L14.2118 8.17931C14.3754 8.01571 14.4572 7.83166 14.4572 7.60672Z" fill="#6C5B51" />
        </svg>
    );
};

export default CheckIcon;