import React, { useState } from "react";
import DeleteIcon from "../../../Assets/Icons/DeleteIcon";
import EyesIcon from "../../../Assets/Icons/EyesIcon";
import ViewImage from "../../WorkOrders/Modal/ViewImage";
import ViewVideo from "../../WorkOrders/Modal/ViewVideo";
import ViewDocument from "../../WorkOrders/Modal/ViewDocument";
import DeleteModal from "../../WorkOrders/Modal/DeleteModal";
import AddDocumentModal from "../../WorkOrders/Modal/AddDocumentModal";
import EditBasicInfoModal from "../../WorkOrders/Modal/EditBasicInfoModal";
import EditIdentityInfoModal from "../../WorkOrders/Modal/EditIdentityInfoModal";
import { useParams } from "react-router-dom";
import usefetchData from "../../../hooks/useFetchData";
import useMutateData from "../../../hooks/useMutateData";
import { toast } from "react-toastify";

const ProfileSummary = () => {
  const [jobCardShow, setJobCardShow] = useState(false);
  const [basicInfoShow, setBasicInfoShow] = useState(false);
  const [identityInfoShow, setIdentityInfoShow] = useState(false);
  const [delateModalShow, setDelateModalShow] = useState(false);
  const [assetFileId, setAssetFileId] = useState("")
  const [imageShow, setImageShow] = useState({
    isShow: false,
    image: "",
    file_name: "",
  });
  const [videoShow, setVideoShow] = useState({
    isShow: false,
    video: "",
    file_name: "",
  });
  const [documentShow, setDocumentShow] = useState({
    isShow: false,
    document: "",
    file_name: "",
  });

  // get id from params
  const {id} = useParams()

  //function to download files
  const handleDownload = (fileUrl) => {
    fetch(fileUrl)
      .then((response) => response.blob()) // Convert response to a Blob
      .then((blob) => {
        const url = window.URL.createObjectURL(blob); // Create a temporary URL
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileUrl); // Set the downloaded file name
        link.click(); // Simulate a click on the link to initiate download

        // Optional: Clean up the temporary URL after download (recommended)
        setTimeout(() => window.URL.revokeObjectURL(url), 1000); // Revoke after 1 second
      })
      .catch((error) => console.error("Error downloading file:", error));
  };

  // get asset data by id
  const { data: assetSummary, isLoading: isLoadingCondition } = usefetchData(
    ["single-asset", parseInt(id)],
    `/Assets/GetAssetSummaryById/${id}`,
    {},
    "Error fetching asset data by id"
  );

  const {mutate, idLoading: isDeleting} = useMutateData({
    url: `/Assets/DeleteAssetDocument`,
    method: "POST",
    onSuccessfullMutation: (data) => {
      if (data?.data?.succeeded === true) {
        toast.success(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setDelateModalShow(false); // Hide edit modal
      } else {
        toast.error(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
    successMessage: `Successfully updated basic info`,
    errorMessage: "Basic info editing failed",
    queryKeysToInvalidate: [["single-asset", parseInt(id) ]],
  })

  const onDelete = () => {
    mutate({assetFileId})
  }

  return (
    <>
      <div className="order-request washroom-right-content responses-cont">
        <div className="washroom-title">
          <div className="fs-13 fw-medium">ASSET PROFILE DETAILS</div>
          {/* <div className="fs-13 fw-bold">
            Status: <button className="primary-text fw-bold">ON HOLD</button>
          </div> */}
        </div>

        {/* ASSET BASIC INFO */}
        <div className="fs-13 fw-semibold pt-5 gray-c d-flex flex-wrap justify-content-between">
          ASSET BASIC INFO
          <button
            onClick={() => setBasicInfoShow(true)}
            style={{
              fontWeight: "500",
              fontSize: "14px",
              color: "#D57D2A",
              borderBottom: "1px solid #D57D2A",
            }}
          >
            Edit Info
          </button>
        </div>
        <hr style={{ margin: "10px 0 10px", borderColor: "#D0D0D0" }} />

        <div className="row row-gap-4 pt-3">
          <div className="col-md-6">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061" }}
            >
              Asset Name:
            </div>
            <div className="fs-14 fw-medium" style={{ lineHeight: "1.3" }}>
              {assetSummary?.assetName}
            </div>
          </div>
          <div className="col-md-3">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061" }}
            >
              Asset Category
            </div>
            <div className="fs-14 fw-medium">{assetSummary?.assetCategoryName}</div>
          </div>
          <div className="col-md-3">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061" }}
            >
              Asset Location
            </div>
            <div className="fs-14 fw-medium whitespace-wrap">{assetSummary?.assetLocationName ?? "Not Assigned"}</div>
          </div>
          <div className="col-md-6">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061" }}
            >
              Description:
            </div>
            <div className="fs-14 fw-medium" style={{ lineHeight: "1.3" }}>
              {assetSummary?.assetDescription}
            </div>
          </div>
          <div className="col-md-3">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061" }}
            >
              Asset Status
            </div>
            <div className="fs-14 fw-medium">{assetSummary?.assetStatusName}</div>
          </div>
          <div className="col-md-3">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061" }}
            >
              Asset Condition
            </div>
            <div className="fs-14 fw-medium">{assetSummary?.assetConditionName}</div>
          </div>
        </div>

        {/* ASSET IDENTIFICATION, MAKE & MODEL */}
        <div className="fs-13 fw-semibold pt-5 gray-c d-flex flex-wrap justify-content-between">
          ASSET IDENTIFICATION, MAKE & MODEL
          <button
            onClick={() => setIdentityInfoShow(true)}
            style={{
              fontWeight: "500",
              fontSize: "14px",
              color: "#D57D2A",
              borderBottom: "1px solid #D57D2A",
            }}
          >
            Edit Info
          </button>
        </div>
        <hr style={{ margin: "10px 0 10px", borderColor: "#D0D0D0" }} />

        <div className="row row-gap-4 pt-3">
          <div className="col-md-4">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061", letterSpacing: "-.4px" }}
            >
              Serial Number
            </div>
            <div className="fs-14 fw-medium">{assetSummary?.serialNumber} </div>
          </div>
          <div className="col-md-4">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061", letterSpacing: "-.4px" }}
            >
              Tag ID
            </div>
            <div className="fs-14 fw-medium"> {assetSummary?.saharaDeskTag}</div>
          </div>
          <div className="col-md-4">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061", letterSpacing: "-.4px" }}
            >
              Make
            </div>
            <div className="fs-14 fw-medium">{assetSummary?.makeName} </div>
          </div>
          <div className="col-md-4">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061", letterSpacing: "-.4px" }}
            >
              Model
            </div>
            <div className="fs-14 fw-medium">{assetSummary?.modelName} </div>
          </div>
        </div>

        {/* ASSET DOCUMENTS */}
        <div className="fs-13 fw-semibold pt-5 gray-c d-flex flex-wrap justify-content-between">
          ASSET DOCUMENTS
          <button
            onClick={() => setJobCardShow(true)}
            style={{
              fontWeight: "500",
              fontSize: "14px",
              color: "#D57D2A",
              borderBottom: "1px solid #D57D2A",
            }}
          >
            Add Documents
          </button>
        </div>
        <hr style={{ margin: "10px 0 10px", borderColor: "#D0D0D0" }} />

        <div className="py-3">
          <div className="fs-14 fw-medium black-38">Documents uploaded</div>
          {assetSummary?.assetFiles?.map((item) => (
            <div className="upload-details" key={item.id}>
              <div>
                <div className="fs-14" style={{ color: "#D57D2A" }}>
                  {item.fileName}
                </div>
              </div>
              <div className="upload-icons">
                {/* <button
                  onClick={() =>
                    item.file.includes(
                      ".jpg" || ".jpeg" || ".png" || ".JPG" || ".JPEG" || ".PNG"
                    )
                      ? setImageShow({
                          isShow: true,
                          image: item.file,
                          file_name: item.fileName,
                        })
                      : item.file.includes(".pdf" || "PDF")
                      ? setDocumentShow({
                          isShow: true,
                          document: item.file,
                          file_name: item.fileName,
                        })
                      : item.file.includes(
                          ".mp4" || ".AVI" || "3GP" || "MP4" || ".avi" || "3gp"
                        ) &&
                        setVideoShow({
                          isShow: true,
                          video: item.file,
                          file_name: item.fileName,
                        })
                  }
                >
                  <EyesIcon />
                </button> */}
                <button onClick={() => {setAssetFileId(item.id); setDelateModalShow(true)}}>
                  <DeleteIcon />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal function */}
      <ViewImage show={imageShow} onHide={() => setImageShow(false)} />
      <ViewVideo show={videoShow} onHide={() => setVideoShow(false)} />
      <ViewDocument show={documentShow} onHide={() => setDocumentShow(false)} />

      <DeleteModal
        show={delateModalShow}
        onHide={() => setDelateModalShow(false)}
        onDelete={onDelete}
        isDeleting={isDeleting}
      />
      <AddDocumentModal
        show={jobCardShow}
        onHide={() => setJobCardShow(false)}
        assetId={id}
        mode="upload"
      />
      <EditBasicInfoModal
        show={basicInfoShow}
        onHide={() => setBasicInfoShow(false)}
        basicInfo={assetSummary}
      />
      <EditIdentityInfoModal
        show={identityInfoShow}
        onHide={() => setIdentityInfoShow(false)}
        basicInfo={assetSummary}
      />
    </>
  );
};

export default ProfileSummary;

