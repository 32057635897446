import React, { useState, useEffect, useContext } from "react";
import { Dropdown } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import {
  ADD_RFQ_PROJECTEDPART,
  GET_ALL_PARTS,
  GET_USER_LOCATIONS,
} from "../../../utls/constants";
import { DiagnosisDetailsContext } from "../../../Context/Inspections/WorkOrderContext";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { get, post } from "../../../Services/ApiHelper";
import Astericks from "../../Common/Asterick";

const AddProjectedPartsQuotesModal = (props) => {
  const { diagnosisSummary, setDiagnosisSummary } = useContext(
    DiagnosisDetailsContext
  );
  const [selectedPartProject, setselectedPartProject] = useState("Select part");
  const [selectedPartNumber, setSelectedPartNumber] = useState("");
  const [selectedPartId, setSelectedPartId] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [parts, setParts] = useState([]);
  const token = localStorage.getItem("bearerToken");
  //search inputs
  const [partProjectSearch, setPartProjectSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [partError, setPartError] = useState(null);
  const [quantError, setQuantError] = useState(null);

  useEffect(() => {
    get(GET_ALL_PARTS, token)
      .then((response) => setParts(response))
      .catch((error) => console.log(error));

    setselectedPartProject("Select part");
    setQuantity(0);
    setQuantError(null);
    setPartError(null);
  }, [props.show]);

  //filter items parts that are available in context also
  //filter part data  by search input
  const filteredPartData = parts?.filter((item) => {
    //item?.partName.toLowerCase().includes(partProjectSearch.toLowerCase())
    const matchesSearch = item?.partName
      .toLowerCase()
      .includes(partProjectSearch.toLowerCase());
    const matchesContext = diagnosisSummary?.requestPartsSummary.some(
      (contextItem) => contextItem.partId === item?.id
    );
    return matchesSearch && !matchesContext;
  });

  //handle select part
  const handlePartProjectSelect = (eventKey) => {
    const partObject = parts.find((p) => p.partName === eventKey);
    if (partObject) {
      setselectedPartProject(eventKey);
      setSelectedPartId(partObject.id);
      setSelectedPartNumber(partObject.partNumber);
    }
    setPartProjectSearch("");
  };

  const validateInputs = () => {
    let isValid = true;

    setPartError(null);
    setQuantError(null);

    if (selectedPartProject === "Select Part") {
      setPartError("Required *");
      isValid = false;
    }

    if (!quantity || quantity == 0) {
      setQuantError("Required *");
      isValid = false;
    }

    return isValid;
  };

  const onSubmit = () => {
    const isValid = validateInputs();

    if (!isValid) {
      return;
    }

    setIsLoading(true);
    const payload = {
      requestAssetId: props.requestAssetId,
      partId: selectedPartId,
      quantityProjected: quantity,
    };

    post(ADD_RFQ_PROJECTEDPART, payload, token)
      .then((response) => {
        if (response.succeeded === true) {
          props.onHide();
          setIsLoading(false);
          setselectedPartProject("Select Part");
          setQuantity(0);

          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          const newPartsData = {
            id: response.data,
            partId: selectedPartId,
            partName: selectedPartProject,
            quantityProjected: quantity,
            requestAssetId: props.requestAssetId,
            //partSerialNumber: selectedPartNumber,
          };
          setDiagnosisSummary((prevDiagnosisSummary) => ({
            ...prevDiagnosisSummary,
            requestPartsSummary: [
              ...prevDiagnosisSummary.requestPartsSummary,
              newPartsData,
            ],
          }));
          /*const updatedWorkOrderAssetList =
            ticketSummary.assetSummary.workOrderAssetList[props.index];

          updatedWorkOrderAssetList.partsUsedList.push(newPartsData);*/
        } else {
          props.onHide();
          setIsLoading(false);
          setselectedPartProject("Select Part");
          setQuantity(0);

          toast.error(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((error) => {
        props.onHide();
        setIsLoading(false);
        setselectedPartProject("Select Part");
        setQuantity(0);
        toast.error("An error occured", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
      })
      .finally(() => {
        props.onHide();
        setIsLoading(false);
        setselectedPartProject("Select Part");
        setQuantity(0);
      });
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Add Projected Parts</span>
            <span className="fs-14" style={{ color: "#72777A" }}>
              Select parts and quantities required.
            </span>
          </div>
          <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2 fs-14">
                  Select Part
                  <Astericks />
                </label>
                {partError && <p style={{ color: "red" }}>{partError}</p>}
              </div>
              <Dropdown
                className="select__form"
                onSelect={handlePartProjectSelect}
              >
                <Dropdown.Toggle
                  className={`select-title ${
                    selectedPartProject !== "Select part" ? "selected" : ""
                  }`}
                  style={{ height: "50px" }}
                >
                  {selectedPartProject}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      value={partProjectSearch}
                      onChange={(e) => setPartProjectSearch(e.target.value)}
                      type="text"
                      placeholder="Search"
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filteredPartData.map((item, index) => (
                      <Dropdown.Item key={index} eventKey={item.partName}>
                        {item.partName}
                      </Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2 fs-14 fs-14">
                  Quantity
                  <Astericks />
                </label>
                {quantError && <p style={{ color: "red" }}>{quantError}</p>}
              </div>
              <input
                className="modal-input-box"
                type="number"
                onChange={(e) => setQuantity(e.target.value)}
                min="1"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "0 15px",
                }}
                placeholder="Enter quantity required"
                onKeyPress={(event) => {
                  // Prevent typing of negative sign
                  if (event.key === "-") {
                    event.preventDefault();
                  }
                }}
                onInput={(event) => {
                  // Remove non-integer characters
                  event.target.value = event.target.value.replace(/\D/g, "");
                }}
              />
            </div>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            {isLoading ? (
              <Spinner
                animation="border"
                style={{
                  color: "#D57D2A",
                }}
              />
            ) : (
              <Link to="" className="delate-btn" onClick={onSubmit}>
                Add
              </Link>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddProjectedPartsQuotesModal;
