import React, { useMemo } from "react";
import {
  Category,
  ChartComponent,
  ColumnSeries,
  Inject,
  Legend,
  SeriesCollectionDirective,
  SeriesDirective,
  Tooltip,
} from "@syncfusion/ej2-react-charts";
import { format } from "date-fns";

const PMCMCategoryGraph = ({
  workOrder,
  maxScheduled,
  filters,
  categories,
  categoryOfWorkIds,
  maxCM
}) => {
  const workOrderData = workOrder?.data || [];
  const interval = maxScheduled / 10;
  const maxValue = Math.max(maxCM, maxScheduled)
  const roundedUpToNext10 = Math.ceil(maxValue / 10) * 10;
  const primaryXAxis = { valueType: "Category", title: "Category of Work" };
  const primaryYAxis = {
    interval: roundedUpToNext10 / 10,
    maximum: Math.ceil(roundedUpToNext10 / 10) * 10,
    title: "Completed",
  };

  const tooltip = { enable: true };

  const transformedData = workOrderData?.map((data) => ({
    categoryOfWorkName: data.categoryOfWorkName,
    completedPM: data.completedPM,
    completedCM: data.completedCM,
    // onHold: data.onHold,
  }));

  const formatDate = (dateString) => {
    return format(new Date(dateString), "dd/MM/yyyy");
  };

  const getCategoryNames = () => {
    return categoryOfWorkIds.map(
      (id) =>
        categories.find((category) => category.id === id)?.categoryOfWorkName ??
        ""
    );
  };

  const formattedFilters = useMemo(() => {
    if (!filters) return null;

    return {
      startDate: formatDate(filters["filter.StartDate"]),
      endDate: formatDate(filters["filter.EndDate"]),
      calendarGrouping: filters["filter.CalendarGrouping"],
      categoryOfWork: getCategoryNames(
        filters["filter.CategoryOfWorkIds"]
      ).join(", "),
    };
  }, [filters]);

  return (
    <div id="pm-cm-category-graph">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyItems: "start",
        }}
      >
        <h3 style={{ fontWeight: 700 }}>PM vs CM by Category of Work</h3>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gridColumnGap: "20px",
            gridRowGap: "4px",
            fontSize: "14px",
            fontWeight: 400,
            marginBottom: "10px",
            width: "60%",
          }}
        >
          <p style={{ color: "gray" }}>Start Date: <span style={{fontWeight: "bold", color: "black"}}>{formattedFilters?.startDate || ""}</span> </p>
          {/* <p style={{ color: "gray" }}>Category of Work: <span style={{fontWeight: "bold", color: "black"}}>{formattedFilters?.categoryOfWork || "All"}</span></p> */}
          <p style={{ color: "gray" }}>End Date: <span style={{fontWeight: "bold", color: "black"}}>{formattedFilters?.endDate || ""}</span></p>
        </div>
        <hr />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <div style={{ width: "100%" }}>
          <ChartComponent
            id="charts"
            primaryXAxis={{...primaryXAxis, labelIntersectAction: "Rotate45", maximumLabels: 2}}
            primaryYAxis={primaryYAxis}
            title="PM vs CM by Category of Work"
            // subTitle="Scheduled vs Completed"
            legendSettings={{ visible: true }}
            width="1200px" // Change width to 100%
            height="600px"
            tooltip={tooltip}
          >
            <Inject services={[ColumnSeries, Category, Legend, Tooltip]} />
            <SeriesCollectionDirective>
              <SeriesDirective
                dataSource={transformedData}
                xName="categoryOfWorkName"
                yName="completedPM"
                name="CompletedPM"
                type="Column"
                fill="rgba(88, 69, 57, 0.87)"
                columnWidth={0.7}
              />
              <SeriesDirective
                dataSource={transformedData}
                xName="categoryOfWorkName"
                yName="completedCM"
                name="CompletedCM"
                type="Column"
                fill="#D57D2A"
                columnWidth={0.7}
              />
            </SeriesCollectionDirective>
          </ChartComponent>
        </div>
      </div>
    </div>
  );
};

export default PMCMCategoryGraph;
