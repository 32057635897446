import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { DiagnosisDetailsContext } from "../../../Context/Inspections/WorkOrderContext";
import { ADD_RFQ_DIAGNOSIS } from "../../../utls/constants";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { post } from "../../../Services/ApiHelper";
import Astericks from "../../Common/Asterick";

const AddDiagnosisQuotesModal = (props) => {
  const { diagnosisSummary, setDiagnosisSummary } = useContext(
    DiagnosisDetailsContext
  );
  const [fault, setFault] = useState("");
  const [cause, setCause] = useState("");
  const [solution, setSolution] = useState("");
  const token = localStorage.getItem("bearerToken");
  const [isLoading, setIsLoading] = useState(false);
  const [faultError, setFaultError] = useState(null);
  const [causeError, setCauseError] = useState(null);
  const [solutionError, setSolutionError] = useState(null);

  useEffect(() => {
    //Can this also be used to reset error required section??
    setSolution("");
    setCause("");
    setFault("");
    setFaultError(null);
    setCauseError(null);
    setSolutionError(null);
  }, [props.show]);

  const validateInputs = () => {
    let isValid = true;

    setFaultError(null);
    setCauseError(null);
    setSolutionError(null);

    if (!fault) {
      setFaultError("Required *");
      isValid = false;
    }

    if (!cause) {
      setCauseError("Required *");
      isValid = false;
    }

    if (!solution) {
      setSolutionError("Required *");
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = () => {
    const isValid = validateInputs();

    if (!isValid) {
      return;
    }
    setIsLoading(true);
    const payload = {
      requestAssetId: props.requestAssetId,
      faultyPart: fault,
      diagnosis: cause,
      proposedSolution: solution,
    };
    setSolution("");
    setCause("");
    setFault("");
    post(ADD_RFQ_DIAGNOSIS, payload, token)
      .then((response) => {
        if (response.succeeded === true) {
          props.onHide();
          setIsLoading(false);
          setSolution("");
          setCause("");
          setFault("");

          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          const newPartsData = {
            diagnosis: cause,
            faultyPart: fault,
            proposedSolution: solution,
            requestAssetId: props.requestAssetId,
            id: response.data,
          };
          setDiagnosisSummary((prevDiagnosisSummary) => ({
            ...prevDiagnosisSummary,
            requestDiagnosisSummary: [
              ...prevDiagnosisSummary.requestDiagnosisSummary,
              newPartsData,
            ],
          }));
        } else {
          props.onHide();
          setIsLoading(false);
          setSolution("");
          setCause("");
          setFault("");

          toast.error(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((error) => {
        props.onHide();
        setIsLoading(false);
        setSolution("");
        setCause("");
        setFault("");
        toast.error("An error occurred", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .finally(() => {
        props.onHide();
        setIsLoading(false);
      });
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Add Diagnosis</span>
            <span className="fs-14" style={{ color: "#72777A" }}>
              Provide diagnosis for the fault
            </span>
          </div>
          <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">
                  What is faulty?
                  <Astericks />
                </label>
                {faultError && <p style={{ color: "red" }}>{faultError}</p>}
              </div>
              <input
                onChange={(e) => setFault(e.target.value)}
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "0 15px",
                }}
                placeholder="Enter the part that is not working"
              />
            </div>
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">
                  What caused the fault (Diagnosis)?
                  <Astericks />
                </label>
                {causeError && <p style={{ color: "red" }}>{causeError}</p>}
              </div>
              <textarea
                className="modal-input-box"
                onChange={(e) => setCause(e.target.value)}
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "150px",
                  borderRadius: "5px",
                  padding: "15px 15px",
                }}
                placeholder="Enter description of the issue"
              />
            </div>
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">
                  How do you intend to solve the fault (Solution)?
                  <Astericks />
                </label>
                {solutionError && (
                  <p style={{ color: "red" }}>{solutionError}</p>
                )}
              </div>
              <textarea
                className="modal-input-box"
                onChange={(e) => setSolution(e.target.value)}
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "150px",
                  borderRadius: "5px",
                  padding: "15px 15px",
                }}
                placeholder="Enter proposed solution"
              />
            </div>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            {isLoading ? (
              <Spinner
                animation="border"
                style={{
                  color: "#D57D2A",
                }}
              />
            ) : (
              <Link to="" className="delate-btn" onClick={handleSubmit}>
                Add
              </Link>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddDiagnosisQuotesModal;
