import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AttachIcon from '../../../Assets/Icons/AttachIcon';
import gallery from '../../../Assets/Images/gallery-add.png';
import AddJobCardModal from '../../WorkOrders/Modal/AddJobCardModal';
import EyesIcon from '../../../Assets/Icons/EyesIcon';
import DeleteIcon from '../../../Assets/Icons/DeleteIcon';
import ViewImage from '../../WorkOrders/Modal/ViewImage';
import ViewVideo from '../../WorkOrders/Modal/ViewVideo';
import ViewDocument from '../../WorkOrders/Modal/ViewDocument';
import DeleteModal from '../../WorkOrders/Modal/DeleteModal';
import AssetPhotoModal from '../../WorkOrders/Modal/AssetPhotoModal';
import AddDocumentModal from '../../WorkOrders/Modal/AddDocumentModal';
import { removeAssetFile } from '../../../redux/assetSlice';

const CreateDocuments = () => {
  const dispatch = useDispatch();
  const assetFiles = useSelector((state) => state.asset.assetFiles);

  const [jobCardShow, setJobCardShow] = useState(false);
  const [assetPhotoShow, setAssetPhotoShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [imageShow, setImageShow] = useState({
    isShow: false,
    image: '',
    file_name: ''
  });
  const [videoShow, setVideoShow] = useState({
    isShow: false,
    video: '',
    file_name: ''
  });
  const [documentShow, setDocumentShow] = useState({
    isShow: false,
    document: '',
    file_name: ''
  });
  const [fileToDelete, setFileToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = () => {
    setIsDeleting(true);
    dispatch(removeAssetFile(fileToDelete));
    setIsDeleting(false);
    setDeleteModalShow(false);
  };

  const handleDownload = (fileUrl) => {
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileUrl);
        link.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 1000);
      })
      .catch((error) => console.error('Error downloading file:', error));
  };

  return (
    <>
      <div className="order-details-content pb-lg-4">
        <div className="fs-16 fw-semibold">Photos and Documents</div>
        <div className="fs-13 fw-semibold pt-5 gray-c">ASSET PHOTO</div>
        <hr style={{ margin: '10px 0 30px', borderColor: '#D0D0D0' }} />

        <div className="gallery-add">
          <div className="d-flex flex-wrap align-items-center gap-5">
            <button
              className="upload-photo"
              onClick={() => setAssetPhotoShow(true)}
            >
              <img src={gallery} alt="gallery" />
              <span className="fs-12 fw-medium">Upload asset photo</span>
            </button>
            <div className="attach-file mt-3">
              <label
                htmlFor="fileInput"
                className="attach-button"
                onClick={() => setAssetPhotoShow(true)}
                style={{
                  cursor: 'pointer',
                  background: '#F1EFEF',
                  padding: '10px 15px',
                  borderRadius: '5px'
                }}
              >
                <AttachIcon /> Click to attach
                <input
                  type="file"
                  id="fileInput"
                  multiple
                  style={{ display: 'none' }}
                />
              </label>
              <span
                className="fs-15 fw-medium text-black "
                style={{ fontStyle: 'italic', paddingLeft: '15px' }}
              >
                No file chosen
              </span>
            </div>
          </div>
        </div>

        <div className="fs-13 fw-semibold pt-5 mt-4 gray-c">
          ASSET DOCUMENTS
        </div>
        <hr style={{ margin: '10px 0 30px', borderColor: '#D0D0D0' }} />

        <div className="asset-documents">
          <div className="fs-14 fw-medium black-38">Documents uploaded</div>
          {assetFiles.map((item) => (
            <div className="upload-details" key={item.fileName}>
              <div>
                <div className="fs-14" style={{ color: '#1F2024' }}>
                  {item.fileName}
                </div>
              </div>
              <div className="upload-icons">
                <button
                  onClick={() =>
                    item.fileType.includes(
                      '.jpg' ||
                      '.jpeg' ||
                      '.png' ||
                      '.JPG' ||
                      '.JPEG' ||
                      '.PNG'
                    )
                      ? setImageShow({
                          isShow: true,
                          image: item.url,
                          file_name: item.fileName
                        })
                      : item.fileType.includes('.pdf' || 'PDF')
                      ? setDocumentShow({
                          isShow: true,
                          document: item.url,
                          file_name: item.fileName
                        })
                      : item.fileType.includes(
                          '.mp4' ||
                          '.AVI' ||
                          '3GP' ||
                          'MP4' ||
                          '.avi' ||
                          '3gp'
                        ) &&
                        setVideoShow({
                          isShow: true,
                          video: item.url,
                          file_name: item.fileName
                        })
                  }
                >
                  <EyesIcon />
                </button>
                <button
                  onClick={() => {
                    setFileToDelete(item.fileName);
                    setDeleteModalShow(true);
                  }}
                >
                  <DeleteIcon />
                </button>
              </div>
            </div>
          ))}
          <div className="text-end">
            <button
              style={{
                fontSize: '15px',
                color: '#D57D2A',
                borderBottom: '1px solid #D57D2A'
              }}
              className="add-button my-4"
              onClick={() => setJobCardShow(true)}
            >
              Add Document
            </button>
          </div>
        </div>

        <div className="details-buttons pt-lg-5"></div>
        <div className="details-buttons pt-lg-5 d-flex justify-content-between">
          <Link to="/assets/create-new-asset" className="next-btn ms-0">
            Previous
          </Link>
          <Link to="/assets/create-new-asset/summary" className="next-btn ms-0">
            Next
          </Link>
        </div>
      </div>
      <ViewImage show={imageShow.isShow} onHide={() => setImageShow(false)} />
      <ViewVideo show={videoShow.isShow} onHide={() => setVideoShow(false)} />
      <ViewDocument show={documentShow.isShow} onHide={() => setDocumentShow(false)} />
      <DeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        onDelete={handleDelete}
        isDeleting={isDeleting}
      />
      <AssetPhotoModal
        show={assetPhotoShow}
        onHide={() => setAssetPhotoShow(false)}
        mode="store"
      />
      <AddDocumentModal
        show={jobCardShow}
        onHide={() => setJobCardShow(false)}
        mode="store"
      />
    </>
  );
};

export default CreateDocuments;
