import React from "react";
import "../Components/InspectionCategory/InspectionCategory.scss";
 import CreateHeader from "../Components/InspectionCategory/Create/CreateHeader";
import CreateNavbar from "../Components/InspectionCategory/Create/CreateNavbar";
import CreateSidebar from "../Components/InspectionCategory/Create/CreateSidebar";
import CreateContent from "../Components/InspectionCategory/Create/CreateContent";
import { useLocation } from "react-router-dom";


const CreateInspectionCategory = () => {
 
  const location = useLocation();
  const data = location.state
  const item = data ? data.item : null;
  return (
    <div className="work-orders-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <CreateHeader item ={item} />
          </div>
          <div className="col-lg-12">
            <CreateNavbar  item ={item}/>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="add-pm-area create__area">
              <CreateSidebar item ={item} />
              <CreateContent item ={item}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateInspectionCategory;