import React from "react";
import { Link } from "react-router-dom";

const RequestsNavbar = () => {
  return (
    <div className="other-nav">
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li> 
        <li>
          <Link> Requests</Link>
        </li>
      </ul>
    </div>
  );
};

export default RequestsNavbar;
