import React from "react";
import DownIcon from "../../Assets/Icons/DownIcon";
import AddIcon from "../../Assets/Icons/AddIcon";
import { Link } from "react-router-dom";
import exportToExcel from "../../utls/exportToExcel";
import exportToExcelMain from "../../utls/exportToExecelMain";
const DiagnosisHeader = (report) => {
  return (
    <>
      <div className="work-header">
        <div className="fs-20">Diagnosis & Quotes</div>
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-15 d-flex align-items-center gap-2">
              Actions <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu dropdown-menu-end select-menu">
            <li
              onClick={() =>
                exportToExcelMain(report.report, "Diagnosis_and_Quotes")
              }
            >
              <Link to="">
                <AddIcon />
                Export to Excel
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

const diagnosisQuotesData = [
  {
    ref: "1000001",
    fault: "Pump leakage",
    location: "Mavoko Station",
    status: "Invited to Quote",
    submitBy: "James Muli",
    submitDate: "01/11/2024",
  },
  {
    ref: "1000002",
    fault: "Pump overdispensing",
    location: "Mavoko Station",
    status: "Invited to Quote",
    submitBy: "Ivan Miguna",
    submitDate: "01/11/2024",
  },
  {
    ref: "1000003",
    fault: "Faulty screen",
    location: "Mavoko Station",
    status: "Quoted",
    submitBy: "Ivan Miguna",
    submitDate: "01/11/2024",
  },
  {
    ref: "1000004",
    fault: "Pump leakage",
    location: "Kisaju Station",
    status: "Quoted",
    submitBy: "Dorcas Sitati",
    submitDate: "01/15/2024",
  },
  {
    ref: "1000005",
    fault: "Pump leakage",
    location: "Kisaju Station",
    status: "Work Assigned",
    submitBy: "Dorcas Sitati",
    submitDate: "01/15/2024",
  },
  {
    ref: "1000006",
    fault: "Faulty manual meter",
    location: "Kisaju Station",
    status: "Work Not Assigned",
    submitBy: "Amos Wambuka",
    submitDate: "01/15/2024",
  },
  {
    ref: "1000007",
    fault: "Faulty submersible",
    location: "Kisaju Station",
    status: "Work Assigned",
    submitBy: "Alphonce Michuki",
    submitDate: "01/16/2024",
  },
  {
    ref: "1000008",
    fault: "Calibration overdue",
    location: "Kisaju Station",
    status: "Work Not Assigned",
    submitBy: "Alphonce Michuki",
    submitDate: "01/16/2024",
  },
  {
    ref: "1000009",
    fault: "Calibration overdue",
    location: "Kisaju Station",
    status: "Work Not Assigned",
    submitBy: "Dorcas Sitati",
    submitDate: "01/17/2024",
  },
];

export default DiagnosisHeader;
