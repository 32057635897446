import React, { useState } from "react";
import DeleteIcon from "../../../Assets/Icons/DeleteIcon";
import DeleteModal from "../Modals/DeleteModal";
import AddStakeHolderModal from "../Modals/AddStakeHolderModal";
import { Accordion } from "react-bootstrap";
import EyesIcon from "../../../Assets/Icons/EyesIcon";

const ViewStakeHolders = () => {
  const [StakeHolderShow, setStakeHolderShow] = useState(false);
  const [delateModalShow, setDelateModalShow] = useState(false);
  return (
    <>
      <div className="order-request washroom-right-content responses-cont">
        <div className="washroom-title">
          <div className="fs-13 fw-medium">LOCATION STAKEHOLDERS</div>
        </div>
        <div className="order-request mt-4 p-0">
          <Accordion defaultActiveKey="0">
            {/* JOB CARDS */}
            <Accordion.Item eventKey="0" className="request-box">
              <Accordion.Header className="request-box-title">
                <div className="fs-13 fw-medium">TEAMS</div>
              </Accordion.Header>
              <Accordion.Body className="request-box-body p-0">
                <div className="fs-14 fw-medium black-38">Team Name</div>
                {fileData.map((item) => (
                  <div className="upload-details" key={item.id}>
                    <div>
                      <div className="fs-14" style={{ color: "#1F2024" }}>
                        {item.fileName}
                      </div>
                      <div className="fs-13 gray-c">
                        Members: {item.uploadedTime}{" "}
                      </div>
                    </div>
                    <div className="upload-icons">
                      <button>
                        <EyesIcon />
                      </button>
                      <button onClick={() => setDelateModalShow(true)}>
                        <DeleteIcon />
                      </button>
                    </div>
                  </div>
                ))}
                <div className="text-end">
                  <button
                    onClick={() => setStakeHolderShow(true)}
                    className="add-button my-3 "
                  >
                    Add Team to Location
                  </button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>

      <DeleteModal
        show={delateModalShow}
        onHide={() => setDelateModalShow(false)}
      />
      <AddStakeHolderModal
        show={StakeHolderShow}
        onHide={() => setStakeHolderShow(false)}
      />
    </>
  );
};

export default ViewStakeHolders;

const fileData = [
  {
    id: 0,
    fileName: "Contractors Team",
    uploadedTime: "30 Users, 55 Contractors, 2 Customers",
  },
  {
    id: 1,
    fileName: "Retail Team",
    uploadedTime: "20 Users",
  },
  {
    id: 2,
    fileName: "Rubis Machakos Team",
    uploadedTime: "10 Users",
  },
];
