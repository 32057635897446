import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";
import { get, post } from "../../../Services/ApiHelper";
import {
  Add_Ticket_Additional_Labour,
  GET_ALL_USERS_BY_TEAM,
  GET_TEAMS_TO_ASSIGN_TICKET,
} from "../../../utls/constants";
import { set } from "lodash";
import Astericks from "../../Common/Asterick";

const SummaryLabourCostsModal = (props) => {
  const [selectTeam, setSelectTeam] = useState("Select team");
  const [selectTeamId, setSelectTeamId] = useState(0);
  const [selectWorker, setSelectWorker] = useState("Select worker");
  const [selectWorkerId, setSelectWorkerId] = useState(0);
  const [team, setTeam] = useState([]);
  const [workHours, setWorkHours] = useState(0);
  const [ratePerHour, setRatePerHour] = useState(0);
  const [worker, setWorker] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("bearerToken");
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);
  const [teamError, setTeamError] = useState(null);
  const [userError, setUserError] = useState(null);
  const [hoursError, setHoursError] = useState(null);
  const [rateError, setRateError] = useState(null);

  const ticketId = ticketSummary?.workOrderSummary?.ticketId;

  //demo data
  const partData = [{ name: "Select  " }, { name: "team" }];
  const partLocationData = [
    { name: "Select  " },
    { name: "worker  " },
    { name: "Part" },
  ];
  //get search input
  const [selectTeamSearch, setSelectTeamSearch] = useState("");
  const [selectWorkerSearch, setSelectWorkerSearch] = useState("");

  //filter via search input
  const filterSelectTeamData = team.filter((item) =>
    item.teamName.toLowerCase().includes(selectTeamSearch.toLowerCase())
  );
  const filterSelectWorkdersData = worker.filter((item) =>
    `${item?.user?.userFirstName} ${item?.user?.userLastName}`
      .toLowerCase()
      .includes(selectWorkerSearch.toLowerCase())
  );

  useEffect(() => {
    get(GET_TEAMS_TO_ASSIGN_TICKET, token)
      .then((data) => {
        setTeam(data);
      })
      .catch((err) => console.log(err));

    if (selectTeamId > 0) {
      get(GET_ALL_USERS_BY_TEAM(selectTeamId), token)
        .then((data) => {
          setWorker(data);
        })
        .catch((error) => console.log(error));
    }
  }, [selectTeamId]);

  useEffect(() => {
    setSelectTeam("Select team");
    setSelectWorker("Select worker");
    setWorkHours(0);
    setRatePerHour(0);
  }, [props.show]);

  //handle select
  const handleSelectTeam = (eventKey) => {
    const selectedTeam = team.find((team) => team.teamName === eventKey);
    setSelectTeam(eventKey);

    if (selectedTeam) {
      setSelectTeamId(selectedTeam.id);
    }

    setSelectTeamSearch("");
  };
  const handleSelectWorker = (eventKey) => {
    const selectedWorker = worker?.find(
      (item) =>
        item?.user?.userFirstName + "" + item?.user?.userLastName === eventKey
    );

    if (selectedWorker) {
      setSelectWorker(
        selectedWorker?.user?.userFirstName +
          " " +
          selectedWorker?.user?.userLastName
      );
      setSelectWorkerId(selectedWorker?.userId);
    } else {
      setSelectWorker("Select worker");
      setSelectWorkerId(0);
    }

    setSelectWorkerSearch("");
  };

  const handleWorkHoursChange = (e) => {
    setWorkHours(parseInt(e.target.value, 10));
  };

  const handleRatePerHourChange = (e) => {
    setRatePerHour(parseInt(e.target.value, 10));
  };

  const validateInputs = () => {
    let isValid = true;

    setTeamError(null);
    setUserError(null);
    setHoursError(null);
    setRateError(null);

    if (selectTeam === "Select team") {
      setTeamError("Required *");
      isValid = false;
    }

    if (selectWorker === "Select worker") {
      setUserError("Required *");
      isValid = false;
    }

    if (!workHours) {
      setHoursError("Required *");
      isValid = false;
    }

    if (!ratePerHour) {
      setRateError("Required *");
      isValid = false;
    }

    return isValid;
  };

  const onSubmit = () => {
    const isValid = validateInputs();

    if (!isValid) {
      return;
    }

    setIsLoading(true);
    const payload = {
      ticketId: ticketId,
      teamId: selectTeamId,
      userId: selectWorkerId,
      ratePerHour: ratePerHour,
      hoursWorked: workHours,
    };

    post(Add_Ticket_Additional_Labour, payload, token)
      .then((response) => {
        if (response.succeeded === true) {
          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          setTicketSummary((prevTicketSummary) => {
            const updatedTicketSummary = { ...prevTicketSummary };
            updatedTicketSummary.workOrderSummary.ticketLabourList.push({
              id: response.data,
              ticketId: ticketId,
              manHoursProjected: null,
              manHoursUsed: workHours,
              perHourRate: ratePerHour,
              userName: selectWorker,
              userId: selectWorkerId,
              teamName: selectTeam,
              teamId: selectTeamId,
            });
            return updatedTicketSummary;
          });
          props.onHide();

          // setTimeout(() => {
          //   props.onHide();
          //   window.location.reload();
          // });
        } else {
          props.onHide();
          setSelectTeam("Select team");
          setSelectWorker("Select worker");
          setWorkHours(0);
          setRatePerHour(0);
          toast.error(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        props.onHide();
        setSelectTeam("Select team");
        setSelectWorker("Select worker");
        setWorkHours(0);
        setRatePerHour(0);
        toast.error("An error occured", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Add Labour Costs</span>
            <span className="fs-14" style={{ color: "#72777A" }}>
              Select the team and worker to add labour costs incurred.
            </span>
          </div>
          <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">
                  Select Team
                  <Astericks />
                </label>
                {teamError && <p style={{ color: "red" }}>{teamError}</p>}
              </div>
              <Dropdown className="select__form" onSelect={handleSelectTeam}>
                <Dropdown.Toggle
                  className={`select-title ${
                    selectTeam !== "Select team" ? "selected" : ""
                  }`}
                  style={{ height: "50px" }}
                >
                  {selectTeam}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      value={selectTeamSearch}
                      onChange={(e) => setSelectTeamSearch(e.target.value)}
                      type="text"
                      placeholder="Search"
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filterSelectTeamData.map((item, index) => (
                      <Dropdown.Item key={index} eventKey={item.teamName}>
                        {item.teamName}
                      </Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">
                  Select Worker
                  <Astericks />
                </label>
                {userError && <p style={{ color: "red" }}>{userError}</p>}
              </div>

              <Dropdown className="select__form" onSelect={handleSelectWorker}>
                <Dropdown.Toggle
                  className={`select-title ${
                    selectWorker !== "Select worker" ? "selected" : ""
                  }`}
                  style={{ height: "50px" }}
                >
                  {selectWorker}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      onChange={(e) => setSelectWorkerSearch(e.target.value)}
                      value={selectWorkerSearch}
                      type="text"
                      placeholder="Search"
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filterSelectWorkdersData.map((item, index) => (
                      <Dropdown.Item
                        key={index}
                        eventKey={
                          item.user?.userFirstName +
                          "" +
                          item.user?.userLastName
                        }
                      >
                        {item.user?.userFirstName +
                          " " +
                          item.user?.userLastName}
                      </Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">
                  Hours Worked
                  <Astericks />{" "}
                </label>
                {hoursError && <p style={{ color: "red" }}>{hoursError}</p>}
              </div>
              <input
                onChange={handleWorkHoursChange}
                className="modal-input-box"
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "0 15px",
                }}
                placeholder="Hours spent on the work"
              />
            </div>
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">
                  Rate per Hour (KES)
                  <Astericks />
                </label>
                {rateError && <p style={{ color: "red" }}>{rateError}</p>}
              </div>

              <input
                onChange={handleRatePerHourChange}
                className="modal-input-box"
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "0 15px",
                }}
                placeholder="Enter rate per hour"
              />
            </div>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            {isLoading ? (
              <Spinner
                animation="border"
                style={{
                  color: "#D57D2A",
                }}
              />
            ) : (
              <Link to="" className="delate-btn" onClick={onSubmit}>
                Add
              </Link>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SummaryLabourCostsModal;
