import React,{useContext} from "react";
import RoutineInspectionCategoryContext from "../../../Context/Inspections/RoutineInspectionCategoryContext";

const WashroomSidebar = () => {

  const {routineInspectionCategoryData} = useContext(RoutineInspectionCategoryContext);



  return (
    <div className="order-sidebar-content pe-2">
      <div className="fs-13 fw-medium">INSPECTION CATEGORY DETAILS</div>
      <div className="washroom-sidebar">
        <div>
          <div className="text-ii">
            <div className="fs-14 fw-medium">Category Name: </div>
            <div className="fs-14 fw-medium text-black">{routineInspectionCategoryData.routineInspectionName}</div>
          </div>
          <div className="text-ii">
            <div className="fs-14 fw-medium"> Description: </div>
            <div className="fs-14 fw-medium text-black">{routineInspectionCategoryData.routineInspectionDescription}</div>
          </div>
          <div className="text-ii">
            <div className="fs-14 fw-medium"> Checklist:</div>
            <div className="fs-14 fw-medium text-black">
            {routineInspectionCategoryData.checklistFormName}
            </div>
          </div>
          <div className="text-ii">
            <div className="fs-14 fw-medium">Asset Category:</div>
            <div className="fs-14 fw-medium text-black">
            {routineInspectionCategoryData.assetCategoryName}
            </div>
          </div>
        </div>
        <div>
          <div className="text-ii">
            <div className="fs-14 fw-medium">Recurrence:</div>
            <div className="fs-14 fw-medium text-black">
              {routineInspectionCategoryData.frequencyType}
            </div>
          </div>
          <div className="text-ii">
            <div className="fs-14 fw-medium">Starts on</div>
            <div className="fs-14 fw-medium text-black">
              {routineInspectionCategoryData.startsOn}
            </div>
          </div>
          <div className="text-ii">
            <div className="fs-14 fw-medium">Ends on</div>
            <div className="fs-14 fw-medium text-black">
              {routineInspectionCategoryData.endsOn}
            </div>
          </div>
          <div className="text-ii">
            <div className="fs-14 fw-medium">Ends after</div>
            <div className="fs-14 fw-medium text-black">
              {routineInspectionCategoryData.frequency} Occurrences
            </div>
          </div>
        </div>
      </div>


    </div>
  );
};

export default WashroomSidebar;
