import React, { useContext, useState } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FaCircleCheck } from "react-icons/fa6";
import { toast } from "react-toastify";
import { post } from "../../../Services/ApiHelper";
import { TICKET_RESUME_WORK } from "../../../utls/constants";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";

const ResumeModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);

  const handleResume = async () => {
    setIsLoading(true);

    const bearerToken = localStorage.getItem("bearerToken");
    const data = {
      ticketId: props.ticketId,
    };

    post(TICKET_RESUME_WORK, data, bearerToken)
      .then((response) => {
        if (response.succeeded === true) {
          setTicketSummary({
            ...ticketSummary,
            workOrderSummary: {
              ...ticketSummary.workOrderSummary,
              currentTicketActivity: "RESUME",
            },
          });
          props.onHide();
          setIsLoading(false);

          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.error(response.messages[0],  {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((error) => {
        props.onHide();
        setIsLoading(false);
        console.error("Error deleting item:", error);
        toast.error("An error occured", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .finally(() => {
        props.onHide();
        setIsLoading(false);
      });
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="">
            <span className="fs-16 fw-bold ">Resume Work</span>
            <span className="fs-14 ps-3">Resume work that was on hold</span>
          </div>
          <div
            className="delate-warning"
            style={{
              background: "#F1EFEF",
              height: "267px",
              display: "grid",
              alignItems: "center",
              textAlign: "center",
              padding: "20px",
              marginTop: "30px",
              borderRadius: "5px",
            }}
          >
            <div>
              <FaCircleCheck style={{ color: "#D57D2A", fontSize: "70px" }} />
              <div
                className="fs-20 fw-medium "
                style={{
                  lineHeight: "1.4",
                  marginTop: "10px",
                  color: "#72777A",
                }}
              >
                Please confirm that you want to resume the <br /> work that was
                on hold.
              </div>
            </div>
          </div>
          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>

            {isLoading ? (
              <Spinner
                animation="border"
                style={{
                  color: "#D57D2A",
                }}
              />
            ) : (
              <button className="delate-btn" onClick={handleResume}>
                Resume
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ResumeModal;
