import React from "react";

const PumpSidebar = () => {
  return (
    <div className="order-sidebar-content">
      <div className="fs-13 fw-medium">SCHEDULE CATEGORY DETAILS</div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="text-ii">
          <div className="fs-14 fw-medium">Name: </div>
          <div className="fs-14 fw-medium text-black">Pump Calibration </div>
        </div>
        <div className="text-dd">
          <div className="fs-14 fw-medium">Recurrence:</div>
          <div className="fs-14 text-black pt-2">Annually</div>
        </div>
      </div>
      <div className="text-ii">
        <div className="fs-14 fw-medium"> Category of Work: </div>
        <div className="fs-14 fw-medium text-black">Pump Calibration</div>
      </div>
      <div className="text-ii">
        <div className="fs-14 fw-medium"> Asset Category:</div>
        <div className="fs-14 fw-medium text-black">Pumps</div>
      </div>
      <div className="text-ii">
        <div className="fs-14 fw-medium"> Checklist:</div>
        <div className="fs-14 fw-medium text-black">
          Pump Calibration Checklist
        </div>
      </div>
      <div className="text-ii">
        <div className="fs-14 fw-medium"> Priority:</div>
        <button className="high-btn">high</button>
      </div>
    </div>
  );
};

export default PumpSidebar;
