import React from "react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { IoMdTime } from "react-icons/io";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { post } from "../../../Services/ApiHelper";
import { CREATE_PM_TEMPLATE } from "../../../utls/constants";

const WorkScheduleSummary = () => {
  const value = { value: 1 };
  const [isLoading, setIsLoading] = useState(false);

  const storageData = localStorage.getItem("templateSchedule");
  const parsedStoragedata = JSON.parse(storageData);
  console.log(parsedStoragedata);

  const ScheduleTemplateDetails = JSON.parse(
    localStorage.getItem("scheduleTemplate")
  );

  const storageDataPart = localStorage.getItem("selectedProjectedPartsNew");
  const parsedStoragedataPart = JSON.parse(storageDataPart);

  const transformedArray = parsedStoragedataPart?.map((item) => ({
    spareId: item.partId,
    quantity: item.quantity,
  }));
  const handleSubmit = (event) => {
    setIsLoading(true);
    event.preventDefault();

    const token = localStorage.getItem("bearerToken");

    const payload = {
      templateName: ScheduleTemplateDetails?.tempName,
      templateDescription: ScheduleTemplateDetails?.templateDescription,
      categoryOfWorkId: ScheduleTemplateDetails.cowId,
      assetCategoryId: ScheduleTemplateDetails?.assetCategoryId,
      ticketPriorityId: ScheduleTemplateDetails?.priorityId,
      recurring: ScheduleTemplateDetails?.recurrenceTime,
      calendarTypeId: ScheduleTemplateDetails?.calenderTypeId,
      estimatedHours: ScheduleTemplateDetails?.estimateTime,
      checkListIds: parsedStoragedata?.selectedChecklistIds,
      projectedParts: transformedArray,
    };

    post(CREATE_PM_TEMPLATE, payload, token)
      .then((response) => {
        if (response.succeeded === true) {
          setIsLoading(false);
          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          setTimeout(() => {
            window.location.href = "/work-schedule-templates";
          }, 3000);

          localStorage.removeItem("selectedChecklistIds");
          localStorage.removeItem("selectedProjectedParts");
          localStorage.removeItem("selectedChecklistIds");
          localStorage.removeItem("selectedChecklistDets");
          localStorage.removeItem("selectedProjectedPartsNew");
          localStorage.removeItem("mainChecklistDets");
          localStorage.removeItem("templateSchedule");
          localStorage.removeItem("scheduleTemplate");
        } else {
          toast.error(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className="order-details-content PMWorkScheduling pb-lg-4">
        <div className="fs-16 fw-bold">Summary</div>

        <div className="work-order-summary mt-5">
          <div
            className="fs-14 fw-semibold pb-4"
            style={{ color: "#00000061" }}
          >
            SCHEDULE TEMPLATE DETAILS
          </div>
          <div className="row" style={{ rowGap: "24px" }}>
            <div className="col-md-3">
              <div
                className="fs-14 fw-semibold pb-2"
                style={{ color: "#00000061" }}
              >
                Template Name
              </div>
              <div className="fs-14 fw-medium">
                {ScheduleTemplateDetails?.tempName}
              </div>
            </div>
            <div className="col-md-3">
              <div
                className="fs-14 fw-semibold pb-2"
                style={{ color: "#00000061" }}
              >
                Category of Work:
              </div>
              <div className="fs-14 fw-medium">
                {ScheduleTemplateDetails?.cow}
              </div>
            </div>
            <div className="col-md-3">
              <div
                className="fs-14 fw-semibold pb-2"
                style={{ color: "#00000061" }}
              >
                Asset Category
              </div>
              <div className="fs-14 fw-medium">
                {ScheduleTemplateDetails?.assetCategory}
              </div>
            </div>
            <div className="col-md-3">
              <div
                className="fs-14 fw-semibold pb-2"
                style={{ color: "#00000061" }}
              >
                Work recurs every:
              </div>
              <div className="fs-14 fw-medium">
                {ScheduleTemplateDetails?.recurrenceTime}{" "}
                {ScheduleTemplateDetails?.calenderType}(s)
              </div>
            </div>
          </div>
          <br /> <br />
          <div className="row" style={{ rowGap: "24px" }}>
            <div className="col-md-6">
              <div
                className="fs-14 fw-semibold pb-2"
                style={{ color: "#00000061" }}
              >
                Description:
              </div>
              <div className="fs-14 fw-medium" style={{ lineHeight: "1.3" }}>
                {ScheduleTemplateDetails?.templateDescription}
              </div>
            </div>
            <div className="col-md-3">
              <div
                className="fs-14 fw-semibold pb-2"
                style={{ color: "#00000061" }}
              >
                Work Estimate Hours{" "}
              </div>
              <div className="fs-14 fw-medium">
                {ScheduleTemplateDetails?.estimateTime} Hour(s)
              </div>
            </div>
            <div className="col-md-3">
              <div
                className="fs-14 fw-semibold pb-2"
                style={{ color: "#00000061" }}
              >
                Priority:
              </div>
              <div className="fs-14 fw-bold" style={{ color: "#D57D2A" }}>
                {ScheduleTemplateDetails?.priority}
              </div>
            </div>
          </div>
          <hr />
          <div
            className="fs-14 fw-semibold pb-3"
            style={{ color: "#00000061" }}
          >
            PROJECTED PARTS DETAILS
          </div>
          <div className="row">
            <div className="col-md-3 d-grid gap-2">
              {parsedStoragedataPart?.map((item) => {
                return (
                  <div className="fs-14 fw-medium">
                    {item.part}, {item.quantity} Piece(s){" "}
                  </div>
                );
              })}
            </div>
          </div>
          <hr />
          <div
            className="fs-14 fw-semibold pb-3"
            style={{ color: "#00000061" }}
          >
            CHECKLIST DETAILS
          </div>
          <div className="row">
            <div className="col-md-3 d-grid gap-2">
              {parsedStoragedata?.selectedChecklists?.map((item) => {
                return <div className="fs-14 fw-medium">{item}</div>;
              })}
            </div>
          </div>
        </div>

        <div className="details-buttons pt-lg-3 d-flex justify-content-between">
          <Link
            to="/work-schedule-templates/create-template"
            className="next-btn ms-0"
            state={value}
          >
            Previous
          </Link>

          {isLoading ? (
            <Spinner animation="border" style={{ color: "#D57D2A" }} />
          ) : (
            <Link
              to="/work-schedule-templates"
              className="next-btn ms-0"
              onClick={handleSubmit}
            >
              Submit
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export default WorkScheduleSummary;
