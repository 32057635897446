import React, { useState, useEffect, useContext } from "react";
import { Dropdown, Nav, Tab, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import { DiagnosisDetailsContext } from "../../../Context/Inspections/WorkOrderContext";
import { toast } from "react-toastify";
import { get, post } from "../../../Services/ApiHelper";
import {
  GET_REQUEST_PROJECTEDPART_BY_TEAM,
  ADD_RFQ_QUOTEITEM,
  GET_TICKET_OTHER_COST_BY_TICKET_ID,
} from "../../../utls/constants";
import Astericks from "../../Common/Asterick";

const AddLineItemQuotesModal = (props) => {
  const { diagnosisSummary, setDiagnosisSummary } = useContext(
    DiagnosisDetailsContext
  );
  const token = localStorage.getItem("bearerToken");
  const [quantity, setQuantity] = useState(0);
  const [unitCost, setUnitCost] = useState(0);
  const [description, setDescription] = useState("");
  const [parts, setParts] = useState([]);
  const [costTypes, setCostTypes] = useState([]);
  const [selectedPartPart, setselectedPartPart] =
    useState("Select a used part");
  const [selectedPartCategory, setselectedPartCategory] = useState(
    "Select cost category"
  );
  const [maxQuantity, setMaxQuantity] = useState(null);
  const [selectedPartId, setSelectedPartId] = useState(0);
  const [selectedRequestPartId, setSelectedRequestPartId] = useState(0);
  const [selectedPartName, setSelectedPartName] = useState("");
  const [selectedPartSerial, setSelectedPartSerial] = useState("");
  const [selectedItemTypeId, setSelectedItemTypeId] = useState(0);
  const [selectedItemTypeName, setSelectedItemTypeName] = useState("");
  const [activeKey, setActiveKey] = useState("first"); // Initial active key state
  //search inputs
  const [partPartSearch, setPartPartSearch] = useState("");
  const [partCategorySearch, setPartCategorySearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [partError, setPartError] = useState(null);
  const [quantError, setQuantError] = useState(null);
  const [unitcostError, setUnitCostError] = useState(null);
  const [costTypeError, setCostTypeError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);

  useEffect(() => {
    get(
      GET_REQUEST_PROJECTEDPART_BY_TEAM(
        diagnosisSummary?.requestDetailSummary?.id
      ),
      token
    )
      .then((response) => setParts(response))
      .catch((error) => console.log(error));

    get(GET_TICKET_OTHER_COST_BY_TICKET_ID, token)
      .then((response) => setCostTypes(response))
      .catch((error) => console.log(error));

    setselectedPartPart("Select a used part");
    setselectedPartCategory("Select cost category");
    setQuantity(0);
    setUnitCost(0);
    setDescription("");
    setQuantError(null);
    setPartError(null);
    setCostTypeError(null);
    setUnitCostError(null);
    setDescriptionError(null);
  }, [props.show]);
  //filter part data  by search input
  const filteredPartPartData = Array.isArray(parts)
    ? parts.filter((item) =>
        item?.partName.toLowerCase().includes(partPartSearch.toLowerCase())
      )
    : [];
  const filteredPartCategoryData = costTypes.filter((item) =>
    item.ticketCostItemTypeName
      .toLowerCase()
      .includes(partCategorySearch.toLowerCase())
  );
  const handlePartPartSelect = (eventKey) => {
    const partObject = parts.find((p) => p.partName === eventKey);
    if (partObject) {
      setselectedPartPart(eventKey);
      setSelectedPartId(partObject.partId);
      setSelectedPartName(partObject.partName);
      setSelectedPartSerial(partObject.partSerialNumber);
      setSelectedRequestPartId(partObject.id);
      setMaxQuantity(partObject.quantityProjected);
      setSelectedItemTypeName("Part");
    }
    setPartPartSearch("");
  };
  const handlePartCategorySelect = (eventKey) => {
    const costTypeObject = costTypes.find(
      (p) => p.ticketCostItemTypeName === eventKey
    );
    if (costTypeObject) {
      setselectedPartCategory(eventKey);
      setSelectedItemTypeId(costTypeObject.id);
      setSelectedItemTypeName(costTypeObject.ticketCostItemTypeName);
    }
    setPartCategorySearch("");
  };

  const validateInputs = (activeTab) => {
    let isValid = true;
    setPartError(null);
    setQuantError(null);
    setCostTypeError(null);
    setUnitCostError(null);
    setDescriptionError(null);
    if (activeTab === "first" && selectedPartPart === "Select a used part") {
      setPartError("Required *");
      isValid = false;
    }
    if (
      activeTab === "second" &&
      selectedPartCategory === "Select cost category"
    ) {
      setCostTypeError("Required *");
      isValid = false;
    }
    if (activeTab === "second" && (!description || description == "")) {
      setDescriptionError("Required *");
      isValid = false;
    }
    if (!quantity || quantity == 0) {
      setQuantError("Required *");
      isValid = false;
    }
    if (activeTab === "first" && quantity > maxQuantity) {
      setQuantError("Quantity exceeds projected *");
      isValid = false;
    }
    if (!unitCost || unitCost == 0) {
      setUnitCostError("Required *");
      isValid = false;
    }
    return isValid;
  };

  const onSubmit = (activeTab) => {
    const isValid = validateInputs(activeTab);
    if (!isValid) {
      return;
    }
    setIsLoading(true);
    const payload = {
      quoteId: diagnosisSummary?.requestQuoteSummary?.quoteId,
      quoteItemSource: activeTab === "first" ? "Parts" : "Other",
      requestAssetProjectedPartId:
        activeTab === "first" ? selectedRequestPartId : 0,
      itemTypeId: activeTab === "second" ? selectedItemTypeId : 0,
      itemDescription: activeTab === "first" ? selectedPartPart : description,
      quantity: quantity,
      unitCost: unitCost,
    };

    post(ADD_RFQ_QUOTEITEM, payload, token)
      .then((response) => {
        if (response.succeeded === true) {
          props.onHide();
          setIsLoading(false);
          setselectedPartPart("Select a used part");
          setselectedPartCategory("Select cost category");
          setQuantity(0);
          setUnitCost(0);
          setDescription("");

          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          const newQuoteItemData = {
            quoteId: diagnosisSummary?.requestQuoteSummary?.quoteId,
            quoteItemId: response.data,
            quoteItemSourceName: activeTab === "first" ? "Parts" : "Other",
            itemTypeId: activeTab === "second" ? selectedItemTypeId : 0,
            itemTypName: selectedItemTypeName,
            requestAssetProjectedPartId:
              activeTab === "first" ? selectedRequestPartId : 0,
            partId: activeTab === "first" ? selectedPartId : 0,
            description: activeTab === "first" ? selectedPartPart : description,
            quantity: quantity,
            unitCost: unitCost,
            totalCost: quantity * unitCost,
          };
          setDiagnosisSummary((prevDiagnosisSummary) => ({
            ...prevDiagnosisSummary,
            requestQuoteSummary: {
              ...prevDiagnosisSummary?.requestQuoteSummary,
              quoteItems: [
                ...prevDiagnosisSummary?.requestQuoteSummary?.quoteItems,
                newQuoteItemData,
              ],
            },
          }));
          //Update Quote Total
          setDiagnosisSummary((prevDiagnosisSummary) => ({
            ...prevDiagnosisSummary,
            requestQuoteSummary: {
              ...prevDiagnosisSummary?.requestQuoteSummary,
              total:
                prevDiagnosisSummary?.requestQuoteSummary?.total +
                quantity * unitCost,
            },
          }));
        } else {
          props.onHide();
          setIsLoading(false);
          setselectedPartPart("Select a used part");
          setselectedPartCategory("Select cost category");
          setQuantity(0);
          setUnitCost(0);
          setDescription("");

          toast.error(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((error) => {
        props.onHide();
        setIsLoading(false);
        setselectedPartPart("Select a used part");
        setselectedPartCategory("Select cost category");
        setQuantity(0);
        setUnitCost(0);
        setDescription("");
        toast.error("An error occured", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
      })
      .finally(() => {
        props.onHide();
        setIsLoading(false);
        setselectedPartPart("Select a used part");
        setselectedPartCategory("Select cost category");
        setQuantity(0);
        setUnitCost(0);
        setDescription("");
      });
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Add Line Item</span>
            <span className="fs-14" style={{ color: "#72777A" }}>
              Enter information about the quote line item
            </span>
          </div>
          <div className="mt-4 pt-2 addLien-tab">
            <div className="fs-14 fw-semibold text-black">Cost Type</div>
            <Tab.Container
              defaultActiveKey="first"
              activeKey={activeKey} // Pass activeKey state here
              onSelect={(key) => setActiveKey(key)} // Update activeKey state when a tab is selected
            >
              <Nav variant="pills" className="py-3 mb-2">
                <Nav.Item>
                  <Nav.Link eventKey="first">Parts Costs</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Other Costs</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <div className="d-grid gap-4 modal-forms-content">
                    <div className="col-md-12">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label className="fw-medium pb-2 fs-14">
                          Select Part
                          <Astericks />
                        </label>
                        {partError && (
                          <p style={{ color: "red" }}>{partError}</p>
                        )}
                      </div>
                      <Dropdown
                        className="select__form"
                        onSelect={handlePartPartSelect}
                      >
                        <Dropdown.Toggle
                          className={`select-title ${
                            selectedPartPart !== "Select a used part"
                              ? "selected"
                              : ""
                          }`}
                          style={{ height: "50px" }}
                        >
                          {selectedPartPart}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <form className="dropdown-search">
                            <button disabled>
                              <SearchIcon />
                            </button>
                            <input
                              value={partPartSearch}
                              onChange={(e) =>
                                setPartPartSearch(e.target.value)
                              }
                              type="text"
                              placeholder="Search"
                            />
                          </form>
                          <div className="dropdown-item-content">
                            {filteredPartPartData.map((item, index) => (
                              <Dropdown.Item
                                key={index}
                                eventKey={item.partName}
                              >
                                {item.partName}
                              </Dropdown.Item>
                            ))}
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="col-md-12">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label className="fw-medium pb-2 fs-14">
                          Part Quantity to be Costed
                          <Astericks />
                        </label>
                        {quantError && (
                          <p style={{ color: "red" }}>{quantError}</p>
                        )}
                      </div>
                      <input
                        className="modal-input-box"
                        type="number"
                        onChange={(e) => setQuantity(e.target.value)}
                        min="1"
                        max={maxQuantity}
                        style={{
                          background: "#F1EFEF",
                          width: "100%",
                          border: "0",
                          height: "50px",
                          borderRadius: "5px",
                          padding: "0 15px",
                        }}
                        placeholder="Enter quantity"
                        onKeyPress={(event) => {
                          // Prevent typing of negative sign
                          if (event.key === "-") {
                            event.preventDefault();
                          }
                        }}
                        onInput={(event) => {
                          // Remove non-integer characters
                          event.target.value = event.target.value.replace(
                            /\D/g,
                            ""
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-12">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label className="fw-medium pb-2 fs-14">
                          Enter Unit Cost
                          <Astericks />
                        </label>
                        {unitcostError && (
                          <p style={{ color: "red" }}>{unitcostError}</p>
                        )}
                      </div>
                      <input
                        className="modal-input-box"
                        type="number"
                        onChange={(e) => setUnitCost(e.target.value)}
                        min="0"
                        style={{
                          background: "#F1EFEF",
                          width: "100%",
                          border: "0",
                          height: "50px",
                          borderRadius: "5px",
                          padding: "0 15px",
                        }}
                        placeholder="Enter unit part cost"
                        onKeyPress={(event) => {
                          // Prevent typing of negative sign
                          if (event.key === "-") {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <div className="d-grid gap-4 modal-forms-content">
                    <div className="col-md-12">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label className="fw-medium pb-2 fs-14">
                          Cost Category
                          <Astericks />
                        </label>
                        {costTypeError && (
                          <p style={{ color: "red" }}>{costTypeError}</p>
                        )}
                      </div>
                      <Dropdown
                        className="select__form"
                        onSelect={handlePartCategorySelect}
                      >
                        <Dropdown.Toggle
                          className={`select-title ${
                            selectedPartCategory !== "Select cost category"
                              ? "selected"
                              : ""
                          }`}
                          style={{ height: "50px" }}
                        >
                          {selectedPartCategory}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <form className="dropdown-search">
                            <button disabled>
                              <SearchIcon />
                            </button>
                            <input
                              value={partCategorySearch}
                              onChange={(e) =>
                                setPartCategorySearch(e.target.value)
                              }
                              type="text"
                              placeholder="Search"
                            />
                          </form>
                          <div className="dropdown-item-content">
                            {filteredPartCategoryData.map((item, index) => (
                              <Dropdown.Item
                                key={index}
                                eventKey={item.ticketCostItemTypeName}
                              >
                                {item.ticketCostItemTypeName}
                              </Dropdown.Item>
                            ))}
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="col-md-12">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label className="fw-medium pb-2 fs-14">
                          Cost Description
                          <Astericks />
                        </label>
                        {descriptionError && (
                          <p style={{ color: "red" }}>{descriptionError}</p>
                        )}
                      </div>
                      <input
                        className="modal-input-box"
                        type="text"
                        onChange={(e) => setDescription(e.target.value)}
                        style={{
                          background: "#F1EFEF",
                          width: "100%",
                          border: "0",
                          height: "50px",
                          borderRadius: "5px",
                          padding: "0 15px",
                        }}
                        placeholder="Enter cost description"
                      />
                    </div>
                    <div className="col-md-12">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label className="fw-medium pb-2 fs-14">
                          Quantity
                          <Astericks />
                        </label>
                        {quantError && (
                          <p style={{ color: "red" }}>{quantError}</p>
                        )}
                      </div>
                      <input
                        className="modal-input-box"
                        type="number"
                        onChange={(e) => setQuantity(e.target.value)}
                        min="1"
                        style={{
                          background: "#F1EFEF",
                          width: "100%",
                          border: "0",
                          height: "50px",
                          borderRadius: "5px",
                          padding: "0 15px",
                        }}
                        placeholder="Enter quantity"
                        onKeyPress={(event) => {
                          // Prevent typing of negative sign
                          if (event.key === "-") {
                            event.preventDefault();
                          }
                        }}
                        onInput={(event) => {
                          // Remove non-integer characters
                          event.target.value = event.target.value.replace(
                            /\D/g,
                            ""
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-12">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label className="fw-medium pb-2 fs-14">
                          Unit Cost
                          <Astericks />
                        </label>
                        {unitcostError && (
                          <p style={{ color: "red" }}>{unitcostError}</p>
                        )}
                      </div>
                      <input
                        className="modal-input-box"
                        type="number"
                        onChange={(e) => setUnitCost(e.target.value)}
                        min="0"
                        style={{
                          background: "#F1EFEF",
                          width: "100%",
                          border: "0",
                          height: "50px",
                          borderRadius: "5px",
                          padding: "0 15px",
                        }}
                        placeholder="Enter unit cost"
                        onKeyPress={(event) => {
                          // Prevent typing of negative sign
                          if (event.key === "-") {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            {isLoading ? (
              <Spinner
                animation="border"
                style={{
                  color: "#D57D2A",
                }}
              />
            ) : (
              <Link
                to=""
                className="delate-btn"
                onClick={() => onSubmit(activeKey)}
              >
                Add
              </Link>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddLineItemQuotesModal;
