import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import SearchIcon from "../../../../Assets/Icons/SearchIcon";
import DatePicker from "react-datepicker";
import { get } from "../../../../Services/ApiHelper";
import {
  GET_CALENDAR_TYPES,
  GET_PM_SCHEDULES,
  GET_PM_TEMPLATE_BY_ID,
} from "../../../../utls/constants";

const UseSchedule = () => {
  const [previousDate, setPreviousDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedSchedule, setSelectedSchedule] = useState(
    "Select schedule template from list"
  );

  const [selectedday, setselectedday] = useState("Select");
  const [selectedday2, setselectedday2] = useState("Select");
  // search input
  const [daySearch, setdaySearch] = useState("");
  const [daySearch2, setdaySearch2] = useState("");
  const [locationSearch, setLocationSearch] = useState("");
  const [scheduleSearch, setScheduleSearch] = useState("");
  const [calenderType, setCalenderType] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [selectedScheduleId, setSelectedScheduleId] = useState(0);
  const [occurence, setOccurence] = useState(1);
  const [calendarTypeId, setCalendarTypeId] = useState(0);

  const [projectedParts, setProjectedParts] = useState([]);
  const [checklist, setChecklist] = useState([]);
  const [submit, setSubmit] = useState(false);

  const handleScheduleSelect = (eventKey) => {
    const scheduleObject = scheduleData?.find(
      (item) => item.templateName === eventKey
    );
    if (scheduleObject) {
      setSubmit(true);
      setSelectedScheduleId(scheduleObject.id);
      get(GET_PM_TEMPLATE_BY_ID(scheduleObject.id), bearerToken)
        .then((response) => {
          const selectedInfo = {
            templateId: response.id,
            calendarTypeId: response.calendarTypeId,
            calendarTypeName: response.calendarTypeName || "Select",
            recursEvery: response.recursEvery || 0,
            categoryOfWorkId: response.categoryOfWorkId,
            categoryOfWorkName: response.categoryOfWorkName || "Select",
            estimatedTime: response.estimatedHours || 0,
            priorityId: response.ticketPriorityId,
            priorityName: response.priorityName || "Select",
            projectParts: response.projectedParts,
            checklist: response.checkLists,
            assetCategoryId: response.assetCategoryId,
            assetCategoryName: response.assetCategoryName || "Select",
          };

          localStorage.setItem(
            "SelectedTemplateData",
            JSON.stringify(selectedInfo)
          );
          setOccurence(response.recursEvery);
          setCalendarTypeId(response.calendarTypeId);
          setselectedday(response.calendarTypeName);
          setselectedday2(response.calendarTypeName);
          setProjectedParts(response.projectedParts);
          setChecklist(response.checkLists);
          updateScheuduleleBasis();
        })
        .catch((error) => console.log(error));
    }
    setSelectedSchedule(eventKey);
    setScheduleSearch("");
    updateScheuduleleBasis();
  };

  const handleSelect = (eventKey) => {
    setselectedday(eventKey);
    setdaySearch("");
    updateScheuduleleBasis();
  };

  const handleSelect2 = (eventKey) => {
    setselectedday2(eventKey);
    setdaySearch2("");
    updateScheuduleleBasis();
  };

  const filteredScheduleData = scheduleData?.filter((item) => {
    return item?.templateName
      ?.toLowerCase()
      .includes(scheduleSearch.toLowerCase());
  });

  const filteredDayData = calenderType?.filter((item) => {
    //return item?.name?.toLowerCase().includes(daySearch2.toLowerCase());
    return (
      item.name.toLowerCase().includes(daySearch2.toLowerCase()) &&
      item.name.toLowerCase() !== "year" &&
      item.name.toLowerCase() !== "hour"
    );
  });

  const bearerToken = localStorage.getItem("bearerToken");
  useEffect(() => {
    get(GET_CALENDAR_TYPES, bearerToken)
      .then((response) => {
        setCalenderType(response.data);
      })
      .catch((error) => console.log(error));

    get(GET_PM_SCHEDULES, bearerToken)
      .then((response) => {
        setScheduleData(response.data);
      })
      .catch((error) => console.log(error));

  }, []);
  const [nextPMOnCompletion, setnextPMOnCompletion] = useState(true);
  useEffect(() => {
    updateScheuduleleBasis();
  }, [
    occurence,
    calendarTypeId,
    previousDate,
    startDate,
    endDate,
    nextPMOnCompletion,
    checklist,
    projectedParts,
  ]);
  const updateScheuduleleBasis = () => {
    const selectedInfo = {
      reocurrence: occurence,
      calendarTypeId: calendarTypeId,
      calendarTypeName: selectedday,
      previousDate: previousDate,
      startDate: startDate,
      setEndDate: endDate,
      ticketTypeDescription: "preventiveschedule",
      nextPMOnCompletion: nextPMOnCompletion,
      projectedParts: [],
      checklist: [],
    };
    localStorage.setItem("ScheduleBasis", JSON.stringify(selectedInfo));
  };
  const clear = (basis) => {
    setnextPMOnCompletion(basis);
    updateScheuduleleBasis();

    setCalendarTypeId(0);
    setOccurence(1);
    setPreviousDate(null);
    setStartDate(null);
    setEndDate(null);
    setselectedday("Select");
    setselectedday2("Select");
  };
  return (
    <div className="schedule-const">
      <Tab.Container defaultActiveKey="first">
        <div className="fs-16 fw-semibold text-black pt-5">Date Basis</div>
        <Nav variant="pills" className="scheduling-nav pt-2">
          <Nav.Item>
            <Nav.Link
              eventKey="first"
              onClick={() => {
                clear(true);
              }}
            >
              Based on previous completion date
            </Nav.Link>
            <div className="fs-13 text-black fst-italic">
              This option allows you to create only one schedule at a time
            </div>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="second"
              onClick={() => {
                clear(false);
              }}
            >
              Based on fixed dates
            </Nav.Link>
            <div className="fs-13 text-black fst-italic">
              This option allows you to create only one schedule at a time
            </div>
          </Nav.Item>
        </Nav>
        <hr />
        <div className="fs-16 fw-bold text-black">Recurrence</div>
        <Tab.Content className="scheduling-fff mt-4">
          <Tab.Pane eventKey="first">
            <div className="row row-gap">
              <div className="col-lg-6">
                <label htmlFor="" className="fs-15 pb-3">
                  Select Schedule Template
                </label>
                <div className="scheduling-input pe-3">
                  <Dropdown
                    className="select__form"
                    onSelect={handleScheduleSelect}
                  >
                    <Dropdown.Toggle
                      className={`select-title ${
                        selectedSchedule !==
                        "Select schedule  template from list"
                          ? "selected"
                          : ""
                      }`}
                    >
                      {selectedSchedule}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <form className="dropdown-search">
                        <button disabled>
                          <SearchIcon />
                        </button>
                        <input
                          value={locationSearch}
                          onChange={(e) => setLocationSearch(e.target.value)}
                          type="text"
                          placeholder="Search"
                        />
                      </form>
                      <div className="dropdown-item-content">
                        {filteredScheduleData.map((item, index) => (
                          <Dropdown.Item
                            key={index}
                            eventKey={item.templateName}
                          >
                            {item.templateName}
                          </Dropdown.Item>
                        ))}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="col-lg-3 px-0">
                <label htmlFor="" className="fs-15 pb-3 d-block">
                  Recurs every
                </label>
                <div className="scheduling-input">
                  <input
                    type="number"
                    min={1}
                    className="input-black"
                    value={occurence}
                    onChange={(e) => {
                      setOccurence(e.target.value);
                      updateScheuduleleBasis();
                    }}
                    style={{ width: "80px" }}
                  />
                  <Dropdown className="select__form" onSelect={handleSelect}>
                    <Dropdown.Toggle
                      className={`select-title ${
                        selectedday !== "Select" ? "selected" : ""
                      }`}
                    >
                      {selectedday}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="dropdown-item-content">
                        {filteredDayData.map((item, index) => (
                          <Dropdown.Item key={index} eventKey={item.name}>
                            {item.name}
                          </Dropdown.Item>
                        ))}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="fs-13 text-black fst-italic pt-2">
                  Select a schedule to view recurrence
                </div>
              </div>
              <div className="col-lg-3 px-0">
                <div className="col-12 px-2">
                  <label htmlFor="" className="fs-15 pb-3 d-block">
                    Previous completion date
                  </label>
                  <DatePicker
                    className="shedule-calendar"
                    selected={previousDate}
                    onChange={(date) => setPreviousDate(date)}
                  />
                </div>
              </div>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="second">
            {" "}
            <div className="row row-gap">
              <div className="col-lg-6">
                <label htmlFor="" className="fs-15 pb-3">
                  Select Schedule
                </label>
                <div className="scheduling-input pe-2">
                  <Dropdown
                    className="select__form"
                    onSelect={handleScheduleSelect}
                  >
                    <Dropdown.Toggle
                      className={`select-title ${
                        selectedSchedule !== "Select schedule from list"
                          ? "selected"
                          : ""
                      }`}
                    >
                      {selectedSchedule}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <form className="dropdown-search">
                        <button disabled>
                          <SearchIcon />
                        </button>
                        <input
                          value={scheduleSearch}
                          onChange={(e) => setScheduleSearch(e.target.value)}
                          type="text"
                          placeholder="Search"
                        />
                      </form>
                      <div className="dropdown-item-content">
                        {filteredScheduleData.map((item, index) => (
                          <Dropdown.Item
                            key={index}
                            eventKey={item.templateName}
                          >
                            {item.templateName}
                          </Dropdown.Item>
                        ))}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="col-lg-4">
                <label htmlFor="" className="fs-15 pb-3 d-block">
                  Recurs every
                </label>
                <div className="scheduling-input">
                  <input
                    type="number"
                    min={0}
                    value={occurence}
                    onChange={(e) => setOccurence(e.target.value)}
                    className="input-black"
                    style={{ width: "80px" }}
                  />
                  <Dropdown className="select__form" onSelect={handleSelect2}>
                    <Dropdown.Toggle
                      className={`select-title ${
                        selectedday2 !== "Select" ? "selected" : ""
                      }`}
                    >
                      {selectedday2}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="dropdown-item-content">
                        {filteredDayData.map((item, index) => (
                          <Dropdown.Item key={index} eventKey={item.name}>
                            {item.name}
                          </Dropdown.Item>
                        ))}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="fs-13 text-black fst-italic pt-2">
                  Select a schedule to view recurrence
                </div>
              </div>
              <div className="row pt-3 row-gap">
                <div className="col-lg-3">
                  <label htmlFor="" className="fs-15 pb-3 d-block">
                    Starts on
                  </label>

                  <div className="scheduling-input">
                    <DatePicker
                      maxDate={endDate}
                      className="shedule-calendar"
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                        updateScheuduleleBasis();
                      }}
                      disabledKeyboardNavigation
                      placeholderText="Date"
                    />
                  </div>
                </div>
                <div className="col-lg-3 offset-lg-1">
                  <label htmlFor="" className="fs-15 pb-3 d-block">
                    Ends on
                  </label>
                  <div className="scheduling-input">
                    <DatePicker
                      minDate={startDate}
                      className="shedule-calendar"
                      selected={endDate}
                      onChange={(date) => {
                        setEndDate(date);
                        updateScheuduleleBasis();
                      }}
                      disabledKeyboardNavigation
                      placeholderText="Date"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default UseSchedule;
