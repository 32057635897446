import React from "react";
import { Link } from "react-router-dom";

const CreateSidebar = ({item}) => {
  return (
    <div className="add-pm-sidebar order-nav-content">
      <div className="fs-19 fw-bold">{item ===null ? "ADD":"EDIT" } INSPECTION CATEGORY</div>
      <div className="order-nav-lists">
                  <Link className="active">Inspection Details</Link>
                </div> 
    </div>
  );
};

export default CreateSidebar;
