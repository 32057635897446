import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import usefetchData from "../../../hooks/useFetchData";
import useMutateData from "../../../hooks/useMutateData";
import { toast } from "react-toastify";

const EditBasicInfoModal = ({show, onHide, basicInfo}) => {
  const [selectValue, setSelectValue] = useState({
    asset: "Select",
    assetCategory: basicInfo?.assetCategoryName ?? "Select category from list",
    location: basicInfo?.assetStatusName ?? "Select status from list",
    assignAdditionalTeam: "Select",
  });

  const [selectedAssetCategory, setSelectedAssetCategory] = useState(
    basicInfo?.assetCategoryName ?? "Select category from list"
  );
  const [categoryId, setCategoryId] = useState(basicInfo?.assetCategoryId)
  const [statusId, setStatusId] = useState(basicInfo?.assetStatusId)
  const [conditionId, setConditionId] = useState(basicInfo?.assetConditionId)
  const [selectedAssetStatus, setSelectedAssetStatus] = useState(
    basicInfo?.assetStatusName ?? "Select status from list"
  );
  const [selectedCondition, setSelectedAssetCondition] = useState(
    basicInfo?.assetConditionName ?? "Select condition from list"
  );

  console.log("category", selectedAssetCategory)
  console.log("status", selectedAssetStatus)
  console.log("consition", selectedCondition)

  // user input change
  const [assetName, setAssetName] = useState(basicInfo?.assetName)
  const [assetDescription, setAssetDescription] = useState(basicInfo?.assetDescription)

  //asset category search input
  const [assetSearch, setAssetSearch] = useState("");
  const [statusSearch, setStatusSearch] = useState("");
  const [conditionSearch, setConditionSearch] = useState("");

  const handleAssetCategorySelect = (eventKey) => {
    const category = categoryOfWork?.find((item)=> item.assetCategoryName === eventKey)
    setCategoryId(category.id);
    setSelectedAssetCategory(eventKey);
    setSelectValue({ ...selectValue, assetCategory: eventKey });
    setAssetSearch("");
  };

  const handleAssetStatusSelect = (eventKey) => {
    const statuses = status?.find((item)=> item.assetStatusName === eventKey)
    setSelectedAssetStatus(eventKey);
    setStatusId(statuses.id)
    setSelectValue({ ...selectValue, assetStatus: eventKey });
    setStatusSearch("");
  };

  const handleConditionSelect = (eventKey) => {
    const condition = conditions?.find((item)=> item.assetConditionName === eventKey)
    setConditionId(condition.id)
    setSelectedAssetCondition(eventKey);
    setSelectValue({ ...selectValue, assetCondition: eventKey });
    setConditionSearch("");
  };

  // get asset category data
  const { data: categoryOfWork, isLoading: isLoadingCow } = usefetchData(
    ["asset-category"],
    `/Assets/Categories`,
    {},
    "Error fetching category of work"
  );

  // get asset conditions data data
  const { data: conditions, isLoading: isLoadingCondition } = usefetchData(
    ["asset-condition"],
    `/Assets/GetAllAssetConditions`,
    {},
    "Error fetching category of work"
  );

  // get asset status data data
  const { data: status, isLoading: isLoadingStatus } = usefetchData(
    ["asset-status"],
    `/Assets/GetAllAssetStatuses`,
    {},
    "Error fetching category of work"
  );

  //Filter asset data
  const filteredAssetData =
    categoryOfWork &&
    categoryOfWork?.filter((item) => {
      return item?.assetCategoryName
        .toLowerCase()
        .includes(assetSearch.toLowerCase());
    });

  //Filter asset Status
  const filteredAssetStatus =
    status &&
    status?.filter((item) => {
      return item?.assetStatusName
        .toLowerCase()
        .includes(statusSearch.toLowerCase());
    });

  //Filter asset Condition
  const filteredAssetCondition =
    conditions &&
    conditions.filter((item) => {
      return item?.assetConditionName
        .toLowerCase()
        .includes(conditionSearch.toLowerCase());
    });

    // edit asset basic info mutation
  const {mutate, isLoading: isEditing} = useMutateData({
    url: `/Assets/EditAssetBasicInfo`,
    method: "POST",
    onSuccessfullMutation: (data) => {
      console.log("Recall mutation data", data);
      if (data?.data?.succeeded === true) {
        toast.success(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        onHide(); // Hide edit modal
      } else {
        toast.error(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
    successMessage: `Successfully updated basic info`,
    errorMessage: "Basic info editing failed",
    queryKeysToInvalidate: [["single-asset", parseInt(basicInfo?.assetId) ]],
  })

  const editAsset = () => {
    const requestBody = {
      assetId: basicInfo?.assetId,
      assetName:assetName || basicInfo?.assetName,
      assetDescription: assetDescription || basicInfo?.assetDescription,
      assetCategoryId: categoryId,
      assetStatusId: statusId,
      assetConditionId: conditionId
    }
    mutate(requestBody)
  }

  return (
    <Modal
      // {...props}
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal add-asset-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-md-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Edit Basic Info</span>
            <div className="fs-14 pt-2 pt-md-0" style={{ color: "#72777A" }}>
              Edit asset basic information
            </div>
          </div>
          <div className="mt-4 pt-2 row row-gap-4 modal-forms-content">
            <div className="col-md-12">
              <label className="fw-medium fs-14 pb-2">Asset Name</label>
              <input
                className="modal-input-box"
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "0 15px",
                  fontSize: "14px",
                }}
                placeholder="Enter Asset Category"
                defaultValue={basicInfo?.assetName}
                onChange={(e)=> setAssetName(e.target.value)}
              />
            </div>
            <div className="col-md-12">
              <label className="fw-medium fs-14 pb-2">Description</label>
              <textarea
                className="modal-input-box"
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "140px",
                  borderRadius: "5px",
                  padding: "15px 15px",
                  fontSize: "14px",
                }}
                placeholder="Enter asset category description"
                defaultValue={basicInfo?.assetDescription}
                onChange={(e)=> setAssetDescription(e.target.value)}
              />
            </div>
            <div className="col-md-12">
              <label className="fw-medium fs-14 pb-2">Asset Category</label>
              <Dropdown
                className="select__form"
                onSelect={handleAssetCategorySelect}
              >
                <Dropdown.Toggle
                  className={`select-title ${
                    selectedAssetCategory !== "Select category from list"
                      ? "selected"
                      : ""
                  }`}
                >
                  {selectedAssetCategory}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      onChange={(e) => setAssetSearch(e.target.value)}
                      type="text"
                      placeholder="Search"
                      defaultValue={assetSearch}
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filteredAssetData?.map((item, index) => (
                      <Dropdown.Item
                        key={index}
                        eventKey={item.assetCategoryName}
                      >
                        {item.assetCategoryName}
                      </Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-6">
              <label className="fw-medium fs-14 pb-2">Asset Status</label>
              <Dropdown
                className="select__form"
                onSelect={handleAssetStatusSelect}
              >
                <Dropdown.Toggle
                  className={`select-title ${
                    selectedAssetStatus !== "Select status from list"
                      ? "selected"
                      : ""
                  }`}
                >
                  {selectedAssetStatus}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      onChange={(e) => setStatusSearch(e.target.value)}
                      type="text"
                      placeholder="Search"
                      value={assetSearch}
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filteredAssetStatus?.map((item, index) => (
                      <Dropdown.Item
                        key={index}
                        eventKey={item.assetStatusName}
                      >
                        {item.assetStatusName}
                      </Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-6">
              <label className="fw-medium fs-14 pb-2">Asset Condition</label>
              <Dropdown
                className="select__form"
                onSelect={handleConditionSelect}
              >
                <Dropdown.Toggle
                  className={`select-title ${
                    selectedCondition !== "Select condition from list"
                      ? "selected"
                      : ""
                  }`}
                >
                  {selectedCondition}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      value={conditionSearch}
                      onChange={(e) => setConditionSearch(e.target.value)}
                      type="text"
                      placeholder="Search"
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filteredAssetCondition?.map((item, index) => (
                      <Dropdown.Item
                        key={index}
                        eventKey={item.assetConditionName}
                      >
                        {item.assetConditionName}
                      </Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={onHide}>
              Cancel
            </button>
            <button className="delate-btn" onClick={()=>editAsset()}>
              {isEditing ? "Saving..." : "Save"}
              
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditBasicInfoModal;
