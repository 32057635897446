import React, { useEffect, useState } from "react";
import { Dropdown, Nav, Spinner, Tab } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link, useParams } from "react-router-dom";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import useMutateData from "../../../hooks/useMutateData";
import { toast } from "react-toastify";
import usefetchData from "../../../hooks/useFetchData";

const AddItemCostsModal = ({
  show,
  onHide,
  categoryId,
  currency,
  locationId,
}) => {
  const [selectedPartPart, setselectedPartPart] = useState(
    "Select item from list"
  );
  const [selectedCost, setselectedCost] = useState("Select cost category");
  const [quantity, setQuantity] = useState("");
  const [itemTypeId, setItemTypeId] = useState(0);
  const [description, setDescription] = useState("");
  const [unitCost, setUnitCost] = useState(0);

  //search inputs
  const [partPartSearch, setPartPartSearch] = useState("");
  const [costSearch, setCostSearch] = useState("");

  const { id } = useParams();

  const ticketId = Number(id);
  const LocationId = locationId;

  const { data: partData, isLoading: isLoadingPricelist } = usefetchData(
    ["price-list", categoryId],
    `Tickets/GetPriceListItemsByCategory/${categoryId}`,
    {},
    "Couldn't get",
    !!categoryId
  );

  const { data: costData, isLoading: isLoadingcostData } = usefetchData(
    ["items-type", categoryId],
    `TicketCostings/GetAllTicketCostItemsTypes`,
    {},
    "Couldn't get",
    true
  );

  const { data: mileage, isLoading: isLoadingMileage } = usefetchData(
    ["category-id", categoryId],
    `Tickets/GetMileageByLocationCategory`,
    { LocationId: locationId, CategoryId: categoryId },
    "Couldn't get  mileage data",
    !!locationId && !!categoryId
  );


  //filter part location data  by search input
  const filteredPartPartData =
    partData?.length > 0 &&
    partData?.filter((item) =>
      item?.name.toLowerCase().includes(partPartSearch.toLowerCase())
    );

  const filteredCostData =
    costData?.length > 0 &&
    costData?.filter((item) =>
      item?.ticketCostItemTypeName
        ?.toLowerCase()
        .includes(costSearch.toLowerCase())
    );

  const handlePartPartSelect = (eventKey) => {
    const part = partData.find((item) => item?.name === eventKey);
    setselectedPartPart(eventKey);
    setPartPartSearch("");
    setItemTypeId(part.id);
    setDescription(part.name);
    setUnitCost(part.price);
  };

  const handleCostSelect = (eventKey) => {
    const cost = costData.find(
      (item) => item?.ticketCostItemTypeName === eventKey
    );
    setselectedCost(eventKey);
    setCostSearch("");
    setItemTypeId(cost.id);
  };

  const { mutate, isLoading } = useMutateData({
    url: `Tickets/AddTicketPriceListCostedItem`,
    method: "POST",
    onSuccessfullMutation: (data) => {
      if (data?.data?.succeeded === true) {
        toast.success(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        onHide();
      } else {
        toast.error(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
    successMessage: `Successfully created a work order`,
    errorMessage: "Work order creation failed",
    queryKeysToInvalidate: [["ticket-summary", ticketId]],
  });
  const [selectedItemType, setSelectedItemType] = useState("first");

  const onSubmit = () => {
    let requestBody = {};
    if (selectedPartPart === "first") {
      requestBody = {
        ticketId: ticketId,
        itemTypeId: itemTypeId,
        description: description,
        quantity: parseInt(quantity) || parseInt(mileage),
        unitCost: unitCost,
      };
    } else {
      requestBody = {
        ticketId: ticketId,
        itemTypeId: itemTypeId,
        description: description,
        quantity: parseInt(quantity) || parseInt(mileage),
        unitCost: unitCost,
      };
    }
    mutate(requestBody);
  };

  useEffect(() => {
    setselectedPartPart("Select item from list");
    setselectedCost("Select cost category");
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal add-asset-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-md-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Add Item Costs</span>
            <div className="fs-14 pt-2 pt-md-0" style={{ color: "#72777A" }}>
              Add items that have pre-registered rates in the system
            </div>
          </div>
          <div className="mt-4">
            <label className="fw-medium pb-2 fs-14">Select item</label>
            <Dropdown className="select__form" onSelect={handlePartPartSelect}>
              <Dropdown.Toggle
                className={`select-title ${
                  selectedPartPart !== "Select item from list" ? "selected" : ""
                }`}
                style={{ height: "50px", fontSize: "14px" }}
              >
                {selectedPartPart}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <form className="dropdown-search">
                  <button disabled>
                    <SearchIcon />
                  </button>
                  <input
                    value={partPartSearch}
                    onChange={(e) => setPartPartSearch(e.target.value)}
                    type="text"
                    placeholder="Search"
                  />
                </form>
                <div className="dropdown-item-content">
                  {filteredPartPartData &&
                    filteredPartPartData?.map((item, index) => (
                      <Dropdown.Item
                        className="fs-14"
                        key={index}
                        eventKey={item?.name}
                      >
                        {item?.name}
                      </Dropdown.Item>
                    ))}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="fs-16 fw-semibold" style={{ paddingTop: "30px" }}>
            Item Type
          </div>
          <Tab.Container defaultActiveKey="first">
            <Nav
              variant="pills"
              className="existing-tab-nav row"
              activeKey={selectedItemType}
              onSelect={(selectedKey) => setSelectedItemType(selectedKey)}
            >
              <Nav.Item className="col-md-6">
                <Nav.Link eventKey="first">
                  <div className="fs-15 fw-medium title-text">
                    Parts, Labour & Others
                  </div>
                  <div className="fs-13 fs-italic">
                    Choosing this option allows you to specify the quantity
                    used.
                  </div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="col-md-6">
                <Nav.Link eventKey="second">
                  <div className="fs-15 fw-medium title-text">Mileage</div>
                  <div className="fs-13 fs-italic">
                    Choosing this option will automatically compute the mileage
                    quantity.
                  </div>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div className="fs-14">
                  <b>Unit Rate/ Cost:</b> {currency} {unitCost}
                </div>
                <div className="mt-4">
                  <label className="fw-medium pb-2 fs-14">Cost Category</label>
                  <Dropdown
                    className="select__form"
                    onSelect={handleCostSelect}
                  >
                    <Dropdown.Toggle
                      className={`select-title ${
                        selectedCost !== "Select cost category"
                          ? "selected"
                          : ""
                      }`}
                      style={{ height: "50px", fontSize: "14px" }}
                    >
                      {selectedCost}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <form className="dropdown-search">
                        <button disabled>
                          <SearchIcon />
                        </button>
                        <input
                          value={costSearch}
                          onChange={(e) => setCostSearch(e.target.value)}
                          type="text"
                          placeholder="Search"
                        />
                      </form>
                      <div className="dropdown-item-content">
                        {filteredCostData &&
                          filteredCostData?.map((item, index) => (
                            <Dropdown.Item
                              className="fs-14"
                              key={index}
                              eventKey={item?.ticketCostItemTypeName}
                            >
                              {item?.ticketCostItemTypeName}
                            </Dropdown.Item>
                          ))}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="mt-4">
                  <label className="fw-medium pb-2 fs-14">
                    Quantity to be Costed
                  </label>
                  <input
                    className="modal-input-box"
                    onChange={(e) => setQuantity(e.target.value)}
                    type="text"
                    style={{
                      background: "#F1EFEF",
                      width: "100%",
                      border: "0",
                      height: "50px",
                      borderRadius: "5px",
                      padding: "0 15px",
                      fontSize: "14px",
                    }}
                    placeholder="Enter quantity"
                  />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <div className="fs-14">
                  <b>Unit Rate/ Cost:</b> {currency} {unitCost}
                </div>
                <div className="fs-14 pt-4">
                  <b>Mileage:</b> {mileage} KM
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={onHide}>
              Cancel
            </button>
            <button className="delate-btn" onClick={() => onSubmit()}>
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Add"
              )}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddItemCostsModal;
