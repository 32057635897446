import React from 'react';

const DownloadIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M9.39489 14.0343C9.56175 14.2011 9.78118 14.2857 10.0006 14.2857C10.22 14.2857 10.4395 14.2023 10.6063 14.0343L14.0349 10.6057C14.3697 10.2709 14.3697 9.728 14.0349 9.39314C13.7 9.05828 13.1572 9.05828 12.8223 9.39314L10.8566 11.3589V0.857143C10.8566 0.384 10.4726 0 9.99946 0C9.52632 0 9.14232 0.384 9.14232 0.857143V11.3589L7.1766 9.39314C6.84175 9.05828 6.29889 9.05828 5.96403 9.39314C5.62918 9.728 5.62918 10.2709 5.96403 10.6057L9.39261 14.0343H9.39489Z" fill="currentColor" />
            <path d="M19.1429 12.5713C18.6697 12.5713 18.2857 12.9553 18.2857 13.4284V16.857C18.2857 17.6444 17.6446 18.2856 16.8571 18.2856H3.14286C2.35543 18.2856 1.71429 17.6444 1.71429 16.857V13.4284C1.71429 12.9553 1.33029 12.5713 0.857143 12.5713C0.384 12.5713 0 12.9553 0 13.4284V16.857C0 18.5907 1.40914 19.9999 3.14286 19.9999H16.8571C18.5909 19.9999 20 18.5907 20 16.857V13.4284C20 12.9553 19.616 12.5713 19.1429 12.5713Z" fill="currentColor" />
        </svg>
    );
};

export default DownloadIcon;