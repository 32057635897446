import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import AddAssetMakeModal from "./AddAssetMakeModal";
import AddAssetModelModal from "./AddAssetModelModal";
import EditIdentityInfoTwoModal from "./EditIdentityInfoTwoModal";
import EditIdentityInfoThreeModal from "./EditIdentityInfoThreeModal";
import usefetchData from "../../../hooks/useFetchData";
import useMutateData from "../../../hooks/useMutateData";
import { toast } from "react-toastify";

const EditIdentityInfoModal = ({ onHide, show, basicInfo }) => {
  const [selectValue, setSelectValue] = useState({
    asset: "Select",
    assetCategory: "Select category from list",
    location: "Select asset location from list",
    assignAdditionalTeam: "Select",
  });
  const [selectedMake, setSelectedMake] = useState("Select make");
  const [selectedModel, setSelectedModel] = useState("Select model");

  const [makeShow, setMake] = useState(false);
  const [modelShow, setModel] = useState(false);

  //asset category search input
  const [makeSearch, setMakeSearch] = useState("");
  const [modelSearch, setModelSearch] = useState("");

  const [serialNumber, setSerialNumber] = useState(basicInfo?.serialNumber);
  const [tag, setTag] = useState(basicInfo?.saharaDeskTag);
  const [makeId, setMakeId] = useState()
  const [modelId, setModelId] = useState()
  const [assetModel, setAssetModel] = useState([])

  const handleMakeSelect = (eventKey) => {
    const make = assetMake?.find((item)=> item?.makeName === eventKey)
    setSelectedMake(eventKey);
    setMakeId(make.id)
    setSelectValue({ ...selectValue, assetMake: eventKey });
    setMakeSearch("");
  };
  const handleModelSelect = (eventKey) => {
    setSelectedModel(eventKey);
    const model = assetModel?.find((item)=> item.modelName === eventKey)
    setModelId(model.id)
    setSelectValue({ ...selectValue, assetModel: eventKey });
    setModelSearch("");
  };

  const { data:assetMake, isLoading } = usefetchData(
    ["asset-make"],
    `/Assets/GetAllAssetMakes`,
    {},
    "Failed to get asset makes"
  );


  //Filter asset Make
  const filteredAssetMake = assetMake && assetMake?.filter((item) => {
    return item.makeName?.toLowerCase().includes(makeSearch?.toLowerCase());
  });
  //Filter asset Model
  const filteredAssetModel = assetModel && assetModel?.filter((item) => {
    return item.modelName?.toLowerCase().includes(modelSearch?.toLowerCase());
  });
  console.log("filtered model", filteredAssetModel)

  const handleAddNewMake = () => {
    setMake(true);
  };

  const handleAddNewModel = () => {
    setModel(true);
  };

  console.log("models", assetModel)

      // edit asset basic info mutation
      const {mutate: getModel, isLoading: isLoadingMake} = useMutateData({
        url: `/Assets/GetAssetModelByMake`,
        method: "POST",
        onSuccessfullMutation: (data) => {
          console.log("Get models data", data);
          setAssetModel(data?.data)
          if (data?.data?.succeeded === true) {
            toast.success(data?.data?.messages[0], {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            onHide(); // Hide edit modal
          } else {
            toast.error(data?.data?.messages[0], {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        },
        successMessage: `Successfully updated basic info`,
        errorMessage: "Basic info editing failed",
        queryKeysToInvalidate: [["single-asset", parseInt(basicInfo?.assetId) ]],
      })

      useEffect(()=> {
        if(makeId){
          getModel({assetMakeId:makeId})
        }         
      },[makeId, getModel])

    // edit asset basic info mutation
    const {mutate, isLoading: isEditing} = useMutateData({
      url: `/Assets/EditAssetIdentityInfo`,
      method: "POST",
      onSuccessfullMutation: (data) => {
        console.log("Recall mutation data", data);
        if (data?.data?.succeeded === true) {
          toast.success(data?.data?.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          onHide(); // Hide edit modal
        } else {
          toast.error(data?.data?.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      },
      successMessage: `Successfully updated basic info`,
      errorMessage: "Basic info editing failed",
      queryKeysToInvalidate: [["single-asset", parseInt(basicInfo?.assetId) ]],
    })
  
    const editAsset = () => {
      const requestBody = {
        assetId: basicInfo?.assetId,
        serialNumber,
        saharaDeskTag: tag,
        modelId
      }
      mutate(requestBody)
    }

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="medium-modal add-asset-modal"
      >
        <Modal.Body style={{ padding: "28px 30px" }}>
          <div className="delate-content">
            <div className="d-md-flex align-items-center gap-4">
              <span className="fs-16 fw-bold">Edit Identity Info</span>
              <div className="fs-14 pt-2 pt-md-0" style={{ color: "#72777A" }}>
                Edit asset identification, make and model information
              </div>
            </div>
            <div className="mt-4 pt-2 row row-gap-4 modal-forms-content">
              <div className="col-md-12">
                <label className="fw-medium fs-14 pb-2">Serial Number</label>
                <input
                  className="modal-input-box"
                  type="text"
                  style={{
                    background: "#F1EFEF",
                    width: "100%",
                    border: "0",
                    height: "50px",
                    borderRadius: "5px",
                    padding: "0 15px",
                    fontSize: "14px",
                  }}
                  placeholder="Enter Asset Category"
                  defaultValue={serialNumber}
                  onChange={(e) => setSerialNumber(e.target.value)}
                />
              </div>
              <div className="col-md-12">
                <label className="fw-medium fs-14 pb-2">Tag ID</label>
                <input
                  className="modal-input-box"
                  type="text"
                  style={{
                    background: "#F1EFEF",
                    width: "100%",
                    border: "0",
                    height: "50px",
                    borderRadius: "5px",
                    padding: "0 15px",
                    fontSize: "14px",
                  }}
                  placeholder="Enter Asset Category"
                  defaultValue={tag}
                  onChange={(e) => setTag(e.target.value)}
                />
              </div>

              <div className="col-md-12">
                <label className="d-flex align-items-center justify-content-between mb-2 fw-medium fs-14">
                  Make{" "}
                  <button
                    style={{
                      fontSize: "14px",
                      color: "#D57D2A",
                      borderBottom: "1px solid #D57D2A",
                    }}
                    onClick={handleAddNewMake}
                  >
                    Add New Make
                  </button>
                </label>
                <Dropdown className="select__form" onSelect={handleMakeSelect}>
                  <Dropdown.Toggle
                    style={{
                      height: "50px",
                    }}
                    className={`select-title ${
                      selectedMake !== "Select make" ? "selected" : ""
                    }`}
                  >
                    {selectedMake}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <form className="dropdown-search">
                      <button disabled>
                        <SearchIcon />
                      </button>
                      <input
                        onChange={(e) => setMakeSearch(e.target.value)}
                        type="text"
                        placeholder="Search"
                        value={makeSearch}
                      />
                    </form>
                    <div className="dropdown-item-content">
                      {filteredAssetMake?.map((item, index) => (
                        <Dropdown.Item key={index} eventKey={item.makeName}>
                          {item.makeName}
                        </Dropdown.Item>
                      ))}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="col-md-12">
                <label className="d-flex align-items-center justify-content-between mb-2 fw-medium fs-14">
                  Model{" "}
                  <button
                    style={{
                      fontSize: "14px",
                      color: "#D57D2A",
                      borderBottom: "1px solid #D57D2A",
                    }}
                    onClick={handleAddNewModel}
                  >
                    Add New Model
                  </button>
                </label>
                <Dropdown className="select__form" onSelect={handleModelSelect}>
                  <Dropdown.Toggle
                    style={{
                      height: "50px",
                    }}
                    className={`select-title ${
                      selectedModel !== "Select model" ? "selected" : ""
                    }`}
                  >
                    {selectedModel}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <form className="dropdown-search">
                      <button disabled>
                        <SearchIcon />
                      </button>
                      <input
                        onChange={(e) => setModelSearch(e.target.value)}
                        type="text"
                        placeholder="Search"
                        value={modelSearch}
                      />
                    </form>
                    <div className="dropdown-item-content">
                      {filteredAssetModel && filteredAssetModel?.map((item, index) => (
                        <Dropdown.Item key={index} eventKey={item.modelName}>
                          {item.modelName}
                        </Dropdown.Item>
                      ))}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <div
              className="button-group"
              style={{
                marginTop: "25px",
                display: "flex",
                justifyContent: "end",
                gap: "30px",
              }}
            >
              <button className="cancel-btn" onClick={onHide}>
                Cancel
              </button>
              <button className="delate-btn" onClick={()=> editAsset()}>
                {isLoading ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <EditIdentityInfoThreeModal
        show={makeShow}
        onHide={() => setMake(false)}
      />
      <EditIdentityInfoTwoModal
        show={modelShow}
        onHide={() => setModel(false)}
        assetMake={assetMake}
      />
    </>
  );
};

export default EditIdentityInfoModal;
