import React, { useState } from "react";
import AttachIcon from "../../Assets/Icons/AttachIcon";
import DownIcon from "../../Assets/Icons/DownIcon";
import PartInformation from "./RequestItem/PartInformation";
import Checklists from "./RequestItem/Checklists";
import OtherInformation from "./RequestItem/OtherInformation";
import Labour from "./RequestItem/Labour";
import Diagnosis from "./RequestItem/Diagnosis";
import Accordion from 'react-bootstrap/Accordion';

const OrderRequest = () => {
  const [attachments, setAttachments] = useState([]);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setAttachments(selectedFiles.map((file) => file.name));
  };

  return (
    <div className="order-request">
      <Accordion defaultActiveKey="0">
        {/* CHECKLIST INFORMATION */}
        <Accordion.Item eventKey="0" className="request-box">
          <Accordion.Header className="request-box-title">
            <div className="fs-13 fw-medium">CHECKLIST INFORMATION</div>
          </Accordion.Header>
          <Accordion.Body className="request-box-body">
            <Checklists />
            <div className="text-end">
              <button className="add-button">Add Checklist</button>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        {/* PARTS INFORMATION */}
        <Accordion.Item eventKey="1" className="request-box">
          <Accordion.Header className="request-box-title">
            <div className="fs-13 fw-medium">PARTS INFORMATION</div>
          </Accordion.Header>
          <Accordion.Body className="request-box-body">
            <PartInformation />
            <div className="text-end">
              <button className="add-button">Add Part</button>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        {/* OTHER INFORMATION */}
        <Accordion.Item eventKey="2" className="request-box">
          <Accordion.Header className="request-box-title">
            <div className="fs-13 fw-medium">OTHER INFORMATION</div>
          </Accordion.Header>
          <Accordion.Body className="request-box-body">
            <OtherInformation />
            <div className="text-end">
              <button className="add-button">Add Cost</button>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        {/* LABOUR INFORMATION */}
        <Accordion.Item eventKey="3" className="request-box">
          <Accordion.Header className="request-box-title">
            <div className="fs-13 fw-medium">LABOUR INFORMATION</div>
          </Accordion.Header>
          <Accordion.Body className="request-box-body">
            <Labour />
            <div className="text-end">
              <button className="add-button">Add Labour</button>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        {/* DIAGNOSIS & SOLUTION INFORMATION */}
        <Accordion.Item eventKey="4" className="request-box">
          <Accordion.Header className="request-box-title">
            <div className="fs-13 fw-medium">DIAGNOSIS & SOLUTION INFORMATION</div>
          </Accordion.Header>
          <Accordion.Body className="request-box-body">
            <Diagnosis />
            <div className="text-end">
              <button className="add-button">Add Diagnosis</button>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        {/* FILES INFORMATION */}
        <Accordion.Item eventKey="5" className="request-box">
          <Accordion.Header className="request-box-title">
            <div className="fs-13 fw-medium">FILES INFORMATION</div>
          </Accordion.Header>
          <Accordion.Body className="request-box-body">
            <div className="fs-14 fw-medium">Attachments:</div>
            <ul>
              {attachments.map((attachment, index) => (
                <li key={index} className="fs-14 fw-medium text-black">
                  {attachment}
                </li>
              ))}
            </ul>
            <div className="text-end">
              <label htmlFor="fileInput" className="attach-button">
                <AttachIcon /> Click to attach
                <input
                  type="file"
                  id="fileInput"
                  onChange={handleFileChange}
                  multiple
                  style={{ display: "none" }}
                />
              </label>
            </div>
          </Accordion.Body>
        </Accordion.Item>

      </Accordion>
    </div>
  );
};

export default OrderRequest;
