import React, {useState, useContext} from "react";
import Modal from "react-bootstrap/Modal";
import { FaCircleCheck } from "react-icons/fa6";
import { toast } from "react-toastify";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";
import { ACCEPTREJECT_WORK, GET_WORKORDER_DETAILS_BY_ID } from "../../../utls/constants";
import { Spinner } from "react-bootstrap";
import { get } from "../../../Services/ApiHelper";
import { useParams } from "react-router-dom";

const AcceptModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const {ticketSummary,setTicketSummary} = useContext(WorkOrderContext);
  const { id } = useParams();
  const [reason, setReason] = useState("");
  const onSubmit = (status) => {
    const body = {
      ticketId: ticketSummary?.workOrderSummary?.ticketId,
      isAccepted: status,
      reason: reason,
    };
    setLoading(true);
    fetch(ACCEPTREJECT_WORK, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
          "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: "Bearer " + localStorage.getItem("bearerToken"),
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.succeeded === true) {
          get(GET_WORKORDER_DETAILS_BY_ID(id), localStorage.getItem("bearerToken"))
            .then((result) => {
              setTicketSummary(result);
            })
            .catch((error) => {
              console.log(error);
          });
          props.onHide();
          setLoading(false);
          toast.success(status ? "Ticket Accepted Successfully" : "Ticket Rejected Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });
          /*Reload page here as an exception scenario since the values for update are not available in the context provider i.e.
          On Acceptance assigned Team goes to accepting user default team and the accepting user while for rejection scenario,
           it goes to the first external team/person who assigned the ticket*/
           // Reload the page after successful submission
          /* setTimeout(() => {
            window.location.reload();
          }, 3000);*/
        } else {
          setLoading(false);
          props.onHide();
          toast.error(status ? "Ticket Acceptance failed" : "Ticket Rejection failed", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });
        }
      });
  };
  const handleAccept = () => {
    setIsAccepted(true);
    // Call onSubmit with the updated value of isAccepted
    onSubmit(true);
};

const handleReject = () => {
    setIsAccepted(false);
    // Call onSubmit with the updated value of isAccepted
    onSubmit(false);
};

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="">
            <span className="fs-16 fw-bold ">Accept Work</span>
            <span className="fs-14 ps-3">Accept assigned work</span>
          </div>
          <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
            <div className="col-md-12">
              <label className="fw-medium pb-2 fs-14">
                Add comments for accepting or rejecting the work
              </label>
              <textarea
                name=""
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "150px",
                  borderRadius: "5px",
                  padding: "15px 15px",
                }}
                placeholder="Enter comment"
              ></textarea>
            </div>
          </div>
          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            {!loading ? 
              <>
              <button className="cancel-btn" onClick={handleReject}>
                Reject
              </button>
              <button className="delate-btn" onClick={handleAccept}>
                Accept
              </button>
              </>
            : (loading && isAccepted) ?
            <button className="delate-btn" disabled={loading}>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Accepting...
            </button>
            :
            <button className="cancel-btn" disabled={loading}>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Rejecting...
            </button>
            }
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AcceptModal;
