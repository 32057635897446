import React from 'react';
import '../Components/RequestedOrder/RequestedOrder.scss'  
  import RequestdHeader from '../Components/RequestedOrder/RequestdHeader';
import RequestdNav from '../Components/RequestedOrder/RequestdNav';
import OrderSidebar from '../Components/RequestedOrder/OrderSidebar';
import OrderRequest from '../Components/RequestedOrder/OrderRequest';


const RequestedOrder = () => {
    return (
        <div className="work-orders-area">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <RequestdHeader/>
                </div>
                <div className="col-lg-12">
                    <RequestdNav/>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="order-request-area">
                         <OrderSidebar/>
                         <OrderRequest/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};

export default RequestedOrder;