import React, { useState, useEffect, useContext } from "react";
import { Dropdown, Form, Spinner } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { ADD_CHECKLIST } from "../../../../utls/constants";
import { post, get } from "../../../../Services/ApiHelper";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { WorkOrderContext } from "../../../../Context/Inspections/WorkOrderContext";
import { GET_WORKORDER_DETAILS_BY_ID } from "../../../../utls/constants";
import { useParams } from "react-router-dom";

const SectionOne = ({checkListData, currentPage, toggleChecklist}) => {
  const [questionStates, setQuestionStates] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);
  //const [checkListLocalData, setCheckListData] = useState(checkListData);
  const token = localStorage.getItem("bearerToken");

  useEffect(() => {
    const initialStates = {};
    checkListData.checkListSections.forEach((sect) => {
      sect.questions.forEach((item) => {
        initialStates[item.checkListSectionTaskId] = {
          comment: item?.questionComment || null,
          response: item?.questionAnswer || null,
          selectedOption: item?.questionType === "DropDown" ? item?.questionAnswer || "Select response" : "Select response",
          selectedRadio: item?.questionType === "Radio Button" ? item?.questionAnswer || null : null,
          checked: item?.questionType === "CheckBox" ? item?.questionAnswer?.toLowerCase() === "true" : false,
          switch: item?.questionType === "Switch" ? item?.questionAnswer?.toLowerCase() === "true" : false,
          selectedDate: item?.questionType === "Date" ? (item?.questionAnswer ? new Date(item.questionAnswer) : null) : null,
          questionType: item?.questionType,
        };
      });
    });
    setQuestionStates(initialStates);
  }, [checkListData]);

  //console.log("Initial");
  //console.log(questionStates);
  
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    setQuestionStates((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        [name]: value,
      },
    }));
  };

  const handleCheckboxChange = (index) => {
    setQuestionStates((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        checked: !prevState[index].checked,
        //response: (!prevState[index].checked).toString(),
      },
    }));
  };

  const handleSwitchChange = (index) => {
    setQuestionStates((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        switch: !prevState[index].switch,
        //response: (!prevState[index].switch).toString(),
      },
    }));
  };

  const handleSelectCategory = (index, eventKey) => {
    setQuestionStates((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        selectedOption: eventKey,
      },
    }));
  };

  const handleDateChange = (index, date) => {
    setQuestionStates((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        selectedDate: date,
      },
    }));
  };
  
  const handleSubmit = (event) => {
    setIsLoading(true);
     // Transform questionStates into the required payload format
     // The answer field is derived based on the question type
    //console.log("After Submit");
    //console.log(questionStates);
    const questionAnswerList = Object.keys(questionStates).map((id) => ({
      checkListSectionTaskId: parseInt(id, 10),
      answer: questionStates[id].questionType === "Text" ? (questionStates[id].response || null) :
       questionStates[id].questionType === "DropDown" ? (questionStates[id].selectedOption === "Select response" ? null : questionStates[id].selectedOption) : 
       questionStates[id].questionType === "Radio Button" ? (questionStates[id].selectedRadio || null) : 
       questionStates[id].questionType === "CheckBox" ? (questionStates[id].checked.toString()) : 
       questionStates[id].questionType === "Switch" ? (questionStates[id].switch.toString()) : 
       questionStates[id].questionType === "Date" ? (questionStates[id].selectedDate !== null ? questionStates[id].selectedDate.toISOString() : null) : null,
      comment: questionStates[id].comment || null
    }));
    const payload = {
      checkListTransactionId: checkListData.checkListTransactionId,
      questionAnswerList, //Put in array the sectionquestionstate values here including the not filled ones
    };
    //console.log("Submit");
    //console.log(payload);

    post(ADD_CHECKLIST, payload, token)
      .then((response) => {
        if (response.succeeded === true) {
          //Reload Get FilledCheckList API here
          get(GET_WORKORDER_DETAILS_BY_ID(id), token)
            .then((result) => {
              setTicketSummary(result);
            })
            .catch((error) => {
              console.log(error);
          });
          if(event === "submit"){
            toggleChecklist();
          }
          setIsLoading(false);

          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          setIsLoading(false);

          toast.error(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("An error occured", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const section = checkListData.checkListSections[currentPage - 1];
  const totalSections = checkListData.checkListSections?.length;
  const sectionQuestion = (item) => {
    const index = item.checkListSectionTaskId;  // each question unique 'id'
    const state = questionStates[index] || {};
      if(item?.questionType == "Text"){
        return(
          <li key={index}>
          <div className="fs-15 text-black">{item?.questionName}</div>
          <div className="row">
            <div className="col-md-5">
              <input
                type="text"
                className="check-input"
                name="response"
                placeholder="Enter response"
                value={state.response}
                onChange={(e) => handleInputChange(index, e)}
              />
            </div>
            <div className="col-md-7">
              <input
                type="text"
                className="check-input"
                name="comment"
                placeholder="Comment"
                value={state.comment}
                onChange={(e) => handleInputChange(index, e)}
              />
            </div>
          </div>
        </li>
        );
      } else if(item?.questionType == "Radio Button"){
        return(
          <li key={index}>
          <div className="fs-15 text-black">{item?.questionName}</div>
          <div className="row">
            <div className="col-md-3">
              <div className="radio-buttons">
              {item?.questionOptions?.map((option, idx) => (
                <Form.Check
                  key={idx}
                  inline
                  label={option?.optionName}
                  name={`group-${index}`}
                  type="radio"
                  id={`inline-radio-${index}-${idx}`}
                  checked={state.selectedRadio === option.optionName}
                  onChange={() =>
                    setQuestionStates((prevState) => ({
                      ...prevState,
                      [index]: {
                        ...prevState[index],
                        selectedRadio: option.optionName,
                      },
                    }))
                }
              />
              ))}
              </div>
            </div>
            <div className="col-md-7">
              <input
                type="text"
                className="check-input"
                name="comment"
                placeholder="Comment"
                value={state.comment}
                onChange={(e) => handleInputChange(index, e)}
              />
            </div>
          </div>
        </li>
        );
      } else if(item?.questionType == "CheckBox"){
        return(
          <li key={index}>
          <div className="fs-15 text-black">{item?.questionName}</div>
          <div className="row">
            <div className="col-md-2">
              <div className="section-checkbox">
                <Form.Check
                  inline
                  name={`group-${index}`}
                  type="checkbox"
                  id={`inline-checkbox-${index}`}
                  checked={state.checked}
                  onChange={() => handleCheckboxChange(index)}
                />
              </div>
            </div>
            <div className="col-md-7">
              <input
                type="text"
                className="check-input"
                name="comment"
                placeholder="Comment"
                value={state.comment}
                onChange={(e) => handleInputChange(index, e)}
              />
            </div>
          </div>
        </li>
        );
      } else if (item?.questionType == "Switch"){
        return(
          <li key={index}>
          <div className="fs-15 text-black">
            {item?.questionName}
          </div>
          <div className="row">
            <div className="col-md-2">
              <div className="section-switch ">
                <Form.Check
                  name={`group-${index}`}
                  type="switch"
                  id={`custom-switch-${index}`}
                  className="section-switch"
                  checked={state.switch}
                  onChange={() => handleSwitchChange(index)}
                />
              </div>
            </div>
            <div className="col-md-7">
              <input
                type="text"
                className="check-input"
                name="comment"
                placeholder="Comment"
                value={state.comment}
                onChange={(e) => handleInputChange(index, e)}
              />
            </div>
          </div>
        </li>
        );
      } else if(item?.questionType == "Date"){
        return(
          <li key={index}>
          <div className="fs-15 text-black">{item?.questionName}</div>
          <div className="row">
            <div className="col-md-5">
              <div className="section-datePicker">
                <ReactDatePicker
                  selected={state.selectedDate}
                  onChange={(date) => handleDateChange(index, date)}
                  disabledKeyboardNavigation
                  placeholderText="Select date"
                />
              </div>
            </div>
            <div className="col-md-7">
              <input
                type="text"
                className="check-input"
                name="comment"
                placeholder="Comment"
                value={state.comment}
                onChange={(e) => handleInputChange(index, e)}
              />
            </div>
          </div>
        </li>
        );
      } else if(item?.questionType == "DropDown"){
        return(
          <li key={index}>
          <div className="fs-15 text-black">
            {item?.questionName}
          </div>
          <div className="row">
            <div className="col-md-5">
              <Dropdown
                className="select__form"
                onSelect={(eventKey) => handleSelectCategory(index, eventKey)}
              >
                <Dropdown.Toggle
                  className={`select-title ${
                    state.selectedOption !== "Select response" ? "selected" : ""
                  }`}
                  style={{ height: "40px" }}
                >
                  {state.selectedOption}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="dropdown-item-content">
                    {item?.questionOptions?.map((option, idx) => (
                      <Dropdown.Item key={idx} eventKey={option.optionName}>
                        {option.optionName}
                      </Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-7">
              <input
                type="text"
                className="check-input"
                name="comment"
                placeholder="Comment"
                value={state.comment}
                onChange={(e) => handleInputChange(index, e)}
              />
            </div>
          </div>
        </li>
        );
      }
  };
  return (
      <>
      <div className="d-flex align-items-center justify-content-between">
        <div
          className="fs-13 fw-bold text-uppercase"
          style={{ color: "#D57D2A" }}
        >
          {section?.sectionName}
        </div>
        {currentPage == totalSections ? 
        isLoading ? (
          <Link
          to=""
          className="delate-btn"
          onClick={(e) => e.preventDefault()}
        >
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Submitting...
        </Link>
        ) : (
          <button className="delate-btn" onClick={() => handleSubmit("submit")}>
            Save & Submit
          </button>
        ) : 
          isLoading ? (
            <Link
            to=""
            className="delate-btn"
            onClick={(e) => e.preventDefault()}
          >
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Submitting...
          </Link>
          ) : (
          <button className="delate-btn" onClick={() => handleSubmit("draft")}>Save Draft</button>
          )}
      </div>
      <ul className="section-one">
        {section?.questions?.map((item) => (
          <React.Fragment key={item.checkListSectionTaskId}>
            {sectionQuestion(item)}
          </React.Fragment>
        ))}
      </ul>
    </>
  );
};

export default SectionOne;
