import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Nav, Tab } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DatePicker from "react-datepicker";
import { get } from "../../../../Services/ApiHelper";
import { GET_CALENDAR_TYPES } from "../../../../utls/constants";
import {
  setStartDate,
  setEndDate,
  setPreviousDate,
  setSelectedDay,
  setSelectedDay2,
  setCalenderType,
  setCalendarTypeId,
  setOccurence,
  setDaySearch,
  setDaySearch2,
  setNextPMOnCompletion,
} from "../../../../redux/pmFormSlice";
import moment from "moment/moment";

const CreateSchedule = () => {
  const dispatch = useDispatch();
  const {
    startDate,
    endDate,
    previousDate,
    selectedday,
    selectedday2,
    calenderType,
    calendarTypeId,
    occurence,
    daySearch,
    daySearch2,
    nextPMOnCompletion,
  } = useSelector((state) => state.pmform);

  const bearerToken = localStorage.getItem("bearerToken");
  const [error, setError] = useState("")

  const handleSelect = (eventKey) => {
    const teamObject = filteredDayData?.find((item) => item.name === eventKey);

    dispatch(setSelectedDay(eventKey));
    dispatch(setCalendarTypeId(teamObject.id));
    dispatch(setDaySearch(""));

    updateScheuduleleBasis();
  };

  const handleSelect2 = (eventKey) => {
    const teamObject = filteredDayData?.find((item) => item.name === eventKey);

    dispatch(setSelectedDay2(eventKey));
    dispatch(setCalendarTypeId(teamObject.id));
    dispatch(setDaySearch2(""));
    updateScheuduleleBasis();
  };

  const filteredDayData = calenderType?.filter((item) => {
    return (
      item.name.toLowerCase().includes(daySearch.toLowerCase()) &&
      item.name.toLowerCase() !== "year" &&
      item.name.toLowerCase() !== "hour"
    );
  });

  useEffect(() => {
    get(GET_CALENDAR_TYPES, bearerToken)
      .then((response) => {
        dispatch(setCalenderType(response.data));
      })
      .catch((error) => console.log(error));
  }, [dispatch, bearerToken]);

  useEffect(() => {
    updateScheuduleleBasis();
  }, [
    occurence,
    calendarTypeId,
    previousDate,
    startDate,
    endDate,
    nextPMOnCompletion,
  ]);

  const updateScheuduleleBasis = () => {
    const selectedInfo = {
      reocurrence: occurence,
      calendarTypeId: calendarTypeId,
      calendarTypeName: selectedday === "Select" ? selectedday2 : selectedday,
      previousDate: previousDate,
      startDate: startDate,
      setEndDate: endDate,
      ticketTypeDescription: "preventiveschedule",
      nextPMOnCompletion: nextPMOnCompletion,
    };
    localStorage.setItem("ScheduleBasis", JSON.stringify(selectedInfo));
  };

  // date selection logic
  const oneYearApart = (start, end) => {
    const diff = moment(end).diff(moment(start), "years", true);
    return diff <= 1;
  };

  const handleStartDateChange = (date) => {
    // Check if the end date exists and is valid
    if (endDate && !oneYearApart(date, endDate)) {
      setError("Start date and end date should not be more than 1 year apart.");
      return;
    }
    dispatch(setStartDate(date));
    updateScheuduleleBasis()
  };

  const handleEndDateChange = (date) => {
    // Check if the start date exists and is valid
    if (startDate && !oneYearApart(startDate, date)) {
      setError("Start date and end date should not be more than 1 year apart.");
      return;
    }
    dispatch(setEndDate(date));
    updateScheuduleleBasis()
  };



  const clear = (basis) => {
    dispatch(setNextPMOnCompletion(basis));
    updateScheuduleleBasis();

    dispatch(setCalendarTypeId(0));
    dispatch(setOccurence(1));
    dispatch(setPreviousDate(null));
    dispatch(setStartDate(null));
    dispatch(setEndDate(null));
    dispatch(setSelectedDay("Select"));
    dispatch(setSelectedDay2("Select"));
  };

  return (
    <div className="schedule-const">
      <Tab.Container defaultActiveKey="first">
        <div className="fs-16 fw-semibold text-black pt-5">Date Basis</div>
        <Nav variant="pills" className="scheduling-nav pt-2">
          <Nav.Item>
            <Nav.Link
              eventKey="first"
              onClick={() => {
                clear(true);
              }}
            >
              Based on previous completion date
            </Nav.Link>
            <div className="fs-13 text-black fst-italic">
              This option allows you to create only one schedule at a time
            </div>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="second"
              onClick={() => {
                clear(false);
              }}
            >
              Based on fixed dates
            </Nav.Link>
            <div className="fs-13 text-black fst-italic">
            This options allows you to create multiple schedules at a time
            </div>
          </Nav.Item>
        </Nav>
        <hr />
        <div className="fs-16 fw-bold text-black">Recurrence</div>
        <Tab.Content className="scheduling-fff mt-4">
          <Tab.Pane eventKey="first">
            <div className="row row-gap">
              <div className="col-lg-6">
                <label htmlFor="" className="fs-15 pb-3">
                  Recurs every
                </label>
                <div className="scheduling-input">
                  <input
                    type="number"
                    min={0}
                    value={occurence}
                    onChange={(e) => {
                      dispatch(setOccurence(e.target.value));
                      updateScheuduleleBasis();
                    }}
                    className="input-black"
                  />
                  <Dropdown className="select__form" onSelect={handleSelect}>
                    <Dropdown.Toggle
                      className={`select-title ${
                        selectedday !== "Select" ? "selected" : ""
                      }`}
                    >
                      {selectedday}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="dropdown-item-content">
                        {filteredDayData.map((item, index) => (
                          <Dropdown.Item key={index} eventKey={item.name}>
                            {item.name}
                          </Dropdown.Item>
                        ))}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="col-lg-5 offset-lg-1 row">
                <div className="col-8 px-2">
                  <label htmlFor="" className="fs-15 pb-3 d-block">
                    Previous completion date
                  </label>
                  <DatePicker
                    className="shedule-calendar"
                    selected={previousDate}
                    onChange={(date) => {
                      dispatch(setPreviousDate(date));
                      updateScheuduleleBasis();
                    }}
                  />
                </div>
              </div>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="second">
            {" "}
            <div className="row row-gap">
              <div className="col-lg-6">
                <label                 htmlFor="" className="fs-15 pb-3">
                  Recurs every
                </label>
                <div className="scheduling-input">
                  <input
                    type="number"
                    min={0}
                    value={occurence}
                    onChange={(e) => {
                      dispatch(setOccurence(e.target.value));
                      updateScheuduleleBasis();
                    }}
                    className="input-black"
                  />
                  <Dropdown className="select__form" onSelect={handleSelect2}>
                    <Dropdown.Toggle
                      className={`select-title ${
                        selectedday2 !== "Select" ? "selected" : ""
                      }`}
                    >
                      {selectedday2}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="dropdown-item-content">
                        {filteredDayData.map((item, index) => (
                          <Dropdown.Item key={index} eventKey={item.name}>
                            {item.name}
                          </Dropdown.Item>
                        ))}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="col-lg-5 offset-lg-1 row">
                <div className="col-8 px-2">
                  <label htmlFor="" className="fs-15 pb-3 d-block">
                    Start date
                  </label>
                  <DatePicker
                    className="shedule-calendar"
                    selected={startDate}
                    onChange={handleStartDateChange}
                  />
                  {error && (
                    <p className="text-danger small">{error}</p>
                  )}
                </div>
                <div className="col-8 px-2 mt-4">
                  <label htmlFor="" className="fs-15 pb-3 d-block">
                    End date
                  </label>
                  <DatePicker
                    className="shedule-calendar"
                    selected={endDate}
                    onChange={handleEndDateChange}
                  />
                  {error && (
                    <p className="text-danger small">{error}</p>
                  )}
                </div>
              </div>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default CreateSchedule;
