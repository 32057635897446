import React, { useState } from "react";
import DownIcon from "../../../Assets/Icons/DownIcon";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import { HiDotsVertical } from "react-icons/hi";
import ViewIcon2 from "../../../Assets/Icons/ViewIcon2";
import EditIcon2 from "../../../Assets/Icons/EditIcon2";
import DelateIcon2 from "../../../Assets/Icons/DelateIcon2";
import ArrowLeft from "../../../Assets/Icons/ArrowLeft";
import ArrowRight from "../../../Assets/Icons/ArrowRight";
import DropdownIcon from "../../../Assets/Icons/DropdownIcon";
import getUniqueProperty from "../../../utls/getUniqueProprty";
import filterViaProperty from "../../../utls/filterViaProperty";
import searchAllProperty from "../../../utls/searchAllProperty";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import DeleteModal from "../../WorkOrders/Modal/DeleteModal";
import EyesIcon from "../../../Assets/Icons/EyesIcon";
import AddAssetPartsModal from "../../WorkOrders/Modal/AddAssetPartsModal";
import usefetchData from "../../../hooks/useFetchData";

const ProfileParts = () => {
  const [approvalStatus, setApprovalStatus] = useState(""); //Asset Select
  const [selectedcategoryDesc, setSelectedcategoryDesc] = useState(""); //category Desc Select
  const [selectedmakeDesc, setSelectedmakeDesc] = useState(""); //make Desc Select
  const [type, setType] = useState(""); //Type Select
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1); //for pagination
  const [RequestsPerPage, setRequestsPerPage] = useState(10); //for pagination
  const [search, setSearch] = useState(""); //for search
  const [RequestsShow, setRequestsShow] = useState(false); //for modal
  const [assetPartsShow, setAssetPartsShow] = useState(false); //for modal

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const ExampleCustomInput = ({ value, onClick }) => (
    <button className="example-custom-input" onClick={onClick}>
      {value ? value : ""} <DownIcon />
    </button>
  );
    // get id from params
    const {id} = useParams()

  const sortedRequestsData = RequestsData.sort((a, b) => {
    if (sortBy) {
      const valueA = a[sortBy];
      const valueB = b[sortBy];
      return sortOrder === "asc"
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    }
    return 0;
  });

  const handlecategoryDescClick = (categoryDesc) => {
    setSelectedcategoryDesc(categoryDesc);
  };

  //get request categoryDesc & filter by categoryDesc
  const categoryDescs = getUniqueProperty(sortedRequestsData, "categoryDesc");
  const filterBycategoryDesc = filterViaProperty(
    sortedRequestsData,
    selectedcategoryDesc,
    "categoryDesc"
  );

    // get asset data by id
    const { data: parts, isLoading: isLoadingCondition } = usefetchData(
      ["single-part", parseInt(id)],
      `/Assets/GetAllPartByAssetQuery`,
      {
        params: {
          assetId: id
        }
      },
      "Error fetching asset data by id"
    );

  // Update current page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <>
      <div className="order-request washroom-right-content responses-cont">
        <div className="washroom-title">
          <div className="fs-13 fw-medium">ASSETS PARTS</div>
        </div>
        <div className="order-request ProfileParts mt-4 p-0">
          <Accordion defaultActiveKey="0">
            {/* PARTS LIST */}
            <Accordion.Item eventKey="0" className="request-box">
              <Accordion.Header className="request-box-title">
                <div className="fs-13 fw-medium">PARTS LIST</div>
              </Accordion.Header>
              <Accordion.Body className="request-box-body p-0">
                <div className="text-end">
                  <button
                    onClick={() => setAssetPartsShow(true)}
                    style={{
                      fontSize: "14px",
                      color: "#D57D2A",
                      borderBottom: "1px solid #D57D2A",
                    }}
                  >
                    Add Asset Parts
                  </button>
                </div>
                <div className="selection-grope mt-3">
                  <div className="dropdown select-dropdown">
                    <button className="select-title" data-bs-toggle="dropdown">
                      <span className="fs-13">Category</span>
                      <span className="fs-14 d-flex align-items-center gap-1">
                        {selectedcategoryDesc} <DownIcon />
                      </span>
                    </button>
                    <ul className="dropdown-menu select-menu">
                      <li onClick={() => handlecategoryDescClick("")}>
                        <b>All</b>
                      </li>
                      <hr />
                      <div className="dropdown-item-content">
                        {categoryDescs.map((categoryDesc, i) => (
                          <li
                            key={i}
                            onClick={() =>
                              handlecategoryDescClick(categoryDesc)
                            }
                          >
                            {/* {categoryDesc} */}
                          </li>
                        ))}
                      </div>
                    </ul>
                  </div>

                  <form action="" className="search__box">
                    <button disabled type="submit">
                      <SearchIcon />
                    </button>
                    <input
                      onChange={(e) => setSearch(e.target.value)}
                      type="search"
                      placeholder="Search"
                    />
                  </form>
                </div>
                <div className="inspection-tables-content">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>
                            <span>Part Name</span>
                          </th>
                          <th>
                            <span>Part No.</span>
                          </th>
                          <th>
                            <span>Make & Model</span>
                          </th>
                          <th className="table-th"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {parts?.data?.length > 0 && parts?.data?.map((item, i) => (
                          <tr key={i}>
                            <td style={{ minWidth: "160px" }}>
                              <div>
                                <div className="fw-semibold">
                                  {item.partName}
                                </div>
                                <div className="fw-regular gray-c">
                                  Category: {item.partCategoryName}
                                </div>
                              </div>
                            </td>
                            <td style={{ minWidth: "160px" }}>
                              <div>
                                <div className="fw-semibold">
                                  {item.tagTitle}
                                </div>
                                <div className="fw-regular gray-c">
                                  Tag: {item.partNumber}
                                </div>
                              </div>
                            </td>
                            <td style={{ minWidth: "160px" }}>
                              <div>
                                <div className="fw-semibold">
                                  {item.partMakeName}
                                </div>
                                <div className="fw-regular gray-c">
                                  Model: {item.partMakeName}
                                </div>
                              </div>
                            </td>

                            <td>
                              <div className="d-flex gap-1 upload-icons">
                                {/* <button
                                  className="dropdown-item"
                                  style={{ color: "#6C5B51" }}
                                >
                                  <EyesIcon />
                                </button> */}
                                <button
                                  className="dropdown-item"
                                  onClick={() => setRequestsShow(true)}
                                >
                                  <DelateIcon2 />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="table-pagination pagination-center">
                    <div className="pagination-row">
                      Rows per page:
                      <div className="dropdown select-dropdown">
                        <button
                          className="select-title"
                          data-bs-toggle="dropdown"
                        >
                          <span className="fs-14 d-flex align-items-center gap-2">
                            {RequestsPerPage} <DropdownIcon />
                          </span>
                        </button>
                        <ul
                          className="dropdown-menu select-menu"
                          style={{ minWidth: "25px" }}
                        >
                          <li onClick={() => setRequestsPerPage(5)}>5</li>
                          <li onClick={() => setRequestsPerPage(10)}>10</li>
                          <li onClick={() => setRequestsPerPage(15)}>15</li>
                          <li onClick={() => setRequestsPerPage(20)}>20</li>
                        </ul>
                      </div>
                    </div>
                    <div className="pagination-number">
                      <div className="fs-14">
                        {currentPage} of {10}
                      </div>
                      <div className="arrow-btn">
                        <button
                          onClick={() =>
                            currentPage !== 1 &&
                            handlePageChange(currentPage - 1)
                          }
                        >
                          <ArrowLeft />
                        </button>
                        <button
                          onClick={() =>
                            10 !== currentPage &&
                            handlePageChange(currentPage + 1)
                          }
                        >
                          <ArrowRight />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      <DeleteModal show={RequestsShow} onHide={() => setRequestsShow(false)} id={id} />
      <AddAssetPartsModal
        show={assetPartsShow}
        onHide={() => setAssetPartsShow(false)}
      />
    </>
  );
};

export default ProfileParts;

const RequestsData = [
  {
    categoryTitle: "Spin-Filter 30 Micron for 4/2/4",
    categoryDesc: "Dispenser Hydraulics",
    tagTitle: "140806266",
    makeDesc: "SD24A001",
    modelTitle: "Gilbarco Frontier",
    modelDesc: "Frontier Series I - F160",
  },
  {
    categoryTitle: "C+Meter for 1/2/1",
    categoryDesc: "Dispenser Hydraulics",
    tagTitle: "T19976-G3",
    makeDesc: "SD24A00",
    modelTitle: "Gilbarco Frontier",
    modelDesc: "Frontier Series I - F160",
  },
  {
    categoryTitle: "Proportional Valve without Coil",
    categoryDesc: "Metering Units & Valves",
    tagTitle: "140863832",
    makeDesc: "SD24A0101",
    modelTitle: "Gilbarco Frontier",
    modelDesc: "Frontier Series I - F160",
  },
  {
    categoryTitle: "Coil 24 V for Proportional Valve",
    categoryDesc: "Metering Units & Valves",
    tagTitle: "LF909690005",
    makeDesc: "SD24A0101",
    modelTitle: "Gilbarco Frontier",
    modelDesc: "Frontier Series I - F160",
  },
  {
    categoryTitle: "SIP Pulser",
    categoryDesc: "Metering Units & Valves",
    tagTitle: "LF909690005",
    makeDesc: "SD24A0101",
    modelTitle: "Gilbarco Frontier",
    modelDesc: "Frontier Series I - F160",
  },
  {
    categoryTitle: "Feed line for 4/2/4",
    categoryDesc: "Inlet Connection",
    tagTitle: "LF909690005",
    makeDesc: "SD24A0101",
    modelTitle: "Gilbarco Frontier",
    modelDesc: "Frontier Series I - F160",
  },
  {
    categoryTitle: "Suction Union Assembly for 4/2/4",
    categoryDesc: "Inlet Connection",
    tagTitle: "LF909690005",
    makeDesc: "SD24A0101",
    modelTitle: "Gilbarco Frontier",
    modelDesc: "Frontier Series I - F160",
  },
];
