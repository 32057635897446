import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import LoadingIcon from "../../../Assets/Icons/LoadingIcon";
import { ProgressBar, Spinner } from "react-bootstrap";
import DeleteIcon from "../../../Assets/Icons/DeleteIcon";
import CloseIcon from "../../../Assets/Icons/CloseIcon";
import CompletedIcon from "../../../Assets/Icons/CompletedIcon";
import CloudAddIcon from "../../../Assets/Icons/CloudAddIcon";
import { FileUploader } from "react-drag-drop-files";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";
import { get, post } from "../../../Services/ApiHelper";
import { ADD_TICKET_JOBCARDS, GET_WORKORDER_DETAILS_BY_ID } from "../../../utls/constants";
import { toast } from "react-toastify";

const AddJobCardModal = (props) => {
  const fileTypes = ["PDF", "JPEG", "JPG", "PNG", "3GP", "AVI", "MP4"];
  const [file, setFile] = useState(null); // Only one file state
  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);
  const ticketId = ticketSummary?.workOrderSummary?.ticketId;
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async (newFile) => {
    // Validate file type and size
    const fileType = newFile.name.split(".").pop().toUpperCase();
    if (!fileTypes.includes(fileType)) {
      toast.error("Invalid file type");
      return;
    }

    if (newFile.size > 50 * 1024 * 1024) {
      toast.error("File size should not exceed 50MB");
      return;
    }

    // Convert file to base64 and update state
    const base64File = (await readFileAsBase64(newFile)).split(",")[1];
    const fileWithBase64 = {
      ...newFile,
      status: "Uploading...",
      uploading: 50,
      base64: base64File,
      fileType: fileType,
      originalName: newFile.name,
      url: "",
    };

    setFile(fileWithBase64); // Set only one file
  };

  const token = localStorage.getItem("bearerToken");

  useEffect(() => {
    if (file && file.status === "Uploading...") {
      const timer = setTimeout(() => {
        setFile((prevFile) => ({
          ...prevFile,
          status: "Completed",
          uploading: 100,
        }));
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [file]);

  const onSubmit = () => {
    if (!file) {
      toast.error("Please select a file to upload.");
      return;
    }

    setIsLoading(true);

    const payload = {
      ticketId: ticketId,
      fileList: [
        {
          encodedFile: file.base64,
          fileName: file.originalName,
          url: file.url,
          fileType: file.fileType,
        },
      ],
    };

    post(ADD_TICKET_JOBCARDS, payload, token)
      .then((response) => {
        if (response.succeeded === true) {
          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          setTicketSummary((prevTicketSummary) => {
            const updatedTicketSummary = { ...prevTicketSummary };
            updatedTicketSummary.jobCardSummary.push({
              fileName: file.originalName,
              url: file.url,
              ticketId: ticketId,
              createdDate: Date.now(),
            });

            return updatedTicketSummary;
          });

          get(GET_WORKORDER_DETAILS_BY_ID(ticketId), token)
            .then((data) => {
              setTicketSummary({
                id: data.id,
                workOrderSummary: data.workOrderSummary,
                assetSummary: data.assetSummary,
                costingSummary: data.costingSummary,
                jobCardSummary: data.jobCardSummary,
              });
              props.onHide();
            })
            .catch((err) => {
              console.log(err);
            });

          setFile(null); // Clear the file state after a successful upload
        } else {
          toast.error(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((error) => {
        toast.error("An error occurred", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const handleDelete = () => {
    setFile(null); // Clear the selected file
  };

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered className="medium-modal">
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="">
            <span className="fs-16 fw-bold ">Add File</span>
            <span className="fs-14 ps-3">
              Select a file to add to the work order.
            </span>
          </div>
          <div className="browse-file-upload mt-4">
            <FileUploader
              hoverTitle=" "
              children={
                <div>
                  <CloudAddIcon />
                  <div className="fs-16 fw-medium pt-3 pb-2" style={{ color: "#292D32" }}>
                    Choose a file or drag & drop it here
                  </div>
                  <div className="fs-16 fw-medium" style={{ color: "#A9ACB4" }}>
                    PDF, JPEG, PNG, 3GP, AVI, and MP4 formats, up to 50MB
                  </div>
                  <input
                    fileOrFiles="files"
                    type="file"
                    accept=".pdf,.jpeg,.jpg,.png,.3gp,.avi,.mp4"
                    style={{ display: "none" }}
                    id="fileInput"
                  />
                  <label htmlFor="fileInput" className="browse-file cursor-pointer">
                    Browse File
                  </label>
                </div>
              }
              classes="cursor-pointer"
              maxFileSize={50}
              multiple={false} // Disable multiple file selection
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              onTypeError={(error) => toast.error(error)}
              onSizeError={(error) => toast.error(error)}
            />
          </div>
          <div className="d-grid gap-3 mt-3">
            {file && (
              <div className="uploading-content">
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <div className="fs-16 fw-medium" style={{ color: "#292D32" }}>
                      {file.name}
                    </div>
                    <div className="fs-13 pt-1 pb-2" style={{ color: "#A9ACB4" }}>
                      {/* {parseInt((file.size / 1024) * (file.uploading / 100))} KB of {parseInt(file.size / 1024)} KB • */}
                      {file.status === "Uploading..." && (
                        <span style={{ color: "#292D32", paddingLeft: "10px" }}>
                          <LoadingIcon /> Uploading...
                        </span>
                      )}
                      {file.status === "Completed" && (
                        <span style={{ color: "#292D32", paddingLeft: "10px" }}>
                          <CompletedIcon /> {file.status}
                        </span>
                      )}
                    </div>
                  </div>
                  <button onClick={handleDelete} style={{ color: "#6C5B51" }}>
                    <DeleteIcon />
                  </button>
                </div>
                <ProgressBar variant="warning" now={file.uploading} />
              </div>
            )}
          </div>
          <div className="button-group" style={{ marginTop: "25px", display: "flex", justifyContent: "end", gap: "30px" }}>
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            {isLoading ? (
              <Spinner animation="border" style={{ color: "#D57D2A" }} />
            ) : (
              <button className="delate-btn" onClick={onSubmit} disabled={!file}>
                Add
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddJobCardModal;
