import React, { useEffect, useState } from "react";
import "../Components/Requests/Requests.scss";
// import "../Components.Requests/RequestsCustom.scss"
import RequestsHeader from "../Components/Requests/RequestsHeader";
import RequestsNavbar from "../Components/Requests/RequestsNavbar";
import { Nav, Spinner, Tab } from "react-bootstrap";
import NewRequests from "../Components/Requests/TabNewRequests";
import ApprovedRequests from "../Components/Requests/TabApprovedRequests";
import DeclinedRequests from "../Components/Requests/TabDeclinedRequests";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RequestService } from "../Services/ApiServiceCalls/RequestService";
import { set } from "react-hook-form";
import { get } from "../Services/ApiHelper";
import { GET_REQUESTS } from "../utls/constants";

const Requests = () => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const navigate = useNavigate();
  const requestService = RequestService();
  const [newRequests, setNewRequests] = useState([]);
  const [approvedRequests, setApprovedRequests] = useState([]);
  const [declinedRequests, setDeclinedRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  let [report, setReport] = useState([]);

  const [tabActive, setTabActive] = useState("new-requests");

  const handleTabChange = (tabName) => {
    setTabActive(tabName);
    navigate(`?tab=${tabName}`);
  };

  useEffect(() => {
    newRequestReport();
  }, []);

  const newRequestReport = () => {
    setIsLoading(true);
    get(GET_REQUESTS(1000, 1, "Pending"), localStorage.getItem("bearerToken"))
      .then((response) => {
        setReport(response["data"]);
        setIsLoading(false);
      })
      .catch((error) => console.log("An Error Ocurred"));
  };
  const handleNewReport = () => {
    newRequestReport();
  };
  const handleApprovedReport = () => {
    setIsLoading(true);
    get(GET_REQUESTS(1000, 1, "Approved"), localStorage.getItem("bearerToken"))
      .then((response) => {
        setReport(response["data"]);
        setIsLoading(false);
      })
      .catch((error) => console.log("An Error Ocurred"));
  };

  const handlDeclinedReport = () => {
    setIsLoading(true);
    get(GET_REQUESTS(1000, 1, "Declined"), localStorage.getItem("bearerToken"))
      .then((response) => {
        setReport(response["data"]);
        setIsLoading(false);
      })
      .catch((error) => console.log("An error has occured"));
  };
  return (
    <div className="work-orders-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <RequestsHeader report={report} />
          </div>
          <div className="col-lg-12">
            <RequestsNavbar />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="inspection-cate">
              <Tab.Container
                id="f3washroom-table"
                defaultActiveKey={tab ? tab : "new-requests"}
              >
                <Nav variant="pills" className="f3washroom-table-nav mt-0">
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => {
                        handleNewReport();
                        navigate("?tab=new-requests");
                        handleTabChange("new-requests");
                      }}
                      eventKey="new-requests"
                    >
                      New Requests
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => {
                        handleApprovedReport();
                        navigate("?tab=approved-requests");
                        handleTabChange("approved-requests");
                      }}
                      eventKey="approved-requests"
                    >
                      Approved Requests
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => {
                        handlDeclinedReport();
                        navigate("?tab=declined-requests");
                        handleTabChange("declined-requests");
                      }}
                      eventKey="declined-requests"
                    >
                      Declined Requests
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="new-requests">
                    <div className="f3washroom-min-content request-tab-content">
                      <NewRequests
                        newRequests={newRequests}
                        setIsLoadingNew={setIsLoading}
                        isLoadingNew={isLoading}
                        tabActive={tabActive}
                      />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="approved-requests">
                    <div className="f3washroom-min-content request-tab-content">
                      <ApprovedRequests
                        setIsLoadingNew={setIsLoading}
                        isLoadingNew={isLoading}
                        tabActive={tabActive}
                      />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="declined-requests">
                    <div className="f3washroom-min-content request-tab-content">
                      <DeclinedRequests
                        setIsLoadingNew={setIsLoading}
                        isLoadingNew={isLoading}
                        tabActive={tabActive}
                      />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Requests;