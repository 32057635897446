import React, { useState } from "react";
import DownIcon from "../../Assets/Icons/DownIcon"; 
import AddIcon from "../../Assets/Icons/AddIcon";
import AddScheduleModal from "../PumpCalibration/AddScheduleModal";
import { Link } from "react-router-dom";
import exportToExcel from "../../utls/exportToExcel";
import exportToExcelMain from "../../utls/exportToExecelMain";
const InspectionHeader = ({ inspectionData }) => {
  const [scheduleShow, setScheduleShow] = useState(false);
  const [item, setItem] = useState(null);
  return (
    <>
      <div className="work-header">
        <div className="fs-20">Inspection Category</div>
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-15 d-flex align-items-center gap-2"> 
              Actions <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu dropdown-menu-end select-menu">
            <li>
              <Link to ={{
                pathname:"/inspection-category/create",
                state: {item}
              }}
              >
                <AddIcon /> Add Category
              </Link>
            </li>
            <li onClick={() => exportToExcelMain(inspectionData)}>
              <Link to="#">
                <AddIcon />
                Export to Excel
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <AddScheduleModal
        show={scheduleShow}
        onHide={() => setScheduleShow(false)}
      />
    </>
  );
};

export default InspectionHeader;
