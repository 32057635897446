import React, { useState } from "react";
import "../Components/WorkOrders/WorkOrders.scss";
import OtherPageNav from "../Components/WorkOrders/AddWorkOrder/OtherPageNav";

import { NavLink, Outlet } from "react-router-dom";
import { WorkOrderContext } from "../Context/Inspections/WorkOrderContext";
import { useSelector } from "react-redux";

const AddWorkOrder = () => {
  const [locationCategoryStatus, setLocationCategoryStatus] = useState(false);
  const formState = useSelector((state) => state.form);

  console.log("navigation page", formState);

  const isSecondNavEnabled =
    formState?.selectedAssets?.length > 0 &&
    formState.location !== "" &&
    formState.assetCategory !== "";

    const isThirdNavEnabled =
    isSecondNavEnabled &&
    formState.ticketTitle !== "" &&
    formState.ticketDescription !== "" &&
    formState.ticketPriority !== "" &&
    formState.categoryOfWork !== "" &&
    (formState.responseType === "FullJob" ? formState.estimatedHours !== null : true) &&
    (formState.responseType === "ResponseResolution" ? formState.expectedTimeValue && formState.expectedTime2Value : true) &&
    Object.keys(formState.assignedTeam).length > 0 &&
    Object.keys(formState.assignedUser).length > 0;
  


  const navItems = [
    { path: "/work-orders/add-work-order", label: "Assets", condition: true },
    {
      path: "/work-orders/add-work-order/details",
      label: "Work Order Info",
      condition: isSecondNavEnabled,
    },
    {
      path: "/work-orders/add-work-order/information",
      label: "Other Information",
      condition: isThirdNavEnabled,
    },
    {
      path: "/work-orders/add-work-order/summary",
      label: "Summary",
      condition: isThirdNavEnabled,
    },
  ];

  return (
    <WorkOrderContext.Provider
      value={{
        locationCategoryStatus,
        setLocationCategoryStatus,
      }}
    >
      <div className="work-orders-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="work-header">
                <div className="fs-20"> Work Orders</div>
              </div>
            </div>
            <div className="col-lg-12">
              <OtherPageNav />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="order-details-area ">
                <div className="order-nav-content">
                  <div className="fs-19 fw-bold">ADD WORK ORDER</div>
                  <div className="order-nav-lists">
                    {navItems.map((item, index) =>
                      item.condition ? (
                        <NavLink
                          key={index}
                          to={item.path}
                          state={{ routFrom: 1 }}
                          end
                        >
                          {item.label}
                        </NavLink>
                      ) : (
                        <span key={index} className="disabled-link">
                          {item.label}
                        </span>
                      )
                    )}
                  </div>
                </div>
                <div className="work-order-contents">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WorkOrderContext.Provider>
  );
};

export default AddWorkOrder;
