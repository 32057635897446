const generateTextLogoFromName = (assetName) => {
  if (!assetName) return "";

  // Split the asset name into words
  const words = assetName.split(" ");

  // Initialize an array to store initials
  let initials = [];

  // Iterate through the words and get the first letter of each word that is a letter
  for (let i = 0; i < words.length; i++) {
    const firstLetter = words[i][0];
    if (firstLetter && /^[a-zA-Z]$/.test(firstLetter)) {
      initials.push(firstLetter.toUpperCase());
    }
    if (initials.length === 2) break; // We only need the first two letters
  }

  // Handle cases where the name has fewer than two letters or the first letter(s) are not alphabets
  if (initials.length === 1) {
    // If only one valid letter was found, use the first two characters of the first word
    initials.push(words[0][1] ? words[0][1].toUpperCase() : "");
  } else if (initials.length === 0 && words[0]) {
    // If no valid letters were found, just use the first two characters of the first word
    initials = words[0].slice(0, 2).toUpperCase();
  }

  // Join the initials to form the code
  const code = initials.join("");

  // Return the generated code
  return code;
};

export default generateTextLogoFromName;
