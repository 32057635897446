import React, { useState, useMemo, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useFetchData from "../../hooks/useFetchData";
import Dropdown from "react-bootstrap/Dropdown";
import DownIcon from "../../Assets/Icons/DownIcon";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import { Nav, Tab } from "react-bootstrap";
import "./Reports.scss";
import { Link } from "react-router-dom";
import ArrowLeft from "../../Assets/Icons/ArrowLeft";
import ArrowRight from "../../Assets/Icons/ArrowRight";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { useReactToPrint } from "react-to-print";
import * as XLSX from "xlsx";
import { format } from "date-fns";
import WorkCostsReportGrid from "./WorkCostsReportGrid";
import useMutateData from "../../hooks/useMutateData";
import ContractorPerformanceGrid from "./ContractorPerformanceGrid";
import qs from "qs";

// function to flattten the filter object
const flattenObject = (obj, parent, res = {}) => {
  for (let key in obj) {
    let propName = parent ? parent + "." + key : key;
    if (
      typeof obj[key] === "object" &&
      obj[key] !== null &&
      !Array.isArray(obj[key])
    ) {
      flattenObject(obj[key], propName, res);
    } else {
      res[propName] = obj[key];
    }
  }
  return res;
};

const ContractorPerformance = () => {
  const [endDate, setEndDate] = useState(null);
  const [workStatus, setWorkStatus] = useState("");
  const [categoryOfWorkIds, setCategoryOfWorkIds] = useState([]);
  const [equipmentCategoryIds, setEquipmentCategoryIds] = useState([]);
  const [locationIds, setLocationIds] = useState([]);
  const [equipmentIds, setEquipmentIds] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [requestsPerPage, setRequestsPerPage] = useState(10);
  const [filters, setFilters] = useState(null);
  const [validationMessage, setValidationMessage] = useState("");
  const [activeTab, setActiveTab] = useState("grid");
  const [equipment, setEquipment] = useState([]);

  const ExampleCustomInput = ({ value, onClick }) => (
    <button className="example-custom-input" onClick={onClick}>
      {value ? value : ""} <DownIcon />
    </button>
  );

  const handleRun = () => {
    if (!startDate || !endDate) {
      setValidationMessage("Start Date and End Date are required.");
      return;
    }
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays > 365) {
      setValidationMessage("The date range should not be longer than a year.");
      return;
    }

    setValidationMessage("");
    handleRunClick();
  };

  const handleRunClick = () => {
    const filterObject = {
      filter: {
        StartDate: startDate
          ? new Date(
              startDate.getTime() - startDate.getTimezoneOffset() * 60000
            ).toISOString()
          : null,
        EndDate: endDate
          ? new Date(
              endDate.getTime() - endDate.getTimezoneOffset() * 60000
            ).toISOString()
          : null,
        Status: workStatus,
        CategoryOfWorkIds: categoryOfWorkIds,
        EquipmentCategoryIds: equipmentCategoryIds,
        LocationIds: locationIds,
        EquipmentIds: equipmentIds,
      },
    };

    setFilters(flattenObject(filterObject));
  };

  const { data: workOrder, isLoading } = useFetchData(
    ["contractor-performance", filters],
    `/Report/GetContractorPerformance`,
    {
      params: {
        ...filters,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    },
    "Error fetching work costs",
    !!filters
  );

  const { data: categoryOfWork, isLoading: isLoadingCow } = useFetchData(
    ["work-category"],
    `/CategoryOfWorks/GetAllWorkCategories`,
    {},
    "Error fetching category of work"
  );

  const { data: equipmentCategory, isLoading: isLoadingEquipCat } =
    useFetchData(
      ["equipment-category"],
      `/Assets/Categories`,
      {},
      "Error fetching equipment category"
    );

  const { data: location, isLoading: isLoadingLocation } = useFetchData(
    ["location"],
    `/Locations/GetSimpleLocationList`,
    {},
    "Error fetching location"
  );

  const { mutate: fetchEquipment, isLoading: isLoadingEquipment } =
    useMutateData({
      url: "/Assets/GetAssetsByLocationList",
      method: "POST",
      onSuccessfullMutation: (data) => {
        console.log("mutation data", data);
        setEquipment(data?.data);
      },
      successMessage: "Equipment fetched successfully",
      errorMessage: "Error fetching equipment",
    });

  useEffect(() => {
    if (locationIds.length > 0) {
      fetchEquipment({ locationIds: locationIds });
    }
  }, [locationIds, fetchEquipment]);

  const formatDate = (dateString) => {
    return format(new Date(dateString), "dd/MM/yyyy");
  };

  const formattedFilters = useMemo(() => {
    if (!filters) return null;

    return {
      startDate: formatDate(filters["filter.StartDate"]),
      endDate: formatDate(filters["filter.EndDate"]),
      calendarGrouping: filters["filter.CalendarGrouping"],
      categoryOfWorkIds: filters["filter.CategoryOfWorkIds"],
      equipmentCategoryIds: filters["filter.EquipmentCategoryIds"],
      locationIds: filters["filter.LocationIds"],
      equipmentIds: filters["filter.EquipmentIds"],
    };
  }, [filters]);

  const handleExcelExport = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([]);

    XLSX.utils.sheet_add_aoa(
      ws,
      [
        ["Work Cost by Category Report"],
        [
          `Start Date: ${formattedFilters?.startDate ?? ""}`,
          `Category of Work: ${formattedFilters?.categoryOfWork ?? "All"}`,
        ],
        [
          `End Date: ${formattedFilters?.endDate ?? ""}`,
          `Calendar Grouping: ${
            formattedFilters?.calendarGrouping ?? "Weekly"
          }`,
        ],
        [],
      ],
      { origin: "A1" }
    );

    let jsonData = [];

    if (activeTab === "grid") {
      jsonData = workOrder?.data[0]?.contractorPerformanceList || [];
    } else if (activeTab === "graph") {
      // Placeholder: Modify this to export the graph data if applicable
      jsonData = [["Graph Data Placeholder"]];
    }

    // Extract specific fields and format createdDate
    const formattedData = jsonData.map((item) => ({
      "Ref No.": item.ticketRef,
      Location: item.locationName,
      "Work Category": item.categoryOfWorkName,
      "Date & Time (Received)": new Date(item.dateReceived).toLocaleString(),
      "Date & Time (Arrived/Work Start)": new Date(
        item.dateArrivedStarted
      ).toLocaleString(),
      "Date & Time (Completed)": new Date(item.dateCompleted).toLocaleString(),
      "Response Time (HRS)": item.responseTimeHrs,
      "Resolution Time (HRS)": item.resolutionTimeHrs,
      "Total Time (HRS)": item.totalTimeHrs,
      "SLA Response (HRS)": item.slaResponseTime,
      "SLA Resolution (HRS)": item.slaResolutionTime,
      "SLA Total Time (HRS)": item.slaTotalTime,
      Status: item.status,
      "Fault Reported": item.ticketDescription,
      Contractor: item.contractorName,
      "Work Done By": item.currentAssignedUser,
    }));

    // Add headers manually
    const headers = [
      "Ref No.",
      "Location",
      "Work Category",
      "Date & Time (Received)",
      "Date & Time (Arrived/Work Start)",
      "Date & Time (Completed)",
      "Response Time (HRS)",
      "Resolution Time (HRS)",
      "Total Time (HRS)",
      "SLA Response (HRS)",
      "SLA Resolution (HRS)",
      "SLA Total Time (HRS)",
      "Status",
      "Fault Reported",
      "Contractor",
      "Work Done By",
    ];
    XLSX.utils.sheet_add_aoa(ws, [headers], { origin: "A5" });

    // Add data below the headers
    XLSX.utils.sheet_add_json(ws, formattedData, {
      origin: "A6",
      skipHeader: true, // Skip default header
    });

    // Calculate maximum column widths
    const calculateMaxWidths = (data) => {
      const maxLengths = [];

      data.forEach((row) => {
        Object.values(row).forEach((cell, colIndex) => {
          const cellValue = String(cell || "");
          const length = cellValue.length;

          if (!maxLengths[colIndex] || length > maxLengths[colIndex]) {
            maxLengths[colIndex] = length;
          }
        });
      });

      return maxLengths.map((length) => ({ wch: length + 2 })); // Add padding
    };

    // Get maximum widths for both the header and the content
    const headerWidths = calculateMaxWidths([headers]);
    const contentWidths = calculateMaxWidths(formattedData.map(Object.values));

    const maxColumnWidths = headerWidths.map((width, index) => ({
      wch: Math.max(width.wch, contentWidths[index]?.wch || 0),
    }));

    // Set column widths and enable text wrapping
    ws["!cols"] = maxColumnWidths;
    ws["!rows"] = [{ hpx: 20 }]; // Example: set height for header row

    // Add color to the header
    const headerRange = XLSX.utils.decode_range("A5:H5"); // Adjust range as needed

    for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
      const cellAddress = XLSX.utils.encode_cell({ r: 4, c: C }); // Row 5 is index 4
      if (!ws[cellAddress]) continue;

      ws[cellAddress].s = {
        fill: {
          fgColor: { rgb: "d57d2a" },
        },
        font: {
          bold: true,
          color: { rgb: "FFFFFF" },
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center",
        },
      };
    }

    XLSX.utils.book_append_sheet(wb, ws, "Contractor Performance Report");
    XLSX.writeFile(wb, "Contractor_Performance.xlsx");
  };

  const handlePdf = useReactToPrint({
    content: () => document.getElementById("user-pm-performance"),
    pageStyle: `
        @page {
          size: A4 landscape;
          margin: 10mm;
        }
        @media print {
          body {
            -webkit-print-color-adjust: exact;
          }
          #pm-performance-graph {
            width: 100%;
          }
          .e-chart {
            width: 100% !important;
          }
        }
      `,
  });

  const handleStartDateChange = (date) => {
    //
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    //
    setEndDate(date);
  };

  const handleCategoryChange = (selectedCategories) => {
    setCategoryOfWorkIds(selectedCategories);
  };

  const handleEquipmentCategoryChange = (selectedCategories) => {
    setEquipmentCategoryIds(selectedCategories);
  };

  const handleLocationChange = (selectedCategories) => {
    setLocationIds(selectedCategories);
  };

  const handleWorkStatusChange = (event) => {
    setWorkStatus(event.target.value);
  };

  return (
    <div className="work-orders-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="work-header">
              <div className="fs-20">Reports - Contractor Performance</div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="other-nav">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="">Contractor Performance Reports</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="inspection-cate">
              <div className="request-box-body p-0">
                <Tab.Container
                  defaultActiveKey="grid"
                  onSelect={(k) => setActiveTab(k)}
                >
                  <Nav className="workCostings-nav-report">
                    <div
                      className="selection-grope"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyItems: "center",
                        justifyContent: "start",
                      }}
                    >
                      <div>
                        <p>Report Filters: </p>
                      </div>
                      <div className="select-dropdown">
                        <div className="select-title">
                          <div className="fs-13">Date From:</div>
                          <div className="format-date">
                            <DatePicker
                              maxDate={endDate}
                              selected={startDate}
                              onChange={(date) => handleStartDateChange(date)}
                              customInput={<ExampleCustomInput />}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="select-dropdown">
                        <div className="select-title">
                          <div className="fs-13">Date To:</div>
                          <div className="format-date">
                            <DatePicker
                              minDate={startDate}
                              selected={endDate}
                              onChange={(date) => handleEndDateChange(date)}
                              customInput={<ExampleCustomInput />}
                            />
                          </div>
                        </div>
                      </div>
                      {/* category of work dropdown */}
                      <Dropdown className="select__form">
                        <Dropdown.Toggle className="select-title bg-white">
                          {categoryOfWorkIds?.length < 1 && (
                            <span className="fs-13">
                              Category of Work &nbsp;
                            </span>
                          )}

                          <span className="fs-14 d-flex align-items-center gap-1">
                            {categoryOfWorkIds?.length === 0
                              ? "All"
                              : categoryOfWorkIds
                                  .map(
                                    (id) =>
                                      categoryOfWork.find(
                                        (item) => item.id === id
                                      )?.categoryOfWorkName
                                  )
                                  .join(", ")}
                          </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <form className="dropdown-search bg-white">
                            <button disabled>
                              <SearchIcon />
                            </button>
                            <input
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              type="text"
                              placeholder="Search"
                            />
                          </form>
                          <div className="dropdown-item-content">
                            {categoryOfWork?.map((item) => (
                              <Dropdown.Item
                                key={item.id}
                                className="checklists-list"
                                onClick={(e) => {
                                  e.stopPropagation()
                                  handleCategoryChange(
                                    categoryOfWorkIds.includes(item.id)
                                      ? categoryOfWorkIds.filter(
                                          (cat) => cat !== item.id
                                        )
                                      : [...categoryOfWorkIds, item.id]
                                  )
                                }
                                  
                                }
                              >
                                <input
                                  type="checkbox"
                                  checked={categoryOfWorkIds.includes(item.id)}
                                  readOnly
                                />
                                <span className="checkbox-label">
                                  {item?.categoryOfWorkName}
                                </span>
                              </Dropdown.Item>
                            ))}
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>

                      {/* asset categories(equipment) dropdown */}
                      <Dropdown className="select__form">
                        <Dropdown.Toggle className="select-title bg-white">
                          {categoryOfWorkIds?.length < 1 && (
                            <span className="fs-13">
                              Equipment Category &nbsp;
                            </span>
                          )}

                          <span className="fs-14 d-flex align-items-center gap-1">
                            {equipmentCategoryIds?.length === 0
                              ? "All"
                              : equipmentCategoryIds
                                  .map(
                                    (id) =>
                                      equipmentCategory.find(
                                        (item) => item.id === id
                                      )?.assetCategoryName
                                  )
                                  .join(", ")}
                          </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <form className="dropdown-search bg-white">
                            <button disabled>
                              <SearchIcon />
                            </button>
                            <input
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              type="text"
                              placeholder="Search"
                            />
                          </form>
                          <div className="dropdown-item-content">
                            {equipmentCategory?.map((item) => (
                              <Dropdown.Item
                                key={item.id}
                                className="checklists-list"
                                onClick={() =>
                                  handleEquipmentCategoryChange(
                                    equipmentCategoryIds.includes(item.id)
                                      ? equipmentCategoryIds.filter(
                                          (cat) => cat !== item.id
                                        )
                                      : [...equipmentCategoryIds, item.id]
                                  )
                                }
                              >
                                <input
                                  type="checkbox"
                                  checked={equipmentCategoryIds.includes(
                                    item.id
                                  )}
                                  readOnly
                                />
                                <span className="checkbox-label">
                                  {item?.assetCategoryName}
                                </span>
                              </Dropdown.Item>
                            ))}
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>

                      {/* equiment based on location */}
                      <Dropdown className="select__form">
                        <Dropdown.Toggle className="select-title bg-white">
                          {locationIds?.length < 1 && (
                            <span className="fs-13">Location &nbsp;</span>
                          )}

                          <span className="fs-14 d-flex align-items-center gap-1">
                            {locationIds?.length === 0
                              ? "All"
                              : locationIds
                                  .map(
                                    (id) =>
                                      location.find((item) => item.id === id)
                                        ?.locationName
                                  )
                                  .join(", ")}
                          </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <form className="dropdown-search bg-white">
                            <button disabled>
                              <SearchIcon />
                            </button>
                            <input
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              type="text"
                              placeholder="Search"
                            />
                          </form>
                          <div className="dropdown-item-content">
                            {location?.map((item) => (
                              <Dropdown.Item
                                key={item.id}
                                className="checklists-list"
                                onClick={(e) => {
                                  e.stopPropagation()
                                  handleLocationChange(
                                    locationIds.includes(item.id)
                                      ? locationIds.filter(
                                          (cat) => cat !== item.id
                                        )
                                      : [...locationIds, item.id]
                                  )
                                }
                                  
                                }
                              >
                                <input
                                  type="checkbox"
                                  checked={locationIds.includes(item.id)}
                                  readOnly
                                />
                                <span className="checkbox-label">
                                  {item?.locationName}
                                </span>
                              </Dropdown.Item>
                            ))}
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>

                      {/* asset category based on location  */}
                      <Dropdown className="select__form">
                        <Dropdown.Toggle className="select-title bg-white">
                          {equipmentIds?.length < 1 && (
                            <span className="fs-13">Equipment &nbsp;</span>
                          )}

                          <span className="fs-14 d-flex align-items-center gap-1">
                            {equipmentIds?.length === 0
                              ? "All"
                              : equipmentIds
                                  .map(
                                    (id) =>
                                      equipment.find((item) => item.id === id)
                                        ?.assetName
                                  )
                                  .join(", ")}
                          </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <form className="dropdown-search bg-white">
                            <button disabled>
                              <SearchIcon />
                            </button>
                            <input
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              type="text"
                              placeholder="Search"
                            />
                          </form>
                          <div className="dropdown-item-content">
                            {equipment?.map((item) => (
                              <Dropdown.Item
                                key={item.id}
                                className="checklists-list"
                                onClick={() =>
                                  handleLocationChange(
                                    equipmentIds.includes(item.id)
                                      ? equipmentIds.filter(
                                          (cat) => cat !== item.id
                                        )
                                      : [...equipmentIds, item.id]
                                  )
                                }
                              >
                                <input
                                  type="checkbox"
                                  checked={equipmentIds.includes(item.id)}
                                  readOnly
                                />
                                <span className="checkbox-label">
                                  {item?.assetName}
                                </span>
                              </Dropdown.Item>
                            ))}
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>

                      {/* work status filter */}
                      <Dropdown className="select__form">
                        <Dropdown.Toggle className="select-title bg-white">
                          <span className="fs-13">Work Status</span>
                          <span className="fs-14 d-flex align-items-center gap-1">
                            {workStatus.charAt(0).toUpperCase() +
                              workStatus.slice(1)}
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              handleWorkStatusChange({
                                target: { value: "on-time" },
                              })
                            }
                          >
                            On-Time
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              handleWorkStatusChange({
                                target: { value: "late" },
                              })
                            }
                          >
                            Late
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>


                      <button
                        className="add-button my-2"
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                          background: "rgba(213, 125, 42, 0.87)",
                          padding: "8px 24px 8px 24px",
                          color: "white",
                          borderRadius: "8px",
                        }}
                        onClick={handleRun}
                      >
                        Run
                      </button>

                      {validationMessage && (
                        <div style={{ color: "red", marginTop: "10px" }}>
                          {validationMessage}
                        </div>
                      )}
                    </div>
                    <div
                      className="flex icon-thin"
                      style={{
                        color: "gray",
                        fontWeight: 100,
                        fontSize: "15px",
                        display: "flex",
                        gap: "20px",
                        alignItems: "center",
                        paddingTop: "15px",
                      }}
                    >
                      <FirstPageIcon
                        className="icon-thin"
                        style={{
                          color: workOrder?.hasPreviousPage
                            ? "gray"
                            : "lightgray",
                          cursor: workOrder?.hasPreviousPage
                            ? "pointer"
                            : "not-allowed",
                        }}
                        onClick={() => {
                          if (workOrder?.hasPreviousPage) {
                            setCurrentPage(1);
                            setFilters((prev) => ({ ...prev, pageNumber: 1 }));
                          }
                        }}
                      />
                      <button
                        style={{
                          color: workOrder?.hasPreviousPage
                            ? "gray"
                            : "lightgray",
                          cursor: workOrder?.hasPreviousPage
                            ? "pointer"
                            : "not-allowed",
                        }}
                        onClick={() => {
                          if (workOrder?.hasPreviousPage) {
                            setCurrentPage((prev) => {
                              const newPage = prev - 1;
                              setFilters((prevFilters) => ({
                                ...prevFilters,
                                pageNumber: newPage,
                              }));
                              return newPage;
                            });
                          }
                        }}
                        disabled={!workOrder?.hasPreviousPage}
                      >
                        <ArrowLeft className="icon-thin" />
                      </button>
                      <button
                        style={{
                          color: workOrder?.hasNextPage ? "gray" : "lightgray",
                          cursor: workOrder?.hasNextPage
                            ? "pointer"
                            : "not-allowed",
                        }}
                        onClick={() => {
                          if (workOrder?.hasNextPage) {
                            setCurrentPage((prev) => {
                              const newPage = prev + 1;
                              setFilters((prevFilters) => ({
                                ...prevFilters,
                                pageNumber: newPage,
                              }));
                              return newPage;
                            });
                          }
                        }}
                        disabled={!workOrder?.hasNextPage}
                      >
                        <ArrowRight className="icon-thin" />
                      </button>

                      <LastPageIcon
                        className="icon-thin"
                        style={{
                          color: workOrder?.hasNextPage ? "gray" : "lightgray",
                          cursor: workOrder?.hasNextPage
                            ? "pointer"
                            : "not-allowed",
                        }}
                        onClick={() => {
                          if (workOrder?.hasNextPage) {
                            setCurrentPage(workOrder.totalPages);
                            setFilters((prev) => ({
                              ...prev,
                              pageNumber: workOrder.totalPages,
                            }));
                          }
                        }}
                      />
                      <form action="" style={{ width: "30px", height: "30px" }}>
                        <input
                          type="text"
                          placeholder="1"
                          value={workOrder?.currentPage}
                          readOnly
                          style={{
                            width: "100%",
                            height: "100%",
                            textAlign: "center",
                            padding: 0,
                          }}
                        />
                      </form>
                      <p style={{ margin: 0 }}>
                        of {workOrder?.totalPages || "1"}
                      </p>
                      <div
                        style={{
                          borderLeft: "1px solid gray",
                          height: "24px",
                          margin: "0 10px",
                        }}
                      ></div>
                      <Dropdown>
                        <Dropdown.Toggle
                          className="icon-thin"
                          style={{
                            background: "none",
                            border: "none",
                            padding: 0,
                            color: "gray",
                          }}
                        >
                          <SaveAltIcon className="icon-thin" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={handlePdf}>PDF</Dropdown.Item>
                          <Dropdown.Item onClick={handleExcelExport}>
                            Excel
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Nav>
                  <hr />
                  <Tab.Content>
                    <Tab.Pane eventKey="grid">
                      <ContractorPerformanceGrid
                        workOrder={workOrder}
                        categories={categoryOfWork}
                        filters={filters}
                        categoryOfWorkIds={categoryOfWorkIds}
                        equipment={equipment}
                        equipmentIds={equipmentCategoryIds}
                        location={location}
                        locationIds={locationIds}
                        equimentCategories={equipmentCategory}
                        equipmentCategoriesId={equipmentCategoryIds}
                        isLoading={isLoading}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractorPerformance;
