import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import SearchIcon from '../../../Assets/Icons/SearchIcon';
import AddAssetMakeModal from '../../WorkOrders/Modal/AddAssetMakeModal';
import AddAssetModelModal from '../../WorkOrders/Modal/AddAssetModelModal';
import usefetchData from '../../../hooks/useFetchData';
import {
  setAssetName,
  setAssetDescription,
  setAssetCategory,
  setAssetLocation,
  setAssetStatus,
  setAssetCondition,
  setSerialNumber,
  setSaharaDeskTag,
  setBarCode,
  setAssetModel,
  setParentAssetId
} from '../../../redux/assetSlice';

const CreateAssets = () => {
  const dispatch = useDispatch();
  const assetState = useSelector((state) => state.asset);

  const [locationSearch, setLocationSearch] = useState('');
  const [assetSearch, setAssetSearch] = useState('');
  const [statusSearch, setStatusSearch] = useState('');
  const [conditionSearch, setConditionSearch] = useState('');
  const [makeSearch, setMakeSearch] = useState('');
  const [modelSearch, setModelSearch] = useState('');
  const [assetMakeShow, setAssetMakeShow] = useState(false);
  const [assetModelShow, setAssetModelShow] = useState(false);

  const [assetName, setAssetNameInput] = useState(assetState.assetName || '');
  const [assetDescription, setAssetDescriptionInput] = useState(assetState.assetDescription || '');
  const [serialNumber, setSerialNumberInput] = useState(assetState.serialNumber || '');
  const [saharaDeskTag, setSaharaDeskTagInput] = useState(assetState.saharaDeskTag || '');
  const [barCode, setBarCodeInput] = useState(assetState.barCode || '');
  
  const { data: assetData = [] } = usefetchData(
    ['asset-category'],
    `/Assets/Categories`,
    {},
    'Error fetching category of work'
  );

  const { data: locationData = [] } = usefetchData(
    ['location'],
    `/Locations/GetSimpleLocationList`,
    {},
    'Error getting location data',
    true
  );

  const { data: assetCondition = [] } = usefetchData(
    ['asset-condition'],
    `/Assets/GetAllAssetConditions`,
    {},
    'Error fetching category of work'
  );

  const { data: assetStatus = [] } = usefetchData(
    ['asset-status'],
    `/Assets/GetAllAssetStatuses`,
    {},
    'Error fetching category of work'
  );

  const { data: assetMake = [] } = usefetchData(
    ['asset-make'],
    `/Assets/GetAllAssetMakes`,
    {},
    'Error fetching category of work'
  );

  const { data: assetModel = [] } = usefetchData(
    ['asset-model'],
    `/Assets/GetAllAssetMakes`,
    {},
    'Error fetching category of work'
  );

  useEffect(() => {
    setAssetNameInput(assetState.assetName);
    setAssetDescriptionInput(assetState.assetDescription);
    setSerialNumberInput(assetState.serialNumber);
    setSaharaDeskTagInput(assetState.saharaDeskTag);
    setBarCodeInput(assetState.barCode);
  }, [assetState]);

  const handleLocationSelect = (eventKey) => {
    const selectedLocation = locationData.find(location => location.locationName === eventKey);
    if (selectedLocation) {
      dispatch(setAssetLocation({ id: selectedLocation.id, name: selectedLocation.locationName }));
      setLocationSearch('');
    }
  };

  const handleAssetCategorySelect = (eventKey) => {
    const selectedCategory = assetData.find(category => category.assetCategoryName === eventKey);
    if (selectedCategory) {
      dispatch(setAssetCategory({ id: selectedCategory.id, name: selectedCategory.assetCategoryName }));
      setAssetSearch('');
    }
  };

  const handleAssetStatusSelect = (eventKey) => {
    const selectedStatus = assetStatus.find(status => status.assetStatusName === eventKey);
    if (selectedStatus) {
      dispatch(setAssetStatus({ id: selectedStatus.id, name: selectedStatus.assetStatusName }));
      setStatusSearch('');
    }
  };

  const handleConditionSelect = (eventKey) => {
    const selectedCondition = assetCondition.find(condition => condition.assetConditionName === eventKey);
    if (selectedCondition) {
      dispatch(setAssetCondition({ id: selectedCondition.id, name: selectedCondition.assetConditionName }));
      setConditionSearch('');
    }
  };

  const handleMakeSelect = (eventKey) => {
    const selectedMake = assetMake.find(make => make.makeName === eventKey);
    if (selectedMake) {
      dispatch(setAssetModel({ id: selectedMake.id, name: selectedMake.makeName }));
      setMakeSearch('');
    }
  };

  const handleModelSelect = (eventKey) => {
    const selectedModel = assetModel.find(model => model.name === eventKey);
    if (selectedModel) {
      dispatch(setAssetModel({ id: selectedModel.id, name: selectedModel.name }));
      setModelSearch('');
    }
  };

  const filteredLocationData = locationData.filter(item => item.locationName.toLowerCase().includes(locationSearch.toLowerCase()));
  const filteredAssetData = assetData.filter(item => item.assetCategoryName.toLowerCase().includes(assetSearch.toLowerCase()));
  const filteredAssetStatus = assetStatus.filter(item => item.assetStatusName.toLowerCase().includes(statusSearch.toLowerCase()));
  const filteredAssetCondition = assetCondition.filter(item => item.assetConditionName.toLowerCase().includes(conditionSearch.toLowerCase()));
  const filteredAssetMake = assetMake.filter(item => item.makeName.toLowerCase().includes(makeSearch.toLowerCase()));
  const filteredAssetModel = assetModel.filter(item => item.makeName.toLowerCase().includes(modelSearch.toLowerCase()));

  return (
    <>
      <div className="order-details-content pb-lg-4">
        <div className="fs-16 fw-semibold">Asset Profile</div>
        <div className="fs-13 fw-semibold pt-5 gray-c">ASSET BASIC INFO</div>
        <hr style={{ margin: '10px 0 30px', borderColor: '#D0D0D0' }} />
        <div className="row details-forms-one">
          <div className="col-md-12">
            <label>Asset Name</label>
            <input
              type="text"
              placeholder="Enter the asset name"
              value={assetName}
              onChange={(e) => {
                setAssetNameInput(e.target.value);
                dispatch(setAssetName(e.target.value));
              }}
            />
          </div>
          <div className="col-md-12">
            <label>Description</label>
            <textarea
              placeholder="Enter the asset description"
              value={assetDescription}
              onChange={(e) => {
                setAssetDescriptionInput(e.target.value);
                dispatch(setAssetDescription(e.target.value));
              }}
            ></textarea>
          </div>
          <div className="col-md-6">
            <label>Asset Category</label>
            <Dropdown className="select__form" onSelect={handleAssetCategorySelect}>
              <Dropdown.Toggle className={`select-title ${assetState.assetCategoryName !== '' ? 'selected' : ''}`}>
                {assetState.assetCategoryName || 'Select category from list'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <form className="dropdown-search">
                  <button disabled>
                    <SearchIcon />
                  </button>
                  <input
                    onChange={(e) => setAssetSearch(e.target.value)}
                    type="text"
                    placeholder="Search"
                    value={assetSearch}
                  />
                </form>
                <div className="dropdown-item-content">
                  {filteredAssetData.map((item, index) => (
                    <Dropdown.Item key={index} eventKey={item.assetCategoryName}>
                      {item.assetCategoryName}
                    </Dropdown.Item>
                  ))}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-md-6">
            <label>Asset Location</label>
            <Dropdown className="select__form" onSelect={handleLocationSelect}>
              <Dropdown.Toggle className={`select-title ${assetState.assetLocationName !== '' ? 'selected' : ''}`}>
                {assetState.assetLocationName || 'Select asset location from list'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <form className="dropdown-search">
                  <button disabled>
                    <SearchIcon />
                  </button>
                  <input
                    value={locationSearch}
                    onChange={(e) => setLocationSearch(e.target.value)}
                    type="text"
                    placeholder="Search"
                  />
                </form>
                <div className="dropdown-item-content">
                  {filteredLocationData.map((item, index) => (
                    <Dropdown.Item key={index} eventKey={item.locationName}>
                      {item.locationName}
                    </Dropdown.Item>
                  ))}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-md-6">
            <label>Asset Status</label>
            <Dropdown className="select__form" onSelect={handleAssetStatusSelect}>
              <Dropdown.Toggle className={`select-title ${assetState.assetStatusName !== '' ? 'selected' : ''}`}>
                {assetState.assetStatusName || 'Select status from list'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <form className="dropdown-search">
                  <button disabled>
                    <SearchIcon />
                  </button>
                  <input
                    onChange={(e) => setStatusSearch(e.target.value)}
                    type="text"
                    placeholder="Search"
                    value={statusSearch}
                  />
                </form>
                <div className="dropdown-item-content">
                  {filteredAssetStatus.map((item, index) => (
                    <Dropdown.Item key={index} eventKey={item.assetStatusName}>
                      {item.assetStatusName}
                    </Dropdown.Item>
                  ))}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-md-6">
            <label>Asset Condition</label>
            <Dropdown className="select__form" onSelect={handleConditionSelect}>
              <Dropdown.Toggle className={`select-title ${assetState.assetConditionName !== '' ? 'selected' : ''}`}>
                {assetState.assetConditionName || 'Select condition from list'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <form className="dropdown-search">
                  <button disabled>
                    <SearchIcon />
                  </button>
                  <input
                    value={conditionSearch}
                    onChange={(e) => setConditionSearch(e.target.value)}
                    type="text"
                    placeholder="Search"
                  />
                </form>
                <div className="dropdown-item-content">
                  {filteredAssetCondition.map((item, index) => (
                    <Dropdown.Item key={index} eventKey={item.assetConditionName}>
                      {item.assetConditionName}
                    </Dropdown.Item>
                  ))}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className="fs-13 fw-semibold pt-5 gray-c">ASSET IDENTIFICATION, MAKE & MODEL</div>
        <hr style={{ margin: '10px 0 30px', borderColor: '#D0D0D0' }} />
        <div className="row details-forms-one">
          <div className="col-md-6">
            <label>Serial Number</label>
            <input
              type="text"
              placeholder="Enter serial number"
              value={serialNumber}
              onChange={(e) => {
                setSerialNumberInput(e.target.value);
                dispatch(setSerialNumber(e.target.value));
              }}
            />
          </div>
          <div className="col-md-6">
            <label>Tag ID</label>
            <input
              type="text"
              placeholder="Enter tag ID"
              value={saharaDeskTag}
              onChange={(e) => {
                setSaharaDeskTagInput(e.target.value);
                dispatch(setSaharaDeskTag(e.target.value));
              }}
            />
          </div>

          <div className="col-md-6">
            <label className="d-flex align-items-center justify-content-between">
              Make
              <button
                style={{ fontSize: '14px', color: '#D57D2A', borderBottom: '1px solid #D57D2A' }}
                onClick={() => setAssetMakeShow(true)}
              >
                Add New Make
              </button>
            </label>
            <Dropdown className="select__form" onSelect={handleMakeSelect}>
              <Dropdown.Toggle className={`select-title ${assetState.assetModelName !== '' ? 'selected' : ''}`}>
                {assetState.assetModelName || 'Select make'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <form className="dropdown-search">
                  <button disabled>
                    <SearchIcon />
                  </button>
                  <input
                    onChange={(e) => setMakeSearch(e.target.value)}
                    type="text"
                    placeholder="Search"
                    value={makeSearch}
                  />
                </form>
                <div className="dropdown-item-content">
                  {filteredAssetMake.map((item, index) => (
                    <Dropdown.Item key={index} eventKey={item.makeName}>
                      {item.makeName}
                    </Dropdown.Item>
                  ))}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-md-6">
            <label className="d-flex align-items-center justify-content-between">
              Model
              <button
                style={{ fontSize: '14px', color: '#D57D2A', borderBottom: '1px solid #D57D2A' }}
                onClick={() => setAssetModelShow(true)}
              >
                Add New Model
              </button>
            </label>
            <Dropdown className="select__form" onSelect={handleModelSelect}>
              <Dropdown.Toggle className={`select-title ${assetState.assetModelName !== '' ? 'selected' : ''}`}>
                {assetState.assetModelName || 'Select model'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <form className="dropdown-search">
                  <button disabled>
                    <SearchIcon />
                  </button>
                  <input
                    onChange={(e) => setModelSearch(e.target.value)}
                    type="text"
                    placeholder="Search"
                    value={modelSearch}
                  />
                </form>
                <div className="dropdown-item-content">
                  {filteredAssetModel.map((item, index) => (
                    <Dropdown.Item key={index} eventKey={item.makeName}>
                      {item.makeName}
                    </Dropdown.Item>
                  ))}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className="details-buttons pt-lg-5">
          <Link to="/assets/create-new-asset/documents" className="next-btn mt-lg-5">
            Next
          </Link>
        </div>
      </div>

      <AddAssetMakeModal show={assetMakeShow} onHide={() => setAssetMakeShow(false)} />
      <AddAssetModelModal show={assetModelShow} onHide={() => setAssetModelShow(false)} />
    </>
  );
};

export default CreateAssets;
