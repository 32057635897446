import React, { useState,useEffect,useContext} from "react";
import DownIcon from "../../../Assets/Icons/DownIcon";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import GridIcon from "../../../Assets/Icons/GridIcon";
import { Link } from "react-router-dom";
import WashroomTable from "./WashroomTable";
import ModalInspection from "../ModalInspection";
import getUniqueProperty from "../../../utls/getUniqueProprty";
import filterViaProperty from "../../../utls/filterViaProperty";
import searchAllProperty from "../../../utls/searchAllProperty";
import { GET_INSPECTION_CATEGORY_BY_ID } from "../../../utls/constants";
import RoutineInspectionCategoryContext from "../../../Context/Inspections/RoutineInspectionCategoryContext";

const WashroomContent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [inspectionData, setInspectionData] = useState([]);
  const [inspectionShow, setInspectionShow] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const handleAssetClick = (Asset) => {
    setSelectedAsset(Asset);
  };
  const handleLocationClick = (location) => {
    setSelectedLocation(location);
  };

  const handleTeamClick = (team) => {
    setSelectedTeam(team);
  };

  //get asets , locations and teams array from the array of object
  const uniqueAssets = getUniqueProperty(inspectionData, "asset");
  const uniqueLocations = getUniqueProperty(inspectionData, "location");
  const uniqueTeams = getUniqueProperty(inspectionData, "team");

  //filter inspectionData based on selectedAsset if selectedAsset is not empty string
  const filteredData = filterViaProperty(
    inspectionData,
    selectedAsset,
    "asset"
  );

  //filter filteredData based on selectedLocation if selectedLocation is not empty string
  const filteredDataBasedOnLocation = filterViaProperty(
    filteredData,
    selectedLocation,
    "location"
  );

  //filter filteredDataBasedOnLocation based on selectedTeam if selectedTeam is not empty string
  const filteredDataBasedOnTeam = filterViaProperty(
    filteredDataBasedOnLocation,
    selectedTeam,
    "team"
  );

  //filter filteredDataBasedOnTeam based on searchTerm if searchTerm is not empty string also search in every property of filteredDataBasedOnTeam
  const filteredDataBasedOnSearchTerm = searchAllProperty(
    filteredDataBasedOnTeam,
    searchTerm
  );
  const {routineInspectionCategoryData} = useContext(RoutineInspectionCategoryContext);

  const inspectionCategoryId=routineInspectionCategoryData.id;
  var token=localStorage.getItem("bearerToken");
  useEffect(()=>{
 
      fetch(GET_INSPECTION_CATEGORY_BY_ID +inspectionCategoryId,{
        method:'GET',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Authorization': 'Bearer ' + token,
          }
      })
      .then(res=>res.json())
      .then(data=>{

        
        var result=data["data"];
        
        setIsLoading(false);
        setInspectionData(result);
      })
    },[inspectionCategoryId]);
  return (
    <>
      <div className="order-request washroom-right-content">
        <div className="washroom-title">
          <Link
            to="/inspection-category/washroom/f3washroom"
            className="text-decoration-none"
          >
            <div className="fs-13 fw-medium">INSPECTION SCHEDULES</div>
          </Link>
          <div className="d-flex align-items-center gap-3">
            <Link onClick={() => setInspectionShow(true)}>Add Schedule</Link>
            <button>
              <GridIcon />
            </button>
          </div>
        </div>
        <div className="selection-grope">
          <div className="phone-none"></div>
          <div className="dropdown select-dropdown">
            <button className="select-title" data-bs-toggle="dropdown">
              <span className="fs-13">Asset:</span>
              <span className="fs-14 d-flex align-items-center gap-1 text-nowrap">
                {selectedAsset} <DownIcon />
              </span>
            </button>
            <ul className="dropdown-menu select-menu">
              <li onClick={() => handleAssetClick("")}>
                <b>All</b>
              </li>
              <hr />
              {uniqueAssets.map((item, index) => (
                <li key={index} onClick={() => handleAssetClick(item)}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <div className="dropdown select-dropdown">
            <button className="select-title" data-bs-toggle="dropdown">
              <span className="fs-13">Location:</span>
              <span className="fs-14 d-flex align-items-center gap-1 text-nowrap">
                {selectedLocation} <DownIcon />
              </span>
            </button>
            <ul className="dropdown-menu select-menu">
              <li onClick={() => handleLocationClick("")}>
                <b>All</b>
              </li>
              <hr />
              {uniqueLocations.map((item, index) => (
                <li key={index} onClick={() => handleLocationClick(item)}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <div className="dropdown select-dropdown">
            <button className="select-title" data-bs-toggle="dropdown">
              <span className="fs-13">Team:</span>
              <span className="fs-14 d-flex align-items-center gap-1 text-nowrap">
                {selectedTeam} <DownIcon />
              </span>
            </button>
            <ul className="dropdown-menu select-menu">
              <li onClick={() => handleTeamClick("")}>
                <b>All</b>
              </li>
              <hr />
              {uniqueTeams.map((item, index) => (
                <li key={index} onClick={() => handleTeamClick(item)}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <form action="" className="search__box">
            <button disabled type="submit">
              <SearchIcon />
            </button>
            <input
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              type="search"
              placeholder="Search"
            />
          </form>
        </div>

        <div className="inspection-tables-content washroom_tables">
          <WashroomTable washroomData={filteredDataBasedOnSearchTerm} />
        </div>
      </div>

      <ModalInspection
        show={inspectionShow}
        onHide={() => setInspectionShow(false)}
      />
    </>
  );
};

export default WashroomContent;

const inspectionData = [
  {
    id: 0,
    asset: "KDB 869G",
    location: "Karen Mall",
    last: "01/11/2024",
    next: "01/11/2024",
    team: "Engineering",
    worker: "John Wick",
    new: "12 New",
  },
  {
    id: 0,
    asset: "Freezer - 80912",
    location: "Java highway",
    last: "01/11/2024",
    next: "01/11/2024",
    team: "Stores",
    worker: "Anne",
    new: "12 New",
  },
  {
    id: 0,
    asset: "F3 Washrooms",
    location: "UAP Towers",
    last: "01/11/2024",
    next: "01/11/2024",
    team: "Electrical",
    worker: "Andrew",
    new: "12 New",
  },
  {
    id: 0,
    asset: "KDB 869G",
    location: "Karen Mall",
    last: "01/11/2024",
    next: "01/11/2024",
    team: "Engineering",
    worker: "John Wick",
    new: "12 New",
  },
  {
    id: 0,
    asset: "Freezer - 80912",
    location: "Java highway",
    last: "01/11/2024",
    next: "01/11/2024",
    team: "Stores",
    worker: "Anne",
    new: "12 New",
  },
  {
    id: 0,
    asset: "F3 Washrooms",
    location: "UAP Towers",
    last: "01/11/2024",
    next: "01/11/2024",
    team: "Electrical",
    worker: "Andrew",
    new: "12 New",
  },
  {
    id: 0,
    asset: "KDB 869G",
    location: "Karen Mall",
    last: "01/11/2024",
    next: "01/11/2024",
    team: "Engineering",
    worker: "John Wick",
    new: "12 New",
  },
  {
    id: 0,
    asset: "Freezer - 80912",
    location: "Java highway",
    last: "01/11/2024",
    next: "01/11/2024",
    team: "Stores",
    worker: "Anne",
    new: "12 New",
  },
  {
    id: 0,
    asset: "F3 Washrooms",
    location: "UAP Towers",
    last: "01/11/2024",
    next: "01/11/2024",
    team: "Electrical",
    worker: "Andrew",
    new: "12 New",
  },
];
